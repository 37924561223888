import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import EditName from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/ChannelLinkageOverlay/components/EditName';
import EditPhone from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/ChannelLinkageOverlay/components/EditPhone';
import { useEffect } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';

interface IFormInput {
  name: string;
  phone: string;
}

export default function InstagramLinkApply({
  setIsApplyDone,
}: { setIsApplyDone: (value: boolean) => void }) {
  const { realName: name } = useUserInfo();

  const methods = useForm<IFormInput>({
    defaultValues: {
      name,
      phone: '',
    },
  });

  const { handleSubmit, setFocus } = methods;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(function initializeFocus() {
    setFocus('name');
  }, []);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    //TODO - API 연동
    const probability = 0.5; // success가 true가 될 확률 (50%)
    const success = Math.random() < probability;

    if (success) {
      dialogService.pop();
      toastService.successMsg({
        text: '신청 완료했습니다. 담당자의 연락을 기다려주세요.',
      });
      setIsApplyDone(true);
    } else {
      toastService.errorMsg({
        text: '신청을 실패했습니다.',
      });
    }
  };

  return (
    <div className='flex w-[458px]'>
      <FormProvider {...methods}>
        <form autoComplete='off' className='mx-20 mt-5 w-full' onSubmit={handleSubmit(onSubmit)}>
          <div className='flex w-full flex-col gap-9'>
            <EditName isAlwaysHasErrorSpace={true} />
            <EditPhone isAlwaysHasErrorSpace={true} />
          </div>
          <div className='mt-20 flex gap-8'>
            <OutlinedButton
              className='w-full'
              btnColor='blue'
              onClick={() => {
                dialogService.pop();
              }}>
              취소
            </OutlinedButton>

            <ContainedButton type='submit' className='w-full' btnColor='blue'>
              확인
            </ContainedButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
