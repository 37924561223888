import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  type CreateRequestPushParams,
  eventPushRequestCreateParamState,
} from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/event-push-request-create-param-state';
import { eventPushRequestListQueryKeyState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/event-push-request-list-query-key-state';
import { isTargetDropdownErrorState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/is-target-dropdown-error-state';
import { isUseDateRangeFilterState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/is-use-date-range-filter-state';
import {
  type ErrorType,
  signupRangeErrorTypeState,
} from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/signup-range-error-type-state';
import { categoryTypeState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/category-type-state';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useSetAtom } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';
import { SHARED_UTILS } from 'utils/utils';

const isValidDateFormat = (date: string): boolean => {
  if (!date) return false;

  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateFormatRegex.test(date)) return false;

  const parsedDate = dayjs(date, 'YYYY-MM-DD', true);
  if (!parsedDate.isValid()) return false;

  const earliestAllowedDate = dayjs('1900-01-01');
  const latestAllowedDate = dayjs('2500-12-31');
  if (parsedDate.isBefore(earliestAllowedDate) || parsedDate.isAfter(latestAllowedDate))
    return false;

  const dayOfMonth = Number.parseInt(date.split('-')[2], 10);
  return dayOfMonth > 0 && dayOfMonth <= parsedDate.daysInMonth();
};

const validatePushRequestDates = (pushParams: CreateRequestPushParams): ErrorType => {
  const { isSendToHospital, startDate, endDate } = pushParams;

  if (!isSendToHospital) {
    if (!isValidDateFormat(startDate ?? '') || !isValidDateFormat(endDate ?? '')) {
      return 'invalidFormat';
    }
    if (startDate && endDate && dayjs(startDate).isAfter(dayjs(endDate))) {
      return 'endDateBeforeStartDate';
    }
  }
  return 'none';
};

const createPushRequest = async (params: CreateRequestPushParams) => {
  const { data } = await apiClient.v3.apiPushRequestElCreate(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useRequestPush = () => {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();

  const resetPushRequestListKey = useResetAtom(eventPushRequestListQueryKeyState);
  const resetPushRequestParams = useResetAtom(eventPushRequestCreateParamState);
  const resetDateRangeFilter = useResetAtom(isUseDateRangeFilterState);
  const resetCategoryType = useResetAtom(categoryTypeState);

  const isDateRangeFilterUsed = useAtomValue(isUseDateRangeFilterState);
  const categoryType = useAtomValue(categoryTypeState);

  const setSignupRangeErrorType = useSetAtom(signupRangeErrorTypeState);
  const setTargetDropdownError = useSetAtom(isTargetDropdownErrorState);

  const handleRequestSuccess = () => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPushRequestElFind] });
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPushRequestElCount] });
    resetDateRangeFilter();
    resetPushRequestListKey();
    resetPushRequestParams();
    resetCategoryType();
  };

  const pushRequestMutation = useMutation({
    mutationFn: (params: CreateRequestPushParams) => createPushRequest(params),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: handleRequestSuccess,
  });

  const triggerMutation = (
    params: CreateRequestPushParams,
    hospitalEventID: string,
    onSuccessCallback?: () => void,
    onFailureCallback?: () => void,
  ) => {
    pushRequestMutation.mutate(
      { ...params, hospitalID, hospitalEventID },
      {
        onSuccess: () => onSuccessCallback?.(),
        onError: () => onFailureCallback?.(),
      },
    );
  };

  const checkPushParamValid = (params: CreateRequestPushParams) => {
    if (params.isSendToHospital) return true;

    if (isDateRangeFilterUsed) {
      return validateSignupDate(params);
    }
    return validateCategories(params);
  };

  const validateSignupDate = (params: CreateRequestPushParams) => {
    const dateValidationResult = validatePushRequestDates(params);
    setSignupRangeErrorType(dateValidationResult);

    return dateValidationResult === 'none';
  };

  const validateCategories = (params: CreateRequestPushParams): boolean => {
    const { isFirstVisit, treatmentCategories } = params;

    const isNotEmptyArray = (array: boolean[] | string[] | undefined) => (array?.length ?? 0) > 0;

    if (categoryType === 'treatmentCategories') {
      const isValid = isNotEmptyArray(treatmentCategories);
      setTargetDropdownError(!isValid);
      return isValid;
    }

    if (categoryType === 'isFirstVisit') {
      const isValid = isNotEmptyArray(isFirstVisit);
      setTargetDropdownError(!isValid);
      return isValid;
    }

    return false;
  };

  const requestPushAction = ({
    shouldValidate,
    eventPushRequestParam,
    hospitalEventID,
    onSuccessCallback,
    onFailureCallback,
  }: {
    shouldValidate: boolean;
    eventPushRequestParam: CreateRequestPushParams;
    hospitalEventID: string;
    onSuccessCallback: () => void;
    onFailureCallback: () => void;
  }) => {
    const handleMutation = () => {
      triggerMutation(eventPushRequestParam, hospitalEventID, onSuccessCallback, onFailureCallback);
    };

    if (shouldValidate) {
      const isPass = checkPushParamValid(eventPushRequestParam);
      if (isPass) handleMutation();
      return;
    }
    handleMutation();
  };

  return { checkPushParamValid, requestPushAction };
};
