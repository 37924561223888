import type { CounselAutomationAPIFormValues } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { atomWithReset } from 'jotai/utils';

export const originAlgorithmDataState = atomWithReset<CounselAutomationAPIFormValues>({
  hospitalID: '',
  name: '',
  targetTreatmentTags: undefined,
  toBeAppliedNationalityIds: undefined,
  commonAlgorithm: undefined,
  messages: [],
  type: 'CONSULTATION',
});
