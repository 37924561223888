import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import type { CounselAutomationAPIFormValues } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import EditOverlay from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/EditOverlay/EditOverlay';
import { useKakaoCheckWhetherEditOrNot } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/hooks/use-kakao-check-whether-edit-or-not';
import { selectedMessageIndexState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { useAtomValue } from 'jotai';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

export default function MessageNameForm() {
  const inputRef = useRef<HTMLInputElement>(null);
  const { setValue, watch } = useFormContext<CounselAutomationAPIFormValues>();

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const { handleFormClick, showOverlay } = useKakaoCheckWhetherEditOrNot();

  return (
    <div className='relative'>
      {showOverlay && (
        <EditOverlay
          id='message-name-form-overlay'
          onClick={(e) => {
            handleFormClick(e, () => {
              inputRef.current?.focus();
            });
          }}
        />
      )}
      <TextInput
        onChange={(e) => {
          const limitedValue = e.target.value.slice(0, 50);
          setValue(`messages.${selectedMessageIndex}.name`, limitedValue);
        }}
        value={watch(`messages.${selectedMessageIndex}.name`) || ''}
        maxLength={50}
        label={{
          isRequired: true,
          children: '메시지명',
        }}
        ref={inputRef}
      />
    </div>
  );
}
