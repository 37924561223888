import { useMemo } from 'react';
import { ChatMessageProvider } from './ChatMessageContext';
import LeftMessage from './components/LeftMessage/LeftMessage';
import RightMessage from './components/RightMessage/RightMessage';
import { MESSAGE_TEXT_MAX_LENGTH, urlRegex } from './constants/message';
import type { ChattingMessageProps } from './types/message';

export default function ChattingMessage({
  chatRoomID,
  userId,
  chattingWidth,
  setResMessages,
  resMessages,
  ...chatMessage
}: ChattingMessageProps) {
  const { isLeft, content = '', userID, type, _id, translatedContent = '' } = chatMessage;

  const messageWidthStyle = {
    maxWidth: `${chattingWidth / 2}px`,
    width: `${chattingWidth / 2}px`,
  };
  const isMine = userID === userId;

  const isOverLength =
    content.length > MESSAGE_TEXT_MAX_LENGTH || translatedContent.length > MESSAGE_TEXT_MAX_LENGTH;

  const contentParts = content.split(urlRegex) ?? [];
  const isTextMessageContentNull =
    (type === 100 || type === 20100 || type === 12100 || type === 11100) && !content; //텍스트 메시지에서 텍스트가 빈값일 경우 무조건적으로 안보여줌.
  const isInResMessages = useMemo(
    () => !!resMessages?.find((resMessage) => resMessage._id === _id),
    [resMessages, _id],
  );

  return (
    <>
      <ChatMessageProvider
        value={{
          ...chatMessage,
          chatRoomID,
          chattingWidth,
          isMine,
          messageWidthStyle,
          contentParts,
          isOverLength,
          setResMessages,
          resMessages,
          isInResMessages,
        }}>
        {!isTextMessageContentNull && (isLeft ? <LeftMessage /> : <RightMessage />)}
      </ChatMessageProvider>
    </>
  );
}
