import AutoGreetingMessageContainer from '@templates/HospitalSetting/containers/HospitalProfile/components/AutoGreetingMessage/AutoGreetingMessage.container';
import { useHospitalInfo } from '@templates/HospitalSetting/hooks/use-hospital-info';
import { hospitalSettingsModifiedState } from '@templates/HospitalSetting/states/hospital-settings-modified-state';
import { funcSaveButtonState } from '@templates/HospitalSetting/states/save-button-disabled-state';
import { useNavigationBlocker } from 'afterdoc-saas-web/shared/hooks/gnb/use-navigation-blocker';
import { useAtom } from 'jotai';
import { useSetAtom } from 'jotai/index';
import { isEqual } from 'lodash-es';
import { useEffect } from 'react';
import HospitalAddress from './components/HospitalAddress/HospitalAddress';
import HospitalProfile from './components/HospitalProfile/HospitalProfile';
import OperatingHours from './components/OperatingHours/OperatingHours';
import ReservationInquiryMethod from './components/ReservationInquiryMethod/ReservationInquiryMethod';

export default function HospitalProfileContainer() {
  const setSaveFunction = useSetAtom(funcSaveButtonState);

  const { hospitalInfo, initialHospitalInfo, handleSave } = useHospitalInfo();

  const [hospitalSettingsModified, setHospitalSettingsModified] = useAtom(
    hospitalSettingsModifiedState,
  );

  useEffect(() => {
    if (initialHospitalInfo && hospitalInfo) {
      const isEquals = isEqual(initialHospitalInfo, hospitalInfo);
      setHospitalSettingsModified(!isEquals);
    }
  }, [initialHospitalInfo, hospitalInfo]);

  useEffect(() => {
    setSaveFunction(() => handleSave('profile'));
  }, [handleSave, setSaveFunction]);

  useNavigationBlocker({
    shouldBlock: hospitalSettingsModified,
  });

  return (
    <div className='flex flex-col bg-white50'>
      <HospitalProfile />
      <OperatingHours />
      <HospitalAddress />
      <ReservationInquiryMethod />
      <AutoGreetingMessageContainer />
    </div>
  );
}
