import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiManualsElFoldersData } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';

export const createNewFolder = async (
  params: Parameters<typeof apiClient.v3.apiManualsElFoldersCreate>[0],
) => {
  const response = await apiClient.v3.apiManualsElFoldersCreate(params);
  return response.data;
};

export const updateFolders = async (
  params: Parameters<typeof apiClient.v3.apiManualsElFoldersUpdate>[0],
) => {
  const response = await apiClient.v3.apiManualsElFoldersUpdate(params);
  return response.data;
};

export const updateFoldersSortNum = async (
  params: Parameters<typeof apiClient.v3.updatingFolderSortHandler>[0],
) => {
  const response = await apiClient.v3.updatingFolderSortHandler(params);
  return response.data;
};

export const postFolderDelete = async (
  params: Parameters<typeof apiClient.v3.apiManualsElFoldersDelete>[0],
) => {
  const response = await apiClient.v3.apiManualsElFoldersDelete(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useMutateFolders = () => {
  const { hospitalID } = useSelectedHospitalInfo();
  const queryClient = useQueryClient();

  const createNewFolderMutation = useMutation({
    mutationFn: createNewFolder,
  });

  const updateFoldersMutation = useMutation({
    mutationFn: updateFolders,
    onSuccess: (response, variables) => {
      if (response.code === 0) {
        queryClient.setQueryData(
          [QUERY_KEY.apiManualsElFolders, { hospitalID }],
          (oldData: ApiManualsElFoldersData['data']) => {
            if (!oldData) return;
            const updatedFolders = oldData.foldersHasTags?.map((folder) => {
              if (folder._id === variables.folderId) {
                return { ...folder, ...variables };
              }
              return folder;
            });
            const sortedFolders = updatedFolders?.sort((a, b) => b.sortNum - a.sortNum);
            const updatedData = {
              ...oldData,
              foldersHasTags: sortedFolders,
            };
            return updatedData;
          },
        );
      }
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiManualsElFolders, { hospitalID }],
      });
    },
  });

  const updateFoldersSortNumMutation = useMutation({
    mutationFn: updateFoldersSortNum,
    onSuccess: (response) => {
      if (response.code === 0 && response.data.isUpdatedSuccess) {
        queryClient.setQueryData(
          [QUERY_KEY.apiManualsElFolders, { hospitalID }],
          (oldData: ApiManualsElFoldersData['data']) => {
            if (!oldData?.foldersHasTags) return oldData;

            // 현재 캐시된 데이터를 한번 더 가져와서 최신 상태 확인
            const currentData = queryClient.getQueryData([
              QUERY_KEY.apiManualsElFolders,
              { hospitalID },
            ]) as ApiManualsElFoldersData['data'];

            // 최신 데이터를 기반으로 업데이트
            const updatedFolders =
              currentData?.foldersHasTags?.map((folder) => {
                const newSortData = response.data.folders.find((f) => f.folderID === folder._id);
                if (newSortData) {
                  return {
                    ...folder,
                    sortNum: newSortData.sortNum,
                  };
                }
                return folder;
              }) || [];

            // API 응답의 순서대로 정렬
            updatedFolders.sort((a, b) => b.sortNum - a.sortNum);

            return {
              ...oldData,
              foldersHasTags: updatedFolders,
            };
          },
        );
      }
    },
  });

  return {
    createNewFolderMutation,
    updateFoldersMutation,
    updateFoldersSortNumMutation,
  };
};
