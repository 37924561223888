import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import DownloadFooter from 'afterdoc-design-system/components/Molecules/DownloadFooter/DownloadFooter';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ManualResponse } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useProcessImagesForUpload } from 'afterdoc-saas-web/shared/hooks/files/images/use-process-images-for-upload';
import type { ImageType } from 'afterdoc-saas-web/shared/hooks/files/images/use-upload-multiple-images';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import type { UpdateSingleManualAPIFormValues } from 'afterdoc-saas-web/templates/HospitalManual/components/HospitalManualContent/HospitalManualContent';
import { selectedTagIdState } from 'afterdoc-saas-web/templates/HospitalManual/components/HospitalManualPanel/states/selected-tag-id';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';

const updateSingleManual = async (
  params: Parameters<typeof apiClient.v3.apiManualsElUpsertSingle>[0],
) => {
  const response = await apiClient.v3.apiManualsElUpsertSingle(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

interface SaveTreatmentTagContentFooterProps {
  originalImages?: ManualResponse['cautionImages'];
}

export default function SaveTreatmentTagContentFooter({
  originalImages,
}: SaveTreatmentTagContentFooterProps) {
  const queryClient = useQueryClient();

  const { processImagesForUpload, patchFileMutation } = useProcessImagesForUpload();
  const { hospitalID } = useSelectedHospitalInfo();
  const { getValues } = useFormContext<UpdateSingleManualAPIFormValues>();

  const selectedTagId = useAtomValue(selectedTagIdState);

  const updateSingleManualMutation = useMutation({
    mutationFn: updateSingleManual,
    onSuccess: (data) => {
      if (data.isCreatedSuccess) {
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEY.apiManualsElSingle,
            {
              hospitalID,
              treatmentTagId: selectedTagId,
            },
          ],
        });
      }
    },
    onSettled: (data) => {
      showToast(data?.isCreatedSuccess);
    },
  });

  const showToast = (isSuccess: boolean | undefined) => {
    const msg = isSuccess ? '저장되었습니다.' : '저장에 실패했습니다.';
    isSuccess ? toastService.successMsg({ text: msg }) : toastService.errorMsg({ text: msg });
  };

  const onSave = async () => {
    if (!selectedTagId) return;

    const { fileID, manualContent, cautionMessage, usingCautionMessage } = getValues();

    if (manualContent && !SHARED_UTILS.text.checkStringFileSize(manualContent, 15)) {
      return toastService.errorMsg({
        text: '텍스트 에디터의 내용이 너무 많습니다. 내용을 줄여주세요.',
      });
    }

    let processedFileID: string | undefined = undefined;

    if (fileID) {
      const parsedImageFiles = JSON.parse(fileID) as ImageType[];
      processedFileID = await processImagesForUpload({
        newImages: parsedImageFiles,
        originalImages,
      });
    }

    updateSingleManualMutation.mutate({
      hospitalID,
      manualContent,
      cautionMessage,
      usingCautionMessage,
      treatmentTagID: selectedTagId,
      fileID: processedFileID,
    });
  };

  return (
    <DownloadFooter
      saveButtonProps={{
        disabled: updateSingleManualMutation.isPending || patchFileMutation.isPending,
      }}
      saveButton='저장'
      onSave={onSave}
      className='sticky right-0 bottom-0'
    />
  );
}
