import type { UserHServicePhotoAuthorizationType } from '@apis/swaggers/swagger-docs';
import EditAccountAuthorization from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AllAccountList/components/AccountUpdateDialog/components/EditAccountAuthorization';
import EditAccountButton from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AllAccountList/components/AccountUpdateDialog/components/EditAccountButton';
import EditAccountId from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AllAccountList/components/AccountUpdateDialog/components/EditAccountId';
import EditAccountJob from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AllAccountList/components/AccountUpdateDialog/components/EditAccountJob';
import EditAccountName from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AllAccountList/components/AccountUpdateDialog/components/EditAccountName';
import EditAccountPassword from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AllAccountList/components/AccountUpdateDialog/components/EditAccountPassword';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { FormProvider, useForm } from 'react-hook-form';

interface AccountUpdateDialogProps {
  account: UserHServicePhotoAuthorizationType;
}

export default function AccountUpdateDialog({ account }: AccountUpdateDialogProps) {
  const { hospitalID } = useSelectedHospitalInfo();

  const methods = useForm({
    defaultValues: {
      _id: account._id,
      hospitalID: hospitalID,
      id: account.id,
      realName: account.realName,
      jobType: account.jobType,
      authorizationType: account.authorizationTypeID?.name,
    },
  });

  return (
    <FormProvider {...methods}>
      <form autoComplete='off' className='h-full'>
        <div className='flex h-full w-full flex-col'>
          <div className='flex items-center bg-blue50 p-16'>
            <span className='text-Body12 text-black700'>
              <span className='font-bold text-blue500'>비밀번호는 변경을 원하시는 경우</span>
              에만 입력해 주세요.
            </span>
          </div>
        </div>
        <div className='flex w-full flex-col-center gap-32 p-20'>
          <div className='w-[342px] flex-col-center flex-grow gap-16'>
            <EditAccountName />
            <EditAccountId />
            <EditAccountPassword />
            <EditAccountJob />
            <EditAccountAuthorization />
          </div>
          <EditAccountButton />
        </div>
      </form>
    </FormProvider>
  );
}
