import { SHARED_UTILS } from '@shared-utils/utils';
import ButtonTab from 'afterdoc-design-system/components/Atoms/Tabs/components/ButtonTab';
import Tabs from 'afterdoc-design-system/components/Atoms/Tabs/components/Tabs';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import type {
  TargetableNationalitiesHandlerParams,
  TargetableNationalitiesHandlerResponse,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import type { CounselAutomationAPIFormValues } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { COUNSEL_TAB_ITEMS } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/constants/counsel-tab-items';
import { algorithmModeState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/algorithm-mode';
import { selectedCounselAutomationTabState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/states/selected-counsel-automation-tab-state';
import { useAtom, useAtomValue } from 'jotai';
import { intersection, xor } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSingleAlgorithmInfo } from '../hooks/use-single-algorithm-info';

const getTargetableNationalities = async (params: TargetableNationalitiesHandlerParams) => {
  const response = await apiClient.v3.targetableNationalitiesHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const isNationalitySubset = (
  toBeAppliedNationalityIds: string[],
  targetableNationalities: { _id: string }[],
) => {
  return toBeAppliedNationalityIds.every((id) =>
    targetableNationalities.some((nat) => nat._id === id),
  );
};

const canProceedToNextTab = (
  mode: string,
  toBeAppliedNationalityIds: string[] | undefined,
  targetableNationalities: { _id: string }[],
  originalNationalityIds?: string[],
  targetTreatmentTags?: string[],
  originalTreatmentTagIds?: string[],
) => {
  switch (mode) {
    case 'CREATE':
      if (
        !toBeAppliedNationalityIds?.length &&
        targetableNationalities &&
        targetableNationalities[0]._id !== '-1'
      ) {
        return false;
      }

      return isNationalitySubset(toBeAppliedNationalityIds || [], targetableNationalities);

    case 'EDIT': {
      if (!originalNationalityIds || !targetTreatmentTags || !originalTreatmentTagIds) return false;

      // 수정사항이 있는지 확인
      const isTreatmentTagsChanged = !!xor(targetTreatmentTags, originalTreatmentTagIds).length;

      const isNationalityChanged = !!xor(toBeAppliedNationalityIds, originalNationalityIds).length;

      // 치료태그나 국적이 변경된 경우 TARGET_EDITED로 처리되어야 함
      if (isTreatmentTagsChanged || isNationalityChanged) return false;

      // 아무것도 변경되지 않은 경우만 EDIT으로 처리
      return true;
    }

    case 'TARGET_EDITED': {
      const isUnchanged =
        !xor(toBeAppliedNationalityIds, originalNationalityIds).length &&
        !xor(targetTreatmentTags, originalTreatmentTagIds).length;

      // 변경사항이 없는 경우
      if (isUnchanged) {
        // 다음으로 이동 불가
        return false;
      }

      // 기존에 선택됐던 국적이 없거나 / 기존에 선택됐던 치료태그가 없거나 / 대상 치료태그가 없거나
      if (!originalNationalityIds || !targetTreatmentTags || !originalTreatmentTagIds) {
        // 다음으로 이동 불가
        return false;
      }

      if (
        // 선택된 국적이 하나도 없으면서
        !toBeAppliedNationalityIds?.length &&
        // 선택 가능한 국적이 존재하면서
        targetableNationalities &&
        // 국적 미입력이 없는 경우 (=이미 국적 미입력으로 만들어진 자동화가 존재하는 경우)
        targetableNationalities[0]._id !== '-1'
      ) {
        // 다음으로 이동 불가
        return false;
      }

      // 치료태그 변경 여부 확인
      const isTreatmentTagsChanged = !!xor(targetTreatmentTags, originalTreatmentTagIds).length;

      // 치료태그가 변경된 경우
      if (isTreatmentTagsChanged) {
        // targetableNationalities가 존재하면 (해당 치료태그로 생성된 자동화가 없으면) 진행 가능
        return targetableNationalities.length > 0;
      }

      // 치료태그는 그대로이고 국적만 변경된 경우
      return isNationalitySubset(toBeAppliedNationalityIds || [], targetableNationalities);
    }

    case 'COPY': {
      const isUnchanged =
        !xor(toBeAppliedNationalityIds, originalNationalityIds).length &&
        !xor(targetTreatmentTags, originalTreatmentTagIds).length;

      // 변경사항이 없는 경우
      if (isUnchanged) {
        // 다음으로 이동 불가
        return false;
      }

      // 기존에 선택됐던 국적이 없거나 / 기존에 선택됐던 치료태그가 없거나 / 대상 치료태그가 없거나
      if (!originalNationalityIds || !originalTreatmentTagIds || !targetTreatmentTags) {
        // 다음으로 이동 불가
        return false;
      }

      if (
        // 선택된 국적이 하나도 없으면서
        !toBeAppliedNationalityIds?.length &&
        // 선택 가능한 국적이 존재하면서
        targetableNationalities &&
        // 국적 미입력이 없는 경우 (=이미 국적 미입력으로 만들어진 자동화가 존재하는 경우)
        targetableNationalities[0]._id !== '-1'
      ) {
        // 다음으로 이동 불가
        return false;
      }

      const isTreatmentTagsChanged = !!xor(targetTreatmentTags, originalTreatmentTagIds).length;
      if (isTreatmentTagsChanged) {
        // targetableNationalities가 존재하면 (해당 치료태그로 생성된 자동화가 없으면) 진행 가능
        return targetableNationalities.length > 0;
      }

      return intersection(toBeAppliedNationalityIds || [], originalNationalityIds).length === 0;
    }

    default:
      return false;
  }
};

export default function RegisterCounselAutomationDialogTabs() {
  const { watch, getValues } = useFormContext<CounselAutomationAPIFormValues>();
  const { hospitalID } = useSelectedHospitalInfo();

  const algorithmMode = useAtomValue(algorithmModeState);
  const name = watch('name');
  const targetTreatmentTags = watch('targetTreatmentTags');

  const [counselAutomationTabItems, setCounselAutomationTabItems] = useState(COUNSEL_TAB_ITEMS);
  const [targetableNationalities, setTargetableNationalities] =
    useState<TargetableNationalitiesHandlerResponse['data']>();
  const [selectedCounselAutomationTab, setCounselSelectedAutomationTab] = useAtom(
    selectedCounselAutomationTabState,
  );
  const { singleAlgorithmInfo } = useSingleAlgorithmInfo();

  const fetchTargetableNationalities = async () => {
    try {
      const response = await getTargetableNationalities({
        hospitalID,
        treatmentTagId: targetTreatmentTags?.join(','),
      });
      setTargetableNationalities(response);
    } catch (error) {
      console.error('Failed to fetch nationalities:', error);
    }
  };

  const onChangeTab = (value: (typeof COUNSEL_TAB_ITEMS)[number]['value']) => {
    const isMovingFromFirstTab = selectedCounselAutomationTab === 0 && value !== 0;

    if (!isMovingFromFirstTab) {
      setCounselSelectedAutomationTab(value);
      return;
    }

    // 1. 기본 유효성 검사
    if (!name?.length || !targetTreatmentTags?.length) {
      toastService.errorMsg({
        text: '현재 페이지의 필수정보를 모두 입력해 주세요.',
      });
      return;
    }

    if (!targetableNationalities) return;

    // 3. 자동화 설정 가능 여부 체크
    const toBeAppliedNationalityIds = getValues('toBeAppliedNationalityIds');

    const canProceed = canProceedToNextTab(
      algorithmMode.mode,
      toBeAppliedNationalityIds,
      targetableNationalities,
      singleAlgorithmInfo?.appliedNationalityIds,
      targetTreatmentTags,
      singleAlgorithmInfo?.appliedTreatmentTagIds,
    );

    if (!canProceed) {
      toastService.errorMsg({
        text: '현재 설정된 치료태그로 설정된 자동화가 이미 존재합니다.',
      });
      return;
    }

    setCounselSelectedAutomationTab(value);
  };

  useEffect(() => {
    if (algorithmMode.mode === 'EDIT' || algorithmMode.mode === 'TARGET_EDITED') {
      setCounselAutomationTabItems((prev) => {
        return prev.map((item) => {
          if (item.value === 0 || item.value === 1) {
            return {
              ...item,
              disabled: true,
            };
          }
          return item;
        });
      });
    }
  }, [algorithmMode]);

  useEffect(() => {
    if (selectedCounselAutomationTab === 0) {
      fetchTargetableNationalities();
    }
  }, [selectedCounselAutomationTab, hospitalID, targetTreatmentTags]);

  return (
    <Tabs value={selectedCounselAutomationTab} onChange={onChangeTab} className='w-[700px]'>
      {counselAutomationTabItems.map(({ label, value }) => (
        <ButtonTab value={value} label={label} key={value} className='border-b border-b-white400'>
          {label}
        </ButtonTab>
      ))}
    </Tabs>
  );
}
