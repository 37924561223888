import { API_URL } from '@config/url';
import { authStatusAtom } from 'afterdoc-saas-web/states/token';
import axios from 'axios';
import { getDefaultStore } from 'jotai/vanilla';
import swaggerDocs from '../swaggers/swagger.json';

const defaultStore = getDefaultStore();

export const Client_Unauthorized = 'Client_Unauthorized';

const getSwaggerPaths = (swaggerDocs: { paths: Record<string, unknown> }) => {
  return Object.keys(swaggerDocs.paths);
};

const swaggerPaths = getSwaggerPaths(swaggerDocs);

const isSwaggerPath = (url: string, swaggerPaths: string[]) => {
  return swaggerPaths.some((path) => url.includes(path));
};

const axiosConfig = {
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
};

const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');

    if (token) {
      config.headers.Authorization = JSON.parse(token);
    }

    // 현재 URL의 origin을 가져와서 baseURL로 설정
    const currentOrigin = window.location.origin;

    // Swagger 문서에 경로가 정의되어 있는지 검사
    if (isSwaggerPath(config.url ?? '', swaggerPaths)) {
      config.baseURL = API_URL;
    } else {
      config.baseURL = currentOrigin;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      const currentStatus = defaultStore.get(authStatusAtom);

      if (!currentStatus?.requiresReLogin) {
        const newStatus = {
          code: Client_Unauthorized,
          requiresReLogin: true,
        };
        defaultStore.set(authStatusAtom, newStatus);
      }
    }

    return Promise.reject(error);
  },
);

export { axiosInstance };
