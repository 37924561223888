import { SHARED_UTILS } from '@shared-utils/utils';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  ApiMemosElFindParams,
  ApiPatientsElDetailData,
  File,
  MemoWithFileID,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import FullLoading from 'afterdoc-saas-web/shared/components/FullLoading/FullLoading';
import { useProcessImagesForUpload } from 'afterdoc-saas-web/shared/hooks/files/images/use-process-images-for-upload';
import { useUploadMultipleImages } from 'afterdoc-saas-web/shared/hooks/files/images/use-upload-multiple-images';
import { patientIdState } from 'afterdoc-saas-web/states/patient-id';
import { useManagementDirection } from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoInputBoard/ManagementDirection/containers/hooks/use-management-direction';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ManagementDirectionImageGallery from './components/ManagementDirectionImageGallery';
import ManagementDirectionListsWithTitle from './components/ManagementDirectionListsWithTitle';
import ManagementDirectionMemoContentEditor from './components/ManagementDirectionMemoContentEditor';

const fetchManagementDirection = async (params: ApiMemosElFindParams) => {
  const response = await apiClient.v3.apiMemosElFind(params);
  return SHARED_UTILS.api.checkApiResponse(response.data) as MemoWithFileID[];
};

export type CreateMemosAPIFormValues = Parameters<typeof apiClient.v3.apiMemosElCreate>[0];
export type UpdateMemosAPIFormValues = Parameters<typeof apiClient.v3.apiMemosElUpdate>[0];

export const MANAGEMENT_DIRECTION_DIALOG_ID = 'management-direction-dialog';

export default function ManagementDirectionDialogContainer() {
  const queryClient = useQueryClient();

  const patientId = useAtomValue(patientIdState);
  const { patientId: patientIdFromQuery } = queryClient.getQueryData([
    QUERY_KEY.apiPatientsElDetail,
    { patientId },
  ]) as ApiPatientsElDetailData['data'];

  const [contentType, setContentType] = useState<'CREATE' | 'EDIT'>();
  const [selectedManagementDirectionId, setSelectedManagementDirectionId] = useState<string>();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [
      QUERY_KEY.apiMemosElFind,
      { hserviceID: patientIdFromQuery, type: 2000, limit: 5, key: '_id', way: -1 },
    ] as const,
    queryFn: async ({ queryKey, pageParam = 0 }) => {
      return fetchManagementDirection({ ...queryKey[1], skip: pageParam });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const totalLoadedMemos = allPages.flat().length;
      if (lastPage.length === 5) {
        return totalLoadedMemos;
      }
      return undefined;
    },
    refetchOnMount: 'always',
  });

  const managementDirectionLists = useMemo(() => {
    return data?.pages.flat() ?? [];
  }, [data]);

  const selectedManagementDirection = useMemo(
    () => data?.pages.flat().find((memo) => memo._id === selectedManagementDirectionId),
    [data, selectedManagementDirectionId],
  );

  const onClickAddManagementDirection = () => {
    setSelectedManagementDirectionId(undefined);
    setContentType('CREATE');
  };

  const handleClickMemoCard = (id: string) => {
    setSelectedManagementDirectionId(id);
    setContentType('EDIT');
  };

  useEffect(() => {
    if (data && !!data.pages?.[0]?.length && !selectedManagementDirectionId && !contentType) {
      setSelectedManagementDirectionId(data.pages[0][0]._id);
      setContentType('EDIT');
    }
  }, [data]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        dialogService.popById(MANAGEMENT_DIRECTION_DIALOG_ID);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!data) {
    return (
      <div className='mt-10 flex h-[444px] w-full gap-20 bg-white50 px-20'>
        <FullLoading />
      </div>
    );
  }

  return (
    <div className='mt-10 grid h-full grid-cols-[320px,1fr] gap-20 px-20 pb-20'>
      <ManagementDirectionListsWithTitle
        managementDirectionLists={managementDirectionLists}
        selectedManagementDirectionId={selectedManagementDirectionId}
        onClickAddManagementDirection={onClickAddManagementDirection}
        handleClickMemoCard={handleClickMemoCard}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
      <Content
        managementDirectionLists={managementDirectionLists}
        selectedManagementDirectionId={selectedManagementDirectionId}
        setSelectedManagementDirectionId={setSelectedManagementDirectionId}
        contentType={contentType}
        selectedManagementDirection={selectedManagementDirection}
        setContentType={setContentType}
      />
    </div>
  );
}

interface ContentProps {
  managementDirectionLists: MemoWithFileID[];
  selectedManagementDirection?: MemoWithFileID;
  selectedManagementDirectionId?: string;
  setSelectedManagementDirectionId: (id?: string) => void;
  contentType?: 'CREATE' | 'EDIT';
  setContentType: (contentType?: 'CREATE' | 'EDIT') => void;
}

const Content = ({
  managementDirectionLists,
  selectedManagementDirection,
  selectedManagementDirectionId,
  setSelectedManagementDirectionId,
  contentType,
  setContentType,
}: ContentProps) => {
  const queryClient = useQueryClient();
  const [content, setContent] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);

  const {
    images,
    setImages,
    handleFileChange,
    handleRemoveImage,
    handleAttachMultipleImages,
    filePickerRef,
  } = useUploadMultipleImages();

  const patientId = useAtomValue(patientIdState);
  const { patientId: patientIdFromQuery } = queryClient.getQueryData([
    QUERY_KEY.apiPatientsElDetail,
    { patientId },
  ]) as ApiPatientsElDetailData['data'];

  const { processImagesForUpload } = useProcessImagesForUpload();

  const {
    createManagementDirectionMutation,
    updateManagementDirectionMutation,
    deleteManagementDirectionMutation,
  } = useManagementDirection(patientIdFromQuery);

  const resetState = () => {
    setSelectedManagementDirectionId(undefined);
    setContentType(undefined);
  };

  const handleSaveManagementDirection = async () => {
    setIsSaving(true);

    if (!content?.length) {
      toastService.errorMsg({
        text: '내용을 입력해주세요.',
      });
      setIsSaving(false);
      return;
    }

    let processedFileID: string | undefined;
    if (images.length > 0) {
      processedFileID = await processImagesForUpload({
        newImages: images,
        originalImages:
          contentType === 'EDIT'
            ? (selectedManagementDirection?.fileIDs as File[])?.[0]?.paths?.map((path) => ({
                url: path,
                id: (selectedManagementDirection?.fileIDs as File[])[0]?._id,
              }))
            : undefined,
      });
    }

    if (contentType === 'CREATE') {
      return createManagementDirectionMutation.mutate(
        {
          hserviceID: patientIdFromQuery,
          type: 2000,
          content,
          fileIDs: processedFileID ? [processedFileID] : undefined,
        },
        {
          onSuccess: (data) => {
            setContentType('EDIT');
            setSelectedManagementDirectionId(data._id);
          },
          onSettled: () => {
            setIsSaving(false);
          },
        },
      );
    }

    if (contentType === 'EDIT' && selectedManagementDirectionId) {
      return updateManagementDirectionMutation.mutate(
        {
          _id: selectedManagementDirectionId,
          content,
          fileIDs: processedFileID ? [processedFileID] : undefined,
        },
        {
          onSettled: () => {
            setIsSaving(false);
          },
        },
      );
    }
  };

  useEffect(() => {
    if (contentType === 'CREATE') {
      setContent('');
      setImages([]);
    }
  }, [contentType]);

  useEffect(() => {
    if (!selectedManagementDirection) return;

    setContent(selectedManagementDirection?.content ?? '');
    setImages(
      (selectedManagementDirection?.fileIDs as File[])?.[0]?.paths?.map((path) => ({
        id: (selectedManagementDirection?.fileIDs as File[])?.[0]?._id,
        url: path,
      })) ?? [],
    );
  }, [selectedManagementDirection]);

  useEffect(() => {
    if (selectedManagementDirectionId && !contentType) {
      setContentType('EDIT');
    }
  }, [selectedManagementDirectionId]);

  return (
    <div className='flex h-full w-[620px] flex-col gap-10'>
      <div className='relative h-full w-full'>
        <ManagementDirectionMemoContentEditor
          content={content}
          setContent={setContent}
          selectedManagementDirectionId={selectedManagementDirectionId}
          disabled={!selectedManagementDirectionId && !contentType}
        />
        <ManagementDirectionImageGallery
          key={selectedManagementDirectionId ?? uuidv4()}
          disabled={!selectedManagementDirectionId && !contentType}
          images={images}
          onImagesChange={setImages}
          handleFileChange={handleFileChange}
          handleRemoveImage={handleRemoveImage}
          handleAttachMultipleImages={handleAttachMultipleImages}
          ref={filePickerRef}
        />
      </div>
      {(selectedManagementDirectionId || !!contentType) && (
        <BottomTextAndHandlerButtons
          isSaving={isSaving}
          contentType={contentType}
          onRemoveManagementDirection={() =>
            deleteManagementDirectionMutation.mutate(
              { _id: selectedManagementDirectionId },
              {
                onSuccess: (data) => {
                  if (
                    managementDirectionLists.filter((memo) => memo._id !== data._id).length === 0
                  ) {
                    resetState();
                    setContent('');
                    setImages([]);
                    return;
                  }

                  setSelectedManagementDirectionId(
                    managementDirectionLists.filter((memo) => memo._id !== data._id).at(0)?._id ??
                      undefined,
                  );
                  setContent(
                    managementDirectionLists.filter((memo) => memo._id !== data._id).at(0)
                      ?.content ?? '',
                  );
                  setImages(
                    (
                      managementDirectionLists.filter((memo) => memo._id !== data._id).at(0)
                        ?.fileIDs as File[]
                    )?.[0]?.paths?.map((path) => ({
                      id: (
                        managementDirectionLists.filter((memo) => memo._id !== data._id).at(0)
                          ?.fileIDs as File[]
                      )?.[0]?._id,
                      url: path,
                    })) ?? [],
                  );
                },
              },
            )
          }
          onHandleSaveManagementDirection={handleSaveManagementDirection}
          updatedAt={selectedManagementDirection?.updatedAt}
          modifierName={selectedManagementDirection?.modifierName}
          writerName={selectedManagementDirection?.writerName}
        />
      )}
    </div>
  );
};

interface BottomTextAndHandlerButtonsProps {
  isSaving: boolean;
  onRemoveManagementDirection: () => void;
  onHandleSaveManagementDirection: () => void;
  contentType?: 'CREATE' | 'EDIT';
  updatedAt?: string;
  modifierName?: string;
  writerName?: string;
}

const BottomTextAndHandlerButtons = ({
  isSaving,
  contentType,
  onHandleSaveManagementDirection,
  onRemoveManagementDirection,
  updatedAt,
  modifierName,
  writerName,
}: BottomTextAndHandlerButtonsProps) => {
  const bottomText =
    updatedAt && dayjs(updatedAt).isValid()
      ? `${dayjs(updatedAt).format('YYYY-MM-DD HH:mm')}/${modifierName ?? writerName ?? '-'}`
      : '-/-';

  return (
    <div className='flex items-center'>
      <div className='whitespace-nowrap text-Body10 text-black200'>{bottomText}</div>
      <div className='mt-4 flex w-full items-center justify-end gap-10 pt-10'>
        {contentType === 'EDIT' && (
          <OutlinedButton
            btnColor='secondary'
            buttonSize='big'
            className='w-[100px]'
            onClick={onRemoveManagementDirection}>
            삭제
          </OutlinedButton>
        )}
        <ContainedButton
          buttonSize='big'
          className='w-[100px]'
          disabled={isSaving}
          onClick={onHandleSaveManagementDirection}>
          저장
        </ContainedButton>
      </div>
    </div>
  );
};
