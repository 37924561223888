import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import { selectedMessageIDState } from 'afterdoc-saas-web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { useResetAtom } from 'jotai/utils';
import { type RefObject, forwardRef } from 'react';
import { scrollToBottom } from '../ChattingContent/functions/scrollToBottom';

export const MoveBelowButton = forwardRef<HTMLDivElement>((_, ref) => {
  const resetSelectedMessageId = useResetAtom(selectedMessageIDState);

  const moveBelow = () => {
    resetSelectedMessageId();

    if (ref && 'current' in ref) {
      scrollToBottom(ref as RefObject<HTMLDivElement>);
    }
  };

  return (
    <div className='absolute right-0 bottom-10 left-0 flex justify-center'>
      <div
        onClick={moveBelow}
        className='flex h-40 w-40 items-center justify-center rounded-full border border-none bg-black100 transition-transform hover:scale-105 active:scale-95'>
        <IconButton icon='chevron-down' color='white50' size={32} />
      </div>
    </div>
  );
});
