import { CUSTOMER_CHAT_ERROR } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/constants/customer-chat-error-text';
import { isActivatingChatTranslateState } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/states/is-activating-chat-translate';
import { ROOM_TYPE_MAP } from 'afterdoc-saas-web/templates/CustomerChat/constants/ROOM_TYPE_MAP';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';

export const useHandleErrorInMssage = ({
  error,
  handleRemoveMySelf,
}: { error: Record<string, unknown> | undefined; handleRemoveMySelf: () => void }) => {
  const isActivatingChatTranslate = useAtomValue(isActivatingChatTranslateState);

  //기존 const isNullForInvisibleMoment=!isActivatingChatTranslate && error 코드로 굉장히 광범위하게 조건이 잡혀있었음.
  //이유가 정확히 기억이 안나는데 특정 조건이 있었던 맥락자체만 기억이 남음. 그러므로 흔적을 남기면서 수정함.
  const isNullForInvisibleMoment =
    !isActivatingChatTranslate && error?.code === CUSTOMER_CHAT_ERROR.KAKAO.RESPONSE_ERROR_410;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (error) {
      if (
        isNullForInvisibleMoment &&
        error.type === ROOM_TYPE_MAP.kakao &&
        error.code === CUSTOMER_CHAT_ERROR.KAKAO.RESPONSE_ERROR_410
      ) {
        handleRemoveMySelf();
      }
    }
  }, [error]);

  return { isNullForInvisibleMoment };
};
