import { useQuery } from '@tanstack/react-query';
import TextInput, {
  type TextInputLabelTextProps,
} from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiServiceSettingsElCountriesParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { type ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { SHARED_UTILS } from 'utils/utils';

const baseLabelProps: TextInputLabelTextProps = {
  isRequired: true,
  children: '휴대폰번호',
  width: 70,
  position: 'horizontal',
};

const fetchCountriesCodesList = async (params: ApiServiceSettingsElCountriesParams) => {
  const response = await apiClient.v3.apiServiceSettingsElCountries(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

//스펙 아웃으로 주석 처리, 추후 추가 후 주석 해제 예정
export default function EditPhoneNum() {
  const { hospitalID } = useSelectedHospitalInfo();

  const { data } = useQuery({
    queryKey: [QUERY_KEY.apiServiceSettingsElCountries, { hospitalID, limit: 240 }] as const,
    queryFn: ({ queryKey }) => fetchCountriesCodesList(queryKey[1]),
  });

  const displayOptions = data?.map(
    (item) => `${item.koreanCountryName}(+${item.internationalDialingCodes})`,
  ) ?? ['한국(+82)'];

  const { setValue, register, clearErrors, watch } = useFormContext();

  const inputValue = watch('phone') ?? '';

  const defaultIndex = data?.findIndex((item) => item.koreanCountryName === '한국') ?? 0;
  // const [displayValue, setDisplayValue] = useState<string>('한국(+82)');
  // const [selectedIndex, setSelectedIndex] = useState<number>(defaultIndex);

  // 국가 코드 선택 시 업데이트
  const handleNationalCodeSelect = (index: number) => {
    // setSelectedIndex(index);
    const selectedDisplayNation = displayOptions[index];
    // setDisplayValue(selectedDisplayNation);

    const selectedCodeId = data?.find(
      (item) =>
        selectedDisplayNation === `${item.koreanCountryName}(+${item.internationalDialingCodes})`,
    )?.countryId;

    setValue('countryCodeId', selectedCodeId);
    clearErrors('countryCodeId');

    // 국가 코드 선택 후 전화번호 필드로 포커스 이동
    // setFocus('phone');
  };

  useEffect(() => {
    handleNationalCodeSelect(defaultIndex);
  }, [defaultIndex]);

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    value = value.replace(/[^0-9]/g, '');
    setValue('phone', value);
  };

  return (
    <div className='flex w-full flex-row items-center gap-4'>
      {/* <div>
        <TextFieldSelectBoxDropdown
          label={baseLabelProps}
          options={displayOptions}
          onSelect={handleNationalCodeSelect}
          dropdownWrapperClassName='ml-80'
          wrapperClassName='flex-grow'
          hasFixedHeight={false}
          value={displayValue}
          width={126}
          selectedIndex={selectedIndex}
          {...register('countryCodeId', {
            required: true,
          })}
          // Tab 키로 포커스를 전화번호 필드로 이동
          onKeyDown={(e) => {
            if (e.key === 'Tab') {
              e.preventDefault(); // 기본 동작 방지
              setFocus('phone'); // React Hook Form의 setFocus 호출
            }
          }}
        />
      </div> */}
      {/* <div> */}
      <TextInput
        label={baseLabelProps}
        id='phone'
        placeholder='예) 01012345678'
        maxLength={15}
        className='flex-grow'
        value={inputValue}
        {...register('phone', {
          required: true,
          onChange: handlePhoneChange,
        })}
      />
    </div>
    // </div>
  );
}
