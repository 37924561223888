import ButtonTab from 'afterdoc-design-system/components/Atoms/Tabs/components/ButtonTab';
import Tabs from 'afterdoc-design-system/components/Atoms/Tabs/components/Tabs';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { MARKETING_TAB_ITEMS } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/constants/marketing-tab-items';
import { algorithmModeState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/algorithm-mode';
import { selectedMarketingAutomationTabState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/states/selected-marketing-automation-tab-state';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export default function RegisterMarketingAutomationDialogTabs() {
  const { watch } = useFormContext();

  const algorithmMode = useAtomValue(algorithmModeState);

  const name = watch('name');
  const targetTreatmentTags = watch('targetTreatmentTags');
  const isForNoTreatmentTags = watch('isForNoTreatmentTags');

  const [marketingAutomationTabItems, setMarketingAutomationTabItems] =
    useState(MARKETING_TAB_ITEMS);
  const [selectedAutomationTab, setSelectedAutomationTab] = useAtom(
    selectedMarketingAutomationTabState,
  );

  const onChangeTab = (value: (typeof MARKETING_TAB_ITEMS)[number]['value']) => {
    const isLeavingFirstTab = selectedAutomationTab === 0 && value !== 0;
    if (!isLeavingFirstTab) {
      return setSelectedAutomationTab(value);
    }

    // 필수 입력값 검증
    const isNameEmpty = !name?.length;
    const isTreatmentTagsInvalid = !targetTreatmentTags?.length && !isForNoTreatmentTags;
    const hasValidationError = isNameEmpty || isTreatmentTagsInvalid;

    if (hasValidationError) {
      return toastService.errorMsg({ text: '현재 페이지의 필수정보를 모두 입력해 주세요.' });
    }

    return setSelectedAutomationTab(value);
  };

  useEffect(() => {
    if (algorithmMode.mode === 'EDIT') {
      setMarketingAutomationTabItems((prev) => {
        return prev.map((item) => {
          if (item.value === 0 || item.value === 1) {
            return {
              ...item,
            };
          }

          return item;
        });
      });
    }
  }, [algorithmMode]);

  return (
    <Tabs value={selectedAutomationTab} onChange={onChangeTab} className='w-[700px]'>
      {marketingAutomationTabItems.map(({ label, value }) => {
        return (
          <ButtonTab value={value} label={label} key={value} className='border-b border-b-white400'>
            {label}
          </ButtonTab>
        );
      })}
    </Tabs>
  );
}
