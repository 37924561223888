import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import EditorViewer from 'afterdoc-saas-web/shared/components/EditorViewer/EditorViewer';
import { MessageDialogContent } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/Message/components/MessageDialogContent';
import dayjs from 'dayjs';
import { getDetailNotice } from '../apis/get-detail-notice';
import type { NoticeData } from '../types';

type NoticeItemProps = {
  noticeData: NoticeData;
  toggleSelectItem: (e: React.MouseEvent<HTMLDivElement>, id: string) => void;
  isSelected: boolean;
  isSelectable: boolean;
  isChatRoomNotNull: boolean;
};

export default function NoticeItem({
  noticeData,
  toggleSelectItem,
  isSelected,
  isSelectable,
  isChatRoomNotNull,
}: NoticeItemProps) {
  const { id, title, createdAt } = noticeData;
  const activeColor = isSelectable ? 'black500' : 'disabled';
  const activeTextColor = `text-${activeColor}`;
  return (
    <div key={id} className='col-span-2 flex h-36 w-[360px] flex-col-center'>
      <li className='flex w-full flex-row justify-between gap-10'>
        <div className='flex flex-center flex-grow gap-10'>
          {isChatRoomNotNull && (
            <div
              className='cursor-pointer'
              onClick={(e) => isSelectable && toggleSelectItem(e, id)}>
              {isSelected ? (
                <Icon name='checkbox-marked' size={24} color='blue500' />
              ) : (
                <Icon name='checkbox-blank-outline' size={24} color='white600' />
              )}
            </div>
          )}
          <div className={`line-clamp-1 flex-grow text-Header12 ${activeTextColor}`}>{title}</div>
        </div>
        <div
          className='flex flex-center flex-none cursor-pointer'
          onClick={async () => {
            const { title, content, createdAt } = await getDetailNotice(id);
            dialogService.push(
              <MessageDialogContent
                displayCreated={dayjs(createdAt).format('YYYY-MM-DD')}
                title={title ?? ''}>
                <div className='w-full'>
                  <EditorViewer htmlContent={content ?? ''} />
                </div>
              </MessageDialogContent>,
              {
                hasCloseButton: true,
                titleProps: {
                  title: '공지사항 미리보기',
                },
              },
            );
          }}>
          <span className={`text-Body10 ${activeTextColor}`}>
            {dayjs(createdAt).format('YYYY-MM-DD')}
          </span>
          <Icon name='chevron-right' size={24} color={activeColor} />
        </div>
      </li>
      <Divider type='line' className='mt-10' />
    </div>
  );
}
