import TextButton from 'afterdoc-design-system/components/Atoms/Button/TextButton';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import type { CounselAutomationAPIFormValues } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { useTextAreaControl } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/components/hooks/use-text-area-control';
import { selectedMessageIndexState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import ResizableTextArea from 'afterdoc-saas-web/templates/Automation/containers/Dialog/shared/components/ResizableTextArea/ResizableTextArea';
import { useGetIsKakaoAlimAndFriendTalkLinkage } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/shared/hooks/use-get-is-kakao-alim-and-friend-talk-linkage';
import { useAtomValue } from 'jotai';
import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const SurveyContentForm = forwardRef<HTMLTextAreaElement>((_, ref) => {
  const { control, setValue, watch, getValues } = useFormContext<CounselAutomationAPIFormValues>();
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const { hasKakaoAlimAndFriendTalkLinkage } = useGetIsKakaoAlimAndFriendTalkLinkage();

  const surveyQuestion = watch(`messages.${selectedMessageIndex}.content.survey.question`);
  const surveyAnswers = watch(`messages.${selectedMessageIndex}.content.survey.answers`) ?? [];
  const isSurveyOverMax = useMemo(() => surveyAnswers.length >= 10, [surveyAnswers]);

  const answerRefs = useRef<Record<number, HTMLTextAreaElement>>({});

  const { handlePaste, handleChange } = useTextAreaControl({
    text: surveyQuestion ?? '',
  });

  const handleAddAnswer = useCallback(() => {
    const currentAnswers =
      getValues(`messages.${selectedMessageIndex}.content.survey.answers`) || [];

    if (currentAnswers.length >= 100) return;

    setValue(`messages.${selectedMessageIndex}.content.survey.answers`, [...currentAnswers, '']);

    setTimeout(() => {
      const newIndex = currentAnswers.length;
      if (answerRefs.current[newIndex]) {
        answerRefs.current[newIndex].focus();
      }
    }, 0);
  }, [getValues, setValue, selectedMessageIndex]);

  const handleRemoveAnswer = useCallback(
    (index: number) => {
      const currentAnswers =
        getValues(`messages.${selectedMessageIndex}.content.survey.answers`) || [];
      const updatedAnswers = currentAnswers.filter((_, idx) => idx !== index);

      setValue(`messages.${selectedMessageIndex}.content.survey.answers`, updatedAnswers);

      setTimeout(() => {
        const prevIndex = index > 0 ? index - 1 : 0;
        if (answerRefs.current[prevIndex]) {
          answerRefs.current[prevIndex].focus();
        }
      }, 0);
    },
    [getValues, setValue, selectedMessageIndex],
  );

  useEffect(() => {
    if (!surveyAnswers?.length) {
      setValue(`messages.${selectedMessageIndex}.content.survey.answers`, ['', '']);
    }
  }, [surveyAnswers, setValue, selectedMessageIndex]);

  return (
    <>
      <div className='mt-10'>
        {hasKakaoAlimAndFriendTalkLinkage && (
          <div className='rounded-r10 bg-white200 px-16 py-8 text-Body10 text-black500'>
            설문 타입의 메시지는 알림톡을 발송할 수 없습니다.
          </div>
        )}
        <div className='mt-10 flex flex-col gap-10 rounded-r16 bg-white100 p-16'>
          <Controller
            key={`messages.${selectedMessageIndex}.content.survey.question`}
            name={`messages.${selectedMessageIndex}.content.survey.question`}
            control={control}
            render={({ field }) => (
              <>
                <div className='flex w-full gap-10'>
                  <LabelText isRequired className='shrink-0' width={60}>
                    질문
                  </LabelText>

                  <ResizableTextArea
                    {...field}
                    ref={ref}
                    onPaste={handlePaste}
                    onChange={(e) => {
                      handleChange(e, field.onChange);
                    }}
                    maxHeight={120}
                    maxLength={200}
                    placeholder='내용을 입력해 주세요.'
                  />
                </div>
              </>
            )}
          />

          <Divider type='line' />

          {surveyAnswers?.map((_, index) => {
            const disabled = index === 0 || index === 1;

            return (
              <div key={index} className='flex items-center gap-10'>
                <Controller
                  key={`messages.${selectedMessageIndex}.content.survey.answers.${index}`}
                  name={`messages.${selectedMessageIndex}.content.survey.answers.${index}`}
                  control={control}
                  render={({ field }) => (
                    <div className='flex w-full gap-10'>
                      <LabelText isRequired className='shrink-0' width={60}>
                        {`답변${index + 1}`}
                      </LabelText>
                      <ResizableTextArea
                        {...field}
                        className='w-full'
                        maxLength={200}
                        placeholder='내용을 입력해 주세요.'
                        ref={(el) => {
                          if (el) {
                            answerRefs.current[index] = el;
                          }
                        }}
                      />
                    </div>
                  )}
                />
                <TextButton
                  onClick={() => handleRemoveAnswer(index)}
                  disabled={disabled}
                  className='whitespace-nowrap'
                  iconProps={{
                    name: 'minus',
                    color: disabled ? 'disabled' : 'black200',
                    size: 16,
                  }}>
                  삭제
                </TextButton>
              </div>
            );
          })}

          <div className='ml-auto'>
            <button
              type='button'
              onClick={handleAddAnswer}
              disabled={isSurveyOverMax}
              className='flex gap-2 rounded-r6 bg-white400 py-4 pr-4 pl-8 text-Body11 text-black400 disabled:bg-disabled disabled:text-black200'>
              추가
              <Icon name='plus' color={isSurveyOverMax ? 'black200' : 'black400'} size={16} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
});

export default SurveyContentForm;
