import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import type { FilePickerHandle } from 'afterdoc-saas-web/shared/components/FilePicker/FilePicker';
import { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export type LocalImage = {
  id: string;
  name: string;
  size: number;
  type: string;
  lastModified: number;
  blobUrl: string;
};

export type ServerImage = {
  id: string;
  url: string;
};

export type ImageType = LocalImage | ServerImage;

export function isLocalImage(image: ImageType): image is LocalImage {
  return 'blobUrl' in image;
}

interface UseImageUploadProps {
  maxFileSizeMB?: number; // 사용자 정의 파일 크기 제한 (MB)
  maxImages?: number; // 사용자 정의 이미지 개수 제한
}

export function useUploadMultipleImages({
  maxFileSizeMB = 5, // 기본값: 5MB
  maxImages = 10, // 기본값: 최대 10장
}: UseImageUploadProps = {}) {
  const [images, setImages] = useState<ImageType[]>([]);
  const filePickerRef = useRef<FilePickerHandle>(null);

  const resetImages = () => {
    for (const image of images) {
      if (isLocalImage(image)) {
        URL.revokeObjectURL(image.blobUrl);
      }
    }
    setImages([]);
  };

  const handleFileChange = (files: File[]) => {
    const validFiles = files.filter((file) => file.size <= maxFileSizeMB * 1024 * 1024);
    const invalidFiles = files.filter((file) => file.size > maxFileSizeMB * 1024 * 1024);

    if (invalidFiles.length > 0) {
      toastService.errorMsg({
        text: `${maxFileSizeMB}MB 이하의 이미지만 업로드할 수 있습니다.`,
      });
    }

    if (images.length + validFiles.length > maxImages) {
      toastService.errorMsg({
        text: `이미지는 최대 ${maxImages}장까지 등록할 수 있습니다.`,
      });
      validFiles.splice(maxImages - images.length);
    }

    const newImages = validFiles.map((file) => ({
      id: uuidv4(),
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
      blobUrl: URL.createObjectURL(file),
    }));

    setImages((prevImages) => [...prevImages, ...newImages].slice(0, maxImages));
  };

  const handleRemoveImage = (index: number) => {
    const newImages = [...images];
    const imageToRemove = newImages[index];
    if (isLocalImage(imageToRemove)) {
      URL.revokeObjectURL(imageToRemove.blobUrl);
    }
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const handleAttachMultipleImages = () => {
    filePickerRef.current?.clickMultiple();
  };

  const handleAttachSingleImage = () => {
    filePickerRef.current?.clickSingle();
  };

  return {
    images,
    setImages,
    handleFileChange,
    handleRemoveImage,
    handleAttachMultipleImages,
    handleAttachSingleImage,
    filePickerRef,
    resetImages,
  };
}
