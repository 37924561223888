import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiServiceSettingsElCountriesParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { KOREA_COUNTRY_CODE_ID } from 'afterdoc-saas-web/shared/constants/country-code-id';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { COUNTRY_LIMIT } from 'afterdoc-saas-web/templates/CustomerManagement/constants/country-limit';
import { filterWrapperClassName } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableHeader/constants/class';
import { Fragment, forwardRef, useMemo, useState } from 'react';

const fetchCountriesList = async (params: ApiServiceSettingsElCountriesParams) => {
  const response = await apiClient.v3.apiServiceSettingsElCountries(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};
const NationalityFilter = forwardRef<
  HTMLDivElement,
  { value: string | undefined; onValueChange?: (value: string | undefined) => void }
>((props, ref) => {
  const { hospitalID } = useSelectedHospitalInfo();

  const { data: countriesList } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiServiceSettingsElCountries,
      { hospitalID, limit: COUNTRY_LIMIT },
    ] as const,
    queryFn: ({ queryKey }) => fetchCountriesList(queryKey[1]),
  });

  const [checkState, setCheckState] = useState<{ [nationalityId: string]: boolean } | undefined>(
    () => {
      if (!props.value) return undefined;
      const initialState: { [nationalityId: string]: boolean } = {};
      for (const cur of props.value.split(',')) {
        initialState[cur] = true;
      }
      return initialState;
    },
  );

  const nationalities = useMemo(() => {
    return countriesList
      .map((country) => ({
        nationalityId: country.countryId,
        name: country.koreanCountryName,
      }))
      .filter((country) => country.nationalityId !== KOREA_COUNTRY_CODE_ID);
  }, [countriesList]);

  const handleCheckboxChange = (nationalityId: string) => {
    const newState = { ...checkState, [nationalityId]: !checkState?.[nationalityId] };
    setCheckState(newState);

    const selectedNationalities = Object.keys(newState).filter((key) => newState[key]);

    if (
      selectedNationalities.length === 0 ||
      selectedNationalities.length === nationalities?.length
    ) {
      props.onValueChange?.(undefined);
    } else {
      props.onValueChange?.(selectedNationalities.join(','));
    }
  };

  return (
    <div className={`${filterWrapperClassName} h-[200px] w-[300px]`} ref={ref}>
      <Scrollbar disabledX>
        <div className='p-16'>
          <div className='mb-10 text-Caption9 text-black200'>
            *체크한 값에 해당하는 고객만 표시됩니다.
          </div>
          {nationalities?.map((nationality) => (
            <Fragment key={nationality.nationalityId}>
              {nationality.nationalityId && (
                <Checkbox
                  key={nationality.nationalityId}
                  label={
                    <FilledTag className='cursor-pointer' tagSize='small' maxTextLength={25}>
                      {nationality.name}
                    </FilledTag>
                  }
                  checked={!!checkState?.[nationality.nationalityId]}
                  onChange={() => {
                    if (!nationality.nationalityId) return;
                    handleCheckboxChange(nationality.nationalityId);
                  }}
                />
              )}
            </Fragment>
          ))}
        </div>
      </Scrollbar>
    </div>
  );
});

export default NationalityFilter;
