import LinkedChatRoomManagerDialog, {
  LINKED_CHAT_MANAGER_DIALOG_ID,
} from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoTitle/components/LinkedChatRoomManagerDialog/components/LinkedChatRoomManagerDialog';
import TextButton from 'afterdoc-design-system/components/Atoms/Button/TextButton';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useServiceSettings } from '@shared/hooks/etc/use-serivce-settings';

interface LinkedChatManagerButtonProps {
  hserviceId: string;
}

export default function LinkedChatRoomManagerButton({ hserviceId }: LinkedChatManagerButtonProps) {
  const { hasKakaoConsultationTalkLinkage } = useServiceSettings();

  if (!hasKakaoConsultationTalkLinkage) return null;

  const buttonClick = () => {
    dialogService.push(<LinkedChatRoomManagerDialog hserviceId={hserviceId} />, {
      id: LINKED_CHAT_MANAGER_DIALOG_ID,
      hasCloseButton: true,
      titleProps: {
        title: '연동된 채팅 관리',
        subTitle:
          '고객과 연결되어 있는 다른 채널의 채팅방을 확인할 수 있습니다.\n연동을 해제하는 경우, 채팅방에서 고객의 정보를 확인할 수 없습니다.',
      },
      width: 400,
      wrapperClassName: 'min-h-[300px] h-fit my-0 overflow-visible',
      onClose: async () => {
        const isShow = await modalService.isShowModal();
        if (!isShow) dialogService.popById(LINKED_CHAT_MANAGER_DIALOG_ID);
      },
    });
  };

  return <TextButton onClick={buttonClick}>연동된 채팅 관리</TextButton>;
}
