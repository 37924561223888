import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';

export default function NoInstagramLinkData() {
  return (
    <div className='flex h-[556px] flex-center flex-col-center gap-16'>
      <Icon name='warning' color='white600' size={48} />
      <div className='flex-col-center gap-6'>
        <h2 className='text-Header14 text-black500'>표시할 내용이 없습니다.</h2>
        <p className='text-Body13 text-black200'>
          [계정 추가] 버튼으로 연동할 계정을 등록할 수 있습니다.
        </p>
      </div>
    </div>
  );
}
