import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiEventHospitalEventElFindSimpleParams } from '@apis/swaggers/swagger-docs';
import { useSuspenseQuery } from '@tanstack/react-query';
import EventListItem from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventListItem';
import { eventListQueryKeyState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/states/event-list-query-key-state';
import ContentNoData from '@templates/Content/containers/ContentManager/shared/components/ContentNoData/ContentNoData';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useAtomValue } from 'jotai/index';
import { useMemo } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchHospitalEventList = async (params: ApiEventHospitalEventElFindSimpleParams) => {
  const response = await apiClient.v3.apiEventHospitalEventElFindSimple(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function EventListBody() {
  const { hospitalID } = useSelectedHospitalInfo();
  const eventListQueryKey = useAtomValue(eventListQueryKeyState);

  const { data: eventList } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiEventHospitalEventElFindSimple,
      { hospitalID, ...eventListQueryKey },
    ] as const,
    queryFn: ({ queryKey }) => fetchHospitalEventList(queryKey[1]),
  });

  const isSearchQueryEntered = useMemo(() => {
    if (eventListQueryKey.keyword) {
      return eventListQueryKey.keyword.length > 0;
    }
    return false;
  }, [eventListQueryKey.keyword]);

  if (!eventList.length) {
    return <ContentNoData isSearchQueryEntered={isSearchQueryEntered} type={'event'} />;
  }

  return (
    <div className='flex flex-col'>
      {eventList.map((item) => (
        <EventListItem key={item._id} data={item} />
      ))}
    </div>
  );
}
