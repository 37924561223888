import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import MultipleTextFieldSelectBoxTagDropdown from 'afterdoc-design-system/components/Organisms/Dropdown/MultipleTextFieldSelectBoxTagDropdown/MultipleTextFieldSelectBoxTagDropdown';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Organisms/Dropdown/TextFieldSelectBoxDropdown/TextFieldSelectBoxDropdown';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  ApiPatientsElDetailData,
  ApiServiceSettingsElCountriesData,
  ApiTreatmentTagsElParams,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import {
  GENDER_DISPLAY,
  GENDER_OPTIONS,
  VISIT_OPTIONS,
} from 'afterdoc-saas-web/shared/constants/options';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import type { APIFormValues } from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/CareAndCustomerInfoInputForm/CareAndCustomerInfoInputForm.container';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

const fetchTreatmentTagsList = async (params: ApiTreatmentTagsElParams) => {
  const response = await apiClient.v3.apiTreatmentTagsEl(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

type CustomerInfoFormContainerProps = {
  countriesList: ApiServiceSettingsElCountriesData['data'];
} & ApiPatientsElDetailData['data'];

export default function CustomerInfoFormContainer({
  countriesList,
  ...patientInfo
}: CustomerInfoFormContainerProps) {
  const { isClosed, nationality, treatmentTags, automationAppliedTreatmentTag } = patientInfo;

  const {
    register,
    setValue,
    getValues,
    formState: { errors, isSubmitted },
  } = useFormContext<APIFormValues>();

  const { hospitalID } = useSelectedHospitalInfo();

  const { data: treatmentTagsList } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiTreatmentTagsEl, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchTreatmentTagsList(queryKey[1]),
  });

  const formatBirthdate = (value: string) => {
    let inputValue = value.replace(/[^0-9]/g, '');

    if (inputValue.length >= 4) {
      inputValue = `${inputValue.slice(0, 4)}-${inputValue.slice(4)}`;
    }
    if (inputValue.length >= 7) {
      inputValue = `${inputValue.slice(0, 7)}-${inputValue.slice(7, 10)}`;
    }

    return inputValue;
  };

  const treatmentTagOptions = useMemo(
    () =>
      treatmentTagsList.treatmentTags?.map((tag) => ({
        name: tag.name,
        id: tag.tagId,
        color: tag.color,
        isSpecial: false,
      })) ?? [],
    [treatmentTagsList],
  );

  const automationAppliedTreatmentTagOption = useMemo(
    () =>
      automationAppliedTreatmentTag
        ? {
            name: automationAppliedTreatmentTag.name,
            id: automationAppliedTreatmentTag.tagId,
            color: automationAppliedTreatmentTag.color,
            isSpecial: automationAppliedTreatmentTag.automationApplied,
          }
        : undefined,
    [automationAppliedTreatmentTag],
  );

  const defaultSelectedTags = useMemo(
    () =>
      treatmentTags?.map((tag) => ({
        name: tag.name,
        id: tag.tagId,
        color: tag.color,
        isSpecial: false,
      })),
    [treatmentTags],
  );

  const defaultSelectedTagsWithAutomationApplied = useMemo(
    () => [
      ...(automationAppliedTreatmentTagOption ? [automationAppliedTreatmentTagOption] : []),
      ...(defaultSelectedTags ?? []),
    ],
    [automationAppliedTreatmentTagOption, defaultSelectedTags],
  );

  return (
    <>
      <div className='grid grid-cols-2 gap-14'>
        {/* 고객명 */}
        <TextInput
          label={{
            isRequired: true,
            children: '고객명',
          }}
          placeholder='예) 홍길동'
          errorText={isSubmitted && (errors.name?.message as string)}
          hasError={isSubmitted && !!errors.name}
          disabled={isClosed}
          {...register('name', { required: '필수값입니다.' })}
        />

        {/* 차트번호 */}
        <TextInput
          errorText={isSubmitted && (errors.chartNumber?.message as string)}
          hasError={isSubmitted && !!errors.chartNumber}
          label={{
            children: '차트번호',
          }}
          placeholder='예) 00000'
          disabled={isClosed}
          {...register('chartNumber', {
            maxLength: {
              value: 40000,
              message: '최대 40000자까지 입력 가능합니다.',
            },
          })}
        />

        {/* 초/재진 */}
        <TextFieldSelectBoxDropdown
          clearableOption={true}
          disabled={isClosed}
          options={VISIT_OPTIONS}
          onSelect={(index) => {
            const isFirstVisit = VISIT_OPTIONS[index] === '초진';
            if (getValues('isFirstVisit') === isFirstVisit) {
              setValue('isFirstVisit', null, {
                shouldDirty: true,
                shouldTouch: true,
              });
              return;
            }
            setValue('isFirstVisit', isFirstVisit, {
              shouldDirty: true,
              shouldTouch: true,
            });
          }}
          selectedIndex={VISIT_OPTIONS.findIndex(
            (option) =>
              option ===
              (getValues('isFirstVisit') !== undefined && getValues('isFirstVisit') !== null
                ? getValues('isFirstVisit')
                  ? '초진'
                  : '재진'
                : null),
          )}
          label={{
            children: '초/재진',
          }}
          placeholder='선택'
        />

        {/* 휴대폰번호 */}
        <TextInput
          disabled={isClosed}
          errorText={isSubmitted && (errors.phoneNumber?.message as string)}
          hasError={isSubmitted && !!errors.phoneNumber}
          label={{
            isRequired: true,
            children: '휴대폰번호',
          }}
          maxLength={15}
          placeholder='예) 01012345678'
          {...register('phoneNumber', {
            required: '필수값입니다.',
            onChange: (e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
            },
          })}
        />

        {/* 생년월일 */}
        <TextInput
          disabled={isClosed}
          errorText={isSubmitted && (errors.birthdate?.message as string)}
          hasError={isSubmitted && !!errors.birthdate}
          label={{
            children: '생년월일',
          }}
          placeholder='예) YYYY-MM-DD'
          maxLength={10}
          {...register('birthdate', {
            onChange: (e) => {
              e.target.value = formatBirthdate(e.target.value);
            },
          })}
          onKeyDown={(e) => {
            if (e.key === 'Backspace' && e.currentTarget.value.endsWith('-')) {
              e.preventDefault();
              e.currentTarget.value = e.currentTarget.value.slice(0, -1);
            }
          }}
        />

        {/* 성별 */}
        <TextFieldSelectBoxDropdown
          clearableOption={true}
          disabled={isClosed}
          options={GENDER_OPTIONS.map((option) => GENDER_DISPLAY[option])}
          onSelect={(index) => {
            if (GENDER_OPTIONS[index] === getValues('gender')) {
              setValue('gender', null, {
                shouldDirty: true,
                shouldTouch: true,
              });
              return;
            }
            setValue('gender', GENDER_OPTIONS[index], {
              shouldDirty: true,
              shouldTouch: true,
            });
          }}
          selectedIndex={GENDER_OPTIONS.findIndex((option) => option === getValues('gender'))}
          label={{
            children: '성별',
          }}
          placeholder='선택'
        />

        {/* 치료태그 */}
        <MultipleTextFieldSelectBoxTagDropdown
          disabled={isClosed}
          placeholder={'예) 도수치료, 리프팅'}
          tagOptions={treatmentTagOptions}
          onSelectedTagsChange={(tags) => {
            const normalTags = tags.filter(
              (tag) => tag.id !== automationAppliedTreatmentTagOption?.id,
            );

            setValue(
              'treatmentTags',
              normalTags.map((tag) => ({
                tagId: tag.id,
                name: tag.name,
                color: tag.color,
              })),
              {
                shouldDirty: true,
                shouldTouch: true,
              },
            );
          }}
          onRemoveTag={(tagId) => {
            if (tagId === automationAppliedTreatmentTagOption?.id) {
              setValue('wantToDeleteAutomationAppliedTreatmentTag', true, {
                shouldDirty: true,
                shouldTouch: true,
              });
            }
          }}
          value={defaultSelectedTagsWithAutomationApplied}
          label={{
            children: '치료태그',
          }}
          className='col-span-2'
          fieldWrapperClassName='max-h-[110px]'
        />

        {/* 국적 */}
        <TextFieldSelectBoxDropdown
          clearableOption={true}
          disabled={isClosed}
          placeholder='선택'
          options={countriesList?.map((country) => country.koreanCountryName ?? '') ?? []}
          onSelect={(index) => {
            if (countriesList?.[index]?.countryId === getValues('nationality')?.id) {
              setValue('nationality', null, {
                shouldDirty: true,
                shouldTouch: true,
              });
              return;
            }

            if (countriesList[index]?.countryId && countriesList[index].koreanCountryName) {
              setValue(
                'nationality',
                {
                  id: countriesList[index].countryId,
                  koreanCountryName: countriesList[index].koreanCountryName,
                },
                {
                  shouldDirty: true,
                  shouldTouch: true,
                },
              );
            }
          }}
          selectedIndex={
            countriesList?.findIndex((country) => country.countryId === nationality?.id) === -1
              ? undefined
              : countriesList?.findIndex((country) => country.countryId === nationality?.id)
          }
          label={{
            children: '국적',
          }}
          wrapperClassName='col-span-2'
        />
      </div>
    </>
  );
}
