import { SHARED_UTILS } from '@shared-utils/utils';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import ImageViewer from 'afterdoc-saas-web/shared/components/ImageViewer/ImageViewer';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { neverCheck } from 'afterdoc-saas-web/shared/utils/never-check';
import { MESSAGE_TYPE_CODE } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/constants/message';
import { chattingRequestState } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/states/chatting-request';
import { REQUEST_ROOM_TYPE_MAP } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingSending/constants/types-codes';
import { useManualSingleData } from 'afterdoc-saas-web/templates/CustomerChat/components/Manual/components/ManualList/components/ManualPanelFolderItem/hooks/use-manual-single-data';
import SendingTopLine from 'afterdoc-saas-web/templates/CustomerChat/components/Manual/components/SendingTopLine/SendingTopLine';
import SelectedCheckboxInImg from 'afterdoc-saas-web/templates/CustomerChat/components/Manual/components/components/SelectedCheckboxInImg';
import { ROOM_TYPE_MAP } from 'afterdoc-saas-web/templates/CustomerChat/constants/ROOM_TYPE_MAP';
import {
  chatRoomHserviceIDSelector,
  chatRoomIDSelector,
  chatRoomParentHServiceIDSelector,
  roomTypeSelector,
} from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtomValue, useSetAtom } from 'jotai';
import { type MouseEvent, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isUsageHaltedDisabledState } from '../../../../../../../../../../states/is-usage-halted-disabled';
import { NoCautionImages } from '../NoCautionImages';
import { truncateFileName } from './functions/splitFileName';

type CautionImagesSelectProps = {
  treatmentTagId: string;
};

type PostSendCautionImagesParams = {
  chatRoomID: string;
  patientId: string;
  hospitalID: string;
  treatmentTagId: string;
  selectedImageIndices: number[];
};

const postSendCautionImages = async ({
  chatRoomID,
  patientId,
  hospitalID,
  treatmentTagId,
  selectedImageIndices,
}: PostSendCautionImagesParams) => {
  const newParams = {
    targetChatRoomID: chatRoomID,
    targetPatientID: patientId,
    hospitalID,
    treatmentTagID: treatmentTagId,
    selectedImageIndices: selectedImageIndices,
  };
  const { data } = await apiClient.v3.apiManualsElTreatmentCautionsChat(newParams);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export default function CautionImagesSelect({ treatmentTagId }: CautionImagesSelectProps) {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const chatRoomParentHServiceID = useAtomValue(chatRoomParentHServiceIDSelector);
  const chatRoomHserivceID = useAtomValue(chatRoomHserviceIDSelector);
  const roomType = useAtomValue(roomTypeSelector);
  const isUsageHaltedDisabled = useAtomValue(isUsageHaltedDisabledState);

  const { hospitalID } = useSelectedHospitalInfo();
  const {
    data: { treatmentCautionImages, fileUpdatedAt },
    isLoading,
  } = useManualSingleData({ treatmentTagId });

  const [isDisabledSendButton, setIsDisabledSendButton] = useState(false);
  const [selectedImageIndices, setSelectedImagesIndices] = useState<number[]>([]);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedViewImageIndex, setSelectedViewImageIndex] = useState(-1);

  const setReq = useSetAtom(chattingRequestState);

  const isData = treatmentCautionImages.length > 0;
  const cautionImages = treatmentCautionImages?.map(({ url }) => url) ?? [];
  const treatmentCautionImageObjects =
    treatmentCautionImages?.map(({ url, fileName }) => {
      return { id: fileName, fileName, url, sentAt: fileUpdatedAt };
    }) ?? [];

  const toggleSelectImage = (e: MouseEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();
    setSelectedImagesIndices((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((i) => i !== index)
        : [...prevSelected, index],
    );
  };

  const handleOpenImageViewer = (e: MouseEvent<HTMLDivElement>, index: number) => {
    e.stopPropagation();

    setSelectedViewImageIndex(index);
    setIsImageViewerOpen(true);
  };

  const handleClose = () => {
    setSelectedViewImageIndex(-1);
    setIsImageViewerOpen(false);
  };

  const sendCautionImagesToChat = async () => {
    if (!chatRoomID) return;
    if (!chatRoomParentHServiceID) return;
    if (roomType === null) throw new Error('Room type is null');

    setIsDisabledSendButton(true);

    const mockingPayload = {
      chatRoomID,
      userType: 0,
      userName: '',
      userRealName: '',
      userID: '',
      jobType: '',
      fileIDs: [''],
      type: MESSAGE_TYPE_CODE.IMAGE_MOCKING,
      photoPath: '',
      mages: selectedImageIndices,
    };

    switch (roomType) {
      case ROOM_TYPE_MAP.kakao:
        {
          const imagesDataWithMockingType = selectedImageIndices.map(() => {
            return {
              ...mockingPayload,
              _id: uuidv4(),
            };
          });
          setReq({
            type: REQUEST_ROOM_TYPE_MAP.KAKAO.IMAGES_FILES_TYPE,
            payload: imagesDataWithMockingType,
          });
        }

        break;
      case ROOM_TYPE_MAP.instagram:
      case ROOM_TYPE_MAP.manager:
      case ROOM_TYPE_MAP.afterdoc: {
        setReq({
          type: REQUEST_ROOM_TYPE_MAP.AFTERDOC.IMAGE_TYPE,
          payload: mockingPayload,
        });
        break;
      }
      default:
        roomType satisfies never;
        neverCheck(roomType);
    }

    const { isSentSuccess } = await postSendCautionImages({
      chatRoomID,
      patientId: roomType === ROOM_TYPE_MAP.kakao ? chatRoomHserivceID : chatRoomParentHServiceID,
      hospitalID,
      treatmentTagId,
      selectedImageIndices,
    });

    isSentSuccess && setSelectedImagesIndices([]);
    setIsDisabledSendButton(false);
  };

  const isChatRoomNotNull = chatRoomID;

  return (
    <div className='flex flex-col'>
      {isData && isChatRoomNotNull && !!selectedImageIndices.length && (
        <SendingTopLine
          selectedLength={selectedImageIndices.length}
          maxSelectNumber={10}
          sendFunction={sendCautionImagesToChat}
          isDisabled={isDisabledSendButton || isUsageHaltedDisabled}
        />
      )}

      <div className='mx-auto grid grid-cols-2 justify-items-start gap-x-20 gap-y-10'>
        {isLoading && (
          <div className='col-span-2 flex h-[224px] w-full'>
            <BaseLoading />
          </div>
        )}
        {!isData && !isLoading && <NoCautionImages />}
        {isData &&
          treatmentCautionImages.map(({ fileName, url }, index) => {
            const isSelected = selectedImageIndices.includes(index);
            return (
              <div key={url} className='flex flex-col'>
                <div
                  key={url}
                  className={`relative inline-block rounded-r10 ${isChatRoomNotNull && 'cursor-pointer'} overflow-hidden border border-1 ${isSelected ? 'border-blue500' : 'border-white600'}`}
                  onClick={(e) => isChatRoomNotNull && toggleSelectImage(e, index)}>
                  <img
                    src={url}
                    alt='주의사항 이미지'
                    className='h-[160px] w-[160px] rounded-r10 object-cover'
                  />

                  {isChatRoomNotNull && <SelectedCheckboxInImg isSelected={isSelected} />}

                  <div
                    className='absolute right-2 bottom-2 flex h-32 w-32 flex-center cursor-pointer bg-black200'
                    onClick={(e) => handleOpenImageViewer(e, index)}
                    style={{ borderRadius: '6px' }}>
                    <Icon name='magnify-scan' size={20} color='white50' />
                  </div>
                </div>
                <span className='my-10 line-clamp-1 text-Body13 text-black700'>
                  {truncateFileName(fileName)}
                </span>
              </div>
            );
          })}
      </div>
      {isImageViewerOpen && (
        <ImageViewer
          selectedIndex={selectedViewImageIndex}
          imageUrls={cautionImages}
          imageObjects={treatmentCautionImageObjects}
          onClose={handleClose}
        />
      )}
    </div>
  );
}
