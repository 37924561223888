import {
  type InfiniteData,
  type UseInfiniteQueryResult,
  useQueryClient,
} from '@tanstack/react-query';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  ApiPatientsElTemporaryListOrSearchData,
  ApiPatientsElTemporaryListOrSearchParams,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { nullIfEmpty } from 'afterdoc-saas-web/shared/utils/null-if-empty';
import { TOTAL_PATIENT_ROW_COUNT } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/constants/total-patient-row-count';
import { useResetTableJotaiStates } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/hooks/use-reset-table/hooks/use-reset-table-jotai-states';
import { customerTableDisplayModeState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-display-mode';
import type { CustomerManagementTemporaryAPIFormValues } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import { createInitialRows } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/utils/create-initial-rows';
import { clickedPanelIndexState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/panel';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { SHARED_UTILS } from 'utils/utils';

export const fetchPatientsTemporaryList = async ({
  pageParam = undefined,
  ...params
}: {
  pageParam?: string;
} & ApiPatientsElTemporaryListOrSearchParams) => {
  const response = await apiClient.v3.apiPatientsElTemporaryListOrSearch({
    cursor: pageParam,
    ...params,
  });
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useResetTable = () => {
  const queryClient = useQueryClient();

  const {
    reset,
    formState: { isDirty },
  } = useFormContext<{ rows: CustomerManagementTemporaryAPIFormValues }>();

  const { hospitalID } = useSelectedHospitalInfo();
  const [customerTableDisplayMode] = useAtom(customerTableDisplayModeState);
  const clickedPanelIndex = useAtomValue(clickedPanelIndexState);

  const { resetTableJotaiStates } = useResetTableJotaiStates();

  const revertTableData = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEY.apiPatientsElTemporaryListOrSearch,
        { hospitalID, limit: TOTAL_PATIENT_ROW_COUNT },
      ] as const,
    });

    const newData: UseInfiniteQueryResult<
      InfiniteData<ApiPatientsElTemporaryListOrSearchData['data']>,
      Error
    >['data'] = await queryClient.fetchInfiniteQuery({
      queryKey: [
        QUERY_KEY.apiPatientsElTemporaryListOrSearch,
        { hospitalID, limit: TOTAL_PATIENT_ROW_COUNT },
      ] as const,
      queryFn: ({ queryKey }) => fetchPatientsTemporaryList(queryKey[1]),
      initialPageParam: undefined,
      getNextPageParam: (lastPage: ApiPatientsElTemporaryListOrSearchData['data']) =>
        lastPage.hasNextPage ? (lastPage.nextCursor as unknown as undefined) : null,
    });

    if (!newData?.pages?.[0].patients?.length) {
      reset({
        rows: createInitialRows(hospitalID),
      });
    } else {
      const allPatients = newData?.pages.flatMap((page) =>
        page.patients?.map((patient) => ({
          order: patient.order,
          patientId: patient.patientId,
          hasToBlockSendingMessage: patient.hasToBlockSendingMessage,
          name: nullIfEmpty(patient.name),
          chartNumber: nullIfEmpty(patient.chartNumber),
          phoneNumber: nullIfEmpty(patient.phoneNumber),
          birthdate: nullIfEmpty(patient.birthdate),
          isFirstVisit: patient.isFirstVisit,
          gender: patient.gender,
          treatmentTagIds: patient.treatmentTags?.map((tag) => tag.tagId),
          nationalityId: patient.nationality?.id,
        })),
      ) as CustomerManagementTemporaryAPIFormValues;

      reset(
        {
          rows: createInitialRows(hospitalID, allPatients),
        },
        {
          keepDefaultValues: false,
          keepDirty: false,
          keepErrors: false,
          keepDirtyValues: false,
          keepTouched: false,
          keepSubmitCount: false,
        },
      );
    }
  }, [queryClient, hospitalID, reset]);

  const resetTable = useCallback(
    async ({
      mode = 'VIEWER',
      isFinalSave = false,
    }: {
      mode?: Parameters<typeof resetTableJotaiStates>[0];
      isFinalSave?: boolean;
    } = {}) => {
      if (clickedPanelIndex === 1) {
        return;
      }

      resetTableJotaiStates(mode);

      if (!isFinalSave) {
        toastService.successMsg({
          text: '고객정보 수정이 취소되었습니다.',
        });
      }
    },

    [clickedPanelIndex, resetTableJotaiStates],
  );

  const hasChange = useMemo(
    () => isDirty && (clickedPanelIndex === 0 ? customerTableDisplayMode === 'EDITOR' : true),
    [isDirty, customerTableDisplayMode, clickedPanelIndex],
  );

  return { resetTable, hasChange, revertTableData };
};
