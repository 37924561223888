import FullLoading from 'afterdoc-saas-web/shared/components/FullLoading/FullLoading';
import CustomerManagementEditableTableContainer from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/CustomerManagementEditableTable.container';
import UnTaggedCustomerManagementEditableTableContainer from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/UnTaggedCustomerManagementEditableTable.container';
import { clickedPanelIndexState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/panel';
import { selectedCardInfoState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/ControlPanel/containers/OngoingConsultingAutomation/state/selected-card-info';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { Suspense, useEffect } from 'react';
import CustomerManagementReadOnlyTableContainer from '../../../CustomerManagement/containers/CustomerManagementReadOnlyTable/CustomerManagementReadOnlyTable.container';
import { TABLE_TAB_ITEMS } from './constants/tab-items';
import { tabsState } from './states/tabs';

export default function CustomerManagementTableContainer() {
  // 고객정보 관리 / 치료태그 미입력
  const clickedPanelIndex = useAtomValue(clickedPanelIndexState);
  const resetSelectedTab = useResetAtom(tabsState);

  // 고객정보 관리 / 전체 고객
  const selectedTab = useAtomValue(tabsState);

  // 적용 중인 상담자동화 카드 정보
  const selectedCardInfo = useAtomValue(selectedCardInfoState);

  const isCustomerInfoManagementTab = selectedTab === TABLE_TAB_ITEMS[0].value;
  const isAllCustomersTab = selectedTab === TABLE_TAB_ITEMS[1].value;

  useEffect(() => {
    if (clickedPanelIndex !== 0) {
      resetSelectedTab();
    }
  }, [clickedPanelIndex]);

  useEffect(() => {
    if (selectedCardInfo) {
      resetSelectedTab();
    }
  }, [selectedCardInfo]);

  // 적용 중인 상담자동화 적용 환자 목록
  if (selectedCardInfo) {
    return (
      <Suspense fallback={<FullLoading />}>
        <CustomerManagementReadOnlyTableContainer />
      </Suspense>
    );
  }

  // 고객정보 관리 패널
  if (clickedPanelIndex === 0) {
    // 고객정보 관리 탭
    if (isCustomerInfoManagementTab) {
      return (
        <Suspense fallback={<FullLoading />}>
          <CustomerManagementEditableTableContainer />
        </Suspense>
      );
    }

    // 전체 고객 탭 (읽기전용)
    if (isAllCustomersTab) {
      return (
        <Suspense fallback={<FullLoading />}>
          <CustomerManagementReadOnlyTableContainer />
        </Suspense>
      );
    }
  }

  // 치료태그 미입력 패널
  if (clickedPanelIndex === 1) {
    return (
      <Suspense fallback={<FullLoading />}>
        <UnTaggedCustomerManagementEditableTableContainer />
      </Suspense>
    );
  }

  return <FullLoading />;
}
