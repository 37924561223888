import type { HospitalEventFindSimple } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useMemo } from 'react';

type UseGetCalculatedResult = Pick<
  HospitalEventFindSimple,
  'price' | 'salePrice' | 'saleRate' | 'saleType'
>;

export const useGetCalculatedResult = ({
  price = 0,
  salePrice = 0,
  saleRate = 0,
  saleType,
}: UseGetCalculatedResult) => {
  const formattedPriceDisplay = useMemo(() => {
    const isPriceValid = saleType === 'price' && salePrice !== 0;
    const isRateValid = saleType === 'rate' && saleRate !== 0;

    return isPriceValid || isRateValid ? `${price.toLocaleString()}원` : '0원';
  }, [saleType, salePrice, saleRate, price]);

  const calculatedPrice = useMemo(() => {
    if (saleType === 'price') {
      return (price - salePrice).toLocaleString();
    }

    if (saleType === 'rate') {
      const discount = (price * saleRate) / 100;
      return (price - discount).toLocaleString();
    }

    return '0';
  }, [saleType, salePrice, saleRate, price]);

  const calculatedRate = useMemo(() => {
    if (saleType === 'rate') return saleRate ? `${saleRate}%` : '0%';
    if (salePrice === 0) return '0%';

    const rate = Math.round((salePrice / price) * 100);
    return `${rate}%`;
  }, [saleType, salePrice, saleRate, price]);

  return { formattedPriceDisplay, calculatedPrice, calculatedRate };
};
