import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';

export default function LinkedChannelsNoData() {
  return (
    <div className='flex size-full flex-center'>
      <div className='h-[100px] flex-center'>
        <NoData
          iconProps={{
            name: 'warning',
            size: 48,
            color: 'white600',
          }}
          title='이용 중인 채팅방이 없습니다.'
        />
      </div>
    </div>
  );
}
