import TextInput, {
  type TextInputLabelTextProps,
} from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import type { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';

const baseLabelProps: TextInputLabelTextProps = {
  isRequired: true,
  children: '휴대폰번호',
  width: 70,
  position: 'horizontal',
};

type EditPhoneNumProps = {
  isAlwaysHasErrorSpace?: boolean;
};

export default function EditPhoneNum({ isAlwaysHasErrorSpace = false }: EditPhoneNumProps) {
  const { setValue, register, watch } = useFormContext();

  const phone = watch('phone');

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    value = value.replace(/[^0-9]/g, '');
    setValue('phone', value);
  };

  const isFormValid = phone.length > 0;

  return (
    <TextInput
      id='phone'
      placeholder='연락받으실 휴대폰번호'
      className='items-start justify-between'
      label={baseLabelProps}
      inputWrapperClassName='flex justify-end'
      inputClassName='w-[268px]'
      maxLength={15}
      hasError={!isFormValid}
      isAlwaysHasErrorSpace={isAlwaysHasErrorSpace}
      errorTextClassName='ml-4'
      errorTextPosition='vertical'
      errorText={isFormValid ? '' : '연락처를 입력해 주세요.'}
      {...register('phone', {
        required: true,
        onChange: handlePhoneChange,
      })}
    />
  );
}
