import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useChatMessageContext } from '../../ChatMessageContext';
import { MessageDialogContent } from '../components/MessageDialogContent';
import TranslatedMessageBundle from './components/TranslatedMessageBundle/TranslatedMessageBundle';

export default function TranslateTextMessage({
  isLeftTimeout,
  restartTimer,
}: {
  isLeftTimeout: boolean;
  restartTimer: () => void;
}) {
  const {
    isOverLength,
    isMine,
    contentParts,
    translatedContent,
    retranslatedContent,
    isLeft,
    content,
    error,
    status,
    isTranslated,
    messageWidthStyle,
  } = useChatMessageContext();
  const messageWidthStyleWithFitContent = {
    ...messageWidthStyle,
    width: 'fit-content',
  };
  const first = isLeft ? contentParts : [translatedContent ?? content];
  const second = isLeft ? [translatedContent ?? '번역 없음'] : [retranslatedContent ?? content];

  const handleModalOpen = () => {
    dialogService.push(
      <MessageDialogContent>
        <TranslatedMessageBundle
          first={first}
          second={second}
          isLeftError={isLeftError}
          errorMessage={errorMessage}
          restartTimer={restartTimer}
          isTruncate={false}
          isTranslated={isTranslated}
          status={status}
        />
      </MessageDialogContent>,
      {
        hasCloseButton: true,
        titleProps: {
          title: '전체보기',
        },
      },
    );
  };

  //왼쪽&&timeout error 또는 번역 실패일 경우
  const isLeftError =
    isLeft && (isLeftTimeout || (isTranslated !== undefined && status === 'failed'));
  const errorMessage =
    error && typeof error.message === 'string' ? error.message : '번역에 실패했습니다.';
  return (
    <div
      className={`flex flex-col-center gap-10 rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
      style={messageWidthStyleWithFitContent}>
      <TranslatedMessageBundle
        first={first}
        second={second}
        isLeftError={isLeftError}
        errorMessage={errorMessage}
        restartTimer={restartTimer}
        isTranslated={isTranslated}
        status={status}
      />
      {!error && isOverLength && (
        <OutlinedButton
          className='w-full border-black200 px-20 py-6 text-black200'
          btnColor='secondary'
          onClick={handleModalOpen}>
          전체보기
        </OutlinedButton>
      )}
    </div>
  );
}
