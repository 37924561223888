import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import type { AlgorithmMessage } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import WorkoutVideoDetailContentDialog, {
  WORKOUT_VIDEO_DETAIL_CONTENT_DIALOG_ID,
} from 'afterdoc-saas-web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/components/WorkoutVideoContent/components/WorkoutVideoDetailContentDialog/WorkoutVideoDetailContentDialog';
import { Suspense } from 'react';

interface WorkoutVideoContentProps {
  workoutVideo: NonNullable<NonNullable<AlgorithmMessage['content']>['workoutVideo']>;
}

export default function WorkoutVideoContent({
  workoutVideo: { videoContentHospitalID, thumbnailUrl, title, subtitle },
}: WorkoutVideoContentProps) {
  const onClickWorkoutVideo = () => {
    if (!videoContentHospitalID) return;

    dialogService.push(
      <Suspense>
        <WorkoutVideoDetailContentDialog videoContentHospitalID={videoContentHospitalID} />
      </Suspense>,
      {
        id: WORKOUT_VIDEO_DETAIL_CONTENT_DIALOG_ID,
        hasCloseButton: true,
        width: 400,
        onClose: () => {
          dialogService.popById(WORKOUT_VIDEO_DETAIL_CONTENT_DIALOG_ID);
        },
      },
    );
  };

  return (
    <div
      className='flex cursor-pointer items-center gap-10 rounded-r16 border border-white400 p-10'
      onClick={onClickWorkoutVideo}>
      {!!thumbnailUrl && (
        <img
          src={thumbnailUrl}
          alt='홈케어콘텐츠 썸네일'
          className={customTwMerge('h-[64px] w-[64px] rounded-[8px] object-cover')}
        />
      )}
      <div className='flex w-[130px] flex-col justify-center gap-4'>
        {subtitle && <div className='line-clamp-2 text-Body10 text-black500'>{subtitle}</div>}
        {title && <div className='line-clamp-2 text-Header12 text-black500'>{title}</div>}
      </div>
    </div>
  );
}
