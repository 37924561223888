import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ElFindLinkedChatroomsHandlerParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';

const fetchLinkedChatRoomsForMoving = async (params: ElFindLinkedChatroomsHandlerParams) => {
  const { data } = await apiClient.v3.elFindLinkedChatroomsHandler(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

/*백엔드와 합의 결과, 상세 조회의 캐시의 메리트가 적고 고객상담 등 다른 페이지에 일관적인 hserviceID(키)를 보장하기 어려우며
  심지어 parentHServiceID를 가지고 있지 않은 경우도 발생(고객등록 버튼 클릭으로 연동시), 이 경우 여러 상황에 따른 push를 추가해야하는 단점 발생
  또한, 채팅 시작시 상담종료에서 시작으로 변경되는 상태 등 다양한 input에 따라 영향을 받는 상황도 발생, 그래서 캐싱을 사용하지 않음*/
export const useLinkedChatRoomsForMoving = (hserviceID: string) => {
  const { data } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.elFindLinkedChatroomsHandler,
      {
        hserviceID,
      },
    ] as const,
    queryFn: ({ queryKey }) => fetchLinkedChatRoomsForMoving(queryKey[1]),
    //백엔드와 합의해 정책적으로 결정한 캐싱 미사용
    staleTime: 0,
    gcTime: 0,
  });

  return { linkedChannels: data };
};
