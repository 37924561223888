export const CUSTOMER_CHAT_ERROR = {
  KAKAO: {
    EXPIRED: {
      CODE: '-502',
      TEXT: '카카오톡 세션 만료 또는 고객정보를 알 수 없어 메시지를 보낼 수 없습니다.',
    },
    RESPONSE_ERROR_410: {
      CODE: '-410',
      TEXT: '카카오톡 서버의 문제로 메시지를 보낼 수 없습니다.',
    },
    RESPONSE_ERROR_600: {
      CODE: '-600',
      TEXT: '카카오톡 서버의 문제로 메시지를 보낼 수 없습니다.',
    },
    API_ERROR: {
      CODE: '5850',
      TEXT: '카카오 채팅 api 실패',
    },
  },
  AFTERDOC: {
    USAGE_HALTED_DISABLED_SENDING: {
      TEXT: '고객정보를 알 수 없어 메시지를 보낼 수 없습니다.',
    },
  },
};
