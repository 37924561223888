import { useHospitalNotice } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/hooks/use-hospital-notice';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';

interface NoticeActionPanelProps {
  id: string;
  handleAction: (isFocus: boolean) => void;
}

const showDeleteConfirmation = (onConfirm: () => void, onClose: () => void) => {
  modalService.defaultWarning({
    title: '공지사항을 삭제할까요?',
    contents: '삭제한 공지사항은 복구할 수 없습니다.',
    onConfirm,
    onClose,
  });
};

export default function NoticeActionPanel({ id, handleAction }: NoticeActionPanelProps) {
  const { removeNoticeAction } = useHospitalNotice();

  const { authorizationTypeID } = useUserInfo();

  const handleModalConfirm = () => {
    removeNoticeAction(id);
  };

  const handleModalClose = () => {
    handleAction(false);
  };

  const handleRemoveButtonClick = () => {
    handleAction(true);
    showDeleteConfirmation(handleModalConfirm, handleModalClose);
  };

  return (
    <>
      {authorizationTypeID?.canDeleteContent && (
        <div className='flex h-full flex-grow items-center justify-end'>
          <ContainedButton
            className='h-22 w-50'
            buttonSize='small'
            btnColor='secondary'
            onClick={handleRemoveButtonClick}>
            삭제
          </ContainedButton>
        </div>
      )}
    </>
  );
}
