import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import FullLoading from 'afterdoc-saas-web/shared/components/FullLoading/FullLoading';
import HospitalManualContent from 'afterdoc-saas-web/templates/HospitalManual/components/HospitalManualContent/HospitalManualContent';
import HospitalManualPanel from 'afterdoc-saas-web/templates/HospitalManual/components/HospitalManualPanel/HospitalManualPanel';
import { selectedTagIdState } from 'afterdoc-saas-web/templates/HospitalManual/components/HospitalManualPanel/states/selected-tag-id';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';

export default function HospitalManualContainer() {
  const selectedTagId = useAtomValue(selectedTagIdState);
  return (
    <main className='grid h-full grid-cols-[360px,1fr] bg-blueLight'>
      <HospitalManualPanel />

      {selectedTagId ? (
        <Suspense
          fallback={
            <div className='mx-auto h-full w-[1000px] bg-white50 px-[100px]'>
              <FullLoading />
            </div>
          }>
          <HospitalManualContent />
        </Suspense>
      ) : (
        <div className='mx-auto w-[1000px] flex-center bg-white50 px-[100px]'>
          <NoData
            title='표시할 내용이 없습니다.'
            iconProps={{
              name: 'warning',
              size: 40,
              color: 'white600',
            }}
          />
        </div>
      )}
    </main>
  );
}
