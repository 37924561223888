import type { QUERY_KEY } from '@apis/swaggers/query-key';
import type {
  ApiEventHospitalAftercareElCountParams,
  ApiEventHospitalEventElCountParams,
  ApiNoticeHospitalElCountParams,
  ApiPopupHospitalElCountParams,
} from '@apis/swaggers/swagger-docs';
import { useQuery } from '@tanstack/react-query';
import SearchInput from 'afterdoc-design-system/components/Atoms/Input/SearchInput';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { type WritableAtom, useAtom, useSetAtom } from 'jotai/index';
import type { PrimitiveAtom } from 'jotai/vanilla/atom';
import { type KeyboardEvent, useEffect, useState } from 'react';

type QueryKeyType =
  | typeof QUERY_KEY.apiEventHospitalEventElCount
  | typeof QUERY_KEY.apiEventHospitalAftercareElCount
  | typeof QUERY_KEY.apiNoticeHospitalElCount
  | typeof QUERY_KEY.apiPopupHospitalElCount;

type FetchCountParamType =
  | ApiEventHospitalEventElCountParams
  | ApiEventHospitalAftercareElCountParams
  | ApiNoticeHospitalElCountParams
  | ApiPopupHospitalElCountParams;

interface ContentSearchHeaderProps {
  queryKey: QueryKeyType;
  fetchCount: (param: FetchCountParamType) => Promise<number>;
  totalCountState: PrimitiveAtom<number>;
  keywordState: WritableAtom<string | undefined, [newKeyword?: string], void>;
  inputPlaceholder: string;
}

export default function ContentSearchHeader({
  queryKey,
  fetchCount,
  totalCountState,
  keywordState,
  inputPlaceholder,
}: ContentSearchHeaderProps) {
  const { hospitalID } = useSelectedHospitalInfo();

  const [keyword, setKeyword] = useAtom(keywordState);

  const setTotalCount = useSetAtom(totalCountState);

  const [inputValue, setInputValue] = useState<string>();

  const { data: count } = useQuery({
    queryKey: [
      queryKey,
      {
        hospitalID,
        keyword: keyword || undefined,
        isRemoved: false,
      },
    ] as const,
    queryFn: ({ queryKey }) => fetchCount(queryKey[1]),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleDelete = () => {
    setInputValue('');
    setKeyword('');
  };

  const handleKeyDown = async (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      setKeyword(inputValue);
    }
  };

  useEffect(() => {
    setInputValue(keyword);
  }, [keyword]);

  useEffect(() => {
    setTotalCount(count ?? 0);
  }, [count]);

  return (
    <div className='flex w-full items-center gap-20 px-20'>
      <div className='w-[290px] px-20'>
        <span className='text-Header16 text-black500'>{`총 ${count ?? 0}개`}</span>
      </div>
      <div className='flex-grow'>
        <SearchInput
          placeholder={inputPlaceholder}
          className='py-20'
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onDelete={handleDelete}
        />
      </div>
    </div>
  );
}
