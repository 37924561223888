import { useMutation, useQueryClient } from '@tanstack/react-query';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiPatientsElDetailData } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { nullIfEmpty } from 'afterdoc-saas-web/shared/utils/null-if-empty';
import { patientIdState } from 'afterdoc-saas-web/states/patient-id';
import { chatRoomParentHServiceIDSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { type SubmitHandler, useFormContext } from 'react-hook-form';
import type { APIFormValues } from '../../CareAndCustomerInfoInputForm.container';

interface SaveButtonProps {
  disabled?: boolean;
}

type PostReInvitationSMSParams = Parameters<
  typeof apiClient.v3.apiPatientsRegistrationsElReinvitationWithSms
>[0];

const postPatientsInfo = async (
  params: Parameters<typeof apiClient.v3.apiPatientsElUpsertSingle>[0],
) => {
  const response = await apiClient.v3.apiPatientsElUpsertSingle(params);
  return response.data;
};

const postReInvitationSMS = async (params: PostReInvitationSMSParams) => {
  const response = await apiClient.v3.apiPatientsRegistrationsElReinvitationWithSms(params);
  return response;
};

export default function SaveButton({ disabled }: SaveButtonProps) {
  const queryClient = useQueryClient();

  const chatRoomParentHServiceID = useAtomValue(chatRoomParentHServiceIDSelector);

  const {
    handleSubmit,
    getValues,
    formState: { defaultValues, isDirty },
    reset,
  } = useFormContext<APIFormValues>();

  const { hospitalID } = useSelectedHospitalInfo();

  const patientId = useAtomValue(patientIdState);

  const {
    patientId: patientIdFromQuery,
    name,
    automationAppliedTreatmentTag,
    recentChatRoom,
  } = queryClient.getQueryData([
    QUERY_KEY.apiPatientsElDetail,
    { patientId },
  ]) as ApiPatientsElDetailData['data'];

  const updateCustomerSuccess = async () => {
    if (
      !!automationAppliedTreatmentTag?.automationApplied &&
      defaultValues?.wantToDeleteAutomationAppliedTreatmentTag !==
        getValues('wantToDeleteAutomationAppliedTreatmentTag') &&
      getValues('wantToDeleteAutomationAppliedTreatmentTag')
    ) {
      toastService.successMsg({
        text: '상담자동화 태그가 삭제되어 진행중인 상담자동화를 종료하였습니다.',
      });
    } else {
      toastService.successMsg({ text: '고객 정보를 업데이트 했습니다.' });
    }

    queryClient.setQueryData<ApiPatientsElDetailData['data']>(
      [QUERY_KEY.apiPatientsElDetail, { patientId }],
      (oldData) => {
        if (!oldData) return oldData;

        const updatedData = {
          ...oldData,
          name: getValues('name') ?? '',
          phoneNumber: getValues('phoneNumber') ?? '',
          birthdate: getValues('birthdate') ?? '',
          chartNumber: getValues('chartNumber') ?? '',
          gender: getValues('gender') ?? undefined,
          isFirstVisit: getValues('isFirstVisit') ?? undefined,
          nationality: getValues('nationality') ?? undefined,
          treatmentTags: getValues('treatmentTags') ?? undefined,
        } as ApiPatientsElDetailData['data'];

        if (getValues('wantToDeleteAutomationAppliedTreatmentTag')) {
          updatedData.automationAppliedTreatmentTag = undefined;
          updatedData.appliedAutomation = undefined;
        } else {
          updatedData.automationAppliedTreatmentTag = automationAppliedTreatmentTag;
          updatedData.appliedAutomation = automationAppliedTreatmentTag?.automationApplied
            ? oldData.appliedAutomation
            : undefined;
        }

        return updatedData;
      },
    );

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.appliedAutomation, { patientId }],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiPatientsElListOrSearch],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiChatroomElFind],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiPatientsElListOrSearchForUntagged],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiAutomationsElOngoing, { hospitalID }],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiPatientsElUntaggedCount, { hospitalID }],
    });
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEY.chatRoomElFindServiceInformationHandler,
        { chatRoomID: recentChatRoom?.id },
      ],
    });

    // 채팅방 상단에서 고객 상세정보로 이동할 수 있는데 이 경우에는 unmount가 되지 않아 invalidateQueries가 작동하지 않아 refetchQueries를 사용해야 함
    queryClient.refetchQueries({
      queryKey: [
        QUERY_KEY.chattingTopPatientDetailInfo,
        {
          patientId,
          chatRoomID: recentChatRoom?.id,
        },
      ],
    });

    const patientID = chatRoomParentHServiceID ?? patientId;
    queryClient.refetchQueries({
      queryKey: [QUERY_KEY.apiPatientsElDetail, { patientId: patientID }],
    });

    reset(getValues());
  };

  const mutationPostReInvitationSMS = useMutation({
    mutationFn: (params: PostReInvitationSMSParams) => postReInvitationSMS(params),
    onSuccess: (data) => {
      // 성공한 경우
      if (data.data.code === 0) {
        return toastService.successMsg({
          text: '애프터닥 앱 가입 문자를 발송했습니다.',
        });
      }

      // 실패한 경우
      return modalService.defaultWarning({
        id: 're-invitation-sms-fail',
        title: '문자 발송 실패',
        contents: '문자 발송을 실패했습니다. 다시 시도하시겠습니까?',
        buttonText: ['확인', '취소'],
        onConfirm: () => {
          mutationPostReInvitationSMS.mutate({
            hospitalID,
            hserviceID: patientIdFromQuery,
          });
          modalService.popById('re-invitation-sms-fail');
        },
      });
    },
  });

  const mutationPostPatientsInfo = useMutation({
    mutationFn: (data: Parameters<typeof apiClient.v3.apiPatientsElUpsertSingle>[0]) =>
      postPatientsInfo(data),
    onSuccess: (response) => {
      // 성공한 경우
      if (response.code === 0) {
        // 휴대폰번호 변경 시
        if (response.data.isPhoneChanged) {
          return modalService.defaultSuccess({
            title: '휴대폰번호 변경',
            buttonType: 'CUSTOM',
            bottomButtons: (
              <div className='flex gap-8'>
                <OutlinedButton
                  className='w-[136px]'
                  btnColor='blue'
                  onClick={() => {
                    updateCustomerSuccess();
                    modalService.pop();
                  }}>
                  취소
                </OutlinedButton>
                <ContainedButton
                  className='w-[136px]'
                  onClick={() => {
                    updateCustomerSuccess();
                    mutationPostReInvitationSMS.mutate({
                      hospitalID,
                      hserviceID: patientIdFromQuery,
                    });
                    modalService.pop();
                  }}
                  btnColor='blue'>
                  확인
                </ContainedButton>
              </div>
            ),
            contents: (
              <div className='whitespace-pre-wrap'>
                {`휴대폰번호가 변경되었습니다.\n${name}님께 다운로드 문자를 전송할까요?`}
              </div>
            ),
          });
        }

        if (response.data.isUpdatedSuccess) {
          return updateCustomerSuccess();
        }
      }

      // 이미 다른 유저가 해당 전화번호를 점유한 경우
      if (response.code === 2031) {
        return toastService.errorMsg({
          text: response.message,
        });
      }

      // 실패한 경우 - 사용 중인 휴대폰 번호
      if (response.code === 40001) {
        return modalService.defaultSuccess({
          title: '사용 중인 휴대폰번호',
          buttonType: 'CONFIRM',
          buttonText: '확인',
          contents: (
            <div className='whitespace-pre-wrap'>
              {'다른 고객의 휴대폰번호와 동일합니다.\n휴대폰번호를 다시 확인해주세요.'}
            </div>
          ),
        });
      }

      // 나머지 실패
      return toastService.errorMsg({
        text: '고객 정보 업데이트를 실패했습니다.',
      });
    },
    onError: (error) => {
      console.error('Error saving memo:', error);
    },
  });

  const saveButtonDisabled = useMemo(() => {
    return disabled || !isDirty || mutationPostPatientsInfo.isPending;
  }, [disabled, isDirty, mutationPostPatientsInfo.isPending]);

  const onSubmit: SubmitHandler<APIFormValues> = (data) => {
    const {
      name,
      patientId,
      isFirstVisit,
      phoneNumber,
      birthdate,
      chartNumber,
      gender,
      nationality,
      treatmentTags,
      wantToDeleteAutomationAppliedTreatmentTag,
    } = data;

    const convertedData: Parameters<typeof apiClient.v3.apiPatientsElUpsertSingle>[0] = {
      name,
      patientId,
      phoneNumber: phoneNumber?.replace(/-/g, '') ?? null,
      birthdate: birthdate ? birthdate.replace(/-/g, '') : null,
      chartNumber: nullIfEmpty(chartNumber),
      gender,
      isFirstVisit,
      nationalityId: nullIfEmpty(nationality?.id),
      treatmentTagIds: treatmentTags?.map((tag) => tag.tagId),
      wantToDeleteAutomationAppliedTreatmentTag,
    };

    mutationPostPatientsInfo.mutate(convertedData);
  };

  return (
    <div className='mt-20 flex w-full justify-end'>
      <ContainedButton
        disabled={saveButtonDisabled}
        buttonSize='small'
        type='button'
        onClick={handleSubmit(onSubmit)}>
        저장
      </ContainedButton>
    </div>
  );
}
