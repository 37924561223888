import AlgorithmGuide from '@templates/CustomerChat/components/SupportBot/components/AlgorithmGuide/AlgorithmGuide';
import { selectedMessageIDState } from '@templates/CustomerChat/components/SupportBot/states/selected-message-id';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import ButtonTab from 'afterdoc-design-system/components/Atoms/Tabs/components/ButtonTab';
import Tabs from 'afterdoc-design-system/components/Atoms/Tabs/components/Tabs';
import { useAtom, useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useMemo, useState } from 'react';
import { SUPPORT_TABS } from '../../constants/tab';
import { chatRoomHserviceIDSelector, chatRoomIDSelector } from '../../states/selected-chat-room';
import { selectedSupportTabState } from '../ChattingRoom/components/ChattingContainer/components/ChattingContent/states/selected-support-tab';
import { useGetAlgorithmGuide } from './components/AlgorithmGuide/hooks/use-get-algorithm-guide';
import BookmarkList from './components/BookmarkList/BookmarkList';

export const SupportBot = () => {
  // console.count('SupportBot');
  const { data, isLoading: isGuideLoading } = useGetAlgorithmGuide();

  const resetSelectedMessageID = useResetAtom(selectedMessageIDState);

  const hserviceID = useAtomValue(chatRoomHserviceIDSelector);
  const chatRoomID = useAtomValue(chatRoomIDSelector);

  const [selectedTab, setSelectedTab] = useAtom(selectedSupportTabState);

  const [isBookMarkLoading, setIsBookMarkLoading] = useState(false);

  const isConsultationGuide = !!data?.consultationGuide;

  const isShowLoading = useMemo(() => {
    if (selectedTab === SUPPORT_TABS.BOOKMARK) {
      return isBookMarkLoading;
    }
    if (selectedTab === SUPPORT_TABS.GUIDE) {
      return isGuideLoading;
    }
  }, [isGuideLoading, isBookMarkLoading, selectedTab]);

  useEffect(() => {
    setSelectedTab(isConsultationGuide ? SUPPORT_TABS.GUIDE : SUPPORT_TABS.BOOKMARK);
  }, [hserviceID, isConsultationGuide]);

  useEffect(() => {
    resetSelectedMessageID();
  }, [chatRoomID]);

  return (
    <div className='mt-10 flex h-full flex-col gap-10'>
      <Tabs value={selectedTab} onChange={setSelectedTab}>
        {Object.values(SUPPORT_TABS).map((value) => (
          <ButtonTab className='border-b border-b-white400' value={value} label={value} key={value}>
            {value}
          </ButtonTab>
        ))}
      </Tabs>
      <div className='relative flex size-full pb-10'>
        {selectedTab === SUPPORT_TABS.BOOKMARK && (
          <BookmarkList onChangeLoadingState={setIsBookMarkLoading} />
        )}
        {selectedTab === SUPPORT_TABS.GUIDE && !isGuideLoading && <AlgorithmGuide data={data} />}
        {isShowLoading && (
          <div className='absolute inset-0 flex-center bg-white50 opacity-40'>
            <BaseLoading />
          </div>
        )}
      </div>
    </div>
  );
};
