import { useGetStatsChatsMonthly } from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/hooks/use-get-stats-chats-monthly';
import { selectedDateState } from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/states/selected-date';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';

export default function DashBoardUpdateTime() {
  const { data } = useGetStatsChatsMonthly();

  const selectedDate = useAtomValue(selectedDateState);
  const updatedAtWithThisMonthData = data?.find(
    (item) => dayjs(item.registerDate).format('YYYY-MM') === dayjs(selectedDate).format('YYYY-MM'),
  )?.updatedAt;

  return (
    <div className='text-Body11 text-black200'>
      <span>업데이트:</span>&nbsp;
      <span>
        {updatedAtWithThisMonthData
          ? dayjs(updatedAtWithThisMonthData).format('YYYY년 MM월 DD일 HH:mm')
          : '-'}
      </span>
    </div>
  );
}
