import { SHARED_UTILS } from '@shared-utils/utils';
import { useQueryClient } from '@tanstack/react-query';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useChattingList } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingList/hooks/use-chatting-list';
import { useSyncChattingListAndInfoTop } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/CustomerInfoTop/hooks/use-sync-chatting-list-and-info-top';
import {
  chatRoomIDSelector,
  chatRoomParentHServiceIDSelector,
} from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import EditName from './components/EditName';
import EditPhone from './components/EditPhone';

interface IFormInput {
  name: string;
  phone: string;
  countryCodeId: string;
}

export default function EnrollDialog() {
  const queryClient = useQueryClient();

  const chatRoomParentHServiceID = useAtomValue(chatRoomParentHServiceIDSelector);
  const chatRoomID = useAtomValue(chatRoomIDSelector);

  const [isSaving, setIsSaving] = useState(false); // "저장" 버튼 로딩 상태
  const [isSavingAndReserving, setIsSavingAndReserving] = useState(false); // "저장 후 예약 등록하기" 버튼 로딩 상태
  const [isEnrolled, setIsEnrolled] = useState(false); // "저장 후 예약 등록하기" 버튼 로딩 상태

  const { updateChattingListAndInfoTop } = useSyncChattingListAndInfoTop();
  const { hospitalID, usingSaaSReservation } = useSelectedHospitalInfo();
  const { chatRooms } = useChattingList();
  const user = chatRooms.find((item) => item._id === chatRoomID);

  const methods = useForm<IFormInput>({
    defaultValues: {
      name: '',
      phone: '',
      countryCodeId: '66a9d85407a37e99d4ae625f', // 한국 국가코드
    },
  });

  const { watch, handleSubmit, setFocus } = methods;
  const name = watch('name');
  const phone = watch('phone');
  const isFormValid = name?.trim() !== '' && phone.length > 10;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setFocus('name');
  }, []);

  const handleApiCall = async (data: IFormInput) => {
    try {
      const { data: apiResponse } = await apiClient.v3.apiPatientsElLinkKakao({
        pf_id: user?.ikakaoInfo?.pf_id,
        user_key: user?.ikakaoInfo?.user_key,
        hospitalID,
        name: data.name,
        phone: data.phone,
        countryCodeID: data.countryCodeId,
      });
      const res = SHARED_UTILS.api.checkApiResponse(apiResponse);

      if (!res) {
        throw new Error('API error');
      }

      toastService.successMsg({ text: '고객을 등록했습니다.' });
      return true;
    } catch (error) {
      console.error(error);
      methods.setError('name', {
        type: 'manual',
        message: '알 수 없는 오류가 발생했습니다.',
      });
      toastService.errorMsg({ text: '고객 등록을 실패했습니다.' });
      return false;
    }
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setIsSaving(true);
    const success = await handleApiCall(data);
    updateChattingListAndInfoTop();

    setIsSaving(false);

    if (success) {
      dialogService.pop();
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.elFindLinkedChatroomsHandler],
      });
    }
  };

  const onSubmitAndReservation: SubmitHandler<IFormInput> = async (data) => {
    setIsSavingAndReserving(true);
    const success = await handleApiCall(data);

    updateChattingListAndInfoTop();

    setIsSavingAndReserving(false);

    if (success) {
      setIsEnrolled(true);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.elFindLinkedChatroomsHandler],
      });
    }
  };

  //isEnrolled가 true가 변경될 때가 트리거로 들어가면 chatRoomParentHServiceID이 올바른 값으로 최신화되기 전에 트리거가 동작해버림
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isEnrolled && chatRoomParentHServiceID) {
      window.electron?.ipcRenderer.send('Application.showReservationWindow', {
        value: { hserviceID: chatRoomParentHServiceID },
      });

      dialogService.pop();
      setIsEnrolled(false);
    }
  }, [chatRoomParentHServiceID]);

  const isUseReservation = !!usingSaaSReservation;
  const isDisabledSaveAllButton = !isFormValid || isSaving || isSavingAndReserving;

  return (
    <div className='flex w-[384px] flex-center'>
      <FormProvider {...methods}>
        <form autoComplete='off' className={`mx-20 gap-10 mt-${isUseReservation ? 10 : 25}`}>
          <div className='flex w-full flex-col'>
            <span className='text-Body12 text-black500'>
              {
                '저장된 정보는 고객이 확인할 수 있습니다. 정보 입력시 유의해 주세요.\n상세정보는 고객 등록 후 입력할 수 있습니다.'
              }
            </span>
          </div>
          <div className='mt-10 flex w-full flex-col-center'>
            <div className='w-full flex-col-center flex-shrink-0 flex-grow gap-10'>
              <EditName />
              <EditPhone />
            </div>
          </div>
          <div className='my-20 flex flex-col gap-8'>
            <ContainedButton
              type='submit'
              className='h-30 w-full'
              disabled={isDisabledSaveAllButton}
              onClick={handleSubmit(onSubmit)}>
              {isSaving ? '등록중...' : '저장'}
            </ContainedButton>

            {isUseReservation && (
              <OutlinedButton
                className='w-full'
                disabled={isDisabledSaveAllButton}
                onClick={handleSubmit(onSubmitAndReservation)}>
                {isSavingAndReserving ? '등록중...' : '저장 후 예약등록'}
              </OutlinedButton>
            )}

            <ContainedButton
              className='w-full'
              btnColor='secondary'
              onClick={() => {
                dialogService.pop();
              }}>
              취소
            </ContainedButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
