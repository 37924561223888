import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import type {
  ApiLanguageElFindDefaultParams,
  LanguageNameAndAlpha2,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { chatRoomIDSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { translateLanguageState } from 'afterdoc-saas-web/templates/CustomerChat/states/translate-language';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

const getLanguageList = async (params: ApiLanguageElFindDefaultParams) => {
  const { data } = await apiClient.v3.apiLanguageElFindDefault(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

type UseLanguageList = {
  languageID?: LanguageNameAndAlpha2;
  setSelectedIndex: (index: number) => void;
};

export const useLanguageList = ({ languageID, setSelectedIndex }: UseLanguageList) => {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const setTranslateLanguage = useSetAtom(translateLanguageState);

  const { hospitalID } = useSelectedHospitalInfo();
  const params = {
    hospitalID,
  };
  const { data, refetch, isLoading } = useSuspenseQuery({
    queryKey: ['chat-translate-language', hospitalID],
    queryFn: () => getLanguageList(params as ApiLanguageElFindDefaultParams),
  });

  const languageCodeISOAlpha2 = data.find(
    (item) => item?.languageCodeISOAlpha2 === languageID?.languageCodeISOAlpha2,
  )?.languageCodeISOAlpha2;

  const reorderedLanguageList = languageCodeISOAlpha2
    ? [
        ...data.filter((item) => item.languageCodeISOAlpha2 === languageCodeISOAlpha2),
        ...data.filter((item) => item.languageCodeISOAlpha2 !== languageCodeISOAlpha2),
      ]
    : data;

  useEffect(() => {
    if (languageCodeISOAlpha2) {
      setTranslateLanguage(languageCodeISOAlpha2);
    }
  }, [languageCodeISOAlpha2, setTranslateLanguage]);

  useEffect(() => {
    if (languageCodeISOAlpha2) {
      const index = reorderedLanguageList.findIndex(
        (item) => item.languageCodeISOAlpha2 === languageCodeISOAlpha2,
      );
      setSelectedIndex(index);
    }
  }, [languageCodeISOAlpha2, chatRoomID]);

  return {
    languageList: reorderedLanguageList ?? [
      {
        _id: '0',
        englishLanguageName: 'English',
        koreanLanguageName: '영어',
        languageCodeISOAlpha2: 'en',
      },
    ],
    refetch,
    isLoading,
  };
};
