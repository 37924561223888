import { Color } from '@tailwind-base/styles/color';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import type { ApiStatsAlgorithmsConsultationMonthlyElFindData } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { BAR_CHART_OPTIONS } from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/constants/chart-options';
import { useGetAlgorithmsConsultationMonthly } from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/hooks/use-get-algorithms-consultation-monthly';
import { selectedDateState } from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/states/selected-date';
import { calculateMonthOverMonthChange } from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/utils/calculate-month-over-month-change';
import { getMonthRange } from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/utils/get-month-range';
import { getUpDownText } from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/utils/get-up-down-text';
import CardItemLayout from 'afterdoc-saas-web/templates/Automation/containers/Main/shared/components/CardItemLayout';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function AutomatedConsultationCountCardItem() {
  const selectedDate = useAtomValue(selectedDateState);
  const { startDate, endDate } = getMonthRange(selectedDate);

  const { data } = useGetAlgorithmsConsultationMonthly();
  const monthLabels = useMemo(() => {
    const months: { label: string; yearMonth: string }[] = [];
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    let current = start;
    while (current.isBefore(end) || current.isSame(end, 'month')) {
      months.push({
        label: current.format('M'),
        yearMonth: current.format('YYYY-MM'),
      });
      current = current.add(1, 'month');
    }
    return months;
  }, [startDate, endDate]);

  const dataSet: number[] = useMemo(() => {
    return monthLabels.map(({ yearMonth }) => {
      const matchingData: ApiStatsAlgorithmsConsultationMonthlyElFindData | undefined = data?.find(
        (item) => dayjs(item.registerDate).format('YYYY-MM') === yearMonth,
      );
      return matchingData?.chatCount || 0;
    });
  }, [data, monthLabels]);

  const chartData = {
    labels: monthLabels.map(({ label }) => `${label}월`),
    datasets: [
      {
        label: '발생 건수',
        data: dataSet,
        backgroundColor: '#64B5F6',
        barThickness: 16,
        borderRadius: 4,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const lastMonthData =
    data?.find(
      (item) =>
        dayjs(item.registerDate).format('YYYY-MM') ===
        dayjs(selectedDate).subtract(1, 'month').format('YYYY-MM'),
    )?.chatCount ?? 0;

  const thisMonthData =
    data?.find(
      (item) =>
        dayjs(item.registerDate).format('YYYY-MM') === dayjs(selectedDate).format('YYYY-MM'),
    )?.chatCount ?? 0;

  const changeFromLastMonthChatCount = calculateMonthOverMonthChange(lastMonthData, thisMonthData);

  return (
    <CardItemLayout className='flex flex-col gap-24'>
      <TopInfo number={thisMonthData} changeFromLastMonth={changeFromLastMonthChatCount} />
      <div className='mt-4 h-90'>
        <Bar data={chartData} options={BAR_CHART_OPTIONS} height={90} />
      </div>
    </CardItemLayout>
  );
}

interface TopInfoProps {
  number: number;
  changeFromLastMonth: number;
}

function TopInfo({ number, changeFromLastMonth }: TopInfoProps) {
  const textColor =
    changeFromLastMonth === 0
      ? Color.black200
      : changeFromLastMonth > 0
        ? Color.green500
        : Color.red500;

  const bgColor =
    changeFromLastMonth === 0
      ? Color.white500
      : changeFromLastMonth > 0
        ? Color.green100
        : Color.red50;

  return (
    <div className='flex items-start justify-between'>
      <div>
        <div>
          <span className='text-Header12 text-black500'>자동화를 통한 상담발생 건수</span>&nbsp;
          <span className='text-Body12 text-black500'>(월)</span>
        </div>
        <div className='mt-8 flex items-center gap-10'>
          <div className='text-Header16 text-black700'>{number}건</div>
          <FilledTag tagSize='small' textColor={textColor} maxTextLength='full' bgColor={bgColor}>
            {getUpDownText(changeFromLastMonth)}
          </FilledTag>
        </div>
      </div>
      <HoverTooltip
        text={'상담자동화가 적용된 고객 중 해당 월에\n응답을 한 상담 건수를 의미합니다.'}>
        <div className='p-6'>
          <Icon name='info-circle-outline' color='black200' size={20} />
        </div>
      </HoverTooltip>
    </div>
  );
}
