import type { ImageChattingResponse } from 'afterdoc-saas-web/templates/CustomerChat/types';
import { MESSAGE_TYPE_CODE } from '../components/ChattingContent/components/ChattingMessage/constants/message';

export const replaceImageMessage = (
  prev: Array<ImageChattingResponse>,
  res: ImageChattingResponse,
) => {
  const existingMessageIndex = prev.findIndex(
    (item) => item.type === MESSAGE_TYPE_CODE.IMAGE_MOCKING,
  );

  const isExisted = existingMessageIndex !== -1;

  if (isExisted) {
    const isSent = res.isSent;
    if (isSent) {
      prev[existingMessageIndex] = res;
    } else {
      prev[existingMessageIndex] = {
        ...prev[existingMessageIndex],
        _id: res._id,
        status: res.status,
      };
    }
    return [...prev];
  }

  return [res, ...prev];
};
