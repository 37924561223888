import type { KakaoAlimFriendTalkLinkageHandlerBodyRequest } from '@apis/swaggers/swagger-docs';
import { ISO_DATE_TIME_TIME_FORMAT } from '@shared/constants/iso-date-time-format';
import type { KakaoLatestLinkageHistoryResType } from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/IntegrationFormWrapper';
import AlimTalkIntegrationWrapper from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoAlimTalkForm/components/AlimTalkIntegrationWrapper/AlimTalkIntegrationWrapper';
import KakaoAlimTalkRequestButton from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoAlimTalkForm/components/KakaoAlimTalkRequestButton';
import {
  type CategoryData,
  historyCategoryDataState,
} from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoAlimTalkForm/states/category-data-state';
import RequestInfoWrapper from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/common/RequestInfoWrapper/RequestInfoWrapper';
import { hasLinkageOrRequestHistoryState } from '@templates/HospitalSetting/containers/ChannelIntegration/states/has-linkage-or-request-history-state';
import { hospitalSettingsModifiedState } from '@templates/HospitalSetting/states/hospital-settings-modified-state';
import { useNavigationBlocker } from 'afterdoc-saas-web/shared/hooks/gnb/use-navigation-blocker';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/index';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const SUB_TITLE = '알림톡 연동과 관련하여 연락 받으실 분의 정보를 입력해주세요.';

const defaultStartTime = dayjs()
  .set('hour', 9)
  .set('minute', 0)
  .set('second', 0)
  .set('millisecond', 0)
  .format(ISO_DATE_TIME_TIME_FORMAT);

const defaultToBeStartedAt = dayjs(defaultStartTime)
  .add(3, 'day')
  .format(ISO_DATE_TIME_TIME_FORMAT);

const formatDate = (date: string | undefined, fallback: string): string =>
  date ? dayjs(date).format(ISO_DATE_TIME_TIME_FORMAT) : fallback;

const hasDifference = (
  current: KakaoAlimFriendTalkLinkageHandlerBodyRequest,
  defaultValues?: KakaoAlimFriendTalkLinkageHandlerBodyRequest,
) => {
  return Object.keys(current).some((key) => {
    const typedKey = key as keyof KakaoAlimFriendTalkLinkageHandlerBodyRequest;
    return current[typedKey] !== defaultValues?.[typedKey];
  });
};

const hasCategoryChanged = (history: CategoryData, current: CategoryData) => {
  return (
    history.broadCategory !== current.broadCategory ||
    history.mediumCategory !== current.mediumCategory ||
    history.smallCategory !== current.smallCategory
  );
};

interface KakaoAlimTalkFormProps {
  data?: KakaoLatestLinkageHistoryResType | null;
}

export default function KakaoAlimTalkForm({ data }: KakaoAlimTalkFormProps) {
  const { hospitalID } = useSelectedHospitalInfo();
  const { realName } = useUserInfo();

  const [hospitalSettingsModified, setHospitalSettingsModified] = useAtom(
    hospitalSettingsModifiedState,
  );

  const [historyCategoryData, setHistoryCategoryData] = useAtom(historyCategoryDataState);

  const hasLinkageOrRequestHistory = useAtomValue(hasLinkageOrRequestHistoryState);

  const defaultValues: KakaoAlimFriendTalkLinkageHandlerBodyRequest = {
    hospitalID,
    requesterName: data?.requesterName || realName || '',
    toBeContactedPhone: data?.requesterContact || '',
    toBeStartedAt: formatDate(data?.toBeStartedAt, defaultToBeStartedAt),
    searchableChannelId: data?.searchableChannelId || '',
    channelAdminPhone: data?.channelAdminPhone || '',
    authenticationNumber: '',
    categoryCode: '',
  };

  const methods = useForm<KakaoAlimFriendTalkLinkageHandlerBodyRequest>({
    defaultValues: { ...defaultValues },
  });

  const currentValues = methods.watch();

  useEffect(() => {
    const isLinked = hasLinkageOrRequestHistory !== 'NotLinked';
    if (isLinked) {
      setHospitalSettingsModified(false);
    } else {
      const hasFormChanged = hasDifference(currentValues, defaultValues);
      const hasCategoryUpdated = hasCategoryChanged(
        historyCategoryData.history,
        historyCategoryData.current,
      );
      setHospitalSettingsModified(hasFormChanged || hasCategoryUpdated);
    }
  }, [currentValues, historyCategoryData, hasLinkageOrRequestHistory, setHospitalSettingsModified]);

  useNavigationBlocker({
    shouldBlock: hospitalSettingsModified,
  });

  useEffect(() => {
    if (data?.categoryInformation) {
      const {
        broadCategory = '',
        mediumCategory = '',
        smallCategory = '',
      } = data.categoryInformation;

      setHistoryCategoryData((prev) => ({
        ...prev,
        history: {
          broadCategory,
          mediumCategory,
          smallCategory,
        },
      }));
    }
  }, [data]);

  return (
    <FormProvider {...methods}>
      <form autoComplete='off'>
        <div className='flex h-full flex-col'>
          <RequestInfoWrapper subTitle={SUB_TITLE} />
          <AlimTalkIntegrationWrapper />
          <KakaoAlimTalkRequestButton />
        </div>
      </form>
    </FormProvider>
  );
}
