import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { SUPPORT_TABS } from 'afterdoc-saas-web/templates/CustomerChat/constants/tab';
import { useAtomValue } from 'jotai/index';
import { selectedSupportTabState } from '../../../ChattingRoom/components/ChattingContainer/components/ChattingContent/states/selected-support-tab';

export default function NoSupportBotContents() {
  const selectedTab = useAtomValue(selectedSupportTabState);

  const displayContents =
    selectedTab === SUPPORT_TABS.BOOKMARK ? '북마크된 채팅이' : '표시할 내용이';

  return (
    <>
      <NoData
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white600',
        }}
        className='h-full w-full flex-center'
        title={`${displayContents} 없습니다.`}
      />
    </>
  );
}
