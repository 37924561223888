import type {
  ManualFolderList,
  SortedFoldersWithTags,
  TreatmentTagForFolder,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import type {
  Layer,
  SubLayer,
} from 'afterdoc-saas-web/shared/components/SequencePanelList/SequencePanelList';
import type { ReactNode } from 'react';

export const mapApiToLayers = (
  data: ManualFolderList,
  folderRightContent: (folder: SortedFoldersWithTags) => ReactNode,
  tagRightContent: (tag: TreatmentTagForFolder, folder: SortedFoldersWithTags) => ReactNode,
): Layer[] => {
  return (
    data.foldersHasTags
      ?.sort((a, b) => b.sortNum - a.sortNum)
      .map((folder) => ({
        id: folder._id,
        title: folder.name,
        icon: 'folder-outline',
        rightContent: folderRightContent(folder),
        items:
          folder.treatmentTags
            ?.sort((a, b) => b.sortNum - a.sortNum)
            .map((tag) => ({
              id: tag._id,
              text: tag.categoryName,
              icon: 'tag',
              rightContent: tagRightContent(tag, folder),
              sortNum: tag.sortNum,
              color: tag.color,
            })) || [],
        sortNum: folder.sortNum,
      })) || []
  );
};

export const mapApiToOrphanSubLayers = (
  data: ManualFolderList,
  tagRightContent: (tag: TreatmentTagForFolder) => ReactNode,
): SubLayer[] => {
  return (
    data.unassignedTags
      ?.sort((a, b) => b.sortNum - a.sortNum)
      .map((tag) => ({
        id: tag._id,
        text: tag.categoryName,
        icon: 'tag',
        rightContent: tagRightContent(tag),
        sortNum: tag.sortNum,
        color: tag.color,
      })) || []
  );
};
