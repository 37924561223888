type InstagramLinkageInfoAdjacentProps = {
  usingNum: number;
  totalNum: number;
};

export default function InstagramLinkageInfoAdjacent({
  usingNum,
  totalNum,
}: InstagramLinkageInfoAdjacentProps) {
  return (
    <div className='flex flex-row gap-8'>
      <p className='text-Body10 text-red500'>*</p>
      <p className='text-Body11 text-black200'>
        {usingNum} / {totalNum}
      </p>
    </div>
  );
}
