import { API_URL } from '@config/url';
import type {
  ChattingResponse,
  TextChattingResponse,
} from 'afterdoc-saas-web/templates/CustomerChat/types';
import { MESSAGE_TYPE_CODE } from '../components/ChattingContent/components/ChattingMessage/constants/message';
import type { DataResponse } from '../hooks/use-conditional-chat-data';

const CONTENTS_FILES_PREFIX_URL = `${API_URL}/v3/shared`;

export const replaceTextMessage = (
  prev: Array<DataResponse>,
  res: ChattingResponse | TextChattingResponse,
) => {
  // 기존 메시지 중 현재 메시지와 ID가 일치하는 메시지를 찾음
  const existingMessageIndex = prev.findIndex(
    (item) => item?.content === res?.content && item.type === MESSAGE_TYPE_CODE.TEXT_MOCKING,
  );

  const isExisted = existingMessageIndex !== -1;
  if (isExisted) {
    const isSent = res.isSent;
    if (isSent) {
      prev[existingMessageIndex] = res;
    } else {
      if (res.error) {
        prev[existingMessageIndex] = res;
      } else {
        prev[existingMessageIndex] = { ...res, type: MESSAGE_TYPE_CODE.TEXT_MOCKING };
      }
    }
    return [...prev];
  }

  if (res.isTranslated) {
    //번역 상태인데 아래의 경우는 컨텐츠 파일을 받은 경우라 추가해줘야함
    if (res.content.startsWith(CONTENTS_FILES_PREFIX_URL)) {
      return [res, ...prev];
    }
    return [...prev];
  }

  // 일치하는 메시지가 없으면 현재 메시지를 추가
  return [res, ...prev];
};
