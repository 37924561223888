import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useChatMessageContext } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/ChatMessageContext';
import { isActivatingChatTranslateState } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/states/is-activating-chat-translate';
import { useAtomValue } from 'jotai';
import { postChatTranslate } from './apis/post-chat-translate';

export default function LeftChatReTranslateError({
  restartTimer,
}: {
  restartTimer: () => void;
}) {
  const { _id: chatID } = useChatMessageContext();
  const isActivatingChatTranslate = useAtomValue(isActivatingChatTranslateState);

  const handleResend = async () => {
    //번역이 켜져 있는 경우 api로 번역을 다시 요청
    if (isActivatingChatTranslate) {
      await postChatTranslate(chatID);
      restartTimer();
    } else {
      toastService.errorMsg({ text: '번역을 키고 다시 시도해주세요.' });
    }
  };

  return (
    <div className='flex justify-end gap-6'>
      <div className='flex cursor-pointer' onClick={handleResend}>
        <div className='px-6 text-Body11 text-red500'>재번역</div>
        <Icon name='replay' color='red500' size={16} />
      </div>
    </div>
  );
}
