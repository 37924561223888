import { useCheckIsOverflow } from '@shared-hooks/use-check-is-overflow';
import ParentDropdown from '@templates/CustomerChat/components/ParentDropDown/ParentDropdown';
import { useDropdown } from '@templates/CustomerChat/components/ParentDropDown/hooks/use-dropdown';
import CursorTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/CursorTooltip';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import type { DropdownDataParams } from 'afterdoc-saas-web/templates/CustomerChat/components/ParentDropDown/hooks/use-parent-dropdown-options';
import RoomTypeIconState from 'afterdoc-saas-web/templates/CustomerChat/components/RoomTypeIconState/RoomTypeIconState';
import { useChatTopPatient } from 'afterdoc-saas-web/templates/CustomerChat/hooks/use-chat-top-patient';
import { useIsDisabledChat } from 'afterdoc-saas-web/templates/CustomerChat/hooks/use-is-disabled-chat';
import { chattingWidthAtom } from 'afterdoc-saas-web/templates/CustomerChat/states/chattingWidth';
import {
  chatRoomParentHServiceIDSelector,
  isLinkedSelector,
  roomTypeSelector,
} from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useLinkedChatRoom } from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoTitle/components/LinkedChatRoomManagerDialog/hooks/use-linked-chat-room';
import { useAtomValue } from 'jotai';
import { type MouseEvent, useRef } from 'react';
import { makePatientInfoStringDisplay } from '../../functions/makePatientInfoStringDisplay';

export default function PatientTitle({ chatRoomID }: { chatRoomID: string }) {
  const chatRoomParentHServiceID = useAtomValue(chatRoomParentHServiceIDSelector);
  const roomType = useAtomValue(roomTypeSelector);
  const isLinked = useAtomValue(isLinkedSelector);

  const { isUsageHaltedDisabledSending } = useIsDisabledChat({ chatRoomID });
  const { isFirstVisit, name, chartNumber, isKakaoIcon, isColorAfterDocIcon, isGrayAfterDocIcon } =
    useChatTopPatient({ chatRoomID });

  const { handleUnLinkChatRoomByChatRoom } = useLinkedChatRoom(chatRoomParentHServiceID);

  const handleConfirm = (e?: MouseEvent) => {
    e?.stopPropagation();
    handleUnLinkChatRoomByChatRoom({ chatRoomID, hserviceID: chatRoomParentHServiceID });
    modalService.pop();
  };

  const handleCancel = (e?: MouseEvent) => {
    e?.stopPropagation();
    modalService.pop();
  };

  const handleUnLinkChatRoomButton = () => {
    modalService.defaultWarning({
      title: '채팅방 연동 해제',
      contents:
        '선택한 채팅방의 연동을 해제할까요?\n' +
        '연동을 해제하더라도, 고객등록을 통해 다시 연동할 수 있습니다.',
      onConfirm: handleConfirm,
      onCancel: handleCancel,
    });
  };

  const isFirstVisitDisplay = makePatientInfoStringDisplay({
    type: 'isFirstVisit',
    payload: {
      isFirstVisit,
    },
  });
  const chartNumberDisplay = makePatientInfoStringDisplay({
    type: 'chartNumber',
    payload: {
      chartNumber,
    },
  });

  const isNoVisitInfo = isFirstVisit !== undefined && isFirstVisit !== null;
  const isVisitStatus = isKakaoIcon && !isColorAfterDocIcon && !isGrayAfterDocIcon;

  const isOnlyKakao = !isLinked && roomType === 'ikakao';
  const dropdownDataParams = {
    isOnlyKakao,
  };
  const isVisibleUnLinkButton =
    isLinked &&
    !isUsageHaltedDisabledSending &&
    !(roomType === 'manager' || roomType === 'patient');

  return (
    <div className='mt-5 flex items-center justify-between'>
      <div className='flex items-center gap-6'>
        {!isVisitStatus && isNoVisitInfo ? (
          <div className='mr-10 h-20 w-28 rounded-[5px] bg-blueLight pt-1 text-center text-Header12 text-blue500'>
            {isFirstVisitDisplay}
          </div>
        ) : null}
        <div className='relative flex h-22 min-w-0 flex-center gap-4'>
          <NameTextWithCursor name={name} dropdownDataParams={dropdownDataParams} />
          <ChartNumberTextWithCursor
            chartNumber={chartNumberDisplay}
            isVisitStatus={isVisitStatus}
          />
        </div>
        <div className='ml-6 flex flex-center gap-6'>
          <RoomTypeIconState
            roomType={roomType}
            isUsageHaltedDisabledSending={isUsageHaltedDisabledSending}
          />
        </div>
        {isVisibleUnLinkButton && (
          <div
            className='cursor-pointer text-Body11 text-black200 underline'
            onClick={handleUnLinkChatRoomButton}>
            연동해제
          </div>
        )}
      </div>
    </div>
  );
}

interface NameTextWithCursorProps {
  name?: string;
  dropdownDataParams: DropdownDataParams;
}

function NameTextWithCursor({ name, dropdownDataParams }: NameTextWithCursorProps) {
  const { isDropdownOpen, handleDropdown } = useDropdown();

  const ref = useRef<HTMLDivElement>(null);
  const { isOverflow } = useCheckIsOverflow(ref);

  const chattingWidth = useAtomValue(chattingWidthAtom);

  return (
    <CursorTooltip
      text={name}
      show={isOverflow}
      wrapperProps={{
        className: 'truncate',
        style: {
          maxWidth: `${chattingWidth / 1.5}px`,
        },
      }}>
      <div
        ref={ref}
        className='cursor-pointer truncate text-Header16 text-black700'
        onClick={handleDropdown}>
        {name}
      </div>
      {isDropdownOpen && (
        <ParentDropdown
          dropdownDataParams={dropdownDataParams}
          handleToggle={handleDropdown}
          style={{
            position: 'fixed',
          }}
        />
      )}
    </CursorTooltip>
  );
}

interface ChartNumberTextWithCursorProps {
  chartNumber?: string;
  isVisitStatus: boolean;
}

function ChartNumberTextWithCursor({ chartNumber, isVisitStatus }: ChartNumberTextWithCursorProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { isOverflow } = useCheckIsOverflow(ref);

  const chattingWidth = useAtomValue(chattingWidthAtom);

  return (
    <CursorTooltip
      text={chartNumber}
      show={isOverflow}
      wrapperProps={{
        className: 'flex',
        style: {
          maxWidth: `${chattingWidth / 3}px`,
        },
      }}>
      {!isVisitStatus && (
        <div ref={ref} className='mx-[1px] flex truncate text-Body11 text-black700'>
          {chartNumber}
        </div>
      )}
    </CursorTooltip>
  );
}
