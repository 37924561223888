import { TAG_STATUS_CONFIG } from '@templates/HospitalManual/components/HospitalManualContent/components/ManualCautionMessage/constants/tag-status-config';
import type { StatusType } from '@templates/HospitalManual/components/HospitalManualContent/components/ManualCautionMessage/types/status-type';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';

interface CautionStatusInfoProps {
  statusType: StatusType;
}

export const CautionStatusInfo = ({ statusType }: CautionStatusInfoProps) => {
  const config = TAG_STATUS_CONFIG[statusType];

  if (!config) return null;

  return (
    <div className='mb-[50px] flex items-center gap-10'>
      <FilledTag
        className='rounded-r10'
        tagSize='small'
        bgColor={config.bgColor}
        textColor={config.textColor}>
        {config.label}
      </FilledTag>
      <div className='flex items-center gap-10'>
        <span className='!leading-[19px] align-middle text-Body11 text-black200 '>
          {config.message}
          {config.showLink && <KakaoHelpLink />}
        </span>
        {config.showTooltip && <TooltipContent />}
      </div>
    </div>
  );
};

const KakaoHelpLink = () => {
  return (
    <a
      className='ml-4 cursor-pointer font-bold underline'
      href='http://pf.kakao.com/_kHcjn/chat'
      target='_blank'
      rel='noopener noreferrer'>
      @애프터닥헬프데스크
    </a>
  );
};

const TooltipContent = () => {
  return (
    <HoverTooltip
      position='topLeft'
      text={
        '안녕하세요. 카카오톡 알림톡 검수 담당자입니다.\n\n' +
        '알림톡은 수신자의 요청 또는 액션을 기반으로 한 정보성 메시지에 한해 발송이 가능합니다.\n' +
        '그러나 해당 메시지는 수신자의 요청과 무관한 광고성 및 공지성 내용으로 판단되어, 알림톡 발송이 불가합니다.\n\n' +
        '보다 자세한 알림톡 검수 기준은 딜러사를 통해 확인 부탁드립니다.\n\n' +
        '감사합니다.'
      }>
      <Icon name={'info-circle-outline'} color={'black200'} size={16} />
    </HoverTooltip>
  );
};
