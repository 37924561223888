import { SHARED_UTILS } from '@shared-utils/utils';
import { type InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { Memo, MemoWithFileID } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';

const createManagementDirection = async (
  params: Parameters<typeof apiClient.v3.apiMemosElCreate>['0'],
) => {
  const response = await apiClient.v3.apiMemosElCreate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const updateManagementDirection = async (
  params: Parameters<typeof apiClient.v3.apiMemosElUpdate>['0'],
) => {
  const response = await apiClient.v3.apiMemosElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const deleteManagementDirection = async (
  params: Parameters<typeof apiClient.v3.apiMemosElRemove>['0'],
): Promise<MemoWithFileID> => {
  const response = await apiClient.v3.apiMemosElRemove(params);
  return SHARED_UTILS.api.checkApiResponse(response.data) as MemoWithFileID;
};

export const useManagementDirection = (patientId: string) => {
  const queryClient = useQueryClient();

  const createManagementDirectionMutation = useMutation({
    mutationFn: (params: Parameters<typeof apiClient.v3.apiMemosElCreate>['0']) =>
      createManagementDirection(params),
    onSuccess: (data) => {
      toastService.successMsg({ text: '관리방향을 저장했습니다.' });
      createQueryData(data);
    },
  });

  const updateManagementDirectionMutation = useMutation({
    mutationFn: (params: Parameters<typeof apiClient.v3.apiMemosElUpdate>['0']) =>
      updateManagementDirection(params),
    onSuccess: () => {
      toastService.successMsg({ text: '관리방향을 수정했습니다.' });
      updateQueryData();
    },
  });

  const deleteManagementDirectionMutation = useMutation({
    mutationFn: (params: Parameters<typeof apiClient.v3.apiMemosElRemove>['0']) =>
      deleteManagementDirection(params),
    onSuccess: (data: MemoWithFileID) => {
      toastService.successMsg({ text: '관리방향을 삭제했습니다.' });
      deleteQueryData(data);
    },
  });

  const createQueryData = (data: Memo) => {
    queryClient.setQueryData(
      [
        QUERY_KEY.apiMemosElFind,
        { hserviceID: patientId, type: 2000, limit: 5, key: '_id', way: -1 },
      ],
      (oldData: InfiniteData<MemoWithFileID[], unknown>) => {
        if (!oldData) return oldData;

        return {
          ...oldData,
          pages: [data, ...oldData.pages],
        };
      },
    );

    queryClient.refetchQueries({
      queryKey: [QUERY_KEY.apiPatientsElDetail, { patientId }],
    });
  };

  const updateQueryData = () => {
    queryClient.refetchQueries({
      queryKey: [
        QUERY_KEY.apiMemosElFind,
        { hserviceID: patientId, type: 2000, limit: 5, key: '_id', way: -1 },
      ],
    });

    queryClient.refetchQueries({
      queryKey: [QUERY_KEY.apiPatientsElDetail, { patientId }],
    });
  };

  const deleteQueryData = (data: MemoWithFileID) => {
    queryClient.setQueryData(
      [
        QUERY_KEY.apiMemosElFind,
        { hserviceID: patientId, type: 2000, limit: 5, key: '_id', way: -1 },
      ],
      (oldData: InfiniteData<MemoWithFileID[], unknown>) => {
        if (!oldData) return oldData;

        const normalizedPages = oldData.pages.map((page) => (Array.isArray(page) ? page : [page]));

        const updatedPages = normalizedPages
          .map((page) => page.filter((memo) => memo._id !== data._id))
          .filter((page) => page.length > 0);

        return {
          ...oldData,
          pages: updatedPages.length > 0 ? updatedPages : [],
        };
      },
    );

    queryClient.refetchQueries({
      queryKey: [QUERY_KEY.apiPatientsElDetail, { patientId }],
    });
  };

  return {
    createManagementDirectionMutation,
    updateManagementDirectionMutation,
    deleteManagementDirectionMutation,
  };
};
