
import type { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { CUSTOM_QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/custom-query-key';

export const QUERY_KEY = {
  apiChatroomElFind: 'apiChatroomElFind',
  apiChatroomElFindOne: 'apiChatroomElFindOne',
  apiChatroomElCount: 'apiChatroomElCount',
  apiChatroomElUnreadcount: 'apiChatroomElUnreadcount',
  apiChatroomElUpdate: 'apiChatroomElUpdate',
  apiChatroomElUpdateRead: 'apiChatroomElUpdateRead',
  apiChatroomElUpdateCounselStatus: 'apiChatroomElUpdateCounselStatus',
  apiChatroomElUpdateCounselCounselor: 'apiChatroomElUpdateCounselCounselor',
  apiChatroomElUserAdd: 'apiChatroomElUserAdd',
  apiChatroomElUserRemove: 'apiChatroomElUserRemove',
  apiChatroomElUserRemoveAll: 'apiChatroomElUserRemoveAll',
  apiChatroomElCounselorAdd: 'apiChatroomElCounselorAdd',
  apiChatroomElDetectLanguage: 'apiChatroomElDetectLanguage',
  apiChatroomElUpdateTranslateToggle: 'apiChatroomElUpdateTranslateToggle',
  apiChatroomHmFind: 'apiChatroomHmFind',
  apiChatroomHmFindOne: 'apiChatroomHmFindOne',
  apiChatroomHmCount: 'apiChatroomHmCount',
  apiChatroomHmUnreadcount: 'apiChatroomHmUnreadcount',
  apiChatroomHmUpdateRead: 'apiChatroomHmUpdateRead',
  apiChatroomHmUpdateCounselStatus: 'apiChatroomHmUpdateCounselStatus',
  apiChatroomHmUserRemove: 'apiChatroomHmUserRemove',
  apiChatroomHmCounselorAdd: 'apiChatroomHmCounselorAdd',
  apiChatroomHmUpdateTranslateToggle: 'apiChatroomHmUpdateTranslateToggle',
  apiChatElFind: 'apiChatElFind',
  apiChatElFindDate: 'apiChatElFindDate',
  apiChatElFindId: 'apiChatElFindId',
  apiChatElFindLast: 'apiChatElFindLast',
  apiChatElFindOne: 'apiChatElFindOne',
  apiChatElCount: 'apiChatElCount',
  apiChatElUpdateMarker: 'apiChatElUpdateMarker',
  apiChatElUpdateBookmark: 'apiChatElUpdateBookmark',
  apiChatElRemove: 'apiChatElRemove',
  apiChatElSendHnotice: 'apiChatElSendHnotice',
  apiChatElSendHevent: 'apiChatElSendHevent',
  apiChatElSendHaftercare: 'apiChatElSendHaftercare',
  apiChatElSendVcu: 'apiChatElSendVcu',
  apiChatElTranslate: 'apiChatElTranslate',
  apiChatHmFind: 'apiChatHmFind',
  apiChatHmFindOne: 'apiChatHmFindOne',
  apiChatHmCount: 'apiChatHmCount',
  apiChatHmRemove: 'apiChatHmRemove',
  apiChatHmTranslate: 'apiChatHmTranslate',
  apiPatientsElDetail: 'apiPatientsElDetail',
  apiPatientsElListOrSearch: 'apiPatientsElListOrSearch',
  apiPatientsElListOrSearchForUntagged: 'apiPatientsElListOrSearchForUntagged',
  apiPatientsElUntaggedCount: 'apiPatientsElUntaggedCount',
  apiPatientsElCount: 'apiPatientsElCount',
  apiPatientsElUntaggedTarget: 'apiPatientsElUntaggedTarget',
  apiPatientsEl: 'apiPatientsEl',
  apiPatientsElUpsertSingle: 'apiPatientsElUpsertSingle',
  apiPatientsElTemporaryV2ReservationToday: 'apiPatientsElTemporaryV2ReservationToday',
  apiPatientsElLinkKakao: 'apiPatientsElLinkKakao',
  apiPatientsHmDetail: 'apiPatientsHmDetail',
  apiPatientsHmLinkKakao: 'apiPatientsHmLinkKakao',
  apiPatientsElTemporaryListOrSearch: 'apiPatientsElTemporaryListOrSearch',
  apiPatientsElTemporary: 'apiPatientsElTemporary',
  temporaryPatientsDeletionHandler: 'temporaryPatientsDeletionHandler',
  apiPatientsRegistrationsElInvitationWithSms: 'apiPatientsRegistrationsElInvitationWithSms',
  apiPatientsRegistrationsElReinvitationWithSms: 'apiPatientsRegistrationsElReinvitationWithSms',
  apiPatientsMemosElFindOne: 'apiPatientsMemosElFindOne',
  apiPatientsMemosElUpdate: 'apiPatientsMemosElUpdate',
  apiPatientsMemosHmFindOne: 'apiPatientsMemosHmFindOne',
  apiPatientsMemosHmUpdate: 'apiPatientsMemosHmUpdate',
  apiHospitalsElFind: 'apiHospitalsElFind',
  apiHospitalsElUpdate: 'apiHospitalsElUpdate',
  apiHospitalsElUpdateSmslogoimage: 'apiHospitalsElUpdateSmslogoimage',
  apiHospitalsElFindAddress: 'apiHospitalsElFindAddress',
  apiHospitalsElFindDoctors: 'apiHospitalsElFindDoctors',
  apiHospitalsElFindUsers: 'apiHospitalsElFindUsers',
  hospitalMFindOneHandler: 'hospitalMFindOneHandler',
  hospitalMFindDoctorsHandler: 'hospitalMFindDoctorsHandler',
  apiHospitalsMFindAfterhoursPopup: 'apiHospitalsMFindAfterhoursPopup',
  apiServiceSettingsElFind: 'apiServiceSettingsElFind',
  apiServiceSettingsElFindOne: 'apiServiceSettingsElFindOne',
  apiServiceSettingsElCreate: 'apiServiceSettingsElCreate',
  apiServiceSettingsElUpdate: 'apiServiceSettingsElUpdate',
  apiServiceSettingsElToBeDisplayedPatientsFields: 'apiServiceSettingsElToBeDisplayedPatientsFields',
  apiServiceSettingsElCountries: 'apiServiceSettingsElCountries',
  apiServiceSettingsHmFindOne: 'apiServiceSettingsHmFindOne',
  apiServiceSettingsHmCountries: 'apiServiceSettingsHmCountries',
  apiHservicesElFindChatCount: 'apiHservicesElFindChatCount',
  apiHservicesElFindChat: 'apiHservicesElFindChat',
  apiHservicesElFindCount: 'apiHservicesElFindCount',
  apiHservicesElFindDetail: 'apiHservicesElFindDetail',
  apiHservicesElFindPatientsettingCount: 'apiHservicesElFindPatientsettingCount',
  apiHservicesElFindNeedsetting: 'apiHservicesElFindNeedsetting',
  apiHservicesElFindSimple: 'apiHservicesElFindSimple',
  apiHservicesElFindTreatmentcategoryCount: 'apiHservicesElFindTreatmentcategoryCount',
  apiHservicesElFindTreatmentcategory: 'apiHservicesElFindTreatmentcategory',
  apiHservicesElFindUnjoinedCount: 'apiHservicesElFindUnjoinedCount',
  apiHservicesElFindUnjoined: 'apiHservicesElFindUnjoined',
  apiHservicesElFind: 'apiHservicesElFind',
  apiHservicesElSuspend: 'apiHservicesElSuspend',
  apiHservicesElUpdateSetting: 'apiHservicesElUpdateSetting',
  apiHservicesElUpdate: 'apiHservicesElUpdate',
  apiHservicesHmFindSimple: 'apiHservicesHmFindSimple',
  apiHservicesMSuspend: 'apiHservicesMSuspend',
  apiHservicesMUpdateSelect: 'apiHservicesMUpdateSelect',
  apiHospitalsAccountsElFind: 'apiHospitalsAccountsElFind',
  apiHospitalsAccountsElCreate: 'apiHospitalsAccountsElCreate',
  apiHospitalsAccountsElUpdate: 'apiHospitalsAccountsElUpdate',
  apiHospitalsAccountsElIdcheck: 'apiHospitalsAccountsElIdcheck',
  apiHospitalsAccountsElReset: 'apiHospitalsAccountsElReset',
  apiHospitalsAccountsElRemove: 'apiHospitalsAccountsElRemove',
  apiHospitalsAuthorizationTypeElFind: 'apiHospitalsAuthorizationTypeElFind',
  apiHospitalsAuthorizationTypeElUpdate: 'apiHospitalsAuthorizationTypeElUpdate',
  apiHworkersElFind: 'apiHworkersElFind',
  apiHworkersElFindOne: 'apiHworkersElFindOne',
  apiHworkersElFindCounselor: 'apiHworkersElFindCounselor',
  apiHworkersElUpdate: 'apiHworkersElUpdate',
  apiHworkersElUpdateDashboard: 'apiHworkersElUpdateDashboard',
  apiNoticeHospitalElFindSimple: 'apiNoticeHospitalElFindSimple',
  apiNoticeHospitalElFindDetail: 'apiNoticeHospitalElFindDetail',
  apiNoticeHospitalElCount: 'apiNoticeHospitalElCount',
  apiNoticeHospitalElCreate: 'apiNoticeHospitalElCreate',
  apiNoticeHospitalElUpdate: 'apiNoticeHospitalElUpdate',
  apiNoticeHospitalElRemove: 'apiNoticeHospitalElRemove',
  apiPopupHospitalElFindSimple: 'apiPopupHospitalElFindSimple',
  apiPopupHospitalElFindDetail: 'apiPopupHospitalElFindDetail',
  apiPopupHospitalElCount: 'apiPopupHospitalElCount',
  apiPopupHospitalElCreate: 'apiPopupHospitalElCreate',
  apiPopupHospitalElUpdate: 'apiPopupHospitalElUpdate',
  apiPopupHospitalElRemove: 'apiPopupHospitalElRemove',
  apiPushRequestElFind: 'apiPushRequestElFind',
  apiPushRequestElCount: 'apiPushRequestElCount',
  apiPushRequestElCreate: 'apiPushRequestElCreate',
  apiPushResponseElFind: 'apiPushResponseElFind',
  apiTreatmentTagsElFind: 'apiTreatmentTagsElFind',
  apiTreatmentTagsElCreate: 'apiTreatmentTagsElCreate',
  apiTreatmentTagsElUpdate: 'apiTreatmentTagsElUpdate',
  apiTreatmentTagsElRemove: 'apiTreatmentTagsElRemove',
  apiTreatmentTagsEl: 'apiTreatmentTagsEl',
  apiHworkersElAuthorization: 'apiHworkersElAuthorization',
  apiServiceManagerNotesEl: 'apiServiceManagerNotesEl',
  apiServiceManagerNotesElUpdate: 'apiServiceManagerNotesElUpdate',
  apiServiceManagerNotesElRemove: 'apiServiceManagerNotesElRemove',
  apiServiceManagerNotesElDetail: 'apiServiceManagerNotesElDetail',
  apiV2ReservationElCount: 'apiV2ReservationElCount',
  apiV2ReservationElCreateRegist: 'apiV2ReservationElCreateRegist',
  apiV2ReservationElCreateUnregistered: 'apiV2ReservationElCreateUnregistered',
  apiV2ReservationElCreate: 'apiV2ReservationElCreate',
  apiV2ReservationElFind: 'apiV2ReservationElFind',
  apiV2ReservationElFindHistory: 'apiV2ReservationElFindHistory',
  apiV2ReservationElFindNext: 'apiV2ReservationElFindNext',
  apiV2ReservationElRemove: 'apiV2ReservationElRemove',
  apiV2ReservationElSendPush: 'apiV2ReservationElSendPush',
  apiV2ReservationElUpdate: 'apiV2ReservationElUpdate',
  apiV2ReservationMCancel: 'apiV2ReservationMCancel',
  apiV2ReservationMCreate: 'apiV2ReservationMCreate',
  apiV2ReservationMFindDate: 'apiV2ReservationMFindDate',
  apiV2ReservationMFindLast: 'apiV2ReservationMFindLast',
  apiV2ReservationMFindNext: 'apiV2ReservationMFindNext',
  apiV2ReservationMFindOne: 'apiV2ReservationMFindOne',
  apiV2ReservationMFind: 'apiV2ReservationMFind',
  apiV2ReservationMPossibledate: 'apiV2ReservationMPossibledate',
  apiV2ReservationMPossibletime: 'apiV2ReservationMPossibletime',
  apiV2ReservationMUpdate: 'apiV2ReservationMUpdate',
  apiV2ReservationusernoteElFind: 'apiV2ReservationusernoteElFind',
  apiV2ReservationusernoteElUpdate: 'apiV2ReservationusernoteElUpdate',
  apiV2HreservationElCount: 'apiV2HreservationElCount',
  apiV2HreservationElFind: 'apiV2HreservationElFind',
  apiVideocontentglobalsettingElFindOne: 'apiVideocontentglobalsettingElFindOne',
  apiVideocontentcategoryElFind: 'apiVideocontentcategoryElFind',
  apiVideocontentcategoryElFindOne: 'apiVideocontentcategoryElFindOne',
  apiVideocontenthospitalElFind: 'apiVideocontenthospitalElFind',
  apiVideocontenthospitalElDetail: 'apiVideocontenthospitalElDetail',
  apiVideocontenthospitalElPlay: 'apiVideocontenthospitalElPlay',
  apiVideocontenthospitalElPlaysub: 'apiVideocontenthospitalElPlaysub',
  apiVideocontentuserElFind: 'apiVideocontentuserElFind',
  apiVideocontentuserElCreate: 'apiVideocontentuserElCreate',
  apiFiles: 'apiFiles',
  singleFileContentsUpdateHandler: 'singleFileContentsUpdateHandler',
  apiAutomationsElOngoing: 'apiAutomationsElOngoing',
  apiAutomationsElAlgorithmsSingle: 'apiAutomationsElAlgorithmsSingle',
  apiAutomationsElAlgorithmsSingleDeletion: 'apiAutomationsElAlgorithmsSingleDeletion',
  apiAutomationsElMainList: 'apiAutomationsElMainList',
  apiAutomationsElDashboard: 'apiAutomationsElDashboard',
  apiAutomationsElAlgorithmsMessages: 'apiAutomationsElAlgorithmsMessages',
  apiAutomationsElAlgorithmsMessagesSingle: 'apiAutomationsElAlgorithmsMessagesSingle',
  apiAutomationsElAlgorithmsMessagesUpsertSingle: 'apiAutomationsElAlgorithmsMessagesUpsertSingle',
  apiAutomationsElAlgorithmsMessagesDeleteSingle: 'apiAutomationsElAlgorithmsMessagesDeleteSingle',
  apiAutomationsElAlgorithmsToggle: 'apiAutomationsElAlgorithmsToggle',
  apiAutomationsElAlgorithmsResponses: 'apiAutomationsElAlgorithmsResponses',
  apiAutomationsElCommonAlgorithm: 'apiAutomationsElCommonAlgorithm',
  apiAutomationsElCommonAlgorithmUpsert: 'apiAutomationsElCommonAlgorithmUpsert',
  apiAutomationsElAffectedPatientCountWhenTreatmentTagDeleted: 'apiAutomationsElAffectedPatientCountWhenTreatmentTagDeleted',
  apiLanguageElFindDefault: 'apiLanguageElFindDefault',
  apiLanguageHmFindDefault: 'apiLanguageHmFindDefault',
  apiManualsElFoldersCreate: 'apiManualsElFoldersCreate',
  apiManualsElFolders: 'apiManualsElFolders',
  apiManualsElFoldersUpdate: 'apiManualsElFoldersUpdate',
  apiManualsElFoldersDelete: 'apiManualsElFoldersDelete',
  apiManualsElUpsertSingle: 'apiManualsElUpsertSingle',
  apiManualsElTreatmentCautionsChat: 'apiManualsElTreatmentCautionsChat',
  apiManualsElDelete: 'apiManualsElDelete',
  apiManualsElSingle: 'apiManualsElSingle',
  apiEventHospitalAftercareElFindSimple: 'apiEventHospitalAftercareElFindSimple',
  apiEventHospitalAftercareElFindOne: 'apiEventHospitalAftercareElFindOne',
  apiEventHospitalAftercareElCount: 'apiEventHospitalAftercareElCount',
  apiEventHospitalAftercareElCreate: 'apiEventHospitalAftercareElCreate',
  apiEventHospitalAftercareElUpdate: 'apiEventHospitalAftercareElUpdate',
  apiEventHospitalAftercareElRemove: 'apiEventHospitalAftercareElRemove',
  apiEventHospitalEventElFindSimple: 'apiEventHospitalEventElFindSimple',
  apiEventHospitalEventElFindOne: 'apiEventHospitalEventElFindOne',
  apiEventHospitalEventElCount: 'apiEventHospitalEventElCount',
  apiEventHospitalEventElCreate: 'apiEventHospitalEventElCreate',
  apiEventHospitalEventElUpdate: 'apiEventHospitalEventElUpdate',
  apiEventHospitalEventElRemove: 'apiEventHospitalEventElRemove',
  apiMemosElCreate: 'apiMemosElCreate',
  apiMemosElFindLast: 'apiMemosElFindLast',
  apiMemosElFindManageCount: 'apiMemosElFindManageCount',
  apiMemosElFindManageLast: 'apiMemosElFindManageLast',
  apiMemosElFindManage: 'apiMemosElFindManage',
  apiMemosElFindNurseCount: 'apiMemosElFindNurseCount',
  apiMemosElFindNurseLast: 'apiMemosElFindNurseLast',
  apiMemosElFindNurse: 'apiMemosElFindNurse',
  apiMemosElFind: 'apiMemosElFind',
  apiMemosElFindWithPinned: 'apiMemosElFindWithPinned',
  apiMemosElRead: 'apiMemosElRead',
  apiMemosElRemove: 'apiMemosElRemove',
  apiMemosElUpdatePin: 'apiMemosElUpdatePin',
  apiMemosElUpdate: 'apiMemosElUpdate',
  apiApprovalChatroomElFind: 'apiApprovalChatroomElFind',
  apiApprovalChatroomElCreate: 'apiApprovalChatroomElCreate',
  apiApprovalChatroomElUpdate: 'apiApprovalChatroomElUpdate',
  apiApprovalChatroomHmFind: 'apiApprovalChatroomHmFind',
  apiApprovalChatroomHmUpdate: 'apiApprovalChatroomHmUpdate',
  apiShortlinkEventElCreate: 'apiShortlinkEventElCreate',
  apiShortlinkAftercareElCreate: 'apiShortlinkAftercareElCreate',
  apiShortlinkVideocontentElCreate: 'apiShortlinkVideocontentElCreate',
  apiShortlinkHospitalnoticeElCreate: 'apiShortlinkHospitalnoticeElCreate',
  sharedEventShare: 'sharedEventShare',
  sharedAftercareShare: 'sharedAftercareShare',
  sharedVideocontentShare: 'sharedVideocontentShare',
  sharedHospitalnoticeShare: 'sharedHospitalnoticeShare',
  adminHospitalsElFind: 'adminHospitalsElFind',
  adminHospitalsElFindNurse: 'adminHospitalsElFindNurse',
  adminHospitalsElCreate: 'adminHospitalsElCreate',
  adminHospitalsElCount: 'adminHospitalsElCount',
  adminHospitalsElUpdate: 'adminHospitalsElUpdate',
  adminHospitalsElFindDoctor: 'adminHospitalsElFindDoctor',
  adminHospitalsElCountDoctor: 'adminHospitalsElCountDoctor',
  adminHospitalsElCreateDoctor: 'adminHospitalsElCreateDoctor',
  adminHospitalsElUpdateDoctor: 'adminHospitalsElUpdateDoctor',
  adminHospitalsElRemoveDoctor: 'adminHospitalsElRemoveDoctor',
  adminAuthorizationTypeElFind: 'adminAuthorizationTypeElFind',
  apiNoticeServicenoticeElFind: 'apiNoticeServicenoticeElFind',
  apiNoticeServicenoticeElCount: 'apiNoticeServicenoticeElCount',
  apiNoticeServicenoticeElUpdateRead: 'apiNoticeServicenoticeElUpdateRead',
  apiNoticeServicenoticeElUpdateReadAll: 'apiNoticeServicenoticeElUpdateReadAll',
  apiNoticeServicenoticeHmFind: 'apiNoticeServicenoticeHmFind',
  apiNoticeServicenoticeHmCount: 'apiNoticeServicenoticeHmCount',
  apiNoticeServicenoticeHmUpdateRead: 'apiNoticeServicenoticeHmUpdateRead',
  apiNoticeServicenoticeHmUpdateReadAll: 'apiNoticeServicenoticeHmUpdateReadAll',
  apiStatsAlgorithmsConsultationElAggregateBatch: 'apiStatsAlgorithmsConsultationElAggregateBatch',
  apiStatsAlgorithmsConsultationMonthlyElFind: 'apiStatsAlgorithmsConsultationMonthlyElFind',
  apiStatsAlgorithmsConsultationMonthlyElFindHospital: 'apiStatsAlgorithmsConsultationMonthlyElFindHospital',
  apiStatsChatsElAggregateBatch: 'apiStatsChatsElAggregateBatch',
  apiStatsChatsMonthlyElFindHospital: 'apiStatsChatsMonthlyElFindHospital',
  v2authJoinMQr: 'v2authJoinMQr',
  v2authJoinMReleasepending: 'v2authJoinMReleasepending',
  v2authJoinMMassivelink: 'v2authJoinMMassivelink',
  v2authLoginEl: 'v2authLoginEl',
  v2authLoginMCheckphone: 'v2authLoginMCheckphone',
  v2authLoginM: 'v2authLoginM',
  v2authLogoutM: 'v2authLogoutM',
  v2authLoginHm: 'v2authLoginHm',
  v2authLogoutHm: 'v2authLogoutHm',
  v2authPhoneMRequest: 'v2authPhoneMRequest',
  v2authPhoneMRequestChangephone: 'v2authPhoneMRequestChangephone',
  v2authPhoneMRequestUnidentify: 'v2authPhoneMRequestUnidentify',
  v2authPhoneMVerify: 'v2authPhoneMVerify',
  v2authPhoneMChange: 'v2authPhoneMChange',
  v2authPhoneMwRequest: 'v2authPhoneMwRequest',
  v2authPhoneMwVerify: 'v2authPhoneMwVerify',
  v2authPasswordMHaspassword: 'v2authPasswordMHaspassword',
  v2authPasswordMUpdate: 'v2authPasswordMUpdate',
  v2authShortlinkMUserinfo: 'v2authShortlinkMUserinfo',
  v2authShortlinkMHserviceinfo: 'v2authShortlinkMHserviceinfo',
  v2authUserMwSuspend: 'v2authUserMwSuspend',
  v2apiRegistPatientElCreate: 'v2apiRegistPatientElCreate',
  v2apiRegistPatientElSendsms: 'v2apiRegistPatientElSendsms',
  v2apiUserElFind: 'v2apiUserElFind',
  v2apiUserElFindOne: 'v2apiUserElFindOne',
  v2apiUserElUpdate: 'v2apiUserElUpdate',
  v2apiUserElCanchangephone: 'v2apiUserElCanchangephone',
  v2apiUserElUpdateDashboard: 'v2apiUserElUpdateDashboard',
  v2apiUserMFindOne: 'v2apiUserMFindOne',
  v2apiUserMUpdate: 'v2apiUserMUpdate',
  v2apiUserMUpdateDeviceinfo: 'v2apiUserMUpdateDeviceinfo',
  v2apiUserMPhoneCode: 'v2apiUserMPhoneCode',
  v2apiUserMPhoneVerify: 'v2apiUserMPhoneVerify',
  v2apiUserMChangepassword: 'v2apiUserMChangepassword',
  v2apiUserHmUpdateDeviceinfo: 'v2apiUserHmUpdateDeviceinfo',
  v2apiChatroomElFind: 'v2apiChatroomElFind',
  v2apiChatroomElCountResponserequired: 'v2apiChatroomElCountResponserequired',
  v2apiChatroomElCount: 'v2apiChatroomElCount',
  v2apiChatroomElUnreadcount: 'v2apiChatroomElUnreadcount',
  v2apiChatroomElCreate: 'v2apiChatroomElCreate',
  v2apiChatroomElUpdate: 'v2apiChatroomElUpdate',
  v2apiChatroomElUpdateReadResponserequired: 'v2apiChatroomElUpdateReadResponserequired',
  v2apiChatroomElUpdateRead: 'v2apiChatroomElUpdateRead',
  v2apiChatroomElUpdateStatusResponserequiredAll: 'v2apiChatroomElUpdateStatusResponserequiredAll',
  v2apiChatroomElUpdateTitle: 'v2apiChatroomElUpdateTitle',
  v2apiChatroomElUserAdd: 'v2apiChatroomElUserAdd',
  v2apiChatroomElUserRemove: 'v2apiChatroomElUserRemove',
  v2apiChatroomElUserRemoveAll: 'v2apiChatroomElUserRemoveAll',
  v2apiChatroomMFindOne: 'v2apiChatroomMFindOne',
  v2apiChatroomMUpdateRead: 'v2apiChatroomMUpdateRead',
  v2apiChatElFind: 'v2apiChatElFind',
  v2apiChatElCount: 'v2apiChatElCount',
  v2apiChatElUpdateMarker: 'v2apiChatElUpdateMarker',
  v2apiChatMCreateReservation: 'v2apiChatMCreateReservation',
  v2apiChatMCreateHevent: 'v2apiChatMCreateHevent',
  v2apiChatMCreateHaftercare: 'v2apiChatMCreateHaftercare',
  v2apiHospitalElFind: 'v2apiHospitalElFind',
  v2apiHospitalElFindAddress: 'v2apiHospitalElFindAddress',
  v2apiHospitalElUpdate: 'v2apiHospitalElUpdate',
  v2apiHospitalElFindDoctors: 'v2apiHospitalElFindDoctors',
  v2apiHospitalElFindUsers: 'v2apiHospitalElFindUsers',
  v2apiHospitalMFindOne: 'v2apiHospitalMFindOne',
  v2apiHospitalMFindDoctors: 'v2apiHospitalMFindDoctors',
  v2apiHospitalMFindAfterhoursPopup: 'v2apiHospitalMFindAfterhoursPopup',
  v2apiNoticeServicenoticeElCount: 'v2apiNoticeServicenoticeElCount',
  v2apiNoticeServicenoticeElFind: 'v2apiNoticeServicenoticeElFind',
  v2apiNoticeServicenoticeElUpdateRead: 'v2apiNoticeServicenoticeElUpdateRead',
  v2apiNoticeServicenoticeElUpdateReadAll: 'v2apiNoticeServicenoticeElUpdateReadAll',
  v2apiNoticeServicenoticeMCount: 'v2apiNoticeServicenoticeMCount',
  v2apiNoticeServicenoticeMFind: 'v2apiNoticeServicenoticeMFind',
  v2apiNoticeServicenoticeMUpdateRead: 'v2apiNoticeServicenoticeMUpdateRead',
  v2apiNoticeServicenoticeMUpdateReadAll: 'v2apiNoticeServicenoticeMUpdateReadAll',
  v2apiNoticeServicenoticeHmCount: 'v2apiNoticeServicenoticeHmCount',
  v2apiNoticeServicenoticeHmFind: 'v2apiNoticeServicenoticeHmFind',
  v2apiNoticeServicenoticeHmUpdateRead: 'v2apiNoticeServicenoticeHmUpdateRead',
  v2apiNoticeServicenoticeHmUpdateReadAll: 'v2apiNoticeServicenoticeHmUpdateReadAll',
  v2apiNoticeServicenoticeHmUpdateReadReservation: 'v2apiNoticeServicenoticeHmUpdateReadReservation',
  v2apiReservationsettingElFind: 'v2apiReservationsettingElFind',
  v2apiReservationsettingElUpdate: 'v2apiReservationsettingElUpdate',
  v2apiReservationsheetElFind: 'v2apiReservationsheetElFind',
  v2apiReservationsheetElFindOne: 'v2apiReservationsheetElFindOne',
  v2apiReservationsheetElDetail: 'v2apiReservationsheetElDetail',
  v2apiReservationsheetElCreate: 'v2apiReservationsheetElCreate',
  v2apiReservationsheetElUpdate: 'v2apiReservationsheetElUpdate',
  v2apiReservationsheetElRemove: 'v2apiReservationsheetElRemove',
  v2apiReservationsheetElUpdateActivate: 'v2apiReservationsheetElUpdateActivate',
  v2apiReservationsheetMFind: 'v2apiReservationsheetMFind',
  v2apiReservationsheetMDetail: 'v2apiReservationsheetMDetail',
  v2apiReservationsheetMDetailReservationid: 'v2apiReservationsheetMDetailReservationid',
  v2apiReservationsheetMPossibledays: 'v2apiReservationsheetMPossibledays',
  v2apiReservationsheetMReservationlist: 'v2apiReservationsheetMReservationlist',
  v2apiReservationsheetHmFind: 'v2apiReservationsheetHmFind',
  v2apiReservationcolumnElFind: 'v2apiReservationcolumnElFind',
  v2apiReservationcolumnElCreate: 'v2apiReservationcolumnElCreate',
  v2apiReservationcolumnElUpdate: 'v2apiReservationcolumnElUpdate',
  v2apiReservationcolumnElUpdateTimeintervals: 'v2apiReservationcolumnElUpdateTimeintervals',
  v2apiReservationcolumnElRemove: 'v2apiReservationcolumnElRemove',
  v2apiReservationcolumnElDate: 'v2apiReservationcolumnElDate',
  v2apiReservationcolumnMDate: 'v2apiReservationcolumnMDate',
  v2apiReservationcolumnHmDate: 'v2apiReservationcolumnHmDate',
  v2apiReservationprogramElFind: 'v2apiReservationprogramElFind',
  v2apiReservationprogramElCreate: 'v2apiReservationprogramElCreate',
  v2apiReservationprogramElUpdate: 'v2apiReservationprogramElUpdate',
  v2apiReservationprogramElRemove: 'v2apiReservationprogramElRemove',
  v2apiReservationprogramHmFind: 'v2apiReservationprogramHmFind',
  v2apiReservationElCount: 'v2apiReservationElCount',
  v2apiReservationElFind: 'v2apiReservationElFind',
  v2apiReservationElCreate: 'v2apiReservationElCreate',
  v2apiReservationElCreateRegist: 'v2apiReservationElCreateRegist',
  v2apiReservationElUpdate: 'v2apiReservationElUpdate',
  v2apiReservationElRemove: 'v2apiReservationElRemove',
  v2apiReservationElLast: 'v2apiReservationElLast',
  v2apiReservationElFindNext: 'v2apiReservationElFindNext',
  v2apiReservationElFindHistory: 'v2apiReservationElFindHistory',
  v2apiReservationElFindParent: 'v2apiReservationElFindParent',
  v2apiReservationElFindSchedule: 'v2apiReservationElFindSchedule',
  v2apiReservationMFind: 'v2apiReservationMFind',
  v2apiReservationMFindLast: 'v2apiReservationMFindLast',
  v2apiReservationMFindDate: 'v2apiReservationMFindDate',
  v2apiReservationMFindNext: 'v2apiReservationMFindNext',
  v2apiReservationMCreate: 'v2apiReservationMCreate',
  v2apiReservationMUpdate: 'v2apiReservationMUpdate',
  v2apiReservationMRemove: 'v2apiReservationMRemove',
  v2apiReservationMPossibledate: 'v2apiReservationMPossibledate',
  v2apiReservationHmFind: 'v2apiReservationHmFind',
  v2apiReservationHmCreate: 'v2apiReservationHmCreate',
  v2apiReservationHmUpdate: 'v2apiReservationHmUpdate',
  v2apiReservationHmRemove: 'v2apiReservationHmRemove',
  v2apiReservationHmFindHistory: 'v2apiReservationHmFindHistory',
  v2apiReservationvacationElFind: 'v2apiReservationvacationElFind',
  v2apiReservationvacationElFindHospital: 'v2apiReservationvacationElFindHospital',
  v2apiReservationvacationElFindSheet: 'v2apiReservationvacationElFindSheet',
  v2apiReservationvacationElFindColumn: 'v2apiReservationvacationElFindColumn',
  v2apiReservationvacationElCreate: 'v2apiReservationvacationElCreate',
  v2apiReservationvacationElUpdate: 'v2apiReservationvacationElUpdate',
  v2apiReservationvacationElRemove: 'v2apiReservationvacationElRemove',
  v2apiReservationvacationHmFind: 'v2apiReservationvacationHmFind',
  v2apiReservationblockElFind: 'v2apiReservationblockElFind',
  v2apiReservationblockElCreate: 'v2apiReservationblockElCreate',
  v2apiReservationblockElUpdate: 'v2apiReservationblockElUpdate',
  v2apiReservationblockElRemove: 'v2apiReservationblockElRemove',
  v2apiReservationblockHmFind: 'v2apiReservationblockHmFind',
  v2apiReservationblockHmCreate: 'v2apiReservationblockHmCreate',
  v2apiReservationblockHmUpdate: 'v2apiReservationblockHmUpdate',
  v2apiReservationblockHmRemove: 'v2apiReservationblockHmRemove',
  v2apiReservationsheetdatenoteElFind: 'v2apiReservationsheetdatenoteElFind',
  v2apiReservationsheetdatenoteElFindOne: 'v2apiReservationsheetdatenoteElFindOne',
  v2apiReservationsheetdatenoteElUpdate: 'v2apiReservationsheetdatenoteElUpdate',
  v2apiReservationusernoteElFind: 'v2apiReservationusernoteElFind',
  v2apiReservationusernoteElUpdate: 'v2apiReservationusernoteElUpdate',
  v2apiReservationusernoteHmFind: 'v2apiReservationusernoteHmFind',
  v2apiHreservationElFind: 'v2apiHreservationElFind',
  v2apiHreservationHmFind: 'v2apiHreservationHmFind',
  v2apiUsertagElFind: 'v2apiUsertagElFind',
  v2apiUsertagElCreate: 'v2apiUsertagElCreate',
  v2apiUsertagElUpdate: 'v2apiUsertagElUpdate',
  v2apiUsertagElRemove: 'v2apiUsertagElRemove',
  v2apiVideocontentEl: 'v2apiVideocontentEl',
  v2apiVideocontentElCreate: 'v2apiVideocontentElCreate',
  v2apiVideocontentElUpdate: 'v2apiVideocontentElUpdate',
  v2apiVideocontentElRemove: 'v2apiVideocontentElRemove',
  v2apiServicesettingElFind: 'v2apiServicesettingElFind',
  v2apiServicesettingElFindOne: 'v2apiServicesettingElFindOne',
  v2apiServicesettingElCreate: 'v2apiServicesettingElCreate',
  v2apiServicesettingElUpdate: 'v2apiServicesettingElUpdate',
  v2apiServicesettingHmFindOne: 'v2apiServicesettingHmFindOne',
  v2apiIntegrationsettingElFind: 'v2apiIntegrationsettingElFind',
  v2apiIntegrationsettingElFindOne: 'v2apiIntegrationsettingElFindOne',
  v2apiIntegrationsettingElCreate: 'v2apiIntegrationsettingElCreate',
  v2apiIntegrationsettingElUpdate: 'v2apiIntegrationsettingElUpdate',
  v2apiVideocontenthospitalEl: 'v2apiVideocontenthospitalEl',
  v2apiVideocontenthospitalElCreate: 'v2apiVideocontenthospitalElCreate',
  v2apiVideocontenthospitalElFind: 'v2apiVideocontenthospitalElFind',
  v2apiVideocontenthospitalElDetail: 'v2apiVideocontenthospitalElDetail',
  v2apiVideocontenthospitalElUpdate: 'v2apiVideocontenthospitalElUpdate',
  v2apiVideocontenthospitalElRecommend: 'v2apiVideocontenthospitalElRecommend',
  v2apiVideocontenthospitalElRemove: 'v2apiVideocontenthospitalElRemove',
  v2apiVideocontenthospitalElPlay: 'v2apiVideocontenthospitalElPlay',
  v2apiVideocontenthospitalElPlaysub: 'v2apiVideocontenthospitalElPlaysub',
  v2apiVideocontentuserEl: 'v2apiVideocontentuserEl',
  v2apiVideocontentuserElFindstats: 'v2apiVideocontentuserElFindstats',
  v2apiVideocontentuserElCreate: 'v2apiVideocontentuserElCreate',
  v2apiVideocontentuserElDetail: 'v2apiVideocontentuserElDetail',
  v2apiVideocontentuserElUpdate: 'v2apiVideocontentuserElUpdate',
  v2apiVideocontentuserElRemove: 'v2apiVideocontentuserElRemove',
  v2apiVideocontentuserM: 'v2apiVideocontentuserM',
  v2apiVideocontentuserMDetail: 'v2apiVideocontentuserMDetail',
  v2apiVideocontentuserMFind: 'v2apiVideocontentuserMFind',
  v2apiVideocontentuserMFindCount: 'v2apiVideocontentuserMFindCount',
  v2apiVideocontentuserMPlay: 'v2apiVideocontentuserMPlay',
  v2apiVideocontentuserMPlaysub: 'v2apiVideocontentuserMPlaysub',
  v2apiVideocontentuserMUpdateRead: 'v2apiVideocontentuserMUpdateRead',
  v2apiVideocontentuserMUpdateCv: 'v2apiVideocontentuserMUpdateCv',
  v2apiVideocontentuserMUpdatePv: 'v2apiVideocontentuserMUpdatePv',
  v2apiVideocontentuserMShareimage: 'v2apiVideocontentuserMShareimage',
  v2apiHistoryuseractionEl: 'v2apiHistoryuseractionEl',
  v2apiHistoryuseractionElFind: 'v2apiHistoryuseractionElFind',
  v2apiHistoryuseractionElCreate: 'v2apiHistoryuseractionElCreate',
  v2apiHistoryuseractionElUpdate: 'v2apiHistoryuseractionElUpdate',
  v2apiHistoryuseractionElRemove: 'v2apiHistoryuseractionElRemove',
  v2apiHistoryuseractionMCreate: 'v2apiHistoryuseractionMCreate',
  v2apiVideocontentcategoryEl: 'v2apiVideocontentcategoryEl',
  v2apiVideocontentcategoryElCreate: 'v2apiVideocontentcategoryElCreate',
  v2apiVideocontentcategoryElUpdate: 'v2apiVideocontentcategoryElUpdate',
  v2apiVideocontentcategoryElRemove: 'v2apiVideocontentcategoryElRemove',
  v2apiVideocontentcategoryM: 'v2apiVideocontentcategoryM',
  v2apiDashboardDailyUserEl: 'v2apiDashboardDailyUserEl',
  v2apiDashboardDailyUserElFindtop: 'v2apiDashboardDailyUserElFindtop',
  v2apiDashboardDailyColumnEl: 'v2apiDashboardDailyColumnEl',
  v2apiDashboardDailyColumnElFindtop: 'v2apiDashboardDailyColumnElFindtop',
  v2apiDashboardDailyColumnprogramEl: 'v2apiDashboardDailyColumnprogramEl',
  v2apiDashboardDailyColumnprogramElFindtop: 'v2apiDashboardDailyColumnprogramElFindtop',
  v2apiDashboardWeeklyUserEl: 'v2apiDashboardWeeklyUserEl',
  v2apiDashboardWeeklyUserElFindtop: 'v2apiDashboardWeeklyUserElFindtop',
  v2apiDashboardWeeklyColumnEl: 'v2apiDashboardWeeklyColumnEl',
  v2apiDashboardWeeklyColumnElFindtop: 'v2apiDashboardWeeklyColumnElFindtop',
  v2apiDashboardWeeklyColumnprogramEl: 'v2apiDashboardWeeklyColumnprogramEl',
  v2apiDashboardWeeklyColumnprogramElFindtop: 'v2apiDashboardWeeklyColumnprogramElFindtop',
  v2apiDashboardMonthlyUserEl: 'v2apiDashboardMonthlyUserEl',
  v2apiDashboardMonthlyUserElFindtop: 'v2apiDashboardMonthlyUserElFindtop',
  v2apiDashboardMonthlyColumnEl: 'v2apiDashboardMonthlyColumnEl',
  v2apiDashboardMonthlyColumnElFindtop: 'v2apiDashboardMonthlyColumnElFindtop',
  v2apiDashboardMonthlyColumnprogramEl: 'v2apiDashboardMonthlyColumnprogramEl',
  v2apiDashboardMonthlyColumnprogramElFindtop: 'v2apiDashboardMonthlyColumnprogramElFindtop',
  v2apiDashboardHserviceElCountJoinuser: 'v2apiDashboardHserviceElCountJoinuser',
  v2apiDashboardDailyColumnprogramElSheet: 'v2apiDashboardDailyColumnprogramElSheet',
  v2apiDashboardWeeklyColumnprogramElSheet: 'v2apiDashboardWeeklyColumnprogramElSheet',
  v2apiDashboardMonthlyColumnprogramElSheet: 'v2apiDashboardMonthlyColumnprogramElSheet',
  v2apiTreatmentcategoryEl: 'v2apiTreatmentcategoryEl',
  v2apiTreatmentcategoryElCreate: 'v2apiTreatmentcategoryElCreate',
  v2apiTreatmentcategoryElUpdate: 'v2apiTreatmentcategoryElUpdate',
  v2apiTreatmentcategoryElRemove: 'v2apiTreatmentcategoryElRemove',
  v2apiTreatmentCautionEl: 'v2apiTreatmentCautionEl',
  v2apiTreatmentcautionElCreate: 'v2apiTreatmentcautionElCreate',
  v2apiTreatmentcautionElUpdate: 'v2apiTreatmentcautionElUpdate',
  v2apiTreatmentcautionElRemove: 'v2apiTreatmentcautionElRemove',
  v2apiEventHospitalEventElFind: 'v2apiEventHospitalEventElFind',
  v2apiEventHospitalEventElFindSort: 'v2apiEventHospitalEventElFindSort',
  v2apiEventHospitalEventElCount: 'v2apiEventHospitalEventElCount',
  v2apiEventHospitalEventElCreate: 'v2apiEventHospitalEventElCreate',
  v2apiEventHospitalEventElUpdate: 'v2apiEventHospitalEventElUpdate',
  v2apiEventHospitalEventElRemove: 'v2apiEventHospitalEventElRemove',
  v2apiEventHospitalEventMFind: 'v2apiEventHospitalEventMFind',
  v2apiEventHospitalEventMFindOne: 'v2apiEventHospitalEventMFindOne',
  v2apiEventHospitalEventMFindDetail: 'v2apiEventHospitalEventMFindDetail',
  v2apiEventHospitalAftercareElFind: 'v2apiEventHospitalAftercareElFind',
  v2apiEventHospitalAftercareElFindSort: 'v2apiEventHospitalAftercareElFindSort',
  v2apiEventHospitalAftercareElCount: 'v2apiEventHospitalAftercareElCount',
  v2apiEventHospitalAftercareElCreate: 'v2apiEventHospitalAftercareElCreate',
  v2apiEventHospitalAftercareElUpdate: 'v2apiEventHospitalAftercareElUpdate',
  v2apiEventHospitalAftercareElRemove: 'v2apiEventHospitalAftercareElRemove',
  v2apiEventHospitalAftercareMFind: 'v2apiEventHospitalAftercareMFind',
  v2apiEventHospitalAftercareMFindDetail: 'v2apiEventHospitalAftercareMFindDetail',
  v2apiHserviceElSuspend: 'v2apiHserviceElSuspend',
  v2apiHserviceElUpdate: 'v2apiHserviceElUpdate',
  v2apiHserviceElFindSimple: 'v2apiHserviceElFindSimple',
  v2apiHserviceElFindDetail: 'v2apiHserviceElFindDetail',
  v2apiHserviceElFindTreatmentcategoryCount: 'v2apiHserviceElFindTreatmentcategoryCount',
  v2apiHserviceElFindTreatmentcategory: 'v2apiHserviceElFindTreatmentcategory',
  v2apiHserviceElFindUnjoined: 'v2apiHserviceElFindUnjoined',
  v2apiHserviceElFindUnjoinedCount: 'v2apiHserviceElFindUnjoinedCount',
  v2apiHserviceElFindNeedsetting: 'v2apiHserviceElFindNeedsetting',
  v2apiHserviceElFindPatientsettingCount: 'v2apiHserviceElFindPatientsettingCount',
  v2apiHserviceElFind: 'v2apiHserviceElFind',
  v2apiHserviceElFindCount: 'v2apiHserviceElFindCount',
  v2apiHserviceElFindChat: 'v2apiHserviceElFindChat',
  v2apiHserviceElFindChatCount: 'v2apiHserviceElFindChatCount',
  v2apiHserviceElUpdateSetting: 'v2apiHserviceElUpdateSetting',
  v2apiHserviceElUpdateNeedvcu: 'v2apiHserviceElUpdateNeedvcu',
  v2apiHserviceMSuspend: 'v2apiHserviceMSuspend',
  v2apiHserviceMUpdateSelect: 'v2apiHserviceMUpdateSelect',
  v2apiHserviceHmFindSimple: 'v2apiHserviceHmFindSimple',
  v2apiMemoElCreate: 'v2apiMemoElCreate',
  v2apiMemoElFind: 'v2apiMemoElFind',
  v2apiMemoElFindManage: 'v2apiMemoElFindManage',
  v2apiMemoElFindNurse: 'v2apiMemoElFindNurse',
  v2apiMemoElFindPatient: 'v2apiMemoElFindPatient',
  v2apiMemoElFindLast: 'v2apiMemoElFindLast',
  v2apiMemoElFindManageCount: 'v2apiMemoElFindManageCount',
  v2apiMemoElFindNurseCount: 'v2apiMemoElFindNurseCount',
  v2apiMemoElFindPatientCount: 'v2apiMemoElFindPatientCount',
  v2apiMemoElFindManageLast: 'v2apiMemoElFindManageLast',
  v2apiMemoElFindNurseLast: 'v2apiMemoElFindNurseLast',
  v2apiMemoElFindPatientLast: 'v2apiMemoElFindPatientLast',
  v2apiMemoElUpdate: 'v2apiMemoElUpdate',
  v2apiMemoElRemove: 'v2apiMemoElRemove',
  v2apiMemoElUpdatePin: 'v2apiMemoElUpdatePin',
  v2apiMemoElRead: 'v2apiMemoElRead',
  v2apiPurchaserecordElFind: 'v2apiPurchaserecordElFind',
  v2apiPurchaserecordElCount: 'v2apiPurchaserecordElCount',
  v2apiPurchaserecordElCreate: 'v2apiPurchaserecordElCreate',
  v2apiPurchaserecordElUpdate: 'v2apiPurchaserecordElUpdate',
  v2apiPurchaserecordElRemove: 'v2apiPurchaserecordElRemove',
  v2apiHospitalAccountElFind: 'v2apiHospitalAccountElFind',
  v2apiHospitalAccountElCreate: 'v2apiHospitalAccountElCreate',
  v2apiHospitalAccountElUpdate: 'v2apiHospitalAccountElUpdate',
  v2apiHospitalAccountElIdcheck: 'v2apiHospitalAccountElIdcheck',
  v2apiHospitalAccountElReset: 'v2apiHospitalAccountElReset',
  v2apiHospitalAccountElRemove: 'v2apiHospitalAccountElRemove',
  v2apiHospitalAuthorizationtypeElFind: 'v2apiHospitalAuthorizationtypeElFind',
  v2apiHospitalAuthorizationtypeElUpdate: 'v2apiHospitalAuthorizationtypeElUpdate',
  v2apiHealthcontentsCategoryElCreate: 'v2apiHealthcontentsCategoryElCreate',
  v2apiHealthcontentsCategoryElFind: 'v2apiHealthcontentsCategoryElFind',
  v2apiHealthcontentsCategoryElUpdate: 'v2apiHealthcontentsCategoryElUpdate',
  v2apiHealthcontentsCategoryElRemove: 'v2apiHealthcontentsCategoryElRemove',
  v2apiHealthcontentsCategoryMFind: 'v2apiHealthcontentsCategoryMFind',
  v2apiHealthcontentsContentElCreate: 'v2apiHealthcontentsContentElCreate',
  v2apiHealthcontentsContentElFind: 'v2apiHealthcontentsContentElFind',
  v2apiHealthcontentsContentElUpdate: 'v2apiHealthcontentsContentElUpdate',
  v2apiHealthcontentsContentElRemove: 'v2apiHealthcontentsContentElRemove',
  v2apiHealthcontentsContentMFind: 'v2apiHealthcontentsContentMFind',
  v2apiHealthcontentsContentMCount: 'v2apiHealthcontentsContentMCount',
  v2apiHealthcontentsBookmarkMCreate: 'v2apiHealthcontentsBookmarkMCreate',
  v2apiHealthcontentsBookmarkMFind: 'v2apiHealthcontentsBookmarkMFind',
  v2apiHospitalcontentsContentElCreate: 'v2apiHospitalcontentsContentElCreate',
  v2apiHospitalcontentsContentElFind: 'v2apiHospitalcontentsContentElFind',
  v2apiHospitalcontentsContentElRemove: 'v2apiHospitalcontentsContentElRemove',
  v2apiHospitalcontentsContentElUpdate: 'v2apiHospitalcontentsContentElUpdate',
  v2apiHospitalcontentsContentMCount: 'v2apiHospitalcontentsContentMCount',
  v2apiHospitalcontentsContentMFind: 'v2apiHospitalcontentsContentMFind',
  v2apiHospitalcontentsCategoryElCreate: 'v2apiHospitalcontentsCategoryElCreate',
  v2apiHospitalcontentsCategoryElFind: 'v2apiHospitalcontentsCategoryElFind',
  v2apiHospitalcontentsCategoryElFindOne: 'v2apiHospitalcontentsCategoryElFindOne',
  v2apiHospitalcontentsCategoryElRemove: 'v2apiHospitalcontentsCategoryElRemove',
  v2apiHospitalcontentsCategoryElUpdate: 'v2apiHospitalcontentsCategoryElUpdate',
  v2apiHospitalcontentsCategoryMFind: 'v2apiHospitalcontentsCategoryMFind',
  v2apiHospitalcontentsBookmarkMCreate: 'v2apiHospitalcontentsBookmarkMCreate',
  v2apiHospitalcontentsBookmarkMFind: 'v2apiHospitalcontentsBookmarkMFind',
  v2apiShortlinkVideocontentMCreate: 'v2apiShortlinkVideocontentMCreate',
  v2apiShortlinkVideocontentMUpdateGr: 'v2apiShortlinkVideocontentMUpdateGr',
  v2apiShortlinkEventMCreate: 'v2apiShortlinkEventMCreate',
  v2apiShortlinkAftercareMCreate: 'v2apiShortlinkAftercareMCreate',
  v2apiShortlinkHserviceMFindOne: 'v2apiShortlinkHserviceMFindOne',
  v2sharedVideocontent: 'v2sharedVideocontent',
  v2sharedEvent: 'v2sharedEvent',
  v2sharedAftercare: 'v2sharedAftercare',
  v2apiPushsettingElFindOne: 'v2apiPushsettingElFindOne',
  v2apiPushsettingElUpdate: 'v2apiPushsettingElUpdate',
  v2apiPushsettingMFindOne: 'v2apiPushsettingMFindOne',
  v2apiPushsettingMUpdate: 'v2apiPushsettingMUpdate',
  v2apiNoticeHospitalElFind: 'v2apiNoticeHospitalElFind',
  v2apiNoticeHospitalElFindSimple: 'v2apiNoticeHospitalElFindSimple',
  v2apiNoticeHospitalElCount: 'v2apiNoticeHospitalElCount',
  v2apiNoticeHospitalElCreate: 'v2apiNoticeHospitalElCreate',
  v2apiNoticeHospitalElUpdate: 'v2apiNoticeHospitalElUpdate',
  v2apiNoticeHospitalElRemove: 'v2apiNoticeHospitalElRemove',
  v2apiNoticeHospitalMFind: 'v2apiNoticeHospitalMFind',
  v2apiNoticeHospitalMFindOne: 'v2apiNoticeHospitalMFindOne',
  v2apiNoticeHospitalMFindDetail: 'v2apiNoticeHospitalMFindDetail',
  v2apiPopupHospitalElFind: 'v2apiPopupHospitalElFind',
  v2apiPopupHospitalElCount: 'v2apiPopupHospitalElCount',
  v2apiPopupHospitalElCreate: 'v2apiPopupHospitalElCreate',
  v2apiPopupHospitalElUpdate: 'v2apiPopupHospitalElUpdate',
  v2apiPopupHospitalElRemove: 'v2apiPopupHospitalElRemove',
  v2apiPopupHospitalMFind: 'v2apiPopupHospitalMFind',
  v2apiPopupHospitalMIncView: 'v2apiPopupHospitalMIncView',
  v2apiNotificationsoundElCount: 'v2apiNotificationsoundElCount',
  v2apiNotificationsoundEl: 'v2apiNotificationsoundEl',
  v2apiNotificationsoundElCreate: 'v2apiNotificationsoundElCreate',
  v2apiNotificationsoundElUpdate: 'v2apiNotificationsoundElUpdate',
  v2apiV2ReservationElCount: 'v2apiV2ReservationElCount',
  v2apiV2ReservationElFind: 'v2apiV2ReservationElFind',
  v2apiV2ReservationElFindHistory: 'v2apiV2ReservationElFindHistory',
  v2apiV2ReservationElFindNext: 'v2apiV2ReservationElFindNext',
  v2apiV2ReservationElCreate: 'v2apiV2ReservationElCreate',
  v2apiV2ReservationElCreateRegist: 'v2apiV2ReservationElCreateRegist',
  v2apiV2ReservationElCreateUnregistered: 'v2apiV2ReservationElCreateUnregistered',
  v2apiV2ReservationElUpdate: 'v2apiV2ReservationElUpdate',
  v2apiV2ReservationElRemove: 'v2apiV2ReservationElRemove',
  v2apiV2ReservationElSendPush: 'v2apiV2ReservationElSendPush',
  v2apiV2ReservationMFind: 'v2apiV2ReservationMFind',
  v2apiV2ReservationMFindOne: 'v2apiV2ReservationMFindOne',
  v2apiV2ReservationMFindDate: 'v2apiV2ReservationMFindDate',
  v2apiV2ReservationMFindLast: 'v2apiV2ReservationMFindLast',
  v2apiV2ReservationMFindNext: 'v2apiV2ReservationMFindNext',
  v2apiV2ReservationMPossibledate: 'v2apiV2ReservationMPossibledate',
  v2apiV2ReservationMPossibletime: 'v2apiV2ReservationMPossibletime',
  v2apiV2ReservationMCreate: 'v2apiV2ReservationMCreate',
  v2apiV2ReservationMUpdate: 'v2apiV2ReservationMUpdate',
  v2apiV2ReservationMCancel: 'v2apiV2ReservationMCancel',
  v2apiV2ReservationcellElFind: 'v2apiV2ReservationcellElFind',
  v2apiV2ReservationcellElCreate: 'v2apiV2ReservationcellElCreate',
  v2apiV2ReservationcellElUpdate: 'v2apiV2ReservationcellElUpdate',
  v2apiV2ReservationcellElRemove: 'v2apiV2ReservationcellElRemove',
  v2apiV2ReservationblockElFind: 'v2apiV2ReservationblockElFind',
  v2apiV2ReservationblockElCreate: 'v2apiV2ReservationblockElCreate',
  v2apiV2ReservationblockElUpdate: 'v2apiV2ReservationblockElUpdate',
  v2apiV2ReservationblockElRemove: 'v2apiV2ReservationblockElRemove',
  v2apiV2ReservationblockMFind: 'v2apiV2ReservationblockMFind',
  v2apiV2ReservationsettingElFindOne: 'v2apiV2ReservationsettingElFindOne',
  v2apiV2ReservationsettingElUpdate: 'v2apiV2ReservationsettingElUpdate',
  v2apiV2ReservationsettingMFindOne: 'v2apiV2ReservationsettingMFindOne',
  v2apiV2ReservationstatusElFind: 'v2apiV2ReservationstatusElFind',
  v2apiV2ReservationstatusElCreate: 'v2apiV2ReservationstatusElCreate',
  v2apiV2ReservationstatusElUpdate: 'v2apiV2ReservationstatusElUpdate',
  v2apiV2ReservationstatusElRemove: 'v2apiV2ReservationstatusElRemove',
  v2apiV2ReservationstatusElSendPush: 'v2apiV2ReservationstatusElSendPush',
  v2apiV2ReservationgroupElFind: 'v2apiV2ReservationgroupElFind',
  v2apiV2ReservationgroupElFindOne: 'v2apiV2ReservationgroupElFindOne',
  v2apiV2ReservationgroupElFindRemoved: 'v2apiV2ReservationgroupElFindRemoved',
  v2apiV2ReservationgroupElCreate: 'v2apiV2ReservationgroupElCreate',
  v2apiV2ReservationgroupElUpdate: 'v2apiV2ReservationgroupElUpdate',
  v2apiV2ReservationgroupElRemove: 'v2apiV2ReservationgroupElRemove',
  v2apiV2ReservationgroupElUpdateSort: 'v2apiV2ReservationgroupElUpdateSort',
  v2apiV2ReservationcolumnElFind: 'v2apiV2ReservationcolumnElFind',
  v2apiV2ReservationcolumnElFindRemoved: 'v2apiV2ReservationcolumnElFindRemoved',
  v2apiV2ReservationcolumnElCreate: 'v2apiV2ReservationcolumnElCreate',
  v2apiV2ReservationcolumnElUpdate: 'v2apiV2ReservationcolumnElUpdate',
  v2apiV2ReservationcolumnElRemove: 'v2apiV2ReservationcolumnElRemove',
  v2apiV2ReservationcolumnElUpdateSort: 'v2apiV2ReservationcolumnElUpdateSort',
  v2apiV2ReservationcolumnMDate: 'v2apiV2ReservationcolumnMDate',
  v2apiV2ReservationcolumnMActive: 'v2apiV2ReservationcolumnMActive',
  v2apiV2ReservationprogramElFind: 'v2apiV2ReservationprogramElFind',
  v2apiV2ReservationprogramElCreate: 'v2apiV2ReservationprogramElCreate',
  v2apiV2ReservationprogramElUpdate: 'v2apiV2ReservationprogramElUpdate',
  v2apiV2ReservationprogramElRemove: 'v2apiV2ReservationprogramElRemove',
  v2apiV2ReservationprogramElSendPush: 'v2apiV2ReservationprogramElSendPush',
  v2apiV2ReservationprogramMFind: 'v2apiV2ReservationprogramMFind',
  v2apiV2ReservationusernoteElFind: 'v2apiV2ReservationusernoteElFind',
  v2apiV2ReservationusernoteElUpdate: 'v2apiV2ReservationusernoteElUpdate',
  v2apiV2ReservationdatenoteElFind: 'v2apiV2ReservationdatenoteElFind',
  v2apiV2ReservationdatenoteElFindDate: 'v2apiV2ReservationdatenoteElFindDate',
  v2apiV2ReservationdatenoteElUpdate: 'v2apiV2ReservationdatenoteElUpdate',
  v2apiV2HreservationElFind: 'v2apiV2HreservationElFind',
  v2apiV2HreservationElCount: 'v2apiV2HreservationElCount',
  v2apiV2DepartmentElFind: 'v2apiV2DepartmentElFind',
  v2apiV2DepartmentElFindOne: 'v2apiV2DepartmentElFindOne',
  v2apiV2DepartmentElCreate: 'v2apiV2DepartmentElCreate',
  v2apiV2DepartmentElUpdate: 'v2apiV2DepartmentElUpdate',
  v2apiV2DepartmentElUpdateSort: 'v2apiV2DepartmentElUpdateSort',
  v2apiV2DepartmentElRemove: 'v2apiV2DepartmentElRemove',
  v2apiV2EmployeeElFind: 'v2apiV2EmployeeElFind',
  v2apiV2EmployeeElCreate: 'v2apiV2EmployeeElCreate',
  v2apiV2EmployeeElUpdate: 'v2apiV2EmployeeElUpdate',
  v2apiV2EmployeeElUpdateSort: 'v2apiV2EmployeeElUpdateSort',
  v2apiV2EmployeeElRemove: 'v2apiV2EmployeeElRemove',
  v2apiV2WorkcodeElFind: 'v2apiV2WorkcodeElFind',
  v2apiV2WorkcodeElFindSchedule: 'v2apiV2WorkcodeElFindSchedule',
  v2apiV2WorkcodeElCreate: 'v2apiV2WorkcodeElCreate',
  v2apiV2WorkcodeElUpdate: 'v2apiV2WorkcodeElUpdate',
  v2apiV2WorkcodeElRemove: 'v2apiV2WorkcodeElRemove',
  v2apiV2WorkcodeElSendPush: 'v2apiV2WorkcodeElSendPush',
  v2apiV2WorkrecordElFindDate: 'v2apiV2WorkrecordElFindDate',
  v2apiV2WorkrecordElFindSchedule: 'v2apiV2WorkrecordElFindSchedule',
  v2apiV2WorkrecordElUpdate: 'v2apiV2WorkrecordElUpdate',
  v2apiV2WorkrecordElUpdateBatch: 'v2apiV2WorkrecordElUpdateBatch',
  v2apiV2WorkrecordElRemove: 'v2apiV2WorkrecordElRemove',
  v2apiV2WorkrecordElSendPush: 'v2apiV2WorkrecordElSendPush',
  v2apiV2WorkholidayElFindDate: 'v2apiV2WorkholidayElFindDate',
  v2apiV2WorkholidayElCreate: 'v2apiV2WorkholidayElCreate',
  v2apiV2WorkholidayElUpdate: 'v2apiV2WorkholidayElUpdate',
  v2apiV2WorkholidayElRemove: 'v2apiV2WorkholidayElRemove',
  v2apiV2WorkholidayMFindDate: 'v2apiV2WorkholidayMFindDate',
  v2apiStatsV2ReservationDailyElFind: 'v2apiStatsV2ReservationDailyElFind',
  v2apiStatsV2ReservationDailyElFindHospital: 'v2apiStatsV2ReservationDailyElFindHospital',
  v2apiStatsV2ReservationMonthlyElFind: 'v2apiStatsV2ReservationMonthlyElFind',
  v2apiStatsV2ReservationMonthlyElFindHospital: 'v2apiStatsV2ReservationMonthlyElFindHospital',
  v2apiStatsV2WorkrecordDailyElFind: 'v2apiStatsV2WorkrecordDailyElFind',
  v2apiSiAlHserviceElFindSimple: 'v2apiSiAlHserviceElFindSimple',
  v2publicServersettingMFind: 'v2publicServersettingMFind',
  v2publicServersettingHmFind: 'v2publicServersettingHmFind',
  v2apiPushRequestElFind: 'v2apiPushRequestElFind',
  v2apiPushRequestElCreate: 'v2apiPushRequestElCreate',
  v2apiPushResponseElFind: 'v2apiPushResponseElFind',
  v2apiUseractionHospitaleventMCreate: 'v2apiUseractionHospitaleventMCreate',
  v2adminHealthcontentPushElCreate: 'v2adminHealthcontentPushElCreate',
  v2adminHealthcontentPushElFind: 'v2adminHealthcontentPushElFind',
  v2adminHealthcontentPushElRemove: 'v2adminHealthcontentPushElRemove',
  v2adminHealthcontentContentElCreate: 'v2adminHealthcontentContentElCreate',
  v2adminHealthcontentContentElFind: 'v2adminHealthcontentContentElFind',
  v2adminHealthcontentContentElRemove: 'v2adminHealthcontentContentElRemove',
  v2adminHealthcontentContentElUpdate: 'v2adminHealthcontentContentElUpdate',
  v2adminHealthcontentCategoryElFind: 'v2adminHealthcontentCategoryElFind',
  v2adminHomecareVideocontentHlsvideoElCreate: 'v2adminHomecareVideocontentHlsvideoElCreate',
  v2adminHomecareVideocontentHlsvideoElFind: 'v2adminHomecareVideocontentHlsvideoElFind',
  v2adminHomecareVideocontentHlsvideoElUpdate: 'v2adminHomecareVideocontentHlsvideoElUpdate',
  v2adminHomecareVideocontentHlsvideoElCount: 'v2adminHomecareVideocontentHlsvideoElCount',
  v2adminHomecareVideocontentVideocontentglobalsettingElCreate: 'v2adminHomecareVideocontentVideocontentglobalsettingElCreate',
  v2adminHomecareVideocontentVideocontentglobalsettingElFind: 'v2adminHomecareVideocontentVideocontentglobalsettingElFind',
  v2adminHomecareVideocontentVideocontentglobalsettingElUpdate: 'v2adminHomecareVideocontentVideocontentglobalsettingElUpdate',
  v2adminHomecareVideocontentVideocontentElCreate: 'v2adminHomecareVideocontentVideocontentElCreate',
  v2adminHomecareVideocontentVideocontentElFind: 'v2adminHomecareVideocontentVideocontentElFind',
  v2adminHomecareVideocontentVideocontentElUpdate: 'v2adminHomecareVideocontentVideocontentElUpdate',
  v2adminHomecareVideocontentVideocontentElRemove: 'v2adminHomecareVideocontentVideocontentElRemove',
  v2adminHomecareVideocontentVideocontentElCount: 'v2adminHomecareVideocontentVideocontentElCount',
  v2adminHomecareVideocontentVideocontenthospitalElFind: 'v2adminHomecareVideocontentVideocontenthospitalElFind',
  v2adminHomecareVideocontentVideocontenthospitalElCreate: 'v2adminHomecareVideocontentVideocontenthospitalElCreate',
  v2adminHomecareVideocontentVideocontenthospitalElUpdate: 'v2adminHomecareVideocontentVideocontenthospitalElUpdate',
  v2adminHomecareVideocontentVideocontenthospitalElCopyAll: 'v2adminHomecareVideocontentVideocontenthospitalElCopyAll',
  v2adminHomecareVideocontentVideocontenthospitalElCopyGlobalcategory: 'v2adminHomecareVideocontentVideocontenthospitalElCopyGlobalcategory',
  v2adminHomecareVideocontentVideocontenthospitalElCopyHospitalcategory: 'v2adminHomecareVideocontentVideocontenthospitalElCopyHospitalcategory',
  v2adminHomecareVideocontentVideocontentcategoryElCreate: 'v2adminHomecareVideocontentVideocontentcategoryElCreate',
  v2adminHomecareVideocontentVideocontentcategoryElFind: 'v2adminHomecareVideocontentVideocontentcategoryElFind',
  v2adminHomecareVideocontentVideocontentcategoryElFindOne: 'v2adminHomecareVideocontentVideocontentcategoryElFindOne',
  v2adminHomecareVideocontentVideocontentcategoryElFindTop: 'v2adminHomecareVideocontentVideocontentcategoryElFindTop',
  v2adminHomecareVideocontentVideocontentcategoryElFindLast: 'v2adminHomecareVideocontentVideocontentcategoryElFindLast',
  v2adminHomecareVideocontentVideocontentcategoryElFindConditioning: 'v2adminHomecareVideocontentVideocontentcategoryElFindConditioning',
  v2adminHomecareVideocontentVideocontentcategoryElUpdate: 'v2adminHomecareVideocontentVideocontentcategoryElUpdate',
  v2adminHomecareVideocontentVideocontentcategoryElUpdateHidden: 'v2adminHomecareVideocontentVideocontentcategoryElUpdateHidden',
  v2adminHomecareVideocontentVideocontentcategoryElUpdateTypename: 'v2adminHomecareVideocontentVideocontentcategoryElUpdateTypename',
  v2adminHospitalElFind: 'v2adminHospitalElFind',
  v2adminHospitalElFindNurse: 'v2adminHospitalElFindNurse',
  v2adminHospitalElCreate: 'v2adminHospitalElCreate',
  v2adminHospitalElCount: 'v2adminHospitalElCount',
  v2adminHospitalElUpdate: 'v2adminHospitalElUpdate',
  v2adminHospitalElFindDoctor: 'v2adminHospitalElFindDoctor',
  v2adminHospitalElCountDoctor: 'v2adminHospitalElCountDoctor',
  v2adminHospitalElCreateDoctor: 'v2adminHospitalElCreateDoctor',
  v2adminHospitalElUpdateDoctor: 'v2adminHospitalElUpdateDoctor',
  v2adminHserviceElFindIsclosed: 'v2adminHserviceElFindIsclosed',
  v2adminHserviceElUpdateSuspendAll: 'v2adminHserviceElUpdateSuspendAll',
  v2adminNurseElFind: 'v2adminNurseElFind',
  v2adminNurseElCreate: 'v2adminNurseElCreate',
  v2adminNurseElUpdate: 'v2adminNurseElUpdate',
  v2adminNurseElCount: 'v2adminNurseElCount',
  v2adminServicesettingElFind: 'v2adminServicesettingElFind',
  v2adminServicesettingElFindOne: 'v2adminServicesettingElFindOne',
  v2adminServicesettingElFindUsingvc: 'v2adminServicesettingElFindUsingvc',
  v2adminServicesettingElCreate: 'v2adminServicesettingElCreate',
  v2adminServicesettingElCreateSample: 'v2adminServicesettingElCreateSample',
  v2adminServicesettingElCount: 'v2adminServicesettingElCount',
  v2adminServicesettingElUpdate: 'v2adminServicesettingElUpdate',
  v2adminServicesettingElUpdateSample: 'v2adminServicesettingElUpdateSample',
  v2adminAuthorizationtypeElFind: 'v2adminAuthorizationtypeElFind',
  v2adminTreatmentcategoryElCreate: 'v2adminTreatmentcategoryElCreate',
  v2adminSettingVersionElFind: 'v2adminSettingVersionElFind',
  v2adminSettingVersionElUpdate: 'v2adminSettingVersionElUpdate',
  v2adminIntegrationsettingElFind: 'v2adminIntegrationsettingElFind',
  v2adminIntegrationsettingElFindOne: 'v2adminIntegrationsettingElFindOne',
  v2adminIntegrationsettingElCreate: 'v2adminIntegrationsettingElCreate',
  v2adminIntegrationsettingElUpdate: 'v2adminIntegrationsettingElUpdate',
  v2adminAnsweringchatElFind: 'v2adminAnsweringchatElFind',
  v2adminAnsweringchatElCount: 'v2adminAnsweringchatElCount',
  v2adminAnsweringchatsettingElFind: 'v2adminAnsweringchatsettingElFind',
  v2adminAnsweringchatsettingElCreate: 'v2adminAnsweringchatsettingElCreate',
  v2adminAnsweringchatsettingElUpdate: 'v2adminAnsweringchatsettingElUpdate',
  v2adminMonitoringHospitalElFind: 'v2adminMonitoringHospitalElFind',
  v2adminMonitoringHospitalElCount: 'v2adminMonitoringHospitalElCount',
  v2adminMonitoringHospitalElCreate: 'v2adminMonitoringHospitalElCreate',
  v2adminMonitoringHospitalElUpdate: 'v2adminMonitoringHospitalElUpdate',
  v2adminMonitoringHospitalElRemove: 'v2adminMonitoringHospitalElRemove',
  v2adminPurchaserecordElFind: 'v2adminPurchaserecordElFind',
  v2adminEventHospitalEventElFind: 'v2adminEventHospitalEventElFind',
  v2adminEventHospitalEventElFindOne: 'v2adminEventHospitalEventElFindOne',
  v2apiReservedsmsElCount: 'v2apiReservedsmsElCount',
  v2apiReservedsmsElFind: 'v2apiReservedsmsElFind',
  v2adminCaCareservedchatElCount: 'v2adminCaCareservedchatElCount',
  v2adminCaCareservedchatElFind: 'v2adminCaCareservedchatElFind',
  v2adminCaCareservedchatElCreate: 'v2adminCaCareservedchatElCreate',
  v2adminCaCareservedchatElCreatemany: 'v2adminCaCareservedchatElCreatemany',
  v2adminStatsEventHospitalEventElFind: 'v2adminStatsEventHospitalEventElFind',
  v2adminStatsEventHospitalEventElCount: 'v2adminStatsEventHospitalEventElCount',
  v2adminStatsEventHospitalEventpushElFind: 'v2adminStatsEventHospitalEventpushElFind',
  v2adminStatsEventHospitalEventpushElCount: 'v2adminStatsEventHospitalEventpushElCount',
  v2publicEmailSend: 'v2publicEmailSend',
  v2publicRegistKakaoConsult: 'v2publicRegistKakaoConsult',
  v2publicRegistKakaoFriend: 'v2publicRegistKakaoFriend',
  apitestGetHandler: 'apitestGetHandler',
  apitestPostHandler: 'apitestPostHandler',
  apitestPutHandler: 'apitestPutHandler',
  apitestPatchHandler: 'apitestPatchHandler',
  apitestDeleteHandler: 'apitestDeleteHandler',
  apitestChatElFindHandler: 'apitestChatElFindHandler',
  apitestChatElSendHnoticeHandler: 'apitestChatElSendHnoticeHandler',
  shortLinkMHserviceInfoHandler: 'shortLinkMHserviceInfoHandler',
  shortLinkMUserInfoHandler: 'shortLinkMUserInfoHandler',
  phoneMRequestHandler: 'phoneMRequestHandler',
  phoneMRequestChangePhoneHandler: 'phoneMRequestChangePhoneHandler',
  phoneMRequestUnidentifyHandler: 'phoneMRequestUnidentifyHandler',
  phoneMVerifyHandler: 'phoneMVerifyHandler',
  phoneMChangeHandler: 'phoneMChangeHandler',
  elPhoneCodeRequestHandler: 'elPhoneCodeRequestHandler',
  elPhoneCodeVerifyingRequestHandler: 'elPhoneCodeVerifyingRequestHandler',
  passwordMHasPasswordHandler: 'passwordMHasPasswordHandler',
  mPasswordUpdateHandler: 'mPasswordUpdateHandler',
  joinMMassiveLinkHandler: 'joinMMassiveLinkHandler',
  joinMReleasePendingHandler: 'joinMReleasePendingHandler',
  loginMHandler: 'loginMHandler',
  loginMCheckPhoneHandler: 'loginMCheckPhoneHandler',
  elLoginAndTokenHandler: 'elLoginAndTokenHandler',
  elLogoutHandler: 'elLogoutHandler',
  hmLoginAndTokenHandler: 'hmLoginAndTokenHandler',
  hmLogoutHandler: 'hmLogoutHandler',
  apiElUpdateDeviceinfoHandler: 'apiElUpdateDeviceinfoHandler',
  userMPhoneCodeHandler: 'userMPhoneCodeHandler',
  userMPhoneUpdateHandler: 'userMPhoneUpdateHandler',
  chatRoomMUpdateHandler: 'chatRoomMUpdateHandler',
  chatRoomMFindOneHandler: 'chatRoomMFindOneHandler',
  chatRoomMUpdateReadHandler: 'chatRoomMUpdateReadHandler',
  chatRoomElFindServiceInformationHandler: 'chatRoomElFindServiceInformationHandler',
  chatRoomHmFindServiceInformationHandler: 'chatRoomHmFindServiceInformationHandler',
  chatMCreateHAftercareHandler: 'chatMCreateHAftercareHandler',
  chatMCreateHEventHandler: 'chatMCreateHEventHandler',
  chatMCreateReservationHandler: 'chatMCreateReservationHandler',
  chatMCountHandler: 'chatMCountHandler',
  chatMFindHandler: 'chatMFindHandler',
  chatRoomsLatestConsultationGuideHandler: 'chatRoomsLatestConsultationGuideHandler',
  hospitalsBackgroundJobsHandler: 'hospitalsBackgroundJobsHandler',
  countryMFindHandler: 'countryMFindHandler',
  countryMFindOneHandler: 'countryMFindOneHandler',
  targetableNationalitiesHandler: 'targetableNationalitiesHandler',
  singleAlgorithmMessageHandler: 'singleAlgorithmMessageHandler',
  singleAlgorithmHandler: 'singleAlgorithmHandler',
  marketingAlgorithmTargetCountHandler: 'marketingAlgorithmTargetCountHandler',
  submittingSurveyResponseHandler: 'submittingSurveyResponseHandler',
  sendingConsultationAlgorithmMessageBatchHandler: 'sendingConsultationAlgorithmMessageBatchHandler',
  sendingMarketingAlgorithmMessageBatchHandler: 'sendingMarketingAlgorithmMessageBatchHandler',
  applyingCommonAlgorithmHandler: 'applyingCommonAlgorithmHandler',
  generatingSurveyHandler: 'generatingSurveyHandler',
  updateAlgorithmMainDataHandler: 'updateAlgorithmMainDataHandler',
  sendingCommonAlgorithmMessageBatchHandler: 'sendingCommonAlgorithmMessageBatchHandler',
  renewingKakaoTemplateStatusBatchHandler: 'renewingKakaoTemplateStatusBatchHandler',
  singleHospitalPushSettingHandler: 'singleHospitalPushSettingHandler',
  hospitalPushSettingUpdateHandler: 'hospitalPushSettingUpdateHandler',
  hospitalMobileSingleHospitalPushSettingHandler: 'hospitalMobileSingleHospitalPushSettingHandler',
  hospitalMobileHospitalPushSettingUpdateHandler: 'hospitalMobileHospitalPushSettingUpdateHandler',
  pushNotificationSendHandler: 'pushNotificationSendHandler',
  pushNotificationModuleTestHandler: 'pushNotificationModuleTestHandler',
  singlePatientDeletionHandler: 'singlePatientDeletionHandler',
  temporaryPatientsIsLockHandler: 'temporaryPatientsIsLockHandler',
  temporaryPatientsRequestLockHandler: 'temporaryPatientsRequestLockHandler',
  temporaryPatientsLastModifierHandler: 'temporaryPatientsLastModifierHandler',
  temporaryPatientsProcessingBatchHandler: 'temporaryPatientsProcessingBatchHandler',
  patientsHasNoTreatmentTagBatchHandler: 'patientsHasNoTreatmentTagBatchHandler',
  patientsHasRenewedFirstTagsBatchHandler: 'patientsHasRenewedFirstTagsBatchHandler',
  noPatientsCreatedBatchHandler: 'noPatientsCreatedBatchHandler',
  tempPatientsProcessingBatchHandler: 'tempPatientsProcessingBatchHandler',
  elFindLinkedChatroomsHandler: 'elFindLinkedChatroomsHandler',
  hospitalsAccountsHandler: 'hospitalsAccountsHandler',
  hospitalsAccountsCountTuningHandler: 'hospitalsAccountsCountTuningHandler',
  kakaoConsultationTalkLinkageHandler: 'kakaoConsultationTalkLinkageHandler',
  kakaoLatestLinkageHistoryHandler: 'kakaoLatestLinkageHistoryHandler',
  kakaoAuthenticationNumberHandler: 'kakaoAuthenticationNumberHandler',
  kakaoProfileCategoryHandler: 'kakaoProfileCategoryHandler',
  kakaoAlimFriendTalkLinkageHandler: 'kakaoAlimFriendTalkLinkageHandler',
  instagramLinkageHandler: 'instagramLinkageHandler',
  instagramAccountLinkageHandler: 'instagramAccountLinkageHandler',
  instagramAccountsHandler: 'instagramAccountsHandler',
  instagramDisconnectionHandler: 'instagramDisconnectionHandler',
  manualAllContentsHandler: 'manualAllContentsHandler',
  updatingFolderSortHandler: 'updatingFolderSortHandler',
  multipleTreatmentTagCreationHandler: 'multipleTreatmentTagCreationHandler',
  electronNotificationSoundsHandler: 'electronNotificationSoundsHandler',
  metadataGetHandler: 'metadataGetHandler',
  linkedChatroomElFindHandler: 'linkedChatroomElFindHandler',
  linkedChatroomElUnlinkHandler: 'linkedChatroomElUnlinkHandler',
  linkedChatroomElUnlinkByChatroomHandler: 'linkedChatroomElUnlinkByChatroomHandler',
  fcmTokenHmUpdateDeviceInfoHandler: 'fcmTokenHmUpdateDeviceInfoHandler',
  adminHospitalCountHandler: 'adminHospitalCountHandler',
  adminHospitalFindHandler: 'adminHospitalFindHandler',
  adminHospitalFindOneHandler: 'adminHospitalFindOneHandler',
  adminHospitalUpdateHandler: 'adminHospitalUpdateHandler',
  adminHospitalCreateHandler: 'adminHospitalCreateHandler',
  adminHospitalUpdateNameHandler: 'adminHospitalUpdateNameHandler',
  adminHospitalUpdateSmsPhoneHandler: 'adminHospitalUpdateSmsPhoneHandler',
  adminHospitalTestHServiceSmsFlowHandler: 'adminHospitalTestHServiceSmsFlowHandler',
  adminServiceSettingCountHandler: 'adminServiceSettingCountHandler',
  adminServiceSettingFindHandler: 'adminServiceSettingFindHandler',
  adminServiceSettingFindOneHandler: 'adminServiceSettingFindOneHandler',
  adminServiceSettingCreateHandler: 'adminServiceSettingCreateHandler',
  adminServiceSettingUpdateHandler: 'adminServiceSettingUpdateHandler',
  adminServiceSettingRemoveHandler: 'adminServiceSettingRemoveHandler',
  adminServiceSettingAccountUpdateHandler: 'adminServiceSettingAccountUpdateHandler',
  adminServiceSettingDefaultCounselorUpdateHandler: 'adminServiceSettingDefaultCounselorUpdateHandler',
  adminHealthContentCategoryFindHandler: 'adminHealthContentCategoryFindHandler',
  adminUserNurseFindHandler: 'adminUserNurseFindHandler',
  adminUserCountHandler: 'adminUserCountHandler',
  adminUserFindHandler: 'adminUserFindHandler',
  adminUserCreateHandler: 'adminUserCreateHandler',
  adminUserUpdateHandler: 'adminUserUpdateHandler',
  adminUserRemoveHandler: 'adminUserRemoveHandler',
  adminUserFindOneHandler: 'adminUserFindOneHandler',
  adminAnsweringChatSettingCreateHandler: 'adminAnsweringChatSettingCreateHandler',
  adminAnsweringChatSettingUpdateHandler: 'adminAnsweringChatSettingUpdateHandler',
  adminAnsweringChatSettingFindOneHandler: 'adminAnsweringChatSettingFindOneHandler',
  adminVideoContentCategoryFindHandler: 'adminVideoContentCategoryFindHandler',
  adminVideoContentCategoryFindOneHandler: 'adminVideoContentCategoryFindOneHandler',
  adminIntegrationSettingFindOneHandler: 'adminIntegrationSettingFindOneHandler',
  adminIntegrationSettingUpsertHandler: 'adminIntegrationSettingUpsertHandler',
  adminHospitalAccountCountHandler: 'adminHospitalAccountCountHandler',
  adminHospitalDoctorFindHandler: 'adminHospitalDoctorFindHandler',
  adminHospitalAccountCreateHandler: 'adminHospitalAccountCreateHandler',
  adminHospitalAccountUpdateHandler: 'adminHospitalAccountUpdateHandler',
  adminHospitalAccountRemoveHandler: 'adminHospitalAccountRemoveHandler',
  adminHospitalAccountSettingHistoryCountHandler: 'adminHospitalAccountSettingHistoryCountHandler',
  adminHospitalAccountSettingHistoryFindHandler: 'adminHospitalAccountSettingHistoryFindHandler',
  adminTreatmentCategoryCountHandler: 'adminTreatmentCategoryCountHandler',
  adminTreatmentCategoryCreateHandler: 'adminTreatmentCategoryCreateHandler',
  adminTreatmentCategoryFindHandler: 'adminTreatmentCategoryFindHandler',
  adminTreatmentCategoryFindOneHandler: 'adminTreatmentCategoryFindOneHandler',
  adminTreatmentCategoryRemoveHandler: 'adminTreatmentCategoryRemoveHandler',
  adminTreatmentCategoryUpdateHandler: 'adminTreatmentCategoryUpdateHandler',
  adminTreatmentCategoryCopyHandler: 'adminTreatmentCategoryCopyHandler',
  adminFileCreateHandler: 'adminFileCreateHandler',
  adminFileUpdateHandler: 'adminFileUpdateHandler',
  adminFileFindOneHandler: 'adminFileFindOneHandler',
  adminCountryFindHandler: 'adminCountryFindHandler',
  adminCountryUpdateHandler: 'adminCountryUpdateHandler',
  kakaoLinkageHistoryCountHandler: 'kakaoLinkageHistoryCountHandler',
  kakaoLinkageHistoryFindHandler: 'kakaoLinkageHistoryFindHandler',
  kakaoLinkageHistoryDetailHandler: 'kakaoLinkageHistoryDetailHandler',
  kakaoLinkageHistoryUpdateHandler: 'kakaoLinkageHistoryUpdateHandler',
  kakaoLinkageHistoryClearHandler: 'kakaoLinkageHistoryClearHandler',
  kakaoTemplateFindGroupHandler: 'kakaoTemplateFindGroupHandler',
  kakaoTemplateFindAllProfileHandler: 'kakaoTemplateFindAllProfileHandler',
  kakaoTemplateFindProfileHandler: 'kakaoTemplateFindProfileHandler',
  kakaoTemplateFindCategoryHandler: 'kakaoTemplateFindCategoryHandler',
  kakaoTemplateFindHandler: 'kakaoTemplateFindHandler',
  kakaoTemplateCreateHandler: 'kakaoTemplateCreateHandler',
  kakaoTemplateUpdateHandler: 'kakaoTemplateUpdateHandler',
  kakaoTemplateDeleteHandler: 'kakaoTemplateDeleteHandler',
  kakaoTemplateDetailHandler: 'kakaoTemplateDetailHandler',
  kakaoTemplateRequestHandler: 'kakaoTemplateRequestHandler',
  kakaoTemplateRequestCancelHandler: 'kakaoTemplateRequestCancelHandler',
  kakaoTemplateStopHandler: 'kakaoTemplateStopHandler',
  kakaoTemplateReuseHandler: 'kakaoTemplateReuseHandler',
  kakaoTemplateCancelApprovalHandler: 'kakaoTemplateCancelApprovalHandler',
  kakaoTemplateReleaseHandler: 'kakaoTemplateReleaseHandler',
  adminAutomationCountHandler: 'adminAutomationCountHandler',
  adminAutomationFindHandler: 'adminAutomationFindHandler',
  adminAutomationCreateHandler: 'adminAutomationCreateHandler',
  adminAutomationUpdateHandler: 'adminAutomationUpdateHandler',
  adminAutomationDeleteHandler: 'adminAutomationDeleteHandler',
  adminAuomtaionHospitalFindHandler: 'adminAuomtaionHospitalFindHandler',
  adminAutomationHospitalCreateHandler: 'adminAutomationHospitalCreateHandler',
  adminAutomationHospitalUpdateHandler: 'adminAutomationHospitalUpdateHandler',
  adminAutomationHospitalDeleteHandler: 'adminAutomationHospitalDeleteHandler',
  adminAuomtaionHospitalFindVideoContentHandler: 'adminAuomtaionHospitalFindVideoContentHandler',
  adminAuomtaionHospitalFindHomecareHandler: 'adminAuomtaionHospitalFindHomecareHandler',
  adminAuomtaionHospitalFindEventHandler: 'adminAuomtaionHospitalFindEventHandler',
  kakaoServiceNotificationMessageFindOneHandler: 'kakaoServiceNotificationMessageFindOneHandler',
  kakaoServiceNotificationMessageUpdateHandler: 'kakaoServiceNotificationMessageUpdateHandler',
  adminAuthorizationTypeCreateHandler: 'adminAuthorizationTypeCreateHandler',
  adminAuthorizationTypeUpdateHandler: 'adminAuthorizationTypeUpdateHandler',
  adminAuthorizationTypeFindOneHandler: 'adminAuthorizationTypeFindOneHandler',
  publicbizPpurioBlockHandler: 'publicbizPpurioBlockHandler',
  ...CUSTOM_QUERY_KEY,
} as const;

export type QueryKeyParamsMap = {
  [QUERY_KEY.apiChatroomElFind]: Partial<Parameters<typeof apiClient.v3.apiChatroomElFind>[0]>;
  [QUERY_KEY.apiChatroomElFindOne]: Partial<Parameters<typeof apiClient.v3.apiChatroomElFindOne>[0]>;
  [QUERY_KEY.apiChatroomElCount]: Partial<Parameters<typeof apiClient.v3.apiChatroomElCount>[0]>;
  [QUERY_KEY.apiChatroomElUnreadcount]: Partial<Parameters<typeof apiClient.v3.apiChatroomElUnreadcount>[0]>;
  [QUERY_KEY.apiChatroomElUpdate]: Partial<Parameters<typeof apiClient.v3.apiChatroomElUpdate>[0]>;
  [QUERY_KEY.apiChatroomElUpdateRead]: Partial<Parameters<typeof apiClient.v3.apiChatroomElUpdateRead>[0]>;
  [QUERY_KEY.apiChatroomElUpdateCounselStatus]: Partial<Parameters<typeof apiClient.v3.apiChatroomElUpdateCounselStatus>[0]>;
  [QUERY_KEY.apiChatroomElUpdateCounselCounselor]: Partial<Parameters<typeof apiClient.v3.apiChatroomElUpdateCounselCounselor>[0]>;
  [QUERY_KEY.apiChatroomElUserAdd]: Partial<Parameters<typeof apiClient.v3.apiChatroomElUserAdd>[0]>;
  [QUERY_KEY.apiChatroomElUserRemove]: Partial<Parameters<typeof apiClient.v3.apiChatroomElUserRemove>[0]>;
  [QUERY_KEY.apiChatroomElUserRemoveAll]: Partial<Parameters<typeof apiClient.v3.apiChatroomElUserRemoveAll>[0]>;
  [QUERY_KEY.apiChatroomElCounselorAdd]: Partial<Parameters<typeof apiClient.v3.apiChatroomElCounselorAdd>[0]>;
  [QUERY_KEY.apiChatroomElDetectLanguage]: Partial<Parameters<typeof apiClient.v3.apiChatroomElDetectLanguage>[0]>;
  [QUERY_KEY.apiChatroomElUpdateTranslateToggle]: Partial<Parameters<typeof apiClient.v3.apiChatroomElUpdateTranslateToggle>[0]>;
  [QUERY_KEY.apiChatroomHmFind]: Partial<Parameters<typeof apiClient.v3.apiChatroomHmFind>[0]>;
  [QUERY_KEY.apiChatroomHmFindOne]: Partial<Parameters<typeof apiClient.v3.apiChatroomHmFindOne>[0]>;
  [QUERY_KEY.apiChatroomHmCount]: Partial<Parameters<typeof apiClient.v3.apiChatroomHmCount>[0]>;
  [QUERY_KEY.apiChatroomHmUnreadcount]: Partial<Parameters<typeof apiClient.v3.apiChatroomHmUnreadcount>[0]>;
  [QUERY_KEY.apiChatroomHmUpdateRead]: Partial<Parameters<typeof apiClient.v3.apiChatroomHmUpdateRead>[0]>;
  [QUERY_KEY.apiChatroomHmUpdateCounselStatus]: Partial<Parameters<typeof apiClient.v3.apiChatroomHmUpdateCounselStatus>[0]>;
  [QUERY_KEY.apiChatroomHmUserRemove]: Partial<Parameters<typeof apiClient.v3.apiChatroomHmUserRemove>[0]>;
  [QUERY_KEY.apiChatroomHmCounselorAdd]: Partial<Parameters<typeof apiClient.v3.apiChatroomHmCounselorAdd>[0]>;
  [QUERY_KEY.apiChatroomHmUpdateTranslateToggle]: Partial<Parameters<typeof apiClient.v3.apiChatroomHmUpdateTranslateToggle>[0]>;
  [QUERY_KEY.apiChatElFind]: Partial<Parameters<typeof apiClient.v3.apiChatElFind>[0]>;
  [QUERY_KEY.apiChatElFindDate]: Partial<Parameters<typeof apiClient.v3.apiChatElFindDate>[0]>;
  [QUERY_KEY.apiChatElFindId]: Partial<Parameters<typeof apiClient.v3.apiChatElFindId>[0]>;
  [QUERY_KEY.apiChatElFindLast]: Partial<Parameters<typeof apiClient.v3.apiChatElFindLast>[0]>;
  [QUERY_KEY.apiChatElFindOne]: Partial<Parameters<typeof apiClient.v3.apiChatElFindOne>[0]>;
  [QUERY_KEY.apiChatElCount]: Partial<Parameters<typeof apiClient.v3.apiChatElCount>[0]>;
  [QUERY_KEY.apiChatElUpdateMarker]: Partial<Parameters<typeof apiClient.v3.apiChatElUpdateMarker>[0]>;
  [QUERY_KEY.apiChatElUpdateBookmark]: Partial<Parameters<typeof apiClient.v3.apiChatElUpdateBookmark>[0]>;
  [QUERY_KEY.apiChatElRemove]: Partial<Parameters<typeof apiClient.v3.apiChatElRemove>[0]>;
  [QUERY_KEY.apiChatElSendHnotice]: Partial<Parameters<typeof apiClient.v3.apiChatElSendHnotice>[0]>;
  [QUERY_KEY.apiChatElSendHevent]: Partial<Parameters<typeof apiClient.v3.apiChatElSendHevent>[0]>;
  [QUERY_KEY.apiChatElSendHaftercare]: Partial<Parameters<typeof apiClient.v3.apiChatElSendHaftercare>[0]>;
  [QUERY_KEY.apiChatElSendVcu]: Partial<Parameters<typeof apiClient.v3.apiChatElSendVcu>[0]>;
  [QUERY_KEY.apiChatElTranslate]: Partial<Parameters<typeof apiClient.v3.apiChatElTranslate>[0]>;
  [QUERY_KEY.apiChatHmFind]: Partial<Parameters<typeof apiClient.v3.apiChatHmFind>[0]>;
  [QUERY_KEY.apiChatHmFindOne]: Partial<Parameters<typeof apiClient.v3.apiChatHmFindOne>[0]>;
  [QUERY_KEY.apiChatHmCount]: Partial<Parameters<typeof apiClient.v3.apiChatHmCount>[0]>;
  [QUERY_KEY.apiChatHmRemove]: Partial<Parameters<typeof apiClient.v3.apiChatHmRemove>[0]>;
  [QUERY_KEY.apiChatHmTranslate]: Partial<Parameters<typeof apiClient.v3.apiChatHmTranslate>[0]>;
  [QUERY_KEY.apiPatientsElDetail]: Partial<Parameters<typeof apiClient.v3.apiPatientsElDetail>[0]>;
  [QUERY_KEY.apiPatientsElListOrSearch]: Partial<Parameters<typeof apiClient.v3.apiPatientsElListOrSearch>[0]>;
  [QUERY_KEY.apiPatientsElListOrSearchForUntagged]: Partial<Parameters<typeof apiClient.v3.apiPatientsElListOrSearchForUntagged>[0]>;
  [QUERY_KEY.apiPatientsElUntaggedCount]: Partial<Parameters<typeof apiClient.v3.apiPatientsElUntaggedCount>[0]>;
  [QUERY_KEY.apiPatientsElCount]: Partial<Parameters<typeof apiClient.v3.apiPatientsElCount>[0]>;
  [QUERY_KEY.apiPatientsElUntaggedTarget]: Partial<Parameters<typeof apiClient.v3.apiPatientsElUntaggedTarget>[0]>;
  [QUERY_KEY.apiPatientsEl]: Partial<Parameters<typeof apiClient.v3.apiPatientsEl>[0]>;
  [QUERY_KEY.apiPatientsElUpsertSingle]: Partial<Parameters<typeof apiClient.v3.apiPatientsElUpsertSingle>[0]>;
  [QUERY_KEY.apiPatientsElTemporaryV2ReservationToday]: Partial<Parameters<typeof apiClient.v3.apiPatientsElTemporaryV2ReservationToday>[0]>;
  [QUERY_KEY.apiPatientsElLinkKakao]: Partial<Parameters<typeof apiClient.v3.apiPatientsElLinkKakao>[0]>;
  [QUERY_KEY.apiPatientsHmDetail]: Partial<Parameters<typeof apiClient.v3.apiPatientsHmDetail>[0]>;
  [QUERY_KEY.apiPatientsHmLinkKakao]: Partial<Parameters<typeof apiClient.v3.apiPatientsHmLinkKakao>[0]>;
  [QUERY_KEY.apiPatientsElTemporaryListOrSearch]: Partial<Parameters<typeof apiClient.v3.apiPatientsElTemporaryListOrSearch>[0]>;
  [QUERY_KEY.apiPatientsElTemporary]: Partial<Parameters<typeof apiClient.v3.apiPatientsElTemporary>[0]>;
  [QUERY_KEY.temporaryPatientsDeletionHandler]: Partial<Parameters<typeof apiClient.v3.temporaryPatientsDeletionHandler>[0]>;
  [QUERY_KEY.apiPatientsRegistrationsElInvitationWithSms]: Partial<Parameters<typeof apiClient.v3.apiPatientsRegistrationsElInvitationWithSms>[0]>;
  [QUERY_KEY.apiPatientsRegistrationsElReinvitationWithSms]: Partial<Parameters<typeof apiClient.v3.apiPatientsRegistrationsElReinvitationWithSms>[0]>;
  [QUERY_KEY.apiPatientsMemosElFindOne]: Partial<Parameters<typeof apiClient.v3.apiPatientsMemosElFindOne>[0]>;
  [QUERY_KEY.apiPatientsMemosElUpdate]: Partial<Parameters<typeof apiClient.v3.apiPatientsMemosElUpdate>[0]>;
  [QUERY_KEY.apiPatientsMemosHmFindOne]: Partial<Parameters<typeof apiClient.v3.apiPatientsMemosHmFindOne>[0]>;
  [QUERY_KEY.apiPatientsMemosHmUpdate]: Partial<Parameters<typeof apiClient.v3.apiPatientsMemosHmUpdate>[0]>;
  [QUERY_KEY.apiHospitalsElFind]: Partial<Parameters<typeof apiClient.v3.apiHospitalsElFind>[0]>;
  [QUERY_KEY.apiHospitalsElUpdate]: Partial<Parameters<typeof apiClient.v3.apiHospitalsElUpdate>[0]>;
  [QUERY_KEY.apiHospitalsElUpdateSmslogoimage]: Partial<Parameters<typeof apiClient.v3.apiHospitalsElUpdateSmslogoimage>[0]>;
  [QUERY_KEY.apiHospitalsElFindAddress]: Partial<Parameters<typeof apiClient.v3.apiHospitalsElFindAddress>[0]>;
  [QUERY_KEY.apiHospitalsElFindDoctors]: Partial<Parameters<typeof apiClient.v3.apiHospitalsElFindDoctors>[0]>;
  [QUERY_KEY.apiHospitalsElFindUsers]: Partial<Parameters<typeof apiClient.v3.apiHospitalsElFindUsers>[0]>;
  [QUERY_KEY.hospitalMFindOneHandler]: Partial<Parameters<typeof apiClient.v3.hospitalMFindOneHandler>[0]>;
  [QUERY_KEY.hospitalMFindDoctorsHandler]: Partial<Parameters<typeof apiClient.v3.hospitalMFindDoctorsHandler>[0]>;
  [QUERY_KEY.apiHospitalsMFindAfterhoursPopup]: Partial<Parameters<typeof apiClient.v3.apiHospitalsMFindAfterhoursPopup>[0]>;
  [QUERY_KEY.apiServiceSettingsElFind]: Partial<Parameters<typeof apiClient.v3.apiServiceSettingsElFind>[0]>;
  [QUERY_KEY.apiServiceSettingsElFindOne]: Partial<Parameters<typeof apiClient.v3.apiServiceSettingsElFindOne>[0]>;
  [QUERY_KEY.apiServiceSettingsElCreate]: Partial<Parameters<typeof apiClient.v3.apiServiceSettingsElCreate>[0]>;
  [QUERY_KEY.apiServiceSettingsElUpdate]: Partial<Parameters<typeof apiClient.v3.apiServiceSettingsElUpdate>[0]>;
  [QUERY_KEY.apiServiceSettingsElToBeDisplayedPatientsFields]: Partial<Parameters<typeof apiClient.v3.apiServiceSettingsElToBeDisplayedPatientsFields>[0]>;
  [QUERY_KEY.apiServiceSettingsElCountries]: Partial<Parameters<typeof apiClient.v3.apiServiceSettingsElCountries>[0]>;
  [QUERY_KEY.apiServiceSettingsHmFindOne]: Partial<Parameters<typeof apiClient.v3.apiServiceSettingsHmFindOne>[0]>;
  [QUERY_KEY.apiServiceSettingsHmCountries]: Partial<Parameters<typeof apiClient.v3.apiServiceSettingsHmCountries>[0]>;
  [QUERY_KEY.apiHservicesElFindChatCount]: Partial<Parameters<typeof apiClient.v3.apiHservicesElFindChatCount>[0]>;
  [QUERY_KEY.apiHservicesElFindChat]: Partial<Parameters<typeof apiClient.v3.apiHservicesElFindChat>[0]>;
  [QUERY_KEY.apiHservicesElFindCount]: Partial<Parameters<typeof apiClient.v3.apiHservicesElFindCount>[0]>;
  [QUERY_KEY.apiHservicesElFindDetail]: Partial<Parameters<typeof apiClient.v3.apiHservicesElFindDetail>[0]>;
  [QUERY_KEY.apiHservicesElFindPatientsettingCount]: Partial<Parameters<typeof apiClient.v3.apiHservicesElFindPatientsettingCount>[0]>;
  [QUERY_KEY.apiHservicesElFindNeedsetting]: Partial<Parameters<typeof apiClient.v3.apiHservicesElFindNeedsetting>[0]>;
  [QUERY_KEY.apiHservicesElFindSimple]: Partial<Parameters<typeof apiClient.v3.apiHservicesElFindSimple>[0]>;
  [QUERY_KEY.apiHservicesElFindTreatmentcategoryCount]: Partial<Parameters<typeof apiClient.v3.apiHservicesElFindTreatmentcategoryCount>[0]>;
  [QUERY_KEY.apiHservicesElFindTreatmentcategory]: Partial<Parameters<typeof apiClient.v3.apiHservicesElFindTreatmentcategory>[0]>;
  [QUERY_KEY.apiHservicesElFindUnjoinedCount]: Partial<Parameters<typeof apiClient.v3.apiHservicesElFindUnjoinedCount>[0]>;
  [QUERY_KEY.apiHservicesElFindUnjoined]: Partial<Parameters<typeof apiClient.v3.apiHservicesElFindUnjoined>[0]>;
  [QUERY_KEY.apiHservicesElFind]: Partial<Parameters<typeof apiClient.v3.apiHservicesElFind>[0]>;
  [QUERY_KEY.apiHservicesElSuspend]: Partial<Parameters<typeof apiClient.v3.apiHservicesElSuspend>[0]>;
  [QUERY_KEY.apiHservicesElUpdateSetting]: Partial<Parameters<typeof apiClient.v3.apiHservicesElUpdateSetting>[0]>;
  [QUERY_KEY.apiHservicesElUpdate]: Partial<Parameters<typeof apiClient.v3.apiHservicesElUpdate>[0]>;
  [QUERY_KEY.apiHservicesHmFindSimple]: Partial<Parameters<typeof apiClient.v3.apiHservicesHmFindSimple>[0]>;
  [QUERY_KEY.apiHservicesMSuspend]: Partial<Parameters<typeof apiClient.v3.apiHservicesMSuspend>[0]>;
  [QUERY_KEY.apiHservicesMUpdateSelect]: Partial<Parameters<typeof apiClient.v3.apiHservicesMUpdateSelect>[0]>;
  [QUERY_KEY.apiHospitalsAccountsElFind]: Partial<Parameters<typeof apiClient.v3.apiHospitalsAccountsElFind>[0]>;
  [QUERY_KEY.apiHospitalsAccountsElCreate]: Partial<Parameters<typeof apiClient.v3.apiHospitalsAccountsElCreate>[0]>;
  [QUERY_KEY.apiHospitalsAccountsElUpdate]: Partial<Parameters<typeof apiClient.v3.apiHospitalsAccountsElUpdate>[0]>;
  [QUERY_KEY.apiHospitalsAccountsElIdcheck]: Partial<Parameters<typeof apiClient.v3.apiHospitalsAccountsElIdcheck>[0]>;
  [QUERY_KEY.apiHospitalsAccountsElReset]: Partial<Parameters<typeof apiClient.v3.apiHospitalsAccountsElReset>[0]>;
  [QUERY_KEY.apiHospitalsAccountsElRemove]: Partial<Parameters<typeof apiClient.v3.apiHospitalsAccountsElRemove>[0]>;
  [QUERY_KEY.apiHospitalsAuthorizationTypeElFind]: Partial<Parameters<typeof apiClient.v3.apiHospitalsAuthorizationTypeElFind>[0]>;
  [QUERY_KEY.apiHospitalsAuthorizationTypeElUpdate]: Partial<Parameters<typeof apiClient.v3.apiHospitalsAuthorizationTypeElUpdate>[0]>;
  [QUERY_KEY.apiHworkersElFind]: Partial<Parameters<typeof apiClient.v3.apiHworkersElFind>[0]>;
  [QUERY_KEY.apiHworkersElFindOne]: Partial<Parameters<typeof apiClient.v3.apiHworkersElFindOne>[0]>;
  [QUERY_KEY.apiHworkersElFindCounselor]: Partial<Parameters<typeof apiClient.v3.apiHworkersElFindCounselor>[0]>;
  [QUERY_KEY.apiHworkersElUpdate]: Partial<Parameters<typeof apiClient.v3.apiHworkersElUpdate>[0]>;
  [QUERY_KEY.apiHworkersElUpdateDashboard]: Partial<Parameters<typeof apiClient.v3.apiHworkersElUpdateDashboard>[0]>;
  [QUERY_KEY.apiNoticeHospitalElFindSimple]: Partial<Parameters<typeof apiClient.v3.apiNoticeHospitalElFindSimple>[0]>;
  [QUERY_KEY.apiNoticeHospitalElFindDetail]: Partial<Parameters<typeof apiClient.v3.apiNoticeHospitalElFindDetail>[0]>;
  [QUERY_KEY.apiNoticeHospitalElCount]: Partial<Parameters<typeof apiClient.v3.apiNoticeHospitalElCount>[0]>;
  [QUERY_KEY.apiNoticeHospitalElCreate]: Partial<Parameters<typeof apiClient.v3.apiNoticeHospitalElCreate>[0]>;
  [QUERY_KEY.apiNoticeHospitalElUpdate]: Partial<Parameters<typeof apiClient.v3.apiNoticeHospitalElUpdate>[0]>;
  [QUERY_KEY.apiNoticeHospitalElRemove]: Partial<Parameters<typeof apiClient.v3.apiNoticeHospitalElRemove>[0]>;
  [QUERY_KEY.apiPopupHospitalElFindSimple]: Partial<Parameters<typeof apiClient.v3.apiPopupHospitalElFindSimple>[0]>;
  [QUERY_KEY.apiPopupHospitalElFindDetail]: Partial<Parameters<typeof apiClient.v3.apiPopupHospitalElFindDetail>[0]>;
  [QUERY_KEY.apiPopupHospitalElCount]: Partial<Parameters<typeof apiClient.v3.apiPopupHospitalElCount>[0]>;
  [QUERY_KEY.apiPopupHospitalElCreate]: Partial<Parameters<typeof apiClient.v3.apiPopupHospitalElCreate>[0]>;
  [QUERY_KEY.apiPopupHospitalElUpdate]: Partial<Parameters<typeof apiClient.v3.apiPopupHospitalElUpdate>[0]>;
  [QUERY_KEY.apiPopupHospitalElRemove]: Partial<Parameters<typeof apiClient.v3.apiPopupHospitalElRemove>[0]>;
  [QUERY_KEY.apiPushRequestElFind]: Partial<Parameters<typeof apiClient.v3.apiPushRequestElFind>[0]>;
  [QUERY_KEY.apiPushRequestElCount]: Partial<Parameters<typeof apiClient.v3.apiPushRequestElCount>[0]>;
  [QUERY_KEY.apiPushRequestElCreate]: Partial<Parameters<typeof apiClient.v3.apiPushRequestElCreate>[0]>;
  [QUERY_KEY.apiPushResponseElFind]: Partial<Parameters<typeof apiClient.v3.apiPushResponseElFind>[0]>;
  [QUERY_KEY.apiTreatmentTagsElFind]: Partial<Parameters<typeof apiClient.v3.apiTreatmentTagsElFind>[0]>;
  [QUERY_KEY.apiTreatmentTagsElCreate]: Partial<Parameters<typeof apiClient.v3.apiTreatmentTagsElCreate>[0]>;
  [QUERY_KEY.apiTreatmentTagsElUpdate]: Partial<Parameters<typeof apiClient.v3.apiTreatmentTagsElUpdate>[0]>;
  [QUERY_KEY.apiTreatmentTagsElRemove]: Partial<Parameters<typeof apiClient.v3.apiTreatmentTagsElRemove>[0]>;
  [QUERY_KEY.apiTreatmentTagsEl]: Partial<Parameters<typeof apiClient.v3.apiTreatmentTagsEl>[0]>;
  [QUERY_KEY.apiHworkersElAuthorization]: Partial<Parameters<typeof apiClient.v3.apiHworkersElAuthorization>[0]>;
  [QUERY_KEY.apiServiceManagerNotesEl]: Partial<Parameters<typeof apiClient.v3.apiServiceManagerNotesEl>[0]>;
  [QUERY_KEY.apiServiceManagerNotesEl]: Partial<Parameters<typeof apiClient.v3.apiServiceManagerNotesEl>[0]>;
  [QUERY_KEY.apiServiceManagerNotesElUpdate]: Partial<Parameters<typeof apiClient.v3.apiServiceManagerNotesElUpdate>[0]>;
  [QUERY_KEY.apiServiceManagerNotesElRemove]: Partial<Parameters<typeof apiClient.v3.apiServiceManagerNotesElRemove>[0]>;
  [QUERY_KEY.apiServiceManagerNotesElDetail]: Partial<Parameters<typeof apiClient.v3.apiServiceManagerNotesElDetail>[0]>;
  [QUERY_KEY.apiV2ReservationElCount]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationElCount>[0]>;
  [QUERY_KEY.apiV2ReservationElCreateRegist]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationElCreateRegist>[0]>;
  [QUERY_KEY.apiV2ReservationElCreateUnregistered]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationElCreateUnregistered>[0]>;
  [QUERY_KEY.apiV2ReservationElCreate]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationElCreate>[0]>;
  [QUERY_KEY.apiV2ReservationElFind]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationElFind>[0]>;
  [QUERY_KEY.apiV2ReservationElFindHistory]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationElFindHistory>[0]>;
  [QUERY_KEY.apiV2ReservationElFindNext]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationElFindNext>[0]>;
  [QUERY_KEY.apiV2ReservationElRemove]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationElRemove>[0]>;
  [QUERY_KEY.apiV2ReservationElSendPush]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationElSendPush>[0]>;
  [QUERY_KEY.apiV2ReservationElUpdate]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationElUpdate>[0]>;
  [QUERY_KEY.apiV2ReservationMCancel]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationMCancel>[0]>;
  [QUERY_KEY.apiV2ReservationMCreate]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationMCreate>[0]>;
  [QUERY_KEY.apiV2ReservationMFindDate]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationMFindDate>[0]>;
  [QUERY_KEY.apiV2ReservationMFindLast]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationMFindLast>[0]>;
  [QUERY_KEY.apiV2ReservationMFindNext]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationMFindNext>[0]>;
  [QUERY_KEY.apiV2ReservationMFindOne]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationMFindOne>[0]>;
  [QUERY_KEY.apiV2ReservationMFind]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationMFind>[0]>;
  [QUERY_KEY.apiV2ReservationMPossibledate]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationMPossibledate>[0]>;
  [QUERY_KEY.apiV2ReservationMPossibletime]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationMPossibletime>[0]>;
  [QUERY_KEY.apiV2ReservationMUpdate]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationMUpdate>[0]>;
  [QUERY_KEY.apiV2ReservationusernoteElFind]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationusernoteElFind>[0]>;
  [QUERY_KEY.apiV2ReservationusernoteElUpdate]: Partial<Parameters<typeof apiClient.v3.apiV2ReservationusernoteElUpdate>[0]>;
  [QUERY_KEY.apiV2HreservationElCount]: Partial<Parameters<typeof apiClient.v3.apiV2HreservationElCount>[0]>;
  [QUERY_KEY.apiV2HreservationElFind]: Partial<Parameters<typeof apiClient.v3.apiV2HreservationElFind>[0]>;
  [QUERY_KEY.apiVideocontentglobalsettingElFindOne]: Partial<Parameters<typeof apiClient.v3.apiVideocontentglobalsettingElFindOne>[0]>;
  [QUERY_KEY.apiVideocontentcategoryElFind]: Partial<Parameters<typeof apiClient.v3.apiVideocontentcategoryElFind>[0]>;
  [QUERY_KEY.apiVideocontentcategoryElFindOne]: Partial<Parameters<typeof apiClient.v3.apiVideocontentcategoryElFindOne>[0]>;
  [QUERY_KEY.apiVideocontenthospitalElFind]: Partial<Parameters<typeof apiClient.v3.apiVideocontenthospitalElFind>[0]>;
  [QUERY_KEY.apiVideocontenthospitalElDetail]: Partial<Parameters<typeof apiClient.v3.apiVideocontenthospitalElDetail>[0]>;
  [QUERY_KEY.apiVideocontenthospitalElPlay]: Partial<Parameters<typeof apiClient.v3.apiVideocontenthospitalElPlay>[0]>;
  [QUERY_KEY.apiVideocontenthospitalElPlaysub]: Partial<Parameters<typeof apiClient.v3.apiVideocontenthospitalElPlaysub>[0]>;
  [QUERY_KEY.apiVideocontentuserElFind]: Partial<Parameters<typeof apiClient.v3.apiVideocontentuserElFind>[0]>;
  [QUERY_KEY.apiVideocontentuserElCreate]: Partial<Parameters<typeof apiClient.v3.apiVideocontentuserElCreate>[0]>;
  [QUERY_KEY.apiFiles]: Partial<Parameters<typeof apiClient.v3.apiFiles>[0]>;
  [QUERY_KEY.singleFileContentsUpdateHandler]: Partial<Parameters<typeof apiClient.v3.singleFileContentsUpdateHandler>[0]>;
  [QUERY_KEY.apiAutomationsElOngoing]: Partial<Parameters<typeof apiClient.v3.apiAutomationsElOngoing>[0]>;
  [QUERY_KEY.apiAutomationsElAlgorithmsSingle]: Partial<Parameters<typeof apiClient.v3.apiAutomationsElAlgorithmsSingle>[0]>;
  [QUERY_KEY.apiAutomationsElAlgorithmsSingleDeletion]: Partial<Parameters<typeof apiClient.v3.apiAutomationsElAlgorithmsSingleDeletion>[0]>;
  [QUERY_KEY.apiAutomationsElMainList]: Partial<Parameters<typeof apiClient.v3.apiAutomationsElMainList>[0]>;
  [QUERY_KEY.apiAutomationsElDashboard]: Partial<Parameters<typeof apiClient.v3.apiAutomationsElDashboard>[0]>;
  [QUERY_KEY.apiAutomationsElAlgorithmsMessages]: Partial<Parameters<typeof apiClient.v3.apiAutomationsElAlgorithmsMessages>[0]>;
  [QUERY_KEY.apiAutomationsElAlgorithmsMessagesSingle]: Partial<Parameters<typeof apiClient.v3.apiAutomationsElAlgorithmsMessagesSingle>[0]>;
  [QUERY_KEY.apiAutomationsElAlgorithmsMessagesUpsertSingle]: Partial<Parameters<typeof apiClient.v3.apiAutomationsElAlgorithmsMessagesUpsertSingle>[0]>;
  [QUERY_KEY.apiAutomationsElAlgorithmsMessagesDeleteSingle]: Partial<Parameters<typeof apiClient.v3.apiAutomationsElAlgorithmsMessagesDeleteSingle>[0]>;
  [QUERY_KEY.apiAutomationsElAlgorithmsToggle]: Partial<Parameters<typeof apiClient.v3.apiAutomationsElAlgorithmsToggle>[0]>;
  [QUERY_KEY.apiAutomationsElAlgorithmsResponses]: Partial<Parameters<typeof apiClient.v3.apiAutomationsElAlgorithmsResponses>[0]>;
  [QUERY_KEY.apiAutomationsElCommonAlgorithm]: Partial<Parameters<typeof apiClient.v3.apiAutomationsElCommonAlgorithm>[0]>;
  [QUERY_KEY.apiAutomationsElCommonAlgorithmUpsert]: Partial<Parameters<typeof apiClient.v3.apiAutomationsElCommonAlgorithmUpsert>[0]>;
  [QUERY_KEY.apiAutomationsElAffectedPatientCountWhenTreatmentTagDeleted]: Partial<Parameters<typeof apiClient.v3.apiAutomationsElAffectedPatientCountWhenTreatmentTagDeleted>[0]>;
  [QUERY_KEY.apiLanguageElFindDefault]: Partial<Parameters<typeof apiClient.v3.apiLanguageElFindDefault>[0]>;
  [QUERY_KEY.apiLanguageHmFindDefault]: Partial<Parameters<typeof apiClient.v3.apiLanguageHmFindDefault>[0]>;
  [QUERY_KEY.apiManualsElFoldersCreate]: Partial<Parameters<typeof apiClient.v3.apiManualsElFoldersCreate>[0]>;
  [QUERY_KEY.apiManualsElFolders]: Partial<Parameters<typeof apiClient.v3.apiManualsElFolders>[0]>;
  [QUERY_KEY.apiManualsElFoldersUpdate]: Partial<Parameters<typeof apiClient.v3.apiManualsElFoldersUpdate>[0]>;
  [QUERY_KEY.apiManualsElFoldersDelete]: Partial<Parameters<typeof apiClient.v3.apiManualsElFoldersDelete>[0]>;
  [QUERY_KEY.apiManualsElUpsertSingle]: Partial<Parameters<typeof apiClient.v3.apiManualsElUpsertSingle>[0]>;
  [QUERY_KEY.apiManualsElTreatmentCautionsChat]: Partial<Parameters<typeof apiClient.v3.apiManualsElTreatmentCautionsChat>[0]>;
  [QUERY_KEY.apiManualsElDelete]: Partial<Parameters<typeof apiClient.v3.apiManualsElDelete>[0]>;
  [QUERY_KEY.apiManualsElSingle]: Partial<Parameters<typeof apiClient.v3.apiManualsElSingle>[0]>;
  [QUERY_KEY.apiEventHospitalAftercareElFindSimple]: Partial<Parameters<typeof apiClient.v3.apiEventHospitalAftercareElFindSimple>[0]>;
  [QUERY_KEY.apiEventHospitalAftercareElFindOne]: Partial<Parameters<typeof apiClient.v3.apiEventHospitalAftercareElFindOne>[0]>;
  [QUERY_KEY.apiEventHospitalAftercareElCount]: Partial<Parameters<typeof apiClient.v3.apiEventHospitalAftercareElCount>[0]>;
  [QUERY_KEY.apiEventHospitalAftercareElCreate]: Partial<Parameters<typeof apiClient.v3.apiEventHospitalAftercareElCreate>[0]>;
  [QUERY_KEY.apiEventHospitalAftercareElUpdate]: Partial<Parameters<typeof apiClient.v3.apiEventHospitalAftercareElUpdate>[0]>;
  [QUERY_KEY.apiEventHospitalAftercareElRemove]: Partial<Parameters<typeof apiClient.v3.apiEventHospitalAftercareElRemove>[0]>;
  [QUERY_KEY.apiEventHospitalEventElFindSimple]: Partial<Parameters<typeof apiClient.v3.apiEventHospitalEventElFindSimple>[0]>;
  [QUERY_KEY.apiEventHospitalEventElFindOne]: Partial<Parameters<typeof apiClient.v3.apiEventHospitalEventElFindOne>[0]>;
  [QUERY_KEY.apiEventHospitalEventElCount]: Partial<Parameters<typeof apiClient.v3.apiEventHospitalEventElCount>[0]>;
  [QUERY_KEY.apiEventHospitalEventElCreate]: Partial<Parameters<typeof apiClient.v3.apiEventHospitalEventElCreate>[0]>;
  [QUERY_KEY.apiEventHospitalEventElUpdate]: Partial<Parameters<typeof apiClient.v3.apiEventHospitalEventElUpdate>[0]>;
  [QUERY_KEY.apiEventHospitalEventElRemove]: Partial<Parameters<typeof apiClient.v3.apiEventHospitalEventElRemove>[0]>;
  [QUERY_KEY.apiMemosElCreate]: Partial<Parameters<typeof apiClient.v3.apiMemosElCreate>[0]>;
  [QUERY_KEY.apiMemosElFindLast]: Partial<Parameters<typeof apiClient.v3.apiMemosElFindLast>[0]>;
  [QUERY_KEY.apiMemosElFindManageCount]: Partial<Parameters<typeof apiClient.v3.apiMemosElFindManageCount>[0]>;
  [QUERY_KEY.apiMemosElFindManageLast]: Partial<Parameters<typeof apiClient.v3.apiMemosElFindManageLast>[0]>;
  [QUERY_KEY.apiMemosElFindManage]: Partial<Parameters<typeof apiClient.v3.apiMemosElFindManage>[0]>;
  [QUERY_KEY.apiMemosElFindNurseCount]: Partial<Parameters<typeof apiClient.v3.apiMemosElFindNurseCount>[0]>;
  [QUERY_KEY.apiMemosElFindNurseLast]: Partial<Parameters<typeof apiClient.v3.apiMemosElFindNurseLast>[0]>;
  [QUERY_KEY.apiMemosElFindNurse]: Partial<Parameters<typeof apiClient.v3.apiMemosElFindNurse>[0]>;
  [QUERY_KEY.apiMemosElFind]: Partial<Parameters<typeof apiClient.v3.apiMemosElFind>[0]>;
  [QUERY_KEY.apiMemosElFindWithPinned]: Partial<Parameters<typeof apiClient.v3.apiMemosElFindWithPinned>[0]>;
  [QUERY_KEY.apiMemosElRead]: Partial<Parameters<typeof apiClient.v3.apiMemosElRead>[0]>;
  [QUERY_KEY.apiMemosElRemove]: Partial<Parameters<typeof apiClient.v3.apiMemosElRemove>[0]>;
  [QUERY_KEY.apiMemosElUpdatePin]: Partial<Parameters<typeof apiClient.v3.apiMemosElUpdatePin>[0]>;
  [QUERY_KEY.apiMemosElUpdate]: Partial<Parameters<typeof apiClient.v3.apiMemosElUpdate>[0]>;
  [QUERY_KEY.apiApprovalChatroomElFind]: Partial<Parameters<typeof apiClient.v3.apiApprovalChatroomElFind>[0]>;
  [QUERY_KEY.apiApprovalChatroomElCreate]: Partial<Parameters<typeof apiClient.v3.apiApprovalChatroomElCreate>[0]>;
  [QUERY_KEY.apiApprovalChatroomElUpdate]: Partial<Parameters<typeof apiClient.v3.apiApprovalChatroomElUpdate>[0]>;
  [QUERY_KEY.apiApprovalChatroomHmFind]: Partial<Parameters<typeof apiClient.v3.apiApprovalChatroomHmFind>[0]>;
  [QUERY_KEY.apiApprovalChatroomHmUpdate]: Partial<Parameters<typeof apiClient.v3.apiApprovalChatroomHmUpdate>[0]>;
  [QUERY_KEY.apiShortlinkEventElCreate]: Partial<Parameters<typeof apiClient.v3.apiShortlinkEventElCreate>[0]>;
  [QUERY_KEY.apiShortlinkAftercareElCreate]: Partial<Parameters<typeof apiClient.v3.apiShortlinkAftercareElCreate>[0]>;
  [QUERY_KEY.apiShortlinkVideocontentElCreate]: Partial<Parameters<typeof apiClient.v3.apiShortlinkVideocontentElCreate>[0]>;
  [QUERY_KEY.apiShortlinkHospitalnoticeElCreate]: Partial<Parameters<typeof apiClient.v3.apiShortlinkHospitalnoticeElCreate>[0]>;
  [QUERY_KEY.sharedEventShare]: Partial<Parameters<typeof apiClient.v3.sharedEventShare>[0]>;
  [QUERY_KEY.sharedAftercareShare]: Partial<Parameters<typeof apiClient.v3.sharedAftercareShare>[0]>;
  [QUERY_KEY.sharedVideocontentShare]: Partial<Parameters<typeof apiClient.v3.sharedVideocontentShare>[0]>;
  [QUERY_KEY.sharedHospitalnoticeShare]: Partial<Parameters<typeof apiClient.v3.sharedHospitalnoticeShare>[0]>;
  [QUERY_KEY.adminHospitalsElFind]: Partial<Parameters<typeof apiClient.v3.adminHospitalsElFind>[0]>;
  [QUERY_KEY.adminHospitalsElFindNurse]: Partial<Parameters<typeof apiClient.v3.adminHospitalsElFindNurse>[0]>;
  [QUERY_KEY.adminHospitalsElCreate]: Partial<Parameters<typeof apiClient.v3.adminHospitalsElCreate>[0]>;
  [QUERY_KEY.adminHospitalsElCount]: Partial<Parameters<typeof apiClient.v3.adminHospitalsElCount>[0]>;
  [QUERY_KEY.adminHospitalsElUpdate]: Partial<Parameters<typeof apiClient.v3.adminHospitalsElUpdate>[0]>;
  [QUERY_KEY.adminHospitalsElFindDoctor]: Partial<Parameters<typeof apiClient.v3.adminHospitalsElFindDoctor>[0]>;
  [QUERY_KEY.adminHospitalsElCountDoctor]: Partial<Parameters<typeof apiClient.v3.adminHospitalsElCountDoctor>[0]>;
  [QUERY_KEY.adminHospitalsElCreateDoctor]: Partial<Parameters<typeof apiClient.v3.adminHospitalsElCreateDoctor>[0]>;
  [QUERY_KEY.adminHospitalsElUpdateDoctor]: Partial<Parameters<typeof apiClient.v3.adminHospitalsElUpdateDoctor>[0]>;
  [QUERY_KEY.adminHospitalsElRemoveDoctor]: Partial<Parameters<typeof apiClient.v3.adminHospitalsElRemoveDoctor>[0]>;
  [QUERY_KEY.adminAuthorizationTypeElFind]: Partial<Parameters<typeof apiClient.v3.adminAuthorizationTypeElFind>[0]>;
  [QUERY_KEY.apiNoticeServicenoticeElFind]: Partial<Parameters<typeof apiClient.v3.apiNoticeServicenoticeElFind>[0]>;
  [QUERY_KEY.apiNoticeServicenoticeElCount]: Partial<Parameters<typeof apiClient.v3.apiNoticeServicenoticeElCount>[0]>;
  [QUERY_KEY.apiNoticeServicenoticeElUpdateRead]: Partial<Parameters<typeof apiClient.v3.apiNoticeServicenoticeElUpdateRead>[0]>;
  [QUERY_KEY.apiNoticeServicenoticeElUpdateReadAll]: Partial<Parameters<typeof apiClient.v3.apiNoticeServicenoticeElUpdateReadAll>[0]>;
  [QUERY_KEY.apiNoticeServicenoticeHmFind]: Partial<Parameters<typeof apiClient.v3.apiNoticeServicenoticeHmFind>[0]>;
  [QUERY_KEY.apiNoticeServicenoticeHmCount]: Partial<Parameters<typeof apiClient.v3.apiNoticeServicenoticeHmCount>[0]>;
  [QUERY_KEY.apiNoticeServicenoticeHmUpdateRead]: Partial<Parameters<typeof apiClient.v3.apiNoticeServicenoticeHmUpdateRead>[0]>;
  [QUERY_KEY.apiNoticeServicenoticeHmUpdateReadAll]: Partial<Parameters<typeof apiClient.v3.apiNoticeServicenoticeHmUpdateReadAll>[0]>;
  [QUERY_KEY.apiStatsAlgorithmsConsultationElAggregateBatch]: Partial<Parameters<typeof apiClient.v3.apiStatsAlgorithmsConsultationElAggregateBatch>[0]>;
  [QUERY_KEY.apiStatsAlgorithmsConsultationMonthlyElFind]: Partial<Parameters<typeof apiClient.v3.apiStatsAlgorithmsConsultationMonthlyElFind>[0]>;
  [QUERY_KEY.apiStatsAlgorithmsConsultationMonthlyElFindHospital]: Partial<Parameters<typeof apiClient.v3.apiStatsAlgorithmsConsultationMonthlyElFindHospital>[0]>;
  [QUERY_KEY.apiStatsChatsElAggregateBatch]: Partial<Parameters<typeof apiClient.v3.apiStatsChatsElAggregateBatch>[0]>;
  [QUERY_KEY.apiStatsChatsMonthlyElFindHospital]: Partial<Parameters<typeof apiClient.v3.apiStatsChatsMonthlyElFindHospital>[0]>;
  [QUERY_KEY.v2authJoinMQr]: Partial<Parameters<typeof apiClient.v2.authJoinMQr>[0]>;
  [QUERY_KEY.v2authJoinMReleasepending]: Partial<Parameters<typeof apiClient.v2.authJoinMReleasepending>[0]>;
  [QUERY_KEY.v2authJoinMMassivelink]: Partial<Parameters<typeof apiClient.v2.authJoinMMassivelink>[0]>;
  [QUERY_KEY.v2authLoginEl]: Partial<Parameters<typeof apiClient.v2.authLoginEl>[0]>;
  [QUERY_KEY.v2authLoginMCheckphone]: Partial<Parameters<typeof apiClient.v2.authLoginMCheckphone>[0]>;
  [QUERY_KEY.v2authLoginM]: Partial<Parameters<typeof apiClient.v2.authLoginM>[0]>;
  [QUERY_KEY.v2authLogoutM]: Partial<Parameters<typeof apiClient.v2.authLogoutM>[0]>;
  [QUERY_KEY.v2authLoginHm]: Partial<Parameters<typeof apiClient.v2.authLoginHm>[0]>;
  [QUERY_KEY.v2authLogoutHm]: Partial<Parameters<typeof apiClient.v2.authLogoutHm>[0]>;
  [QUERY_KEY.v2authPhoneMRequest]: Partial<Parameters<typeof apiClient.v2.authPhoneMRequest>[0]>;
  [QUERY_KEY.v2authPhoneMRequestChangephone]: Partial<Parameters<typeof apiClient.v2.authPhoneMRequestChangephone>[0]>;
  [QUERY_KEY.v2authPhoneMRequestUnidentify]: Partial<Parameters<typeof apiClient.v2.authPhoneMRequestUnidentify>[0]>;
  [QUERY_KEY.v2authPhoneMVerify]: Partial<Parameters<typeof apiClient.v2.authPhoneMVerify>[0]>;
  [QUERY_KEY.v2authPhoneMChange]: Partial<Parameters<typeof apiClient.v2.authPhoneMChange>[0]>;
  [QUERY_KEY.v2authPhoneMwRequest]: Partial<Parameters<typeof apiClient.v2.authPhoneMwRequest>[0]>;
  [QUERY_KEY.v2authPhoneMwVerify]: Partial<Parameters<typeof apiClient.v2.authPhoneMwVerify>[0]>;
  [QUERY_KEY.v2authPasswordMHaspassword]: Partial<Parameters<typeof apiClient.v2.authPasswordMHaspassword>[0]>;
  [QUERY_KEY.v2authPasswordMUpdate]: Partial<Parameters<typeof apiClient.v2.authPasswordMUpdate>[0]>;
  [QUERY_KEY.v2authShortlinkMUserinfo]: Partial<Parameters<typeof apiClient.v2.authShortlinkMUserinfo>[0]>;
  [QUERY_KEY.v2authShortlinkMHserviceinfo]: Partial<Parameters<typeof apiClient.v2.authShortlinkMHserviceinfo>[0]>;
  [QUERY_KEY.v2authUserMwSuspend]: Partial<Parameters<typeof apiClient.v2.authUserMwSuspend>[0]>;
  [QUERY_KEY.v2apiRegistPatientElCreate]: Partial<Parameters<typeof apiClient.v2.apiRegistPatientElCreate>[0]>;
  [QUERY_KEY.v2apiRegistPatientElSendsms]: Partial<Parameters<typeof apiClient.v2.apiRegistPatientElSendsms>[0]>;
  [QUERY_KEY.v2apiUserElFind]: Partial<Parameters<typeof apiClient.v2.apiUserElFind>[0]>;
  [QUERY_KEY.v2apiUserElFindOne]: Partial<Parameters<typeof apiClient.v2.apiUserElFindOne>[0]>;
  [QUERY_KEY.v2apiUserElUpdate]: Partial<Parameters<typeof apiClient.v2.apiUserElUpdate>[0]>;
  [QUERY_KEY.v2apiUserElCanchangephone]: Partial<Parameters<typeof apiClient.v2.apiUserElCanchangephone>[0]>;
  [QUERY_KEY.v2apiUserElUpdateDashboard]: Partial<Parameters<typeof apiClient.v2.apiUserElUpdateDashboard>[0]>;
  [QUERY_KEY.v2apiUserMFindOne]: Partial<Parameters<typeof apiClient.v2.apiUserMFindOne>[0]>;
  [QUERY_KEY.v2apiUserMUpdate]: Partial<Parameters<typeof apiClient.v2.apiUserMUpdate>[0]>;
  [QUERY_KEY.v2apiUserMUpdateDeviceinfo]: Partial<Parameters<typeof apiClient.v2.apiUserMUpdateDeviceinfo>[0]>;
  [QUERY_KEY.v2apiUserMPhoneCode]: Partial<Parameters<typeof apiClient.v2.apiUserMPhoneCode>[0]>;
  [QUERY_KEY.v2apiUserMPhoneVerify]: Partial<Parameters<typeof apiClient.v2.apiUserMPhoneVerify>[0]>;
  [QUERY_KEY.v2apiUserMChangepassword]: Partial<Parameters<typeof apiClient.v2.apiUserMChangepassword>[0]>;
  [QUERY_KEY.v2apiUserHmUpdateDeviceinfo]: Partial<Parameters<typeof apiClient.v2.apiUserHmUpdateDeviceinfo>[0]>;
  [QUERY_KEY.v2apiChatroomElFind]: Partial<Parameters<typeof apiClient.v2.apiChatroomElFind>[0]>;
  [QUERY_KEY.v2apiChatroomElCountResponserequired]: Partial<Parameters<typeof apiClient.v2.apiChatroomElCountResponserequired>[0]>;
  [QUERY_KEY.v2apiChatroomElCount]: Partial<Parameters<typeof apiClient.v2.apiChatroomElCount>[0]>;
  [QUERY_KEY.v2apiChatroomElUnreadcount]: Partial<Parameters<typeof apiClient.v2.apiChatroomElUnreadcount>[0]>;
  [QUERY_KEY.v2apiChatroomElCreate]: Partial<Parameters<typeof apiClient.v2.apiChatroomElCreate>[0]>;
  [QUERY_KEY.v2apiChatroomElUpdate]: Partial<Parameters<typeof apiClient.v2.apiChatroomElUpdate>[0]>;
  [QUERY_KEY.v2apiChatroomElUpdateReadResponserequired]: Partial<Parameters<typeof apiClient.v2.apiChatroomElUpdateReadResponserequired>[0]>;
  [QUERY_KEY.v2apiChatroomElUpdateRead]: Partial<Parameters<typeof apiClient.v2.apiChatroomElUpdateRead>[0]>;
  [QUERY_KEY.v2apiChatroomElUpdateStatusResponserequiredAll]: Partial<Parameters<typeof apiClient.v2.apiChatroomElUpdateStatusResponserequiredAll>[0]>;
  [QUERY_KEY.v2apiChatroomElUpdateTitle]: Partial<Parameters<typeof apiClient.v2.apiChatroomElUpdateTitle>[0]>;
  [QUERY_KEY.v2apiChatroomElUserAdd]: Partial<Parameters<typeof apiClient.v2.apiChatroomElUserAdd>[0]>;
  [QUERY_KEY.v2apiChatroomElUserRemove]: Partial<Parameters<typeof apiClient.v2.apiChatroomElUserRemove>[0]>;
  [QUERY_KEY.v2apiChatroomElUserRemoveAll]: Partial<Parameters<typeof apiClient.v2.apiChatroomElUserRemoveAll>[0]>;
  [QUERY_KEY.v2apiChatroomMFindOne]: Partial<Parameters<typeof apiClient.v2.apiChatroomMFindOne>[0]>;
  [QUERY_KEY.v2apiChatroomMUpdateRead]: Partial<Parameters<typeof apiClient.v2.apiChatroomMUpdateRead>[0]>;
  [QUERY_KEY.v2apiChatElFind]: Partial<Parameters<typeof apiClient.v2.apiChatElFind>[0]>;
  [QUERY_KEY.v2apiChatElCount]: Partial<Parameters<typeof apiClient.v2.apiChatElCount>[0]>;
  [QUERY_KEY.v2apiChatElUpdateMarker]: Partial<Parameters<typeof apiClient.v2.apiChatElUpdateMarker>[0]>;
  [QUERY_KEY.v2apiChatMCreateReservation]: Partial<Parameters<typeof apiClient.v2.apiChatMCreateReservation>[0]>;
  [QUERY_KEY.v2apiChatMCreateHevent]: Partial<Parameters<typeof apiClient.v2.apiChatMCreateHevent>[0]>;
  [QUERY_KEY.v2apiChatMCreateHaftercare]: Partial<Parameters<typeof apiClient.v2.apiChatMCreateHaftercare>[0]>;
  [QUERY_KEY.v2apiHospitalElFind]: Partial<Parameters<typeof apiClient.v2.apiHospitalElFind>[0]>;
  [QUERY_KEY.v2apiHospitalElFindAddress]: Partial<Parameters<typeof apiClient.v2.apiHospitalElFindAddress>[0]>;
  [QUERY_KEY.v2apiHospitalElUpdate]: Partial<Parameters<typeof apiClient.v2.apiHospitalElUpdate>[0]>;
  [QUERY_KEY.v2apiHospitalElFindDoctors]: Partial<Parameters<typeof apiClient.v2.apiHospitalElFindDoctors>[0]>;
  [QUERY_KEY.v2apiHospitalElFindUsers]: Partial<Parameters<typeof apiClient.v2.apiHospitalElFindUsers>[0]>;
  [QUERY_KEY.v2apiHospitalMFindOne]: Partial<Parameters<typeof apiClient.v2.apiHospitalMFindOne>[0]>;
  [QUERY_KEY.v2apiHospitalMFindDoctors]: Partial<Parameters<typeof apiClient.v2.apiHospitalMFindDoctors>[0]>;
  [QUERY_KEY.v2apiHospitalMFindAfterhoursPopup]: Partial<Parameters<typeof apiClient.v2.apiHospitalMFindAfterhoursPopup>[0]>;
  [QUERY_KEY.v2apiNoticeServicenoticeElCount]: Partial<Parameters<typeof apiClient.v2.apiNoticeServicenoticeElCount>[0]>;
  [QUERY_KEY.v2apiNoticeServicenoticeElFind]: Partial<Parameters<typeof apiClient.v2.apiNoticeServicenoticeElFind>[0]>;
  [QUERY_KEY.v2apiNoticeServicenoticeElUpdateRead]: Partial<Parameters<typeof apiClient.v2.apiNoticeServicenoticeElUpdateRead>[0]>;
  [QUERY_KEY.v2apiNoticeServicenoticeElUpdateReadAll]: Partial<Parameters<typeof apiClient.v2.apiNoticeServicenoticeElUpdateReadAll>[0]>;
  [QUERY_KEY.v2apiNoticeServicenoticeMCount]: Partial<Parameters<typeof apiClient.v2.apiNoticeServicenoticeMCount>[0]>;
  [QUERY_KEY.v2apiNoticeServicenoticeMFind]: Partial<Parameters<typeof apiClient.v2.apiNoticeServicenoticeMFind>[0]>;
  [QUERY_KEY.v2apiNoticeServicenoticeMUpdateRead]: Partial<Parameters<typeof apiClient.v2.apiNoticeServicenoticeMUpdateRead>[0]>;
  [QUERY_KEY.v2apiNoticeServicenoticeMUpdateReadAll]: Partial<Parameters<typeof apiClient.v2.apiNoticeServicenoticeMUpdateReadAll>[0]>;
  [QUERY_KEY.v2apiNoticeServicenoticeHmCount]: Partial<Parameters<typeof apiClient.v2.apiNoticeServicenoticeHmCount>[0]>;
  [QUERY_KEY.v2apiNoticeServicenoticeHmFind]: Partial<Parameters<typeof apiClient.v2.apiNoticeServicenoticeHmFind>[0]>;
  [QUERY_KEY.v2apiNoticeServicenoticeHmUpdateRead]: Partial<Parameters<typeof apiClient.v2.apiNoticeServicenoticeHmUpdateRead>[0]>;
  [QUERY_KEY.v2apiNoticeServicenoticeHmUpdateReadAll]: Partial<Parameters<typeof apiClient.v2.apiNoticeServicenoticeHmUpdateReadAll>[0]>;
  [QUERY_KEY.v2apiNoticeServicenoticeHmUpdateReadReservation]: Partial<Parameters<typeof apiClient.v2.apiNoticeServicenoticeHmUpdateReadReservation>[0]>;
  [QUERY_KEY.v2apiReservationsettingElFind]: Partial<Parameters<typeof apiClient.v2.apiReservationsettingElFind>[0]>;
  [QUERY_KEY.v2apiReservationsettingElUpdate]: Partial<Parameters<typeof apiClient.v2.apiReservationsettingElUpdate>[0]>;
  [QUERY_KEY.v2apiReservationsheetElFind]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetElFind>[0]>;
  [QUERY_KEY.v2apiReservationsheetElFindOne]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetElFindOne>[0]>;
  [QUERY_KEY.v2apiReservationsheetElDetail]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetElDetail>[0]>;
  [QUERY_KEY.v2apiReservationsheetElCreate]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetElCreate>[0]>;
  [QUERY_KEY.v2apiReservationsheetElUpdate]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetElUpdate>[0]>;
  [QUERY_KEY.v2apiReservationsheetElRemove]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetElRemove>[0]>;
  [QUERY_KEY.v2apiReservationsheetElUpdateActivate]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetElUpdateActivate>[0]>;
  [QUERY_KEY.v2apiReservationsheetMFind]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetMFind>[0]>;
  [QUERY_KEY.v2apiReservationsheetMDetail]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetMDetail>[0]>;
  [QUERY_KEY.v2apiReservationsheetMDetailReservationid]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetMDetailReservationid>[0]>;
  [QUERY_KEY.v2apiReservationsheetMPossibledays]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetMPossibledays>[0]>;
  [QUERY_KEY.v2apiReservationsheetMReservationlist]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetMReservationlist>[0]>;
  [QUERY_KEY.v2apiReservationsheetHmFind]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetHmFind>[0]>;
  [QUERY_KEY.v2apiReservationcolumnElFind]: Partial<Parameters<typeof apiClient.v2.apiReservationcolumnElFind>[0]>;
  [QUERY_KEY.v2apiReservationcolumnElCreate]: Partial<Parameters<typeof apiClient.v2.apiReservationcolumnElCreate>[0]>;
  [QUERY_KEY.v2apiReservationcolumnElUpdate]: Partial<Parameters<typeof apiClient.v2.apiReservationcolumnElUpdate>[0]>;
  [QUERY_KEY.v2apiReservationcolumnElUpdateTimeintervals]: Partial<Parameters<typeof apiClient.v2.apiReservationcolumnElUpdateTimeintervals>[0]>;
  [QUERY_KEY.v2apiReservationcolumnElRemove]: Partial<Parameters<typeof apiClient.v2.apiReservationcolumnElRemove>[0]>;
  [QUERY_KEY.v2apiReservationcolumnElDate]: Partial<Parameters<typeof apiClient.v2.apiReservationcolumnElDate>[0]>;
  [QUERY_KEY.v2apiReservationcolumnMDate]: Partial<Parameters<typeof apiClient.v2.apiReservationcolumnMDate>[0]>;
  [QUERY_KEY.v2apiReservationcolumnHmDate]: Partial<Parameters<typeof apiClient.v2.apiReservationcolumnHmDate>[0]>;
  [QUERY_KEY.v2apiReservationprogramElFind]: Partial<Parameters<typeof apiClient.v2.apiReservationprogramElFind>[0]>;
  [QUERY_KEY.v2apiReservationprogramElCreate]: Partial<Parameters<typeof apiClient.v2.apiReservationprogramElCreate>[0]>;
  [QUERY_KEY.v2apiReservationprogramElUpdate]: Partial<Parameters<typeof apiClient.v2.apiReservationprogramElUpdate>[0]>;
  [QUERY_KEY.v2apiReservationprogramElRemove]: Partial<Parameters<typeof apiClient.v2.apiReservationprogramElRemove>[0]>;
  [QUERY_KEY.v2apiReservationprogramHmFind]: Partial<Parameters<typeof apiClient.v2.apiReservationprogramHmFind>[0]>;
  [QUERY_KEY.v2apiReservationElCount]: Partial<Parameters<typeof apiClient.v2.apiReservationElCount>[0]>;
  [QUERY_KEY.v2apiReservationElFind]: Partial<Parameters<typeof apiClient.v2.apiReservationElFind>[0]>;
  [QUERY_KEY.v2apiReservationElCreate]: Partial<Parameters<typeof apiClient.v2.apiReservationElCreate>[0]>;
  [QUERY_KEY.v2apiReservationElCreateRegist]: Partial<Parameters<typeof apiClient.v2.apiReservationElCreateRegist>[0]>;
  [QUERY_KEY.v2apiReservationElUpdate]: Partial<Parameters<typeof apiClient.v2.apiReservationElUpdate>[0]>;
  [QUERY_KEY.v2apiReservationElRemove]: Partial<Parameters<typeof apiClient.v2.apiReservationElRemove>[0]>;
  [QUERY_KEY.v2apiReservationElLast]: Partial<Parameters<typeof apiClient.v2.apiReservationElLast>[0]>;
  [QUERY_KEY.v2apiReservationElFindNext]: Partial<Parameters<typeof apiClient.v2.apiReservationElFindNext>[0]>;
  [QUERY_KEY.v2apiReservationElFindHistory]: Partial<Parameters<typeof apiClient.v2.apiReservationElFindHistory>[0]>;
  [QUERY_KEY.v2apiReservationElFindParent]: Partial<Parameters<typeof apiClient.v2.apiReservationElFindParent>[0]>;
  [QUERY_KEY.v2apiReservationElFindSchedule]: Partial<Parameters<typeof apiClient.v2.apiReservationElFindSchedule>[0]>;
  [QUERY_KEY.v2apiReservationMFind]: Partial<Parameters<typeof apiClient.v2.apiReservationMFind>[0]>;
  [QUERY_KEY.v2apiReservationMFindLast]: Partial<Parameters<typeof apiClient.v2.apiReservationMFindLast>[0]>;
  [QUERY_KEY.v2apiReservationMFindDate]: Partial<Parameters<typeof apiClient.v2.apiReservationMFindDate>[0]>;
  [QUERY_KEY.v2apiReservationMFindNext]: Partial<Parameters<typeof apiClient.v2.apiReservationMFindNext>[0]>;
  [QUERY_KEY.v2apiReservationMCreate]: Partial<Parameters<typeof apiClient.v2.apiReservationMCreate>[0]>;
  [QUERY_KEY.v2apiReservationMUpdate]: Partial<Parameters<typeof apiClient.v2.apiReservationMUpdate>[0]>;
  [QUERY_KEY.v2apiReservationMRemove]: Partial<Parameters<typeof apiClient.v2.apiReservationMRemove>[0]>;
  [QUERY_KEY.v2apiReservationMPossibledate]: Partial<Parameters<typeof apiClient.v2.apiReservationMPossibledate>[0]>;
  [QUERY_KEY.v2apiReservationHmFind]: Partial<Parameters<typeof apiClient.v2.apiReservationHmFind>[0]>;
  [QUERY_KEY.v2apiReservationHmCreate]: Partial<Parameters<typeof apiClient.v2.apiReservationHmCreate>[0]>;
  [QUERY_KEY.v2apiReservationHmUpdate]: Partial<Parameters<typeof apiClient.v2.apiReservationHmUpdate>[0]>;
  [QUERY_KEY.v2apiReservationHmRemove]: Partial<Parameters<typeof apiClient.v2.apiReservationHmRemove>[0]>;
  [QUERY_KEY.v2apiReservationHmFindHistory]: Partial<Parameters<typeof apiClient.v2.apiReservationHmFindHistory>[0]>;
  [QUERY_KEY.v2apiReservationvacationElFind]: Partial<Parameters<typeof apiClient.v2.apiReservationvacationElFind>[0]>;
  [QUERY_KEY.v2apiReservationvacationElFindHospital]: Partial<Parameters<typeof apiClient.v2.apiReservationvacationElFindHospital>[0]>;
  [QUERY_KEY.v2apiReservationvacationElFindSheet]: Partial<Parameters<typeof apiClient.v2.apiReservationvacationElFindSheet>[0]>;
  [QUERY_KEY.v2apiReservationvacationElFindColumn]: Partial<Parameters<typeof apiClient.v2.apiReservationvacationElFindColumn>[0]>;
  [QUERY_KEY.v2apiReservationvacationElCreate]: Partial<Parameters<typeof apiClient.v2.apiReservationvacationElCreate>[0]>;
  [QUERY_KEY.v2apiReservationvacationElUpdate]: Partial<Parameters<typeof apiClient.v2.apiReservationvacationElUpdate>[0]>;
  [QUERY_KEY.v2apiReservationvacationElRemove]: Partial<Parameters<typeof apiClient.v2.apiReservationvacationElRemove>[0]>;
  [QUERY_KEY.v2apiReservationvacationHmFind]: Partial<Parameters<typeof apiClient.v2.apiReservationvacationHmFind>[0]>;
  [QUERY_KEY.v2apiReservationblockElFind]: Partial<Parameters<typeof apiClient.v2.apiReservationblockElFind>[0]>;
  [QUERY_KEY.v2apiReservationblockElCreate]: Partial<Parameters<typeof apiClient.v2.apiReservationblockElCreate>[0]>;
  [QUERY_KEY.v2apiReservationblockElUpdate]: Partial<Parameters<typeof apiClient.v2.apiReservationblockElUpdate>[0]>;
  [QUERY_KEY.v2apiReservationblockElRemove]: Partial<Parameters<typeof apiClient.v2.apiReservationblockElRemove>[0]>;
  [QUERY_KEY.v2apiReservationblockHmFind]: Partial<Parameters<typeof apiClient.v2.apiReservationblockHmFind>[0]>;
  [QUERY_KEY.v2apiReservationblockHmCreate]: Partial<Parameters<typeof apiClient.v2.apiReservationblockHmCreate>[0]>;
  [QUERY_KEY.v2apiReservationblockHmUpdate]: Partial<Parameters<typeof apiClient.v2.apiReservationblockHmUpdate>[0]>;
  [QUERY_KEY.v2apiReservationblockHmRemove]: Partial<Parameters<typeof apiClient.v2.apiReservationblockHmRemove>[0]>;
  [QUERY_KEY.v2apiReservationsheetdatenoteElFind]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetdatenoteElFind>[0]>;
  [QUERY_KEY.v2apiReservationsheetdatenoteElFindOne]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetdatenoteElFindOne>[0]>;
  [QUERY_KEY.v2apiReservationsheetdatenoteElUpdate]: Partial<Parameters<typeof apiClient.v2.apiReservationsheetdatenoteElUpdate>[0]>;
  [QUERY_KEY.v2apiReservationusernoteElFind]: Partial<Parameters<typeof apiClient.v2.apiReservationusernoteElFind>[0]>;
  [QUERY_KEY.v2apiReservationusernoteElUpdate]: Partial<Parameters<typeof apiClient.v2.apiReservationusernoteElUpdate>[0]>;
  [QUERY_KEY.v2apiReservationusernoteHmFind]: Partial<Parameters<typeof apiClient.v2.apiReservationusernoteHmFind>[0]>;
  [QUERY_KEY.v2apiHreservationElFind]: Partial<Parameters<typeof apiClient.v2.apiHreservationElFind>[0]>;
  [QUERY_KEY.v2apiHreservationHmFind]: Partial<Parameters<typeof apiClient.v2.apiHreservationHmFind>[0]>;
  [QUERY_KEY.v2apiUsertagElFind]: Partial<Parameters<typeof apiClient.v2.apiUsertagElFind>[0]>;
  [QUERY_KEY.v2apiUsertagElCreate]: Partial<Parameters<typeof apiClient.v2.apiUsertagElCreate>[0]>;
  [QUERY_KEY.v2apiUsertagElUpdate]: Partial<Parameters<typeof apiClient.v2.apiUsertagElUpdate>[0]>;
  [QUERY_KEY.v2apiUsertagElRemove]: Partial<Parameters<typeof apiClient.v2.apiUsertagElRemove>[0]>;
  [QUERY_KEY.v2apiVideocontentEl]: Partial<Parameters<typeof apiClient.v2.apiVideocontentEl>[0]>;
  [QUERY_KEY.v2apiVideocontentElCreate]: Partial<Parameters<typeof apiClient.v2.apiVideocontentElCreate>[0]>;
  [QUERY_KEY.v2apiVideocontentElUpdate]: Partial<Parameters<typeof apiClient.v2.apiVideocontentElUpdate>[0]>;
  [QUERY_KEY.v2apiVideocontentElRemove]: Partial<Parameters<typeof apiClient.v2.apiVideocontentElRemove>[0]>;
  [QUERY_KEY.v2apiServicesettingElFind]: Partial<Parameters<typeof apiClient.v2.apiServicesettingElFind>[0]>;
  [QUERY_KEY.v2apiServicesettingElFindOne]: Partial<Parameters<typeof apiClient.v2.apiServicesettingElFindOne>[0]>;
  [QUERY_KEY.v2apiServicesettingElCreate]: Partial<Parameters<typeof apiClient.v2.apiServicesettingElCreate>[0]>;
  [QUERY_KEY.v2apiServicesettingElUpdate]: Partial<Parameters<typeof apiClient.v2.apiServicesettingElUpdate>[0]>;
  [QUERY_KEY.v2apiServicesettingHmFindOne]: Partial<Parameters<typeof apiClient.v2.apiServicesettingHmFindOne>[0]>;
  [QUERY_KEY.v2apiIntegrationsettingElFind]: Partial<Parameters<typeof apiClient.v2.apiIntegrationsettingElFind>[0]>;
  [QUERY_KEY.v2apiIntegrationsettingElFindOne]: Partial<Parameters<typeof apiClient.v2.apiIntegrationsettingElFindOne>[0]>;
  [QUERY_KEY.v2apiIntegrationsettingElCreate]: Partial<Parameters<typeof apiClient.v2.apiIntegrationsettingElCreate>[0]>;
  [QUERY_KEY.v2apiIntegrationsettingElUpdate]: Partial<Parameters<typeof apiClient.v2.apiIntegrationsettingElUpdate>[0]>;
  [QUERY_KEY.v2apiVideocontenthospitalEl]: Partial<Parameters<typeof apiClient.v2.apiVideocontenthospitalEl>[0]>;
  [QUERY_KEY.v2apiVideocontenthospitalElCreate]: Partial<Parameters<typeof apiClient.v2.apiVideocontenthospitalElCreate>[0]>;
  [QUERY_KEY.v2apiVideocontenthospitalElFind]: Partial<Parameters<typeof apiClient.v2.apiVideocontenthospitalElFind>[0]>;
  [QUERY_KEY.v2apiVideocontenthospitalElDetail]: Partial<Parameters<typeof apiClient.v2.apiVideocontenthospitalElDetail>[0]>;
  [QUERY_KEY.v2apiVideocontenthospitalElUpdate]: Partial<Parameters<typeof apiClient.v2.apiVideocontenthospitalElUpdate>[0]>;
  [QUERY_KEY.v2apiVideocontenthospitalElRecommend]: Partial<Parameters<typeof apiClient.v2.apiVideocontenthospitalElRecommend>[0]>;
  [QUERY_KEY.v2apiVideocontenthospitalElRemove]: Partial<Parameters<typeof apiClient.v2.apiVideocontenthospitalElRemove>[0]>;
  [QUERY_KEY.v2apiVideocontenthospitalElPlay]: Partial<Parameters<typeof apiClient.v2.apiVideocontenthospitalElPlay>[0]>;
  [QUERY_KEY.v2apiVideocontenthospitalElPlaysub]: Partial<Parameters<typeof apiClient.v2.apiVideocontenthospitalElPlaysub>[0]>;
  [QUERY_KEY.v2apiVideocontentuserEl]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserEl>[0]>;
  [QUERY_KEY.v2apiVideocontentuserElFindstats]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserElFindstats>[0]>;
  [QUERY_KEY.v2apiVideocontentuserElCreate]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserElCreate>[0]>;
  [QUERY_KEY.v2apiVideocontentuserElDetail]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserElDetail>[0]>;
  [QUERY_KEY.v2apiVideocontentuserElUpdate]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserElUpdate>[0]>;
  [QUERY_KEY.v2apiVideocontentuserElRemove]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserElRemove>[0]>;
  [QUERY_KEY.v2apiVideocontentuserM]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserM>[0]>;
  [QUERY_KEY.v2apiVideocontentuserMDetail]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserMDetail>[0]>;
  [QUERY_KEY.v2apiVideocontentuserMFind]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserMFind>[0]>;
  [QUERY_KEY.v2apiVideocontentuserMFindCount]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserMFindCount>[0]>;
  [QUERY_KEY.v2apiVideocontentuserMPlay]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserMPlay>[0]>;
  [QUERY_KEY.v2apiVideocontentuserMPlaysub]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserMPlaysub>[0]>;
  [QUERY_KEY.v2apiVideocontentuserMUpdateRead]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserMUpdateRead>[0]>;
  [QUERY_KEY.v2apiVideocontentuserMUpdateCv]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserMUpdateCv>[0]>;
  [QUERY_KEY.v2apiVideocontentuserMUpdatePv]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserMUpdatePv>[0]>;
  [QUERY_KEY.v2apiVideocontentuserMShareimage]: Partial<Parameters<typeof apiClient.v2.apiVideocontentuserMShareimage>[0]>;
  [QUERY_KEY.v2apiHistoryuseractionEl]: Partial<Parameters<typeof apiClient.v2.apiHistoryuseractionEl>[0]>;
  [QUERY_KEY.v2apiHistoryuseractionElFind]: Partial<Parameters<typeof apiClient.v2.apiHistoryuseractionElFind>[0]>;
  [QUERY_KEY.v2apiHistoryuseractionElCreate]: Partial<Parameters<typeof apiClient.v2.apiHistoryuseractionElCreate>[0]>;
  [QUERY_KEY.v2apiHistoryuseractionElUpdate]: Partial<Parameters<typeof apiClient.v2.apiHistoryuseractionElUpdate>[0]>;
  [QUERY_KEY.v2apiHistoryuseractionElRemove]: Partial<Parameters<typeof apiClient.v2.apiHistoryuseractionElRemove>[0]>;
  [QUERY_KEY.v2apiHistoryuseractionMCreate]: Partial<Parameters<typeof apiClient.v2.apiHistoryuseractionMCreate>[0]>;
  [QUERY_KEY.v2apiVideocontentcategoryEl]: Partial<Parameters<typeof apiClient.v2.apiVideocontentcategoryEl>[0]>;
  [QUERY_KEY.v2apiVideocontentcategoryElCreate]: Partial<Parameters<typeof apiClient.v2.apiVideocontentcategoryElCreate>[0]>;
  [QUERY_KEY.v2apiVideocontentcategoryElUpdate]: Partial<Parameters<typeof apiClient.v2.apiVideocontentcategoryElUpdate>[0]>;
  [QUERY_KEY.v2apiVideocontentcategoryElRemove]: Partial<Parameters<typeof apiClient.v2.apiVideocontentcategoryElRemove>[0]>;
  [QUERY_KEY.v2apiVideocontentcategoryM]: Partial<Parameters<typeof apiClient.v2.apiVideocontentcategoryM>[0]>;
  [QUERY_KEY.v2apiDashboardDailyUserEl]: Partial<Parameters<typeof apiClient.v2.apiDashboardDailyUserEl>[0]>;
  [QUERY_KEY.v2apiDashboardDailyUserElFindtop]: Partial<Parameters<typeof apiClient.v2.apiDashboardDailyUserElFindtop>[0]>;
  [QUERY_KEY.v2apiDashboardDailyColumnEl]: Partial<Parameters<typeof apiClient.v2.apiDashboardDailyColumnEl>[0]>;
  [QUERY_KEY.v2apiDashboardDailyColumnElFindtop]: Partial<Parameters<typeof apiClient.v2.apiDashboardDailyColumnElFindtop>[0]>;
  [QUERY_KEY.v2apiDashboardDailyColumnprogramEl]: Partial<Parameters<typeof apiClient.v2.apiDashboardDailyColumnprogramEl>[0]>;
  [QUERY_KEY.v2apiDashboardDailyColumnprogramElFindtop]: Partial<Parameters<typeof apiClient.v2.apiDashboardDailyColumnprogramElFindtop>[0]>;
  [QUERY_KEY.v2apiDashboardWeeklyUserEl]: Partial<Parameters<typeof apiClient.v2.apiDashboardWeeklyUserEl>[0]>;
  [QUERY_KEY.v2apiDashboardWeeklyUserElFindtop]: Partial<Parameters<typeof apiClient.v2.apiDashboardWeeklyUserElFindtop>[0]>;
  [QUERY_KEY.v2apiDashboardWeeklyColumnEl]: Partial<Parameters<typeof apiClient.v2.apiDashboardWeeklyColumnEl>[0]>;
  [QUERY_KEY.v2apiDashboardWeeklyColumnElFindtop]: Partial<Parameters<typeof apiClient.v2.apiDashboardWeeklyColumnElFindtop>[0]>;
  [QUERY_KEY.v2apiDashboardWeeklyColumnprogramEl]: Partial<Parameters<typeof apiClient.v2.apiDashboardWeeklyColumnprogramEl>[0]>;
  [QUERY_KEY.v2apiDashboardWeeklyColumnprogramElFindtop]: Partial<Parameters<typeof apiClient.v2.apiDashboardWeeklyColumnprogramElFindtop>[0]>;
  [QUERY_KEY.v2apiDashboardMonthlyUserEl]: Partial<Parameters<typeof apiClient.v2.apiDashboardMonthlyUserEl>[0]>;
  [QUERY_KEY.v2apiDashboardMonthlyUserElFindtop]: Partial<Parameters<typeof apiClient.v2.apiDashboardMonthlyUserElFindtop>[0]>;
  [QUERY_KEY.v2apiDashboardMonthlyColumnEl]: Partial<Parameters<typeof apiClient.v2.apiDashboardMonthlyColumnEl>[0]>;
  [QUERY_KEY.v2apiDashboardMonthlyColumnElFindtop]: Partial<Parameters<typeof apiClient.v2.apiDashboardMonthlyColumnElFindtop>[0]>;
  [QUERY_KEY.v2apiDashboardMonthlyColumnprogramEl]: Partial<Parameters<typeof apiClient.v2.apiDashboardMonthlyColumnprogramEl>[0]>;
  [QUERY_KEY.v2apiDashboardMonthlyColumnprogramElFindtop]: Partial<Parameters<typeof apiClient.v2.apiDashboardMonthlyColumnprogramElFindtop>[0]>;
  [QUERY_KEY.v2apiDashboardHserviceElCountJoinuser]: Partial<Parameters<typeof apiClient.v2.apiDashboardHserviceElCountJoinuser>[0]>;
  [QUERY_KEY.v2apiDashboardDailyColumnprogramElSheet]: Partial<Parameters<typeof apiClient.v2.apiDashboardDailyColumnprogramElSheet>[0]>;
  [QUERY_KEY.v2apiDashboardWeeklyColumnprogramElSheet]: Partial<Parameters<typeof apiClient.v2.apiDashboardWeeklyColumnprogramElSheet>[0]>;
  [QUERY_KEY.v2apiDashboardMonthlyColumnprogramElSheet]: Partial<Parameters<typeof apiClient.v2.apiDashboardMonthlyColumnprogramElSheet>[0]>;
  [QUERY_KEY.v2apiTreatmentcategoryEl]: Partial<Parameters<typeof apiClient.v2.apiTreatmentcategoryEl>[0]>;
  [QUERY_KEY.v2apiTreatmentcategoryElCreate]: Partial<Parameters<typeof apiClient.v2.apiTreatmentcategoryElCreate>[0]>;
  [QUERY_KEY.v2apiTreatmentcategoryElUpdate]: Partial<Parameters<typeof apiClient.v2.apiTreatmentcategoryElUpdate>[0]>;
  [QUERY_KEY.v2apiTreatmentcategoryElRemove]: Partial<Parameters<typeof apiClient.v2.apiTreatmentcategoryElRemove>[0]>;
  [QUERY_KEY.v2apiTreatmentCautionEl]: Partial<Parameters<typeof apiClient.v2.apiTreatmentCautionEl>[0]>;
  [QUERY_KEY.v2apiTreatmentcautionElCreate]: Partial<Parameters<typeof apiClient.v2.apiTreatmentcautionElCreate>[0]>;
  [QUERY_KEY.v2apiTreatmentcautionElUpdate]: Partial<Parameters<typeof apiClient.v2.apiTreatmentcautionElUpdate>[0]>;
  [QUERY_KEY.v2apiTreatmentcautionElRemove]: Partial<Parameters<typeof apiClient.v2.apiTreatmentcautionElRemove>[0]>;
  [QUERY_KEY.v2apiEventHospitalEventElFind]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalEventElFind>[0]>;
  [QUERY_KEY.v2apiEventHospitalEventElFindSort]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalEventElFindSort>[0]>;
  [QUERY_KEY.v2apiEventHospitalEventElCount]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalEventElCount>[0]>;
  [QUERY_KEY.v2apiEventHospitalEventElCreate]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalEventElCreate>[0]>;
  [QUERY_KEY.v2apiEventHospitalEventElUpdate]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalEventElUpdate>[0]>;
  [QUERY_KEY.v2apiEventHospitalEventElRemove]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalEventElRemove>[0]>;
  [QUERY_KEY.v2apiEventHospitalEventMFind]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalEventMFind>[0]>;
  [QUERY_KEY.v2apiEventHospitalEventMFindOne]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalEventMFindOne>[0]>;
  [QUERY_KEY.v2apiEventHospitalEventMFindDetail]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalEventMFindDetail>[0]>;
  [QUERY_KEY.v2apiEventHospitalAftercareElFind]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalAftercareElFind>[0]>;
  [QUERY_KEY.v2apiEventHospitalAftercareElFindSort]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalAftercareElFindSort>[0]>;
  [QUERY_KEY.v2apiEventHospitalAftercareElCount]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalAftercareElCount>[0]>;
  [QUERY_KEY.v2apiEventHospitalAftercareElCreate]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalAftercareElCreate>[0]>;
  [QUERY_KEY.v2apiEventHospitalAftercareElUpdate]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalAftercareElUpdate>[0]>;
  [QUERY_KEY.v2apiEventHospitalAftercareElRemove]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalAftercareElRemove>[0]>;
  [QUERY_KEY.v2apiEventHospitalAftercareMFind]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalAftercareMFind>[0]>;
  [QUERY_KEY.v2apiEventHospitalAftercareMFindDetail]: Partial<Parameters<typeof apiClient.v2.apiEventHospitalAftercareMFindDetail>[0]>;
  [QUERY_KEY.v2apiHserviceElSuspend]: Partial<Parameters<typeof apiClient.v2.apiHserviceElSuspend>[0]>;
  [QUERY_KEY.v2apiHserviceElUpdate]: Partial<Parameters<typeof apiClient.v2.apiHserviceElUpdate>[0]>;
  [QUERY_KEY.v2apiHserviceElFindSimple]: Partial<Parameters<typeof apiClient.v2.apiHserviceElFindSimple>[0]>;
  [QUERY_KEY.v2apiHserviceElFindDetail]: Partial<Parameters<typeof apiClient.v2.apiHserviceElFindDetail>[0]>;
  [QUERY_KEY.v2apiHserviceElFindTreatmentcategoryCount]: Partial<Parameters<typeof apiClient.v2.apiHserviceElFindTreatmentcategoryCount>[0]>;
  [QUERY_KEY.v2apiHserviceElFindTreatmentcategory]: Partial<Parameters<typeof apiClient.v2.apiHserviceElFindTreatmentcategory>[0]>;
  [QUERY_KEY.v2apiHserviceElFindUnjoined]: Partial<Parameters<typeof apiClient.v2.apiHserviceElFindUnjoined>[0]>;
  [QUERY_KEY.v2apiHserviceElFindUnjoinedCount]: Partial<Parameters<typeof apiClient.v2.apiHserviceElFindUnjoinedCount>[0]>;
  [QUERY_KEY.v2apiHserviceElFindNeedsetting]: Partial<Parameters<typeof apiClient.v2.apiHserviceElFindNeedsetting>[0]>;
  [QUERY_KEY.v2apiHserviceElFindPatientsettingCount]: Partial<Parameters<typeof apiClient.v2.apiHserviceElFindPatientsettingCount>[0]>;
  [QUERY_KEY.v2apiHserviceElFind]: Partial<Parameters<typeof apiClient.v2.apiHserviceElFind>[0]>;
  [QUERY_KEY.v2apiHserviceElFindCount]: Partial<Parameters<typeof apiClient.v2.apiHserviceElFindCount>[0]>;
  [QUERY_KEY.v2apiHserviceElFindChat]: Partial<Parameters<typeof apiClient.v2.apiHserviceElFindChat>[0]>;
  [QUERY_KEY.v2apiHserviceElFindChatCount]: Partial<Parameters<typeof apiClient.v2.apiHserviceElFindChatCount>[0]>;
  [QUERY_KEY.v2apiHserviceElUpdateSetting]: Partial<Parameters<typeof apiClient.v2.apiHserviceElUpdateSetting>[0]>;
  [QUERY_KEY.v2apiHserviceElUpdateNeedvcu]: Partial<Parameters<typeof apiClient.v2.apiHserviceElUpdateNeedvcu>[0]>;
  [QUERY_KEY.v2apiHserviceMSuspend]: Partial<Parameters<typeof apiClient.v2.apiHserviceMSuspend>[0]>;
  [QUERY_KEY.v2apiHserviceMUpdateSelect]: Partial<Parameters<typeof apiClient.v2.apiHserviceMUpdateSelect>[0]>;
  [QUERY_KEY.v2apiHserviceHmFindSimple]: Partial<Parameters<typeof apiClient.v2.apiHserviceHmFindSimple>[0]>;
  [QUERY_KEY.v2apiMemoElCreate]: Partial<Parameters<typeof apiClient.v2.apiMemoElCreate>[0]>;
  [QUERY_KEY.v2apiMemoElFind]: Partial<Parameters<typeof apiClient.v2.apiMemoElFind>[0]>;
  [QUERY_KEY.v2apiMemoElFindManage]: Partial<Parameters<typeof apiClient.v2.apiMemoElFindManage>[0]>;
  [QUERY_KEY.v2apiMemoElFindNurse]: Partial<Parameters<typeof apiClient.v2.apiMemoElFindNurse>[0]>;
  [QUERY_KEY.v2apiMemoElFindPatient]: Partial<Parameters<typeof apiClient.v2.apiMemoElFindPatient>[0]>;
  [QUERY_KEY.v2apiMemoElFindLast]: Partial<Parameters<typeof apiClient.v2.apiMemoElFindLast>[0]>;
  [QUERY_KEY.v2apiMemoElFindManageCount]: Partial<Parameters<typeof apiClient.v2.apiMemoElFindManageCount>[0]>;
  [QUERY_KEY.v2apiMemoElFindNurseCount]: Partial<Parameters<typeof apiClient.v2.apiMemoElFindNurseCount>[0]>;
  [QUERY_KEY.v2apiMemoElFindPatientCount]: Partial<Parameters<typeof apiClient.v2.apiMemoElFindPatientCount>[0]>;
  [QUERY_KEY.v2apiMemoElFindManageLast]: Partial<Parameters<typeof apiClient.v2.apiMemoElFindManageLast>[0]>;
  [QUERY_KEY.v2apiMemoElFindNurseLast]: Partial<Parameters<typeof apiClient.v2.apiMemoElFindNurseLast>[0]>;
  [QUERY_KEY.v2apiMemoElFindPatientLast]: Partial<Parameters<typeof apiClient.v2.apiMemoElFindPatientLast>[0]>;
  [QUERY_KEY.v2apiMemoElUpdate]: Partial<Parameters<typeof apiClient.v2.apiMemoElUpdate>[0]>;
  [QUERY_KEY.v2apiMemoElRemove]: Partial<Parameters<typeof apiClient.v2.apiMemoElRemove>[0]>;
  [QUERY_KEY.v2apiMemoElUpdatePin]: Partial<Parameters<typeof apiClient.v2.apiMemoElUpdatePin>[0]>;
  [QUERY_KEY.v2apiMemoElRead]: Partial<Parameters<typeof apiClient.v2.apiMemoElRead>[0]>;
  [QUERY_KEY.v2apiPurchaserecordElFind]: Partial<Parameters<typeof apiClient.v2.apiPurchaserecordElFind>[0]>;
  [QUERY_KEY.v2apiPurchaserecordElCount]: Partial<Parameters<typeof apiClient.v2.apiPurchaserecordElCount>[0]>;
  [QUERY_KEY.v2apiPurchaserecordElCreate]: Partial<Parameters<typeof apiClient.v2.apiPurchaserecordElCreate>[0]>;
  [QUERY_KEY.v2apiPurchaserecordElUpdate]: Partial<Parameters<typeof apiClient.v2.apiPurchaserecordElUpdate>[0]>;
  [QUERY_KEY.v2apiPurchaserecordElRemove]: Partial<Parameters<typeof apiClient.v2.apiPurchaserecordElRemove>[0]>;
  [QUERY_KEY.v2apiHospitalAccountElFind]: Partial<Parameters<typeof apiClient.v2.apiHospitalAccountElFind>[0]>;
  [QUERY_KEY.v2apiHospitalAccountElCreate]: Partial<Parameters<typeof apiClient.v2.apiHospitalAccountElCreate>[0]>;
  [QUERY_KEY.v2apiHospitalAccountElUpdate]: Partial<Parameters<typeof apiClient.v2.apiHospitalAccountElUpdate>[0]>;
  [QUERY_KEY.v2apiHospitalAccountElIdcheck]: Partial<Parameters<typeof apiClient.v2.apiHospitalAccountElIdcheck>[0]>;
  [QUERY_KEY.v2apiHospitalAccountElReset]: Partial<Parameters<typeof apiClient.v2.apiHospitalAccountElReset>[0]>;
  [QUERY_KEY.v2apiHospitalAccountElRemove]: Partial<Parameters<typeof apiClient.v2.apiHospitalAccountElRemove>[0]>;
  [QUERY_KEY.v2apiHospitalAuthorizationtypeElFind]: Partial<Parameters<typeof apiClient.v2.apiHospitalAuthorizationtypeElFind>[0]>;
  [QUERY_KEY.v2apiHospitalAuthorizationtypeElUpdate]: Partial<Parameters<typeof apiClient.v2.apiHospitalAuthorizationtypeElUpdate>[0]>;
  [QUERY_KEY.v2apiHealthcontentsCategoryElCreate]: Partial<Parameters<typeof apiClient.v2.apiHealthcontentsCategoryElCreate>[0]>;
  [QUERY_KEY.v2apiHealthcontentsCategoryElFind]: Partial<Parameters<typeof apiClient.v2.apiHealthcontentsCategoryElFind>[0]>;
  [QUERY_KEY.v2apiHealthcontentsCategoryElUpdate]: Partial<Parameters<typeof apiClient.v2.apiHealthcontentsCategoryElUpdate>[0]>;
  [QUERY_KEY.v2apiHealthcontentsCategoryElRemove]: Partial<Parameters<typeof apiClient.v2.apiHealthcontentsCategoryElRemove>[0]>;
  [QUERY_KEY.v2apiHealthcontentsCategoryMFind]: Partial<Parameters<typeof apiClient.v2.apiHealthcontentsCategoryMFind>[0]>;
  [QUERY_KEY.v2apiHealthcontentsContentElCreate]: Partial<Parameters<typeof apiClient.v2.apiHealthcontentsContentElCreate>[0]>;
  [QUERY_KEY.v2apiHealthcontentsContentElFind]: Partial<Parameters<typeof apiClient.v2.apiHealthcontentsContentElFind>[0]>;
  [QUERY_KEY.v2apiHealthcontentsContentElUpdate]: Partial<Parameters<typeof apiClient.v2.apiHealthcontentsContentElUpdate>[0]>;
  [QUERY_KEY.v2apiHealthcontentsContentElRemove]: Partial<Parameters<typeof apiClient.v2.apiHealthcontentsContentElRemove>[0]>;
  [QUERY_KEY.v2apiHealthcontentsContentMFind]: Partial<Parameters<typeof apiClient.v2.apiHealthcontentsContentMFind>[0]>;
  [QUERY_KEY.v2apiHealthcontentsContentMCount]: Partial<Parameters<typeof apiClient.v2.apiHealthcontentsContentMCount>[0]>;
  [QUERY_KEY.v2apiHealthcontentsBookmarkMCreate]: Partial<Parameters<typeof apiClient.v2.apiHealthcontentsBookmarkMCreate>[0]>;
  [QUERY_KEY.v2apiHealthcontentsBookmarkMFind]: Partial<Parameters<typeof apiClient.v2.apiHealthcontentsBookmarkMFind>[0]>;
  [QUERY_KEY.v2apiHospitalcontentsContentElCreate]: Partial<Parameters<typeof apiClient.v2.apiHospitalcontentsContentElCreate>[0]>;
  [QUERY_KEY.v2apiHospitalcontentsContentElFind]: Partial<Parameters<typeof apiClient.v2.apiHospitalcontentsContentElFind>[0]>;
  [QUERY_KEY.v2apiHospitalcontentsContentElRemove]: Partial<Parameters<typeof apiClient.v2.apiHospitalcontentsContentElRemove>[0]>;
  [QUERY_KEY.v2apiHospitalcontentsContentElUpdate]: Partial<Parameters<typeof apiClient.v2.apiHospitalcontentsContentElUpdate>[0]>;
  [QUERY_KEY.v2apiHospitalcontentsContentMCount]: Partial<Parameters<typeof apiClient.v2.apiHospitalcontentsContentMCount>[0]>;
  [QUERY_KEY.v2apiHospitalcontentsContentMFind]: Partial<Parameters<typeof apiClient.v2.apiHospitalcontentsContentMFind>[0]>;
  [QUERY_KEY.v2apiHospitalcontentsCategoryElCreate]: Partial<Parameters<typeof apiClient.v2.apiHospitalcontentsCategoryElCreate>[0]>;
  [QUERY_KEY.v2apiHospitalcontentsCategoryElFind]: Partial<Parameters<typeof apiClient.v2.apiHospitalcontentsCategoryElFind>[0]>;
  [QUERY_KEY.v2apiHospitalcontentsCategoryElFindOne]: Partial<Parameters<typeof apiClient.v2.apiHospitalcontentsCategoryElFindOne>[0]>;
  [QUERY_KEY.v2apiHospitalcontentsCategoryElRemove]: Partial<Parameters<typeof apiClient.v2.apiHospitalcontentsCategoryElRemove>[0]>;
  [QUERY_KEY.v2apiHospitalcontentsCategoryElUpdate]: Partial<Parameters<typeof apiClient.v2.apiHospitalcontentsCategoryElUpdate>[0]>;
  [QUERY_KEY.v2apiHospitalcontentsCategoryMFind]: Partial<Parameters<typeof apiClient.v2.apiHospitalcontentsCategoryMFind>[0]>;
  [QUERY_KEY.v2apiHospitalcontentsBookmarkMCreate]: Partial<Parameters<typeof apiClient.v2.apiHospitalcontentsBookmarkMCreate>[0]>;
  [QUERY_KEY.v2apiHospitalcontentsBookmarkMFind]: Partial<Parameters<typeof apiClient.v2.apiHospitalcontentsBookmarkMFind>[0]>;
  [QUERY_KEY.v2apiShortlinkVideocontentMCreate]: Partial<Parameters<typeof apiClient.v2.apiShortlinkVideocontentMCreate>[0]>;
  [QUERY_KEY.v2apiShortlinkVideocontentMUpdateGr]: Partial<Parameters<typeof apiClient.v2.apiShortlinkVideocontentMUpdateGr>[0]>;
  [QUERY_KEY.v2apiShortlinkEventMCreate]: Partial<Parameters<typeof apiClient.v2.apiShortlinkEventMCreate>[0]>;
  [QUERY_KEY.v2apiShortlinkAftercareMCreate]: Partial<Parameters<typeof apiClient.v2.apiShortlinkAftercareMCreate>[0]>;
  [QUERY_KEY.v2apiShortlinkHserviceMFindOne]: Partial<Parameters<typeof apiClient.v2.apiShortlinkHserviceMFindOne>[0]>;
  [QUERY_KEY.v2sharedVideocontent]: Partial<Parameters<typeof apiClient.v2.sharedVideocontent>[0]>;
  [QUERY_KEY.v2sharedEvent]: Partial<Parameters<typeof apiClient.v2.sharedEvent>[0]>;
  [QUERY_KEY.v2sharedAftercare]: Partial<Parameters<typeof apiClient.v2.sharedAftercare>[0]>;
  [QUERY_KEY.v2apiPushsettingElFindOne]: Partial<Parameters<typeof apiClient.v2.apiPushsettingElFindOne>[0]>;
  [QUERY_KEY.v2apiPushsettingElUpdate]: Partial<Parameters<typeof apiClient.v2.apiPushsettingElUpdate>[0]>;
  [QUERY_KEY.v2apiPushsettingMFindOne]: Partial<Parameters<typeof apiClient.v2.apiPushsettingMFindOne>[0]>;
  [QUERY_KEY.v2apiPushsettingMUpdate]: Partial<Parameters<typeof apiClient.v2.apiPushsettingMUpdate>[0]>;
  [QUERY_KEY.v2apiNoticeHospitalElFind]: Partial<Parameters<typeof apiClient.v2.apiNoticeHospitalElFind>[0]>;
  [QUERY_KEY.v2apiNoticeHospitalElFindSimple]: Partial<Parameters<typeof apiClient.v2.apiNoticeHospitalElFindSimple>[0]>;
  [QUERY_KEY.v2apiNoticeHospitalElCount]: Partial<Parameters<typeof apiClient.v2.apiNoticeHospitalElCount>[0]>;
  [QUERY_KEY.v2apiNoticeHospitalElCreate]: Partial<Parameters<typeof apiClient.v2.apiNoticeHospitalElCreate>[0]>;
  [QUERY_KEY.v2apiNoticeHospitalElUpdate]: Partial<Parameters<typeof apiClient.v2.apiNoticeHospitalElUpdate>[0]>;
  [QUERY_KEY.v2apiNoticeHospitalElRemove]: Partial<Parameters<typeof apiClient.v2.apiNoticeHospitalElRemove>[0]>;
  [QUERY_KEY.v2apiNoticeHospitalMFind]: Partial<Parameters<typeof apiClient.v2.apiNoticeHospitalMFind>[0]>;
  [QUERY_KEY.v2apiNoticeHospitalMFindOne]: Partial<Parameters<typeof apiClient.v2.apiNoticeHospitalMFindOne>[0]>;
  [QUERY_KEY.v2apiNoticeHospitalMFindDetail]: Partial<Parameters<typeof apiClient.v2.apiNoticeHospitalMFindDetail>[0]>;
  [QUERY_KEY.v2apiPopupHospitalElFind]: Partial<Parameters<typeof apiClient.v2.apiPopupHospitalElFind>[0]>;
  [QUERY_KEY.v2apiPopupHospitalElCount]: Partial<Parameters<typeof apiClient.v2.apiPopupHospitalElCount>[0]>;
  [QUERY_KEY.v2apiPopupHospitalElCreate]: Partial<Parameters<typeof apiClient.v2.apiPopupHospitalElCreate>[0]>;
  [QUERY_KEY.v2apiPopupHospitalElUpdate]: Partial<Parameters<typeof apiClient.v2.apiPopupHospitalElUpdate>[0]>;
  [QUERY_KEY.v2apiPopupHospitalElRemove]: Partial<Parameters<typeof apiClient.v2.apiPopupHospitalElRemove>[0]>;
  [QUERY_KEY.v2apiPopupHospitalMFind]: Partial<Parameters<typeof apiClient.v2.apiPopupHospitalMFind>[0]>;
  [QUERY_KEY.v2apiPopupHospitalMIncView]: Partial<Parameters<typeof apiClient.v2.apiPopupHospitalMIncView>[0]>;
  [QUERY_KEY.v2apiNotificationsoundElCount]: Partial<Parameters<typeof apiClient.v2.apiNotificationsoundElCount>[0]>;
  [QUERY_KEY.v2apiNotificationsoundEl]: Partial<Parameters<typeof apiClient.v2.apiNotificationsoundEl>[0]>;
  [QUERY_KEY.v2apiNotificationsoundElCreate]: Partial<Parameters<typeof apiClient.v2.apiNotificationsoundElCreate>[0]>;
  [QUERY_KEY.v2apiNotificationsoundElUpdate]: Partial<Parameters<typeof apiClient.v2.apiNotificationsoundElUpdate>[0]>;
  [QUERY_KEY.v2apiV2ReservationElCount]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationElCount>[0]>;
  [QUERY_KEY.v2apiV2ReservationElFind]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationElFind>[0]>;
  [QUERY_KEY.v2apiV2ReservationElFindHistory]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationElFindHistory>[0]>;
  [QUERY_KEY.v2apiV2ReservationElFindNext]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationElFindNext>[0]>;
  [QUERY_KEY.v2apiV2ReservationElCreate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationElCreate>[0]>;
  [QUERY_KEY.v2apiV2ReservationElCreateRegist]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationElCreateRegist>[0]>;
  [QUERY_KEY.v2apiV2ReservationElCreateUnregistered]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationElCreateUnregistered>[0]>;
  [QUERY_KEY.v2apiV2ReservationElUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationElUpdate>[0]>;
  [QUERY_KEY.v2apiV2ReservationElRemove]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationElRemove>[0]>;
  [QUERY_KEY.v2apiV2ReservationElSendPush]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationElSendPush>[0]>;
  [QUERY_KEY.v2apiV2ReservationMFind]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationMFind>[0]>;
  [QUERY_KEY.v2apiV2ReservationMFindOne]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationMFindOne>[0]>;
  [QUERY_KEY.v2apiV2ReservationMFindDate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationMFindDate>[0]>;
  [QUERY_KEY.v2apiV2ReservationMFindLast]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationMFindLast>[0]>;
  [QUERY_KEY.v2apiV2ReservationMFindNext]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationMFindNext>[0]>;
  [QUERY_KEY.v2apiV2ReservationMPossibledate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationMPossibledate>[0]>;
  [QUERY_KEY.v2apiV2ReservationMPossibletime]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationMPossibletime>[0]>;
  [QUERY_KEY.v2apiV2ReservationMCreate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationMCreate>[0]>;
  [QUERY_KEY.v2apiV2ReservationMUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationMUpdate>[0]>;
  [QUERY_KEY.v2apiV2ReservationMCancel]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationMCancel>[0]>;
  [QUERY_KEY.v2apiV2ReservationcellElFind]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationcellElFind>[0]>;
  [QUERY_KEY.v2apiV2ReservationcellElCreate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationcellElCreate>[0]>;
  [QUERY_KEY.v2apiV2ReservationcellElUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationcellElUpdate>[0]>;
  [QUERY_KEY.v2apiV2ReservationcellElRemove]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationcellElRemove>[0]>;
  [QUERY_KEY.v2apiV2ReservationblockElFind]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationblockElFind>[0]>;
  [QUERY_KEY.v2apiV2ReservationblockElCreate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationblockElCreate>[0]>;
  [QUERY_KEY.v2apiV2ReservationblockElUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationblockElUpdate>[0]>;
  [QUERY_KEY.v2apiV2ReservationblockElRemove]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationblockElRemove>[0]>;
  [QUERY_KEY.v2apiV2ReservationblockMFind]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationblockMFind>[0]>;
  [QUERY_KEY.v2apiV2ReservationsettingElFindOne]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationsettingElFindOne>[0]>;
  [QUERY_KEY.v2apiV2ReservationsettingElUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationsettingElUpdate>[0]>;
  [QUERY_KEY.v2apiV2ReservationsettingMFindOne]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationsettingMFindOne>[0]>;
  [QUERY_KEY.v2apiV2ReservationstatusElFind]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationstatusElFind>[0]>;
  [QUERY_KEY.v2apiV2ReservationstatusElCreate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationstatusElCreate>[0]>;
  [QUERY_KEY.v2apiV2ReservationstatusElUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationstatusElUpdate>[0]>;
  [QUERY_KEY.v2apiV2ReservationstatusElRemove]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationstatusElRemove>[0]>;
  [QUERY_KEY.v2apiV2ReservationstatusElSendPush]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationstatusElSendPush>[0]>;
  [QUERY_KEY.v2apiV2ReservationgroupElFind]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationgroupElFind>[0]>;
  [QUERY_KEY.v2apiV2ReservationgroupElFindOne]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationgroupElFindOne>[0]>;
  [QUERY_KEY.v2apiV2ReservationgroupElFindRemoved]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationgroupElFindRemoved>[0]>;
  [QUERY_KEY.v2apiV2ReservationgroupElCreate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationgroupElCreate>[0]>;
  [QUERY_KEY.v2apiV2ReservationgroupElUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationgroupElUpdate>[0]>;
  [QUERY_KEY.v2apiV2ReservationgroupElRemove]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationgroupElRemove>[0]>;
  [QUERY_KEY.v2apiV2ReservationgroupElUpdateSort]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationgroupElUpdateSort>[0]>;
  [QUERY_KEY.v2apiV2ReservationcolumnElFind]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationcolumnElFind>[0]>;
  [QUERY_KEY.v2apiV2ReservationcolumnElFindRemoved]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationcolumnElFindRemoved>[0]>;
  [QUERY_KEY.v2apiV2ReservationcolumnElCreate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationcolumnElCreate>[0]>;
  [QUERY_KEY.v2apiV2ReservationcolumnElUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationcolumnElUpdate>[0]>;
  [QUERY_KEY.v2apiV2ReservationcolumnElRemove]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationcolumnElRemove>[0]>;
  [QUERY_KEY.v2apiV2ReservationcolumnElUpdateSort]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationcolumnElUpdateSort>[0]>;
  [QUERY_KEY.v2apiV2ReservationcolumnMDate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationcolumnMDate>[0]>;
  [QUERY_KEY.v2apiV2ReservationcolumnMActive]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationcolumnMActive>[0]>;
  [QUERY_KEY.v2apiV2ReservationprogramElFind]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationprogramElFind>[0]>;
  [QUERY_KEY.v2apiV2ReservationprogramElCreate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationprogramElCreate>[0]>;
  [QUERY_KEY.v2apiV2ReservationprogramElUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationprogramElUpdate>[0]>;
  [QUERY_KEY.v2apiV2ReservationprogramElRemove]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationprogramElRemove>[0]>;
  [QUERY_KEY.v2apiV2ReservationprogramElSendPush]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationprogramElSendPush>[0]>;
  [QUERY_KEY.v2apiV2ReservationprogramMFind]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationprogramMFind>[0]>;
  [QUERY_KEY.v2apiV2ReservationusernoteElFind]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationusernoteElFind>[0]>;
  [QUERY_KEY.v2apiV2ReservationusernoteElUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationusernoteElUpdate>[0]>;
  [QUERY_KEY.v2apiV2ReservationdatenoteElFind]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationdatenoteElFind>[0]>;
  [QUERY_KEY.v2apiV2ReservationdatenoteElFindDate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationdatenoteElFindDate>[0]>;
  [QUERY_KEY.v2apiV2ReservationdatenoteElUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2ReservationdatenoteElUpdate>[0]>;
  [QUERY_KEY.v2apiV2HreservationElFind]: Partial<Parameters<typeof apiClient.v2.apiV2HreservationElFind>[0]>;
  [QUERY_KEY.v2apiV2HreservationElCount]: Partial<Parameters<typeof apiClient.v2.apiV2HreservationElCount>[0]>;
  [QUERY_KEY.v2apiV2DepartmentElFind]: Partial<Parameters<typeof apiClient.v2.apiV2DepartmentElFind>[0]>;
  [QUERY_KEY.v2apiV2DepartmentElFindOne]: Partial<Parameters<typeof apiClient.v2.apiV2DepartmentElFindOne>[0]>;
  [QUERY_KEY.v2apiV2DepartmentElCreate]: Partial<Parameters<typeof apiClient.v2.apiV2DepartmentElCreate>[0]>;
  [QUERY_KEY.v2apiV2DepartmentElUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2DepartmentElUpdate>[0]>;
  [QUERY_KEY.v2apiV2DepartmentElUpdateSort]: Partial<Parameters<typeof apiClient.v2.apiV2DepartmentElUpdateSort>[0]>;
  [QUERY_KEY.v2apiV2DepartmentElRemove]: Partial<Parameters<typeof apiClient.v2.apiV2DepartmentElRemove>[0]>;
  [QUERY_KEY.v2apiV2EmployeeElFind]: Partial<Parameters<typeof apiClient.v2.apiV2EmployeeElFind>[0]>;
  [QUERY_KEY.v2apiV2EmployeeElCreate]: Partial<Parameters<typeof apiClient.v2.apiV2EmployeeElCreate>[0]>;
  [QUERY_KEY.v2apiV2EmployeeElUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2EmployeeElUpdate>[0]>;
  [QUERY_KEY.v2apiV2EmployeeElUpdateSort]: Partial<Parameters<typeof apiClient.v2.apiV2EmployeeElUpdateSort>[0]>;
  [QUERY_KEY.v2apiV2EmployeeElRemove]: Partial<Parameters<typeof apiClient.v2.apiV2EmployeeElRemove>[0]>;
  [QUERY_KEY.v2apiV2WorkcodeElFind]: Partial<Parameters<typeof apiClient.v2.apiV2WorkcodeElFind>[0]>;
  [QUERY_KEY.v2apiV2WorkcodeElFindSchedule]: Partial<Parameters<typeof apiClient.v2.apiV2WorkcodeElFindSchedule>[0]>;
  [QUERY_KEY.v2apiV2WorkcodeElCreate]: Partial<Parameters<typeof apiClient.v2.apiV2WorkcodeElCreate>[0]>;
  [QUERY_KEY.v2apiV2WorkcodeElUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2WorkcodeElUpdate>[0]>;
  [QUERY_KEY.v2apiV2WorkcodeElRemove]: Partial<Parameters<typeof apiClient.v2.apiV2WorkcodeElRemove>[0]>;
  [QUERY_KEY.v2apiV2WorkcodeElSendPush]: Partial<Parameters<typeof apiClient.v2.apiV2WorkcodeElSendPush>[0]>;
  [QUERY_KEY.v2apiV2WorkrecordElFindDate]: Partial<Parameters<typeof apiClient.v2.apiV2WorkrecordElFindDate>[0]>;
  [QUERY_KEY.v2apiV2WorkrecordElFindSchedule]: Partial<Parameters<typeof apiClient.v2.apiV2WorkrecordElFindSchedule>[0]>;
  [QUERY_KEY.v2apiV2WorkrecordElUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2WorkrecordElUpdate>[0]>;
  [QUERY_KEY.v2apiV2WorkrecordElUpdateBatch]: Partial<Parameters<typeof apiClient.v2.apiV2WorkrecordElUpdateBatch>[0]>;
  [QUERY_KEY.v2apiV2WorkrecordElRemove]: Partial<Parameters<typeof apiClient.v2.apiV2WorkrecordElRemove>[0]>;
  [QUERY_KEY.v2apiV2WorkrecordElSendPush]: Partial<Parameters<typeof apiClient.v2.apiV2WorkrecordElSendPush>[0]>;
  [QUERY_KEY.v2apiV2WorkholidayElFindDate]: Partial<Parameters<typeof apiClient.v2.apiV2WorkholidayElFindDate>[0]>;
  [QUERY_KEY.v2apiV2WorkholidayElCreate]: Partial<Parameters<typeof apiClient.v2.apiV2WorkholidayElCreate>[0]>;
  [QUERY_KEY.v2apiV2WorkholidayElUpdate]: Partial<Parameters<typeof apiClient.v2.apiV2WorkholidayElUpdate>[0]>;
  [QUERY_KEY.v2apiV2WorkholidayElRemove]: Partial<Parameters<typeof apiClient.v2.apiV2WorkholidayElRemove>[0]>;
  [QUERY_KEY.v2apiV2WorkholidayMFindDate]: Partial<Parameters<typeof apiClient.v2.apiV2WorkholidayMFindDate>[0]>;
  [QUERY_KEY.v2apiStatsV2ReservationDailyElFind]: Partial<Parameters<typeof apiClient.v2.apiStatsV2ReservationDailyElFind>[0]>;
  [QUERY_KEY.v2apiStatsV2ReservationDailyElFindHospital]: Partial<Parameters<typeof apiClient.v2.apiStatsV2ReservationDailyElFindHospital>[0]>;
  [QUERY_KEY.v2apiStatsV2ReservationMonthlyElFind]: Partial<Parameters<typeof apiClient.v2.apiStatsV2ReservationMonthlyElFind>[0]>;
  [QUERY_KEY.v2apiStatsV2ReservationMonthlyElFindHospital]: Partial<Parameters<typeof apiClient.v2.apiStatsV2ReservationMonthlyElFindHospital>[0]>;
  [QUERY_KEY.v2apiStatsV2WorkrecordDailyElFind]: Partial<Parameters<typeof apiClient.v2.apiStatsV2WorkrecordDailyElFind>[0]>;
  [QUERY_KEY.v2apiSiAlHserviceElFindSimple]: Partial<Parameters<typeof apiClient.v2.apiSiAlHserviceElFindSimple>[0]>;
  [QUERY_KEY.v2publicServersettingMFind]: Partial<Parameters<typeof apiClient.v2.publicServersettingMFind>[0]>;
  [QUERY_KEY.v2publicServersettingHmFind]: Partial<Parameters<typeof apiClient.v2.publicServersettingHmFind>[0]>;
  [QUERY_KEY.v2apiPushRequestElFind]: Partial<Parameters<typeof apiClient.v2.apiPushRequestElFind>[0]>;
  [QUERY_KEY.v2apiPushRequestElCreate]: Partial<Parameters<typeof apiClient.v2.apiPushRequestElCreate>[0]>;
  [QUERY_KEY.v2apiPushResponseElFind]: Partial<Parameters<typeof apiClient.v2.apiPushResponseElFind>[0]>;
  [QUERY_KEY.v2apiUseractionHospitaleventMCreate]: Partial<Parameters<typeof apiClient.v2.apiUseractionHospitaleventMCreate>[0]>;
  [QUERY_KEY.v2adminHealthcontentPushElCreate]: Partial<Parameters<typeof apiClient.v2.adminHealthcontentPushElCreate>[0]>;
  [QUERY_KEY.v2adminHealthcontentPushElFind]: Partial<Parameters<typeof apiClient.v2.adminHealthcontentPushElFind>[0]>;
  [QUERY_KEY.v2adminHealthcontentPushElRemove]: Partial<Parameters<typeof apiClient.v2.adminHealthcontentPushElRemove>[0]>;
  [QUERY_KEY.v2adminHealthcontentContentElCreate]: Partial<Parameters<typeof apiClient.v2.adminHealthcontentContentElCreate>[0]>;
  [QUERY_KEY.v2adminHealthcontentContentElFind]: Partial<Parameters<typeof apiClient.v2.adminHealthcontentContentElFind>[0]>;
  [QUERY_KEY.v2adminHealthcontentContentElRemove]: Partial<Parameters<typeof apiClient.v2.adminHealthcontentContentElRemove>[0]>;
  [QUERY_KEY.v2adminHealthcontentContentElUpdate]: Partial<Parameters<typeof apiClient.v2.adminHealthcontentContentElUpdate>[0]>;
  [QUERY_KEY.v2adminHealthcontentCategoryElFind]: Partial<Parameters<typeof apiClient.v2.adminHealthcontentCategoryElFind>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentHlsvideoElCreate]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentHlsvideoElCreate>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentHlsvideoElFind]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentHlsvideoElFind>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentHlsvideoElUpdate]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentHlsvideoElUpdate>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentHlsvideoElCount]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentHlsvideoElCount>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentglobalsettingElCreate]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentglobalsettingElCreate>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentglobalsettingElFind]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentglobalsettingElFind>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentglobalsettingElUpdate]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentglobalsettingElUpdate>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentElCreate]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentElCreate>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentElFind]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentElFind>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentElUpdate]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentElUpdate>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentElRemove]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentElRemove>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentElCount]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentElCount>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontenthospitalElFind]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontenthospitalElFind>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontenthospitalElCreate]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontenthospitalElCreate>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontenthospitalElUpdate]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontenthospitalElUpdate>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontenthospitalElCopyAll]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontenthospitalElCopyAll>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontenthospitalElCopyGlobalcategory]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontenthospitalElCopyGlobalcategory>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontenthospitalElCopyHospitalcategory]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontenthospitalElCopyHospitalcategory>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentcategoryElCreate]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentcategoryElCreate>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentcategoryElFind]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentcategoryElFind>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentcategoryElFindOne]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentcategoryElFindOne>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentcategoryElFindTop]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentcategoryElFindTop>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentcategoryElFindLast]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentcategoryElFindLast>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentcategoryElFindConditioning]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentcategoryElFindConditioning>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentcategoryElUpdate]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentcategoryElUpdate>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentcategoryElUpdateHidden]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentcategoryElUpdateHidden>[0]>;
  [QUERY_KEY.v2adminHomecareVideocontentVideocontentcategoryElUpdateTypename]: Partial<Parameters<typeof apiClient.v2.adminHomecareVideocontentVideocontentcategoryElUpdateTypename>[0]>;
  [QUERY_KEY.v2adminHospitalElFind]: Partial<Parameters<typeof apiClient.v2.adminHospitalElFind>[0]>;
  [QUERY_KEY.v2adminHospitalElFindNurse]: Partial<Parameters<typeof apiClient.v2.adminHospitalElFindNurse>[0]>;
  [QUERY_KEY.v2adminHospitalElCreate]: Partial<Parameters<typeof apiClient.v2.adminHospitalElCreate>[0]>;
  [QUERY_KEY.v2adminHospitalElCount]: Partial<Parameters<typeof apiClient.v2.adminHospitalElCount>[0]>;
  [QUERY_KEY.v2adminHospitalElUpdate]: Partial<Parameters<typeof apiClient.v2.adminHospitalElUpdate>[0]>;
  [QUERY_KEY.v2adminHospitalElFindDoctor]: Partial<Parameters<typeof apiClient.v2.adminHospitalElFindDoctor>[0]>;
  [QUERY_KEY.v2adminHospitalElCountDoctor]: Partial<Parameters<typeof apiClient.v2.adminHospitalElCountDoctor>[0]>;
  [QUERY_KEY.v2adminHospitalElCreateDoctor]: Partial<Parameters<typeof apiClient.v2.adminHospitalElCreateDoctor>[0]>;
  [QUERY_KEY.v2adminHospitalElUpdateDoctor]: Partial<Parameters<typeof apiClient.v2.adminHospitalElUpdateDoctor>[0]>;
  [QUERY_KEY.v2adminHserviceElFindIsclosed]: Partial<Parameters<typeof apiClient.v2.adminHserviceElFindIsclosed>[0]>;
  [QUERY_KEY.v2adminHserviceElUpdateSuspendAll]: Partial<Parameters<typeof apiClient.v2.adminHserviceElUpdateSuspendAll>[0]>;
  [QUERY_KEY.v2adminNurseElFind]: Partial<Parameters<typeof apiClient.v2.adminNurseElFind>[0]>;
  [QUERY_KEY.v2adminNurseElCreate]: Partial<Parameters<typeof apiClient.v2.adminNurseElCreate>[0]>;
  [QUERY_KEY.v2adminNurseElUpdate]: Partial<Parameters<typeof apiClient.v2.adminNurseElUpdate>[0]>;
  [QUERY_KEY.v2adminNurseElCount]: Partial<Parameters<typeof apiClient.v2.adminNurseElCount>[0]>;
  [QUERY_KEY.v2adminServicesettingElFind]: Partial<Parameters<typeof apiClient.v2.adminServicesettingElFind>[0]>;
  [QUERY_KEY.v2adminServicesettingElFindOne]: Partial<Parameters<typeof apiClient.v2.adminServicesettingElFindOne>[0]>;
  [QUERY_KEY.v2adminServicesettingElFindUsingvc]: Partial<Parameters<typeof apiClient.v2.adminServicesettingElFindUsingvc>[0]>;
  [QUERY_KEY.v2adminServicesettingElCreate]: Partial<Parameters<typeof apiClient.v2.adminServicesettingElCreate>[0]>;
  [QUERY_KEY.v2adminServicesettingElCreateSample]: Partial<Parameters<typeof apiClient.v2.adminServicesettingElCreateSample>[0]>;
  [QUERY_KEY.v2adminServicesettingElCount]: Partial<Parameters<typeof apiClient.v2.adminServicesettingElCount>[0]>;
  [QUERY_KEY.v2adminServicesettingElUpdate]: Partial<Parameters<typeof apiClient.v2.adminServicesettingElUpdate>[0]>;
  [QUERY_KEY.v2adminServicesettingElUpdateSample]: Partial<Parameters<typeof apiClient.v2.adminServicesettingElUpdateSample>[0]>;
  [QUERY_KEY.v2adminAuthorizationtypeElFind]: Partial<Parameters<typeof apiClient.v2.adminAuthorizationtypeElFind>[0]>;
  [QUERY_KEY.v2adminTreatmentcategoryElCreate]: Partial<Parameters<typeof apiClient.v2.adminTreatmentcategoryElCreate>[0]>;
  [QUERY_KEY.v2adminSettingVersionElFind]: Partial<Parameters<typeof apiClient.v2.adminSettingVersionElFind>[0]>;
  [QUERY_KEY.v2adminSettingVersionElUpdate]: Partial<Parameters<typeof apiClient.v2.adminSettingVersionElUpdate>[0]>;
  [QUERY_KEY.v2adminIntegrationsettingElFind]: Partial<Parameters<typeof apiClient.v2.adminIntegrationsettingElFind>[0]>;
  [QUERY_KEY.v2adminIntegrationsettingElFindOne]: Partial<Parameters<typeof apiClient.v2.adminIntegrationsettingElFindOne>[0]>;
  [QUERY_KEY.v2adminIntegrationsettingElCreate]: Partial<Parameters<typeof apiClient.v2.adminIntegrationsettingElCreate>[0]>;
  [QUERY_KEY.v2adminIntegrationsettingElUpdate]: Partial<Parameters<typeof apiClient.v2.adminIntegrationsettingElUpdate>[0]>;
  [QUERY_KEY.v2adminAnsweringchatElFind]: Partial<Parameters<typeof apiClient.v2.adminAnsweringchatElFind>[0]>;
  [QUERY_KEY.v2adminAnsweringchatElCount]: Partial<Parameters<typeof apiClient.v2.adminAnsweringchatElCount>[0]>;
  [QUERY_KEY.v2adminAnsweringchatsettingElFind]: Partial<Parameters<typeof apiClient.v2.adminAnsweringchatsettingElFind>[0]>;
  [QUERY_KEY.v2adminAnsweringchatsettingElCreate]: Partial<Parameters<typeof apiClient.v2.adminAnsweringchatsettingElCreate>[0]>;
  [QUERY_KEY.v2adminAnsweringchatsettingElUpdate]: Partial<Parameters<typeof apiClient.v2.adminAnsweringchatsettingElUpdate>[0]>;
  [QUERY_KEY.v2adminMonitoringHospitalElFind]: Partial<Parameters<typeof apiClient.v2.adminMonitoringHospitalElFind>[0]>;
  [QUERY_KEY.v2adminMonitoringHospitalElCount]: Partial<Parameters<typeof apiClient.v2.adminMonitoringHospitalElCount>[0]>;
  [QUERY_KEY.v2adminMonitoringHospitalElCreate]: Partial<Parameters<typeof apiClient.v2.adminMonitoringHospitalElCreate>[0]>;
  [QUERY_KEY.v2adminMonitoringHospitalElUpdate]: Partial<Parameters<typeof apiClient.v2.adminMonitoringHospitalElUpdate>[0]>;
  [QUERY_KEY.v2adminMonitoringHospitalElRemove]: Partial<Parameters<typeof apiClient.v2.adminMonitoringHospitalElRemove>[0]>;
  [QUERY_KEY.v2adminPurchaserecordElFind]: Partial<Parameters<typeof apiClient.v2.adminPurchaserecordElFind>[0]>;
  [QUERY_KEY.v2adminEventHospitalEventElFind]: Partial<Parameters<typeof apiClient.v2.adminEventHospitalEventElFind>[0]>;
  [QUERY_KEY.v2adminEventHospitalEventElFindOne]: Partial<Parameters<typeof apiClient.v2.adminEventHospitalEventElFindOne>[0]>;
  [QUERY_KEY.v2apiReservedsmsElCount]: Partial<Parameters<typeof apiClient.v2.apiReservedsmsElCount>[0]>;
  [QUERY_KEY.v2apiReservedsmsElFind]: Partial<Parameters<typeof apiClient.v2.apiReservedsmsElFind>[0]>;
  [QUERY_KEY.v2adminCaCareservedchatElCount]: Partial<Parameters<typeof apiClient.v2.adminCaCareservedchatElCount>[0]>;
  [QUERY_KEY.v2adminCaCareservedchatElFind]: Partial<Parameters<typeof apiClient.v2.adminCaCareservedchatElFind>[0]>;
  [QUERY_KEY.v2adminCaCareservedchatElCreate]: Partial<Parameters<typeof apiClient.v2.adminCaCareservedchatElCreate>[0]>;
  [QUERY_KEY.v2adminCaCareservedchatElCreatemany]: Partial<Parameters<typeof apiClient.v2.adminCaCareservedchatElCreatemany>[0]>;
  [QUERY_KEY.v2adminStatsEventHospitalEventElFind]: Partial<Parameters<typeof apiClient.v2.adminStatsEventHospitalEventElFind>[0]>;
  [QUERY_KEY.v2adminStatsEventHospitalEventElCount]: Partial<Parameters<typeof apiClient.v2.adminStatsEventHospitalEventElCount>[0]>;
  [QUERY_KEY.v2adminStatsEventHospitalEventpushElFind]: Partial<Parameters<typeof apiClient.v2.adminStatsEventHospitalEventpushElFind>[0]>;
  [QUERY_KEY.v2adminStatsEventHospitalEventpushElCount]: Partial<Parameters<typeof apiClient.v2.adminStatsEventHospitalEventpushElCount>[0]>;
  [QUERY_KEY.v2publicEmailSend]: Partial<Parameters<typeof apiClient.v2.publicEmailSend>[0]>;
  [QUERY_KEY.v2publicRegistKakaoConsult]: Partial<Parameters<typeof apiClient.v2.publicRegistKakaoConsult>[0]>;
  [QUERY_KEY.v2publicRegistKakaoFriend]: Partial<Parameters<typeof apiClient.v2.publicRegistKakaoFriend>[0]>;
  [QUERY_KEY.apitestGetHandler]: Partial<Parameters<typeof apiClient.api.testGetHandler>[0]>;
  [QUERY_KEY.apitestPostHandler]: Partial<Parameters<typeof apiClient.api.testPostHandler>[0]>;
  [QUERY_KEY.apitestPutHandler]: Partial<Parameters<typeof apiClient.api.testPutHandler>[0]>;
  [QUERY_KEY.apitestPatchHandler]: Partial<Parameters<typeof apiClient.api.testPatchHandler>[0]>;
  [QUERY_KEY.apitestDeleteHandler]: Partial<Parameters<typeof apiClient.api.testDeleteHandler>[0]>;
  [QUERY_KEY.apitestChatElFindHandler]: Partial<Parameters<typeof apiClient.api.testChatElFindHandler>[0]>;
  [QUERY_KEY.apitestChatElSendHnoticeHandler]: Partial<Parameters<typeof apiClient.api.testChatElSendHnoticeHandler>[0]>;
  [QUERY_KEY.shortLinkMHserviceInfoHandler]: Partial<Parameters<typeof apiClient.v3.shortLinkMHserviceInfoHandler>[0]>;
  [QUERY_KEY.shortLinkMUserInfoHandler]: Partial<Parameters<typeof apiClient.v3.shortLinkMUserInfoHandler>[0]>;
  [QUERY_KEY.phoneMRequestHandler]: Partial<Parameters<typeof apiClient.v3.phoneMRequestHandler>[0]>;
  [QUERY_KEY.phoneMRequestChangePhoneHandler]: Partial<Parameters<typeof apiClient.v3.phoneMRequestChangePhoneHandler>[0]>;
  [QUERY_KEY.phoneMRequestUnidentifyHandler]: Partial<Parameters<typeof apiClient.v3.phoneMRequestUnidentifyHandler>[0]>;
  [QUERY_KEY.phoneMVerifyHandler]: Partial<Parameters<typeof apiClient.v3.phoneMVerifyHandler>[0]>;
  [QUERY_KEY.phoneMChangeHandler]: Partial<Parameters<typeof apiClient.v3.phoneMChangeHandler>[0]>;
  [QUERY_KEY.elPhoneCodeRequestHandler]: Partial<Parameters<typeof apiClient.v3.elPhoneCodeRequestHandler>[0]>;
  [QUERY_KEY.elPhoneCodeVerifyingRequestHandler]: Partial<Parameters<typeof apiClient.v3.elPhoneCodeVerifyingRequestHandler>[0]>;
  [QUERY_KEY.passwordMHasPasswordHandler]: Partial<Parameters<typeof apiClient.v3.passwordMHasPasswordHandler>[0]>;
  [QUERY_KEY.mPasswordUpdateHandler]: Partial<Parameters<typeof apiClient.v3.mPasswordUpdateHandler>[0]>;
  [QUERY_KEY.joinMMassiveLinkHandler]: Partial<Parameters<typeof apiClient.v3.joinMMassiveLinkHandler>[0]>;
  [QUERY_KEY.joinMReleasePendingHandler]: Partial<Parameters<typeof apiClient.v3.joinMReleasePendingHandler>[0]>;
  [QUERY_KEY.loginMHandler]: Partial<Parameters<typeof apiClient.v3.loginMHandler>[0]>;
  [QUERY_KEY.loginMCheckPhoneHandler]: Partial<Parameters<typeof apiClient.v3.loginMCheckPhoneHandler>[0]>;
  [QUERY_KEY.elLoginAndTokenHandler]: Partial<Parameters<typeof apiClient.v3.elLoginAndTokenHandler>[0]>;
  [QUERY_KEY.elLogoutHandler]: Partial<Parameters<typeof apiClient.v3.elLogoutHandler>[0]>;
  [QUERY_KEY.hmLoginAndTokenHandler]: Partial<Parameters<typeof apiClient.v3.hmLoginAndTokenHandler>[0]>;
  [QUERY_KEY.hmLogoutHandler]: Partial<Parameters<typeof apiClient.v3.hmLogoutHandler>[0]>;
  [QUERY_KEY.apiElUpdateDeviceinfoHandler]: Partial<Parameters<typeof apiClient.v3.apiElUpdateDeviceinfoHandler>[0]>;
  [QUERY_KEY.userMPhoneCodeHandler]: Partial<Parameters<typeof apiClient.v3.userMPhoneCodeHandler>[0]>;
  [QUERY_KEY.userMPhoneUpdateHandler]: Partial<Parameters<typeof apiClient.v3.userMPhoneUpdateHandler>[0]>;
  [QUERY_KEY.chatRoomMUpdateHandler]: Partial<Parameters<typeof apiClient.v3.chatRoomMUpdateHandler>[0]>;
  [QUERY_KEY.chatRoomMFindOneHandler]: Partial<Parameters<typeof apiClient.v3.chatRoomMFindOneHandler>[0]>;
  [QUERY_KEY.chatRoomMUpdateReadHandler]: Partial<Parameters<typeof apiClient.v3.chatRoomMUpdateReadHandler>[0]>;
  [QUERY_KEY.chatRoomElFindServiceInformationHandler]: Partial<Parameters<typeof apiClient.v3.chatRoomElFindServiceInformationHandler>[0]>;
  [QUERY_KEY.chatRoomHmFindServiceInformationHandler]: Partial<Parameters<typeof apiClient.v3.chatRoomHmFindServiceInformationHandler>[0]>;
  [QUERY_KEY.chatMCreateHAftercareHandler]: Partial<Parameters<typeof apiClient.v3.chatMCreateHAftercareHandler>[0]>;
  [QUERY_KEY.chatMCreateHEventHandler]: Partial<Parameters<typeof apiClient.v3.chatMCreateHEventHandler>[0]>;
  [QUERY_KEY.chatMCreateReservationHandler]: Partial<Parameters<typeof apiClient.v3.chatMCreateReservationHandler>[0]>;
  [QUERY_KEY.chatMCountHandler]: Partial<Parameters<typeof apiClient.v3.chatMCountHandler>[0]>;
  [QUERY_KEY.chatMFindHandler]: Partial<Parameters<typeof apiClient.v3.chatMFindHandler>[0]>;
  [QUERY_KEY.chatRoomsLatestConsultationGuideHandler]: Partial<Parameters<typeof apiClient.v3.chatRoomsLatestConsultationGuideHandler>[0]>;
  [QUERY_KEY.hospitalsBackgroundJobsHandler]: Partial<Parameters<typeof apiClient.v3.hospitalsBackgroundJobsHandler>[0]>;
  [QUERY_KEY.countryMFindHandler]: Partial<Parameters<typeof apiClient.v3.countryMFindHandler>[0]>;
  [QUERY_KEY.countryMFindOneHandler]: Partial<Parameters<typeof apiClient.v3.countryMFindOneHandler>[0]>;
  [QUERY_KEY.targetableNationalitiesHandler]: Partial<Parameters<typeof apiClient.v3.targetableNationalitiesHandler>[0]>;
  [QUERY_KEY.singleAlgorithmMessageHandler]: Partial<Parameters<typeof apiClient.v3.singleAlgorithmMessageHandler>[0]>;
  [QUERY_KEY.singleAlgorithmHandler]: Partial<Parameters<typeof apiClient.v3.singleAlgorithmHandler>[0]>;
  [QUERY_KEY.marketingAlgorithmTargetCountHandler]: Partial<Parameters<typeof apiClient.v3.marketingAlgorithmTargetCountHandler>[0]>;
  [QUERY_KEY.submittingSurveyResponseHandler]: Partial<Parameters<typeof apiClient.v3.submittingSurveyResponseHandler>[0]>;
  [QUERY_KEY.sendingConsultationAlgorithmMessageBatchHandler]: Partial<Parameters<typeof apiClient.v3.sendingConsultationAlgorithmMessageBatchHandler>[0]>;
  [QUERY_KEY.sendingMarketingAlgorithmMessageBatchHandler]: Partial<Parameters<typeof apiClient.v3.sendingMarketingAlgorithmMessageBatchHandler>[0]>;
  [QUERY_KEY.applyingCommonAlgorithmHandler]: Partial<Parameters<typeof apiClient.v3.applyingCommonAlgorithmHandler>[0]>;
  [QUERY_KEY.generatingSurveyHandler]: Partial<Parameters<typeof apiClient.v3.generatingSurveyHandler>[0]>;
  [QUERY_KEY.updateAlgorithmMainDataHandler]: Partial<Parameters<typeof apiClient.v3.updateAlgorithmMainDataHandler>[0]>;
  [QUERY_KEY.sendingCommonAlgorithmMessageBatchHandler]: Partial<Parameters<typeof apiClient.v3.sendingCommonAlgorithmMessageBatchHandler>[0]>;
  [QUERY_KEY.renewingKakaoTemplateStatusBatchHandler]: Partial<Parameters<typeof apiClient.v3.renewingKakaoTemplateStatusBatchHandler>[0]>;
  [QUERY_KEY.singleHospitalPushSettingHandler]: Partial<Parameters<typeof apiClient.v3.singleHospitalPushSettingHandler>[0]>;
  [QUERY_KEY.hospitalPushSettingUpdateHandler]: Partial<Parameters<typeof apiClient.v3.hospitalPushSettingUpdateHandler>[0]>;
  [QUERY_KEY.hospitalMobileSingleHospitalPushSettingHandler]: Partial<Parameters<typeof apiClient.v3.hospitalMobileSingleHospitalPushSettingHandler>[0]>;
  [QUERY_KEY.hospitalMobileHospitalPushSettingUpdateHandler]: Partial<Parameters<typeof apiClient.v3.hospitalMobileHospitalPushSettingUpdateHandler>[0]>;
  [QUERY_KEY.pushNotificationSendHandler]: Partial<Parameters<typeof apiClient.v3.pushNotificationSendHandler>[0]>;
  [QUERY_KEY.pushNotificationModuleTestHandler]: Partial<Parameters<typeof apiClient.v3.pushNotificationModuleTestHandler>[0]>;
  [QUERY_KEY.singlePatientDeletionHandler]: Partial<Parameters<typeof apiClient.v3.singlePatientDeletionHandler>[0]>;
  [QUERY_KEY.temporaryPatientsIsLockHandler]: Partial<Parameters<typeof apiClient.v3.temporaryPatientsIsLockHandler>[0]>;
  [QUERY_KEY.temporaryPatientsRequestLockHandler]: Partial<Parameters<typeof apiClient.v3.temporaryPatientsRequestLockHandler>[0]>;
  [QUERY_KEY.temporaryPatientsLastModifierHandler]: Partial<Parameters<typeof apiClient.v3.temporaryPatientsLastModifierHandler>[0]>;
  [QUERY_KEY.temporaryPatientsProcessingBatchHandler]: Partial<Parameters<typeof apiClient.v3.temporaryPatientsProcessingBatchHandler>[0]>;
  [QUERY_KEY.patientsHasNoTreatmentTagBatchHandler]: Partial<Parameters<typeof apiClient.v3.patientsHasNoTreatmentTagBatchHandler>[0]>;
  [QUERY_KEY.patientsHasRenewedFirstTagsBatchHandler]: Partial<Parameters<typeof apiClient.v3.patientsHasRenewedFirstTagsBatchHandler>[0]>;
  [QUERY_KEY.noPatientsCreatedBatchHandler]: Partial<Parameters<typeof apiClient.v3.noPatientsCreatedBatchHandler>[0]>;
  [QUERY_KEY.tempPatientsProcessingBatchHandler]: Partial<Parameters<typeof apiClient.v3.tempPatientsProcessingBatchHandler>[0]>;
  [QUERY_KEY.elFindLinkedChatroomsHandler]: Partial<Parameters<typeof apiClient.v3.elFindLinkedChatroomsHandler>[0]>;
  [QUERY_KEY.hospitalsAccountsHandler]: Partial<Parameters<typeof apiClient.v3.hospitalsAccountsHandler>[0]>;
  [QUERY_KEY.hospitalsAccountsCountTuningHandler]: Partial<Parameters<typeof apiClient.v3.hospitalsAccountsCountTuningHandler>[0]>;
  [QUERY_KEY.kakaoConsultationTalkLinkageHandler]: Partial<Parameters<typeof apiClient.v3.kakaoConsultationTalkLinkageHandler>[0]>;
  [QUERY_KEY.kakaoLatestLinkageHistoryHandler]: Partial<Parameters<typeof apiClient.v3.kakaoLatestLinkageHistoryHandler>[0]>;
  [QUERY_KEY.kakaoAuthenticationNumberHandler]: Partial<Parameters<typeof apiClient.v3.kakaoAuthenticationNumberHandler>[0]>;
  [QUERY_KEY.kakaoProfileCategoryHandler]: Partial<Parameters<typeof apiClient.v3.kakaoProfileCategoryHandler>[0]>;
  [QUERY_KEY.kakaoAlimFriendTalkLinkageHandler]: Partial<Parameters<typeof apiClient.v3.kakaoAlimFriendTalkLinkageHandler>[0]>;
  [QUERY_KEY.instagramLinkageHandler]: Partial<Parameters<typeof apiClient.v3.instagramLinkageHandler>[0]>;
  [QUERY_KEY.instagramAccountLinkageHandler]: Partial<Parameters<typeof apiClient.v3.instagramAccountLinkageHandler>[0]>;
  [QUERY_KEY.instagramAccountsHandler]: Partial<Parameters<typeof apiClient.v3.instagramAccountsHandler>[0]>;
  [QUERY_KEY.instagramDisconnectionHandler]: Partial<Parameters<typeof apiClient.v3.instagramDisconnectionHandler>[0]>;
  [QUERY_KEY.manualAllContentsHandler]: Partial<Parameters<typeof apiClient.v3.manualAllContentsHandler>[0]>;
  [QUERY_KEY.updatingFolderSortHandler]: Partial<Parameters<typeof apiClient.v3.updatingFolderSortHandler>[0]>;
  [QUERY_KEY.multipleTreatmentTagCreationHandler]: Partial<Parameters<typeof apiClient.v3.multipleTreatmentTagCreationHandler>[0]>;
  [QUERY_KEY.electronNotificationSoundsHandler]: Partial<Parameters<typeof apiClient.v3.electronNotificationSoundsHandler>[0]>;
  [QUERY_KEY.metadataGetHandler]: Partial<Parameters<typeof apiClient.v3.metadataGetHandler>[0]>;
  [QUERY_KEY.linkedChatroomElFindHandler]: Partial<Parameters<typeof apiClient.v3.linkedChatroomElFindHandler>[0]>;
  [QUERY_KEY.linkedChatroomElUnlinkHandler]: Partial<Parameters<typeof apiClient.v3.linkedChatroomElUnlinkHandler>[0]>;
  [QUERY_KEY.linkedChatroomElUnlinkByChatroomHandler]: Partial<Parameters<typeof apiClient.v3.linkedChatroomElUnlinkByChatroomHandler>[0]>;
  [QUERY_KEY.fcmTokenHmUpdateDeviceInfoHandler]: Partial<Parameters<typeof apiClient.v3.fcmTokenHmUpdateDeviceInfoHandler>[0]>;
  [QUERY_KEY.adminHospitalCountHandler]: Partial<Parameters<typeof apiClient.v3.adminHospitalCountHandler>[0]>;
  [QUERY_KEY.adminHospitalFindHandler]: Partial<Parameters<typeof apiClient.v3.adminHospitalFindHandler>[0]>;
  [QUERY_KEY.adminHospitalFindOneHandler]: Partial<Parameters<typeof apiClient.v3.adminHospitalFindOneHandler>[0]>;
  [QUERY_KEY.adminHospitalUpdateHandler]: Partial<Parameters<typeof apiClient.v3.adminHospitalUpdateHandler>[0]>;
  [QUERY_KEY.adminHospitalCreateHandler]: Partial<Parameters<typeof apiClient.v3.adminHospitalCreateHandler>[0]>;
  [QUERY_KEY.adminHospitalUpdateNameHandler]: Partial<Parameters<typeof apiClient.v3.adminHospitalUpdateNameHandler>[0]>;
  [QUERY_KEY.adminHospitalUpdateSmsPhoneHandler]: Partial<Parameters<typeof apiClient.v3.adminHospitalUpdateSmsPhoneHandler>[0]>;
  [QUERY_KEY.adminHospitalTestHServiceSmsFlowHandler]: Partial<Parameters<typeof apiClient.v3.adminHospitalTestHServiceSmsFlowHandler>[0]>;
  [QUERY_KEY.adminServiceSettingCountHandler]: Partial<Parameters<typeof apiClient.v3.adminServiceSettingCountHandler>[0]>;
  [QUERY_KEY.adminServiceSettingFindHandler]: Partial<Parameters<typeof apiClient.v3.adminServiceSettingFindHandler>[0]>;
  [QUERY_KEY.adminServiceSettingFindOneHandler]: Partial<Parameters<typeof apiClient.v3.adminServiceSettingFindOneHandler>[0]>;
  [QUERY_KEY.adminServiceSettingCreateHandler]: Partial<Parameters<typeof apiClient.v3.adminServiceSettingCreateHandler>[0]>;
  [QUERY_KEY.adminServiceSettingUpdateHandler]: Partial<Parameters<typeof apiClient.v3.adminServiceSettingUpdateHandler>[0]>;
  [QUERY_KEY.adminServiceSettingRemoveHandler]: Partial<Parameters<typeof apiClient.v3.adminServiceSettingRemoveHandler>[0]>;
  [QUERY_KEY.adminServiceSettingAccountUpdateHandler]: Partial<Parameters<typeof apiClient.v3.adminServiceSettingAccountUpdateHandler>[0]>;
  [QUERY_KEY.adminServiceSettingDefaultCounselorUpdateHandler]: Partial<Parameters<typeof apiClient.v3.adminServiceSettingDefaultCounselorUpdateHandler>[0]>;
  [QUERY_KEY.adminHealthContentCategoryFindHandler]: Partial<Parameters<typeof apiClient.v3.adminHealthContentCategoryFindHandler>[0]>;
  [QUERY_KEY.adminUserNurseFindHandler]: Partial<Parameters<typeof apiClient.v3.adminUserNurseFindHandler>[0]>;
  [QUERY_KEY.adminUserCountHandler]: Partial<Parameters<typeof apiClient.v3.adminUserCountHandler>[0]>;
  [QUERY_KEY.adminUserFindHandler]: Partial<Parameters<typeof apiClient.v3.adminUserFindHandler>[0]>;
  [QUERY_KEY.adminUserCreateHandler]: Partial<Parameters<typeof apiClient.v3.adminUserCreateHandler>[0]>;
  [QUERY_KEY.adminUserUpdateHandler]: Partial<Parameters<typeof apiClient.v3.adminUserUpdateHandler>[0]>;
  [QUERY_KEY.adminUserRemoveHandler]: Partial<Parameters<typeof apiClient.v3.adminUserRemoveHandler>[0]>;
  [QUERY_KEY.adminUserFindOneHandler]: Partial<Parameters<typeof apiClient.v3.adminUserFindOneHandler>[0]>;
  [QUERY_KEY.adminAnsweringChatSettingCreateHandler]: Partial<Parameters<typeof apiClient.v3.adminAnsweringChatSettingCreateHandler>[0]>;
  [QUERY_KEY.adminAnsweringChatSettingUpdateHandler]: Partial<Parameters<typeof apiClient.v3.adminAnsweringChatSettingUpdateHandler>[0]>;
  [QUERY_KEY.adminAnsweringChatSettingFindOneHandler]: Partial<Parameters<typeof apiClient.v3.adminAnsweringChatSettingFindOneHandler>[0]>;
  [QUERY_KEY.adminVideoContentCategoryFindHandler]: Partial<Parameters<typeof apiClient.v3.adminVideoContentCategoryFindHandler>[0]>;
  [QUERY_KEY.adminVideoContentCategoryFindOneHandler]: Partial<Parameters<typeof apiClient.v3.adminVideoContentCategoryFindOneHandler>[0]>;
  [QUERY_KEY.adminIntegrationSettingFindOneHandler]: Partial<Parameters<typeof apiClient.v3.adminIntegrationSettingFindOneHandler>[0]>;
  [QUERY_KEY.adminIntegrationSettingUpsertHandler]: Partial<Parameters<typeof apiClient.v3.adminIntegrationSettingUpsertHandler>[0]>;
  [QUERY_KEY.adminHospitalAccountCountHandler]: Partial<Parameters<typeof apiClient.v3.adminHospitalAccountCountHandler>[0]>;
  [QUERY_KEY.adminHospitalDoctorFindHandler]: Partial<Parameters<typeof apiClient.v3.adminHospitalDoctorFindHandler>[0]>;
  [QUERY_KEY.adminHospitalAccountCreateHandler]: Partial<Parameters<typeof apiClient.v3.adminHospitalAccountCreateHandler>[0]>;
  [QUERY_KEY.adminHospitalAccountUpdateHandler]: Partial<Parameters<typeof apiClient.v3.adminHospitalAccountUpdateHandler>[0]>;
  [QUERY_KEY.adminHospitalAccountRemoveHandler]: Partial<Parameters<typeof apiClient.v3.adminHospitalAccountRemoveHandler>[0]>;
  [QUERY_KEY.adminHospitalAccountSettingHistoryCountHandler]: Partial<Parameters<typeof apiClient.v3.adminHospitalAccountSettingHistoryCountHandler>[0]>;
  [QUERY_KEY.adminHospitalAccountSettingHistoryFindHandler]: Partial<Parameters<typeof apiClient.v3.adminHospitalAccountSettingHistoryFindHandler>[0]>;
  [QUERY_KEY.adminTreatmentCategoryCountHandler]: Partial<Parameters<typeof apiClient.v3.adminTreatmentCategoryCountHandler>[0]>;
  [QUERY_KEY.adminTreatmentCategoryCreateHandler]: Partial<Parameters<typeof apiClient.v3.adminTreatmentCategoryCreateHandler>[0]>;
  [QUERY_KEY.adminTreatmentCategoryFindHandler]: Partial<Parameters<typeof apiClient.v3.adminTreatmentCategoryFindHandler>[0]>;
  [QUERY_KEY.adminTreatmentCategoryFindOneHandler]: Partial<Parameters<typeof apiClient.v3.adminTreatmentCategoryFindOneHandler>[0]>;
  [QUERY_KEY.adminTreatmentCategoryRemoveHandler]: Partial<Parameters<typeof apiClient.v3.adminTreatmentCategoryRemoveHandler>[0]>;
  [QUERY_KEY.adminTreatmentCategoryUpdateHandler]: Partial<Parameters<typeof apiClient.v3.adminTreatmentCategoryUpdateHandler>[0]>;
  [QUERY_KEY.adminTreatmentCategoryCopyHandler]: Partial<Parameters<typeof apiClient.v3.adminTreatmentCategoryCopyHandler>[0]>;
  [QUERY_KEY.adminFileCreateHandler]: Partial<Parameters<typeof apiClient.v3.adminFileCreateHandler>[0]>;
  [QUERY_KEY.adminFileUpdateHandler]: Partial<Parameters<typeof apiClient.v3.adminFileUpdateHandler>[0]>;
  [QUERY_KEY.adminFileFindOneHandler]: Partial<Parameters<typeof apiClient.v3.adminFileFindOneHandler>[0]>;
  [QUERY_KEY.adminCountryFindHandler]: Partial<Parameters<typeof apiClient.v3.adminCountryFindHandler>[0]>;
  [QUERY_KEY.adminCountryUpdateHandler]: Partial<Parameters<typeof apiClient.v3.adminCountryUpdateHandler>[0]>;
  [QUERY_KEY.kakaoLinkageHistoryCountHandler]: Partial<Parameters<typeof apiClient.v3.kakaoLinkageHistoryCountHandler>[0]>;
  [QUERY_KEY.kakaoLinkageHistoryFindHandler]: Partial<Parameters<typeof apiClient.v3.kakaoLinkageHistoryFindHandler>[0]>;
  [QUERY_KEY.kakaoLinkageHistoryDetailHandler]: Partial<Parameters<typeof apiClient.v3.kakaoLinkageHistoryDetailHandler>[0]>;
  [QUERY_KEY.kakaoLinkageHistoryUpdateHandler]: Partial<Parameters<typeof apiClient.v3.kakaoLinkageHistoryUpdateHandler>[0]>;
  [QUERY_KEY.kakaoLinkageHistoryClearHandler]: Partial<Parameters<typeof apiClient.v3.kakaoLinkageHistoryClearHandler>[0]>;
  [QUERY_KEY.kakaoTemplateFindGroupHandler]: Partial<Parameters<typeof apiClient.v3.kakaoTemplateFindGroupHandler>[0]>;
  [QUERY_KEY.kakaoTemplateFindAllProfileHandler]: Partial<Parameters<typeof apiClient.v3.kakaoTemplateFindAllProfileHandler>[0]>;
  [QUERY_KEY.kakaoTemplateFindProfileHandler]: Partial<Parameters<typeof apiClient.v3.kakaoTemplateFindProfileHandler>[0]>;
  [QUERY_KEY.kakaoTemplateFindCategoryHandler]: Partial<Parameters<typeof apiClient.v3.kakaoTemplateFindCategoryHandler>[0]>;
  [QUERY_KEY.kakaoTemplateFindHandler]: Partial<Parameters<typeof apiClient.v3.kakaoTemplateFindHandler>[0]>;
  [QUERY_KEY.kakaoTemplateCreateHandler]: Partial<Parameters<typeof apiClient.v3.kakaoTemplateCreateHandler>[0]>;
  [QUERY_KEY.kakaoTemplateUpdateHandler]: Partial<Parameters<typeof apiClient.v3.kakaoTemplateUpdateHandler>[0]>;
  [QUERY_KEY.kakaoTemplateDeleteHandler]: Partial<Parameters<typeof apiClient.v3.kakaoTemplateDeleteHandler>[0]>;
  [QUERY_KEY.kakaoTemplateDetailHandler]: Partial<Parameters<typeof apiClient.v3.kakaoTemplateDetailHandler>[0]>;
  [QUERY_KEY.kakaoTemplateRequestHandler]: Partial<Parameters<typeof apiClient.v3.kakaoTemplateRequestHandler>[0]>;
  [QUERY_KEY.kakaoTemplateRequestCancelHandler]: Partial<Parameters<typeof apiClient.v3.kakaoTemplateRequestCancelHandler>[0]>;
  [QUERY_KEY.kakaoTemplateStopHandler]: Partial<Parameters<typeof apiClient.v3.kakaoTemplateStopHandler>[0]>;
  [QUERY_KEY.kakaoTemplateReuseHandler]: Partial<Parameters<typeof apiClient.v3.kakaoTemplateReuseHandler>[0]>;
  [QUERY_KEY.kakaoTemplateCancelApprovalHandler]: Partial<Parameters<typeof apiClient.v3.kakaoTemplateCancelApprovalHandler>[0]>;
  [QUERY_KEY.kakaoTemplateReleaseHandler]: Partial<Parameters<typeof apiClient.v3.kakaoTemplateReleaseHandler>[0]>;
  [QUERY_KEY.adminAutomationCountHandler]: Partial<Parameters<typeof apiClient.v3.adminAutomationCountHandler>[0]>;
  [QUERY_KEY.adminAutomationFindHandler]: Partial<Parameters<typeof apiClient.v3.adminAutomationFindHandler>[0]>;
  [QUERY_KEY.adminAutomationCreateHandler]: Partial<Parameters<typeof apiClient.v3.adminAutomationCreateHandler>[0]>;
  [QUERY_KEY.adminAutomationUpdateHandler]: Partial<Parameters<typeof apiClient.v3.adminAutomationUpdateHandler>[0]>;
  [QUERY_KEY.adminAutomationDeleteHandler]: Partial<Parameters<typeof apiClient.v3.adminAutomationDeleteHandler>[0]>;
  [QUERY_KEY.adminAuomtaionHospitalFindHandler]: Partial<Parameters<typeof apiClient.v3.adminAuomtaionHospitalFindHandler>[0]>;
  [QUERY_KEY.adminAutomationHospitalCreateHandler]: Partial<Parameters<typeof apiClient.v3.adminAutomationHospitalCreateHandler>[0]>;
  [QUERY_KEY.adminAutomationHospitalUpdateHandler]: Partial<Parameters<typeof apiClient.v3.adminAutomationHospitalUpdateHandler>[0]>;
  [QUERY_KEY.adminAutomationHospitalDeleteHandler]: Partial<Parameters<typeof apiClient.v3.adminAutomationHospitalDeleteHandler>[0]>;
  [QUERY_KEY.adminAuomtaionHospitalFindVideoContentHandler]: Partial<Parameters<typeof apiClient.v3.adminAuomtaionHospitalFindVideoContentHandler>[0]>;
  [QUERY_KEY.adminAuomtaionHospitalFindHomecareHandler]: Partial<Parameters<typeof apiClient.v3.adminAuomtaionHospitalFindHomecareHandler>[0]>;
  [QUERY_KEY.adminAuomtaionHospitalFindEventHandler]: Partial<Parameters<typeof apiClient.v3.adminAuomtaionHospitalFindEventHandler>[0]>;
  [QUERY_KEY.kakaoServiceNotificationMessageFindOneHandler]: Partial<Parameters<typeof apiClient.v3.kakaoServiceNotificationMessageFindOneHandler>[0]>;
  [QUERY_KEY.kakaoServiceNotificationMessageUpdateHandler]: Partial<Parameters<typeof apiClient.v3.kakaoServiceNotificationMessageUpdateHandler>[0]>;
  [QUERY_KEY.adminAuthorizationTypeCreateHandler]: Partial<Parameters<typeof apiClient.v3.adminAuthorizationTypeCreateHandler>[0]>;
  [QUERY_KEY.adminAuthorizationTypeUpdateHandler]: Partial<Parameters<typeof apiClient.v3.adminAuthorizationTypeUpdateHandler>[0]>;
  [QUERY_KEY.adminAuthorizationTypeFindOneHandler]: Partial<Parameters<typeof apiClient.v3.adminAuthorizationTypeFindOneHandler>[0]>;
  [QUERY_KEY.publicbizPpurioBlockHandler]: Partial<Parameters<typeof apiClient.public.bizPpurioBlockHandler>[0]>;
};
