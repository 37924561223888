import { NavBar, Statusbar } from 'afterdoc-design-system/assets/images';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import EditorViewer from 'afterdoc-saas-web/shared/components/EditorViewer/EditorViewer';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { useMemo } from 'react';

const DetailInfo = ({
  type,
  sanitizedContent,
  src,
}: {
  type: string;
  sanitizedContent: string;
  src?: string;
}) => {
  if (type === 'text') {
    if (!sanitizedContent) {
      return <span className='mt-10 px-16 text-Body14 text-black800'>내용을 입력해 주세요.</span>;
    }
    return (
      <>
        <EditorViewer htmlContent={sanitizedContent} />
      </>
    );
  }
  if (type === 'image' && src) {
    return (
      <img
        src={src}
        alt='detailInfoImage'
        className='h-auto max-w-full overflow-hidden object-contain'
      />
    );
  }
  return <ImageEmpty />;
};
const formatDate = (date: string | undefined) => {
  const parsedDate = dayjs(date);

  if (!parsedDate.isValid()) {
    return 'YYYY-MM-DD';
  }

  return `~${parsedDate.format('YYYY-MM-DD')}`;
};
const ImageEmpty = () => {
  return (
    <div className='aspect-[1/1] w-full flex-center'>
      <NoData
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white600',
        }}
        className='my-auto whitespace-pre-wrap'
        title='이미지를 등록해주세요.'
      />
    </div>
  );
};

const ThumbnailImage = ({ src }: { src?: string }) => {
  if (src) {
    return <img src={src} alt='thumbnailImage' className='w-full overflow-hidden object-cover' />;
  }
  return <ImageEmpty />;
};

type EventPopupViewProps = {
  title?: string;
  description?: string;
  isLimitedTime?: boolean;
  endDate?: string;
  thumbnailSrc?: string;
  content?: string;
  type?: string;
  detailInfoImageSrc?: string;
  saleType?: string;
  salePrice?: number;
  saleRate?: number;
  price?: number;
};

export default function EventDevicePopup({
  title,
  description,
  isLimitedTime,
  endDate,
  thumbnailSrc,
  content,
  type,
  detailInfoImageSrc,
  saleType,
  salePrice,
  saleRate,
  price,
}: EventPopupViewProps) {
  const sanitizedContent = DOMPurify.sanitize(content ?? '');

  const formatPriceDisplay = useMemo(() => {
    const isPriceValid = saleType === 'price' && salePrice !== 0;
    const isRateValid = saleType === 'rate' && saleRate !== 0;

    return price && (isPriceValid || isRateValid) ? price.toLocaleString() : '';
  }, [saleType, salePrice, saleRate, price]);

  const calculatePrice = useMemo(() => {
    if (saleType === 'price') {
      const result = (price ?? 0) - (salePrice ?? 0);
      return (result > 0 ? result : 0).toLocaleString();
    }

    if (saleType === 'rate') {
      const discount = ((price ?? 0) * (saleRate ?? 0)) / 100;
      return ((price ?? 0) - discount).toLocaleString();
    }

    return '0';
  }, [saleType, salePrice, saleRate, price]);

  const calculateRate = useMemo(() => {
    if (saleType === 'rate') return saleRate ? `${saleRate}%` : '';
    if (!salePrice) return '';

    const rate = price && Math.round((salePrice / price) * 100);
    if (!Number.isFinite(rate)) return '';
    return `${rate}%`;
  }, [saleType, salePrice, saleRate, price]);

  return (
    <div className='flex-grow'>
      <div className='h-[800px] w-[370px] flex-col-center overflow-hidden rounded-[24px] border-[5px] border-blueLight bg-white50 shadow-modal'>
        <div className='mt-13 h-96 w-full flex-row items-center justify-between '>
          <Statusbar className='w-full bg-white50' />
          <div className='flex flex-center justify-between'>
            <Icon name='chevron-left' size={40} color='black800' />
            <p className='mr-16 font-regular text-[16px] text-black leading-[18px]'>공유</p>
          </div>
        </div>
        <Scrollbar>
          <div className='mt-8 w-full flex-grow'>
            <div className='flex flex-col'>
              <div className='mb-24 px-12'>
                <p className='mb-[8px] break-words font-bold text-[21px] text-black800'>{title}</p>
                <p className='break-words text-[12px] text-black200'>{description}</p>
                <div className='mt-18 flex min-w-0 gap-4'>
                  <div className='flex min-w-0 flex-grow flex-col'>
                    <p className='overflow-hidden text-ellipsis font-regular text-[#CCCCCC] text-[14px] line-through'>
                      {formatPriceDisplay}
                    </p>
                    <div className='flex items-center gap-10'>
                      <p className='overflow-hidden text-ellipsis whitespace-nowrap text-Header16 text-black800'>
                        {`${calculatePrice}원`}
                      </p>
                      <p className='text-Header16 text-[#FF5C71]'>{calculateRate}</p>
                    </div>
                  </div>
                  <p className='mb-6 w-[76px] self-end whitespace-nowrap font-regular text-[#929292] text-[12px]'>
                    {isLimitedTime && formatDate(endDate)}
                  </p>
                </div>
              </div>
            </div>
            <Divider type='line' height={10} />
            <ThumbnailImage src={thumbnailSrc} />
            <Divider type='line' height={1} />
            <div className='pt-10 pb-8'>
              <Divider type='line' height={1} />
              <DetailInfo
                type={type ?? ''}
                sanitizedContent={sanitizedContent}
                src={detailInfoImageSrc}
              />
            </div>
          </div>
        </Scrollbar>
        <NavBar className='h-43 w-full' />
      </div>
    </div>
  );
}
