import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { contextMenuService } from 'afterdoc-design-system/components/Molecules/ContextMenu/ContextMenu.service';
import { PositionType } from 'afterdoc-design-system/components/Molecules/ContextMenu/ContextMenu.type';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  ApiManualsElFoldersData,
  ApiManualsElFoldersParams,
  SortedFoldersWithTags,
  TreatmentTagForFolder,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import type { Layer, SubLayer, } from 'afterdoc-saas-web/shared/components/SequencePanelList/SequencePanelList';
import SequencePanelList from 'afterdoc-saas-web/shared/components/SequencePanelList/SequencePanelList';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import FolderRightToggle
  from 'afterdoc-saas-web/templates/HospitalManual/components/HospitalManualPanel/components/ManualFolderAndTreatmentTagsLists/components/FolderRightToggle';
import TreatmentTagRightToggle
  from 'afterdoc-saas-web/templates/HospitalManual/components/HospitalManualPanel/components/ManualFolderAndTreatmentTagsLists/components/TreatmentTagRightToggle';
import {
  mapApiToLayers,
  mapApiToOrphanSubLayers,
} from 'afterdoc-saas-web/templates/HospitalManual/components/HospitalManualPanel/components/ManualFolderAndTreatmentTagsLists/functions/map-layers';
import {
  postFolderDelete,
  useMutateFolders,
} from 'afterdoc-saas-web/templates/HospitalManual/components/HospitalManualPanel/components/ManualFolderAndTreatmentTagsLists/hooks/use-mutate-folders';
import {
  useMutateTreatmentTags
} from 'afterdoc-saas-web/templates/HospitalManual/components/HospitalManualPanel/components/ManualFolderAndTreatmentTagsLists/hooks/use-mutate-treatment-tags';
import {
  isAddingNewFolderState,
  newFolderNameState,
} from 'afterdoc-saas-web/templates/HospitalManual/components/HospitalManualPanel/states/is-adding-new-folder';
import {
  manualTagSearchTextState
} from 'afterdoc-saas-web/templates/HospitalManual/components/HospitalManualPanel/states/search';
import {
  selectedTagIdState
} from 'afterdoc-saas-web/templates/HospitalManual/components/HospitalManualPanel/states/selected-tag-id';
import { useAtom, useAtomValue } from 'jotai';
import { isEqual } from 'lodash-es';
import {
  type ChangeEvent,
  type KeyboardEvent,
  type MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

const fetchManualElFolders = async (params: ApiManualsElFoldersParams) => {
  const response = await apiClient.v3.apiManualsElFolders(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function ManualFolderAndTreatmentTagsLists() {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();

  const isFirstRender = useRef(false);

  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const manualSearchText = useAtomValue(manualTagSearchTextState);

  const { data } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiManualsElFolders,
      { hospitalID, folderOrTagName: manualSearchText },
    ] as const,
    queryFn: ({ queryKey }) => fetchManualElFolders(queryKey[1]),
    refetchOnMount: 'always',
  });

  const { authorizationTypeID } = useUserInfo();

  const canUserControlManualFolderAndTagStatus =
    authorizationTypeID?.canControlManualFolderAndTagStatus;

  const [selectedTagId, setSelectedTagId] = useAtom(selectedTagIdState);
  const [newFolderName, setNewFolderName] = useAtom(newFolderNameState);
  const [folderActivationMap, setFolderActivationMap] = useState<{ [folderId: string]: boolean }>(
    {},
  );
  const [tagActivationMap, setTagActivationMap] = useState<{ [tagId: string]: boolean }>({});
  const [isAddingNewFolder, setIsAddingNewFolder] = useAtom(isAddingNewFolderState);

  const [isEditingFolderName, setIsEditingFolderName] = useState(false);
  const [editingFolderId, setEditingFolderId] = useState<string | null>(null);
  const [editedFolderNameInput, setEditingFolderName] = useState<string>('');

  const [layers, setLayers] = useState<Layer[]>([]);
  const [orphanSubLayers, setOrphanSubLayers] = useState<SubLayer[]>([]);

  const { updateTreatmentTagsMutation } = useMutateTreatmentTags();
  const { createNewFolderMutation, updateFoldersMutation, updateFoldersSortNumMutation } =
    useMutateFolders();

  const handleFolderNameSubmit = (folderId: string) => {
    updateFoldersMutation.mutate(
      {
        folderId,
        sortNum: layers.find((layer) => layer.id === folderId)?.sortNum || 0,
        hospitalID,
        name: editedFolderNameInput,
        isActive: data.foldersHasTags?.find((folder) => folder._id === folderId)?.isActive ?? true,
      },
      {
        onSuccess: (response) => {
          if (response.code === 40001) {
            setEditingFolderName(
              data.foldersHasTags?.find((folder) => folder._id === folderId)?.name ?? '',
            );
            toastService.errorMsg({
              text: response.message,
            });
            return;
          }

          if (response.code === 0 && response.data.isUpdatedSuccess) {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.apiManualsElFolders, { hospitalID }],
            });

            toastService.successMsg({
              text: '폴더명이 변경되었습니다.',
            });
            return;
          }
        },
        onError: () => {
          toastService.errorMsg({
            text: '폴더명 변경을 실패했습니다.',
          });
        },
      },
    );

    setIsEditingFolderName(false);
    setEditingFolderId(null);
  };

  const handleAddNewFolderName = (event: ChangeEvent<HTMLInputElement>) => {
    setNewFolderName(event.target.value);
  };

  const handleEditFolderName = (event: ChangeEvent<HTMLInputElement>) => {
    setEditingFolderName(event.target.value);
  };

  const handleAddNewFolder = () => {
    if (!isAddingNewFolder || isCreatingFolder) return;

    setIsCreatingFolder(true);

    createNewFolderMutation.mutate(
      {
        hospitalID,
        name: newFolderName,
      },
      {
        onSuccess: (response) => {
          if (response.code === 0) {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.apiManualsElFolders, { hospitalID }],
            });
            toastService.successMsg({
              text: '폴더가 추가되었습니다.',
            });

            setIsCreatingFolder(false);
            setIsAddingNewFolder(false);
            setNewFolderName('');
          } else if (response.code === 40001) {
            toastService.errorMsg({
              text: response.message,
            });
          }
        },
        onError: () => {
          toastService.errorMsg({
            text: '폴더 추가를 실패했습니다.',
          });
          setIsCreatingFolder(false);
          setIsAddingNewFolder(false);
          setLayers((prev) => prev.filter((layer) => layer.id !== 'new-folder'));
        },
        onSettled: () => {
          setIsCreatingFolder(false);
        },
      },
    );
  };

  const handleAddNewFolderBlur = () => {
    if (!isCreatingFolder) handleAddNewFolder();
  };

  const handleEditFolderNameBlur = () => {
    setIsEditingFolderName(false);
    setEditingFolderId(null);
  };

  const handleAddNewFolderKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Enter' || e.key === 'Escape') && !isCreatingFolder) {
      handleAddNewFolder();
    }
  };

  const handleEditFolderLayerKeyDown = (event: KeyboardEvent, folderId: string) => {
    if (event.key === 'Enter') {
      handleFolderNameSubmit(folderId);
    }

    if (event.key === 'Escape') {
      setIsEditingFolderName(false);
      setEditingFolderId(null);
    }
  };

  const handleLayerReorder = (layers: Layer[], changedLayer?: Layer) => {
    if (!changedLayer) return;

    setLayers(layers);

    // 변경된 레이어의 현재 인덱스 찾기
    const currentIndex = layers.findIndex((layer) => layer.id === changedLayer.id);
    if (currentIndex === -1) return;

    // 이전/다음 레이어의 sortNum 가져오기
    const prevLayer = currentIndex > 0 ? layers[currentIndex - 1] : null;
    const nextLayer = currentIndex < layers.length - 1 ? layers[currentIndex + 1] : null;

    const payload: {
      folderID: string;
      hospitalID: string;
      targetSortNum?: number;
      beforeSortNum?: number;
      afterSortNum?: number;
    } = {
      folderID: changedLayer.id,
      hospitalID,
    };

    // 맨 앞으로 이동한 경우
    if (currentIndex === 0) {
      const nextSortNum = nextLayer?.sortNum ?? 0;
      payload.targetSortNum = nextSortNum + 1;
    }
    // 맨 뒤로 이동한 경우
    else if (currentIndex === layers.length - 1) {
      const prevSortNum = prevLayer?.sortNum ?? 0;
      payload.targetSortNum = Math.max(0, prevSortNum - 1);
    }
    // 중간으로 이동한 경우
    else {
      // prevLayer와 nextLayer 사이에 위치하도록 beforeSortNum 사용
      payload.beforeSortNum = prevLayer?.sortNum ?? 0;
    }

    updateFoldersSortNumMutation.mutate(payload);
  };

  const handleSubLayerReorder = (
    subLayers: SubLayer[],
    destinationLayerIndex: number,
    destinationDroppableId: string,
    changedSubLayer?: SubLayer,
  ) => {
    if (!changedSubLayer) return;

    setOrphanSubLayers(subLayers);

    const destinationFolderID =
      destinationDroppableId === 'orphan-subLayers' ? undefined : layers[destinationLayerIndex]?.id;

    const currentFolder = layers.find((layer) =>
      layer.items.some((item) => item.id === changedSubLayer.id),
    );

    const newSubLayers = destinationFolderID ? layers[destinationLayerIndex].items : subLayers;

    const currentIndex = newSubLayers.findIndex((subLayer) => subLayer.id === changedSubLayer.id);
    if (currentIndex === -1) return;

    const calculateNewSortNum = () => {
      const prevItem = currentIndex > 0 ? newSubLayers[currentIndex - 1] : null;

      return prevItem?.sortNum ?? 0;
    };

    const getTagActiveStatus = (tagId: string): boolean => {
      const unassignedTag = data.unassignedTags?.find((tag) => tag._id === tagId);
      if (unassignedTag) return unassignedTag.isActive;

      const assignedTag = data.foldersHasTags
        ?.flatMap((folder) => folder.treatmentTags)
        .find((tag) => tag?._id === tagId);

      return assignedTag?.isActive ?? true;
    };

    const newSortNum = calculateNewSortNum();
    const isFolderChanged = currentFolder?.id !== destinationFolderID;
    const isActive = getTagActiveStatus(changedSubLayer.id);
    const toBeSortedHighest = currentIndex === 0;

    const payload: Parameters<typeof apiClient.v3.apiTreatmentTagsElUpdate>[0] = {
      tcID: changedSubLayer.id,
      hospitalID,
      folderID: destinationFolderID || (isFolderChanged ? undefined : currentFolder?.id),
      ...(toBeSortedHighest
        ? { toBeSortedHighest: true }
        : { beforeTreatmentTagSortNum: newSortNum }),
      isActive,
    };

    updateTreatmentTagsMutation.mutate(payload, {
      onSuccess: () => {
        queryClient.setQueryData(
          [QUERY_KEY.apiManualsElFolders, { hospitalID }],
          (oldData: ApiManualsElFoldersData['data']) => {
            if (!oldData) return oldData;

            const updatedFolders = oldData.foldersHasTags?.map((folder) => {
              if (folder._id === destinationFolderID) {
                const updatedTags = [...(folder.treatmentTags || [])];
                const sortedTags = updatedTags.sort((a, b) => b.sortNum - a.sortNum);
                return { ...folder, treatmentTags: sortedTags };
              }
              return folder;
            });

            return {
              ...oldData,
              foldersHasTags: updatedFolders,
            };
          },
        );
      },
    });
  };

  const mutationPostFolderDelete = useMutation({
    mutationFn: postFolderDelete,
    onSuccess: (data) => {
      if (data.isDeletedSuccess) {
        toastService.successMsg({
          text: '폴더가 삭제되었습니다.',
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiManualsElFolders, { hospitalID }],
        });
      } else {
        toastService.errorMsg({
          text: '폴더 삭제를 실패했습니다.',
        });
      }
    },
    onError: (error) => {
      console.warn('error', error);
      toastService.errorMsg({
        text: '폴더 삭제를 실패했습니다.',
      });
    },
    onSettled: () => {
      modalService.popById('delete-folder-modal');
      contextMenuService.popById('delete-or-edit-folder-context-menu');
    },
  });

  const onDeleteFolder = (folderId: string) => {
    mutationPostFolderDelete.mutate({
      folderId,
      hospitalID,
    });
  };

  const onOpenDeleteFolderModal = (folderId: string) => {
    modalService.defaultWarning({
      id: 'delete-folder-modal',
      title: '폴더를 삭제할까요?',
      contents: (
        <div className='whitespace-pre-wrap'>
          {'폴더에 포함된 치료태그는 삭제되지 않으며, 매뉴얼 목록 맨 하단에 표시됩니다.'}
        </div>
      ),
      onConfirm: () => onDeleteFolder(folderId),
    });
  };

  const handleLayerContextMenu = (e: MouseEvent, id?: string) => {
    if (!canUserControlManualFolderAndTagStatus) return;

    contextMenuService.push(
      e,
      <div className='flex w-[150px] flex-col items-start rounded-r6 border border-white400 bg-white50 py-4 shadow-modal'>
        <>
          <button
            type='button'
            className='w-full px-10 py-7 text-left text-Body12 text-black500 hover:bg-blueLight'
            onClick={() => {
              if (!id) return;
              setIsEditingFolderName(true);
              setEditingFolderId(id);
              setEditingFolderName(
                layers.find((layer) => layer.id === id)?.title?.toString() ?? '',
              );
              contextMenuService.popById('delete-or-edit-folder-context-menu');
            }}>
            폴더명 변경
          </button>
          <button
            type='button'
            className='w-full px-10 py-7 text-left text-Header12 text-red500 hover:bg-blueLight'
            onClick={() => {
              if (!id) return;
              onOpenDeleteFolderModal(id);
            }}>
            폴더 삭제
          </button>
        </>
      </div>,
      {
        positionType: PositionType.MOUSE_EVENT,
        xOffset: 0,
        yOffset: 0,
        customId: 'delete-or-edit-folder-context-menu',
      },
    );
  };

  const onClickUpdateFolderActivation = (isActivated: boolean, folder: SortedFoldersWithTags) => {
    if (isActivated) {
      setFolderActivationMap((prevState) => ({
        ...prevState,
        [folder._id]: isActivated,
      }));

      updateFoldersMutation.mutate({
        folderId: folder._id,
        hospitalID,
        isActive: isActivated,
      });
    } else {
      modalService.defaultWarning({
        id: 'update-folder-activation-modal',
        title: '매뉴얼을 비활성화 할까요?',
        contents: '매뉴얼을 비활성화 할 경우, 고객채팅 > 원내매뉴얼에서 표시하지 않습니다.',
        onConfirm: () => {
          setFolderActivationMap((prevState) => ({
            ...prevState,
            [folder._id]: isActivated,
          }));

          updateFoldersMutation.mutate({
            folderId: folder._id,
            hospitalID,
            isActive: isActivated,
          });

          modalService.popById('update-folder-activation-modal');
        },
      });
    }
  };

  const onClickUpdateTreatmentTagActivation = (
    isActivated: boolean,
    tag: TreatmentTagForFolder & { folderID?: string },
  ) => {
    if (isActivated) {
      setTagActivationMap((prevState) => ({
        ...prevState,
        [tag._id]: isActivated,
      }));

      updateTreatmentTagsMutation.mutate({
        tcID: tag._id,
        hospitalID,
        folderID: tag.folderID,
        isActive: isActivated,
      });
    } else {
      modalService.defaultWarning({
        id: 'update-folder-activation-modal',
        title: '매뉴얼을 비활성화 할까요?',
        contents: '매뉴얼을 비활성화 할 경우, 고객채팅 > 원내매뉴얼에서 표시하지 않습니다.',
        onConfirm: () => {
          setTagActivationMap((prevState) => ({
            ...prevState,
            [tag._id]: isActivated,
          }));

          updateTreatmentTagsMutation.mutate({
            tcID: tag._id,
            hospitalID,
            folderID: tag.folderID,
            isActive: isActivated,
          });

          modalService.popById('update-folder-activation-modal');
        },
      });
    }
  };

  const handleItemClick = useCallback(
    (id: string) => {
      setSelectedTagId(id);
    },
    [setSelectedTagId],
  );

  useEffect(() => {
    if (data) {
      const initialFolderActivation = data.foldersHasTags?.reduce(
        (acc, folder) => {
          acc[folder._id] = folder.isActive;
          return acc;
        },
        {} as { [key: string]: boolean },
      );
      const initialTagActivation = {
        ...data.foldersHasTags?.reduce(
          (acc, folder) => {
            if (folder.treatmentTags) {
              for (const tag of folder.treatmentTags) {
                acc[tag._id] = tag.isActive;
              }
            }
            return acc;
          },
          {} as { [key: string]: boolean },
        ),
        ...data.unassignedTags?.reduce(
          (acc, tag) => {
            acc[tag._id] = tag.isActive;
            return acc;
          },
          {} as { [key: string]: boolean },
        ),
      };

      setFolderActivationMap(initialFolderActivation || {});
      setTagActivationMap(initialTagActivation || {});
    }
  }, [data]);

  useEffect(() => {
    if (isFirstRender.current || !data) {
      isFirstRender.current = false;
      return;
    }

    if (
      data.foldersHasTags &&
      data.foldersHasTags.length > 0 &&
      data.foldersHasTags?.flatMap((folder) => folder.treatmentTags)[0]?._id
    ) {
      return setSelectedTagId(
        data.foldersHasTags?.flatMap((folder) => folder.treatmentTags)[0]?._id,
      );
    }

    if (data.unassignedTags && data.unassignedTags.length > 0 && data.unassignedTags[0]._id) {
      return setSelectedTagId(data.unassignedTags[0]._id);
    }
  }, []);

  // 로컬스토리지 isFolderOpenedLists 데이터 사용중지에 따른 기존에 쌓인 데이터 삭제
  useEffect(() => {
    const deprecatedStorageKey = 'isFolderOpenedLists';
    if (localStorage.getItem(deprecatedStorageKey)) {
      localStorage.removeItem(deprecatedStorageKey);
    }
  }, []);

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiManualsElFolders, { hospitalID }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiTreatmentTagsEl, { hospitalID }],
      });
    };
  }, [hospitalID, queryClient]);

  useEffect(() => {
    if (!data || !folderActivationMap || !tagActivationMap) return;

    const layerArrComponent = mapApiToLayers(
      data,
      (folder) => {
        if (!canUserControlManualFolderAndTagStatus) return null;

        return (
          <FolderRightToggle
            folder={folder}
            onClickUpdateFolderActivation={onClickUpdateFolderActivation}
            isActive={folderActivationMap[folder._id]}
          />
        );
      },
      (tag, folder) => {
        if (!canUserControlManualFolderAndTagStatus) return null;

        return (
          <TreatmentTagRightToggle
            tag={{ ...tag, folderID: folder._id }}
            onClickUpdateTreatmentTagActivation={onClickUpdateTreatmentTagActivation}
            isActive={tagActivationMap[tag._id]}
            isFolderActive={folderActivationMap[folder._id]}
          />
        );
      },
    );
    const orphanSubLayerArrComponent = mapApiToOrphanSubLayers(data, (tag) => {
      if (!canUserControlManualFolderAndTagStatus) return null;

      return (
        <TreatmentTagRightToggle
          isActive={tagActivationMap[tag._id]}
          tag={tag}
          onClickUpdateTreatmentTagActivation={onClickUpdateTreatmentTagActivation}
          isFolderActive={true}
        />
      );
    });

    if (!isEqual(layers, layerArrComponent)) {
      setLayers(layerArrComponent);
    }
    if (!isEqual(orphanSubLayers, orphanSubLayerArrComponent)) {
      setOrphanSubLayers(orphanSubLayerArrComponent);
    }
  }, [data, folderActivationMap, tagActivationMap]);

  useEffect(() => {
    if (isAddingNewFolder) {
      setLayers((prev) => [
        {
          id: 'new-folder',
          title: newFolderName,
          icon: 'folder-outline',
          items: [],
          sortNum: layers[0]?.sortNum ? layers[0].sortNum + 1 : 0,
        },
        ...prev,
      ]);
    }
  }, [isAddingNewFolder]);

  if (
    !data.foldersHasTags?.length &&
    !data.unassignedTags?.length &&
    !!(manualSearchText && manualSearchText.length > 0)
  ) {
    return (
      <div className='h-[calc(100vh-179px)] flex-w-full-center'>
        <NoData
          title='검색 결과가 없습니다.'
          subTitle='단어의 철자가 정확한지 확인해 주세요.'
          iconProps={{
            name: 'warning',
            size: 48,
            color: 'white600',
          }}
        />
      </div>
    );
  }

  if (
    !data.foldersHasTags?.length &&
    !data.unassignedTags?.length &&
    !layers.length &&
    !orphanSubLayers.length
  ) {
    return (
      <div className='h-[calc(100vh-179px)] flex-w-full-center'>
        <NoData
          title='표시할 내용이 없습니다.'
          subTitle='병원설정 > 서비스 설정에서 치료태그를 추가해 주세요.'
          iconProps={{
            name: 'warning',
            size: 48,
            color: 'white600',
          }}
        />
      </div>
    );
  }

  return (
    <div className='h-[calc(100vh-179px)]'>
      <Scrollbar>
        <SequencePanelList
          layers={layers}
          orphanSubLayers={orphanSubLayers}
          onLayersReorder={handleLayerReorder}
          onSubLayersReorder={handleSubLayerReorder}
          layerProps={{
            onContextMenu: (e, id) => handleLayerContextMenu(e, id),
          }}
          onItemClick={handleItemClick}
          editingFolderId={editingFolderId}
          folderNameInput={
            isEditingFolderName
              ? editedFolderNameInput
              : isAddingNewFolder
                ? newFolderName
                : undefined
          }
          isSearching={!!(manualSearchText && manualSearchText.length > 0)}
          isDragDisabled={!canUserControlManualFolderAndTagStatus}
          isAddingNewFolder={isAddingNewFolder}
          isEditingFolderName={isEditingFolderName}
          onKeyDown={
            isEditingFolderName
              ? handleEditFolderLayerKeyDown
              : isAddingNewFolder
                ? handleAddNewFolderKeyDown
                : undefined
          }
          defaultFocusedItemId={selectedTagId}
          onFolderNameChangeBlur={
            isEditingFolderName ? handleEditFolderNameBlur : handleAddNewFolderBlur
          }
          onFolderNameChange={isEditingFolderName ? handleEditFolderName : handleAddNewFolderName}
        />
      </Scrollbar>
    </div>
  );
}
