import { getLocalStorageValue } from '@shared-utils/jotai/get-local-storage-value';
import { setLocalStorageValue } from '@shared-utils/jotai/set-local-storage-value';
import { useEffect, useState } from 'react';

export function useLocalStorageState<T>(key: string, defaultValue: T): [T, (value: T) => void] {
  const [state, setState] = useState<T>(getLocalStorageValue(key, defaultValue));

  useEffect(() => {
    setLocalStorageValue(key, state);
  }, [key, state]);

  return [state, setState];
}
