import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { CUSTOMER_CHAT_ERROR } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/constants/customer-chat-error-text';
import type {
  ChattingResponse,
  TextChattingResponse,
} from 'afterdoc-saas-web/templates/CustomerChat/types';

export const handleErrorInKakaoIsLoginUser = (res: ChattingResponse | TextChattingResponse) => {
  if (res.error?.code === CUSTOMER_CHAT_ERROR.KAKAO.EXPIRED.CODE) {
    toastService.errorMsg({ text: CUSTOMER_CHAT_ERROR.KAKAO.EXPIRED.TEXT });
  }
};
