import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import WeekPicker from 'afterdoc-design-system/components/Atoms/Calendar/WeekPicker';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { reservationSelectedDateState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/states/date';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';

export default function WeekSelector() {
  const setReservationSelectedDate = useSetAtom(reservationSelectedDateState);
  const [isWeekDropdownOpen, setIsWeekDropdownOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [weekRange, setWeekRange] = useState({ from: '', to: '' });

  const getWeekRange = (date: Date) => {
    const startOfWeek = dayjs(date).startOf('week');
    const endOfWeek = dayjs(date).endOf('week');
    return { from: startOfWeek.format('YYYY-MM-DD'), to: endOfWeek.format('YYYY-MM-DD') };
  };

  const handleWeekChange = (direction: number) => {
    const updatedDate = dayjs(selectedDate).add(direction, 'week').toDate();
    setSelectedDate(updatedDate);
  };

  useEffect(() => {
    const startDate = dayjs(selectedDate).startOf('week').startOf('day');

    const endDate = dayjs(selectedDate).endOf('week').endOf('day');

    const range = getWeekRange(selectedDate);
    setWeekRange(range);

    setReservationSelectedDate({
      startedAt: startDate.utc().toDate(),
      endedAt: endDate.utc().toDate(),
    });
  }, [selectedDate, setReservationSelectedDate]);

  return (
    <div className='flex items-center gap-10'>
      <IconButton
        icon='chevron-left'
        size={16}
        color='black500'
        className='h-24 w-24 rounded-r6 border border-white400 p-3'
        onClick={() => handleWeekChange(-1)}
      />
      <div className='relative'>
        <TextInput
          readOnly
          inputNotAllowed
          onClick={() => setIsWeekDropdownOpen((prev) => !prev)}
          width={220}
          value={`${weekRange.from} ~ ${weekRange.to}`}
          contentEditable={false}
        />
        {isWeekDropdownOpen && (
          <WeekPicker
            selectedDate={selectedDate}
            onChangeDate={(date) => {
              setSelectedDate(date);
              setIsWeekDropdownOpen(false);
            }}
            handleToggle={() => setIsWeekDropdownOpen(!isWeekDropdownOpen)}
            className='-left-70 absolute top-40 w-[400px]'
          />
        )}
      </div>
      <IconButton
        icon='chevron-right'
        size={16}
        color='black500'
        className='h-24 w-24 rounded-r6 border border-white400 p-3'
        onClick={() => handleWeekChange(1)}
      />
    </div>
  );
}
