import { overlayPageService } from 'afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { OVERLAY_PAGE_ID } from 'afterdoc-saas-web/shared/constants/overlay-page-id';
import { useNavigationBlocker } from 'afterdoc-saas-web/shared/hooks/gnb/use-navigation-blocker';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import RegisterMarketingAutomationDialogContent, {
  type MarketingAutomationAPIFormValues,
} from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import RegisterMarketingAutomationDialogHeader from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogHeader';
import {
  checkedLayersState,
  checkedOrphanSubLayersState,
  checkedSubLayersState,
} from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingBaseSetting/states/selected-layers';
import dayjs from 'dayjs';
import { useResetAtom } from 'jotai/utils';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export default function RegisterMarketingAutomationDialogContainer() {
  const { hospitalID } = useSelectedHospitalInfo();

  const memoizedDefaultValues: MarketingAutomationAPIFormValues = useMemo(() => {
    const now = dayjs();
    const today8PM = now.hour(20).minute(0).second(0);

    const startDay = now.isAfter(today8PM)
      ? now.add(1, 'day').hour(8).minute(0).second(0).millisecond(0)
      : now.hour(8).minute(0).second(0).millisecond(0);

    return {
      hospitalID,
      name: '',
      targetTreatmentTags: undefined,
      toBeAppliedNationalityIds: undefined,
      messages: [],
      type: 'MARKETING',
      sendingSchedule: {
        sendingCount: 1,
        startDay: startDay.toISOString(),
      },
    } as const;
  }, [hospitalID]);

  const methods = useForm<MarketingAutomationAPIFormValues>({
    mode: 'onChange',
    defaultValues: memoizedDefaultValues,
  });

  const {
    formState: { isDirty },
    reset,
  } = methods;

  const resetCheckedLayers = useResetAtom(checkedLayersState);
  const resetCheckedSubLayers = useResetAtom(checkedSubLayersState);
  const resetCheckedOrphanSubLayers = useResetAtom(checkedOrphanSubLayersState);

  const resetAll = () => {
    resetCheckedLayers();
    resetCheckedSubLayers();
    resetCheckedOrphanSubLayers();
    reset(memoizedDefaultValues);
  };

  const onCloseModal = () => {
    overlayPageService.popById(OVERLAY_PAGE_ID['register-marketing-automation-dialog']);
  };

  useNavigationBlocker({
    shouldBlock: isDirty,
    onConfirm: () => {
      resetAll();
      onCloseModal();
    },
  });

  return (
    <FormProvider {...methods}>
      <RegisterMarketingAutomationDialogHeader />
      <RegisterMarketingAutomationDialogContent />
    </FormProvider>
  );
}
