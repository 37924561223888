import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';

export default function LinkedChannelsLoading() {
  return (
    <div className='flex size-full flex-col gap-4'>
      <div className='h-[100px] flex-center'>
        <BaseLoading />
      </div>
    </div>
  );
}
