import type { KakaoLatestLinkageHistoryHandlerData } from '@apis/swaggers/swagger-docs';
import type { ServiceLinkageStatus } from '@templates/HospitalSetting/containers/ChannelIntegration/ChannelIntegration.container';
import KakaoAlimTalkForm from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoAlimTalkForm/KakaoAlimTalkForm';
import { hasLinkageOrRequestHistoryState } from '@templates/HospitalSetting/containers/ChannelIntegration/states/has-linkage-or-request-history-state';
import type { LinkageType } from '@templates/HospitalSetting/containers/ChannelIntegration/types/linkage-type';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import ChannelLinkageOverlay from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/ChannelLinkageOverlay/ChannelLinkageOverlay';
import InstagramForm from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/InstagramForm/InstagramForm';
import KakaoConsultTalkForm from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoConsultTalkForm/KakaoConsultTalkForm';
import {
  type InstagramAccountsResType,
  type LinkageHistoryResult,
  useLinkageHistory,
} from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/hooks/use-Linkage-history';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai/index';
import { useEffect, useMemo, useState } from 'react';

interface ChannelIntegrationInputFormProps {
  linkageType: LinkageType;
  linkageStatus: ServiceLinkageStatus;
}

export type KakaoLatestLinkageHistoryResType = KakaoLatestLinkageHistoryHandlerData['data'];

const renderFormPage = (data: LinkageHistoryResult, linkageType: LinkageType) => {
  switch (linkageType) {
    case 'ALIM_TALK': {
      return <KakaoAlimTalkForm data={data as KakaoLatestLinkageHistoryResType} />;
    }
    case 'CONSULTATION_TALK': {
      return <KakaoConsultTalkForm data={data as KakaoLatestLinkageHistoryResType} />;
    }
    case 'INSTAGRAM': {
      return <InstagramForm data={data as InstagramAccountsResType} />;
    }
    default:
      return null;
  }
};

export default function IntegrationFormWrapper({
  linkageType,
  linkageStatus,
}: ChannelIntegrationInputFormProps) {
  const { hospitalID } = useSelectedHospitalInfo();

  const setHasLinkageOrRequestHistory = useSetAtom(hasLinkageOrRequestHistoryState);

  const [isShowOverlay, setIsShowOverlay] = useState(false);
  const [RenderFormPage, setRenderFormPage] = useState<JSX.Element | null>(null);

  const { data } = useLinkageHistory({ hospitalID, linkageType });

  const hasLinkageOrRequestHistory = useMemo(() => {
    const { hasLinkage, unlinkedAt } = linkageStatus;

    if (hasLinkage) {
      return 'Linked';
    }

    if (data) {
      if (!unlinkedAt) {
        return 'LinkageRequested';
      }

      const createdDate = dayjs(
        'latestLinkageRequestDateTime' in data && data.latestLinkageRequestDateTime
          ? data.latestLinkageRequestDateTime
          : 'createdAt' in data && data.createdAt
            ? data.createdAt
            : undefined,
      );

      const unlinkedDate = dayjs(unlinkedAt);
      if (createdDate.isValid() && createdDate.isAfter(unlinkedDate)) {
        return 'LinkageRequested';
      }
    }

    return 'NotLinked';
  }, [data, linkageStatus]);

  useEffect(() => {
    setHasLinkageOrRequestHistory(hasLinkageOrRequestHistory);
    setIsShowOverlay(hasLinkageOrRequestHistory === 'NotLinked');
    setRenderFormPage(renderFormPage(data, linkageType));
  }, [hasLinkageOrRequestHistory, setHasLinkageOrRequestHistory, linkageType, data]);

  return (
    <>
      <div className='relative flex min-h-full flex-grow flex-col pl-20'>
        {RenderFormPage}
        {isShowOverlay && (
          <ChannelLinkageOverlay
            linkageType={linkageType}
            removeOverlay={() => setIsShowOverlay(false)}
          />
        )}
      </div>
    </>
  );
}
