import type { ApiServiceSettingsElToBeDisplayedPatientsFieldsData } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { EDITABLE_TABLE_HEADERS } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableHeader/constants/table';
import {
  focusedCellState,
  selectedCellState,
} from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';

export type TableCellElement = HTMLInputElement | HTMLDivElement;
export type TableCellRefs = Array<Array<TableCellElement | null>>;

type UseCustomerManagementEditableCellInteractionProps = {
  maxRows: number;
  isViewerMode: boolean;
} & ApiServiceSettingsElToBeDisplayedPatientsFieldsData['data'];

export const useCustomerManagementEditableCellInteraction = ({
  maxRows,
  isViewerMode,
  ...usingColumns
}: UseCustomerManagementEditableCellInteractionProps) => {
  const {
    usingChartNumber,
    usingFirstVisitStartDate,
    usingBirthDay,
    usingGender,
    usingTreatmentTag,
    usingNationality,
  } = usingColumns;

  const { isTest: usingHasToBlockSendingMessage } = useSelectedHospitalInfo();

  const resetSelectedCell = useResetAtom(selectedCellState);
  const resetFocusedCell = useResetAtom(focusedCellState);

  const [selectedCell, setSelectedCell] = useAtom(selectedCellState);
  const [focusedCell, setFocusedCell] = useAtom(focusedCellState);

  const headers = useMemo(
    () =>
      EDITABLE_TABLE_HEADERS.filter((header) => {
        if (usingChartNumber && header === '차트번호') return true;
        if (usingFirstVisitStartDate && header === '초/재진') return true;
        if (usingBirthDay && header === '생년월일') return true;
        if (usingGender && header === '성별') return true;
        if (usingNationality && header === '국적') return true;
        if (usingTreatmentTag && header === '치료태그') return true;
        if (usingHasToBlockSendingMessage && header === '수신거부') return true;

        if (header === 'No') return true;
        if (header === '고객명') return true;
        if (header === '휴대폰번호') return true;

        return false;
      }),
    [
      usingChartNumber,
      usingFirstVisitStartDate,
      // usingCountryCode,
      usingBirthDay,
      usingGender,
      usingNationality,
      usingTreatmentTag,
      usingHasToBlockSendingMessage,
    ],
  );

  const handleChangeSelectedCell = useCallback(
    (rowIndex: number, colIndex: number) => {
      setSelectedCell({ row: rowIndex, col: colIndex });
    },
    [setSelectedCell],
  );

  const handleChangeFocusedCell = useCallback(
    (rowIndex: number | null, colIndex: number | null) => {
      setFocusedCell({ row: rowIndex, col: colIndex });
    },
    [setFocusedCell],
  );

  const handleKeyDown = useCallback(
    debounce((e: KeyboardEvent) => {
      if (isViewerMode) return;

      // 선택된 셀이 없는 경우
      if (selectedCell.row === null || selectedCell.col === null) return;

      // Focused 상태일 때의 기존 로직
      if (focusedCell.row !== null && focusedCell.col !== null) {
        if (e.key === 'Enter') {
          setFocusedCell(selectedCell);
        } else if (e.key === 'Escape') {
          setFocusedCell({ row: null, col: null });
        } else if (e.key === 'Tab' && e.shiftKey) {
          e.preventDefault();
          setSelectedCell((prev) => {
            let nextCol = prev.col - 1;
            let nextRow = prev.row;

            if (nextCol < 0) {
              nextCol = headers.length - 2;
              nextRow -= 1;
              if (nextRow < 0) {
                nextRow = maxRows - 1;
              }
            }

            setFocusedCell({ row: nextRow, col: nextCol });
            return { row: nextRow, col: nextCol };
          });
        } else if (e.key === 'Tab') {
          e.preventDefault();
          setSelectedCell((prev) => {
            let nextCol = prev.col + (e.shiftKey ? -1 : 1);
            let nextRow = prev.row;

            if (nextCol >= headers.length - 1) {
              nextCol = 0;
              nextRow += 1;
              if (nextRow >= maxRows) {
                nextRow = 0;
              }
            } else if (nextCol < 0) {
              nextCol = headers.length - 2;
              nextRow -= 1;
              if (nextRow < 0) {
                nextRow = maxRows - 1;
              }
            }

            setFocusedCell({ row: nextRow, col: nextCol });
            return { row: nextRow, col: nextCol };
          });
        }
      }

      // Selected 상태일 때의 로직
      if (
        selectedCell.row !== null &&
        selectedCell.col !== null &&
        focusedCell.row === null &&
        focusedCell.col === null
      ) {
        if (e.key === 'Backspace') {
          e.preventDefault();
        }
        if (e.key === 'ArrowUp') {
          setSelectedCell((prev) => {
            const newRow = Math.max(0, prev.row - 1);
            setFocusedCell({ row: null, col: null });
            return { row: newRow, col: prev.col };
          });
        } else if (e.key === 'ArrowDown') {
          setSelectedCell((prev) => {
            const newRow = Math.min(maxRows - 1, prev.row + 1);
            setFocusedCell({ row: null, col: null });
            return { row: newRow, col: prev.col };
          });
        } else if (e.key === 'ArrowLeft' || (e.key === 'Tab' && e.shiftKey)) {
          e.preventDefault();
          setSelectedCell((prev) => {
            let nextCol = prev.col - 1;
            let nextRow = prev.row;

            if (nextCol < 0) {
              nextCol = headers.length - 2;
              nextRow -= 1;
              if (nextRow < 0) {
                nextRow = maxRows - 1;
              }
            }

            setFocusedCell({ row: null, col: null });
            return { row: nextRow, col: nextCol };
          });
        } else if (e.key === 'ArrowRight' || e.key === 'Tab') {
          e.preventDefault();
          setSelectedCell((prev) => {
            let nextCol = prev.col + 1;
            let nextRow = prev.row;

            if (nextCol >= headers.length - 1) {
              nextCol = 0;
              nextRow += 1;
              if (nextRow >= maxRows) {
                nextRow = 0;
              }
            }

            setFocusedCell({ row: null, col: null });
            return { row: nextRow, col: nextCol };
          });
        } else if (e.key === 'Enter') {
          setFocusedCell(selectedCell);
          e.preventDefault();
        }
      }
    }, 10),
    [focusedCell, selectedCell, setFocusedCell, setSelectedCell, maxRows, headers, isViewerMode],
  );

  const handleCellClick = useCallback(
    (rowIndex: number, colIndex: number) => {
      setSelectedCell({ row: rowIndex, col: colIndex });
      setFocusedCell({ row: rowIndex, col: colIndex });
    },
    [setSelectedCell, setFocusedCell],
  );

  useEffect(() => {
    // 전역 이벤트 리스너로 handleKeyDown을 등록
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      // 컴포넌트가 언마운트되면 이벤트 리스너를 제거
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    return () => {
      resetSelectedCell();
      resetFocusedCell();
    };
  }, [resetSelectedCell, resetFocusedCell]);

  return {
    selectedCell,
    focusedCell,
    handleCellClick,
    handleChangeSelectedCell,
    handleChangeFocusedCell,
    resetSelectedCell,
    resetFocusedCell,
  };
};
