import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import VirtualizedTagDropdown from 'afterdoc-design-system/components/Molecules/Dropdown/VirtualizedTagDropdown/VirtualizedTagDropdown';
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import './MultipleTagsSelectBoxTagDropdownForTable.scss';

export interface BaseMultipleTagsProperty {
  name: string;
  id: string;
  color?: string;
  isSpecial?: boolean;
}

export interface MultipleTagsSelectBoxTagDropdownForTableProps<T extends BaseMultipleTagsProperty> {
  id?: string;
  width?: number | string;
  placeholder?: string;
  value: T[] | null;
  tagOptions: T[];
  onSelect?: (index: number) => void;
  onSelectedTagsChange?: (tags: T[]) => void;
  onRemoveTag?: (tagIndex: number) => void;
  isFocused?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  customFocusScrollHandler?: (focusedIndex: number) => number;
  customSelectedScrollHandler?: (selectedIndex: number) => number;
  handleClickOutside?: () => void;
  className?: string;
  isViewerMode?: boolean;
}

const MultipleTagsSelectBoxTagDropdownForTable = forwardRef<
  HTMLDivElement,
  MultipleTagsSelectBoxTagDropdownForTableProps<BaseMultipleTagsProperty>
>(
  (
    {
      id,
      value,
      width,
      onSelect,
      tagOptions,
      onSelectedTagsChange,
      onRemoveTag,
      disabled,
      placeholder,
      isFocused,
      isSelected,
      className,
      isViewerMode,
    },
    ref,
  ) => {
    const uniqueId = useId();
    const inputRef = useRef<HTMLDivElement>(null);
    const ignoreRef = useRef(null);
    const prevInputValueRef = useRef('');

    const [isToggle, setIsToggle] = useState(false);
    const [selectedTags, setSelectedTags] = useState<BaseMultipleTagsProperty[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [immediateInputValue, setImmediateInputValue] = useState('');
    const [isComposing, setIsComposing] = useState(false);

    useImperativeHandle(ref, () => inputRef.current as HTMLDivElement);

    const filteredTags = useMemo(() => {
      if (!isToggle) return []; // 드롭다운이 닫혀있을 때는 필터링하지 않음

      const searchValue = inputValue.toLowerCase();
      return tagOptions.filter(
        (tag) =>
          tag.name.toLowerCase().includes(searchValue) &&
          !selectedTags.some((selectedTag) => selectedTag.name === tag.name),
      );
    }, [isToggle, inputValue, tagOptions, selectedTags]);

    const handleSelect = useCallback(
      (index: number) => {
        if (disabled) return;

        const selectedTag = filteredTags[index];
        if (!selectedTags.some((tag) => tag.id === selectedTag.id)) {
          requestAnimationFrame(() => {
            if (inputRef.current) {
              inputRef.current.textContent = '';
              prevInputValueRef.current = '';
              setInputValue('');

              const range = document.createRange();
              const sel = window.getSelection();
              range.selectNodeContents(inputRef.current);
              range.collapse(false);
              sel?.removeAllRanges();
              sel?.addRange(range);
              inputRef.current.focus();
            }

            const newSelectedTag = {
              id: selectedTag.id,
              name: selectedTag.name,
              color: selectedTag.color,
              isSpecial: selectedTag.isSpecial,
            };
            const newSelectedTags = [...selectedTags, newSelectedTag];
            setSelectedTags(newSelectedTags);
            onSelectedTagsChange?.(newSelectedTags);
            onSelect?.(index);
          });
        }
      },
      [disabled, filteredTags, selectedTags, onSelectedTagsChange, onSelect],
    );

    const handleToggle = (isToggle?: boolean) => {
      if (isToggle !== undefined) {
        setIsToggle(isToggle);
      } else {
        setIsToggle((prev) => !prev);
      }
    };

    const clearInput = () => {
      if (inputRef.current) {
        inputRef.current.textContent = '';
        setImmediateInputValue('');
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (disabled || isComposing) return;

      if (e.key === 'Enter') {
        e.preventDefault();
        if (!isToggle || !filteredTags.length) {
          setIsToggle(true);
        }
      } else if (e.key === 'Tab') {
        e.preventDefault();
        const nextFocusable = inputRef.current?.nextElementSibling as HTMLElement;
        if (nextFocusable) {
          nextFocusable.focus();
        }
      } else if (e.key === 'Backspace') {
        if (window.getSelection()?.toString() || e.ctrlKey || e.metaKey) {
          e.preventDefault();
          setSelectedTags([]);
          onSelectedTagsChange?.([]);
          setInputValue('');
          setImmediateInputValue('');
          if (inputRef.current) {
            inputRef.current.textContent = '';
          }
        } else if (inputValue === '' && selectedTags.length > 0) {
          e.preventDefault();
          const newSelectedTags = selectedTags.slice(0, -1);
          setSelectedTags(newSelectedTags);
          onSelectedTagsChange?.(newSelectedTags);
          setIsToggle(true);
          inputRef.current?.focus();
        }
      } else if (e.key === 'Escape') {
        e.preventDefault();
        setIsToggle(false);
        setInputValue('');
        setImmediateInputValue('');
        inputRef.current?.blur();
      }
    };

    const handleRemoveTag = (tagIndex: number) => {
      if (disabled) return;

      const newSelectedTags = selectedTags.filter((_, index) => index !== tagIndex);
      setSelectedTags(newSelectedTags);
      onSelectedTagsChange?.(newSelectedTags);
      onRemoveTag?.(tagIndex);
      setIsToggle(true);
      inputRef.current?.focus();
    };

    const handleInput = useCallback(
      (e: React.FormEvent<HTMLDivElement>) => {
        if (disabled) return;

        const text = e.currentTarget?.textContent || '';
        if (text === prevInputValueRef.current) return;

        prevInputValueRef.current = text;
        setIsToggle(true);
        setInputValue(text);
      },
      [disabled],
    );

    const handleCompositionStart = () => {
      setIsComposing(true);
    };

    const handleCompositionEnd = (e: React.CompositionEvent<HTMLDivElement>) => {
      setIsComposing(false);
      const text = e.currentTarget?.textContent || '';
      setInputValue(text);
      setImmediateInputValue(text);
    };

    useEffect(() => {
      const observer = new MutationObserver(() => {
        const text = inputRef.current?.textContent || '';
        setImmediateInputValue(text);
      });

      if (inputRef.current) {
        observer.observe(inputRef.current, {
          childList: true,
          subtree: true,
          characterData: true,
        });
      }

      return () => {
        observer.disconnect();
      };
    }, [inputRef]);

    useEffect(() => {
      if (inputRef.current) {
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(inputRef.current);
        range.collapse(false);
        sel?.removeAllRanges();
        sel?.addRange(range);
      }
    }, [inputValue]);

    useEffect(() => {
      if (inputRef.current) {
        if (isFocused && !disabled) {
          setIsToggle(true);
          inputRef.current.focus();
          // placeholder 제거
          inputRef.current.setAttribute('data-placeholder', '');
          inputRef.current.classList.remove('hidden-caret');

          // 빈 텍스트 노드를 추가하여 Caret이 첫 번째 줄에 위치하도록 설정
          if (inputRef.current.innerText.trim() === '') {
            inputRef.current.innerHTML = ''; // 기존의 줄바꿈을 제거
            const textNode = document.createTextNode('');
            inputRef.current.appendChild(textNode);

            // Caret을 첫 번째 줄에 위치시키기
            const range = document.createRange();
            const selection = window.getSelection();
            range.setStart(textNode, 0);
            range.collapse(true);

            if (selection) {
              selection.removeAllRanges();
              selection.addRange(range);
            }
          }
        } else {
          setIsToggle(false);
          inputRef.current.blur();
          inputRef.current.classList.add('hidden-caret');
        }
      }
    }, [isFocused, disabled]);

    useEffect(() => {
      if (inputRef.current) {
        if (!selectedTags.length && !isFocused) {
          inputRef.current.setAttribute('data-placeholder', placeholder ?? '');
        } else {
          inputRef.current.removeAttribute('data-placeholder');
        }
      }
    }, [selectedTags, placeholder, isFocused]);

    useEffect(() => {
      if (!isFocused && inputRef.current) {
        if (inputRef.current.textContent && inputRef.current.textContent.trim() !== '') {
          clearInput();
        }
      }
    }, [isFocused]);

    useEffect(() => {
      if (!value?.length) {
        setSelectedTags([]);
        return;
      }

      // value의 id 순서를 보존하면서 tagOptions에서 매칭되는 태그 찾기
      const tagMap = new Map(tagOptions.map((tag) => [tag.id, tag]));
      const orderedTags = value.reduce<BaseMultipleTagsProperty[]>((acc, value) => {
        const matchedTag = tagMap.get(value.id);
        if (matchedTag) {
          acc.push(matchedTag);
        }
        return acc;
      }, []);

      setSelectedTags(orderedTags);
    }, [value, tagOptions]);

    useEffect(() => {
      if (!isViewerMode) return;

      if (!value?.length) {
        setSelectedTags([]);
        setInputValue('');
        setImmediateInputValue('');
        if (inputRef.current) {
          inputRef.current.textContent = '';
        }
      } else {
        const tagMap = new Map(tagOptions.map((tag) => [tag.id, tag]));
        const orderedTags = value.reduce<BaseMultipleTagsProperty[]>((acc, valueTag) => {
          const matchedTag = tagMap.get(valueTag.id);
          if (matchedTag) {
            acc.push(matchedTag);
          }
          return acc;
        }, []);

        setSelectedTags(orderedTags);
      }
    }, [isViewerMode, value, tagOptions]);

    return (
      <>
        <div
          ref={ignoreRef}
          className={customTwMerge(
            'mt-1 flex flex-wrap items-center gap-4 overflow-auto px-12 outline-none hover:bg-blueLight',
            selectedTags.length > 0 ? 'py-[9.5px]' : 'py-11',
            disabled ? 'cursor-not-allowed border-white600 bg-white200' : 'border-white600',
            (isFocused || isSelected) && 'bg-blue50',
            className,
          )}
          style={{
            width: SHARED_UTILS.css.getCssSizeValue(width),
          }}>
          {selectedTags.map((tag, tagIndex) => (
            <FilledTag
              key={uuidv4()}
              tagSize='small'
              className={customTwMerge(disabled ? 'cursor-not-allowed' : 'cursor-pointer')}
              iconProps={{
                name: 'close',
                size: 12,
              }}
              bgColor={tag.color}
              onClick={(e) => {
                if (disabled) return;
                e?.stopPropagation();
                handleRemoveTag(tagIndex);
              }}>
              {tag.name}
            </FilledTag>
          ))}
          <div
            id={uniqueId}
            ref={inputRef}
            className='flex-1 overflow-hidden bg-transparent text-Body12 text-black700 outline-none'
            contentEditable={!disabled}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            onCompositionStart={handleCompositionStart}
            onCompositionEnd={handleCompositionEnd}
            suppressContentEditableWarning={true}
            onClick={(e) => {
              if (disabled) {
                e.preventDefault();
                inputRef.current?.blur();
              }
            }}
          />
        </div>
        {!disabled && isToggle && (
          <VirtualizedTagDropdown
            id={id}
            width={width}
            isComposing={isComposing}
            tags={filteredTags}
            ignoreRefs={[ignoreRef]}
            isToggle={isToggle}
            searchText={immediateInputValue}
            handleToggle={handleToggle}
            isDefaultFocus={!!immediateInputValue}
            onSelect={handleSelect}
          />
        )}
      </>
    );
  },
);

MultipleTagsSelectBoxTagDropdownForTable.displayName = 'MultipleTagsSelectBoxTagDropdownForTable';

export default memo(MultipleTagsSelectBoxTagDropdownForTable);
