import Loading from 'afterdoc-design-system/assets/lotties/loading.webp';
import { isCustomerManagementSaveLockState } from 'afterdoc-saas-web/hooks/push-notification/states/is-customer-management-save-lock';
import { useAtomValue } from 'jotai';

export default function CustomerManagementLockOverlay() {
  const isCustomerManagementSaveLock = useAtomValue(isCustomerManagementSaveLockState);

  return (
    <div className='absolute top-0 left-0 z-10 flex h-full w-full flex-col items-center justify-center gap-32 bg-white50/50 text-center'>
      <img src={Loading} alt='loading' className='h-48 w-48 object-contain' />
      {isCustomerManagementSaveLock.count ? (
        <div className='flex flex-col gap-8'>
          <div className='text-Header16 text-black700'>
            {isCustomerManagementSaveLock.count}건의 고객을 등록하고 있습니다.
          </div>
          <div className='text-Body14 text-black700'>작업이 완료되면 알림을 보내드립니다.</div>
          <div className='whitespace-pre-wrap text-Body12 text-black200'>
            {'입력된 고객 수가 많아서 네트워크가 느린 경우\n시간이 오래 소요될 수 있습니다.'}
          </div>
        </div>
      ) : (
        <div className='h-[92px]' />
      )}
    </div>
  );
}
