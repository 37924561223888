import { useVirtualScroll } from '@shared-hooks/use-virtual-scroll';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useInfiniteQuery } from '@tanstack/react-query';
import LoadingSpinner from 'afterdoc-design-system/components/Atoms/Loading/LoadingSpinner';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  ApiPatientsElListOrSearchForUntaggedParams,
  ApiServiceSettingsElCountriesData,
  ApiTreatmentTagsElData,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import FullLoading from 'afterdoc-saas-web/shared/components/FullLoading/FullLoading';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import UnTaggedCustomerManagementEditableTableBody from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/components/UnTaggedCustomerManagementEditableTableBody/UnTaggedCustomerManagementEditableTableBody';
import { unTaggedHeaderFilterState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/states/editable-header-filter';
import type { UnTaggedCustomerManagementAPIFormValues } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/types/table';
import { searchTextState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/search';
import { selectedSearchCriteriaState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import { useIntersectionObserver } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-intersection-observer';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

const fetchUnTaggedPatientsListOrSearch = async ({
  pageParam = undefined,
  ...params
}: { pageParam?: string } & ApiPatientsElListOrSearchForUntaggedParams) => {
  const response = await apiClient.v3.apiPatientsElListOrSearchForUntagged({
    cursor: pageParam,
    ...params,
  });
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

interface UnTaggedCustomerManagementEditableTableBodyContainerProps {
  countriesList: ApiServiceSettingsElCountriesData['data'];
  treatmentTags: ApiTreatmentTagsElData['data']['treatmentTags'];
}

export default function UnTaggedCustomerManagementEditableTableBodyContainer({
  countriesList,
  treatmentTags,
}: UnTaggedCustomerManagementEditableTableBodyContainerProps) {
  const { watch, reset, setValue } = useFormContext<{
    rows: UnTaggedCustomerManagementAPIFormValues;
  }>();
  const { hospitalID } = useSelectedHospitalInfo();

  const rows = watch('rows');
  const rowHeight = useRef(40);
  const loadMoreRef = useRef<HTMLTableSectionElement | null>(null);

  const unTaggedHeaderFilter = useAtomValue(unTaggedHeaderFilterState);
  const searchText = useAtomValue(searchTextState);
  const selectedSearchCriteria = useAtomValue(selectedSearchCriteriaState);

  const virtualScrollData = useVirtualScroll({
    rows,
    rowHeight: rowHeight.current,
    offset: 184,
    buffer: 15,
  });

  const params: ApiPatientsElListOrSearchForUntaggedParams = useMemo(() => {
    return {
      hospitalID,
      limit: 50,
      chartNumber: selectedSearchCriteria === 'chartNumber' ? searchText : undefined,
      patientName: selectedSearchCriteria === 'name' ? searchText : undefined,
      phone: selectedSearchCriteria === 'phoneNumber' ? searchText : undefined,
      birth: selectedSearchCriteria === 'birthdate' ? searchText : undefined,
      ...unTaggedHeaderFilter,
      isFirstVisit:
        unTaggedHeaderFilter.isFirstVisit === 'all' ? undefined : unTaggedHeaderFilter.isFirstVisit,
      gender: unTaggedHeaderFilter.gender === 'all' ? undefined : unTaggedHeaderFilter.gender,
    };
  }, [unTaggedHeaderFilter, hospitalID, searchText, selectedSearchCriteria]);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isError, isLoading } =
    useInfiniteQuery({
      queryKey: [QUERY_KEY.apiPatientsElListOrSearchForUntagged, params],
      queryFn: ({ pageParam }: { pageParam?: string }) =>
        fetchUnTaggedPatientsListOrSearch({
          pageParam,
          ...params,
        }),
      getNextPageParam: (lastPage) => {
        if (!lastPage || !lastPage.nextCursor) {
          return undefined;
        }
        return lastPage.nextCursor;
      },
      initialPageParam: undefined,
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => ({
            ...page,
            patients: page.patients?.map((patient) => ({
              patientId: patient.patientId,

              name: patient.name,
              chartNumber: patient.chartNumber,
              isFirstVisit: patient.isFirstVisit,
              phoneNumber: patient.phoneNumber,
              birthdate: patient.birthdate,
              gender: patient.gender,

              treatmentTagIds: patient.treatmentTags?.map((tag) => tag._id) ?? [],
              nationalityId: patient.nationality?.id || null,
            })) as UnTaggedCustomerManagementAPIFormValues,
          })),
        };
      },
    });

  useIntersectionObserver({
    target: loadMoreRef.current,
    onIntersect: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    enabled: hasNextPage && !isError && !isLoading,
    threshold: [0, 0.1, 0.5, 1.0],
  });

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (data && isFirstRender.current) {
      isFirstRender.current = false;
      const allPatients = data.pages.flatMap((page) => page.patients ?? []);
      reset({
        rows: allPatients,
      });
    }
  }, [data, reset]);

  useEffect(() => {
    if (isFirstRender.current || !data) {
      return;
    }

    const allPatients = data.pages.flatMap((page) => page.patients ?? []);
    setValue('rows', allPatients);
  }, [data, setValue]);

  if (isLoading && !data) {
    return <FullLoading />;
  }

  if (
    !data?.pages[0].patients.length &&
    (!!searchText?.length || Object.values(unTaggedHeaderFilter).some((v) => v !== undefined))
  ) {
    return (
      <div className='h-[calc(100vh-500px)] flex-w-full-center'>
        <NoData
          iconProps={{
            name: 'warning',
            size: 48,
            color: 'white600',
          }}
          title='검색 결과가 없습니다.'
        />
      </div>
    );
  }

  if (!data?.pages[0].patients.length) {
    return (
      <div className='h-[calc(100vh-500px)] flex-w-full-center'>
        <NoData
          iconProps={{
            name: 'warning',
            size: 48,
            color: 'white600',
          }}
          title='표시할 내용이 없습니다.'
        />
      </div>
    );
  }

  return (
    <>
      <Scrollbar
        onScroll={(scrollTop) => virtualScrollData.handleScroll(scrollTop)}
        style={{ height: `${window.innerHeight - 184}px` }}>
        <div style={{ height: `${virtualScrollData.totalHeight}px`, position: 'relative' }}>
          <table
            className='w-full table-fixed'
            style={{
              transform: `translateY(${virtualScrollData.offsetY}px)`,
            }}>
            <UnTaggedCustomerManagementEditableTableBody
              countriesList={countriesList}
              treatmentTagsList={treatmentTags}
              visibleRows={virtualScrollData.visibleRows}
            />
          </table>
          <div ref={loadMoreRef} className='absolute bottom-[100px] h-20 w-full' />
        </div>
      </Scrollbar>

      {isFetchingNextPage && (
        <div className='flex w-full justify-center'>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}
