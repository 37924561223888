import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { CreateUpdateEventParamType } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/types/create-update-event-param-type';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { SHARED_UTILS } from 'utils/utils';

type CreateEventParam = Parameters<typeof apiClient.v3.apiEventHospitalEventElCreate>[0];

type UpdateEventParam = Parameters<typeof apiClient.v3.apiEventHospitalEventElUpdate>[0];

type RemoveEventParam = Parameters<typeof apiClient.v3.apiEventHospitalEventElRemove>[0];

const createHospitalEvent = async (params: CreateEventParam) => {
  const { data } = await apiClient.v3.apiEventHospitalEventElCreate(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const updateHospitalEvent = async (params: UpdateEventParam) => {
  const { data } = await apiClient.v3.apiEventHospitalEventElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const removeHospitalEvent = async (params: RemoveEventParam) => {
  const { data } = await apiClient.v3.apiEventHospitalEventElRemove(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const showDeleteConfirmation = (onConfirm: () => void) => {
  modalService.defaultWarning({
    title: '이벤트를 삭제할까요?',
    contents: '삭제한 이벤트는 복구할 수 없습니다.',
    onConfirm,
  });
};

export const useHospitalEvents = () => {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();

  const createEventMutation = useMutation({
    mutationFn: (params: CreateUpdateEventParamType) => {
      const { _id, saleType, saleRate, salePrice, price, ...otherParams } = params;

      const filteredParams = {
        ...otherParams,
        saleType,
        saleRate: saleType === 'price' ? undefined : saleRate,
        salePrice: saleType === 'rate' ? undefined : salePrice,
        price: price ?? 0,
      };

      return createHospitalEvent({
        ...filteredParams,
        hospitalID,
      });
    },
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiEventHospitalEventElCount] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiEventHospitalEventElFindSimple] });
      toastService.successMsg({ text: '이벤트를 등록했습니다.' });
    },
    onError: (error: Error) => {
      console.error('Error creating hospital event', error);
      toastService.errorMsg({ text: '이벤트 등록을 실패했습니다.' });
    },
  });

  const createEventAction = (
    params: CreateUpdateEventParamType,
    onSuccessCallback?: (id: string) => void,
    onFailureCallback?: () => void,
  ) => {
    createEventMutation.mutate(params, {
      onSuccess: (data) => onSuccessCallback?.(data._id ?? ''),
      onError: () => onFailureCallback?.(),
    });
  };

  const updateEventMutation = useMutation({
    mutationFn: (params: CreateUpdateEventParamType) =>
      updateHospitalEvent({ ...params, price: params.price ?? 0, hospitalID }),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiEventHospitalEventElFindSimple] });
      toastService.successMsg({ text: '이벤트를 수정했습니다.' });
    },
    onError: (error: Error) => {
      console.error('Error creating hospital event', error);
      toastService.errorMsg({ text: '이벤트 수정을 실패했습니다.' });
    },
  });

  const updateEventAction = (
    params: CreateUpdateEventParamType,
    onSuccessCallback?: () => void,
    onFailureCallback?: () => void,
  ) => {
    const { saleRate, salePrice, saleType, type, fileIDs, content } = params;

    const filteredParams = {
      ...params,
      fileIDs: type === 'text' ? undefined : fileIDs,
      content: type === 'image' ? undefined : content,
      saleRate: saleType === 'price' ? undefined : saleRate,
      salePrice: saleType === 'rate' ? undefined : salePrice,
    };

    updateEventMutation.mutate(filteredParams, {
      onSuccess: () => onSuccessCallback?.(),
      onError: () => onFailureCallback?.(),
    });
  };

  const removeEventMutation = useMutation({
    mutationFn: (params: RemoveEventParam) => removeHospitalEvent(params),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiEventHospitalEventElFindSimple] });
      modalService.pop();
      toastService.successMsg({ text: '이벤트를 삭제했습니다.' });
    },
    onError: (error: Error) => {
      console.error('Error removing hospital event', error);
      modalService.pop();
      toastService.errorMsg({ text: '이벤트 삭제를 실패했습니다.' });
    },
  });

  const removeEventAction = (id: string, onSuccessCallback?: () => void) => {
    showDeleteConfirmation(() => {
      removeEventMutation.mutate(
        { _id: id },
        {
          onSuccess: () => {
            if (onSuccessCallback) onSuccessCallback();
          },
        },
      );
    });
  };

  return {
    createEventAction,
    updateEventAction,
    removeEventAction,
  };
};
