import TextInput, {
  type TextInputLabelTextProps,
} from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { useFormContext } from 'react-hook-form';

const baseLabelProps: TextInputLabelTextProps = {
  isRequired: true,
  children: '신청자명',
  width: 70,
  position: 'horizontal',
};

type EditNameProps = {
  isAlwaysHasErrorSpace?: boolean;
};

export default function EditName({ isAlwaysHasErrorSpace = false }: EditNameProps) {
  const { watch, register, clearErrors } = useFormContext();
  const name = watch('name');
  const isFormValid = name?.trim() !== '';

  return (
    <>
      <TextInput
        id='name'
        placeholder='예) 홍길동'
        className='items-start justify-between'
        label={baseLabelProps}
        inputWrapperClassName='flex justify-end'
        inputClassName='w-[268px]'
        maxLength={1000}
        hasError={!isFormValid}
        isAlwaysHasErrorSpace={isAlwaysHasErrorSpace}
        errorTextClassName='ml-4'
        errorTextPosition='vertical'
        errorText={isFormValid ? '' : '신청자명을 입력해 주세요.'}
        {...register('name', {
          required: true,
          onChange: () => clearErrors('name'),
        })}
      />
    </>
  );
}
