import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { ParseWorkoutVideoContent } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/WorkoutVideoContentForm/WorkoutVideoContentForm';
import { useMemo } from 'react';

interface DisplayWorkoutVideoProps {
  messageWorkoutVideo?: string;
}

export default function DisplayWorkoutVideo({ messageWorkoutVideo }: DisplayWorkoutVideoProps) {
  const parsedWorkoutVideoContent: ParseWorkoutVideoContent = useMemo(() => {
    if (!messageWorkoutVideo) return undefined;
    try {
      return JSON.parse(messageWorkoutVideo);
    } catch {
      return undefined;
    }
  }, [messageWorkoutVideo]);

  if (!parsedWorkoutVideoContent) {
    return <NoWorkoutVideoContent />;
  }

  return (
    <div className='w-[234px] rounded-[12px] bg-white50 shadow-modal'>
      <div className='rounded-tl-[8px] rounded-tr-[8px] bg-blue400 p-8 text-Header12 text-white50'>
        홈케어콘텐츠 알림
      </div>
      <div className='flex items-center gap-10 p-8'>
        {parsedWorkoutVideoContent.thumbnailUrl ? (
          <img
            src={parsedWorkoutVideoContent.thumbnailUrl}
            alt='홈케어콘텐츠 썸네일'
            className={customTwMerge('h-64 w-64 rounded-[8px] object-cover')}
          />
        ) : (
          <div className='h-64 w-64 rounded-r10 bg-disabled' />
        )}
        <div className='flex flex-col justify-center gap-4'>
          {parsedWorkoutVideoContent.subtitle && (
            <div className='line-clamp-2 text-Body10 text-black500'>
              {parsedWorkoutVideoContent.subtitle}
            </div>
          )}
          <div className='line-clamp-2 text-Header12 text-black500'>
            {parsedWorkoutVideoContent.title || '홈케어콘텐츠 제목'}
          </div>
        </div>
      </div>
      <div className='flex-w-full-center py-8 text-Header14 text-blue400'>바로가기</div>
    </div>
  );
}

const NoWorkoutVideoContent = () => (
  <div className='w-[234px] rounded-[12px] bg-white50 shadow-modal'>
    <div className='rounded-tl-[8px] rounded-tr-[8px] bg-blue400 p-8 text-Header12 text-white50'>
      홈케어콘텐츠 알림
    </div>
    <div className='flex items-center gap-8 p-8'>
      <div className='h-64 w-64 rounded-[8px] bg-disabled' />
      <span className='text-Body14 text-black700'>홈케어콘텐츠 제목</span>
    </div>
    <div className='flex-w-full-center py-8 text-Header14 text-blue400'>바로가기</div>
  </div>
);
