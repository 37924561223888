import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  ApiServiceSettingsElCountriesParams,
  ApiTreatmentTagsElParams,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { COUNTRY_LIMIT } from 'afterdoc-saas-web/templates/CustomerManagement/constants/country-limit';
import UnTaggedCustomerManagementDownloadFooter from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/components/UnTaggedCustomerManagementDownloadFooter/UnTaggedCustomerManagementDownloadFooter';
import UnTaggedCustomerManagementEditableTableHeader from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/components/UnTaggedCustomerManagementEditableTableHeader/UnTaggedCustomerManagementEditableTableHeader';
import { useResetTableJotaiStates } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/hooks/use-reset-table-jotai-states';
import { clickedPanelIndexState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/panel';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { focusedCellState, selectedCellState } from '../../../shared/states/table';
import UnTaggedCustomerManagementEditableTableBodyContainer from './containers/UnTaggedCustomerManagementEditableTableBody.container';

const fetchTreatmentTagsList = async (params: ApiTreatmentTagsElParams) => {
  const response = await apiClient.v3.apiTreatmentTagsEl(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const fetchCountriesList = async (params: ApiServiceSettingsElCountriesParams) => {
  const response = await apiClient.v3.apiServiceSettingsElCountries(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function UnTaggedCustomerManagementEditableTableContainer() {
  const { hospitalID } = useSelectedHospitalInfo();

  const clickedPanelIndex = useAtomValue(clickedPanelIndexState);

  const { resetTableJotaiStates } = useResetTableJotaiStates();

  const methods = useForm({
    defaultValues: {
      rows: [],
    },
  });

  const {
    data: { treatmentTags },
  } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiTreatmentTagsEl, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchTreatmentTagsList(queryKey[1]),
  });

  const { data: countriesList } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiServiceSettingsElCountries,
      { hospitalID, limit: COUNTRY_LIMIT },
    ] as const,
    queryFn: ({ queryKey }) => fetchCountriesList(queryKey[1]),
  });

  const tableRef = useRef<HTMLDivElement>(null);

  const resetSelectedCell = useResetAtom(selectedCellState);
  const resetFocusedCell = useResetAtom(focusedCellState);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;

      if (tableRef.current && !tableRef.current.contains(targetElement)) {
        resetSelectedCell();
        resetFocusedCell();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tableRef]);

  useEffect(() => {
    if (clickedPanelIndex === 1) {
      return;
    }

    return () => {
      resetTableJotaiStates();
    };
  }, [clickedPanelIndex, resetTableJotaiStates]);

  return (
    <FormProvider {...methods}>
      <div
        className={customTwMerge(
          'flex h-[calc(100vh-145px)] w-full justify-center border-line-table',
        )}>
        <div className='flex flex-col'>
          <table className='w-full table-fixed'>
            <UnTaggedCustomerManagementEditableTableHeader />
          </table>
          <UnTaggedCustomerManagementEditableTableBodyContainer
            countriesList={countriesList}
            treatmentTags={treatmentTags}
          />
        </div>
      </div>
      <UnTaggedCustomerManagementDownloadFooter />
    </FormProvider>
  );
}
