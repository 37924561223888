import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  AlgorithmToggleInput,
  AlgorithmWithMessagesInput,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { type ChangeEventHandler, type ReactNode, useEffect, useState } from 'react';

type ContentType = {
  title: string;
  isActivatedContent: ReactNode | string;
  isInActivatedContent: ReactNode | string;
};

const postAutomationsElAlgorithmsToggle = async (params: AlgorithmToggleInput) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsToggle(params);
  return response.data;
};

const getToggleContent = (
  type: AlgorithmWithMessagesInput['type'],
  isChecked: boolean,
  targetCount?: number,
): ContentType => {
  const isActive = isChecked ? '비활성화' : '활성화';

  const content: {
    [key in AlgorithmWithMessagesInput['type']]: ContentType;
  } = {
    CONSULTATION: {
      title: `자동화를 ${isActive} 할까요?`,
      isActivatedContent: (
        <span className='text-Body12 text-black500'>
          현재 자동화가 적용된{' '}
          <span className='text-red500'>{targetCount?.toLocaleString() ?? 0}</span>명의 고객에게 더
          이상 메시지를 발송하지 않습니다. 다시 활성화를 할 경우, 치료태그를 새로 입력한
          고객들에게만 적용됩니다.
        </span>
      ),
      isInActivatedContent: '활성화 된 시점 이전에 치료태그가 입력된 고객에게는 적용되지 않습니다.',
    },
    MARKETING: {
      title: `마케팅을 ${isActive} 할까요?`,
      isActivatedContent: (
        <span className='text-Body12 text-black500'>
          현재 마케팅이 적용된{' '}
          <span className='text-red500'>{targetCount?.toLocaleString() ?? 0}</span>명의 고객에게 더
          이상 메시지를 발송하지 않습니다.
        </span>
      ),
      isInActivatedContent: '마케팅을 활성화 할 경우, 지정된 발송일자에 메시지가 발송됩니다.',
    },
    COMMON: {
      title: `자동화를 ${isActive} 할까요?`,
      isActivatedContent: '현재 상담자동화별 지정된 발송일자에 더 이상 메시지를 발송하지 않습니다.',
      isInActivatedContent: '활성화 할 경우, 상담자동화별 지정된 발송일자에 메시지가 발송됩니다.',
    },
  };

  return content[type];
};

interface UseToggleAlgorithmProps {
  algorithmId: string;
  targetCount: number;
  initialState: boolean;
  type: AlgorithmWithMessagesInput['type'];
}

export const useToggleAlgorithm = ({
  algorithmId,
  targetCount,
  initialState,
  type,
}: UseToggleAlgorithmProps) => {
  const { hospitalID } = useSelectedHospitalInfo();
  const queryClient = useQueryClient();

  const [isChecked, setIsChecked] = useState(initialState);

  useEffect(() => {
    setIsChecked(initialState);
  }, [initialState]);

  const changeAlgorithmToggleMutation = useMutation({
    mutationFn: postAutomationsElAlgorithmsToggle,
    onSuccess: (data, variables) => {
      if (data.code === 0) {
        setIsChecked(variables.isActive);
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiAutomationsElMainList, { hospitalID }],
        });

        if (type === 'COMMON') {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.apiAutomationsElCommonAlgorithm, { hospitalID }],
          });
        }

        return;
      }

      if (data.code === 40062) {
        toastService.errorMsg({
          text: '현재 설정된 치료태그로 설정된 자동화가 이미 존재합니다.',
        });
        return;
      }

      return toastService.errorMsg({
        text: data.message,
      });
    },
    onError: (error) => {
      console.error('error', error);
      toastService.errorMsg({
        text: '자동화 활성화를 실패했습니다. 다시 시도해 주세요.',
      });
    },
  });

  const onToggle: ChangeEventHandler = () => {
    const { title, isInActivatedContent, isActivatedContent } = getToggleContent(
      type,
      isChecked,
      targetCount,
    );

    modalService.defaultWarning({
      id: 'toggle-algorithm-warning',
      title,
      contents: isChecked ? isActivatedContent : isInActivatedContent,
      onConfirm: () => {
        changeAlgorithmToggleMutation.mutate({
          algorithmId,
          isActive: !isChecked,
        });
        modalService.popById('toggle-algorithm-warning');
      },
      onCancel: () => {
        modalService.popById('toggle-algorithm-warning');
      },
    });
  };

  return { isChecked, onToggle };
};
