import type { DataNotificationPayload } from '@shared-hooks/use-data-event-bus-handler/event-bus';
import { getChatRoomOne } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingList/api/get-chatroom-one';
import { postChatroomUpdateRead } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingList/components/ChattingListBody/ChattingListBody';
import { selectedChatRoomState } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useDataEventBusHandler } from 'hooks/use-data-event-bus-handler/use-data-event-bus-handler';
import { useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useSyncChattingListAndInfoTop } from './use-sync-chatting-list-and-info-top';

export const useLinkChannelPushHandler = ({ chatRoomID }: { chatRoomID: string }) => {
  const { updateChattingListAndInfoTop } = useSyncChattingListAndInfoTop();

  const setSelectedChatRoom = useSetAtom(selectedChatRoomState);

  //왼쪽 채팅방 목록을 클릭시 발생하는 변경 상태 값을 그대로 가져옴(일명 handleSelectedChatRoom함수)
  //api를 두번 찌르는 비효율이 발생하지만 총 세 곳의 로직을 일치시키는게 장점이 크다고 판단.
  //이 셋을 하나의 훅 또는 함수로 만들까 고민했지만, 전역 상태를 변경하고 함수 안에, 훅 안에 자유롭게 들어가야하는 바 일단 현 상태를 유지.
  const handleChatRoomUpdate = useCallback(
    async (chatRoomID: string) => {
      const chatRoom = await getChatRoomOne(chatRoomID);
      const chatRoomParentHServiceID = chatRoom.parentHServiceID._id;
      const patientIDInChattingListUserID = chatRoom.userID?._id ?? '';
      const chatRoomHserviceID = chatRoom.hserviceID._id;

      const isLinked = chatRoomHserviceID !== chatRoomParentHServiceID;

      const { roomType, ikakaoInfo } = await postChatroomUpdateRead(chatRoomID);

      setSelectedChatRoom({
        chatRoomID,
        chatRoomHserviceID,
        patientIDInChattingListUserID,
        chatRoomParentHServiceID,
        roomType,
        roomInfo: ikakaoInfo,
        isLinked,
      });
    },
    [setSelectedChatRoom],
  );

  const handleNotification = useCallback(
    async (payload: DataNotificationPayload) => {
      const { chatRoomID: updateLinkChatRoomID } = payload.data;

      if (updateLinkChatRoomID === chatRoomID) {
        updateChattingListAndInfoTop();

        await handleChatRoomUpdate(chatRoomID);
      }
    },
    [chatRoomID, handleChatRoomUpdate, updateChattingListAndInfoTop],
  );

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_HServiceUpdateLink_el'],
        handler: handleNotification,
      },
    ],
    [handleNotification],
  );

  useDataEventBusHandler(notificationConfigs);
};
