import SearchInput from 'afterdoc-design-system/components/Atoms/Input/SearchInput';
import ButtonTab from 'afterdoc-design-system/components/Atoms/Tabs/components/ButtonTab';
import Tabs from 'afterdoc-design-system/components/Atoms/Tabs/components/Tabs';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { chatRoomIDSelector } from '../../states/selected-chat-room';
import { useSearchInput } from '../ChattingList/hooks/use-search-input';
import ContentsList from './components/ContentsList/ContentsList';
import ManualList from './components/ManualList/ManualList';
import { useChangedChatRoomIDDefaultTab } from './hooks/use-changed-chatRoomID-default-tab';

export const TAB_NAMES = ['원내매뉴얼', '콘텐츠'] as const;

export default function Manual() {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const { selectedTab, setSelectedTab } = useChangedChatRoomIDDefaultTab();
  const { inputValue, keyword, handleChange, handleKeyDown, handleDelete } = useSearchInput();

  const isInnerManual = selectedTab === TAB_NAMES[0];
  const isContents = selectedTab === TAB_NAMES[1];

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    handleDelete();
  }, [selectedTab, chatRoomID]);

  return (
    <div className='flex h-full flex-col justify-start pb-10'>
      <Title title={'상담 백과사전'} />
      <div>
        <Tabs value={selectedTab} onChange={setSelectedTab}>
          {TAB_NAMES.map((name) => (
            <ButtonTab className='border-b border-b-white400' value={name} label={name} key={name}>
              {name}
            </ButtonTab>
          ))}
        </Tabs>
        {isInnerManual && (
          <div className='p-10'>
            <SearchInput
              placeholder='태그명으로 검색'
              value={inputValue}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onDelete={handleDelete}
            />
          </div>
        )}
        {/* 전체 height 최소 750px 를 맞춰주기 위한 스타일링 */}
        <div
          className={
            isContents
              ? 'h-[calc(100vh-90px)] min-h-[660px]'
              : 'h-[calc(100vh-150px)] min-h-[600px]'
          }>
          {isInnerManual && <ManualList keyword={keyword} />}
          {isContents && <ContentsList />}
        </div>
      </div>
    </div>
  );
}
