import EditOverlay from '@templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/EditOverlay/EditOverlay';
import ResizableTextArea from '@templates/Automation/containers/Dialog/shared/components/ResizableTextArea/ResizableTextArea';
import type { StatusType } from '@templates/HospitalManual/components/HospitalManualContent/components/ManualCautionMessage/types/status-type';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useCallback, useEffect, useRef, useState } from 'react';

interface CautionTextAreaProps {
  tagId: string;
  defaultCautionMessage: string;
  cautionMessage: string;
  statusType: StatusType | null;
  handleTextAreaChange: (value: string) => void;
  disabled: boolean;
}

export const CautionTextArea = ({
  tagId,
  defaultCautionMessage,
  cautionMessage,
  statusType,
  handleTextAreaChange,
  disabled,
}: CautionTextAreaProps) => {
  const [isShowOverlay, setIsShowOverlay] = useState(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInputClick = useCallback(() => {
    const showAlertTalkReviewConfirmDialog = () => {
      modalService.defaultWarning({
        title: '수정 시 알림톡 검수가 필요합니다.',
        contents:
          '내용을 수정하실 경우 알림톡 검수가 진행됩니다.\n' +
          '승인이 완료되기 전까지 알림톡 전송은 불가하며, 애프터닥 앱에 가입한 사람들에게만 수정된 메시지가 전달됩니다.\n\n' +
          '내용을 계속 수정하시려면 ‘네’ 버튼을 클릭해주세요.',
        onConfirm: () => {
          setIsShowOverlay(false);
          textareaRef.current?.focus();
          modalService.pop();
        },
      });
    };

    showAlertTalkReviewConfirmDialog();
  }, []);

  useEffect(() => {
    setIsShowOverlay(!!defaultCautionMessage && statusType !== 'rejected');
    textareaRef.current?.blur();
  }, [tagId, defaultCautionMessage, statusType]);

  return (
    <div className='relative w-full'>
      {isShowOverlay && !disabled && (
        <EditOverlay id='content-text-area-overlay' onClick={handleInputClick} />
      )}
      <ResizableTextArea
        placeholder='내용을 입력해 주세요.(최대 1,000자)'
        inputClassName='min-h-[150px]'
        width='100%'
        maxLength={1000}
        ref={textareaRef}
        maxHeight={150}
        disabled={disabled}
        value={cautionMessage}
        onChange={(e) => handleTextAreaChange(e.target.value)}
      />
    </div>
  );
};
