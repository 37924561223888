import ImageViewer from 'afterdoc-saas-web/shared/components/ImageViewer/ImageViewer';
import { useChatMessageContext } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/ChatMessageContext';
import { MESSAGE_TYPE_CODE } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/constants/message';
import { roomTypeSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import type { AwsObject } from 'afterdoc-saas-web/templates/CustomerChat/types';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import ExpiredImageMock from './components/ExpiredImage';
import MultiImageCover from './components/MultiImageCover';
import { useImageProxyCallback } from './hooks/use-image-proxy-callback';

export default function ImageList({
  aws,
  isDisabledViewer = false,
  isExpired = false,
}: { aws: AwsObject[]; isDisabledViewer: boolean; isExpired?: boolean }) {
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const roomType = useAtomValue(roomTypeSelector);

  const { userRealName, jobType, userName, sentAt, type } = useChatMessageContext();
  const { messageWidthStyle } = useChatMessageContext();
  const { switchProxyCallback } = useImageProxyCallback();

  if (!aws) return null;

  const imageWidthStyleSameTextStyle = {
    ...messageWidthStyle,
    width: 'fit-content',
  };
  const imagesLength = aws.length;
  const images = aws.map((image) => {
    return {
      id: image.Key,
      url: image.Location,
      fileName: image.fileName,
      sentAt,
      name: (userRealName || userName) ?? '',
      jobType,
    };
  });

  const handleImageOpen = (index: number) => {
    setIsImageViewerOpen(true);
    setSelectedIndex(index);
  };

  const isMocking = type === MESSAGE_TYPE_CODE.IMAGE_MOCKING;

  switch (imagesLength) {
    case 0:
      return null;
    case 1: {
      if (isExpired)
        return (
          <div
            className='flex w-full flex-wrap bg-transparent'
            style={imageWidthStyleSameTextStyle}>
            <div className='relative max-h-[330px] w-full max-w-[330px] overflow-hidden rounded-r10'>
              <ExpiredImageMock />
            </div>
          </div>
        );
      return (
        <div
          className='flex w-full cursor-pointer flex-wrap bg-transparent'
          style={imageWidthStyleSameTextStyle}>
          {aws?.map(({ fileName, Location, Key }, index) => (
            <img
              key={Key + { index }}
              src={Location}
              alt={fileName}
              onClick={() => {
                handleImageOpen(0);
              }}
              className='h-full max-h-[330px] w-full max-w-[330px] rounded-r10 object-center shadow-modal'
            />
          ))}
          {!isDisabledViewer && isImageViewerOpen && (
            <ImageViewer
              imageObjects={images}
              selectedIndex={selectedIndex}
              imageUrls={images.map((image) => image.url)}
              onClose={(e) => {
                setIsImageViewerOpen(false);
                e?.stopPropagation();
                e?.preventDefault();
              }}
              proxyCallback={switchProxyCallback(roomType)}
            />
          )}
        </div>
      );
    }
    default: {
      const firstImage = aws[0];
      const secondImage = aws[1];
      return (
        <div className='flex w-full flex-wrap justify-evenly' style={imageWidthStyleSameTextStyle}>
          <div className='relative mr-4 max-h-[150px] w-[calc(50%-4px)] max-w-[160px] overflow-hidden rounded-r10'>
            {isExpired ? (
              <ExpiredImageMock />
            ) : (
              <img
                src={firstImage.Location}
                alt={firstImage.fileName}
                onClick={() => {
                  handleImageOpen(0);
                }}
                className='h-full w-full cursor-pointer object-cover object-center'
              />
            )}
          </div>
          <div className='relative max-h-[150px] w-[calc(50%-4px)] max-w-[160px] overflow-hidden rounded-r10'>
            {isExpired ? (
              <ExpiredImageMock isFirst={false} />
            ) : (
              <img
                src={secondImage.Location}
                alt={secondImage.fileName}
                onClick={() => {
                  handleImageOpen(1);
                }}
                className='h-full w-full cursor-pointer object-cover object-center'
              />
            )}

            <img
              src={secondImage.Location}
              alt={secondImage.fileName}
              onClick={() => {
                handleImageOpen(1);
              }}
              className='h-full w-full cursor-pointer object-cover object-center'
            />
            {imagesLength > 2 && (
              <MultiImageCover
                isExpired={isExpired}
                isMocking={isMocking}
                imagesLength={imagesLength}
                handleImageOpen={handleImageOpen}
              />
            )}
          </div>
          {!isDisabledViewer && !isExpired && isImageViewerOpen && (
            <ImageViewer
              imageObjects={images}
              selectedIndex={selectedIndex}
              imageUrls={images.map((image) => image.url)}
              onClose={(e) => {
                setIsImageViewerOpen(false);
                e?.stopPropagation();
                e?.preventDefault();
              }}
              proxyCallback={switchProxyCallback(roomType)}
            />
          )}
        </div>
      );
    }
  }
}
