import { INSTAGRAM_LOGIN_REDIRECT_URL } from 'afterdoc-saas-web/config/url';

const CLIENT_ID = '478312678670059';

export const handleInstagramLogin = (hospitalID: string) => {
  const INSTAGRAM_AUTH_URL = `https://api.instagram.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${INSTAGRAM_LOGIN_REDIRECT_URL}&response_type=code&scope=instagram_business_basic,instagram_business_manage_messages&state=${hospitalID}`;

  const width = 782;
  const height = 859;
  const left = window.screenX + (window.innerWidth - width) / 2;
  const top = window.screenY + (window.innerHeight - height) / 2;

  window.open(
    INSTAGRAM_AUTH_URL,
    '_blank',
    `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`,
  );
};
