import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { filterWrapperClassName } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableHeader/constants/class';
import { forwardRef } from 'react';

const IsFirstVisitFilter = forwardRef<
  HTMLDivElement,
  {
    value: boolean | 'all' | undefined;
    onValueChange?: (value: boolean | 'all' | undefined) => void;
  }
>((props, ref) => {
  const handleCheckboxChange = (type: '초진' | '재진', checked: boolean) => {
    const isFirstVisitChecked = type === '초진' ? checked : props.value === true;
    const isRevisitChecked = type === '재진' ? checked : props.value === false;

    if (isFirstVisitChecked && !isRevisitChecked) {
      props.onValueChange?.(true);
    } else if (!isFirstVisitChecked && isRevisitChecked) {
      props.onValueChange?.(false);
    } else if (isFirstVisitChecked && isRevisitChecked) {
      props.onValueChange?.('all');
    } else {
      props.onValueChange?.(undefined);
    }
  };

  return (
    <div className={`${filterWrapperClassName} w-[188px]`} ref={ref}>
      <div className='p-16'>
        <div className='mb-10 text-Caption9 text-black200'>
          *체크한 값에 해당하는 고객만 표시됩니다.
        </div>
        <Checkbox
          label={
            <FilledTag className='cursor-pointer' tagSize='small'>
              초진
            </FilledTag>
          }
          checked={props.value === true || props.value === 'all'}
          onChange={(e) => handleCheckboxChange('초진', e.target.checked)}
        />
        <Checkbox
          label={
            <FilledTag className='cursor-pointer' tagSize='small'>
              재진
            </FilledTag>
          }
          checked={props.value === false || props.value === 'all'}
          onChange={(e) => handleCheckboxChange('재진', e.target.checked)}
        />
      </div>
    </div>
  );
});

export default IsFirstVisitFilter;
