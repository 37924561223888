import { KAKAO_PROXY_URL } from '@config/url';
import { neverCheck } from 'afterdoc-saas-web/shared/utils/never-check';
import { tokenAtom } from 'afterdoc-saas-web/states/token';
import { ROOM_TYPE_MAP } from 'afterdoc-saas-web/templates/CustomerChat/constants/ROOM_TYPE_MAP';
import type { RoomType } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';

export const useImageProxyCallback = () => {
  const token = useAtomValue(tokenAtom);

  const proxyKakaoCallback = useCallback(
    async (url: string) => {
      return await fetch(KAKAO_PROXY_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ url }),
      });
    },
    [token],
  );
  const switchProxyCallback = (roomType: RoomType | null) => {
    if (roomType === null) throw new Error('Room type is null');

    switch (roomType) {
      case ROOM_TYPE_MAP.kakao:
        return proxyKakaoCallback;
      case ROOM_TYPE_MAP.instagram:
      case ROOM_TYPE_MAP.afterdoc:
      case ROOM_TYPE_MAP.manager:
        return undefined;
      default:
        roomType satisfies never;
        neverCheck(roomType);
    }
  };

  return { switchProxyCallback };
};
