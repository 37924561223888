import type { V2Reservation } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { generateTexMessageAfterReservation } from '../functions/generate-text-message-after-reservation';

type Payload<T> = {
  value: T;
};

interface V2ReservationWithType extends V2Reservation {
  type: 'create' | 'update' | 'remove';
  startedAt: string;
}

export const useReservationListener = (
  setText: React.Dispatch<React.SetStateAction<string>>,
  isUsageHaltedDisabledSending: boolean,
) => {
  useEffect(() => {
    const formatDate = (date: string) => dayjs(date).format('YYYY년 M월 D일 (ddd) A HH:mm');

    const handleCreateReservationDone = (
      _: Record<string, never>,
      payload: Payload<V2ReservationWithType>,
    ) => {
      if (isUsageHaltedDisabledSending) return;

      const reservation = payload.value;
      const startDateString = formatDate(reservation?.startedAt);
      const message = generateTexMessageAfterReservation(reservation.type, startDateString);
      setText(message);
    };

    window.electron?.ipcRenderer.on(
      'Application.doneUpdateReservation',
      handleCreateReservationDone,
    );

    return () => {
      window.electron?.ipcRenderer.removeListener(
        'Application.doneUpdateReservation',
        handleCreateReservationDone,
      );
    };
  }, [isUsageHaltedDisabledSending, setText]);
};
