import { useQueryClient } from '@tanstack/react-query';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import Toggle from 'afterdoc-design-system/components/Atoms/Toggle/Toggle';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import { isSendingTranslateState } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/states/is-sending-translate';
import { chattingRequestState } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/states/chatting-request';
import { isSettingBottomTranslateLanguageState } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/states/is-setting-sending-translate-language';
import { useIsDisabledChat } from 'afterdoc-saas-web/templates/CustomerChat/hooks/use-is-disabled-chat';
import type { ChatRoomID } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { postTranstranslate } from './apis/post-translate';
import { useChatTranslationToggle } from './hooks/use-chat-translation-toggle';

export default function TranslateToggle({ chatRoomID }: { chatRoomID: string }) {
  const queryClient = useQueryClient();

  const setIsSettingTranslateLanguage = useSetAtom(isSettingBottomTranslateLanguageState);
  const resetIsSendingTranslate = useResetAtom(isSendingTranslateState);
  const resetReq = useResetAtom(chattingRequestState);

  const { isUsageHaltedDisabledSending } = useIsDisabledChat({ chatRoomID });
  const { isActivatingChatTranslate, setIsActivatingChatTranslate } = useChatTranslationToggle({
    chatRoomID,
  });

  const handleToggle = async (chatRoomID: ChatRoomID) => {
    if (!chatRoomID) return;
    if (isUsageHaltedDisabledSending) return;

    resetIsSendingTranslate();
    resetReq();

    const newIsChatTranslate = !isActivatingChatTranslate;

    setIsActivatingChatTranslate(newIsChatTranslate);
    setIsSettingTranslateLanguage(newIsChatTranslate);

    try {
      await postTranstranslate({
        chatRoomID,
        usingTranslation: !!newIsChatTranslate,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiChatroomElFindOne, { chatRoomID }],
      });

      // 이 부분은 비즈니스 로직에 따라 useChatTranslationToggle 안으로 이동했습니다.
      // toastService.successMsg({ text: `번역을 ${newIsChatTranslate ? '시작' : '종료'}합니다` });
    } catch (err) {
      console.error('trans err', err);
      toastService.errorMsg({ text: '번역기능을 사용할 수 없습니다.' });
      setIsSettingTranslateLanguage(false);
    }
  };

  return (
    <div className='relative flex items-center'>
      <HoverTooltip
        show={true}
        text='자동번역 기능은 베타 버전으로 일부 번역 결과에 오류가 있을 수 있습니다.'
        position='bottomRight'
        offsetX={-108}
        className='w-[193px]'
        wrapperProps={{
          className: 'cursor-default',
        }}>
        <div
          className='absolute left-[-12px] h-12 w-21 text-purple500 italic '
          style={{
            transform: 'translateY(-120%)',
            fontSize: '12px',
            fontFamily: 'Gogh, sans-serif',
            fontWeight: 800,
          }}>
          Beta
        </div>
        <div className='w-50 flex-shrink-0 justify-center text-Body10Bold text-black500'>
          자동번역
        </div>
      </HoverTooltip>
      <Toggle
        onChange={() => handleToggle(chatRoomID)}
        checked={isActivatingChatTranslate}
        defaultChecked={isActivatingChatTranslate}
      />
    </div>
  );
}
