import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { MessageDialogContent } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/Message/components/MessageDialogContent';
import SelectedCheckboxInImg from 'afterdoc-saas-web/templates/CustomerChat/components/Manual/components/components/SelectedCheckboxInImg';
import { getDetailEvent } from '../apis/get-detail-event';
import { getDetailHAftercare } from '../apis/get-detail-haftercare';
import { calculatePrice } from '../functions/calculate-price';
import type { EventData } from '../types';
import EventDevicePopup from './EventDevicePopup';

type HomeCareProductAndEventItemProps = {
  eventData: EventData;
  toggleSelectItem: (e: React.MouseEvent<HTMLDivElement>, id: string) => void;
  isSelected: boolean;
  isAbleSelect: boolean;
  categoryTitle: string;
};

export default function HomeCareProductAndEventItem({
  eventData,
  toggleSelectItem,
  isSelected,
  isAbleSelect,
  categoryTitle,
}: HomeCareProductAndEventItemProps) {
  const { id, url, title, price, salePrice, saleRate, saleType } = eventData;

  return (
    <div key={id} className='flex w-[160px] flex-col'>
      <div
        key={url}
        onClick={(e) => isAbleSelect && toggleSelectItem(e, id)}
        className={`relative inline-block h-[160px] rounded-r10 ${isAbleSelect && 'cursor-pointer'} overflow-hidden border ${
          isSelected ? 'border-blue500' : 'border-white600'
        } ${!isAbleSelect && 'opacity-50'}`}>
        {isAbleSelect && <SelectedCheckboxInImg isSelected={isSelected} />}
        <img
          src={url ?? ''}
          alt='이벤트 이미지'
          className='h-[170px] w-[170px] rounded-r10 object-cover'
        />
        <div
          className='absolute right-2 bottom-2 flex h-32 w-32 flex-center cursor-pointer bg-black200'
          onClick={async (e) => {
            e.stopPropagation();

            const dynamicFunction =
              categoryTitle === '이벤트' ? getDetailEvent : getDetailHAftercare;
            const {
              title,
              content,
              fileIDs,
              description,
              isLimitedTime,
              endDate,
              type,
              saleType,
              salePrice,
              saleRate,
              price,
            } = await dynamicFunction(id);
            const detailInfoImageSrc = fileIDs?.[0]?.paths?.[0];

            dialogService.push(
              <MessageDialogContent isDisabledScrollBar={true}>
                <EventDevicePopup
                  title={title ?? ''}
                  description={description ?? ''}
                  content={content ?? ''}
                  isLimitedTime={isLimitedTime ?? false}
                  endDate={endDate ?? ''}
                  thumbnailSrc={url ?? ''}
                  type={type ?? ''}
                  detailInfoImageSrc={detailInfoImageSrc ?? ''}
                  saleType={saleType ?? ''}
                  salePrice={salePrice ?? 0}
                  saleRate={saleRate ?? 0}
                  price={price ?? 0}
                />
              </MessageDialogContent>,
              {
                hasCloseButton: true,
                titleProps: {
                  title: '상세내용',
                },
              },
            );
          }}
          style={{ borderRadius: '6px' }}>
          <Icon name='magnify-scan' size={20} color='white50' />
        </div>
      </div>
      <div className='my-10'>
        <span className='line-clamp-1 h-36 text-Header12 text-black500'>{title}</span>
        <span className='line-clamp-1 text-Body11 text-black200 line-through'>
          {price.toLocaleString()}
        </span>
        <span className='line-clamp-1 text-Header12 text-blue500'>
          {calculatePrice(price, salePrice, saleRate, saleType)}
        </span>
      </div>
    </div>
  );
}
