import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { UNSAVED_CHANGES_WARNING_ID } from 'afterdoc-saas-web/shared/hooks/gnb/use-navigation-blocker';
import { isEqual } from 'lodash-es';

interface UnsavedChangesWarningOptions<TFormValues> {
  title?: string;
  contents?: string;
  formMethods: {
    isDirty?: boolean;
    originalValues?: TFormValues;
    getValues: () => TFormValues;
    watch: () => TFormValues;
  };
  onReset?: () => void;
  onConfirm?: () => void;
  onCancel: () => void;
}

export function useUnsavedChangesWarning<TFormValues>(
  options: UnsavedChangesWarningOptions<TFormValues>,
) {
  const { formMethods, title, contents, onConfirm, onCancel, onReset } = options;
  const { isDirty, getValues, originalValues } = formMethods;

  const showWarning = () => {
    if (!!isDirty || !isEqual(getValues(), originalValues)) {
      modalService.defaultWarning({
        id: UNSAVED_CHANGES_WARNING_ID,
        title: title || '저장하지 않고 나가시겠어요?',
        contents: contents || '화면을 이동할 경우 입력한 내용이 사라집니다.',
        onConfirm: () => {
          onReset?.();
          onConfirm?.();
        },
        onCancel,
      });
    } else {
      onReset?.();
      onConfirm?.();
    }
  };

  return { showWarning };
}
