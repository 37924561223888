import type { UserNameAndType } from '@apis/swaggers/swagger-docs';
import { displayParticipantsParticipantsChip } from '@templates/CustomerChat/components/ChattingList/components/ChattingListBody/components/ChattingListItem/functions/display-participants-in-participants-chip';
import type { ActiveDropdown } from '@templates/CustomerChat/components/ChattingList/types/active-dropdown';
import { WIDTH } from '@templates/CustomerChat/components/ParentDropDown/constants/dropdown';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { isAfterdocMember } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingList/functions/is-afterdoc-member';
import ChildDropdown from 'afterdoc-saas-web/templates/CustomerChat/components/ParentDropDown/components/ChildDropdown/ChildDropdown';
import { type MouseEvent, useCallback, useMemo, useRef } from 'react';

type ParticipantsChipProps = {
  title?: string;
  counselorID: string | undefined;
  eachChattingRoomID: string;
  counselors: UserNameAndType[] | undefined;
  isNextDropdownOpen: boolean;
  onHandleDropdown: (activeDropdown: ActiveDropdown, isActive: boolean) => void;
};

const POSITION_OFFSET = 410;
const POSITION_DEFUALT = -50;

export default function ParticipantsChip({
  title,
  eachChattingRoomID,
  counselors,
  isNextDropdownOpen,
  onHandleDropdown,
}: ParticipantsChipProps) {
  const { userId: userID, authorizationTypeID } = useUserInfo();

  const isInCounselors = counselors?.some((counselor) => counselor._id === userID);
  const isDisabled = !isInCounselors && !authorizationTypeID?.canControlPatientChatting;

  //ParticipantsChip에는 담당자들과 관련된 표시만 하고 ChildDropdown에는 모든 유저의 정보가 필요함.
  //그러나 하위 자식인 ChildDropdown는 여러 곳에서 불러옴,
  //그래서 props가 아닌 독립적인 participants 확보가 필요.
  //ChildDropdown는 그 안에서 별도 api를 불러와서 users 정보를 확보함.
  const onlyCounselorsNotMedipaler = counselors?.filter(
    (counselor) => !isAfterdocMember(counselor),
  );

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleDropdown = useCallback(
    (event?: MouseEvent) => {
      if (isDisabled) return;
      event?.stopPropagation();
      onHandleDropdown({ key: 'children', chatRoomID: eachChattingRoomID }, !isNextDropdownOpen);
    },
    [isNextDropdownOpen],
  );

  const triggerPosition = useMemo(() => {
    if (isNextDropdownOpen && !isDisabled) {
      const parentRect = dropdownRef.current?.getBoundingClientRect();
      const left = parentRect ? POSITION_OFFSET - parentRect.left - 70 : POSITION_DEFUALT;
      return { top: 5, left };
    }
    return { top: 0, left: WIDTH };
  }, [isNextDropdownOpen]);

  return (
    <div
      className={`h-24 min-w-[66px] max-w-[120px] rounded-[12px] border bg-white50 px-6 py-4 ${
        isDisabled ? 'cursor-not-allowed border-disabled' : 'border-blue100'
      }`}>
      <div
        className={`ml-4 flex h-full items-center justify-end gap-3 ${
          isDisabled ? 'cursor-not-allowed text-gray500' : 'cursor-pointer'
        }`}
        onClick={handleDropdown}
        ref={dropdownRef}>
        <span
          className={`line-clamp-1 text-Body11 ${isDisabled ? 'text-disabled' : 'text-blue500'}`}>
          {displayParticipantsParticipantsChip(onlyCounselorsNotMedipaler)}
        </span>
        <IconButton
          icon={isNextDropdownOpen ? 'chevron-up' : 'chevron-down'}
          size={16}
          color={isDisabled ? 'disabled' : 'blue500'}
        />
      </div>
      {!isDisabled && isNextDropdownOpen && (
        <ChildDropdown
          selectedName={title}
          triggerPosition={triggerPosition}
          handleToggle={handleDropdown}
          isOnMouseLeave={false}
          eachChattingRoomID={eachChattingRoomID}
        />
      )}
    </div>
  );
}
