import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiPatientsElCountParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useOpenLeaveWithoutSavingModal } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/hooks/use-open-leave-without-saving-modal';
import { selectedSearchCriteriaState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { TABLE_TAB_ITEMS } from '../constants/tab-items';
import { tabsState } from '../states/tabs';

const fetchAllPatientsCount = async (params: ApiPatientsElCountParams) => {
  const response = await apiClient.v3.apiPatientsElCount(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function TableCustomTabs() {
  const { hospitalID } = useSelectedHospitalInfo();

  const resetSelectedSearchCriteria = useResetAtom(selectedSearchCriteriaState);
  const [selectedTab, setSelectedTab] = useAtom(tabsState);

  const [tabItems, setTabItems] = useState(TABLE_TAB_ITEMS);

  const { openLeaveWithoutSavingModal } = useOpenLeaveWithoutSavingModal();
  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiPatientsElCount, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchAllPatientsCount(queryKey[1]),
    staleTime: 0,
    gcTime: 0,
  });

  const handleTab = (value: (typeof TABLE_TAB_ITEMS)[number]['value']) => {
    if (selectedTab === value) return;

    openLeaveWithoutSavingModal({
      successCb: () => {
        resetSelectedSearchCriteria();
        setSelectedTab(value);
      },
      settledCb: () => {
        setSelectedTab(value);
        resetSelectedSearchCriteria();
      },
    });
  };

  useEffect(() => {
    const updatedTabItems = TABLE_TAB_ITEMS.map((item, index) =>
      index === 1 ? { ...item, count: data?.count } : item,
    );
    setTabItems(updatedTabItems);
  }, [data]);

  return (
    <div className='flex w-fit whitespace-nowrap text-Header16'>
      {tabItems.map((item) => (
        <div
          key={item.value}
          onClick={() => handleTab(item.value)}
          className={customTwMerge(
            selectedTab === item.value ? 'text-black700' : 'text-black200',
            'flex cursor-pointer items-center gap-10 py-8 pr-16 pl-20',
          )}>
          {item.label}
          {item.count !== undefined && (
            <div className='text-Body12 text-black200'>{item.count.toLocaleString()}</div>
          )}
        </div>
      ))}
    </div>
  );
}
