import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiAutomationsElAlgorithmsMessagesParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { safeStringify } from 'afterdoc-saas-web/shared/utils/safe-stringify';
import type { MARKETING_MESSAGE_SETTING_TAB_ITEMS } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/constants/tab';
import { originAlgorithmDataState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/origin-algorithm-data';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import type { MarketingAutomationAPIFormValues } from '../components/RegisterMarketingAutomationDialogContent';
import { algorithmModeState } from '../containers/MarketingMessageSetting/states/algorithm-mode';
import {
  selectedContentTabItemState,
  selectedMessageState,
} from '../containers/MarketingMessageSetting/states/message-form';
import { originMessagesState } from '../containers/MarketingMessageSetting/states/origin-message';
import { useSingleAlgorithmInfo } from './use-single-algorithm-info';

const fetchAutomationsElAlgorithmsMessages = async (
  params: ApiAutomationsElAlgorithmsMessagesParams,
) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessages(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useAlgorithmData = () => {
  const isFirstRender = useRef(true);

  const { getValues, setValue } = useFormContext<MarketingAutomationAPIFormValues>();
  const { hospitalID } = useSelectedHospitalInfo();

  const algorithmMode = useAtomValue(algorithmModeState);
  const setSelectedContentTabItem = useSetAtom(selectedContentTabItemState);
  const setOriginMessages = useSetAtom(originMessagesState);
  const setSelectedMessage = useSetAtom(selectedMessageState);
  const setOriginAlgorithmData = useSetAtom(originAlgorithmDataState);

  const { singleAlgorithmInfo } = useSingleAlgorithmInfo();

  const { data: algorithmMessagesData } = useQuery({
    queryKey: [
      QUERY_KEY.apiAutomationsElAlgorithmsMessages,
      {
        algorithmId: algorithmMode.mode !== 'CREATE' ? algorithmMode.algorithmId : '',
        hospitalID,
      },
    ] as const,
    queryFn: ({ queryKey }) => {
      if (algorithmMode.mode !== 'CREATE') {
        return fetchAutomationsElAlgorithmsMessages(queryKey[1]);
      }
    },
    enabled: algorithmMode.mode !== 'CREATE',
    select: (data) =>
      data?.map((message) => ({
        ...message,
        canBeSentToFriendTalk: message.canBeSentToFriendTalk ?? false,
      })),
  });

  const messagesData: MarketingAutomationAPIFormValues['messages'] = useMemo(
    () =>
      algorithmMessagesData?.map(
        ({ id, kakaoTemplateHistoryId, name, content, sendingDateTime, createdAt, updatedAt }) => ({
          id,
          kakaoTemplateHistoryId,
          name,
          sendingType: 'BASIC' as const,
          sendingDateTime,
          content: {
            text: content?.text,
            image: content?.images
              ? safeStringify(
                  content.images.map((image) => ({
                    id: content.imageFileID,
                    url: image,
                  })),
                )
              : undefined,
          },
          createdAt,
          updatedAt,
        }),
      ) || [],
    [algorithmMessagesData],
  );

  const handleContentTabItem = useCallback(() => {
    const newContentTabItem: Record<string, (typeof MARKETING_MESSAGE_SETTING_TAB_ITEMS)[number]> =
      {};

    for (const message of messagesData) {
      if (message.content.image && message.content.image.length > 0) {
        newContentTabItem[message.id] = '이미지';
      } else {
        newContentTabItem[message.id] = '텍스트';
      }
    }

    setSelectedContentTabItem((prev) => ({
      ...prev,
      ...newContentTabItem,
    }));
  }, [messagesData, setSelectedContentTabItem]);

  useEffect(() => {
    if (!isFirstRender.current) return;
    if (!singleAlgorithmInfo || !algorithmMessagesData || !messagesData) return;

    const setBaseData = () => {
      const {
        name,
        tagsHaveFolder,
        tagsHaveNoFolder,
        sendingSchedule,
        appliedNationalities,
        isForNoNationality,
        isForNoTreatmentTags,
      } = singleAlgorithmInfo;

      setValue('name', name);
      setValue('sendingSchedule.startDay', sendingSchedule?.startDay ?? dayjs().toISOString());
      setValue('sendingSchedule.sendingCount', sendingSchedule?.sendingCount ?? 1);
      setValue('isForNoNationality', isForNoNationality);
      setValue('isForNoTreatmentTags', isForNoTreatmentTags);
      setValue('targetTreatmentTags', [
        ...tagsHaveFolder.flatMap(({ tags }) => tags.map((tag) => tag._id)),
        ...tagsHaveNoFolder.map((tag) => tag._id),
      ]);
      setValue(
        'toBeAppliedNationalityIds',
        appliedNationalities.map((appliedNationality) => appliedNationality._id),
      );
      setOriginAlgorithmData({
        ...getValues(),
        name: name,
        sendingSchedule: {
          sendingCount: sendingSchedule?.sendingCount ?? 1,
          startDay: sendingSchedule?.startDay ?? dayjs().toISOString(),
        },
        isForNoNationality: isForNoNationality,
        isForNoTreatmentTags: isForNoTreatmentTags,
        targetTreatmentTags: tagsHaveFolder.flatMap(({ tags }) => tags.map((tag) => tag._id)),
        toBeAppliedNationalityIds: appliedNationalities.map(
          (appliedNationality) => appliedNationality._id,
        ),
        messages: messagesData,
      });
    };

    const setMessagesData = () => {
      setOriginMessages(algorithmMessagesData);
      setValue('messages', messagesData);
      setSelectedMessage({
        ...messagesData[0],
        id: messagesData[0].id,
        index: 0,
      });
    };

    if (algorithmMode.mode === 'EDIT' || algorithmMode.mode === 'COPY') {
      isFirstRender.current = false;

      // 기본 설정
      setBaseData();
      // 메시지 설정
      setMessagesData();
      // 각 메시지들이 선택해야할 탭 설정
      handleContentTabItem();
    }
  }, [singleAlgorithmInfo, algorithmMessagesData, algorithmMode.mode, handleContentTabItem]);
};
