import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import InstagramLinkApply from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/ChannelLinkageOverlay/components/InstagramLinkApply';
import { useEffect, useState } from 'react';

export default function InstagramDescription({ removeOverlay }: { removeOverlay: () => void }) {
  const [isApplyDone, setIsApplyDone] = useState(false);

  //TODO: 더미 데이터를 활용하기 위한 모킹
  useEffect(() => {
    if (isApplyDone) {
      setTimeout(() => {
        removeOverlay();
      }, 2000);
    }
  }, [isApplyDone]);

  const handleClick = () => {
    dialogService.push(<InstagramLinkApply setIsApplyDone={setIsApplyDone} />, {
      titleProps: {
        title: '인스타그램 연동 신청',
      },
      wrapperClassName: 'h-[240px] w-[458px]',
    });
  };
  return (
    <>
      {isApplyDone ? (
        <>
          <h2 className='text-Header14 text-black500'>인스타그램 연동 신청 완료</h2>
          <span className='mt-12 text-center text-Body12 text-black500'>
            신청되었습니다.
            <br />
            담당자 확인 후 연락드리겠습니다.
          </span>
        </>
      ) : (
        <>
          <h2 className='text-Header14 text-black500'>인스타그램 (유료)</h2>
          <span className='mt-12 whitespace-pre-wrap text-center text-Body12 text-black500'>
            인스타그램 연동은 <span className='text-red500'>유료 기능</span>으로 연동신청을 먼저
            진행해주세요.
            <br />
            설정을 원하시면 고객센터에 문의하세요.
          </span>
          <ContainedButton btnColor='blue' className='mt-20' onClick={handleClick}>
            연동신청
          </ContainedButton>
        </>
      )}
    </>
  );
}
