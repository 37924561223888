import { API_URL } from '@config/url';

const HOST = 'https://reservation.api.receipt.smartdoctor.systems/reservation-api';

export const FIND_USER_NAME = `${HOST}/v2/customers`;
export const FIND_USER_CUSTOMER_NUMBER = `${FIND_USER_NAME}/{customerNumber}`;

export const FIND_USER_ALCUSTOMER_NUMBER = `${API_URL}/v2/api/si/al/hservice/el/find/simple`;
export const UPDATE_HSERVICE_CUSTOMER_NUMBER = `${API_URL}/v2/api/hservice/el/update`;

export const FIND_RESERVATIONS = `${HOST}/v2/reservations`;
export const FIND_RESERVATIONS_CUSTOMER_NUMBER = `${HOST}/v2/customers/{customerNumber}/reservations`;

export const FIND_USERS_RESERVATION = `${HOST}/users/reservation`;
export const FIND_USERS = `${HOST}/users`;
