import { useClickOutside } from '@shared-hooks/use-click-outside';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import type { ApiServiceSettingsElToBeDisplayedPatientsFieldsData } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import {
  type EditableHeaderFilterState,
  editableHeaderFilterState,
} from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/editable-header-filter';
import GenderFilter from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/Filters/GenderFilter';
import IsFirstVisitFilter from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/Filters/IsFirstVisitFilter';
import NationalityFilter from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/Filters/NationalityFilter';
import TreatmentTagFilter from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/Filters/TreatmentTagFilter';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, memo, useMemo, useRef, useState } from 'react';
import {
  EDITABLE_TABLE_HEADERS,
  EDITABLE_TABLE_HEADERS_WITH_FILTER,
  REQUIRED_HEADERS,
} from './constants/table';

type CustomerManagementEditableTableHeaderProps =
  ApiServiceSettingsElToBeDisplayedPatientsFieldsData['data'];

const convertTableHeaderKRToEN = (
  header: (typeof EDITABLE_TABLE_HEADERS_WITH_FILTER)[number],
): keyof EditableHeaderFilterState => {
  switch (header) {
    case '초/재진':
      return 'isFirstVisit';
    case '성별':
      return 'gender';
    case '치료태그':
      return 'treatmentCategories';
    case '국적':
      return 'nationality';
  }
};

const CustomerManagementEditableTableHeader = ({
  ...usingColumns
}: CustomerManagementEditableTableHeaderProps) => {
  const editableHeaderFilter = useAtomValue(editableHeaderFilterState);

  const [selectedFilter, setSelectedFilter] = useState<
    (typeof EDITABLE_TABLE_HEADERS_WITH_FILTER)[number] | null
  >(null);

  const {
    usingChartNumber,
    usingFirstVisitStartDate,
    usingBirthDay,
    usingGender,
    usingTreatmentTag,
    usingNationality,
  } = usingColumns;

  const { isTest: usingHasToBlockSendingMessage } = useSelectedHospitalInfo();

  const headers = useMemo(
    () =>
      EDITABLE_TABLE_HEADERS.filter((header) => {
        if (usingChartNumber && header === '차트번호') return true;
        if (usingFirstVisitStartDate && header === '초/재진') return true;
        if (usingBirthDay && header === '생년월일') return true;
        if (usingGender && header === '성별') return true;
        if (usingNationality && header === '국적') return true;
        if (usingTreatmentTag && header === '치료태그') return true;
        if (usingHasToBlockSendingMessage && header === '수신거부') return true;

        if (header === 'No') return true;
        if (header === '고객명') return true;
        if (header === '휴대폰번호') return true;

        return false;
      }),
    [
      usingChartNumber,
      usingFirstVisitStartDate,
      usingBirthDay,
      usingGender,
      usingNationality,
      usingTreatmentTag,
      usingHasToBlockSendingMessage,
    ],
  );

  return (
    <>
      <colgroup>
        <col className={usingHasToBlockSendingMessage ? 'w-[40px]' : 'w-[60px]'} />
        {usingHasToBlockSendingMessage && <col className='w-[75px]' />}
        <col className='w-[260px]' />
        {usingChartNumber && <col className='w-[140px]' />}
        {usingFirstVisitStartDate && <col className='w-[120px]' />}
        <col className='w-[130px]' />
        {usingBirthDay && <col className='w-[130px]' />}
        {usingGender && <col className='w-[90px]' />}
        {usingTreatmentTag && <col className='min-w-[150px]' />}
        {usingNationality && <col className='w-[160px]' />}
      </colgroup>
      <thead className='sticky top-0 left-0 z-[1]'>
        <tr>
          {headers.map((header) => {
            const hasFilter = EDITABLE_TABLE_HEADERS_WITH_FILTER.some((filterHeader) =>
              header.includes(filterHeader),
            );
            const filterHasValue =
              editableHeaderFilter[
                convertTableHeaderKRToEN(
                  EDITABLE_TABLE_HEADERS_WITH_FILTER.find((filterHeader) =>
                    header.includes(filterHeader),
                  ) as (typeof EDITABLE_TABLE_HEADERS_WITH_FILTER)[number],
                )
              ] !== undefined;

            const isRequired = REQUIRED_HEADERS.includes(
              header as (typeof REQUIRED_HEADERS)[number],
            );

            return (
              <th
                key={header}
                className='whitespace-nowrap border-line-table border-r bg-table px-12 py-10 text-left text-Header14 text-black700'>
                <div
                  className={customTwMerge(
                    hasFilter ? 'flex w-full items-center justify-between' : '',
                  )}>
                  {isRequired ? (
                    <LabelText textClassName='text-Header14 text-black700' isRequired>
                      {header}
                    </LabelText>
                  ) : (
                    header
                  )}
                  {hasFilter && (
                    <div className='relative h-16 w-16'>
                      <IconButton
                        onClick={() =>
                          setSelectedFilter(
                            header as (typeof EDITABLE_TABLE_HEADERS_WITH_FILTER)[number],
                          )
                        }
                        icon='filter-outline'
                        color={filterHasValue ? 'blue500' : 'black200'}
                        size={20}
                      />
                      {selectedFilter === header && (
                        <Suspense>
                          <Filter
                            selectedFilter={selectedFilter}
                            onChangeSelectedFilter={setSelectedFilter}
                          />
                        </Suspense>
                      )}
                    </div>
                  )}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
    </>
  );
};

const Filter = ({
  selectedFilter,
  onChangeSelectedFilter,
}: {
  selectedFilter: (typeof EDITABLE_TABLE_HEADERS_WITH_FILTER)[number];
  onChangeSelectedFilter?: (
    filter: (typeof EDITABLE_TABLE_HEADERS_WITH_FILTER)[number] | null,
  ) => void;
}) => {
  const [editableHeaderFilter, setEditableHeaderFilter] = useAtom(editableHeaderFilterState);
  const filterRef = useRef<HTMLDivElement>(null);

  const handleFirstVisitFilterChange = (value: boolean | 'all' | undefined) => {
    setEditableHeaderFilter((prev) => ({
      ...prev,
      isFirstVisit: value,
    }));
  };

  const handleGenderFilterChange = (value: 'MALE' | 'FEMALE' | 'all' | undefined) => {
    setEditableHeaderFilter((prev) => ({
      ...prev,
      gender: value,
    }));
  };

  const handleTreatmentTagFilterChange = (value: string | undefined) => {
    setEditableHeaderFilter((prev) => ({
      ...prev,
      treatmentCategories: value,
    }));
  };

  const handleNationalityFilterChange = (value: string | undefined) => {
    setEditableHeaderFilter((prev) => ({
      ...prev,
      nationality: value,
    }));
  };

  useClickOutside({
    id: `${selectedFilter}-filter`,
    ref: filterRef,
    contentRef: filterRef,
    onClose: () => {
      onChangeSelectedFilter?.(null);
    },
  });

  if (selectedFilter === '초/재진') {
    return (
      <IsFirstVisitFilter
        value={editableHeaderFilter.isFirstVisit}
        ref={filterRef}
        onValueChange={handleFirstVisitFilterChange}
      />
    );
  }

  if (selectedFilter === '성별') {
    return (
      <GenderFilter
        value={editableHeaderFilter.gender}
        ref={filterRef}
        onValueChange={handleGenderFilterChange}
      />
    );
  }

  if (selectedFilter === '치료태그') {
    return (
      <TreatmentTagFilter
        value={editableHeaderFilter.treatmentCategories}
        ref={filterRef}
        onValueChange={handleTreatmentTagFilterChange}
      />
    );
  }

  if (selectedFilter === '국적') {
    return (
      <NationalityFilter
        value={editableHeaderFilter.nationality}
        ref={filterRef}
        onValueChange={handleNationalityFilterChange}
      />
    );
  }
  return null;
};

export default memo(CustomerManagementEditableTableHeader);
