import BoardPanelContainer from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/BoardPanel.container';
import ControlPanelContainer from 'afterdoc-saas-web/templates/CustomerManagement/containers/ControlPanel/ControlPanel.container';

export default function CustomerManagementContainer() {
  return (
    <main className='grid h-full grid-cols-[360px,1fr]'>
      {/* 좌측 고객 관리 컨트롤 패널 */}
      <ControlPanelContainer />
      {/* 우측 고객 관리 보드 패널 */}
      <BoardPanelContainer />
    </main>
  );
}
