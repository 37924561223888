import { useServiceSettings } from '@shared/hooks/etc/use-serivce-settings';
import { useUserInfo } from '@shared/hooks/info/use-user-info';
import type {
  UpdateSingleManualAPIFormValues
} from '@templates/HospitalManual/components/HospitalManualContent/HospitalManualContent';
import {
  CautionStatusInfo
} from '@templates/HospitalManual/components/HospitalManualContent/components/ManualCautionMessage/components/CautionStatusInfo';
import {
  CautionTextArea
} from '@templates/HospitalManual/components/HospitalManualContent/components/ManualCautionMessage/components/CautionTextArea';
import {
  CautionToggleSection
} from '@templates/HospitalManual/components/HospitalManualContent/components/ManualCautionMessage/components/CautionToggleSection';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  InspectionStatus
} from '@templates/HospitalManual/components/HospitalManualContent/components/ManualCautionMessage/types/inspection-status';
import {
  keyToStatusTypeMap
} from "@templates/HospitalManual/components/HospitalManualContent/components/ManualCautionMessage/constants/key-to-status-type-map";

const TITLE = '주의사항 메세지';
const SUB_TITLE =
  '설정된 주의사항은 치료태그가 입력된 고객에게 자동발송됩니다.\n' +
  '알림톡을 연동하신 경우, 알림톡 승인이 완료되기 전까지 애프터닥 앱에 가입한 사람들에게만 메시지가 전송됩니다.';

interface ManualCautionMessageProps {
  tagId: string;
  defaultCautionMessage?: string;
  defaultUsingCautionMessage?: boolean;
  inspectionStatus: InspectionStatus | null;
}

export const ManualCautionMessage = ({
  tagId,
  defaultCautionMessage,
  defaultUsingCautionMessage,
  inspectionStatus,
}: ManualCautionMessageProps) => {
  const { control, setValue } = useFormContext<UpdateSingleManualAPIFormValues>();
  const { authorizationTypeID } = useUserInfo();
  const { hasKakaoAlimAndFriendTalkLinkage } = useServiceSettings();

  const cautionMessage = useWatch({ control, name: 'cautionMessage' });
  const usingCautionMessage = useWatch({ control, name: 'usingCautionMessage' });

  const canUserCreateManual = authorizationTypeID?.canCreateManual;

  const statusType = useMemo(() => {
    if (!inspectionStatus) return null;

    return keyToStatusTypeMap[inspectionStatus];
  }, [inspectionStatus]);

  useEffect(() => {
    setValue('cautionMessage', defaultCautionMessage ?? '');
    setValue('usingCautionMessage', defaultUsingCautionMessage ?? false);
  }, [defaultCautionMessage, defaultUsingCautionMessage, setValue, tagId]);

  const handleTextAreaChange = (value: string) => {
    setValue('cautionMessage', value);
  };

  const handleToggleChange = (checked: boolean) => {
    setValue('usingCautionMessage', checked);
  };

  return (
    <div className='mt-10 flex w-full flex-col gap-10'>
      <div className='flex items-center justify-between'>
        <Title title={TITLE} subTitle={SUB_TITLE} wrapperClassName='px-0' />
        <CautionToggleSection
          usingCautionMessage={usingCautionMessage ?? false}
          handleToggleChange={handleToggleChange}
          disabled={!canUserCreateManual}
        />
      </div>
      <CautionTextArea
        tagId={tagId}
        defaultCautionMessage={defaultCautionMessage ?? ''}
        cautionMessage={cautionMessage ?? ''}
        statusType={statusType}
        handleTextAreaChange={handleTextAreaChange}
        disabled={!canUserCreateManual}
      />
      {hasKakaoAlimAndFriendTalkLinkage && statusType && (
        <CautionStatusInfo statusType={statusType} />
      )}
    </div>
  );
};
