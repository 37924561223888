import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AUTHORIZATION_ITEMS } from '@templates/HospitalSetting/containers/AllAccountSetting/constants/authorization-items';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { type SubmitHandler, useFormContext } from 'react-hook-form';
import { SHARED_UTILS } from 'utils/utils';

type CreateHospitalAccountFormValues = Parameters<
  typeof apiClient.v3.apiHospitalsAccountsElCreate
>[0] & { name: string };

const createHospitalAccount = async (params: CreateHospitalAccountFormValues) => {
  const response = await apiClient.v3.apiHospitalsAccountsElCreate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const showToastMessage = (type: 'success' | 'failure') => {
  const defaultMessage =
    type === 'success' ? '추가되었습니다' : '추가를 실패하였습니다. 정보를 다시 확인해 주세요.';

  const toastFn = type === 'success' ? toastService.successMsg : toastService.errorMsg;
  toastFn({ text: defaultMessage });
};

export default function AddAccountButton() {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();
  const { handleSubmit, reset, setError } = useFormContext<CreateHospitalAccountFormValues>();

  const mutation = useMutation({
    mutationFn: (params: CreateHospitalAccountFormValues) => createHospitalAccount(params),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => handleMutationSuccess(),
    onError: (error) => handleMutationError(error),
  });

  const handleMutationSuccess = () => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiHospitalsAccountsElFind] });
    showToastMessage('success');
    reset();
  };

  const handleMutationError = (error: Error) => {
    if (SHARED_UTILS.api.isApiErrorWithCode(error, 40004)) {
      setError('id', { type: 'manual', message: '이미 동일한 아이디가 있습니다.' });
    }

    showToastMessage('failure');
  };

  const validateFields = (data: CreateHospitalAccountFormValues) => {
    const { id, name, password, jobType, authorizationType } = data;
    const errors: { field: keyof CreateHospitalAccountFormValues }[] = [];

    if (!name) {
      errors.push({ field: 'name' });
    }

    if (id.length < 3) {
      errors.push({ field: 'id' });
    }

    if (password.length < 3) {
      errors.push({ field: 'password' });
    }

    if (!jobType) {
      errors.push({ field: 'jobType' });
    }

    if (!authorizationType || !AUTHORIZATION_ITEMS.some((item) => item === authorizationType)) {
      errors.push({ field: 'authorizationType' });
    }

    if (errors.length > 0) {
      for (const error of errors) {
        setError(error.field, { type: 'manual' });
      }
      return false;
    }

    return true;
  };

  const onSubmit: SubmitHandler<CreateHospitalAccountFormValues> = (data) => {
    if (!validateFields(data)) {
      showToastMessage('failure');
      return;
    }
    mutation.mutate({ ...data, hospitalID });
  };

  return (
    <>
      <div className='mt-10 flex'>
        <ContainedButton className='ml-auto' type='submit' onClick={handleSubmit(onSubmit)}>
          추가
        </ContainedButton>
      </div>
    </>
  );
}
