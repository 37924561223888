import { useChatMessageContext } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/ChatMessageContext';
import { useEffect } from 'react';
import { findChat } from '../apis/find-chat';
import { TEXT_MESSAGE_LIMIT_TIME } from '../constants/rightMessage';

/**
 * Custom hook to handle the timeout logic for translated mocking messages.
 * @param {boolean} isTranslatedMockingMessage - State indicating if the message is being translated.
 * @param {function} setIsTranslatedMockingMessage - Function to update the translated mocking message state.
 * @param {function} setIsTimeError - Function to update the time error state.
 */

export const useRightNormalChatTimeout = ({
  isMockingMessage,
  setIsMockingMessage,
  setIsTimeError,
}: {
  isMockingMessage: boolean;
  setIsMockingMessage: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTimeError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { setResMessages, _id } = useChatMessageContext();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isMockingMessage) {
      timer = setTimeout(async () => {
        //_id가 모킹의 client에서 임으로 만든 _id이거나 잘못된 _id일 경우 res가 undefined이다.
        const res = await findChat(_id);
        const isInServer = res !== undefined;
        if (isInServer) {
          setResMessages((prev) => {
            return prev.map((message) => {
              if (message._id === _id) {
                return { ...res, status: 'success' };
              }
              return { ...message, status: 'failed' }; //발생 불가능한 케이스로 메시지 스스로가 본인 존재 자체를 부정하는 케이스 방어로직으로 놓음
            });
          });
        } else {
          setIsMockingMessage(false);
          setResMessages((prev) => {
            return prev.map((message) => {
              if (message._id === _id) {
                return { ...message, status: 'failed' };
              }
              return message; //DB에 없다고 resMessages에 있는 거 전부 failed 처리하면 안됨
            });
          });

          setIsTimeError(true);
        }
      }, TEXT_MESSAGE_LIMIT_TIME);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isMockingMessage]);
};
