import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { UserElUpdateParam } from '@templates/UserInfoSetting/containers/AccountSetting/AccountSetting.container';
import type { UserUpdateFieldType } from '@templates/UserInfoSetting/containers/AccountSetting/types/user-update-field-type';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { SHARED_UTILS } from 'utils/utils';

const requestUserInfoUpdate = async (params: UserElUpdateParam) => {
  const { data } = await apiClient.v2.apiUserElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

interface UpdateUserInfoAction {
  param: UserUpdateFieldType;
  onSuccessCallback?: () => void;
  onFailureCallback?: () => void;
}

export const useUpdateUserInfo = () => {
  const queryClient = useQueryClient();
  const { userId } = useUserInfo();

  const updateUserInfoMutation = useMutation({
    mutationFn: (params: UserElUpdateParam) => requestUserInfoUpdate(params),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
  });

  const updateUserInfo = (action: UpdateUserInfoAction) => {
    updateUserInfoMutation.mutate(
      {
        userID: userId,
        ...action.param,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.v2apiUserElFindOne, { userID: userId }],
          });
          action.onSuccessCallback?.();
        },
        onError: (error: Error) => {
          console.error('Error updating hospital user info', error);
          action.onFailureCallback?.();
        },
      },
    );
  };

  return {
    updateUserInfo,
  };
};
