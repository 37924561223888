import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import type { CounselAutomationAPIFormValues } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import EditOverlay from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/EditOverlay/EditOverlay';
import {
  COUNSEL_REPLACEABLE_TEXTS,
  MAX_LENGTH,
} from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/constants/replaceable-texts';
import { useKakaoCheckWhetherEditOrNot } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/hooks/use-kakao-check-whether-edit-or-not';
import { actualTextLengthState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/states/actual-text-length';
import { selectedMessageIndexState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import type { ReplaceableText } from 'afterdoc-saas-web/templates/Automation/containers/shared/functions/calculate-text';
import { useAtom, useAtomValue } from 'jotai';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

interface SendContentFormReplaceableTextListsProps {
  textRef: React.RefObject<HTMLTextAreaElement>;
  className?: string;
  // 설문일 경우에만 content.text가 없기 때문에
  isSurvey?: boolean;
}

export default function SendContentFormReplaceableTextLists({
  textRef,
  className,
  isSurvey = false,
}: SendContentFormReplaceableTextListsProps) {
  const { getValues, setValue } = useFormContext<CounselAutomationAPIFormValues>();

  const [actualTextLength, setActualTextLength] = useAtom(actualTextLengthState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const handleInsertReplaceText = (text: string, length: number) => {
    if (actualTextLength + length < MAX_LENGTH) {
      if (isSurvey) {
        setValue(
          `messages.${selectedMessageIndex}.content.survey.question`,
          (getValues(`messages.${selectedMessageIndex}.content.survey.question`) ?? '') + text,
        );
      } else {
        setValue(
          `messages.${selectedMessageIndex}.content.text`,
          (getValues(`messages.${selectedMessageIndex}.content.text`) ?? '') + text,
        );

        setActualTextLength((prev) => prev + length);
        textRef.current?.focus();
      }
    }
  };

  return (
    <CounselReplaceableTextLists
      replaceableTexts={COUNSEL_REPLACEABLE_TEXTS}
      handleInsertReplaceText={handleInsertReplaceText}
      className={className}
    />
  );
}

interface CounselReplaceableTextListsProps {
  replaceableTexts: ReplaceableText[];
  handleInsertReplaceText: (text: string, length: number) => void;
  className?: string;
}

function CounselReplaceableTextLists({
  replaceableTexts,
  handleInsertReplaceText,
  className,
}: CounselReplaceableTextListsProps) {
  const textRef = useRef<HTMLTextAreaElement>(null);
  const { handleFormClick, showOverlay } = useKakaoCheckWhetherEditOrNot();

  return (
    <div className={customTwMerge('mt-20 flex items-center gap-10', className)}>
      <div className='text-Header12 text-black500'>치환 문자</div>
      {replaceableTexts.map(({ text, description, length }) => (
        <div className='relative' key={text}>
          {showOverlay && (
            <EditOverlay
              onClick={(e) => {
                handleFormClick(e, () => {
                  handleInsertReplaceText(text, length);
                  textRef.current?.focus();
                });
              }}
            />
          )}
          <HoverTooltip text={description} position='bottomRight' portalZindex={1000}>
            <button
              className='rounded-r6 bg-white200 px-6 py-3 font-regular text-Body12 text-black500'
              type='button'
              onClick={() => {
                handleInsertReplaceText(text, length);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}>
              {text}
            </button>
          </HoverTooltip>
        </div>
      ))}
    </div>
  );
}
