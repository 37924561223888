import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ChatRoomElFindServiceInformationHandlerParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import type { ChatRoomID } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';

const getInfoTopPatient = async (query: ChatRoomElFindServiceInformationHandlerParams) => {
  const { data } = await apiClient.v3.chatRoomElFindServiceInformationHandler(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useChatPatientSync = ({ chatRoomID }: { chatRoomID: ChatRoomID }) => {
  if (chatRoomID === null) return {};
  const { data } = useQuery({
    queryKey: [QUERY_KEY.chatRoomElFindServiceInformationHandler, { chatRoomID }] as const,
    queryFn: ({ queryKey }) => getInfoTopPatient(queryKey[1]),
    enabled: !!chatRoomID,
  });

  const { isColorAfterDoc = false, isGrayAfterDoc = true, hasKakao = false } = data || {};

  return {
    isKakaoIcon: hasKakao,
    isColorAfterDocIcon: isColorAfterDoc,
    isGrayAfterDocIcon: isGrayAfterDoc,
  };
};
