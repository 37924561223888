import SearchInput from 'afterdoc-design-system/components/Atoms/Input/SearchInput';
import { manualTagSearchTextState } from 'afterdoc-saas-web/templates/HospitalManual/components/HospitalManualPanel/states/search';
import { useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { type KeyboardEvent, useEffect, useRef, useState } from 'react';

export default function TagSearchInput() {
  const inputRef = useRef<HTMLInputElement>(null);

  const resetManualTagSearchText = useResetAtom(manualTagSearchTextState);

  const setManualTagSearchText = useSetAtom(manualTagSearchTextState);

  const [localSearchText, setLocalSearchText] = useState('');

  const handleKeyDown = async (e: KeyboardEvent) => {
    e.stopPropagation();

    if (!inputRef.current) return;

    if (e.key === 'Enter' && inputRef.current.value.length > 0) {
      setManualTagSearchText(inputRef.current.value);
    }
  };

  const handleDelete = () => {
    setLocalSearchText('');
    resetManualTagSearchText();
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (!localSearchText.length) {
      resetManualTagSearchText();
    }
  }, [localSearchText]);

  return (
    <div className='px-20 py-10'>
      <SearchInput
        id='tag-search-input'
        placeholder='태그명으로 검색'
        className='w-full'
        value={localSearchText}
        onChange={(e) => {
          setLocalSearchText(e.target.value);
        }}
        onKeyDown={handleKeyDown}
        onDelete={handleDelete}
        ref={inputRef}
      />
    </div>
  );
}
