import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useServiceSettings } from 'afterdoc-saas-web/shared/hooks/etc/use-serivce-settings';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import type { CounselAutomationAPIFormValues } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import EditOverlay from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/EditOverlay/EditOverlay';
import { useKakaoCheckWhetherEditOrNot } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/hooks/use-kakao-check-whether-edit-or-not';
import { actualTextLengthState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/states/actual-text-length';
import {
  COUNSEL_MESSAGE_CONTENT_RESET_MAPPING,
  COUNSEL_MESSAGE_SETTING_TAB_ITEMS,
} from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/constants/tab';
import {
  selectedContentTabItemState,
  selectedMessageIdState,
  selectedMessageIndexState,
} from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';

export default function SendContentFormTab() {
  const { setValue } = useFormContext<CounselAutomationAPIFormValues>();

  const { handleFormClick, showOverlay } = useKakaoCheckWhetherEditOrNot();

  const selectedMessageId = useAtomValue(selectedMessageIdState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const [selectedContentTabItem, setSelectedContentTabItem] = useAtom(selectedContentTabItemState);
  const setActualTextLength = useSetAtom(actualTextLengthState);

  const serviceSettings = useServiceSettings();
  const { usingHospitalEvent } = useSelectedHospitalInfo();

  const handleContentTabChange = (item: (typeof COUNSEL_MESSAGE_SETTING_TAB_ITEMS)[number]) => {
    const prevItem = selectedContentTabItem?.[selectedMessageId];

    if (prevItem === item) {
      return;
    }

    const fieldsToReset =
      COUNSEL_MESSAGE_CONTENT_RESET_MAPPING[
        prevItem as keyof typeof COUNSEL_MESSAGE_CONTENT_RESET_MAPPING
      ] ?? [];
    for (const field of fieldsToReset) {
      setValue(`messages.${selectedMessageIndex}.content.${field}`, undefined);
    }

    // 텍스트 초기화는 항상 수행
    setActualTextLength(0);
    setValue(`messages.${selectedMessageIndex}.content.text`, undefined);

    // 새로운 탭 아이템 설정
    setSelectedContentTabItem((prev) => ({
      ...(prev || {}),
      [selectedMessageId]: item,
    }));
  };

  return (
    <div className='mt-10 flex'>
      {COUNSEL_MESSAGE_SETTING_TAB_ITEMS.filter((item) => {
        if (item === '홈케어콘텐츠') {
          return (serviceSettings?.usingAutoSendVideo?.length ?? 0) > 0;
        }

        if (item === '이벤트') {
          return usingHospitalEvent;
        }

        return true;
      }).map((item) => (
        <div key={item} className='relative'>
          {showOverlay && (
            <EditOverlay
              id='send-content-form-tab-overlay'
              onClick={(e) => {
                handleFormClick(e, () => {
                  handleContentTabChange(item);
                });
              }}
            />
          )}
          <button
            type='button'
            onClick={() => {
              handleContentTabChange(item);
            }}
            className={customTwMerge(
              'py-8 pr-16 pl-20 text-Header12',
              selectedContentTabItem?.[selectedMessageId] === item
                ? 'text-black700'
                : 'text-black200',
            )}>
            {item}
          </button>
        </div>
      ))}
    </div>
  );
}
