import DashBoardCardList from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/components/DashBoardCardList/DashBoardCardList';
import DashBoardHeader from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/components/DashBoardHeader/DashBoardHeader';

export default function DashBoardContent() {
  return (
    <div className='flex w-[370px] flex-col'>
      <DashBoardHeader />
      <DashBoardCardList />
    </div>
  );
}
