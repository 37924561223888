import { neverCheck } from 'afterdoc-saas-web/shared/utils/never-check';
import { ROOM_TYPE_MAP } from 'afterdoc-saas-web/templates/CustomerChat/constants/ROOM_TYPE_MAP';
import type { RoomType } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';

type LinkedChannelsDisplayParams = {
  roomType: RoomType;
  channelName: string;
};

export const linkedChannelChatRoomNameDisplay = ({
  roomType,
  channelName,
}: LinkedChannelsDisplayParams) => {
  if (roomType === null) throw new Error('Room type is null');

  switch (roomType) {
    case ROOM_TYPE_MAP.kakao:
    case ROOM_TYPE_MAP.instagram:
      return channelName;
    case ROOM_TYPE_MAP.afterdoc:
    case ROOM_TYPE_MAP.manager:
      return '애프터닥';
    default:
      roomType satisfies never;
      neverCheck(roomType);
  }
};
