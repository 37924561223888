import { useHospitalHomeCare } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/hooks/use-hospital-home-care';
import { homeCareCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/states/homecare-create-or-update-param-state';
import type { CreateUpdateHomeCareParams } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/types/create-update-homecare-params';
import { validateEventOrHomeCareParams } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/functions/validate-event-or-home-care-params';
import { useImageUpload } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/hooks/use-image-upload';
import { contentEditErrorsState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/content-edit-errors-state';
import { detailImageTempFileState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/detail-image-temp-file-state';
import { thumbnailImageTempFileState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/thumbnail-image-temp-file-state';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import DownloadFooter from 'afterdoc-design-system/components/Molecules/DownloadFooter/DownloadFooter';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { useAtom, useAtomValue, useSetAtom } from 'jotai/index';
import { useEffect, useState } from 'react';

interface HomeCareManagePageFooterProps {
  isModified: boolean;
}

export default function HomeCareManagePageFooter({ isModified }: HomeCareManagePageFooterProps) {
  const { createHomeCareAction, updateHomeCareAction, removeHomeCareAction } =
    useHospitalHomeCare();

  const { authorizationTypeID } = useUserInfo();

  const [contentEdit, setContentEdit] = useAtom(contentEditState);

  const [homeCareCreateOrUpdateParam, setHomeCareCreateOrUpdateParam] = useAtom(
    homeCareCreateOrUpdateParamState,
  );

  const thumbnailImageTempFile = useAtomValue(thumbnailImageTempFileState);
  const detailImageTempFile = useAtomValue(detailImageTempFileState);

  const setContentEditErrors = useSetAtom(contentEditErrorsState);

  const [isPrepared, setIsPrepared] = useState(false);

  const { uploadThumbnailImage, uploadDetailImage } = useImageUpload<CreateUpdateHomeCareParams>(
    thumbnailImageTempFile,
    detailImageTempFile,
    setHomeCareCreateOrUpdateParam,
  );

  const validateAndSetErrors = () => {
    const errors = validateEventOrHomeCareParams(
      homeCareCreateOrUpdateParam,
      thumbnailImageTempFile,
      detailImageTempFile,
    );
    if (Object.keys(errors).length > 0) {
      setContentEditErrors((prev) => ({ ...prev, ...errors }));
      return false;
    }
    return true;
  };

  const handleEditEnd = () => {
    setIsPrepared(false);
    setContentEdit({ isEditing: false });
  };

  const handleCancel = () => {
    if (isModified) {
      modalService.defaultWarning({
        onConfirm: () => {
          const message = `홈케어 상품 ${contentEdit.id ? '수정' : '등록'}을 취소했습니다.`;
          toastService.errorMsg({ text: message });
          setContentEdit({ isEditing: false });
          modalService.pop();
        },
      });
      return;
    }
    setContentEdit({ isEditing: false });
  };

  const handleRemove = () => {
    if (contentEdit.id) {
      removeHomeCareAction(contentEdit.id, handleEditEnd);
    }
  };

  const handleConfirm = async () => {
    try {
      const isEventParamValid = validateAndSetErrors();

      if (!isEventParamValid) return;

      fullDimmedLoadingService.on();

      await uploadThumbnailImage();
      await uploadDetailImage();

      setIsPrepared(true);
    } catch (error) {
      console.error('Error during upload:', error);
      fullDimmedLoadingService.off();
    }
  };

  const handleErrorCallback = () => {
    setIsPrepared(false);
  };

  useEffect(() => {
    if (isPrepared) {
      if (contentEdit.id) {
        updateHomeCareAction(homeCareCreateOrUpdateParam, handleEditEnd, handleErrorCallback);
      } else {
        createHomeCareAction(homeCareCreateOrUpdateParam, handleEditEnd, handleErrorCallback);
      }
    }
  }, [isPrepared]);

  const customLeftButton = contentEdit.id && authorizationTypeID?.canDeleteContent && (
    <ContainedButton btnColor='red' onClick={handleRemove}>
      삭제
    </ContainedButton>
  );

  const customRightButton = (
    <div className='flex gap-10'>
      <ContainedButton btnColor='transparent' onClick={handleCancel}>
        취소
      </ContainedButton>
      <ContainedButton btnColor='blue' onClick={handleConfirm}>
        저장
      </ContainedButton>
    </div>
  );

  return (
    <DownloadFooter customLeftButton={customLeftButton} customRightButton={customRightButton} />
  );
}
