import LinkedChatRoomItem from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoTitle/components/LinkedChatRoomManagerDialog/components/LinkedChatRoomItem';
import { useLinkedChatRoom } from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoTitle/components/LinkedChatRoomManagerDialog/hooks/use-linked-chat-room';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { useEffect } from 'react';

export const LINKED_CHAT_MANAGER_DIALOG_ID = 'linked-chat-manager-dialog';

interface LinkedChatManagerDialogProps {
  hserviceId: string;
}

export default function LinkedChatRoomManagerDialog({ hserviceId }: LinkedChatManagerDialogProps) {
  const { data, isShowIndicator, isError, handleUnLinkChatRoom } = useLinkedChatRoom(hserviceId);

  useEffect(() => {
    if (isError) {
      toastService.errorMsg({ text: '연동된 채팅방 목록을 불러오는 중 오류가 발생했습니다.' });
    }
  }, [isError]);

  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        const lastDialog = await dialogService.getLastDialog();
        const isShowModal = await modalService.isShowModal();

        if (lastDialog?.id === LINKED_CHAT_MANAGER_DIALOG_ID && !isShowModal) {
          dialogService.popById(LINKED_CHAT_MANAGER_DIALOG_ID);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <div className='h-[300px] w-full pt-12 pb-20'>
        <Scrollbar disabledX={true} className='flex-grow'>
          <div className='relative flex h-full flex-col px-20'>
            {isShowIndicator && (
              <div className='absolute inset-0 flex-center bg-white50 opacity-40'>
                <BaseLoading />
              </div>
            )}
            {!isShowIndicator && !data.length && <LinkedChatListNoData />}
            {data.length > 0 && (
              <>
                <Divider type={'line'} />
                {data.map((item) => (
                  <LinkedChatRoomItem
                    key={item._id}
                    item={item}
                    handleUnLinkChatRoom={handleUnLinkChatRoom}
                  />
                ))}
              </>
            )}
          </div>
        </Scrollbar>
      </div>
    </>
  );
}

const LinkedChatListNoData = () => {
  return (
    <>
      <div className='h-full w-full flex-center'>
        <NoData
          iconProps={{
            name: 'warning',
            size: 48,
            color: 'white400',
          }}
          title='고객과 연동된 채팅방이 없습니다.'
        />
      </div>
    </>
  );
};
