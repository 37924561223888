import type { AlgorithmWithMessagesInput } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import LayoutBox from 'afterdoc-saas-web/shared/components/LayoutBox/LayoutBox';
import SuspenseWithLoading from 'afterdoc-saas-web/shared/components/SuspenseWithLoading/SuspenseWithLoading';
import AutomationZoomInOutViewerContent from 'afterdoc-saas-web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/AutomationZoomInOutViewerContent';
import AutomationZoomInOutViewerHeader from 'afterdoc-saas-web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/AutomationZoomInOutViewerHeader';

export interface AutomationZoomInOutViewerContainerProps {
  algorithmId: string;
  type: Exclude<AlgorithmWithMessagesInput['type'], 'COMMON'>;
}

export default function AutomationZoomInOutViewerContainer({
  algorithmId,
  type,
}: AutomationZoomInOutViewerContainerProps) {
  return (
    <>
      {/* 조회 헤더 */}
      <LayoutBox noStyle>
        <AutomationZoomInOutViewerHeader type={type} />
      </LayoutBox>

      {/* 조회 컨텐츠 */}
      <SuspenseWithLoading>
        <AutomationZoomInOutViewerContent algorithmId={algorithmId} type={type} />
      </SuspenseWithLoading>
    </>
  );
}
