import { SHARED_UTILS } from '@shared-utils/utils';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import type { PostSendVideosParams } from '../types';

export const postSendVideos = async ({ chatRoomID, hserviceID, vchIDs }: PostSendVideosParams) => {
  const newParams = {
    chatRoomID,
    hserviceID,
    vchIDs,
  };

  const { data } = await apiClient.v3.apiChatElSendVcu(newParams);
  return SHARED_UTILS.api.checkApiResponse(data);
};
