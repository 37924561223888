import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useServiceSettings } from 'afterdoc-saas-web/shared/hooks/etc/use-serivce-settings';
import { useChatRoomInfoOne } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/hooks/use-chatroom-info-one';
import { isActivatingChatTranslateState } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/states/is-activating-chat-translate';
import { isSettingBottomTranslateLanguageState } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/states/is-setting-sending-translate-language';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect, useRef } from 'react';

export function useChatTranslationToggle({ chatRoomID }: { chatRoomID: string }) {
  const adminSettingResponse = useServiceSettings();
  const isUseTranslate = !!adminSettingResponse?.usingChatTranslate;

  const { isUsingTranslation } = useChatRoomInfoOne({ chatRoomID });
  const [isActivatingChatTranslate, setIsActivatingChatTranslate] = useAtom(
    isActivatingChatTranslateState,
  );
  const setIsSettingTranslateLanguage = useSetAtom(isSettingBottomTranslateLanguageState);

  useEffect(
    function initailizeIsActivatingChatTranslate() {
      setIsActivatingChatTranslate(
        isUseTranslate && isUsingTranslation !== undefined ? isUsingTranslation : false,
      );
    },
    [isUseTranslate, isUsingTranslation, setIsActivatingChatTranslate],
  );
  //chatting room id 가 변경되지 않고 isUsingTranslation 값이 변경될 때만 toast 를 띄우는 로직
  const previousIsUsingTranslation = useRef<boolean | undefined>(undefined);
  const previousChatRoomID = useRef<string | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // Check if chatRoomID has changed
    const hasChatRoomChanged = previousChatRoomID.current !== chatRoomID;

    if (hasChatRoomChanged) {
      previousChatRoomID.current = chatRoomID;
      // Reset the previous value when chatRoomID changes
      previousIsUsingTranslation.current = undefined;
    } else {
      // Check if isUsingTranslation has changed
      if (
        previousIsUsingTranslation.current !== undefined &&
        previousIsUsingTranslation.current !== isUsingTranslation
      ) {
        toastService.successMsg({
          text: `번역을 ${isUsingTranslation ? '시작' : '종료'}합니다`,
        });
        isUsingTranslation && setIsSettingTranslateLanguage(false);
      }
    }

    // Update the current value
    previousIsUsingTranslation.current = isUsingTranslation;
  }, [isUsingTranslation, chatRoomID]);

  return { isActivatingChatTranslate, setIsActivatingChatTranslate };
}
