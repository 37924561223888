import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import ALIntegrationEdit from 'afterdoc-saas-web/shared/components/ALIntegrationEdit/ALIntegrationEdit';
import { useServiceSettings } from 'afterdoc-saas-web/shared/hooks/etc/use-serivce-settings';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import EnrollDialog from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/CustomerInfoTop/components/PatientTitle/components/EnrollDialog/EnrollDialog';
import {
  MAX_LAYOUT_WIDTH,
  MIN_LAYOUT_WIDTH,
} from 'afterdoc-saas-web/templates/CustomerChat/constants/layout';
import { useChatTopPatient } from 'afterdoc-saas-web/templates/CustomerChat/hooks/use-chat-top-patient';
import { chatRoomParentHServiceIDSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtomValue } from 'jotai';
import PatientDetail from './components/PatientDetail/PatientDetail';
import PatientTitle from './components/PatientTitle/PatientTitle';
import TranslateToggle from './components/TranslateToggle/TranslateToggle';
import TreatmentTags from './components/TreatmentTags/TreatmentTags';
import { useLinkChannelPushHandler } from './hooks/use-link-channel-push-handler';
import { useUpdateSuspendSyncChatRoomInfoPushHandler } from './hooks/use-update-suspend-sync-chat-room-info-push-handler';

interface CustomerInfoTopProps {
  chatRoomID: string;
}

export default function CustomerInfoTop({ chatRoomID }: CustomerInfoTopProps) {
  const chatRoomParentHServiceID = useAtomValue(chatRoomParentHServiceIDSelector);

  const { integrationSetting } = useSelectedHospitalInfo();
  const { isKakaoIcon, isColorAfterDocIcon, isGrayAfterDocIcon, hasSmartDoctorLinkage } =
    useChatTopPatient({ chatRoomID });
  const adminSettingResponse = useServiceSettings();

  useLinkChannelPushHandler({ chatRoomID });
  useUpdateSuspendSyncChatRoomInfoPushHandler();

  const handleLinkSmarkDoctor = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dialogService.push(
      <ALIntegrationEdit patientId={chatRoomParentHServiceID} dialogId={'ALIntegrationEdit'} />,
      {
        width: 'auto',
        id: 'ALIntegrationEdit',
      },
    );
  };

  const isUseTranslate = !!adminSettingResponse?.usingChatTranslate;

  //카톡 고객 등록 버튼과 전능 연동 버튼은 공존 할 수 없음
  const isShowRegistButton =
    isKakaoIcon === true && isGrayAfterDocIcon === false && isColorAfterDocIcon === false;

  const isShowALMightyLinkButton =
    integrationSetting &&
    !integrationSetting.isRemoved &&
    integrationSetting.integrationType === 'ALM' &&
    integrationSetting.clientToken;

  const isShowDetail = !(
    isKakaoIcon === true &&
    isGrayAfterDocIcon === false &&
    isColorAfterDocIcon === false
  );

  return (
    <div
      className='flex min-h-[104px] flex-row justify-between gap-20 border-white border-white400 bg-white50 px-20 py-10'
      style={{ minWidth: `${MIN_LAYOUT_WIDTH}`, maxWidth: `${MAX_LAYOUT_WIDTH}` }}>
      <div className={customTwMerge('flex w-full flex-col ', isShowDetail ? '' : 'gap-4')}>
        <PatientTitle chatRoomID={chatRoomID} />
        {isShowDetail ? (
          <>
            <PatientDetail chatRoomID={chatRoomID} />
            <TreatmentTags />
          </>
        ) : (
          <div className='flex flex-col-center items-start text-Body12'>
            <span>카카오톡으로 문의한 고객입니다.</span>
            <span>
              [고객등록] 버튼을 클릭하여 고객으로 등록하면, 예약 및 고객정보를 기록할 수 있습니다.
            </span>
          </div>
        )}
      </div>

      <div className='flex flex-col items-end justify-between'>
        <div>
          {isShowRegistButton ? (
            <OutlinedButton
              className='whitespace-nowrap'
              onClick={() => {
                dialogService.push(<EnrollDialog />, {
                  titleProps: {
                    title: '고객등록',
                  },
                  wrapperClassName: 'h-[326px]',
                });
              }}>
              고객등록
            </OutlinedButton>
          ) : (
            isShowALMightyLinkButton && (
              <HoverTooltip
                text={`스마트닥터에 계정이 ${hasSmartDoctorLinkage ? '연동된' : '연동되지 않은'} 고객입니다.`}
                offsetY={-4}
                position='bottomRight'>
                <button
                  type='button'
                  className='mt-5 h-26 w-49 flex-center gap-4 rounded-r6 border border-white600 bg-white50'
                  onClick={(e) => handleLinkSmarkDoctor(e)}>
                  <Icon
                    name={hasSmartDoctorLinkage ? 'smart-doctor' : 'smart-doctor-non'}
                    size={17}
                  />
                  <Icon name='chevron-right' size={16} color='black200' />
                </button>
              </HoverTooltip>
            )
          )}
        </div>

        {isUseTranslate && <TranslateToggle chatRoomID={chatRoomID} />}
      </div>
    </div>
  );
}
