import { LOCK_TIMEOUT } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/constants/timeout';
import { useTemporaryPatientsLock } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/utils/ use-temporary-patients-lock';
import { useEffect, useRef } from 'react';

export const useLockRefreshTimer = (hospitalID: string, isEnabled: boolean) => {
  const { getPatientsIsLocked, patchRequestLockMutation } = useTemporaryPatientsLock();
  const timerRef = useRef<NodeJS.Timeout>();
  const isFirstRun = useRef(true);
  // StrictMode 환경에서 컴포넌트가 언마운트되지 않도록 하기 위해 사용
  const mountedRef = useRef(false);

  useEffect(() => {
    if (mountedRef.current) return;
    mountedRef.current = true;

    if (!hospitalID || !isEnabled) return;

    const refreshLock = async () => {
      try {
        const latestLockData = await getPatientsIsLocked({ hospitalID });

        if (!latestLockData) return;

        await patchRequestLockMutation.mutateAsync({
          hospitalID,
          isLock: true,
          version: latestLockData.version,
        });
      } catch (error) {
        console.error('Lock refresh failed:', error);
      }
    };

    const startTimer = () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }

      if (isFirstRun.current) {
        void refreshLock();
        isFirstRun.current = false;
      }

      timerRef.current = setInterval(refreshLock, LOCK_TIMEOUT);
    };

    if (isEnabled) {
      startTimer();
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = undefined;
      }
      isFirstRun.current = true;
    };
  }, [hospitalID, isEnabled]);
};
