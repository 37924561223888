import BigImage from 'afterdoc-design-system/components/Atoms/BigImage/BigImage';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import type { AlgorithmMessage } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import type { CounselAutomationAPIFormValues } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import EventOrHomecareContentSelectDialog from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/components/EventOrHomecareContentSelectDialog/EventOrHomecareContentSelectDialog';
import { useGetCalculatedResult } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/hooks/use-get-calculated-result';
import { ContentTextArea } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/components/ContentTextArea';
import { selectedMessageIndexState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { useGetIsKakaoAlimAndFriendTalkLinkage } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/shared/hooks/use-get-is-kakao-alim-and-friend-talk-linkage';
import { useAtomValue } from 'jotai';
import { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

export type ParseEventOrHomecareContent = (
  | NonNullable<NonNullable<AlgorithmMessage['content']>['homecareContent']>
  | NonNullable<NonNullable<AlgorithmMessage['content']>['event']>
) & {
  type: 'event' | 'homecare';
};
const DIALOG_ID = 'event-or-homecare-content-select-dialog';

const EventContentForm = forwardRef<HTMLTextAreaElement>((_, ref) => {
  const { watch, setValue } = useFormContext<CounselAutomationAPIFormValues>();

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const { hasKakaoAlimAndFriendTalkLinkage } = useGetIsKakaoAlimAndFriendTalkLinkage();

  const eventContent = watch(`messages.${selectedMessageIndex}.content.event`);
  const homecareContent = watch(`messages.${selectedMessageIndex}.content.homecareContent`);

  const parsedEventOrHomecareContent: ParseEventOrHomecareContent = eventContent
    ? JSON.parse(eventContent)
    : homecareContent
      ? JSON.parse(homecareContent)
      : {};

  const handleClickWorkoutCard = (parseEventOrHomecareContent: ParseEventOrHomecareContent) => {
    if (parseEventOrHomecareContent.type === 'event') {
      const newEventContent = JSON.stringify(parseEventOrHomecareContent);
      setValue(`messages.${selectedMessageIndex}.content.event`, newEventContent);
      setValue(`messages.${selectedMessageIndex}.content.homecareContent`, undefined);
    }

    if (parseEventOrHomecareContent.type === 'homecare') {
      const newHomecareContent = JSON.stringify(parseEventOrHomecareContent);
      setValue(`messages.${selectedMessageIndex}.content.homecareContent`, newHomecareContent);
      setValue(`messages.${selectedMessageIndex}.content.event`, undefined);
    }
    dialogService.popById(DIALOG_ID);
  };

  const handleOpenEventOrHomecareDialog = () => {
    dialogService.push(
      <EventOrHomecareContentSelectDialog handleClickWorkoutCard={handleClickWorkoutCard} />,
      {
        id: DIALOG_ID,
        width: 581,
        hasCloseButton: true,
        titleProps: {
          title: '이벤트 목록',
        },
      },
    );
  };

  return (
    <>
      {hasKakaoAlimAndFriendTalkLinkage && (
        <div className='rounded-r10 bg-white200 px-16 py-8 text-Body10 text-black500'>
          텍스트를 제외한 다른 타입의 메시지는 알림톡을 발송할 수 없습니다.
        </div>
      )}
      <div className='mt-10 flex items-start gap-10'>
        <EventAttachInput
          handleOpenEventOrHomecareDialog={handleOpenEventOrHomecareDialog}
          {...parsedEventOrHomecareContent}
        />
        <ContentTextArea ref={ref} defaultHeight={120} />
      </div>
    </>
  );
});

export default EventContentForm;

type EventAttachInputProps = ParseEventOrHomecareContent & {
  handleOpenEventOrHomecareDialog: () => void;
};

const EventAttachInput = ({ handleOpenEventOrHomecareDialog, ...props }: EventAttachInputProps) => {
  const { title, thumbnailUrl, saleRate, saleType, price = 0, salePrice } = props;

  const { formattedPriceDisplay, calculatedPrice, calculatedRate } = useGetCalculatedResult({
    saleRate,
    saleType,
    price,
    salePrice,
  });

  if (!thumbnailUrl) {
    return (
      <BigImage
        className='flex-shrink-0'
        width={170}
        height={96}
        onClickNoImage={handleOpenEventOrHomecareDialog}
      />
    );
  }

  return (
    <div className='flex flex-row gap-10 rounded-r16 border border-white600 p-9'>
      <div className='relative h-[100px] w-[100px] flex-shrink-0'>
        <img
          src={thumbnailUrl}
          alt='event-thumbnail'
          className='h-full w-full rounded-r10 border border-white600 object-cover'
        />

        <button
          onClick={handleOpenEventOrHomecareDialog}
          className='absolute-center flex items-center gap-2 rounded-r16 bg-black800 bg-opacity-50 py-7 pr-10 pl-8'
          type='button'>
          <span className='whitespace-nowrap text-Body13 text-white50'>변경</span>
        </button>
      </div>
      <div className='flex w-[155px] flex-col justify-between py-10'>
        {title && <div className='line-clamp-2 text-Header12 text-black500'>{title}</div>}
        <div className='flex flex-col gap-4'>
          <div className='text-Body12 text-black200 line-through'>{formattedPriceDisplay}</div>
          <div className='flex'>
            <div className='text-Header12 text-blue500'>{calculatedPrice}원</div>
            <div className='text-Header12 text-blue500'>&nbsp;({calculatedRate})</div>
          </div>
        </div>
      </div>
    </div>
  );
};
