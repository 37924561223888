import Toggle from 'afterdoc-design-system/components/Atoms/Toggle/Toggle';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import type { CommonAlgorithm } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { OVERLAY_PAGE_ID } from 'afterdoc-saas-web/shared/constants/overlay-page-id';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { useOpenAutomationDialog } from 'afterdoc-saas-web/shared/hooks/overlayPage/use-open-automation-dialog';
import EditCommonAutomationDialogContainer from 'afterdoc-saas-web/templates/Automation/containers/Dialog/EditCommonAutomationDialog/EditCommonAutomationDialog.container';
import CardItemLayout from 'afterdoc-saas-web/templates/Automation/containers/Main/shared/components/CardItemLayout';
import { useToggleAlgorithm } from 'afterdoc-saas-web/templates/Automation/containers/Main/shared/hooks/use-toggle-algorithm';
import { Suspense } from 'react';

interface CommonAutomationCardItemProps {
  commonAlgorithm?: CommonAlgorithm;
}

export default function CommonAutomationCardItem({
  commonAlgorithm,
}: CommonAutomationCardItemProps) {
  if (!commonAlgorithm) {
    return <CardItemLayout className='col-span-3 h-[62px]' />;
  }

  const { _id: algorithmId, isActive, totalTargetCount, appliedAlgorithms } = commonAlgorithm;

  const { authorizationTypeID } = useUserInfo();
  const canUpsertAutomation = !!authorizationTypeID?.canUpsertAutomation;

  const openAutomationDialog = useOpenAutomationDialog();
  const { isChecked, onToggle } = useToggleAlgorithm({
    algorithmId,
    targetCount: totalTargetCount,
    initialState: isActive,
    type: 'COMMON',
  });

  const onClickCard = () => {
    openAutomationDialog(
      <Suspense>
        <EditCommonAutomationDialogContainer />
      </Suspense>,
      OVERLAY_PAGE_ID['edit-common-algorithm-dialog'],
    );
  };

  return (
    <CardItemLayout onClick={onClickCard} className='col-span-3'>
      <div className='flex h-fit w-full select-none items-center justify-between gap-10'>
        <div className='flex flex-col gap-4'>
          <h1 className='text-Header16 text-black700'>공통 재내원 안내</h1>
          <h2 className='text-Body11 text-black200'>
            상담자동화가 적용된 고객에게 발송되는 재내원 안내 메시지
          </h2>
        </div>
        <div className='flex items-center gap-x-16'>
          <div className='flex items-center rounded-r6 bg-white200 px-10 py-6'>
            <div className='min-w-0 max-w-[480px]'>
              {appliedAlgorithms && appliedAlgorithms.length > 0 ? (
                <span className='block truncate text-Body12'>{appliedAlgorithms.join(', ')}</span>
              ) : (
                <span className='text-Body12'>-</span>
              )}
            </div>
            <Icon name='chevron-right' color='black200' className='ml-10' size={24} />
          </div>
          {canUpsertAutomation && (
            <div className='flex items-center gap-x-16'>
              <div onClick={(e) => e.stopPropagation()}>
                <Toggle checked={isChecked} defaultChecked={isChecked} onChange={onToggle} />
              </div>
            </div>
          )}
        </div>
      </div>
    </CardItemLayout>
  );
}
