import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiServiceSettingsElFindOneParams } from '@apis/swaggers/swagger-docs';
import { useSuspenseQuery } from '@tanstack/react-query';
import TreatmentTagList from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/components/TreatmentTagList/TreatmentTagList';
import TreatmentTagTitle from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/components/TreatmentTagTitle';
import {
  initialTreatmentTagsState,
  treatmentTagsState,
} from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/hospital-treatment-tags';
import { serviceSettingState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/service-setting-state';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback, useEffect } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchServiceSetting = async (params: ApiServiceSettingsElFindOneParams) => {
  const response = await apiClient.v3.apiServiceSettingsElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function TreatmentTagContainer() {
  const { hospitalID } = useSelectedHospitalInfo();

  const resetServiceSetting = useResetAtom(serviceSettingState);
  const resetTreatmentTags = useResetAtom(treatmentTagsState);
  const resetInitialTreatmentTags = useResetAtom(initialTreatmentTagsState);

  const setServiceSetting = useSetAtom(serviceSettingState);

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiServiceSettingsElFindOne, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchServiceSetting(queryKey[1]),
  });

  const resetAll = useCallback(() => {
    resetServiceSetting();
    resetTreatmentTags();
    resetInitialTreatmentTags();
  }, [resetInitialTreatmentTags, resetServiceSetting, resetTreatmentTags]);

  useEffect(() => {
    if (data) {
      setServiceSetting(data);
    }
  }, [data, setServiceSetting]);

  useEffect(() => {
    return () => {
      resetAll();
    };
  }, [resetAll]);

  return (
    <div className='flex flex-col px-80 pb-20'>
      <TreatmentTagTitle />
      <TreatmentTagList />
    </div>
  );
}
