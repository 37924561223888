import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import type { VideoContent } from 'afterdoc-saas-web/shared/hooks/files/video/use-process-video-for-upload';
import type { CounselAutomationAPIFormValues } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { ContentTextArea } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/components/ContentTextArea';
import { selectedMessageIndexState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { useGetIsKakaoAlimAndFriendTalkLinkage } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/shared/hooks/use-get-is-kakao-alim-and-friend-talk-linkage';
import { useAtomValue } from 'jotai';
import { type ChangeEvent, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const toastVideoErrorMsg = (error: 'file-size' | 'file-type') => {
  const errorMessages = {
    'file-size': '10MB 이하의 동영상만 업로드할 수 있습니다.',
    'file-type': '동영상 파일만 업로드할 수 있습니다.',
  };

  toastService.errorMsg({
    text: errorMessages[error],
  });
};

const VideoContentForm = forwardRef<HTMLTextAreaElement>((_, ref) => {
  const { setValue, getValues } = useFormContext<CounselAutomationAPIFormValues>();
  const filePickerRef = useRef<HTMLInputElement>(null);

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const [videoInfo, setVideoInfo] = useState<VideoContent | null>(null);

  const { hasKakaoAlimAndFriendTalkLinkage } = useGetIsKakaoAlimAndFriendTalkLinkage();

  const onClickFilePicker = () => {
    filePickerRef.current?.click();
  };

  const onRemoveVideo = () => {
    setVideoInfo(null);
    setValue(`messages.${selectedMessageIndex}.content.video`, undefined);
  };

  const handleFileChange = useCallback(
    (files: File[]) => {
      if (files.length === 0) return;

      const file = files[0];
      if (file.type.startsWith('video/')) {
        if (file.size > 10 * 1024 * 1024) {
          toastVideoErrorMsg('file-size');
          return;
        }

        const blobUrl = URL.createObjectURL(file);

        setVideoInfo({
          fileName: file.name,
          fileSize: file.size,
          url: blobUrl,
        } as VideoContent);

        setValue(
          `messages.${selectedMessageIndex}.content.video`,
          JSON.stringify({
            fileName: file.name,
            fileSize: file.size,
            url: blobUrl,
          } as VideoContent),
        );
      } else {
        toastVideoErrorMsg('file-type');
      }
    },
    [selectedMessageIndex, setValue],
  );

  const onVideoFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files) {
      const fileArray: File[] = Array.from(files);
      handleFileChange(fileArray);

      e.currentTarget.value = '';
    }
  };

  useEffect(() => {
    const video = getValues(`messages.${selectedMessageIndex}.content.video`);

    if (video) {
      const videoContent = JSON.parse(video) as VideoContent;
      setVideoInfo(videoContent);
    } else {
      setVideoInfo(null);
    }
  }, [getValues, selectedMessageIndex]);

  return (
    <>
      {hasKakaoAlimAndFriendTalkLinkage && (
        <div className='rounded-r10 bg-white200 px-16 py-8 text-Body10 text-black500'>
          텍스트를 제외한 다른 타입의 메시지는 알림톡을 발송할 수 없습니다.
        </div>
      )}
      <div className='mt-10 flex flex-col gap-10'>
        <>
          <input
            ref={filePickerRef}
            accept='video/*'
            type='file'
            className='hidden'
            onChange={onVideoFileSelect}
          />
          {videoInfo ? (
            <VideoUploadButton
              videoInfo={videoInfo}
              onRemoveVideo={onRemoveVideo}
              onClick={onClickFilePicker}
            />
          ) : (
            <VideoUploadButton onClick={onClickFilePicker} />
          )}
        </>
        <ContentTextArea defaultHeight={120} ref={ref} />
      </div>
    </>
  );
});

export default VideoContentForm;

interface VideoUploadButtonProps {
  onClick: () => void;
  videoInfo?: VideoContent;
  onRemoveVideo?: () => void;
}

const VideoUploadButton = ({ onClick, videoInfo, onRemoveVideo }: VideoUploadButtonProps) => {
  return (
    <button
      type='button'
      className='rounded-r10 bg-white50 px-16 py-10 shadow-modal'
      onClick={onClick}>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-10'>
          <div className='rounded-r10 border border-white400 bg-white100 p-6'>
            <Icon name='tray-upload' size={24} color='black100' />
          </div>
          <div
            className={customTwMerge(
              'w-[300px] truncate text-left text-Body13',
              videoInfo ? 'text-black700' : 'text-black200',
            )}>
            {videoInfo ? videoInfo.fileName : '동영상을 업로드해 주세요.'}
          </div>
        </div>
        <div className='flex items-center gap-10'>
          <div className='text-Body12 text-black200'>
            {videoInfo?.fileSize
              ? `${(videoInfo.fileSize / (1024 * 1024)).toFixed(2)} MB`
              : '최대 10MB'}
          </div>
          {videoInfo && onRemoveVideo && (
            <IconButton
              icon='close'
              size={24}
              color='black200'
              onClick={(e) => {
                e.stopPropagation();
                onRemoveVideo();
              }}
            />
          )}
        </div>
      </div>
    </button>
  );
};
