import type { CommonAlgorithmInput } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useCallback } from 'react';
import type { UseFormReset } from 'react-hook-form';

interface UseResetAllProps {
  reset: UseFormReset<CommonAlgorithmInput>;
  defaultValues?: CommonAlgorithmInput;
}

export const useResetAll = ({ reset, defaultValues }: UseResetAllProps) => {
  const resetAll = useCallback(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  return { resetAll };
};
