import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';

export const postChatroomCounselorsAdd = async ({
  chatRoomID,
  onlyAddIDs,
}: { chatRoomID: string; onlyAddIDs: string[] }) => {
  const params = {
    chatRoomID,
    userIDs: onlyAddIDs,
  };
  const { data } = await apiClient.v3.apiChatroomElCounselorAdd(params);
  return data;
};
