import { SHARED_UTILS } from '@shared-utils/utils';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import type { PostSendEventParams } from '../types';

export const postSendEvent = async ({ chatRoomID, hserviceID, heventID }: PostSendEventParams) => {
  const body = {
    chatRoomID,
    hserviceID,
    heventID,
  };

  const { data } = await apiClient.v3.apiChatElSendHevent(body);
  return SHARED_UTILS.api.checkApiResponse(data);
};
