export const EDITABLE_TABLE_HEADERS_WITH_FILTER = ['초/재진', '성별', '치료태그', '국적'] as const;

export const EDITABLE_TABLE_HEADERS = [
  'No',
  '수신거부',
  '고객명',
  '차트번호',
  '초/재진',
  '휴대폰번호',
  '생년월일',
  '성별',
  '치료태그',
  '국적',
] as const;

export const REQUIRED_HEADERS = ['고객명', '휴대폰번호'] as const;
