import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiServiceSettingsElToBeDisplayedPatientsFieldsParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';

const fetchDisplayedPatientsFields = async (
  params: ApiServiceSettingsElToBeDisplayedPatientsFieldsParams,
) => {
  const response = await apiClient.v3.apiServiceSettingsElToBeDisplayedPatientsFields(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useGetUsingColumns = () => {
  const { hospitalID } = useSelectedHospitalInfo();

  const { data: usingColumns } = useQuery({
    queryKey: [QUERY_KEY.apiServiceSettingsElToBeDisplayedPatientsFields, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchDisplayedPatientsFields(queryKey[1]),
  });

  return usingColumns;
};
