import { apiClient } from '@apis/instances/api-client';
import type { TempChatRoomHServiceLastMessageUsers } from '@templates/CustomerChat/components/ChattingList/ChattingList';
import ChattingListItem from '@templates/CustomerChat/components/ChattingList/components/ChattingListBody/components/ChattingListItem/ChattingListItem';
import type { TempChatRoomHServiceLastMessageUser } from '@templates/CustomerChat/components/ChattingList/hooks/use-chatting-list';
import type { ChatInfiniteQueryControls } from '@templates/CustomerChat/components/ChattingList/hooks/use-chatting-list-container';
import type { ActiveDropdown } from '@templates/CustomerChat/components/ChattingList/types/active-dropdown';
import { selectedChattingListTabState } from '@templates/CustomerChat/states/selected-chatting-list-tab';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import type { ChatRoomID } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useIntersectionObserver } from 'hooks/use-intersection-observer';
import { useAtomValue } from 'jotai/index';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

export type HandleSelectedChatRoomParmas = {
  chatRoomID: string;
  chatRoomParentHServiceID: string;
  patientIDInChattingListUserID: string;
  chatRoomHserviceID: string;
  isLinked: boolean;
};
interface ChattingListAdvanceProps {
  chatRooms: TempChatRoomHServiceLastMessageUser[];
  selectedChatRoomID: ChatRoomID;
  infiniteQueryControls: ChatInfiniteQueryControls;
  onHandleSelectChatRoom: (params: HandleSelectedChatRoomParmas) => void;
}

export const postChatroomUpdateRead = async (chatRoomID: string) => {
  const body = {
    chatRoomID,
  };
  const { data } = await apiClient.v3.apiChatroomElUpdateRead(body);
  return SHARED_UTILS.api.checkApiResponse(data) as TempChatRoomHServiceLastMessageUsers;
};

export default function ChattingListBody({
  chatRooms,
  selectedChatRoomID,
  infiniteQueryControls,
  onHandleSelectChatRoom,
}: ChattingListAdvanceProps) {
  const selectedTab = useAtomValue(selectedChattingListTabState);

  const [activeDropdown, setActiveDropdown] = useState<ActiveDropdown | null>(null);
  const [targets, setTargets] = useState<(Element | null)[]>([]);

  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const scrollbarRef = useRef<{
    scrollTo: (index: number, behavior?: ScrollBehavior) => void;
  }>(null);

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = infiniteQueryControls;

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  useIntersectionObserver(
    handleIntersection,
    { root: null, rootMargin: '20px', threshold: 0.5 },
    targets,
  );

  useEffect(() => {
    if (loadMoreRef.current) {
      setTargets([loadMoreRef.current]);
    }
  }, [loadMoreRef.current]);

  const handleDropdown = useCallback((activeDropdown: ActiveDropdown, isActive: boolean) => {
    const { key, chatRoomID } = activeDropdown;
    setActiveDropdown(isActive ? { key, chatRoomID } : null);
  }, []);

  useEffect(() => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollTo(0, 'auto');
    }
  }, [selectedTab]);

  return (
    <>
      <Scrollbar disabledX ref={scrollbarRef}>
        {chatRooms?.map((chatRoom) => {
          return (
            <ChattingListItem
              key={chatRoom._id}
              isSelectedItem={chatRoom._id === selectedChatRoomID}
              chatRoom={chatRoom}
              activeDropdown={activeDropdown}
              onHandleDropdown={handleDropdown}
              onHandleSelectChatRoom={onHandleSelectChatRoom}
            />
          );
        })}
        <div ref={loadMoreRef} style={{ height: 1 }} />
      </Scrollbar>
    </>
  );
}
