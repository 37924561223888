import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import Logo from 'afterdoc-design-system/components/Foundations/Logo/Logo';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import type { InstagramAccountLinkageHandlerBodyRequest } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { INSTAGRAM_LOGIN_REDIRECT_URL } from 'afterdoc-saas-web/config/url';
import { useCallback, useEffect, useState } from 'react';
import { ApiError } from 'utils/api/check-api-response';
import { SHARED_UTILS } from 'utils/utils';

const INSTAGRAM_ERROR_CODE = 500;

const postInstagramAccountLinkage = async (params: InstagramAccountLinkageHandlerBodyRequest) => {
  const { data } = await apiClient.v3.instagramAccountLinkageHandler(params);

  return SHARED_UTILS.api.checkApiResponse(data);
};

type Status = 'success' | 'failed' | 'pending' | 'instagramFailed';

export default function InstagramLinkResult() {
  const [status, setStatus] = useState<Status>('pending');

  const handleLinkage = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get('code');
    const hospitalID = urlParams.get('state');

    if (!authorizationCode || !hospitalID) {
      console.error('Missing parameters:', { authorizationCode, hospitalID });
      setStatus('failed');
      return;
    }

    try {
      const { isLinkedSuccess } = await postInstagramAccountLinkage({
        hospitalID,
        authorizationCode,
        redirectUri: INSTAGRAM_LOGIN_REDIRECT_URL,
        isReconnection: true,
      });

      setStatus(isLinkedSuccess ? 'success' : 'failed');
    } catch (error) {
      if (error instanceof ApiError) {
        console.error('Failed to link Instagram account:', error.message);

        if (error.code === INSTAGRAM_ERROR_CODE) {
          setStatus('instagramFailed');
          return;
        }
      }
      setStatus('failed');
    }

    // 개발 편의성을 위한 로그
    console.info('code:', String(authorizationCode));
  }, []);

  useEffect(() => {
    if (status === 'pending') {
      handleLinkage();
    }
  }, [status, handleLinkage]);

  const renderContent = () => {
    switch (status) {
      case 'pending':
        return <BaseLoading />;
      case 'success':
        return (
          <div className='flex-col-center gap-10'>
            <Icon name='done' size={48} color='white600' />
            <h2 className='text-Header14'>
              연동이 성공적으로 완료되었습니다. <br />이 창을 닫아주세요.
            </h2>
          </div>
        );
      case 'failed':
        return (
          <div className='flex-col-center gap-10'>
            <Icon name='warning' size={48} color='red500' />
            <h2 className='text-Header14'>
              인스타그램 연동에 실패했습니다. <br />이 창을 닫고 다시 시도해주세요.
            </h2>
          </div>
        );
      case 'instagramFailed':
        return (
          <div className='flex flex-col items-center justify-center'>
            <Logo name='LogoColor' size={220} />
            <div className='mt-16 text-Header16 text-blue500'>
              서비스 이용에 불편을 드려 죄송합니다.
            </div>
            <div className='mt-20 text-Body13 text-black500'>
              인스타그램 연동 중 에러가 발생하였습니다.
              <br />
              애프터닥헬프데스크로 문의해 주세요.
            </div>
            <Divider type='line' className='my-18' />
            <div className='text-Body11 text-black500'>
              지속적인 오류가 발생하는 경우 아래 카카오톡 채널로 문의해주세요.
            </div>
            <div className='mt-8 flex items-center gap-4'>
              <Icon name='logo-kakaotalk' size={24} className='rounded-r6' />
              <div>
                <span className='font-bold text-Body14 text-black200'>카카오톡 문의: </span>
                <a
                  href='http://pf.kakao.com/_kHcjn/chat'
                  className='font-bold text-Body14 text-blue500 underline'>
                  @애프터닥헬프데스크
                </a>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Scrollbar isAlwaysVisible>
      <div className='flex-full-center text-center'>{renderContent()}</div>
    </Scrollbar>
  );
}
