import { IMAGE_EXTENSIONS } from 'afterdoc-saas-web/shared/utils/fileUpload/constants/file';
import { FIVE_MB } from 'afterdoc-saas-web/shared/utils/fileUpload/functions/validateFileSize';
import { getFileExt } from 'afterdoc-saas-web/shared/utils/fileUpload/services/FileServices';

export const isKakaoImageFilter = (file: File): boolean => {
  const { size } = file;
  const isUnder5MB = size <= FIVE_MB;
  const ext = getFileExt(file).toLowerCase();

  return IMAGE_EXTENSIONS.includes(ext) && isUnder5MB;
};
