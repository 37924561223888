import { algorithmModeState as marketingAlgorithmModeState } from '@templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/algorithm-mode';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { overlayPageService } from 'afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { OVERLAY_PAGE_ID } from 'afterdoc-saas-web/shared/constants/overlay-page-id';
import { UNSAVED_CHANGES_WARNING_ID } from 'afterdoc-saas-web/shared/hooks/gnb/use-navigation-blocker';
import type { MarketingAutomationAPIFormValues } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { originAlgorithmDataState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/origin-algorithm-data';
import { useMarketingAutomationResetAll } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/hooks/use-marketing-automation-reset-all';
import { useUnsavedChangesWarning } from 'afterdoc-saas-web/templates/Automation/containers/shared/hooks/use-unsaved-changes-warning';
import { useAtomValue } from 'jotai/index';
import { useFormContext } from 'react-hook-form';

export default function RegisterMarketingAutomationDialogHeader() {
  const { getValues, reset, watch } = useFormContext<MarketingAutomationAPIFormValues>();

  const marketingAlgorithmMode = useAtomValue(marketingAlgorithmModeState);
  const originAlgorithmData = useAtomValue(originAlgorithmDataState);

  const { resetAll } = useMarketingAutomationResetAll({
    reset,
  });

  const handleConfirmLeave = () => {
    modalService.pop();
    overlayPageService.popById(OVERLAY_PAGE_ID['register-marketing-automation-dialog']);
  };

  const handleCancelLeave = () => {
    modalService.popById(UNSAVED_CHANGES_WARNING_ID);
  };

  const { showWarning } = useUnsavedChangesWarning<MarketingAutomationAPIFormValues>({
    formMethods: {
      originalValues: originAlgorithmData,
      getValues,
      watch,
    },
    onReset: resetAll,
    onConfirm: handleConfirmLeave,
    onCancel: handleCancelLeave,
  });

  return (
    <div className='flex w-full items-center justify-between bg-white50 px-20 pt-20 pb-8'>
      <div className='w-fit text-Header16 text-black700'>
        {`마케팅자동화 ${marketingAlgorithmMode.mode !== 'EDIT' ? '등록' : '수정'}`}
      </div>
      <button type='button' onClick={showWarning} className='text-Body14 text-black700'>
        닫기
      </button>
    </div>
  );
}
