import CommonKakaoDescription from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/ChannelLinkageOverlay/components/CommonKakaoDescription';
import InstagramDescription from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/ChannelLinkageOverlay/components/InstagramDescription';
import type { LinkageType } from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/types/linkage-type';

type ChannelLinkageOverlayProps = {
  linkageType: LinkageType;
  removeOverlay: () => void;
};

export default function ChannelLinkageOverlay({
  linkageType,
  removeOverlay,
}: ChannelLinkageOverlayProps) {
  const linkageComponentMap = new Map<LinkageType, JSX.Element>([
    [
      'CONSULTATION_TALK',
      <CommonKakaoDescription
        key='CONSULTATION_TALK'
        channel='상담톡'
        removeOverlay={removeOverlay}
      />,
    ],
    [
      'ALIM_TALK',
      <CommonKakaoDescription key='ALIM_TALK' channel='알림톡' removeOverlay={removeOverlay} />,
    ],
    ['INSTAGRAM', <InstagramDescription key='INSTAGRAM' removeOverlay={removeOverlay} />],
  ]);

  const Component = linkageComponentMap.get(linkageType) ?? null;

  return (
    <div className='absolute top-0 right-[-80px] bottom-0 left-[-9px] z-10 flex flex-col items-center justify-center rounded-r16 bg-white/80 backdrop-blur-[10px]'>
      {Component}
    </div>
  );
}
