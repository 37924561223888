import type { CounselAutomationAPIFormValues } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { isNationalitySelectBoxOpenedState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselBaseSetting/states/is-nationality-select-box-opened';
import {
  checkedLayersState,
  checkedOrphanSubLayersState,
  checkedSubLayersState,
} from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselBaseSetting/states/selected-layers';
import { isEditDisabledByKakaoAlimTalkState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/hooks/states/is-edit-disabled-by-kakao-alim-talk';
import { algorithmModeState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/algorithm-mode';
import {
  selectedContentTabItemState,
  selectedMessageState,
} from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { messageWarningStatesState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-warning-states';
import { originAlgorithmDataState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/origin-algorithm-data';
import { originMessagesState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/origin-message';
import { selectedCounselAutomationTabState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/states/selected-counsel-automation-tab-state';
import { useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';
import type { UseFormReset } from 'react-hook-form';

interface UseCounselAutomationResetAllProps {
  reset: UseFormReset<CounselAutomationAPIFormValues>;
  defaultValues?: CounselAutomationAPIFormValues;
}

export const useCounselAutomationResetAll = ({
  reset,
  defaultValues,
}: UseCounselAutomationResetAllProps) => {
  const resetOriginMessages = useResetAtom(originMessagesState);
  const resetOriginAlgorithmData = useResetAtom(originAlgorithmDataState);
  const resetMessageWarningStates = useResetAtom(messageWarningStatesState);
  const resetCheckedLayers = useResetAtom(checkedLayersState);
  const resetCheckedSubLayers = useResetAtom(checkedSubLayersState);
  const resetCheckedOrphanSubLayers = useResetAtom(checkedOrphanSubLayersState);
  const resetContentTabIndex = useResetAtom(selectedContentTabItemState);
  const resetSelectedCounselAutomationTab = useResetAtom(selectedCounselAutomationTabState);
  const resetSelectedMessage = useResetAtom(selectedMessageState);
  const resetMessageMode = useResetAtom(algorithmModeState);
  const resetIsNationalitySelectBoxOpened = useResetAtom(isNationalitySelectBoxOpenedState);
  const resetIsEditDisabledByKakaoAlimTalk = useResetAtom(isEditDisabledByKakaoAlimTalkState);
  const resetAll = useCallback(() => {
    resetCheckedLayers();
    resetCheckedSubLayers();
    resetCheckedOrphanSubLayers();
    resetContentTabIndex();
    resetSelectedCounselAutomationTab();
    resetSelectedMessage();
    resetMessageMode();
    resetIsNationalitySelectBoxOpened();
    resetOriginMessages();
    resetOriginAlgorithmData();
    resetMessageWarningStates();
    resetIsEditDisabledByKakaoAlimTalk();
    reset(defaultValues);
  }, [
    reset,
    resetCheckedLayers,
    resetCheckedSubLayers,
    resetCheckedOrphanSubLayers,
    resetContentTabIndex,
    resetSelectedCounselAutomationTab,
    resetSelectedMessage,
    resetMessageMode,
    resetIsNationalitySelectBoxOpened,
    resetOriginMessages,
    resetMessageWarningStates,
    resetOriginAlgorithmData,
    resetIsEditDisabledByKakaoAlimTalk,
    defaultValues,
  ]);

  return { resetAll };
};
