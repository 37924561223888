import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import TextArea from 'afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  ApiPatientsElDetailData,
  ApiPatientsMemosElFindOneParams,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { patientIdState } from 'afterdoc-saas-web/states/patient-id';
import { format, parseISO } from 'date-fns';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';

const getPatientsMemo = async (params: ApiPatientsMemosElFindOneParams) => {
  const { data } = await apiClient.v3.apiPatientsMemosElFindOne(params);
  return data.data;
};

const postPatientsMemo = async (params: ApiPatientsMemosElFindOneParams, text: string) => {
  const body = {
    ...params,
    content: text,
  };
  const response = await apiClient.v3.apiPatientsMemosElUpdate(body);
  return response;
};

export default function CustomerInfoCustomerMemo() {
  const queryClient = useQueryClient();

  const patientId = useAtomValue(patientIdState);
  const { userID: userIDFromQuery, patientId: patientIdFromQuery } = queryClient.getQueryData([
    QUERY_KEY.apiPatientsElDetail,
    { patientId },
  ]) as ApiPatientsElDetailData['data'];
  const { hospitalID } = useSelectedHospitalInfo();

  const params: ApiPatientsMemosElFindOneParams = useMemo(
    () => ({
      hospitalID,
      userID: userIDFromQuery,
      hserviceID: patientIdFromQuery,
    }),
    [hospitalID, userIDFromQuery, patientIdFromQuery],
  );

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEY.apiPatientsMemosElFindOne, params] as const,
    queryFn: ({ queryKey }) => getPatientsMemo(queryKey[1]),
  });

  const mutation = useMutation({
    mutationFn: (text: string) => postPatientsMemo(params, text),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiPatientsMemosElFindOne, params],
      });
      toastService.successMsg({ text: '상담메모를 저장했습니다.' });
    },
    onError: (error) => {
      console.error('Error saving memo:', error);
      toastService.errorMsg({ text: '상담메모 저장을 실패했습니다.' });
    },
  });

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);
  const [text, setText] = useState<string>();
  const [originalText, setOriginalText] = useState<string>('');

  const bottomText = useMemo(() => {
    if (!data) return '- / -';

    const { createdAt, updatedAt, modifierID } = data;

    if (createdAt && updatedAt && createdAt === updatedAt) {
      return '- / -';
    }

    const formattedDate =
      updatedAt && !Number.isNaN(parseISO(updatedAt).getTime())
        ? format(parseISO(updatedAt), 'yyyy-MM-dd HH:mm')
        : '-';

    const formattedName = modifierID?.realName || '-';

    return `${formattedDate} / ${formattedName}`;
  }, [data]);

  useEffect(() => {
    if (!data) return;
    setText(data.content ?? '');
    setOriginalText(data.content ?? '');
  }, [data]);

  useEffect(() => {
    setIsSaveButtonDisabled(text === originalText);
  }, [text, originalText]);

  const handleSave = () => {
    mutation.mutate(text ?? '');
    setOriginalText(text ?? '');
    setIsSaveButtonDisabled(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
    setText(event.currentTarget.value);
  };

  if (isLoading) {
    return (
      <div className='flex h-full w-full flex-col gap-4'>
        <div className='text-Header14 text-black700'>상담메모</div>
        <div className='mb-16 text-Body12 text-black500'>
          입력된 내용은 채팅방에서도 확인할 수 있습니다.
        </div>
        <div className='flex-full-center'>
          <BaseLoading />
        </div>
      </div>
    );
  }

  return (
    <div className='flex h-full w-full flex-col gap-4'>
      <div className='text-Header14 text-black700'>상담메모</div>
      <div className='mb-16 text-Body12 text-black500'>
        입력된 내용은 채팅방에서도 확인할 수 있습니다.
      </div>
      <TextArea
        placeholder='상담 시 참고해야 할 고객정보에 대해 입력해 주세요.'
        onSave={handleSave}
        onChange={handleChange}
        onInput={handleInput}
        leftBottomText={bottomText}
        value={text}
        isSaveButtonDisabled={isSaveButtonDisabled}
      />
    </div>
  );
}
