import { API_URL } from '@config/url';
import { SHARED_UTILS } from '@shared-utils/utils';
import axios from 'axios';

const metaDataAxiosInstance = axios.create({
  timeout: 30000,
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

metaDataAxiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');

    if (token) {
      config.headers.Authorization = JSON.parse(token);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export type ResMetadata = {
  title: string;
  description: string;
  image: string;
  binaryData: string;
};

export const fetchMetadata = async (url: string | undefined) => {
  if (!url) return null;
  const { data } = await metaDataAxiosInstance.get('/v3/api/metadata/get', { params: { url } });
  const res = SHARED_UTILS.api.checkApiResponse(data) as ResMetadata;
  if (!res) return null;
  return res;
};
