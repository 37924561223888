import type { DataNotificationPayload } from '@shared-hooks/use-data-event-bus-handler/event-bus';
import { useQueryClient } from '@tanstack/react-query';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { INTEGRATION_ALL_LINKAGE_HISTORY_HANDLER } from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/hooks/use-Linkage-history';
import { useDataEventBusHandler } from 'hooks/use-data-event-bus-handler/use-data-event-bus-handler';
import { useCallback, useMemo } from 'react';

export const useRefreshInstagramLinkageHandler = () => {
  const { hospitalID } = useSelectedHospitalInfo();
  const queryClient = useQueryClient();

  const handleNotification = useCallback(
    async (payload: DataNotificationPayload) => {
      const { hospitalID: targetHospitalID } = payload.data;
      if (targetHospitalID === hospitalID) {
        queryClient.invalidateQueries({
          queryKey: [
            INTEGRATION_ALL_LINKAGE_HISTORY_HANDLER,
            { hospitalID, linkageType: 'INSTAGRAM' },
          ],
        });
      }
    },
    [hospitalID, queryClient.invalidateQueries],
  );

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_InstagramLinkageRefresh_el'],
        handler: handleNotification,
      },
    ],
    [handleNotification],
  );

  useDataEventBusHandler(notificationConfigs);
};
