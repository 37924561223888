import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { neverCheck } from 'afterdoc-saas-web/shared/utils/never-check';
import { ROOM_TYPE_MAP } from 'afterdoc-saas-web/templates/CustomerChat/constants/ROOM_TYPE_MAP';
import type { RoomType } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';

type RoomTypeIconStateProps = {
  roomType: RoomType | null;
  size?: 17;
  isUsageHaltedDisabledSending?: boolean;
};

export default function RoomTypeIconState({
  roomType,
  size = 17,
  isUsageHaltedDisabledSending,
}: RoomTypeIconStateProps) {
  if (roomType === null) throw new Error('Room type is null');

  const isGrayAfterDocIcon = isUsageHaltedDisabledSending && roomType === ROOM_TYPE_MAP.afterdoc;
  if (isGrayAfterDocIcon) {
    return <Icon name='afterdoc-non' size={size} />;
  }

  switch (roomType) {
    case ROOM_TYPE_MAP.kakao:
      return <Icon name='kakaotalk' size={size} />;
    case ROOM_TYPE_MAP.instagram:
      return <Icon name='instagram' size={size} />;
    case ROOM_TYPE_MAP.afterdoc:
    case ROOM_TYPE_MAP.manager:
      return <Icon name='afterdoc' size={size} />;
    default:
      roomType satisfies never;
      neverCheck(roomType);
  }
}
