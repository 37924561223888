import { useImageCompression } from 'afterdoc-saas-web/shared/hooks/files/images/use-image-compression';
import { extractImageObjectFromS3Url } from 'afterdoc-saas-web/shared/utils/fileUpload/functions/extract-file-name';
import { getImageMimeType } from 'afterdoc-saas-web/shared/utils/fileUpload/functions/get-image-mime-type';
import type { Options } from 'browser-image-compression';
import { useEffect, useState } from 'react';

interface S3ImageInfo {
  url: string;
  fileName: string;
  originalUrl: string;
}

interface ImageStatus {
  data: S3ImageInfo | null;
  isLoading: boolean;
  error: Error | null;
}

export function useFetchS3ImageBlobWithCompression(
  imageUrls: string[],
  compressionOptions?: Options,
) {
  const [imageStatuses, setImageStatuses] = useState<Record<string, ImageStatus>>({});
  const { compressImage } = useImageCompression(compressionOptions);

  const fetchImage = async (url: string) => {
    try {
      setImageStatuses((prev) => ({
        ...prev,
        [url]: { ...prev[url], isLoading: true, error: null },
      }));

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Origin: url,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const originalBlob = await response.blob();
      const fileName = extractImageObjectFromS3Url(url).fileName;

      // Blob의 type을 파일 확장자에 맞게 변경
      const imageBlob = new Blob([originalBlob], {
        type: getImageMimeType(fileName),
      });

      const { compressedDataUrl } = await compressImage(imageBlob, fileName);

      setImageStatuses((prev) => ({
        ...prev,
        [url]: {
          isLoading: false,
          error: null,
          data: {
            url: compressedDataUrl,
            fileName,
            originalUrl: url,
          },
        },
      }));
    } catch (error) {
      console.error(`이미지 fetch 실패 (${url}):`, error);
      setImageStatuses((prev) => ({
        ...prev,
        [url]: {
          ...prev[url],
          isLoading: false,
          error: error instanceof Error ? error : new Error('Unknown error'),
        },
      }));
    }
  };

  useEffect(() => {
    // 초기 상태 설정
    setImageStatuses((prev) => {
      const newStatuses: Record<string, ImageStatus> = {};
      for (const url of imageUrls) {
        newStatuses[url] = {
          data: null,
          isLoading: false,
          error: null,
        };
      }
      return { ...prev, ...newStatuses };
    });

    // 각 이미지 개별적으로 fetch
    for (const url of imageUrls) {
      fetchImage(url);
    }
  }, [imageUrls, compressionOptions]);

  // 결과 정리
  const results = Object.values(imageStatuses)
    .filter((status) => status.data)
    .map((status) => status.data) as S3ImageInfo[];

  return {
    imageStatuses,
    results,
    isAllLoaded: Object.values(imageStatuses).every((status) => !status.isLoading),
    hasAnyError: Object.values(imageStatuses).some((status) => status.error !== null),
  };
}
