import type { PatientIconInfoType } from '../hooks/use-chat-top-patient';
import {
  type ChannelClosedStateSwitchParams,
  channelClosedStateSwitch,
} from './channel-closed-state-switch';

type IsDisabledChatParams = ChannelClosedStateSwitchParams & {
  iconSet: PatientIconInfoType | null;
};

export const isDisabledChat = ({ roomType, ikakaoInfo, iconSet }: IsDisabledChatParams) => {
  const { isClosedChannelChat } = channelClosedStateSwitch({ roomType, ikakaoInfo });

  const isUsageHaltedDisabledSending =
    isClosedChannelChat || (iconSet?.isGrayAfterDocIcon && !iconSet?.isKakaoIcon);

  return isUsageHaltedDisabledSending;
};
