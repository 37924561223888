import { SHARED_UTILS } from '@shared-utils/utils';
import { useInfiniteQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiChatElFindIdParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { chatRoomIDSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

const AFTER_PREV_NUM = 1; //next fetch를 할때 최초 id 객체를 제외하기 위한 상수
const INITIAL_NUM = 10; //최초 중간 데이터 가져 올시 세팅 되는 interval num 미세 조정
const INITIAL_INTERVAL_NUM = 5; //최초 중간 데이터 가져 올시 세팅 되는 interval num 미세 조정
const INTERVAL_NUM = 20;

const getChat = async (query: ApiChatElFindIdParams) => {
  const { data } = await apiClient.v3.apiChatElFindId(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useInfiniteMessageIDChatData = ({
  selectedMessageID,
}: { selectedMessageID: string | null }) => {
  const chatRoomID = useAtomValue(chatRoomIDSelector);

  const query = useMemo(
    () => ({
      chatRoomID,
      _id: selectedMessageID,
      key: 'sentAt',
      direction: 'prev',
      isRemoved: false,
    }),
    [chatRoomID, selectedMessageID],
  );

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    fetchPreviousPage,
    hasPreviousPage,
    isFetchingPreviousPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEY.apiChatElFindId, query],
    queryFn: ({ pageParam }) => {
      const { direction, skip, limit } = pageParam;
      return getChat({ ...query, direction, skip, limit } as ApiChatElFindIdParams);
    },
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (!lastPage?.length) return undefined;
      const { direction, skip, limit } = lastPageParam;

      const isNextFetchingFirst = direction === 'prev';
      const newSkip = isNextFetchingFirst ? AFTER_PREV_NUM : skip + limit;
      const newIntervalNum = newSkip === 1 ? INITIAL_INTERVAL_NUM : INTERVAL_NUM;

      return { direction: 'next', skip: newSkip, limit: newIntervalNum };
    },
    getPreviousPageParam: (firstPage, _, firstPageParam) => {
      if (!firstPage?.length) return undefined;

      const { skip, limit } = firstPageParam;
      const newSkip = skip + limit;

      return { direction: 'prev', skip: newSkip, limit: INTERVAL_NUM };
    },
    initialPageParam: { direction: 'prev', skip: 0, limit: INITIAL_NUM },
    enabled: selectedMessageID !== null,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 0,
    gcTime: 0,
  });

  // 페이지별 direction에 따라 정렬
  const newMessages = [];

  for (const index in data?.pageParams) {
    const numberIndex = Number(index);
    const param = data?.pageParams[numberIndex] as { direction: string };
    const isPrevDirection = param?.direction === 'prev' || param?.direction === 'both';
    const newPushArray = isPrevDirection
      ? (data?.pages[numberIndex] ?? [])
      : (data?.pages[numberIndex].slice().reverse() ?? []);
    newMessages.unshift(...newPushArray);
  }

  const fetchNextPageControlled = async () => {
    if (isFetchingPreviousPage || isFetchingNextPage) return;
    await fetchNextPage();
  };

  const fetchPreviousPageControlled = async () => {
    if (isFetchingNextPage || isFetchingPreviousPage) return;
    await fetchPreviousPage();
  };

  return {
    data: newMessages,
    refetch,
    fetchNextPage: fetchNextPageControlled,
    hasNextPage,
    isFetchingNextPage,
    fetchPreviousPage: fetchPreviousPageControlled,
    hasPreviousPage,
    isFetchingPreviousPage,
    isLoading,
  };
};
