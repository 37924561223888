import { useMutation, useQueryClient } from '@tanstack/react-query';
import LinkedChatRoomManagerButton from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoTitle/components/LinkedChatRoomManagerButton';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import TextButton from 'afterdoc-design-system/components/Atoms/Button/TextButton';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiPatientsElDetailData } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import ALIntegrationEdit from 'afterdoc-saas-web/shared/components/ALIntegrationEdit/ALIntegrationEdit';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { useHandleCustomerInfo } from 'afterdoc-saas-web/shared/hooks/overlayPage/use-handle-customer-detail-info';
import { patientIdState } from 'afterdoc-saas-web/states/patient-id';
import { useSyncChattingListAndInfoTop } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/CustomerInfoTop/hooks/use-sync-chatting-list-and-info-top';
import { postCustomerDelete } from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoTitle/apis';
import ReInvitationButton from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoTitle/components/ReInvitationButton';
import { useAtomValue } from 'jotai';
import { Suspense, useEffect } from 'react';

export default function CustomerInfoTitleContainer() {
  const queryClient = useQueryClient();

  const { updateChattingListAndInfoTop } = useSyncChattingListAndInfoTop();
  const { hospitalID, integrationSetting } = useSelectedHospitalInfo();
  const { authorizationTypeID } = useUserInfo();

  const patientId = useAtomValue(patientIdState);

  // 전능연동된 병원
  const isSmartDoctorLinkedHospital =
    integrationSetting?.isRemoved === false && integrationSetting?.clientToken?.length > 0;
  // 고객 이용중단 권한이 있음
  const canBanPatientToService = !!authorizationTypeID?.canBanPatientToService;

  const {
    // 고객 id
    patientId: patientIdFromQuery,
    // 고객명
    name,
    // 애프터닥 가입 여부
    isJoinedUserToAfterDoc,
    // 카카오톡 연동 여부
    hasKakaoLinkage,
    // 스마트닥터 연동 여부
    hasSmartDoctorLinkage,
    // 이용제외 여부
    isClosed,
  } = queryClient.getQueryData([
    QUERY_KEY.apiPatientsElDetail,
    { patientId },
  ]) as ApiPatientsElDetailData['data'];

  const { closeCustomerInfo } = useHandleCustomerInfo(patientIdFromQuery);

  const deleteCustomerMutation = useMutation({
    mutationFn: (params: { hserviceID: string; closeReason: string }) => postCustomerDelete(params),
    onSuccess: () => {
      toastService.successMsg({
        text: '이용중단이 완료되었습니다.',
      });
      closeCustomerInfo();
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElListOrSearch] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElCount, { hospitalID }] });
      modalService.popById('interrupt-customer');
    },
    onError: (error) => {
      console.warn('error', error);
      toastService.errorMsg({
        text: '처리를 실패했습니다.',
      });
    },
  });

  const handleBackClick = () => {
    closeCustomerInfo();
  };

  const onDeleteCustomer = () => {
    deleteCustomerMutation.mutate({
      hserviceID: patientIdFromQuery,
      closeReason: '이용중단',
    });
  };

  const onOpenALIntegrationEdit = () => {
    dialogService.push(
      <ALIntegrationEdit patientId={patientIdFromQuery} dialogId={'ALIntegrationEdit'} />,
      {
        width: 'auto',
        id: 'ALIntegrationEdit',
      },
    );
    updateChattingListAndInfoTop();
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        modalService.popById('leave-without-save');
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className='flex items-center gap-6'>
      <IconButton icon='chevron-left' size={24} onClick={handleBackClick} className='pt-20' />
      <div className='w-full'>
        <Title
          wrapperClassName='px-0 pb-0'
          title={`${name}님의 상세정보`}
          adjacentChildren={{
            children: (
              <div className='flex items-center gap-8'>
                {isClosed ? (
                  <div className='flex items-center gap-4 rounded-r10 bg-disabled py-[3.5px] pr-10 pl-6'>
                    <Icon name='warning' color='black500' size={16} />
                    <span className='text-Body12 text-black500'>이용중단</span>
                  </div>
                ) : (
                  <>
                    <HoverTooltip
                      portalZindex={9999}
                      text={`애프터닥 ${isJoinedUserToAfterDoc ? '가입' : '미가입'} 고객입니다.`}
                      position='bottomRight'
                      wrapperProps={{
                        className: 'cursor-default',
                      }}>
                      {isJoinedUserToAfterDoc ? (
                        <Icon name='afterdoc' />
                      ) : (
                        <Icon name='afterdoc-non' />
                      )}
                    </HoverTooltip>
                    {hasKakaoLinkage && (
                      <HoverTooltip
                        text='카카오톡으로 유입된 고객입니다.'
                        portalZindex={9999}
                        position='bottomRight'
                        wrapperProps={{
                          className: 'cursor-default',
                        }}>
                        <Icon name='kakaotalk' />
                      </HoverTooltip>
                    )}
                  </>
                )}
                {isSmartDoctorLinkedHospital && (
                  <HoverTooltip
                    text={`스마트닥터에 계정 ${hasSmartDoctorLinkage ? '연동된' : '미연동된'} 고객입니다.`}
                    offsetY={-4}
                    position='bottomRight'>
                    <button
                      type='button'
                      className='flex items-center gap-4 rounded-r6 border border-white600 bg-white50 px-5 py-3'
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpenALIntegrationEdit();
                      }}>
                      <Icon
                        name={hasSmartDoctorLinkage ? 'smart-doctor' : 'smart-doctor-non'}
                        size={17}
                      />
                      <Icon name='chevron-right' size={16} color='black200' />
                    </button>
                  </HoverTooltip>
                )}
              </div>
            ),
            position: 'right',
          }}
          trailingChildren={
            <div className='flex gap-40'>
              <LinkedChatRoomManagerButton hserviceId={patientIdFromQuery} />
              <Suspense>
                <ReInvitationButton />
              </Suspense>
              {!isClosed && canBanPatientToService && (
                <TextButton
                  onClick={() => {
                    modalService.defaultWarning({
                      id: 'interrupt-customer',
                      title: `${name}님을 이용중단할까요?`,
                      contents: (
                        <div className='whitespace-pre-wrap'>
                          {
                            "이용중단 시 고객이 사용중인 앱 서비스의 이용이 제한됩니다. 이용중단 하시려면 '네'를 선택해 주세요."
                          }
                        </div>
                      ),
                      onConfirm: onDeleteCustomer,
                    });
                  }}>
                  이용중단
                </TextButton>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
}
