import { StatusType } from '@templates/HospitalManual/components/HospitalManualContent/components/ManualCautionMessage/types/status-type';
import {
  InspectionStatus
} from "@templates/HospitalManual/components/HospitalManualContent/components/ManualCautionMessage/types/inspection-status";

export const keyToStatusTypeMap: Record<InspectionStatus, StatusType> = {
  REG: 'requested', // 등록
  REQ: 'requested', // 검수요청
  RDY: 'approved', // 발송전
  ACT: 'approved', // 정상
  APR: 'approved', // 승인
  REJ: 'rejected', // 반려
  STP: 'blocked', // 차단
  BLK: 'blocked', // 차단
  DMT: 'dormant', // 휴면
} as const;
