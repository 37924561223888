import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';

export default function ExpiredImage({ isFirst = true }: { isFirst?: boolean }) {
  return (
    <div className='content flex h-[150px] w-[160px]'>
      <div className='h-full w-full rounded-r10 bg-black200 opacity-50' />
      {isFirst && (
        <div className='absolute inset-0 flex-center flex-col gap-6'>
          <Icon name='image-off-outline' color='black500' size={40} />
          <span className='text-Body13 text-black700'>만료됨</span>
        </div>
      )}
    </div>
  );
}
