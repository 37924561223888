import { chatRoomIDSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import type { TAB_NAMES } from '../Manual';

type TabNameType = (typeof TAB_NAMES)[number];

export const useChangedChatRoomIDDefaultTab = () => {
  const [selectedTab, setSelectedTab] = useState<TabNameType>('원내매뉴얼');
  const chatRoomID = useAtomValue(chatRoomIDSelector);

  useEffect(() => {
    if (chatRoomID) {
      selectedTab === '콘텐츠' && setSelectedTab('원내매뉴얼');
    }
  }, [chatRoomID]);

  return { selectedTab, setSelectedTab };
};
