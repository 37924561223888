// const Button = lazy(() => import('remote/Button'));

export default function TaewoongTestPageDev() {
  return (
    <div className='p-16'>
      {/* <Suspense fallback={<div>Loading...</div>}>
        <Button onClick={() => alert('버튼이 클릭되었습니다!')} variant='primary'>
          모듈 페더레이션 테스트 버튼
        </Button>
      </Suspense> */}
    </div>
  );
}
