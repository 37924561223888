import ParentDropdown from '@templates/CustomerChat/components/ParentDropDown/ParentDropdown';
import { useDropdown } from '@templates/CustomerChat/components/ParentDropDown/hooks/use-dropdown';
import { neverCheck } from 'afterdoc-saas-web/shared/utils/never-check';
import BookmarkToggle from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/components/BookmarkToggle/BookmarkToggle';
import { ROOM_TYPE_MAP } from 'afterdoc-saas-web/templates/CustomerChat/constants/ROOM_TYPE_MAP';
import { usePatient } from 'afterdoc-saas-web/templates/CustomerChat/hooks/use-patient';
import {
  type RoomType,
  isLinkedSelector,
  roomTypeSelector,
} from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtomValue } from 'jotai';
import { useChatMessageContext } from '../../ChatMessageContext';
import Message from '../../Message/Message';
import MessageTimestamp from '../../MessageTimestamp';

export default function LeftMessage() {
  const { sentAt, userName } = useChatMessageContext();
  const roomType = useAtomValue(roomTypeSelector);
  const isLinked = useAtomValue(isLinkedSelector);

  const { name } = usePatient();

  const { isDropdownOpen, handleDropdown } = useDropdown();

  const displayPatientNameInMessage = (roomType: RoomType | null) => {
    if (roomType === null) throw new Error('Room type is null');

    switch (roomType) {
      case ROOM_TYPE_MAP.kakao:
        return name;
      case ROOM_TYPE_MAP.instagram:
      case ROOM_TYPE_MAP.manager:
      case ROOM_TYPE_MAP.afterdoc:
        return userName;
      default:
        roomType satisfies never;
        neverCheck(roomType);
    }
  };

  const isOnlyKakao = !isLinked && roomType === ROOM_TYPE_MAP.kakao;

  const dropdownDataParams = { isOnlyKakao };

  return (
    <div className='group relative ml-20 flex flex-col gap-8'>
      <div>
        <div
          className='inline-block max-w-full cursor-pointer text-left text-Header12 text-black200'
          onClick={handleDropdown}>
          {displayPatientNameInMessage(roomType)}
        </div>
        {isDropdownOpen && (
          <ParentDropdown dropdownDataParams={dropdownDataParams} handleToggle={handleDropdown} />
        )}
      </div>
      <div className='flex flex-row items-end justify-start gap-8'>
        <Message />
        <div className='flex flex-col items-start justify-end'>
          <BookmarkToggle />
          {<MessageTimestamp sentAt={sentAt} />}
        </div>
      </div>
    </div>
  );
}
