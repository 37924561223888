import Toggle from 'afterdoc-design-system/components/Atoms/Toggle/Toggle';

interface CautionToggleSectionProps {
  usingCautionMessage: boolean;
  handleToggleChange: (value: boolean) => void;
  disabled: boolean;
}

export const CautionToggleSection = ({
  usingCautionMessage,
  handleToggleChange,
  disabled,
}: CautionToggleSectionProps) => {
  return (
    <div className='flex w-[70px] shrink-0 flex-col justify-center gap-10 pt-20'>
      <span className='text-Header12 text-black500'>사용여부</span>
      <Toggle
        label={usingCautionMessage ? 'ON' : 'OFF'}
        checked={usingCautionMessage}
        onChange={(e) => handleToggleChange(e.target.checked)}
        disabled={disabled}
      />
    </div>
  );
};
