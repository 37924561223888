import { SHARED_UTILS } from '@shared-utils/utils';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  ApiPatientsElDetailData,
  ApiServiceSettingsElCountriesParams,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import FullLoading from 'afterdoc-saas-web/shared/components/FullLoading/FullLoading';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { patientIdState } from 'afterdoc-saas-web/states/patient-id';
import {
  COUNTRY_LIMIT,
  KOREA_COUNTRY_CODE_ISO_ALPHA_3,
} from 'afterdoc-saas-web/templates/CustomerManagement/constants/country-limit';
import { useAtomValue } from 'jotai';
import { Suspense, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CustomerInfoFormContainer from './containers/CustomerInfoForm/CustomerInfoForm.container';
import ServiceManagerMemoContainer from './containers/ServiceManagerMemo/ServiceManagerMemo.container';
import SaveButton from './containers/components/SaveButton';

export type APIFormValues = {
  patientId: ApiPatientsElDetailData['data']['patientId'];
  // 필수값
  name: ApiPatientsElDetailData['data']['name'];
  // 필수값
  phoneNumber: ApiPatientsElDetailData['data']['phoneNumber'];
  birthdate: ApiPatientsElDetailData['data']['birthdate'] | null;
  chartNumber: ApiPatientsElDetailData['data']['chartNumber'] | null;
  gender: ApiPatientsElDetailData['data']['gender'] | null;
  isFirstVisit: ApiPatientsElDetailData['data']['isFirstVisit'] | null;
  treatmentTags: ApiPatientsElDetailData['data']['treatmentTags'];
  nationality: ApiPatientsElDetailData['data']['nationality'] | null;
  wantToDeleteAutomationAppliedTreatmentTag: boolean;
};

const fetchCountriesList = async (params: ApiServiceSettingsElCountriesParams) => {
  const response = await apiClient.v3.apiServiceSettingsElCountries(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function CareAndCustomerInfoInputFormContainer() {
  const queryClient = useQueryClient();

  const patientId = useAtomValue(patientIdState);
  const patientInfo = queryClient.getQueryData([
    QUERY_KEY.apiPatientsElDetail,
    { patientId },
  ]) as ApiPatientsElDetailData['data'];
  const { hospitalID } = useSelectedHospitalInfo();

  const { data: countriesList } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiServiceSettingsElCountries,
      { hospitalID, limit: COUNTRY_LIMIT },
    ] as const,
    queryFn: ({ queryKey }) => fetchCountriesList(queryKey[1]),
    select: (data) =>
      data.filter((country) => country.countryCodeISOAlpha3 !== KOREA_COUNTRY_CODE_ISO_ALPHA_3),
  });

  const memoizedDefaultValues: APIFormValues = useMemo(
    () => ({
      name: patientInfo.name,
      patientId: patientInfo.patientId,
      phoneNumber: patientInfo.phoneNumber,
      birthdate: patientInfo.birthdate,
      chartNumber: patientInfo.chartNumber,
      gender: patientInfo.gender,
      isFirstVisit: patientInfo.isFirstVisit,
      treatmentTags: patientInfo.treatmentTags,
      nationality: patientInfo.nationality,
      wantToDeleteAutomationAppliedTreatmentTag: false,
    }),
    [patientInfo],
  );

  const methods = useForm<APIFormValues>({
    defaultValues: memoizedDefaultValues,
    mode: 'onChange',
  });

  if (!patientInfo) return null;

  return (
    <FormProvider {...methods}>
      <div className='mt-16 mb-60 h-fit flex-col-center justify-between rounded-r16 bg-white100 p-20'>
        <div className='flex flex-col'>
          {/* 고객 정보 입력 폼 */}
          <Suspense fallback={<FullLoading />}>
            <CustomerInfoFormContainer countriesList={countriesList} {...patientInfo} />
          </Suspense>

          {/* 저장 버튼 */}
          <Suspense>
            <SaveButton disabled={patientInfo.isClosed} />
          </Suspense>
        </div>
        {/* 이용 제외인 고객의 경우, 서비스매니저 메모를 보여주지 않음 */}
        <Suspense>{!patientInfo.isClosed && <ServiceManagerMemoContainer />}</Suspense>
      </div>
    </FormProvider>
  );
}
