import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiNoticeHospitalElFindSimpleParams } from '@apis/swaggers/swagger-docs';
import { useSuspenseQuery } from '@tanstack/react-query';
import NoticeListItem from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/components/NoticeList/components/NoticeListBody/components/NoticeListItem';
import { noticeListQueryKeyState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/states/notice-list-query-key-state';
import ContentNoData from '@templates/Content/containers/ContentManager/shared/components/ContentNoData/ContentNoData';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useAtomValue } from 'jotai/index';
import { useMemo } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchHospitalNoticeList = async (params: ApiNoticeHospitalElFindSimpleParams) => {
  const response = await apiClient.v3.apiNoticeHospitalElFindSimple(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function NoticeListBody() {
  const { hospitalID } = useSelectedHospitalInfo();
  const noticeListQueryKey = useAtomValue(noticeListQueryKeyState);

  const { data: noticeList } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiNoticeHospitalElFindSimple,
      { ...noticeListQueryKey, hospitalID },
    ] as const,
    queryFn: ({ queryKey }) => fetchHospitalNoticeList(queryKey[1]),
  });

  const isSearchQueryEntered = useMemo(() => {
    if (noticeListQueryKey.keyword) {
      return noticeListQueryKey.keyword.length > 0;
    }
    return false;
  }, [noticeListQueryKey.keyword]);

  if (!noticeList.length) {
    return <ContentNoData isSearchQueryEntered={isSearchQueryEntered} type={'notice'} />;
  }

  return (
    <div className='flex flex-col'>
      {noticeList.map((item) => (
        <NoticeListItem key={item._id} data={item} />
      ))}
    </div>
  );
}
