import { unTaggedHeaderFilterState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/states/editable-header-filter';
import { searchTextState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/search';
import {
  focusedCellState,
  selectedCellState,
} from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';
import { useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';

export const useResetTableJotaiStates = () => {
  const resetSelectedCell = useResetAtom(selectedCellState);
  const resetFocusedCell = useResetAtom(focusedCellState);
  const resetSearchText = useResetAtom(searchTextState);
  const resetUnTaggedHeaderFilter = useResetAtom(unTaggedHeaderFilterState);

  const resetTableJotaiStates = useCallback(() => {
    // 셀 선택 상태 초기화
    resetSelectedCell();
    resetFocusedCell();

    // 검색어 초기화
    resetSearchText();

    // 필터 초기화
    resetUnTaggedHeaderFilter();
  }, [resetSelectedCell, resetFocusedCell, resetSearchText, resetUnTaggedHeaderFilter]);

  return {
    resetTableJotaiStates,
  };
};
