import { useEffect } from 'react';

interface UseIntersectionObserverProps {
  root?: Element | null;
  target: Element | null;
  onIntersect: () => void;
  threshold?: number | number[];
  rootMargin?: string;
  enabled?: boolean;
}

export function useIntersectionObserver({
  root = null,
  target,
  onIntersect,
  threshold = 0.1,
  rootMargin = '0px',
  enabled = true,
}: UseIntersectionObserverProps) {
  useEffect(() => {
    if (!enabled || !target) return;

    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            onIntersect();
          }
        }
      },
      {
        root,
        rootMargin,
        threshold: [0, 0.1, 0.5, 1.0],
      },
    );

    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
  }, [target, enabled, root, rootMargin, threshold, onIntersect]);
}
