import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiPushRequestElCountParams } from '@apis/swaggers/swagger-docs';
import { useQuery } from '@tanstack/react-query';
import { eventPushRequestListSkipNumAtom } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/event-push-request-list-query-key-state';
import Pagination from 'afterdoc-design-system/components/Molecules/Pagination/Pagination';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useAtom } from 'jotai/index';
import { useMemo } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const DEFAULT_LIMIT = 10;

const fetchEventPushRequestListCount = async (params: ApiPushRequestElCountParams) => {
  const { data } = await apiClient.v3.apiPushRequestElCount(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export default function EventPushPageNavigation({ hospitalEventID }: { hospitalEventID: string }) {
  const { hospitalID } = useSelectedHospitalInfo();
  const [skipNum, setSkipNum] = useAtom(eventPushRequestListSkipNumAtom);

  const { data: totalCount } = useQuery({
    queryKey: [
      QUERY_KEY.apiPushRequestElCount,
      { hospitalID, hospitalEventID, type: 'HospitalEvent' },
    ] as const,
    queryFn: ({ queryKey }) => fetchEventPushRequestListCount(queryKey[1]),
  });

  const currentPage = skipNum === 0 ? 1 : Math.floor(skipNum / DEFAULT_LIMIT) + 1;

  const totalPages = useMemo(() => {
    return Math.max(1, Math.ceil((totalCount ?? 0) / DEFAULT_LIMIT));
  }, [totalCount]);

  const handlePageChange = (page: number) => {
    const newSkip = (page - 1) * DEFAULT_LIMIT;
    setSkipNum(newSkip);
  };

  return (
    <Pagination
      className='h-auto'
      totalPages={totalPages}
      selectedPage={currentPage}
      onPageChange={handlePageChange}
    />
  );
}
