import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiStatsAlgorithmsConsultationMonthlyElFindHospitalParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { selectedDateState } from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/states/selected-date';
import { getMonthRange } from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/utils/get-month-range';
import { useAtomValue } from 'jotai';

type QueryKeyType = [
  typeof QUERY_KEY.apiStatsAlgorithmsConsultationMonthlyElFindHospital,
  ApiStatsAlgorithmsConsultationMonthlyElFindHospitalParams,
];

export interface ApiStatsAlgorithmsConsultationMonthlyElFindData {
  totalUserCount: number;
  chatCount: number;
  _id: string;
  hospitalID: string;
  registerDate: string;
  __v: number;
  createdAt: string;
  updatedAt: string;
}

const getAlgorithmsConsultationMonthlyElFindHospital = async (
  params: ApiStatsAlgorithmsConsultationMonthlyElFindHospitalParams,
) => {
  const response = await apiClient.v3.apiStatsAlgorithmsConsultationMonthlyElFindHospital(params);
  return SHARED_UTILS.api.checkApiResponse(
    response.data,
  ) as ApiStatsAlgorithmsConsultationMonthlyElFindData[];
};

export const useGetAlgorithmsConsultationMonthly = () => {
  const { hospitalID } = useSelectedHospitalInfo();
  const selectedDate = useAtomValue(selectedDateState);

  const { startDate, endDate } = getMonthRange(selectedDate);

  const { data } = useSuspenseQuery<
    ApiStatsAlgorithmsConsultationMonthlyElFindData[],
    Error,
    ApiStatsAlgorithmsConsultationMonthlyElFindData[],
    QueryKeyType
  >({
    queryKey: [
      QUERY_KEY.apiStatsAlgorithmsConsultationMonthlyElFindHospital,
      { hospitalID, startDate, endDate },
    ] as const,
    queryFn: ({ queryKey }) => getAlgorithmsConsultationMonthlyElFindHospital(queryKey[1]),
  });

  return { data };
};
