import BigImage from 'afterdoc-design-system/components/Atoms/BigImage/BigImage';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import FilePicker from 'afterdoc-saas-web/shared/components/FilePicker/FilePicker';
import LayoutBox from 'afterdoc-saas-web/shared/components/LayoutBox/LayoutBox';
import OptimizedImage from 'afterdoc-saas-web/shared/components/OptimizedImage/OptimizedImage';
import {
  type ImageType,
  useUploadMultipleImages,
} from 'afterdoc-saas-web/shared/hooks/files/images/use-upload-multiple-images';
import type { MarketingAutomationAPIFormValues } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { MAX_LENGTH } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/constants/replaceable-texts';
import { useTextAreaWithMaxLength } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/hooks/use-text-area-with-max-length';
import { actualTextLengthState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/states/actual-text-length';
import { selectedMessageIndexState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';
import ResizableTextArea from 'afterdoc-saas-web/templates/Automation/containers/Dialog/shared/components/ResizableTextArea/ResizableTextArea';
import { useGetIsKakaoAlimAndFriendTalkLinkage } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/shared/hooks/use-get-is-kakao-alim-and-friend-talk-linkage';
import { useAtom, useAtomValue } from 'jotai';
import { forwardRef, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

const MAX_FILE_SIZE_MB = 5;
const MAX_IMAGES_LENGTH = 1;

const ImageContentForm = forwardRef<HTMLTextAreaElement>((_, ref) => {
  const { setValue, getValues } = useFormContext<MarketingAutomationAPIFormValues>();

  const [actualTextLength, setActualTextLength] = useAtom(actualTextLengthState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const {
    images,
    setImages,
    handleFileChange,
    handleRemoveImage,
    handleAttachSingleImage,
    filePickerRef,
  } = useUploadMultipleImages({
    maxFileSizeMB: MAX_FILE_SIZE_MB,
    maxImages: MAX_IMAGES_LENGTH,
  });

  const { hasKakaoAlimAndFriendTalkLinkage } = useGetIsKakaoAlimAndFriendTalkLinkage();

  const messageContentText = getValues(`messages.${selectedMessageIndex}.content.text`);
  const messageContentImage = getValues(`messages.${selectedMessageIndex}.content.image`);

  const { handleTextAreaChange } = useTextAreaWithMaxLength({ setActualTextLength });
  const handleAutomationRemoveImage = (index: number) => {
    handleRemoveImage(index);

    if (images.length === 1) {
      setValue(`messages.${selectedMessageIndex}.content.image`, undefined);
    }
  };

  useEffect(() => {
    if (images && images.length > 0) {
      const stringifiedImages = JSON.stringify(images);
      setValue(`messages.${selectedMessageIndex}.content.image`, stringifiedImages);
    }
  }, [images]);

  useEffect(() => {
    if (messageContentImage) {
      try {
        const imageContent: ImageType[] = JSON.parse(messageContentImage);
        setImages(imageContent);
      } catch (error) {
        console.error('Error parsing image content:', error);
        setImages([]);
      }
    } else {
      setImages([]);
    }
  }, [messageContentImage, setImages]);

  return (
    <>
      {hasKakaoAlimAndFriendTalkLinkage && (
        <div className='rounded-r10 bg-white200 px-16 py-8 text-Body10 text-black500'>
          텍스트를 제외한 다른 타입의 메시지는 친구톡을 발송할 수 없습니다.
        </div>
      )}
      <LayoutBox direction='vertical' size={20} width='100%'>
        <div className='mt-10 flex flex-wrap gap-10'>
          <FilePicker ref={filePickerRef} onFileChange={handleFileChange} />
          {images?.map((image, index) => (
            <div className='relative' key={uuidv4()}>
              <OptimizedImage
                image={image}
                className='h-[96px] w-[170px] rounded-r10 border border-white600 object-cover'
              />
              <button
                onClick={() => handleAutomationRemoveImage(index)}
                className='absolute-center flex items-center gap-2 rounded-r16 bg-black800 bg-opacity-50 py-7 pr-10 pl-8'
                type='button'>
                <Icon name='trash-can-outline' color='white50' size={20} />
                <span className='whitespace-nowrap text-Body13 text-white50'>삭제</span>
              </button>
            </div>
          ))}
          {!images.length && (
            <BigImage width={170} height={96} onClickNoImage={handleAttachSingleImage} />
          )}
        </div>
        <Controller
          key={selectedMessageIndex}
          name={`messages.${selectedMessageIndex}.content.text`}
          render={({ field }) => (
            <ResizableTextArea
              {...field}
              ref={ref}
              onChange={(e) => {
                handleTextAreaChange(e);

                if (
                  actualTextLength >= MAX_LENGTH &&
                  e.target.value.length > (messageContentText?.length ?? 0)
                )
                  return;

                field.onChange(e);
              }}
              placeholder='내용을 입력해 주세요.'
            />
          )}
        />
      </LayoutBox>
    </>
  );
});

export default ImageContentForm;
