import type { MarketingAutomationAPIFormValues } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { atomWithReset } from 'jotai/utils';

export const originAlgorithmDataState = atomWithReset<MarketingAutomationAPIFormValues>({
  hospitalID: '',
  name: '',
  targetTreatmentTags: undefined,
  toBeAppliedNationalityIds: undefined,
  messages: [],
  type: 'MARKETING',
  sendingSchedule: {
    sendingCount: 1,
    startDay: '',
  },
});
