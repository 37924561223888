import { Color } from '@tailwind-base/styles/color';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useGetStatsChatsMonthly } from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/hooks/use-get-stats-chats-monthly';
import { selectedDateState } from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/states/selected-date';
import { calculateMonthOverMonthChange } from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/utils/calculate-month-over-month-change';
import { getUpDownText } from 'afterdoc-saas-web/templates/Automation/containers/Main/DashBoardContent/utils/get-up-down-text';
import CardItemLayout from 'afterdoc-saas-web/templates/Automation/containers/Main/shared/components/CardItemLayout';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';

export default function UnAnsweredAndResponseTimeCardItem() {
  const { data } = useGetStatsChatsMonthly();
  const selectedDate = useAtomValue(selectedDateState);
  const lastMonthUnansweredCount =
    data?.find(
      (item) =>
        dayjs(item.registerDate).format('YYYY-MM') ===
        dayjs(selectedDate).subtract(1, 'month').format('YYYY-MM'),
    )?.unansweredCount ?? 0;

  const thisMonthUnansweredCount =
    data?.find(
      (item) =>
        dayjs(item.registerDate).format('YYYY-MM') === dayjs(selectedDate).format('YYYY-MM'),
    )?.unansweredCount ?? 0;

  const changeFromLastMonthUnansweredCount = calculateMonthOverMonthChange(
    lastMonthUnansweredCount,
    thisMonthUnansweredCount,
  );

  const lastMonthResponseTime =
    data?.find(
      (item) =>
        dayjs(item.registerDate).format('YYYY-MM') ===
        dayjs(selectedDate).subtract(1, 'month').format('YYYY-MM'),
    )?.responseTimePerCount ?? 0;

  const thisMonthResponseTime =
    data?.find(
      (item) =>
        dayjs(item.registerDate).format('YYYY-MM') === dayjs(selectedDate).format('YYYY-MM'),
    )?.responseTimePerCount ?? 0;

  const changeFromLastMonthResponseTime = calculateMonthOverMonthChange(
    lastMonthResponseTime,
    thisMonthResponseTime,
  );

  return (
    <CardItemLayout>
      <div className='mt-8 flex flex-col'>
        <UnAnsweredCardItem
          number={thisMonthUnansweredCount}
          changeFromLastMonth={changeFromLastMonthUnansweredCount}
        />
        <Divider type='line' className='my-16' />
        <ResponseTimeCardItem
          number={thisMonthResponseTime}
          changeFromLastMonth={changeFromLastMonthResponseTime}
        />
      </div>
    </CardItemLayout>
  );
}

interface UnAnsweredCardItemProps {
  number: number;
  changeFromLastMonth: number;
}

function UnAnsweredCardItem({ number, changeFromLastMonth }: UnAnsweredCardItemProps) {
  const textColor =
    changeFromLastMonth === 0
      ? Color.black200
      : changeFromLastMonth > 0
        ? Color.red500
        : Color.green500;

  const bgColor =
    changeFromLastMonth === 0
      ? Color.white500
      : changeFromLastMonth > 0
        ? Color.red50
        : Color.green100;

  return (
    <div className='flex w-full items-start justify-between'>
      <div>
        <div className='text-Header12 text-black500'>월별 당일 미응대 건수</div>
        <div className='mt-8 flex items-center gap-10'>
          <div className='font-bold text-[18px] text-black700'>{number}건</div>
          <FilledTag tagSize='small' textColor={textColor} maxTextLength='full' bgColor={bgColor}>
            {getUpDownText(changeFromLastMonth)}
          </FilledTag>
        </div>
      </div>
      <HoverTooltip
        text={
          '운영 시간에 발생한 상담중 당일(자정)에 답변을 하지 않은\n상담 건 수를 의미합니다. 운영 시간 이후에 발생한 상담은\n다음 운영일에 집계됩니다.\n(병원 프로필에서 설정한 진료시간을 기준으로 계산됩니다.)'
        }>
        <div className='p-6'>
          <Icon name='info-circle-outline' color='black200' size={20} />
        </div>
      </HoverTooltip>
    </div>
  );
}

interface ResponseTimeCardItemProps {
  number: number;
  changeFromLastMonth: number;
}

function ResponseTimeCardItem({ number, changeFromLastMonth }: ResponseTimeCardItemProps) {
  const textColor =
    changeFromLastMonth === 0
      ? Color.black200
      : changeFromLastMonth > 0
        ? Color.red500
        : Color.green500;

  const bgColor =
    changeFromLastMonth === 0
      ? Color.white500
      : changeFromLastMonth > 0
        ? Color.red50
        : Color.green100;

  return (
    <div className='flex w-full items-start justify-between'>
      <div>
        <div className='text-Header12 text-black500'>월별 평균 답변 대기 시간</div>
        <div className='mt-8 flex items-center gap-10'>
          {/* number는 초 단위임 */}
          <div className='font-bold text-[18px] text-black700'>
            {Math.floor(number / 60)}분 {Math.floor(number % 60)}초
          </div>
          <FilledTag tagSize='small' textColor={textColor} maxTextLength='full' bgColor={bgColor}>
            {getUpDownText(changeFromLastMonth)}
          </FilledTag>
        </div>
      </div>
      <HoverTooltip
        text={
          '고객이 채팅을 전송한 후 직원의 답변까지 걸리는\n시간의 평균값을 의미합니다.\n이번 달의 집계는 자정 기준으로 매일 갱신됩니다.\n답변을 하지 않고 상담 종료를 한 경우 상담 종료까지의 시간을 계산합니다.'
        }>
        <div className='p-6'>
          <Icon name='info-circle-outline' color='black200' size={20} />
        </div>
      </HoverTooltip>
    </div>
  );
}
