import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ChatRoomElFindServiceInformationHandlerParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';

export interface PatientIconInfoType {
  isKakaoIcon: boolean;
  isGrayAfterDocIcon: boolean;
  isColorAfterDocIcon: boolean;
  isPendingService: boolean;
}

const getInfoTopPatient = async (query: ChatRoomElFindServiceInformationHandlerParams) => {
  const { data } = await apiClient.v3.chatRoomElFindServiceInformationHandler(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useChatTopPatient = ({ chatRoomID }: { chatRoomID: string }) => {
  const { data, error, isLoading } = useQuery({
    queryKey: [QUERY_KEY.chatRoomElFindServiceInformationHandler, { chatRoomID }] as const,
    queryFn: ({ queryKey }) => getInfoTopPatient(queryKey[1]),
    enabled: !!chatRoomID,
  });

  const {
    name,
    hserviceID,
    isFirstVisit,
    chartNumber,
    isColorAfterDoc,
    isGrayAfterDoc,
    hasKakao,
    gender,
    birth,
    phone,
    countryCode,
    nationality,
    treatmentCategories,
    closeReason,
    automationAppliedTreatmentTag,
    hasSmartDoctorLinkage,
  } = data || {};

  return {
    isLoading,
    error,
    isFirstVisit,
    patientId: hserviceID,
    name: name ?? '',
    chartNumber,
    isKakaoIcon: hasKakao ?? false,
    isColorAfterDocIcon: isColorAfterDoc ?? false,
    isGrayAfterDocIcon: isGrayAfterDoc ?? true,
    iconSet: {
      isKakaoIcon: hasKakao ?? false,
      isColorAfterDocIcon: isColorAfterDoc ?? false,
      isGrayAfterDocIcon: isGrayAfterDoc ?? true,
      isPendingService: false,
    },
    gender,
    birthdate: birth,
    phoneNumber: phone,
    countryCode,
    treatmentTags: treatmentCategories,
    nationalityKoreanCountryName: nationality?.koreanCountryName,
    closeReason,
    automationAppliedTreatmentTag,
    hasSmartDoctorLinkage,
  };
};
