import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApplyingCommonAlgorithmHandlerBodyRequest } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';

const postApplyingCommonAlgorithm = async (params: ApplyingCommonAlgorithmHandlerBodyRequest) => {
  const response = await apiClient.v3.applyingCommonAlgorithmHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useApplyCommonAlgorithm = (algorithmId: string) => {
  const { hospitalID } = useSelectedHospitalInfo();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postApplyingCommonAlgorithm,
    onSuccess: (data) => {
      if (data?.isAppliedSuccess) {
        toastService.successMsg({
          text: '자동화가 변경되었습니다.',
        });
      }

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.singleAlgorithmHandler, { algorithmId, hospitalID }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiAutomationsElAlgorithmsMessages, { algorithmId, hospitalID }],
      });
    },
  });
};
