import { chattingSendingPlaceholder } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingSending/functions/chatting-sending-placeholder';
import { isEmptyWithTrim } from 'afterdoc-saas-web/templates/CustomerChat/functions/isEmptyWithTrim';
import { useIsDisabledChat } from 'afterdoc-saas-web/templates/CustomerChat/hooks/use-is-disabled-chat';
import EmojiPicker from 'emoji-picker-react';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useRef } from 'react';
import { useChatRoomInfoOne } from '../../hooks/use-chatroom-info-one';
import { isSettingBottomTranslateLanguageState } from '../../states/is-setting-sending-translate-language';
import ChatTextArea from './components/ChatTextArea/ChatTextArea';
import ChattingSendingBottom from './components/ChattingSendingBottom/ChattingSendingBottom';
import TranslateLanguageSettingBlock from './components/TranslateLanguageSettingBlock/TranslateLanguageSettingBlock';
import { useChatInput } from './hooks/use-chat-input';
import { useEmojiPicker } from './hooks/use-emoji-picker';
import { useReservationListener } from './hooks/use-reservation-listener';

export default function ChattingSending({ chatRoomID }: { chatRoomID: string }) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const isSettingBottomTranslateLanguage = useAtomValue(isSettingBottomTranslateLanguageState);
  const resetIsSettingBottomTranslateLanguage = useResetAtom(isSettingBottomTranslateLanguageState);

  const { isUsageHaltedDisabledSending, code } = useIsDisabledChat({ chatRoomID });

  const { text, setText, handleChange, handleSend } = useChatInput({ chatRoomID });

  useReservationListener(setText, isUsageHaltedDisabledSending);

  const { showEmojiPicker, emojiPickerRef, toggleEmojiPicker, onEmojiClick } = useEmojiPicker(
    setText,
    textAreaRef,
  );

  //chatRoomID가 바뀔때 한번 렌더링이 발생하는 곳이면서 useChatInput를 선언하는 곳이기 때문에 여기서 초기화를 해준다.
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    resetIsSettingBottomTranslateLanguage();
    setText('');
  }, [chatRoomID]);

  const { isCounselInProgress, languageID } = useChatRoomInfoOne({
    chatRoomID,
  });

  return (
    <div
      className={`relative flex h-auto w-full flex-col ${isUsageHaltedDisabledSending ? 'bg-white200' : 'bg-white50'}`}>
      {isSettingBottomTranslateLanguage && <TranslateLanguageSettingBlock />}
      <div className={`relative ${isSettingBottomTranslateLanguage ? 'pointer-events-none' : ''}`}>
        <ChatTextArea
          textAreaRef={textAreaRef}
          placeholder={chattingSendingPlaceholder({
            isSettingBottomTranslateLanguage,
            isUsageHaltedDisabledSending,
            code,
            isCounselInProgress,
          })}
          onChange={handleChange}
          isShowBottom={false}
          errorText='에러 메시지'
          value={text}
          noBorder={true}
          maxLength={1000}
          maxHeight={185}
          className={`overflow-hidden ${isUsageHaltedDisabledSending ? 'text-black200' : 'text-black700'}`}
          disabled={isUsageHaltedDisabledSending}
        />
        {showEmojiPicker && (
          <div
            ref={emojiPickerRef}
            style={{
              position: 'absolute',
              top: '-375px',
              left: 0,
              zIndex: 100,
            }}>
            <EmojiPicker onEmojiClick={onEmojiClick} width={340} height={370} />
          </div>
        )}
      </div>
      <ChattingSendingBottom
        disabled={isEmptyWithTrim(text) || isSettingBottomTranslateLanguage}
        toggleEmojiPicker={toggleEmojiPicker}
        onClickSend={handleSend}
        textAreaRef={textAreaRef}
        languageID={languageID}
        chatRoomID={chatRoomID}
        isUsageHaltedDisabledSending={isUsageHaltedDisabledSending}
      />
    </div>
  );
}
