import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import { chatRoomIDSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useDataEventBusHandler } from 'hooks/use-data-event-bus-handler/use-data-event-bus-handler';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';

export const useUpdateSuspendSyncChatRoomInfoPushHandler = () => {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  if (!chatRoomID) return;

  const queryClient = useQueryClient();

  const handleNotification = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.chatRoomElFindServiceInformationHandler, { chatRoomID }],
    });
  }, [chatRoomID, queryClient]);

  const notificationConfigs = useMemo(
    () => [
      {
        //이 둘과 관련된 채팅 리스트 정보 업데이트는 전역에서 처리 infoTop정보만 업데이트하면 됨.
        //d_HServiceUpdate_el는 sync를 위해 비효율적이지만 추가하는 것이 맞다고 판단.
        //d_HServiceSuspend_el 역시도 sync를 위해 비효율적이지만 추가하는 것이 맞다고 판단.
        codes: ['d_HServiceUpdate_el', 'd_HServiceSuspend_el'],
        handler: handleNotification,
      },
    ],
    [handleNotification],
  );

  useDataEventBusHandler(notificationConfigs);
};
