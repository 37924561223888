import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import AnimatedList from 'afterdoc-saas-web/shared/components/AnimatedList/AnimatedList';
import type { ContentSubLayer } from 'afterdoc-saas-web/templates/Content/containers/ContentPanel/constants/tab-items';
import { AnimatePresence } from 'framer-motion';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import type { ManualHandleClickParams } from '../ManualList';
import type { ManusFolders } from '../hooks/use-manus-folders';
import TagDetailPanel from './ManualPanelFolderItem/components/TagDetailPanel/TagDetailPanel';

export type ParentItem = {
  title: string;
  isOpen: boolean;
  items: ContentSubLayer[];
};

interface ContentPanelFolderProps {
  id: string;
  parentIndex: number;
  parentItem: ManusFolders;
  children: ReactNode;
  handleClick: ({ id, parentIndex }: ManualHandleClickParams) => void;
  isOpen: boolean;
  isChildOpen: boolean;
  treatmentTagsIds: string[];
  focusedManualTagID: string | null;
}

export default function ManualPanelFolder({
  id,
  parentIndex,
  parentItem,
  children,
  isOpen,
  isChildOpen,
  handleClick,
  treatmentTagsIds,
  focusedManualTagID,
}: ContentPanelFolderProps) {
  const { items, title, type, color: tagColor } = parentItem;
  const isNodata = items.length === 0;

  const [isFolderOpen, setIsFolderOpen] = useState<boolean>(isOpen);

  useEffect(() => {
    setIsFolderOpen(isOpen);
  }, [isOpen]);

  const isInTreatmentTagsIds = treatmentTagsIds?.includes(id);
  const isInFocusedTag = focusedManualTagID === id;
  const isFolder = type === 'folder';

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const isExpanded = useMemo(() => {
    if (isFolder) {
      return isFolderOpen;
    }
    return isChildOpen;
  }, [isFolderOpen, isChildOpen]);

  return (
    <div>
      <div
        className={`flex h-40 cursor-pointer items-center justify-start gap-10 px-20 ${
          isFolder
            ? 'bg-white50'
            : isInTreatmentTagsIds
              ? 'bg-purple100'
              : isInFocusedTag
                ? 'bg-blue50'
                : 'bg-white50'
        }`}
        onClick={() => {
          if (isFolder) {
            setIsFolderOpen(!isFolderOpen);
          } else {
            handleClick({ id, parentIndex });
          }
        }}>
        <div className='flex-shrink-0'>
          <Icon name={isExpanded ? 'chevron-down' : 'chevron-right'} size={16} color='black200' />
        </div>
        <div className='flex-shrink-0'>
          <Icon
            name={isFolder ? 'folder-outline' : 'tag'}
            size={20}
            color={isFolder ? 'white700' : 'transparent'}
            customColor={tagColor}
          />
        </div>

        <span className='line-clamp-1 break-words text-Header14 text-black500'>
          {title || '이름없음'}
        </span>
      </div>
      <AnimatePresence initial={false}>
        {isFolder
          ? isFolderOpen &&
            (isNodata ? (
              <NoData
                iconProps={{
                  name: 'warning',
                  size: 48,
                  color: 'white600',
                }}
                title='표시할 내용이 없습니다.'
                titleClassName='text-Header12 text-black500'
                className='my-30'
              />
            ) : (
              <AnimatedList isOpen={isFolderOpen}>{children}</AnimatedList>
            ))
          : isChildOpen && <TagDetailPanel treatmentTagId={id} />}
      </AnimatePresence>
    </div>
  );
}
