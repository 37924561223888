import BeStartDateTimeInput from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/common/RequestInfoWrapper/BeStartDateTimeInput/BeStartDateTimeInput';
import NameInput from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/common/RequestInfoWrapper/NameInput';
import PhoneInput from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/common/RequestInfoWrapper/PhoneInput';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';

interface RequestInfoWrapperProps {
  subTitle: string;
  isBeStartDateTimeInput?: boolean;
  isAllDisabled?: boolean;
  isAllAlwaysHasErrorSpace?: boolean;
}

export default function RequestInfoWrapper({
  subTitle,
  isBeStartDateTimeInput = true,
  isAllDisabled = false,
}: RequestInfoWrapperProps) {
  return (
    <>
      <Title title='신청정보' subTitle={subTitle} wrapperClassName={'px-0 pt-0'} />
      <div className='my-8 flex flex-col gap-12'>
        <NameInput isAllDisabled={isAllDisabled} />
        <PhoneInput isAllDisabled={isAllDisabled} />
        {isBeStartDateTimeInput && <BeStartDateTimeInput />}
      </div>
      <Divider type={'line'} height={1} className='my-10' />
    </>
  );
}
