import { apiClient } from '@apis/instances/api-client';
import { useMutation } from '@tanstack/react-query';
import { useUpdateUserInfo } from '@templates/UserInfoSetting/containers/AccountSetting/hooks/use-update-user-info';
import type { StatusMessage } from '@templates/UserInfoSetting/containers/AccountSetting/types/status-message';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

type HospitalsAccountsElIdCheckParam = Parameters<
  typeof apiClient.v3.apiHospitalsAccountsElIdcheck
>[0];

const fetchHospitalAccountDuplicateStatus = async (param: HospitalsAccountsElIdCheckParam) => {
  const { data } = await apiClient.v3.apiHospitalsAccountsElIdcheck(param);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export default function UserInfoUpdateId() {
  const { userId } = useUserInfo();
  const [newId, setNewId] = useState('');
  const [isDuplicateIdChecked, setIsDuplicateIdChecked] = useState(false);

  const { updateUserInfo } = useUpdateUserInfo();

  const [duplicateIdStatus, setDuplicateIdStatus] = useState<StatusMessage>({
    status: 'none',
  });

  const checkDuplicatedIdMutation = useMutation({
    mutationFn: (params: HospitalsAccountsElIdCheckParam) =>
      fetchHospitalAccountDuplicateStatus(params),
    onSuccess: (data) => handleDuplicateIdCheckSuccess(data),
    onError: (error) => handleDuplicateIdCheckError(error),
  });

  const handleDuplicateIdCheckSuccess = (isNotDuplicated: boolean) => {
    setIsDuplicateIdChecked(isNotDuplicated);
    const status = isNotDuplicated ? 'success' : 'failure';
    const message = isNotDuplicated
      ? '사용할 수 있는 아이디입니다.'
      : '이미 등록된 아이디입니다. 다른 아이디를 입력해 주세요.';

    setDuplicateIdStatus({ status, message });
  };

  const handleDuplicateIdCheckError = (error: Error) => {
    console.error('Error while checking duplicate ID:', error);
    setIsDuplicateIdChecked(false);
    setDuplicateIdStatus({
      status: 'failure',
      message: '중복확인에 실패했습니다. 다시 시도해 주세요.',
    });
  };

  const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDuplicateIdChecked(false);
    setDuplicateIdStatus({ status: 'none' });
    setNewId(e.target.value.replace(/[^a-zA-Z0-9]/g, ''));
  };

  const handleCheckDuplicateButtonClick = () => {
    checkDuplicatedIdMutation.mutate({ _id: userId, id: newId });
  };

  const handleConfirmButtonClick = () => {
    updateUserInfo({
      param: { id: newId },
      onSuccessCallback: () => {
        closeCurrentDialog();
        toastService.successMsg({ text: '아이디가 변경되었습니다.' });
      },
      onFailureCallback: () => {
        toastService.errorMsg({ text: '아이디 변경에 실패했습니다. 다시 시도해 주세요.' });
      },
    });
  };

  const closeCurrentDialog = () => {
    dialogService.popById('user-info-update-dialog');
  };

  return (
    <>
      <div className='flex h-full flex-grow flex-col gap-16'>
        <div className='flex flex-col gap-4'>
          <div className='flex items-start gap-10'>
            <TextInput
              label={{ width: 80, children: '새 아이디', position: 'horizontal' }}
              className='flex-grow'
              maxLength={25}
              onChange={handleIdChange}
              value={newId}
              placeholder={'영문, 숫자 3~25자'}
            />
            <OutlinedButton
              onClick={handleCheckDuplicateButtonClick}
              className='h-30 w-88 px-0'
              disabled={newId.length < 3 || newId.length > 25}>
              중복확인
            </OutlinedButton>
          </div>
          {duplicateIdStatus.status !== 'none' && (
            <span
              className={`ml-90 text-Body10 ${duplicateIdStatus.status === 'success' ? 'text-blue500' : 'text-red500'}`}>
              {duplicateIdStatus.message}
            </span>
          )}
        </div>
        <div className='mx-auto mt-auto flex gap-10'>
          <ContainedButton btnColor={'secondary'} onClick={closeCurrentDialog}>
            취소
          </ContainedButton>
          <ContainedButton disabled={!isDuplicateIdChecked} onClick={handleConfirmButtonClick}>
            확인
          </ContainedButton>
        </div>
      </div>
    </>
  );
}
