import {
  MARKETING_REPLACEABLE_TEXTS,
  MAX_LENGTH,
} from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/constants/replaceable-texts';
import {
  calculateReplacedLength,
  removeSpecificReplaceableText,
  truncateTextBeforeMaxLength,
} from 'afterdoc-saas-web/templates/Automation/containers/shared/functions/calculate-text';
import { useCallback } from 'react';

interface UseTextAreaWithMaxLengthProps {
  setActualTextLength: (value: number) => void;
}

export function useTextAreaWithMaxLength({ setActualTextLength }: UseTextAreaWithMaxLengthProps) {
  const handleTextAreaChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = e.target.value;
      const newActualLength =
        removeSpecificReplaceableText(newValue, MARKETING_REPLACEABLE_TEXTS).length +
        calculateReplacedLength(newValue, MARKETING_REPLACEABLE_TEXTS);

      if (newActualLength <= MAX_LENGTH) {
        setActualTextLength(newActualLength);
      } else {
        const truncatedValue = truncateTextBeforeMaxLength(
          newValue,
          MARKETING_REPLACEABLE_TEXTS,
          MAX_LENGTH,
        );
        const truncatedActualLength =
          removeSpecificReplaceableText(truncatedValue, MARKETING_REPLACEABLE_TEXTS).length +
          calculateReplacedLength(truncatedValue, MARKETING_REPLACEABLE_TEXTS);

        setActualTextLength(truncatedActualLength);
      }
    },
    [setActualTextLength],
  );

  return {
    handleTextAreaChange,
  };
}
