import FullLoading from 'afterdoc-saas-web/shared/components/FullLoading/FullLoading';
import ChattingContent from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/ChattingContent';
import { useBottomScrollObserver } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-bottom-scroll-observer';
import { useChatRoomPatientUnreadCount } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-chat-room-patient-unread-count';
import { useTopScrollObserver } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-top-scroll-observer';
import { MoveBelowButton } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/MoveBelowButton/MoveBelowButton';
import { useConditionalChatData } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/hooks/use-conditional-chat-data';
import { useForcedMoveToBottom } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/hooks/use-forced-move-to-bottom';
import { selectedMessageIDState } from 'afterdoc-saas-web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { selectedChattingListTabState } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chatting-list-tab';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMessagesWithUnread } from './components/ChattingContent/hooks/use-messages-with-unread';
import { useInvalidateConsultationGuide } from './hooks/use-invalidate-consultation-guide';
import { useRefreshScreenPushHandler } from './hooks/use-refresh-screen-push-handler';
import { useUpdateChatroomOnePushHandler } from './hooks/use-update-chat-room-one-push-handler';
import './styles/ChattingContainer.scss';

interface ChattingContainerProps {
  chatRoomID: string;
  onLoading: (isLoading: boolean) => void;
}

export default function ChattingContainer({ chatRoomID, onLoading }: ChattingContainerProps) {
  const selectedChattingListTab = useAtomValue(selectedChattingListTabState);
  const selectedMessageID = useAtomValue(selectedMessageIDState);

  const [previousScrollHeight, setPreviousScrollHeight] = useState(0); // 데이터 fetch 전 스크롤 scrollHeight 저장

  const loadPreviousMoreRef = useRef<HTMLDivElement>(null); // 스크롤 상단 감시용 ref
  const loadNextMoreRef = useRef<HTMLDivElement>(null); // 스크롤 하단 감시용 ref
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  const chatRoomPatientUnreadCount = useChatRoomPatientUnreadCount();

  useUpdateChatroomOnePushHandler();

  const {
    data,
    resMessages,
    isFetchingNextPage,
    fetchNextPage,
    fetchPreviousPage,
    isLoading,
    isFetchingPreviousPage,
    refreshAll,
    setResMessages,
  } = useConditionalChatData(selectedMessageID, chatRoomID);

  //채팅 리스트를 이동했을때 채팅방을 최신화
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    refreshAll();
  }, [selectedChattingListTab]);

  useTopScrollObserver({
    fetchPreviousPage,
    isLoading,
    chatContainerRef,
    loadPreviousMoreRef,
    setPreviousScrollHeight,
  });

  const { isBottomButtonVisible } = useBottomScrollObserver({
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
    chatContainerRef,
    loadNextMoreRef,
    setPreviousScrollHeight,
    previousScrollHeight,
    messages: data,
  });

  useForcedMoveToBottom({
    chatContainerRef,
    isLoading,
    isBottomButtonVisible,
    resMessagesLength: resMessages.length,
  });

  useInvalidateConsultationGuide({ lastMessage: data?.[0] });

  const messagesWithUnread = useMessagesWithUnread({
    messages: data,
    chatRoomPatientUnreadCount,
  });

  // console.log('messagesWithUnread:', messagesWithUnread);

  useRefreshScreenPushHandler();

  useEffect(() => {
    onLoading(isLoading || isFetchingPreviousPage);
  }, [isLoading, isFetchingPreviousPage, onLoading]);

  const stableResMessages = useMemo(() => resMessages, [resMessages]);

  if (!data) return <FullLoading />;

  //채팅 스크롤 컨트롤을 위해 css로 뒤집음, 채팅 스타일링 scss파일로 통일
  return (
    <>
      <div id='chatting-container' ref={chatContainerRef}>
        <div ref={loadNextMoreRef} />

        <ChattingContent
          chatRoomID={chatRoomID}
          messages={messagesWithUnread}
          setResMessages={setResMessages}
          resMessages={stableResMessages}
        />

        <div ref={loadPreviousMoreRef} />
      </div>
      {isBottomButtonVisible && <MoveBelowButton ref={chatContainerRef} />}
    </>
  );
}
