import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiPopupHospitalElFindSimpleParams } from '@apis/swaggers/swagger-docs';
import { useSuspenseQuery } from '@tanstack/react-query';
import PopupListItem from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupList/PopupListBody/components/PopupListItem';
import { popupListQueryKeyState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-list-query-key-state';
import ContentNoData from '@templates/Content/containers/ContentManager/shared/components/ContentNoData/ContentNoData';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useAtomValue } from 'jotai/index';
import { useMemo } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchHospitalPopupList = async (params: ApiPopupHospitalElFindSimpleParams) => {
  const response = await apiClient.v3.apiPopupHospitalElFindSimple(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function PopupListBody() {
  const { hospitalID } = useSelectedHospitalInfo();
  const popupListQueryKey = useAtomValue(popupListQueryKeyState);

  const { data: popupList } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiPopupHospitalElFindSimple,
      { ...popupListQueryKey, hospitalID },
    ] as const,
    queryFn: ({ queryKey }) => fetchHospitalPopupList(queryKey[1]),
  });

  const isSearchQueryEntered = useMemo(() => {
    if (popupListQueryKey.keyword) {
      return popupListQueryKey.keyword.length > 0;
    }
    return false;
  }, [popupListQueryKey.keyword]);

  if (!popupList.length) {
    return <ContentNoData isSearchQueryEntered={isSearchQueryEntered} type={'popup'} />;
  }

  return (
    <div className='flex flex-col'>
      {popupList.map((item) => (
        <PopupListItem key={item._id} data={item} />
      ))}
    </div>
  );
}
