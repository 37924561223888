import type { DataResponse } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/hooks/use-conditional-chat-data';
import { selectedMessageIDState } from 'afterdoc-saas-web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { useAtomValue } from 'jotai';
import { useMemo, useRef } from 'react';
import { MESSAGE_TYPE_CODE, USER_TYPE_CODE } from '../components/ChattingMessage/constants/message';

type useMessagesWithUnreadProps = {
  messages: DataResponse[];
  chatRoomPatientUnreadCount: number;
};

export function useMessagesWithUnread({
  messages,
  chatRoomPatientUnreadCount,
}: useMessagesWithUnreadProps) {
  const selectedMessageID = useAtomValue(selectedMessageIDState);
  const unreadIDList = useRef<string[]>([]);

  return useMemo(() => {
    if (chatRoomPatientUnreadCount === 0) {
      unreadIDList.current = [];
      return messages.map((msg) => ({ ...msg, isUnread: false }));
    }
    let count = chatRoomPatientUnreadCount;

    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      const isLeft = message.userType === USER_TYPE_CODE.PATIENT; // 1000: 환자
      const isError = message.error !== undefined;
      const isUnreadCondition =
        !isError &&
        !!count &&
        !isLeft &&
        message.type !== 13910 && //설문 응답 메시지 왼쪽이라 발생할 상황 없지만 방어
        message.type !== 50000 && //번역 모킹 타입
        message.type !== 40000 && //시스템 메시지
        message.type !== MESSAGE_TYPE_CODE.TEXT_MOCKING &&
        message.type !== MESSAGE_TYPE_CODE.IMAGE_MOCKING &&
        message.type !== MESSAGE_TYPE_CODE.FILE_MOCKING;

      if (isUnreadCondition) {
        count--;
        if (selectedMessageID === null && !unreadIDList.current.includes(message._id)) {
          unreadIDList.current.push(message._id);
        }
      }

      if (count === 0) {
        break;
      }
    }

    const newMessage = messages.map((message) => {
      const isUnread = unreadIDList.current.includes(message._id);
      return { ...message, isUnread };
    });

    //TODO - 채팅 response type 정리 필요
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    return newMessage as any[];
  }, [messages, chatRoomPatientUnreadCount]);
}
