import type { DataNotificationPayload } from '@shared-hooks/use-data-event-bus-handler/event-bus';
import type { InfiniteData, QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import type { ChatFileAndContentIDs } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { isBookmarkMapState } from 'afterdoc-saas-web/templates/CustomerChat/states/is-bookmark-map';
import { chatRoomIDSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useDataEventBusHandler } from 'hooks/use-data-event-bus-handler/use-data-event-bus-handler';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';

type BookmarkPushHandlerParams = {
  refetch: (options?: RefetchOptions) => Promise<
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    QueryObserverResult<InfiniteData<Record<string, any> & ChatFileAndContentIDs[], unknown>, Error>
  >;
};

export const useBookmarkPushHandler = ({ refetch }: BookmarkPushHandlerParams) => {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const setBookmarkState = useSetAtom(isBookmarkMapState);

  const { userId: userID } = useUserInfo();

  const handleNotification = useCallback(
    async (payload: DataNotificationPayload) => {
      const { code, chatID: messageID, chatRoomID: targetChatRoomID, senderID } = payload.data;
      if (senderID === userID) return;

      if (targetChatRoomID === chatRoomID) {
        const isCreate = code === 'd_ChatBookmarkCreate_el';
        setBookmarkState((prev) => ({ ...prev, [messageID as string]: isCreate }));
        refetch();
      }
    },
    [chatRoomID, refetch, setBookmarkState, userID],
  );

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_ChatBookmarkCreate_el', 'd_ChatBookmarkRemove_el'],
        handler: handleNotification,
      },
    ],
    [handleNotification],
  );

  useDataEventBusHandler(notificationConfigs);
};
