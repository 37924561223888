import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import CustomerManagementContainer from 'afterdoc-saas-web/templates/CustomerManagement/CustomerManagement.container';

export default function CustomerManagementPage() {
  return (
    <Scrollbar isAlwaysVisible>
      <CustomerManagementContainer />
    </Scrollbar>
  );
}
