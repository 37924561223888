import { IMAGE_EXTENSIONS } from 'afterdoc-saas-web/shared/utils/fileUpload/constants/file';

type ValidateFileSizeParams = {
  file: File;
  imageLimitSize?: number;
  fileLimitSize?: number;
};

export const TEN_MB = 1024 * 1024 * 10;
export const FIVE_MB = 1024 * 1024 * 5;

export const validateFileSize = ({
  file,
  imageLimitSize = TEN_MB,
  fileLimitSize = TEN_MB,
}: ValidateFileSizeParams) => {
  const { type, size } = file;
  const fileType = type.split('/')[1];
  const isImageFile = IMAGE_EXTENSIONS.includes(fileType);

  const limitSize = isImageFile ? imageLimitSize : fileLimitSize;

  return size <= limitSize;
};
