import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { SHARED_UTILS } from 'utils/utils';

export const postChatRoomElCounselor = async (
  params: Parameters<typeof apiClient.v3.apiChatroomElCounselorAdd>[0],
) => {
  const response = await apiClient.v3.apiChatroomElCounselorAdd(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const postApprovalChatroomCreate = async (
  params: Parameters<typeof apiClient.v3.apiApprovalChatroomElCreate>[0],
) => {
  const response = await apiClient.v3.apiApprovalChatroomElCreate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const postChatroomUserAdd = async (
  params: Parameters<typeof apiClient.v3.apiChatroomElUserAdd>[0],
) => {
  const response = await apiClient.v3.apiChatroomElUserAdd(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const postCustomerDelete = async (
  params: Parameters<typeof apiClient.v3.apiHservicesElSuspend>[0],
) => {
  const response = await apiClient.v3.apiHservicesElSuspend(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};
