import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import {
  chatRoomIDSelector,
  chatRoomParentHServiceIDSelector,
} from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';

export const useSyncChattingListAndInfoTop = () => {
  const queryClient = useQueryClient();

  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const chatRoomParentHServiceID = useAtomValue(chatRoomParentHServiceIDSelector);

  const query = useMemo(
    () => ({
      patientId: chatRoomParentHServiceID,
      chatRoomID,
    }),
    [chatRoomParentHServiceID, chatRoomID],
  );

  const updateChattingListAndInfoTop = useCallback(() => {
    Promise.all([
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.chatRoomElFindServiceInformationHandler, { chatRoomID }],
      }),
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiPatientsElDetail, query],
      }),
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiChatroomElFind],
      }),
    ]);
  }, [query, chatRoomID, queryClient]);

  return { updateChattingListAndInfoTop };
};
