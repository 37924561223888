import type {
  HospitalAftercareFindSimple,
  HospitalEventFindSimple,
} from '@apis/swaggers/swagger-docs';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';

interface EventTopPinnedStatusProps<
  T extends HospitalEventFindSimple | HospitalAftercareFindSimple,
> {
  isPinned: boolean;
  handleChange: <K extends keyof T>(key: K, value: T[K]) => void;
}

export default function TopPinnedStatus<
  T extends HospitalEventFindSimple | HospitalAftercareFindSimple,
>({ isPinned, handleChange }: EventTopPinnedStatusProps<T>) {
  const { authorizationTypeID } = useUserInfo();

  return (
    <div className='flex-center' onClick={(e) => e.stopPropagation()}>
      <IconButton
        icon={isPinned ? 'pinned' : 'pin'}
        size={24}
        onClick={() => handleChange('isPinned', !isPinned)}
        disabled={!authorizationTypeID?.canUpsertContent}
      />
    </div>
  );
}
