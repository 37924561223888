import { CHAT_SOCKET_URL } from '@config/url';
import { type Socket, io } from 'socket.io-client';
import { withLogging } from './withLogging';

//렌더링과 무관하게 한개만 존재하게 하고싶음.
const socket: { chatRoomID: string | null; instance: Socket | null } = {
  chatRoomID: null,
  instance: null,
};

type CreateSocketConnectionParams = {
  userID: string;
  chatRoomID: string;
};

const SOCKET_EVENTS = {
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
};

const initSocketInstance = (userID: string, chatRoomID: string): Socket => {
  const options = {
    secure: true,
    transports: ['websocket'],
    reconnection: true,
    reconnectionAttempts: 5,
    query: { chatRoomID, userID },
  };

  return io(CHAT_SOCKET_URL, options);
};

const handleSocketEvents = (instance: Socket) => {
  instance.on(SOCKET_EVENTS.CONNECT, () => {
    withLogging({
      msg: 'WebSocket connected',
      type: 'info',
    });
  });

  instance.on(SOCKET_EVENTS.DISCONNECT, (reason: string) => {
    withLogging({
      msg: `WebSocket disconnected: ${reason}`,
      type: 'info',
    });

    if (reason !== 'io client disconnect') {
      resetSocketInstance();
    }
  });

  instance.on('connect_error', (error) => {
    withLogging({
      msg: `Connection error: ${error.message}`,
      type: 'error',
    });
  });

  instance.on('reconnect_failed', () => {
    withLogging({
      msg: 'Reconnection failed after maximum attempts.',
      type: 'error',
    });
  });
};

const resetSocketInstance = () => {
  socket.instance = null;
  socket.chatRoomID = null;
};

export const createSocketConnection = ({
  userID,
  chatRoomID,
}: CreateSocketConnectionParams): Socket => {
  //socket.instance.connected가 가드에 있으면 연결만 안되어있다하면 다 새로만들어버림
  if (socket.instance && socket.chatRoomID === chatRoomID) {
    //재연결은 socket io의 default option인 autoConnect가 동작
    //autoConnect가 동작하지 않는 상황이 발생해 방어 코드 추가
    if (!socket.instance.connected) {
      socket.instance.connect();
    }
    return socket.instance;
  }
  socket.instance?.disconnect();

  socket.instance = initSocketInstance(userID, chatRoomID);
  socket.chatRoomID = chatRoomID;

  handleSocketEvents(socket.instance);

  return socket.instance;
};
