import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useChatRoomInfoOne } from '../components/ChattingRoom/hooks/use-chatroom-info-one';
import { channelClosedStateSwitch } from '../functions/channel-closed-state-switch';
import { useChatTopPatient } from '../hooks/use-chat-top-patient';
import { isUsageHaltedDisabledState } from '../states/is-usage-halted-disabled';
import { roomTypeSelector } from '../states/selected-chat-room';

export const useIsDisabledChat = ({ chatRoomID }: { chatRoomID: string }) => {
  const [isUsageHaltedDisabledSending, setIsUsageHaltedDisabledSending] = useState(false);

  const roomType = useAtomValue(roomTypeSelector);
  const setIsUsageHaltedDisabled = useSetAtom(isUsageHaltedDisabledState);

  const { ikakaoInfo } = useChatRoomInfoOne({ chatRoomID });
  const { iconSet } = useChatTopPatient({ chatRoomID });

  const { isClosedChannelChat, code } = channelClosedStateSwitch({ roomType, ikakaoInfo });

  useEffect(() => {
    const isUsageHaltedDisabled =
      isClosedChannelChat || (iconSet?.isGrayAfterDocIcon && !iconSet?.isKakaoIcon);

    setIsUsageHaltedDisabledSending(isUsageHaltedDisabled);
    setIsUsageHaltedDisabled(isUsageHaltedDisabled);
  }, [isClosedChannelChat, iconSet, setIsUsageHaltedDisabled]);

  return { isUsageHaltedDisabledSending, code };
};
