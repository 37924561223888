import type { KakaoConsultationTalkLinkageHandlerBodyRequest } from '@apis/swaggers/swagger-docs';
import { consultationInformationDayErrorAtom } from '@templates/HospitalSetting/containers/ChannelIntegration/states/kakao-consult-form-error-state';
import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import type { ConsultationInformation } from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoConsultTalkForm/components/ConsultTalkIntegrationWrapper/components/IntegrationConsultDatePicker/IntegrationConsultDatePicker';
import { useSetAtom } from 'jotai/index';
import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

interface DayCheckboxProps {
  label: string;
  fieldKey: keyof ConsultationInformation;
  disabled: boolean;
}

export default function DayCheckbox({ label, fieldKey, disabled }: DayCheckboxProps) {
  const { control, setValue } = useFormContext<KakaoConsultationTalkLinkageHandlerBodyRequest>();

  const setConsultationInformationDayError = useSetAtom(consultationInformationDayErrorAtom);

  const checked = useWatch({
    control,
    name: `consultationInformation.${fieldKey}`,
  });

  const handleCheck = useCallback(() => {
    setConsultationInformationDayError(false);
    setValue(`consultationInformation.${fieldKey}`, !checked);
  }, [checked, fieldKey, setValue]);

  return <Checkbox label={label} disabled={disabled} checked={checked} onChange={handleCheck} />;
}
