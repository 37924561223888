import { SHARED_UTILS } from '@shared-utils/utils';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';

export const postChatroomUpdateCounselState = async ({ chatRoomID }: { chatRoomID: string }) => {
  const params = {
    chatRoomID,
  };
  const { data } = await apiClient.v3.apiChatroomElUpdateCounselStatus(params);

  return SHARED_UTILS.api.checkApiResponse(data);
};
