import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import DownloadFooter from 'afterdoc-design-system/components/Molecules/DownloadFooter/DownloadFooter';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import {
  focusedCellState,
  selectedCellState,
} from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';
import { useResetAtom } from 'jotai/utils';
import { useFormContext, useWatch } from 'react-hook-form';
import type { UnTaggedCustomerManagementAPIFormValues } from '../../types/table';

const postUnTaggedPatientsInfo = async (
  params: Parameters<typeof apiClient.v3.apiPatientsElUntaggedTarget>[0],
) => {
  const response = await apiClient.v3.apiPatientsElUntaggedTarget(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function UnTaggedCustomerManagementDownloadFooter() {
  const { hospitalID } = useSelectedHospitalInfo();

  const queryClient = useQueryClient();
  const {
    control,
    reset,
    getValues,
    formState: { isDirty, isSubmitted },
  } = useFormContext<{ rows: UnTaggedCustomerManagementAPIFormValues }>();

  const rows = useWatch({
    name: 'rows',
    control: control,
  });

  const resetSelectedCell = useResetAtom(selectedCellState);
  const resetFocusedCell = useResetAtom(focusedCellState);

  const postUnTaggedPatientsInfoMutation = useMutation({
    mutationFn: postUnTaggedPatientsInfo,
    onMutate: () => {
      fullDimmedLoadingService.on();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiPatientsElUntaggedCount, { hospitalID }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiPatientsElListOrSearchForUntagged],
      });

      for (const row of rows) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiPatientsElDetail, { patientId: row.patientId }],
        });
      }

      resetSelectedCell();
      resetFocusedCell();

      reset({
        rows: getValues('rows').filter((item) => item.treatmentTagIds?.length === 0),
      });

      fullDimmedLoadingService.off();
      toastService.successMsg({ text: '고객 정보를 업데이트 했습니다.' });
    },
    onError: (error) => {
      console.error('error', error);
      toastService.errorMsg({ text: '고객 정보 업데이트를 실패했습니다.' });
      fullDimmedLoadingService.off();
    },
  });

  const handleSave = () => {
    const updatedValues = rows.map((item) => ({
      patientId: item.patientId as string,
      treatmentTagIds: item.treatmentTagIds,
      nationalityId: item.nationalityId as string | undefined,
    }));

    postUnTaggedPatientsInfoMutation.mutate(updatedValues);
  };

  return (
    <DownloadFooter
      onSave={handleSave}
      saveButton='저장'
      saveButtonProps={{
        disabled: !isDirty && !isSubmitted,
      }}
      className='sticky right-0 bottom-0'
    />
  );
}
