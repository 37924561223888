import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import { chatRoomIDSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useDataEventBusHandler } from 'hooks/use-data-event-bus-handler/use-data-event-bus-handler';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { BASED_CHAT_QUERY } from '../components/ChattingContent/hooks/use-infinite-chat-data';

export const useRefreshScreenPushHandler = () => {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  if (!chatRoomID) return;

  const queryClient = useQueryClient();

  const query = useMemo(
    () => ({
      chatRoomID,
      ...BASED_CHAT_QUERY,
    }),
    [chatRoomID],
  );

  const handleNotification = useCallback(async () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiChatElFind, query],
    });
    //채팅리스트 추가로 갱신
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiChatroomElFind],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiChatroomElCount],
    });
  }, [query, queryClient.invalidateQueries]);

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_refreshScreen_el'],
        handler: handleNotification,
      },
    ],
    [handleNotification],
  );

  useDataEventBusHandler(notificationConfigs);
};
