import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiHospitalsElFindParams, Hospital } from '@apis/swaggers/swagger-docs';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { validateCustomerMessageSendParams } from '@templates/HospitalSetting/containers/CustomerMessageSend/functions/validate-customer-message-send-params';
import { validateHospitalProfileParams } from '@templates/HospitalSetting/containers/HospitalProfile/functions/validate-hospital-profile-params';
import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import { HospitalInfoInputErrorMessagesState } from '@templates/HospitalSetting/states/hospital-info-input-error-messages-state';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useAtom, useSetAtom } from 'jotai/index';
import { useCallback, useEffect, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchHospitalInfo = async (params: ApiHospitalsElFindParams) => {
  try {
    const response = await apiClient.v3.apiHospitalsElFind(params);
    const result = SHARED_UTILS.api.checkApiResponse(response.data);
    return result[0];
  } catch (error) {
    console.error('Error fetching hospital info', error);
    return undefined;
  }
};

const getValidationErrors = (page: 'profile' | 'customerMessage', hospitalInfo: Hospital) => {
  return page === 'profile'
    ? validateHospitalProfileParams(hospitalInfo)
    : validateCustomerMessageSendParams(hospitalInfo);
};

const updateHospitalInfo = async (hospital: Hospital) => {
  const response = await apiClient.v3.apiHospitalsElUpdate(hospital);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useHospitalInfo = () => {
  const { hospitalID } = useSelectedHospitalInfo();

  const [hospitalInfo, setHospitalInfo] = useAtom(hospitalInfoState);
  const [initialHospitalInfo, setInitialHospitalInfo] = useState<Hospital | null>(null);

  const setHospitalInfoInputErrorMessages = useSetAtom(HospitalInfoInputErrorMessagesState);

  const { data, refetch } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiHospitalsElFind, { _id: hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchHospitalInfo(queryKey[1]),
  });

  const updateHospitalMutation = useMutation({
    mutationFn: () => updateHospitalInfo(hospitalInfo),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      refetch();
      toastService.successMsg({ text: '저장되었습니다.' });
    },
    onError: (error: Error) => {
      console.error('Error saving hospital info:', error);
      toastService.errorMsg({
        text: '저장을 실패하였습니다. 정보를 다시 확인해 주세요.',
      });
    },
  });

  const handleSave = useCallback(
    async (page: 'profile' | 'customerMessage') => {
      const errors = getValidationErrors(page, hospitalInfo);
      if (Object.keys(errors).length > 0) {
        toastService.errorMsg({ text: '저장을 실패하였습니다. 정보를 다시 확인해 주세요.' });
        setHospitalInfoInputErrorMessages((prev) => ({ ...prev, ...errors }));
        return;
      }
      updateHospitalMutation.mutate();
    },
    [hospitalInfo],
  );

  useEffect(() => {
    if (data) {
      console.info('> fetch api res  : ', data);
      setHospitalInfo(data);
      setInitialHospitalInfo(data);
    }
  }, [data, setHospitalInfo]);

  return { hospitalInfo, initialHospitalInfo, updateHospitalInfo, handleSave };
};
