import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { isUnBlockedState } from 'afterdoc-saas-web/shared/states/is-unblocked';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

export const UNSAVED_CHANGES_WARNING_ID = 'unsaved-changes-warning';

interface UseNavigationBlockerProps {
  shouldBlock: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  warningTitle?: string;
  warningMessage?: string;
}

export const useNavigationBlocker = ({
  shouldBlock,
  onConfirm,
  onCancel,
  warningTitle = '저장하지 않고 나가시겠어요?',
  warningMessage = '화면을 이동할 경우 입력한 내용이 사라집니다.',
}: UseNavigationBlockerProps) => {
  const setIsUnBlocked = useSetAtom(isUnBlockedState);
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      shouldBlock && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setIsUnBlocked(false);
      modalService.defaultWarning({
        id: UNSAVED_CHANGES_WARNING_ID,
        title: warningTitle,
        contents: warningMessage,
        onConfirm: () => {
          onConfirm?.();
          modalService.popById(UNSAVED_CHANGES_WARNING_ID);
          setIsUnBlocked(true);
          blocker.proceed();
        },
        onCancel: () => {
          onCancel?.();
          modalService.popById(UNSAVED_CHANGES_WARNING_ID);
          setIsUnBlocked(true);
          blocker.reset();
        },
      });
    }
  }, [blocker.state, blocker, onConfirm, onCancel, warningTitle, warningMessage, setIsUnBlocked]);
};
