import { parseIconSet } from '@templates/CustomerChat/components/ChattingList/functions/parse-icon-set-by-chat-list';
import type { TempChatRoomHServiceLastMessageUser } from '@templates/CustomerChat/components/ChattingList/hooks/use-chatting-list';
import { USER_TYPE_CODE } from '@templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/constants/message';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { isDisabledChat } from 'afterdoc-saas-web/templates/CustomerChat/functions/is-disabled-Chat';
import type { RoomType } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useMemo } from 'react';

type TempRoomTypeWithNull = RoomType | null;

export const useParsedChatRoomData = (chatRoom: TempChatRoomHServiceLastMessageUser) => {
  const { userId } = useUserInfo();

  return useMemo(() => {
    const {
      _id: id,
      lastMessageUpdatedAt,
      lastMessage,
      counselorID,
      info,
      title,
      parentTitle,
      userID: chatRoomPatient,
      parentHServiceID,
      hserviceID,
      counselors,
      roomType,
      ikakaoInfo,
    } = chatRoom;

    const {
      content = '',
      isTranslated = false,
      translatedContent = '',
      type,
      userType,
    } = lastMessage || {};

    const chatRoomPatientID = chatRoomPatient?._id;

    const realTitle = parentTitle ?? (title || '이름없음');
    const chatRoomParentHServiceID = parentHServiceID?._id;

    const { isKakaoIcon, isGrayAfterDocIcon, isColorAfterDocIcon } = parseIconSet({ chatRoom });

    const isLeft = userType === USER_TYPE_CODE.PATIENT;

    const userInfo = info.find((user) => user.userID === userId);
    const isBadge = (userInfo?.count ?? 0) > 0;

    const isUsageHaltedDisabledSending = isDisabledChat({
      roomType: roomType as TempRoomTypeWithNull,
      ikakaoInfo,
      iconSet: { isKakaoIcon, isGrayAfterDocIcon, isColorAfterDocIcon, isPendingService: false },
    });

    const chatRoomHserviceID = hserviceID?._id;

    const isLinked = chatRoomHserviceID !== chatRoomParentHServiceID;

    return {
      id,
      lastMessageUpdatedAt,
      content,
      isTranslated,
      translatedContent,
      type,
      userType,
      realTitle,
      chatRoomParentHServiceID,
      patientIDInChattingListUserID: chatRoomPatientID,
      counselorID,
      counselors,
      info,
      isLeft,
      isBadge,
      isKakaoIcon,
      isGrayAfterDocIcon,
      isColorAfterDocIcon,
      chatRoomHserviceID,
      isUsageHaltedDisabledSending,
      isLinked,
      roomType: roomType as TempRoomTypeWithNull,
    };
  }, [chatRoom, userId]);
};
