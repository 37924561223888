import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  AlgorithmDeletionRequest,
  AlgorithmWithMessagesInput,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';

const removeAlgorithm = async (params: AlgorithmDeletionRequest) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsSingleDeletion(params);
  return response.data;
};

interface UseDeleteAlgorithmProps {
  type: Exclude<AlgorithmWithMessagesInput['type'], 'COMMON'>;
  onSuccessCb?: () => void;
}

export const useDeleteAlgorithm = ({ type, onSuccessCb }: UseDeleteAlgorithmProps) => {
  const { hospitalID } = useSelectedHospitalInfo();
  const queryClient = useQueryClient();

  const removeAlgorithmMutation = useMutation({
    mutationFn: removeAlgorithm,
  });

  const handleDeleteAlgorithm = (algorithmId: string) => {
    removeAlgorithmMutation.mutate({ algorithmId });
  };

  const handleDeleteAlgorithmWithModal = (algorithmId: string) => {
    modalService.defaultWarning({
      id: 'delete-algorithm',
      title:
        type === 'CONSULTATION'
          ? '상담자동화 세트를 삭제할까요?'
          : '마케팅자동화 세트를 삭제할까요?',
      contents:
        '삭제 시, 적용되고 있는 모든 고객에게 메시지 발송이 중단되며, 삭제된 자동화 세트는 복구할 수 없습니다.',
      onConfirm: () => {
        removeAlgorithmMutation.mutate(
          { algorithmId },
          {
            onSuccess: (data) => {
              if (data.code === 0) {
                toastService.successMsg({
                  text: '자동화가 삭제되었습니다.',
                });

                queryClient.invalidateQueries({
                  queryKey: [QUERY_KEY.apiAutomationsElMainList, { hospitalID }],
                });
                queryClient.invalidateQueries({
                  queryKey: [QUERY_KEY.apiAutomationsElOngoing, { hospitalID }],
                });
                onSuccessCb?.();
              } else {
                toastService.errorMsg({
                  text: '자동화 삭제를 실패했습니다. 다시 시도해 주세요.',
                });
              }
            },
            onError: () => {
              toastService.errorMsg({
                text: '자동화 삭제를 실패했습니다. 다시 시도해 주세요.',
              });
            },
          },
        );
        modalService.popById('delete-algorithm');
      },
    });
  };

  return {
    handleDeleteAlgorithm,
    handleDeleteAlgorithmWithModal,
  };
};
