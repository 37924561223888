import { Color } from '@tailwind-base/styles/color';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import type { TreatmentTag } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import './TagList.scss';

interface TagListProps {
  treatmentTags: TreatmentTag[];
}

export default function TagList({ treatmentTags }: TagListProps) {
  return (
    <div className='tag-list-scrollbar flex h-full max-h-[92px] w-[326px] flex-wrap gap-6 overflow-y-auto rounded-r16 bg-white50 p-10 shadow-modal'>
      {treatmentTags.map((tag, index) => (
        <FilledTag
          key={`${tag.name}-${index}`}
          bgColor={tag.color ? tag.color : Color.white400}
          tagSize='big'>
          {tag.name}
        </FilledTag>
      ))}
    </div>
  );
}
