import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import DayPicker from 'afterdoc-design-system/components/Atoms/Calendar/DayPicker';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { reservationSelectedDateState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/states/date';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';

const YEAR_RANGE = [1900, 2500] as [number, number];

const formatDateWithHyphens = (dateStr: string) => {
  const cleaned = dateStr.replace(/[^0-9]/g, '');
  if (cleaned.length <= 4) return cleaned;
  if (cleaned.length <= 6) return `${cleaned.slice(0, 4)}-${cleaned.slice(4)}`;
  return `${cleaned.slice(0, 4)}-${cleaned.slice(4, 6)}-${cleaned.slice(6)}`;
};

const isValidDate = (year: number, month: number, day: number) => {
  if (year < 1900 || year > 2500) return false;
  if (month < 1 || month > 12) return false;
  const daysInMonth = new Date(year, month, 0).getDate();
  if (day < 1 || day > daysInMonth) return false;
  return true;
};

export default function DaySelector() {
  const inputRef = useRef<HTMLInputElement>(null);
  const setReservationSelectedDate = useSetAtom(reservationSelectedDateState);

  // 상태 관리
  const [selectedDate, setSelectedDate] = useState(dayjs().toDate());
  const [isToggle, setIsToggle] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [inputValue, setInputValue] = useState(dayjs().format('YYYY-MM-DD'));

  // 날짜 업데이트 핸들러
  const updateSelectedDate = (newDate: Date) => {
    setSelectedDate(newDate);
    setInputValue(dayjs(newDate).format('YYYY-MM-DD'));

    const startDate = dayjs(newDate).startOf('day');
    const endDate = dayjs(newDate).endOf('day');

    setReservationSelectedDate({
      startedAt: startDate.utc().toDate(),
      endedAt: endDate.utc().toDate(),
    });
  };

  // 입력 핸들러
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    if (/[^0-9-]/.test(input)) return;

    const formattedInput = formatDateWithHyphens(input);
    setInputValue(formattedInput);

    if (formattedInput.length === 10) {
      const [year, month, day] = formattedInput.split('-').map(Number);
      if (isValidDate(year, month, day)) {
        setIsValid(true);
        const newDate = new Date(year, month - 1, day);
        updateSelectedDate(newDate);
      } else {
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
  };

  // 화살표 버튼 핸들러
  const handleDateChange = (direction: 'prev' | 'next') => {
    const newDate =
      direction === 'prev'
        ? dayjs(selectedDate).subtract(1, 'day').toDate()
        : dayjs(selectedDate).add(1, 'day').toDate();

    updateSelectedDate(newDate);
  };

  // 키보드 핸들러
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      setIsToggle(false);
      inputRef.current?.blur();
    }
  };

  useEffect(() => {
    const today = dayjs().toDate();
    updateSelectedDate(today);
  }, []);

  return (
    <div className='flex items-center gap-10'>
      <IconButton
        icon='chevron-left'
        size={16}
        color='black500'
        className='h-24 w-24 rounded-r6 border border-white400 p-3'
        onClick={() => handleDateChange('prev')}
      />
      <div className='relative'>
        <TextInput
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onClick={() => setIsToggle(!isToggle)}
          maxLength={10}
          className={isValid ? '' : 'input-error'}
        />
        {isToggle && (
          <DayPicker
            hasError={!isValid}
            className={customTwMerge('absolute z-10')}
            selectedDate={selectedDate}
            onChangeDate={(newDate) => {
              if (!newDate) return;
              updateSelectedDate(newDate);
              setIsToggle(false);
            }}
            handleToggle={() => setIsToggle(false)}
            yearRange={YEAR_RANGE}
          />
        )}
      </div>
      <IconButton
        icon='chevron-right'
        size={16}
        color='black500'
        className='h-24 w-24 rounded-r6 border border-white400 p-3'
        onClick={() => handleDateChange('next')}
      />
    </div>
  );
}
