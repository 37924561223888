import { useQueryClient } from '@tanstack/react-query';
import { postChatroomUpdateRead } from '@templates/CustomerChat/components/ChattingList/components/ChattingListBody/ChattingListBody';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ChatFileAndContentIDs } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { neverCheck } from 'afterdoc-saas-web/shared/utils/never-check';
import { SORT_OPTIONS } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingList/ChattingList';
import { DEFAULT_CHATTING_LIST_PARAMS } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingList/constants/chatting-list-default-params';
import { chattingListQueryParamsState } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingList/hooks/use-chatting-list';
import { useInfiniteChatData } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-infinite-chat-data';
import { useInfiniteMessageIDChatData } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-infinite-messageID-chat-data';
import { handleErrorInKakaoIsLoginUser } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/functions/handle-error-in-kakao-is-login-user';
import { REQUEST_ROOM_TYPE_MAP } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingSending/constants/types-codes';
import { useChatRoomInfoOne } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/hooks/use-chatroom-info-one';
import { isActivatingChatTranslateState } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/states/is-activating-chat-translate';
import { ROOM_TYPE_MAP } from 'afterdoc-saas-web/templates/CustomerChat/constants/ROOM_TYPE_MAP';
import { CHATTING_LIST_TABS } from 'afterdoc-saas-web/templates/CustomerChat/constants/tab';
import {
  chatRoomParentHServiceIDSelector,
  roomTypeSelector,
} from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { selectedChattingListTabState } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chatting-list-tab';
import type {
  ChattingResponse,
  FileChattingResponse,
  ImageChattingResponse,
  TextChattingResponse,
} from 'afterdoc-saas-web/templates/CustomerChat/types';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { postChatroomUsersAdd } from '../apis/post-chatroom-users-add';
import {
  FILE_TYPE_GROUP,
  IMAGE_TYPE_GROUP,
  TEXT_TYPE_GROUP,
} from '../components/ChattingContent/components/ChattingMessage/Message/Message';
import {
  MESSAGE_TYPE_CODE,
  USER_TYPE_CODE,
} from '../components/ChattingContent/components/ChattingMessage/constants/message';
import { useHandleChatSocket } from '../components/ChattingContent/hooks/use-handle-chat-socket';
import { replaceFileMessage } from '../functions/replace-file-message';
import { replaceImageMessage } from '../functions/replace-image-message';
import { replaceTextMessage } from '../functions/replace-text-message';
import { chattingRequestState } from '../states/chatting-request';

export type DataResponse =
  | ChattingResponse
  | TextChattingResponse
  | ImageChattingResponse
  | (ChatFileAndContentIDs & { error?: Record<string, unknown> })
  | FileChattingResponse;

export function useConditionalChatData(selectedMessageID: string | null, chatRoomID: string) {
  const queryClient = useQueryClient();

  const roomType = useAtomValue(roomTypeSelector);
  const chatRoomParentHServiceID = useAtomValue(chatRoomParentHServiceIDSelector);
  const isActivatingChatTranslate = useAtomValue(isActivatingChatTranslateState);
  const selectedChattingListTab = useAtomValue(selectedChattingListTabState);
  const { keyword, sortOption } = useAtomValue(chattingListQueryParamsState);

  const { hospitalID } = useSelectedHospitalInfo();
  const { userId: userID } = useUserInfo();

  const isCounselInProgress =
    selectedChattingListTab === CHATTING_LIST_TABS.IN_PROGRESS ? true : undefined;

  const { authorizationTypeID, userId } = useUserInfo();
  const { participants } = useChatRoomInfoOne({ chatRoomID });

  const [resMessages, setResMessages] = useState<Array<DataResponse>>([]);
  const [data, setData] = useState<Array<DataResponse>>([]);

  const req = useAtomValue(chattingRequestState);

  const { res } = useHandleChatSocket({ chatRoomID });

  const chattingListQuery = useMemo(
    () => ({
      ...DEFAULT_CHATTING_LIST_PARAMS,
      hospitalID,
      participant: userID,
      ...(keyword && { keyword }),
      isCounselInProgress,
      isSortByUnread: sortOption === SORT_OPTIONS[1],
    }),
    [hospitalID, userID, keyword, sortOption, isCounselInProgress],
  );

  const {
    data: infiniteData,
    fetchNextPage: fetchPreviousPage,
    hasNextPage: hasPreviousPage,
    isFetchingNextPage: isFetchingPreviousPage,
    isLoading,
    refetch,
    query,
  } = useInfiniteChatData(resMessages);

  useEffect(() => {
    if (!infiniteData) return;
    setData(infiniteData);
  }, [infiniteData]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setResMessages([]);

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiChatElFind, query],
    });
  }, [chatRoomID]);

  const infiniteMessageIDChatData = useInfiniteMessageIDChatData({ selectedMessageID });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (roomType === null) throw new Error('Room type is null');

    if (res) {
      console.count('count check res');
      console.info('check res:', res);

      if (!isCounselInProgress) {
        //채팅 방 정보 갱신: 상담종료 상태일 경우 채팅이 발생(DB)에 추가 됐을 경우 진행 중으로 바로 변경 처리해야 함
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiChatroomElFindOne, { chatRoomID }],
        });
      }

      chatRoomID && postChatroomUpdateRead(chatRoomID);

      const { userType } = res;
      const isLeft = userType === USER_TYPE_CODE.PATIENT; // 1000: 환자
      const isLoginUser = res.userID === userID;

      if (isLeft) {
        if (res.isTranslated) {
          //resMessages에 이전 메시지가 있다면
          const existingMessageIndex = resMessages.findIndex((item) => item?._id === res?._id);
          if (existingMessageIndex !== -1) {
            setResMessages((prev) => {
              prev[existingMessageIndex] = res;
              return [...prev];
            });
          } else {
            //data에 이전 메시지가 있다면
            const existingMessageIndexInData = data.findIndex((item) => item?._id === res?._id);
            if (existingMessageIndexInData !== -1) {
              setData((prev) => {
                prev[existingMessageIndexInData] = res;
                return [...prev];
              });

              //환자의 메시지가 data에 들어 있으면서 번역이 왔다면 채팅방의 infinite로 시작되는 메시지를 업데이트 해줘야함.
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.apiChatElFind, query],
              });
            }
          }

          //번역으로 와서 메시지에 업데이트를 쳐줘야하는데 무한 스크롤 때문에 resMessages와 data에 일치하는 친구가 없을 수 있음.
          //이들은 어차피 데이터를 서버에서 불러올때 번역 처리가 되어있을 것이기에 그냥 무시.
          if (res?.isFromTranslateServer) {
            return;
          }

          //이전에 일반 채팅을 한 것을 번역으로 돌리는 게 아니라
          //일반적으로 번역이 켜져있는 상태에서 보낼 경우는 그냥 그대로 붙이면 됨.
          setResMessages((prev) => [res, ...prev]);
        } else {
          //번역이 켜 있는 경우 이미지, 파일들에 isTranslated가 없기 때문에
          if (FILE_TYPE_GROUP.includes(res.type) || IMAGE_TYPE_GROUP.includes(res.type)) {
            setResMessages((prev) => [res, ...prev]);
          }
        }

        //번역이 아닌 일반 메시지일 경우
        //그런데 번역이 켜져있는 경우 일반 메시지가 앞에 한번 추가로 옴(메시지 보내는 규칙에 따라) 그래서 그 경우 무시하기 위해
        !isActivatingChatTranslate && setResMessages((prev) => [res, ...prev]);
        return;
      }

      const { type } = res;

      //채팅리스트 목록 업데이트: 채팅 안에 있는 읽지않음 카운트 업데이트, 환자가 보낸 메시지는 상관 없음
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiChatroomElFind, chattingListQuery],
      });

      switch (roomType) {
        case ROOM_TYPE_MAP.kakao: {
          if (isLoginUser) {
            handleErrorInKakaoIsLoginUser(res);

            if (TEXT_TYPE_GROUP.includes(type)) {
              setResMessages((prev) => {
                return replaceTextMessage(prev, res);
              });
            }
            if (IMAGE_TYPE_GROUP.includes(type)) {
              setResMessages((prev) => {
                return replaceImageMessage(
                  prev as Array<ImageChattingResponse>,
                  res as unknown as ImageChattingResponse,
                );
              });
            }
            if (FILE_TYPE_GROUP.includes(type)) {
              setResMessages((prev) => {
                return replaceFileMessage(
                  prev as Array<FileChattingResponse>,
                  res as unknown as FileChattingResponse,
                );
              });
            }
          } else {
            setResMessages((prev) => {
              return [res, ...prev];
            });
          }

          break;
        }
        case ROOM_TYPE_MAP.instagram:
        case ROOM_TYPE_MAP.manager:
        case ROOM_TYPE_MAP.afterdoc: {
          if (isLoginUser) {
            if (TEXT_TYPE_GROUP.includes(type)) {
              setResMessages((prev) => {
                return replaceTextMessage(prev, res);
              });
            }
            if (IMAGE_TYPE_GROUP.includes(type)) {
              setResMessages((prev) => {
                return replaceImageMessage(
                  prev as Array<ImageChattingResponse>,
                  res as unknown as ImageChattingResponse,
                );
              });

              break;
            }
            if (FILE_TYPE_GROUP.includes(type)) {
              setResMessages((prev) => {
                return replaceFileMessage(
                  prev as Array<FileChattingResponse>,
                  res as unknown as FileChattingResponse,
                );
              });

              break;
            }

            //컨텐츠 친구들
            if ([410, 701, 711, 801].includes(type)) {
              setResMessages((prev) => {
                return [res, ...prev];
              });
            }
          } else {
            setResMessages((prev) => {
              return [res, ...prev];
            });
          }

          break;
        }
        default:
          roomType satisfies never;
          neverCheck(roomType);
      }
    }
  }, [res]);

  //res에 모킹 메시지를 커스텀 하는 걸 건드리는 로직
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (req) {
      console.count('count check req');
      console.info('check req:', req);

      chatRoomID && postChatroomUpdateRead(chatRoomID);

      //참여자에 빠져있는데 권한이 있어서 채팅을 시작하려는 자.
      if (
        authorizationTypeID?.canControlPatientChatting &&
        !participants.some((participant) => participant._id === userId)
      ) {
        const addUser = async () => {
          await postChatroomUsersAdd({
            chatRoomID, //해당 커스텀 훅은 chatRoomID가 null일수 없음 하지만 return 값을 일일히 적용해줘야 하는 문제 때문에 ''로 default 적용
            onlyAddIDs: [userId],
          });

          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.apiChatroomElFindOne, { chatRoomID }],
          });
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY.elFindLinkedChatroomsHandler],
          });
        };

        addUser();
      }

      switch (req.type) {
        case REQUEST_ROOM_TYPE_MAP.AFTERDOC.TEXT_TYPE:
          {
            const payload = req.payload as TextChattingResponse;

            setResMessages((prev) => {
              const isDuplicate = prev.some((message) => message._id === payload._id);
              if (!isDuplicate)
                return [{ ...payload, type: MESSAGE_TYPE_CODE.TEXT_MOCKING }, ...prev];

              return prev;
            });
          }
          break;
        case REQUEST_ROOM_TYPE_MAP.KAKAO.TEXT_TYPE: {
          const payload = req.payload as ChattingResponse;
          setResMessages((prev) => {
            const isDuplicate = prev.some((message) => message._id === payload._id);
            if (!isDuplicate)
              return [{ ...payload, type: MESSAGE_TYPE_CODE.TEXT_MOCKING }, ...prev];

            return prev;
          });
          break;
        }
        case REQUEST_ROOM_TYPE_MAP.KAKAO.IMAGES_FILES_TYPE: {
          const payloads = req.payload as ImageChattingResponse[];
          setResMessages((prev) => {
            return [...payloads, ...prev];
          });

          break;
        }
        case REQUEST_ROOM_TYPE_MAP.AFTERDOC.IMAGE_TYPE: {
          const payload = req.payload as ImageChattingResponse;
          setResMessages((prev) => {
            return [payload, ...prev];
          });
          break;
        }
        case REQUEST_ROOM_TYPE_MAP.AFTERDOC.IMAGE_FILE_TYPE: {
          const payload = req.payload as ImageChattingResponse[];
          setResMessages((prev) => {
            return [...payload, ...prev];
          });
          break;
        }
        default:
          break;
      }
    }
  }, [req]);

  const refreshAll = () => {
    refetch();
    setResMessages([]);
  };

  //어떠한 경우에라도 chat data에 있는 메시지는 무결해야함.
  //어떠한 경우로 empty값이 들어가도 안됨.
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const newResMessages = useMemo(() => {
    return resMessages.filter((message) => {
      return (
        message &&
        message.chatRoomID === chatRoomID &&
        !data.some((item) => message._id === item._id)
      );
    });
  }, [resMessages, data]);

  //newResMessages 안에 있는 친구들 중 _id 가 중복일 경우 맨처음꺼 빼고 무시
  //자동화 메시지 중에 res 받은 시점과 data를 최신화 시키는 시점에 중복이 발생하는 케이스가 있음
  const newResMessagesWithoutDuplicate = useMemo(() => {
    const newResMessagesWithoutDuplicate = newResMessages.reduce((acc, message) => {
      if (!acc.some((item) => item._id === message._id)) {
        acc.push(message);
      }
      return acc;
    }, [] as DataResponse[]);

    return newResMessagesWithoutDuplicate;
  }, [newResMessages]);

  if (!selectedMessageID) {
    return {
      data: [...newResMessagesWithoutDuplicate, ...data],
      resMessages: newResMessages,
      refetch,
      refreshAll,
      setResMessages,
      fetchPreviousPage,
      hasPreviousPage,
      isFetchingPreviousPage,
      isLoading,
      hasNextPage: null,
      isFetchingNextPage: false,
      fetchNextPage: () => Promise.resolve(),
    };
  }

  return {
    setResMessages,
    resMessages: newResMessages,
    refreshAll,
    ...infiniteMessageIDChatData,
  };
}
