import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import { useChatMessageContext } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/ChatMessageContext';
import { findChat } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/components/RightMessage/apis/find-chat';
import { TRANSLATE_TEXT_MESSAGE_LIMIT_TIME } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/components/RightMessage/constants/rightMessage';
import { SORT_WAY } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/constants/message-sort-way';
import { chatRoomIDSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';

/**
 * Custom hook to handle the timeout logic for translated mocking messages.
 * @param {boolean} isLeftMockingMessage - State indicating if the message is being translated.
 * @param {function} setIsLeftMockingMessage - Function to update the translated mocking message state.
 * @param {function} setIsLeftTimeout - Function to update the time error state.
 */

export const useLeftTranslationTimeout = ({
  isLeftMockingMessage,
  setIsLeftMockingMessage,
}: {
  isLeftMockingMessage: boolean;
  setIsLeftMockingMessage: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const queryClient = useQueryClient();
  const [isLeftTimeout, setIsLeftTimeout] = useState(false);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const { setResMessages, _id, isInResMessages } = useChatMessageContext();

  const chatRoomID = useAtomValue(chatRoomIDSelector);

  const startTimer = () => {
    timerRef.current = setTimeout(async () => {
      const res = await findChat(_id);
      setIsLeftMockingMessage(false);

      if (isInResMessages) {
        //res는 DB에서는 번역이 성공 됐는지 실패했는지를 알려주는 값, 그러므로 res의 isTrasnslated가 undefined이면 번역이 실패한 것으로 간주
        if (res?.isTranslated) {
          setResMessages((prev) => {
            return prev.map((message) => {
              if (message._id === res._id) {
                return { ...res, status: 'success', isTranslated: true };
              }
              return message;
            });
          });
          setIsLeftTimeout(false);
        } else {
          setResMessages((prev) => {
            return prev.map((message) => {
              if (message._id === _id) {
                return { ...message, status: 'failed' };
              }
              return message;
            });
          });
          setIsLeftTimeout(true);
        }
      } else {
        const query = {
          chatRoomID,
          key: 'sentAt',
          way: SORT_WAY,
          isRemoved: false,
        };

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiChatElFind, query],
        });
      }
    }, TRANSLATE_TEXT_MESSAGE_LIMIT_TIME);
  };

  const restartTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    startTimer();
  };

  useEffect(() => {
    if (isLeftMockingMessage) {
      startTimer();
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [isLeftMockingMessage]);

  return { isLeftTimeout, restartTimer };
};
