import { useInfiniteQuery } from '@tanstack/react-query';
import LoadingSpinner from 'afterdoc-design-system/components/Atoms/Loading/LoadingSpinner';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  ApiPatientsElTemporaryListOrSearchParams,
  ApiServiceSettingsElCountriesData,
  ApiServiceSettingsElToBeDisplayedPatientsFieldsData,
  ApiTreatmentTagsElData,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { isCustomerManagementSaveLockState } from 'afterdoc-saas-web/hooks/push-notification/states/is-customer-management-save-lock';
import FullLoading from 'afterdoc-saas-web/shared/components/FullLoading/FullLoading';
import { useNavigationBlocker } from 'afterdoc-saas-web/shared/hooks/gnb/use-navigation-blocker';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { nullIfEmpty } from 'afterdoc-saas-web/shared/utils/null-if-empty';
import CustomerManagementTableSearchResetAndRowAdd from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableBody/components/CustomerManagementTableSearchResetAndRowAdd';
import CustomerManagementLockOverlay from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementLockOverlay/CustomerManagementLockOverlay';
import { useTemporaryPatientsLock } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/utils/ use-temporary-patients-lock';
import { TOTAL_PATIENT_ROW_COUNT } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/constants/total-patient-row-count';
import {
  fetchPatientsTemporaryList,
  useResetTable,
} from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/hooks/use-reset-table/use-reset-table';
import { customerTableDisplayModeState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-display-mode';
import { customerManagementScrollbarRefState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-scrollbar';
import { editableHeaderFilterState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/editable-header-filter';
import type { CustomerManagementTemporaryAPIFormValues } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import { createInitialRows } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/utils/create-initial-rows';
import { CUSTOMER_SEARCH_FIELD_OPTIONS } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/constants/dropdown-items';
import { searchTextState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/search';
import { selectedSearchCriteriaIndexState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import { useIntersectionObserver } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-intersection-observer';
import { useVirtualScroll } from 'hooks/use-virtual-scroll';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import CustomerManagementEditableTableBody from '../components/CustomerManagementEditableTableBody/CustomerManagementEditableTableBody';

type CustomerManagementEditableTableBodyContainerProps = {
  countriesList: ApiServiceSettingsElCountriesData['data'];
  treatmentTags: ApiTreatmentTagsElData['data']['treatmentTags'];
} & ApiServiceSettingsElToBeDisplayedPatientsFieldsData['data'];

export default function CustomerManagementEditableTableBodyContainer({
  countriesList,
  treatmentTags,
  ...usingColumns
}: CustomerManagementEditableTableBodyContainerProps) {
  const { resetTable, revertTableData } = useResetTable();
  const { control, setValue } = useFormContext<{
    rows: CustomerManagementTemporaryAPIFormValues;
  }>();

  const [searchParams] = useSearchParams();
  const { hospitalID } = useSelectedHospitalInfo();

  const isFirstRender = useRef(true);
  const rowHeight = useRef(40);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const isCustomerManagementSaveLock = useAtomValue(isCustomerManagementSaveLockState);
  const editableHeaderFilter = useAtomValue(editableHeaderFilterState);
  const searchText = useAtomValue(searchTextState);
  const selectedSearchCriteriaIndex = useAtomValue(selectedSearchCriteriaIndexState);
  const [customerTableDisplayMode, setCustomerTableDisplayMode] = useAtom(
    customerTableDisplayModeState,
  );

  const params: ApiPatientsElTemporaryListOrSearchParams = useMemo(
    () => ({
      hospitalID,
      limit: TOTAL_PATIENT_ROW_COUNT,
    }),
    [hospitalID],
  );

  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage, isError, isLoading } =
    useInfiniteQuery({
      queryKey: [QUERY_KEY.apiPatientsElTemporaryListOrSearch, params],
      queryFn: ({ pageParam }: { pageParam?: string }) =>
        fetchPatientsTemporaryList({
          pageParam,
          ...params,
        }),
      getNextPageParam: (lastPage) => lastPage.nextCursor || undefined,
      initialPageParam: undefined,
      refetchOnMount: 'always',
      staleTime: Number.POSITIVE_INFINITY, // 캐시를 영구적으로 유지
      gcTime: 5 * 60 * 1000, // 5분 동안 사용하지 않으면 가비지 컬렉션
    });

  const rows = useWatch({
    name: 'rows',
    control,
  });

  const initialRows = useMemo(() => {
    if (isFetching) {
      return [];
    }

    if (!data?.pages.flatMap((page) => page.patients)) {
      return createInitialRows(hospitalID);
    }

    const allPatients = data.pages.flatMap((page) =>
      page.patients?.map((patient) => ({
        order: patient.order,
        hospitalID,
        patientId: patient.patientId,
        name: nullIfEmpty(patient.name),
        chartNumber: nullIfEmpty(patient.chartNumber),
        phoneNumber: nullIfEmpty(patient.phoneNumber),
        birthdate: nullIfEmpty(patient.birthdate),
        isFirstVisit: patient.isFirstVisit,
        hasToBlockSendingMessage: patient.hasToBlockSendingMessage,
        gender: patient.gender,
        treatmentTagIds: patient.treatmentTags?.map((tag) => tag.tagId),
        nationalityId: patient.nationality?.id,
      })),
    ) as CustomerManagementTemporaryAPIFormValues;

    if (rows?.length) {
      const mergedRows = [...rows];

      for (const patient of allPatients) {
        const existingRowIndex = mergedRows.findIndex((row) => row.order === patient.order);

        if (existingRowIndex === -1) {
          mergedRows.push(patient);
        }
      }

      // order 기준으로 정렬
      mergedRows.sort((a, b) => a.order - b.order);

      return createInitialRows(hospitalID, mergedRows);
    }

    return createInitialRows(hospitalID, allPatients);
  }, [data, hospitalID, isFetching, rows]);

  const handleAddRows = (newRows: CustomerManagementTemporaryAPIFormValues) => {
    setValue('rows', [...rows, ...newRows]);
  };

  const handleChangeDisplayMode = useCallback(
    (mode?: Parameters<typeof setCustomerTableDisplayMode>[0]) => {
      setCustomerTableDisplayMode(mode ?? 'VIEWER');
    },
    [setCustomerTableDisplayMode],
  );

  const addNew50Rows = () => {
    const new50Rows: CustomerManagementTemporaryAPIFormValues = Array.from(
      { length: TOTAL_PATIENT_ROW_COUNT },
      (_, index) => ({
        order: rows?.length + index + 1,
        hospitalID,
        patientId: null,
        name: null,
        chartNumber: null,
        isFirstVisit: null,
        phoneNumber: null,
        birthdate: null,
        gender: null,
        treatmentTagIds: [],
        nationalityId: null,
        hasToBlockSendingMessage: null,
      }),
    );
    handleAddRows(new50Rows);
  };

  const { getPatientsIsLocked, patchRequestLockMutation } = useTemporaryPatientsLock();

  const handleConfirm = async () => {
    const latestLockData = await getPatientsIsLocked({ hospitalID });
    if (!latestLockData) return;

    await patchRequestLockMutation.mutateAsync({
      hospitalID,
      isLock: false,
      version: latestLockData.version,
    });
    resetTable();
    await revertTableData();
    handleChangeDisplayMode('VIEWER');
  };

  // 각 필터 함수를 메모이제이션
  const filterByFirstVisit = useCallback(
    (rows: CustomerManagementTemporaryAPIFormValues) => {
      if (editableHeaderFilter.isFirstVisit === undefined) {
        return rows;
      }

      if (editableHeaderFilter.isFirstVisit === 'all') {
        return rows.filter((row) => row.isFirstVisit === true || row.isFirstVisit === false);
      }

      return rows.filter((row) => row.isFirstVisit === editableHeaderFilter.isFirstVisit);
    },
    [editableHeaderFilter.isFirstVisit],
  );

  const filterByGender = useCallback(
    (rows: CustomerManagementTemporaryAPIFormValues) => {
      if (editableHeaderFilter.gender === undefined) {
        return rows;
      }

      if (editableHeaderFilter.gender === 'all') {
        // gender가 'MALE' 또는 'FEMALE'인 경우만 포함
        return rows.filter((row) => row.gender === 'MALE' || row.gender === 'FEMALE');
      }

      // 특정 성별만 선택된 경우
      return rows.filter((row) => row.gender === editableHeaderFilter.gender);
    },
    [editableHeaderFilter.gender],
  );

  const filterByTreatmentCategories = useCallback(
    (rows: CustomerManagementTemporaryAPIFormValues) => {
      if (!editableHeaderFilter.treatmentCategories) return rows;

      const categoryIds = editableHeaderFilter.treatmentCategories.split(',');

      return rows.filter((row) =>
        // row 전체를 유지하면서, treatmentTags 중에 매칭되는 것이 있는지만 확인
        row.treatmentTagIds?.some((tagId) => categoryIds.includes(tagId)),
      );
    },
    [editableHeaderFilter.treatmentCategories],
  );

  const filterByNationality = useCallback(
    (rows: CustomerManagementTemporaryAPIFormValues) => {
      if (!editableHeaderFilter.nationality) return rows;

      const nationalityIds = editableHeaderFilter.nationality.split(',');
      return rows.filter((row) => row.nationalityId && nationalityIds.includes(row.nationalityId));
    },
    [editableHeaderFilter.nationality],
  );

  // 검색 필터 함수
  const filterBySearch = useCallback(
    (rows: CustomerManagementTemporaryAPIFormValues) => {
      if (!searchText) return rows;

      const searchColumn = CUSTOMER_SEARCH_FIELD_OPTIONS[selectedSearchCriteriaIndex].key;

      /*
       * 사용자 입력(searchText)에 포함될 수 있는 정규식 특수 문자를 이스케이프 처리합니다.
       * 예를 들어, '(', ')', '[', ']' 등의 문자는 정규식에서 특별한 의미를 가지므로
       * 일반 문자로 처리하기 위해 앞에 백슬래시(\)를 추가합니다.
       */
      const escapeRegExp = (string: string) => {
        // $&는 매치된 전체 문자열을 의미합니다.
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      };
      const escapedSearchText = escapeRegExp(searchText);
      const searchRegex = new RegExp(`.*${escapedSearchText}.*`, 'i');

      return rows.filter((row) => {
        const value = row[searchColumn as keyof typeof row];
        // value가 null 또는 undefined가 아니고, 정규식 테스트를 통과하는지 확인합니다.
        // value가 문자열이 아닐 수도 있으므로 String()으로 변환합니다.
        return !!value && searchRegex.test(String(value));
      });
    },
    [searchText, selectedSearchCriteriaIndex],
  );

  const filterFunctions = useMemo(
    () => [
      filterByFirstVisit,
      filterByGender,
      filterByTreatmentCategories,
      filterByNationality,
      filterBySearch,
    ],
    [
      filterByFirstVisit,
      filterByGender,
      filterByTreatmentCategories,
      filterByNationality,
      filterBySearch,
    ],
  );

  // 필터링된 rows 계산
  const displayRows = useMemo(() => {
    const currentRows = rows?.length ? rows : initialRows;

    const hasActiveFilters =
      Object.values(editableHeaderFilter).some((v) => v !== undefined) || !!searchText?.length;

    if (!hasActiveFilters) {
      return currentRows;
    }

    return filterFunctions.reduce((rows, filterFn) => filterFn(rows), currentRows);
  }, [rows, initialRows, filterFunctions, editableHeaderFilter, searchText, searchParams]);

  const virtualScrollData = useVirtualScroll({
    rows: displayRows,
    rowHeight: rowHeight.current,
    offset: 185,
    buffer: 30,
  });
  const setScrollbarRef = useSetAtom(customerManagementScrollbarRefState);
  const scrollbarRef = useRef<{
    scrollTo: (index: number, behavior?: ScrollBehavior) => void;
  }>(null);

  useNavigationBlocker({
    shouldBlock: customerTableDisplayMode === 'EDITOR',
    onConfirm: handleConfirm,
  });

  useIntersectionObserver({
    target: loadMoreRef.current,
    onIntersect: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    enabled: hasNextPage && !isError && !isLoading,
    rootMargin: '100px',
    threshold: [0, 0.1, 0.5, 1.0],
  });

  useEffect(() => {
    if (isFetching) return;

    if (isFirstRender.current) {
      setValue('rows', initialRows);
      isFirstRender.current = false;
    }
  }, [initialRows, setValue, isFetching]);

  useEffect(() => {
    if (scrollbarRef.current) {
      setScrollbarRef(scrollbarRef.current);
    }
  }, [setScrollbarRef, virtualScrollData.visibleRows]);

  useEffect(() => {
    return () => {
      isFirstRender.current = true;
    };
  }, [isFetching]);

  if (isLoading && !data) {
    return <FullLoading />;
  }

  return (
    <div className='relative'>
      {isCustomerManagementSaveLock.isLock && <CustomerManagementLockOverlay />}
      <Scrollbar
        ref={scrollbarRef}
        id='customer-management-table-body'
        onScroll={(scrollTop) => virtualScrollData.handleScroll(scrollTop)}
        style={{ height: `${window.innerHeight - 185}px` }}>
        <div style={{ height: `${virtualScrollData.totalHeight}px`, position: 'relative' }}>
          <table
            className='w-full table-fixed'
            style={{
              transform: `translateY(${virtualScrollData.offsetY}px)`,
            }}>
            <CustomerManagementEditableTableBody
              countriesList={countriesList}
              treatmentTagsList={treatmentTags}
              visibleRows={virtualScrollData.visibleRows}
              {...usingColumns}
            />
            {virtualScrollData.visibleRows?.length > 0 && (
              <CustomerManagementTableSearchResetAndRowAdd
                handleAddRow={addNew50Rows}
                {...usingColumns}
              />
            )}
          </table>
          <div ref={loadMoreRef} className='absolute bottom-[100px] h-20 w-full' />
        </div>
      </Scrollbar>

      {isFetchingNextPage && (
        <div className='flex w-full justify-center'>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
}
