import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import { clickedPanelIndexState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/panel';
import { selectedCardInfoState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/ControlPanel/containers/OngoingConsultingAutomation/state/selected-card-info';
import { useAtomValue } from 'jotai';
import CustomTabs from './components/TableCustomTabs';
import TableSearchCriteriaDropdown from './components/TableSearchCriteriaDropdown';
import TableSearchInput from './components/TableSearchInput';

export default function SearchFilterContainer() {
  // 고객정보 관리 / 치료태그 미입력
  const clickedPanelIndex = useAtomValue(clickedPanelIndexState);
  // 적용 중인 상담자동화
  const selectedCardInfo = useAtomValue(selectedCardInfoState);

  return (
    <div className='flex min-h-[75px] w-full items-center border-b border-b-white400 px-20 pt-16 pb-20'>
      <div className='flex w-full items-center'>
        {clickedPanelIndex === 0 && !selectedCardInfo && (
          <>
            <CustomTabs />
            <Divider direction='vertical' type='line' height={38} className='mx-20' />
          </>
        )}
        <TableSearchCriteriaDropdown />
        <TableSearchInput />
      </div>
    </div>
  );
}
