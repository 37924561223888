import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import ImageViewer from 'afterdoc-saas-web/shared/components/ImageViewer/ImageViewer';
import { useFetchS3ImageBlobWithCompression } from 'afterdoc-saas-web/shared/hooks/files/images/use-fetch-s3-image-blob';
import { extractImageObjectFromS3Url } from 'afterdoc-saas-web/shared/utils/fileUpload/functions/extract-file-name';
import { useMemo, useState } from 'react';

interface ImageContentProps {
  images: string[];
}

export default function ImageContent({ images }: ImageContentProps) {
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  // 처음 2개의 이미지만 메모이제이션
  const previewImages = useMemo(() => images.slice(0, 2), [images]);

  const { results, imageStatuses } = useFetchS3ImageBlobWithCompression(previewImages);

  const onClickImage = (index: number) => {
    setIsImageViewerOpen(true);
    setSelectedImageIndex(index);
  };

  return (
    <>
      <div className='flex flex-center gap-4'>
        <div className='cursor-pointer' onClick={() => onClickImage(0)}>
          {imageStatuses[previewImages[0]]?.isLoading ? (
            <div
              className={customTwMerge(
                'h-[96px] w-[127px] flex-center rounded-r16 shadow-modal',
                images.length > 1 ? 'h-[96px] w-[127px]' : 'h-[200px] w-[260px]',
              )}>
              <BaseLoading />
            </div>
          ) : (
            <img
              src={results?.[0]?.url}
              alt={results?.[0]?.fileName}
              className={customTwMerge(
                'rounded-r16 bg-white50 object-cover shadow-modal',
                images.length > 1 ? 'h-[96px] w-[127px]' : 'h-[200px] w-[260px]',
              )}
            />
          )}
        </div>
        {images.length > 1 && (
          <div
            className='relative h-[96px] w-[127px] cursor-pointer rounded-r16'
            onClick={() => onClickImage(1)}>
            {imageStatuses[previewImages[1]]?.isLoading ? (
              <div className='h-[96px] w-[127px] flex-center rounded-r16 shadow-modal'>
                <BaseLoading />
              </div>
            ) : (
              <img
                src={results?.[1]?.url}
                alt={results?.[1]?.fileName}
                className={customTwMerge(' h-full w-full rounded-r16 object-cover shadow-modal')}
              />
            )}
            <div className='absolute inset-0 rounded-r16 bg-black opacity-50 ' />
            <div className='absolute inset-0 flex items-center justify-center'>
              <div className='flex h-28 w-81 items-center justify-center gap-6 rounded-r16 bg-black800 bg-opacity-50 text-Body11 text-white50'>
                <Icon name='image-filter' color='white50' size={16} />총 {images.length}장
              </div>
            </div>
          </div>
        )}
      </div>
      {isImageViewerOpen && (
        <ImageViewer
          selectedIndex={selectedImageIndex}
          imageObjects={images?.map(extractImageObjectFromS3Url)}
          imageUrls={images}
          onClose={() => setIsImageViewerOpen(false)}
        />
      )}
    </>
  );
}
