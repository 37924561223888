import type { AwsObject } from 'afterdoc-saas-web/templates/CustomerChat/types';
import { useEffect, useState } from 'react';
import { useChatMessageContext } from '../ChatMessageContext';
import { IMAGE_MESSAGE_LIMIT_TIME } from '../components/RightMessage/constants/rightMessage';
import { ERROR_IMAGE_LOCATION, MOCKING_IMAGE_LOCATION } from '../constants/message';
import ImageList from './components/ImageList/ImageList';

const item = {
  Bucket: '',
  Etag: '',
  Key: '',
  Location: MOCKING_IMAGE_LOCATION,
  fileName: 'mock',
  fileSize: -1,
  key: 'mock',
};

export default function MockingImageMessage() {
  const { fileIDs, status } = useChatMessageContext();

  const [mockitem, setMockItem] = useState<AwsObject>(item);

  if (!fileIDs || fileIDs?.length === 0) return null;

  useEffect(() => {
    if (status === 'failed') {
      setMockItem({
        ...item,
        fileName: 'err',
        Location: ERROR_IMAGE_LOCATION,
      });
    }
    setTimeout(() => {
      setMockItem({
        ...item,
        fileName: 'err',
        Location: ERROR_IMAGE_LOCATION,
      });
    }, IMAGE_MESSAGE_LIMIT_TIME);
  }, []);

  const length = fileIDs.length === 0 ? 1 : fileIDs.length;

  const aws = Array.from({ length }, () => mockitem);
  //swagger에서 정상적으로 주지 않는 타입
  return <ImageList aws={aws as AwsObject[]} isDisabledViewer={true} />;
}
