import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiNoticeHospitalElFindSimpleParams } from '@apis/swaggers/swagger-docs';
import { useSuspenseQuery } from '@tanstack/react-query';
import { popupCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-create-or-update-param-state';
import { selectedPopupEditNoticeConnectErrorAtom } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-edit-error-state';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Organisms/Dropdown/TextFieldSelectBoxDropdown/TextFieldSelectBoxDropdown';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useAtom } from 'jotai';
import { useSetAtom } from 'jotai/index';
import { memo, useEffect, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchHospitalNoticeList = async (params: ApiNoticeHospitalElFindSimpleParams) => {
  const response = await apiClient.v3.apiNoticeHospitalElFindSimple(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

interface PopupNoticePickerProps {
  hospitalNoticeID: string;
  isDisabled: boolean;
}

function PopupNoticePicker({ hospitalNoticeID, isDisabled }: PopupNoticePickerProps) {
  const { hospitalID } = useSelectedHospitalInfo();

  const [popupNoticeError, setPopupNoticeError] = useAtom(selectedPopupEditNoticeConnectErrorAtom);
  const setPopupCreateOrUpdateParams = useSetAtom(popupCreateOrUpdateParamState);

  const [noticeTitles, setNoticeTitles] = useState<string[]>([]);

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();

  const query: ApiNoticeHospitalElFindSimpleParams = {
    hospitalID,
    key: 'createdAt',
    way: -1,
    isRemoved: false,
  };

  const { data: noticeList } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiNoticeHospitalElFindSimple, query] as const,
    queryFn: ({ queryKey }) => fetchHospitalNoticeList(queryKey[1]),
  });

  const handleSelect = (index: number) => {
    const noticeID = noticeList[index]._id;
    if (noticeID !== undefined) {
      setPopupNoticeError(null);
      setPopupCreateOrUpdateParams((prev) => ({ ...prev, hospitalNoticeID: noticeID }));
    }
  };

  useEffect(() => {
    if (noticeList) {
      const titles = noticeList
        .map((notice) => notice.title)
        .filter((title) => title !== undefined);
      setNoticeTitles(titles);
    }
  }, [noticeList]);

  useEffect(() => {
    if (hospitalNoticeID && noticeList) {
      const index = noticeList.findIndex((notice) => notice._id === hospitalNoticeID);
      if (index >= 0) {
        setSelectedIndex(index);
      }
    }
  }, [hospitalNoticeID, noticeList]);

  return (
    <div className='my-10 flex min-h-40 w-full items-start gap-24 py-4'>
      <LabelText isRequired width={76} className='flex h-32 items-center'>
        공지사항 연결
      </LabelText>
      <div className='flex w-fit flex-grow flex-col gap-4'>
        <TextFieldSelectBoxDropdown
          options={noticeTitles}
          disabled={isDisabled}
          wrapperClassName='w-full'
          onSelect={handleSelect}
          placeholder='[자세히보기] 클릭하여 선택한 공지사항으로 이동할 수 있습니다.'
          selectedIndex={selectedIndex}
        />
        {!!popupNoticeError && <span className='text-Body10 text-red500'>{popupNoticeError}</span>}
      </div>
    </div>
  );
}

export default memo(PopupNoticePicker);
