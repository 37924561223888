import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  ApiChatroomElFindOneParams,
  UserNameAndType,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';

export const getChatRoomInfo = async (params: ApiChatroomElFindOneParams) => {
  const response = await apiClient.v3.apiChatroomElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const defaultChatRoomInfo = {
  usingTranslation: false,
  ikakaoInfo: undefined,
  languageID: undefined,
  users: [] as UserNameAndType[],
  counselors: [] as UserNameAndType[],
  isCounselInProgress: false,
  isLoading: false,
  roomType: null,
};

export const useChatRoomInfoOne = ({ chatRoomID }: { chatRoomID: string }) => {
  const { data = defaultChatRoomInfo, isLoading } = useQuery({
    queryKey: [QUERY_KEY.apiChatroomElFindOne, { chatRoomID }] as const,
    queryFn: ({ queryKey }) => getChatRoomInfo(queryKey[1]),
    enabled: !!chatRoomID,
  });

  // 기본값 설정
  const {
    usingTranslation: isUsingTranslation = false,
    counselors = [],
    users: participants = [],
    ikakaoInfo, //사용하는 곳들에서 undefined 체크를 하고 있음
    languageID,
    isCounselInProgress = false,
    roomType = null,
  } = data;

  return {
    isUsingTranslation,
    ikakaoInfo,
    languageID,
    participants,
    counselors,
    isCounselInProgress,
    isLoading,
    roomType,
  };
};
