import { Z_INDEXS } from 'afterdoc-saas-web/templates/CustomerChat/constants/layout';

export default function TranslateLanguageSettingBlock() {
  return (
    <div
      className={`absolute inset-0 flex items-center justify-center bg-black900 bg-opacity-20 z-${Z_INDEXS.ChattingBottomIsSettingTranslateLanguage}`}>
      <p className='text-center text-Body14 text-black700'>번역중입니다. 잠시만 기다려주세요.</p>
    </div>
  );
}
