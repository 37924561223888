import { CHANGED_RESERVATIONS_TABLE_HEADERS } from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ChangedReservationsList/constants/changed-reservations-table-headers';

export default function ChangedReservationsTableHeader() {
  return (
    <thead>
      <tr>
        {CHANGED_RESERVATIONS_TABLE_HEADERS.map((header) => (
          <th
            key={header}
            className='h-fit whitespace-nowrap border-white400 border-b px-20 py-10 text-left text-Header14 text-black700'>
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
}
