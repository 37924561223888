import type { VideoContentCategoryParentInfos } from '@apis/swaggers/swagger-docs';
import { useVideoContentCategories } from 'afterdoc-saas-web/shared/hooks/etc/use-video-content-categories';
import type { ContentLayer } from 'afterdoc-saas-web/templates/Content/containers/ContentPanel/constants/tab-items';
import { useSetAtom } from 'jotai';
import { type Dispatch, type SetStateAction, useEffect } from 'react';
import { contentPanelLayerState } from '../states/content-panel-layer-state';
import { focusedContentSubLayerState } from '../states/focused-content-sub-layer-state';

type UseContentListCategoriesParams = {
  filteredContentPanelItems: ContentLayer[];
  setCategories: Dispatch<SetStateAction<VideoContentCategoryParentInfos[]>>;
};

export const useContentPanelCategories = ({
  filteredContentPanelItems,
  setCategories,
}: UseContentListCategoriesParams) => {
  const setContentPanelLayer = useSetAtom(contentPanelLayerState);

  const setFocusedContentSubLayer = useSetAtom(focusedContentSubLayerState);
  const { categories } = useVideoContentCategories();

  useEffect(() => {
    if (categories && categories.length > 0) {
      setCategories(categories);
      setContentPanelLayer(filteredContentPanelItems);
      setFocusedContentSubLayer(filteredContentPanelItems[0].items[0]);
    }
  }, [
    categories,
    filteredContentPanelItems,
    setCategories,
    setContentPanelLayer,
    setFocusedContentSubLayer,
  ]);

  useEffect(() => {
    setContentPanelLayer(filteredContentPanelItems);
    setFocusedContentSubLayer(filteredContentPanelItems[0].items[0]);
  }, [filteredContentPanelItems, setContentPanelLayer, setFocusedContentSubLayer]);
};
