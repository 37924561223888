import { KAKAO_PROXY_URL } from '@config/url';
import type { ProgressBarProps } from 'afterdoc-design-system/components/Molecules/ProgressBar/ProgressBar';
import type { File } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { bytesToMB } from 'afterdoc-saas-web/shared/utils/bytesToMB';
import { neverCheck } from 'afterdoc-saas-web/shared/utils/never-check';
import { tokenAtom } from 'afterdoc-saas-web/states/token';
import { ROOM_TYPE_MAP } from 'afterdoc-saas-web/templates/CustomerChat/constants/ROOM_TYPE_MAP';
import { roomTypeSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import {
  chatFileDownloadHistoryAtom,
  chatFileProgressAtom,
  deleteFileProgressAtom,
  setFileProgressAtom,
} from '../../../states/chatFileProgress';
import { useChatMessageContext } from '../ChatMessageContext';
import { MESSAGE_TYPE_CODE } from '../constants/message';
import { RenderingDownloadIcon } from './components/RenderingDownloadIcon';

// TODO: electron에서의 removeListeners 함수 작업 후 removeAllListeners를 removeListeners 다시 변경
export default function FileDownloadContainer({ id, fileID }: { id: string; fileID: File }) {
  if (!fileID.aws || fileID.aws.length === 0) return null;

  const roomType = useAtomValue(roomTypeSelector);
  const chatFileDownloadHistory = useAtomValue(chatFileDownloadHistoryAtom);
  const chatFileProgress = useAtomValue(chatFileProgressAtom);
  const token = useAtomValue(tokenAtom);
  const updateFileProgress = useSetAtom(setFileProgressAtom);
  const deleteFileProgress = useSetAtom(deleteFileProgressAtom);

  const { type } = useChatMessageContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isMockingMessage = type === MESSAGE_TYPE_CODE.FILE_MOCKING;
  const { fileName, Location } = fileID.aws[0];

  const fileSize = fileID.aws[0]?.fileSize;
  const fileSizeMB = bytesToMB(fileSize);
  const fileProgress = chatFileProgress.get(id);
  const isComplete = chatFileDownloadHistory?.get(id)?.isComplete ?? false;
  const triggerCancel = fileProgress?.triggerCancel ?? false;
  const triggerReload = fileProgress?.triggerReload ?? false;

  /*
   * download
   */
  const fileNameRef = useRef<string | null>(null);
  const fileBlobRef = useRef<Blob | null>(null);
  const fileUrlRef = useRef<string | null>(null);
  const [status, setStatus] = useState<ProgressBarProps['status']>();
  const [progress, setProgress] = useState(0);
  const [isCanceled, setIsCanceled] = useState(false);
  const [filePath, setFilePath] = useState<string>();

  const startDownload = async (
    source: Blob | string,
    fileName: string,
    filters: { name: string; extensions: string[] }[],
  ) => {
    addEventListeners();
    if (typeof source === 'string') {
      fileUrlRef.current = source;
      fileBlobRef.current = null;
    } else {
      fileBlobRef.current = source;
      fileUrlRef.current = null;
    }

    fileNameRef.current = fileName;

    window.electron?.ipcRenderer.send('Application.startFileDownload', {
      title: 'Download File',
      fileName,
      filters,
    });
  };

  const downloadFileAfterSaveDialog = async () => {
    if (!fileBlobRef.current && !fileUrlRef.current) return;
    if (isLoading) return;
    if (roomType === null) throw new Error('Room type is null');

    setIsLoading(true);

    setIsCanceled(false);
    setStatus('downloading');
    setProgress(0);

    let offset = 0;
    const CHUNK_SIZE = 1024 * 1024;
    const sendChunk = async () => {
      if (isCanceled) return;

      if (!fileBlobRef.current && fileUrlRef.current) {
        switch (roomType) {
          case ROOM_TYPE_MAP.kakao: {
            const response = await fetch(KAKAO_PROXY_URL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: token,
              },
              body: JSON.stringify({
                url: fileUrlRef.current,
              }),
            });
            fileBlobRef.current = await response.blob();
            break;
          }
          case ROOM_TYPE_MAP.instagram:
          case ROOM_TYPE_MAP.manager:
          case ROOM_TYPE_MAP.afterdoc: {
            const response = await fetch(fileUrlRef.current, {
              headers: {
                Authorization: token,
              },
            });
            fileBlobRef.current = await response.blob();
            break;
          }
          default: {
            roomType satisfies never;
            neverCheck(roomType);
          }
        }
      }

      const blob = fileBlobRef.current;
      if (!blob) return;
      const totalSize = blob.size;

      if (offset >= totalSize) {
        window.electron?.ipcRenderer.send('Application.downloadFileEnd');
        return;
      }

      const chunk = blob.slice(offset, offset + CHUNK_SIZE);
      const reader = new FileReader();

      reader.onload = () => {
        if (isCanceled) return;

        const chunkData = reader.result;
        if (chunkData) {
          const progress = Math.floor((offset / totalSize) * 100);
          window.electron?.ipcRenderer.send('Application.downloadFileChunk', {
            chunk: chunkData,
            progress,
          });
          setProgress(progress);
          offset += CHUNK_SIZE;
          sendChunk();
        }
      };

      reader.onerror = () => {
        console.error('Error reading file chunk');
      };

      reader.readAsArrayBuffer(chunk);
    };

    sendChunk();
  };

  const listeners = {
    writeStreamCreated: () => {
      downloadFileAfterSaveDialog();
    },
    fileSaveDialogResult: (_: unknown, path?: string) => {
      setStatus(path ? 'complete' : 'error');
      setFilePath(path);
    },
    openFileLocatedFolderResult: (_: unknown, { success }: { success: boolean }) => {
      if (!success) {
        console.error('Error opening folder');
      }
    },
    cancelDownloadCompleted: (_: unknown, { success }: { success: boolean }) => {
      if (!success) {
        console.error('Error opening folder');
      }
    },
  };

  const cancelDownload = () => {
    setIsCanceled(true);
    setStatus('error');
    window.electron?.ipcRenderer.send('Application.cancelFileDownload');
  };
  const addEventListeners = () => {
    window.electron?.ipcRenderer.on('Application.writeStreamCreated', listeners.writeStreamCreated);
    window.electron?.ipcRenderer.on(
      'Application.fileSaveDialogResult',
      listeners.fileSaveDialogResult,
    );
    window.electron?.ipcRenderer.on(
      'Application.openFileLocatedFolderResult',
      listeners.openFileLocatedFolderResult,
    );
    window.electron?.ipcRenderer.on(
      'Application.cancelDownloadCompleted',
      listeners.cancelDownloadCompleted,
    );
  };
  const removeEventListeners = () => {
    window.electron?.ipcRenderer.removeAllListeners(
      'Application.writeStreamCreated',
      // listeners.writeStreamCreated,
    );
    window.electron?.ipcRenderer.removeAllListeners(
      'Application.fileSaveDialogResult',
      // listeners.fileSaveDialogResult,
    );
    window.electron?.ipcRenderer.removeAllListeners(
      'Application.openFileLocatedFolderResult',
      // listeners.openFileLocatedFolderResult,
    );
    window.electron?.ipcRenderer.removeAllListeners(
      'Application.cancelDownloadCompleted',
      // listeners.cancelDownloadCompleted,
    );
  };
  /* download end */

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (progress && isLoading === true) {
      updateFileProgress({ id, fileName, progress, filePath });
    }
  }, [progress, fileName, filePath]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (status === 'complete') {
      removeEventListeners();
      setIsLoading(false);
      updateFileProgress({ id, fileName, progress: 100, filePath });
    }
    if (status === 'error') {
      removeEventListeners();
      setIsLoading(false);
    }
  }, [status]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (triggerCancel) {
      cancelDownload();
      deleteFileProgress(id);
    }
  }, [triggerCancel, deleteFileProgress]);

  //Only Reload download
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (triggerReload) {
      startDownload(Location, fileName, [{ name: fileName, extensions: ['*'] }]);
    }
  }, [triggerReload]);

  const isExpired = fileID.expiredAt ? new Date(fileID.expiredAt) < new Date() : false;

  return (
    <div className='line-clamp-2 flex flex-row items-center justify-between'>
      <div className='flex items-center justify-start gap-10'>
        <div
          className={`box-border h-36 w-36 flex-center flex-shrink-0 rounded-r10 border border-white400 bg-white100 p-6 ${
            !isMockingMessage && !isExpired && 'cursor-pointer'
          }`}
          onClick={() =>
            !isMockingMessage &&
            !isExpired &&
            startDownload(Location, fileName, [{ name: fileName, extensions: ['*'] }])
          }>
          <RenderingDownloadIcon
            isComplete={isComplete}
            percentage={progress}
            isExpired={isExpired}
          />
        </div>
        <div className='text-Body13'>{fileName}</div>
      </div>
      <div className='text-Body12 text-black200'>{fileSizeMB ? `${fileSizeMB}MB` : null}</div>
    </div>
  );
}
