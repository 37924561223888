import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import type {
  TemporaryPatientsIsLockHandlerParams,
  TemporaryPatientsRequestLockHandlerBodyRequest,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { LOCK_TIMEOUT } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/constants/timeout';

const patchRequestLock = async (params: TemporaryPatientsRequestLockHandlerBodyRequest) => {
  const response = await apiClient.v3.temporaryPatientsRequestLockHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const getPatientsIsLocked = async (params: TemporaryPatientsIsLockHandlerParams) => {
  const response = await apiClient.v3.temporaryPatientsIsLockHandler(params);
  const { data } = response.data;

  // lastActiveTime이 있고 타임아웃이 지났다면 lock 해제
  if (data.updatedAt) {
    const lastActive = new Date(data.updatedAt);
    const now = new Date();
    const diffMinutes = (now.getTime() - lastActive.getTime()) / LOCK_TIMEOUT;

    if (diffMinutes > LOCK_TIMEOUT && data.isLock) {
      try {
        await patchRequestLock({
          hospitalID: params.hospitalID,
          isLock: false,
          version: data.version,
        });
        return { ...data, isLock: false };
      } catch (error) {
        console.error('자동 잠금 해제 중 오류 발생:', error);
        return data;
      }
    }
  }

  return data;
};

export const useTemporaryPatientsLock = () => {
  const patchRequestLockMutation = useMutation({
    mutationFn: patchRequestLock,
  });

  return {
    patchRequestLockMutation,
    getPatientsIsLocked,
  };
};
