import ChattingListBody from '@templates/CustomerChat/components/ChattingList/components/ChattingListBody/ChattingListBody';
import { displayNinetyNine } from '@templates/CustomerChat/components/ChattingList/functions/displayNinetyNine';
import { useChattingListContainer } from '@templates/CustomerChat/components/ChattingList/hooks/use-chatting-list-container';
import { useSearchInput } from '@templates/CustomerChat/components/ChattingList/hooks/use-search-input';
import { useSelectChatRoom } from '@templates/CustomerChat/components/ChattingList/hooks/use-select-chat-room';
import { useChatRoomRouting } from '@templates/CustomerChat/components/ChattingList/use-chat-room-routing';
import { literalArrayTypeToStringArray } from '@templates/CustomerChat/functions/literalArrayTypeToStringArray';
import { useResetCustomerChatAtomState } from '@templates/CustomerChat/hooks/use-reset-customer-chat-atom-state';
import { selectedChattingListTabState } from '@templates/CustomerChat/states/selected-chatting-list-tab';
import SearchInput from 'afterdoc-design-system/components/Atoms/Input/SearchInput';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import ButtonTab from 'afterdoc-design-system/components/Atoms/Tabs/components/ButtonTab';
import Tabs from 'afterdoc-design-system/components/Atoms/Tabs/components/Tabs';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Organisms/Dropdown/TextFieldSelectBoxDropdown/TextFieldSelectBoxDropdown';
import { useLocalStorageState } from 'afterdoc-saas-web/shared/hooks/etc/use-local-storage-state';
import type {
  RoomInfo,
  RoomType,
} from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtom, useSetAtom } from 'jotai/index';
import { useEffect } from 'react';
import { ChattingListNoData } from './components/ChattingListNoData/ChattingListNoData';
import {
  type TempChatRoomHServiceLastMessageUser,
  chattingListQueryParamsState,
} from './hooks/use-chatting-list';

export interface TempChatRoomHServiceLastMessageUsers extends TempChatRoomHServiceLastMessageUser {
  roomType: RoomType;
  ikakaoInfo: RoomInfo;
}

export const SORT_OPTIONS = ['최신 순', '안 읽은 순'] as const;

export default function ChattingList() {
  const { inputValue, keyword, handleChange, handleKeyDown, handleDelete } = useSearchInput();
  const { chatRooms, tabItemsObject, infiniteQueryControls } = useChattingListContainer();

  const [selectedTab, setSelectedTab] = useAtom(selectedChattingListTabState);
  const { resetSelectedChatRoom, resetChatListAll } = useResetCustomerChatAtomState();

  const { handleSelectChatRoom, selectedChatRoomID } = useSelectChatRoom({ selectedTab });

  const setChattingListQueryParams = useSetAtom(chattingListQueryParamsState);

  const [selectedSortsDropdownIndex, setSelectedSortsDropdownIndex] = useLocalStorageState(
    'sortedIndex',
    0,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setChattingListQueryParams({
      keyword,
      sortOption: SORT_OPTIONS[selectedSortsDropdownIndex],
    });
  }, [keyword, selectedSortsDropdownIndex]);

  useChatRoomRouting({ handleSelectChatRoom });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    handleDelete();
    resetSelectedChatRoom();
  }, [selectedTab]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    return () => {
      resetChatListAll();
    };
  }, []);

  return (
    <div className='flex h-full flex-col pb-10'>
      <div className='flex h-49 flex-row justify-between px-20 pt-20 pb-8'>
        <span className='text-Header16 text-black700'>고객채팅</span>
        <TextFieldSelectBoxDropdown
          options={literalArrayTypeToStringArray(SORT_OPTIONS)}
          selectedIndex={selectedSortsDropdownIndex}
          defaultValue={SORT_OPTIONS[selectedSortsDropdownIndex]}
          onSelect={setSelectedSortsDropdownIndex}
          inputClassName='border-none text-Body11 text-black200 text-end'
          width={100}
          dropdownWidth={115}
          dropdownWrapperClassName='right-0'
        />
      </div>
      <div>
        <Tabs value={selectedTab} onChange={setSelectedTab}>
          {Object.entries(tabItemsObject).map(([key, { isBadge, badgeCount }]) => (
            <ButtonTab
              className='border-b border-b-white400'
              hasBadge={isBadge}
              value={key}
              label={key}
              key={key}
              subText={displayNinetyNine(badgeCount)}>
              {key}
            </ButtonTab>
          ))}
        </Tabs>
        <div className='p-10'>
          <SearchInput
            placeholder='고객명으로 검색'
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onDelete={handleDelete}
          />
        </div>
        {/* 전체 height 최소 750px 를 맞춰주기 위한 스타일링 */}
        <div className='h-[calc(100vh-150px)] min-h-[600px]'>
          <div className='relative h-full w-full'>
            {chatRooms.length === 0 && !infiniteQueryControls.isLoading && (
              <ChattingListNoData keyword={keyword} />
            )}

            {chatRooms.length > 0 && (
              <ChattingListBody
                chatRooms={chatRooms}
                selectedChatRoomID={selectedChatRoomID}
                infiniteQueryControls={infiniteQueryControls}
                onHandleSelectChatRoom={handleSelectChatRoom}
              />
            )}
            {(infiniteQueryControls.isLoading || infiniteQueryControls.isFetchingNextPage) && (
              <div className='absolute inset-10 flex-center bg-white50 opacity-40'>
                <BaseLoading />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
