import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  ChatRoomsLatestConsultationGuideHandlerParams,
  ChatRoomsLatestConsultationGuideHandlerResponse,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { chatRoomHserviceIDSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtomValue } from 'jotai';

export type ResConsultationGuide = ChatRoomsLatestConsultationGuideHandlerResponse['data'];

export const getConsultationGuide = async (
  query: ChatRoomsLatestConsultationGuideHandlerParams,
) => {
  const { data } = await apiClient.v3.chatRoomsLatestConsultationGuideHandler(query);
  return SHARED_UTILS.api.checkApiResponse<ResConsultationGuide>(data);
};

export const useGetAlgorithmGuide = () => {
  const { hospitalID } = useSelectedHospitalInfo();

  const hserviceID = useAtomValue(chatRoomHserviceIDSelector);

  const { data, error, isLoading } = useQuery({
    queryKey: [
      QUERY_KEY.chatRoomsLatestConsultationGuideHandler,
      { hospitalID, hserviceID },
    ] as const,
    queryFn: ({ queryKey }) => getConsultationGuide(queryKey[1]),
    enabled: !!hserviceID,
  });

  return { data, error, isLoading };
};
