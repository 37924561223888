import type { ApiPatientsElTemporaryListOrSearchParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { atomWithReset } from 'jotai/utils';

export interface ReadOnlyHeaderFilterState {
  isFirstVisit: ApiPatientsElTemporaryListOrSearchParams['isFirstVisit'] | 'all';
  gender: ApiPatientsElTemporaryListOrSearchParams['gender'] | 'all';
  treatmentCategories: ApiPatientsElTemporaryListOrSearchParams['treatmentCategories'];
  nationality: ApiPatientsElTemporaryListOrSearchParams['nationality'];
}

export const readOnlyHeaderFilterState = atomWithReset<ReadOnlyHeaderFilterState>({
  isFirstVisit: undefined,
  // countryCode: undefined,
  gender: undefined,
  treatmentCategories: undefined,
  nationality: undefined,
});
