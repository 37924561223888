import { replaceTemplateVariables } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/components/PhoneView/functions/replace-template-variables';

interface DisplayTextProps {
  text?: string;
}

export default function DisplayText({ text }: DisplayTextProps) {
  return (
    <div className='flex gap-8 rounded-[12px] bg-white50 px-12 py-8'>
      <span className='whitespace-pre-wrap break-all text-Body12 text-black700'>
        {replaceTemplateVariables(text) || '내용을 입력해 주세요.'}
      </span>
    </div>
  );
}
