import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import type { ApprovalChatRoomUserInfo } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { Z_INDEXS } from 'afterdoc-saas-web/templates/CustomerChat/constants/layout';
import { updateApprovalChatRoom } from 'afterdoc-saas-web/templates/ServiceNoticeDialog/components/ServiceNoticeList/components/ChatRoomJoinRequestCard';
import { useEffect, useState } from 'react';
import { useApprovalChatroomPushHandler } from './hooks/use-approval-chat-room-push-handler';
import { useGetApprovalChatroom } from './hooks/use-get-approval-chatroom';

interface TempApprovalChatRoomUserInfo extends ApprovalChatRoomUserInfo {
  _id: string;
}

export default function ParticipantsRequest({ chatRoomID }: { chatRoomID: string }) {
  const { approvalChatroom } = useGetApprovalChatroom({ chatRoomID });

  const [currentIndex, setCurrentIndex] = useState(0);
  const currentChatroom = approvalChatroom?.[currentIndex] as TempApprovalChatRoomUserInfo;
  const approvalChatRoomID = currentChatroom?._id;

  useEffect(() => {
    if (approvalChatroom?.length) {
      setCurrentIndex(0);
    }
  }, [chatRoomID, approvalChatroom]);

  useApprovalChatroomPushHandler();

  // 요청 승인/미승인 후 인덱스를 업데이트하는 함수
  const handleApproval = async (isApproved: boolean) => {
    try {
      await updateApprovalChatRoom({ approvalChatRoomID, isApproved });

      if (currentIndex < approvalChatroom.length - 1) {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        setCurrentIndex(-1); // 마지막 항목 처리 후 초기화
      }
    } catch {
      toastService.errorMsg({
        text: `채팅 참여 ${isApproved ? '승인' : '미승인'}을 실패했습니다.`,
      });
    }
  };

  const handleApprove = () => handleApproval(true);
  const handleReject = () => handleApproval(false);

  if (!approvalChatroom || !currentChatroom) return null;

  return (
    <div
      className={`absolute top-6 left-1/2 w-fit rounded-r10 bg-blue100 ${
        approvalChatroom.length - 1 !== currentIndex ? 'shadow-xl' : ''
      }`}
      style={{
        zIndex: Z_INDEXS.ChattingTooltip,
        transform: 'translateX(-50%)', // 수평 중앙 정렬
      }}>
      <div className='mx-16 my-12 flex flex-center gap-10'>
        <div className='flex-grow text-Body12 text-blue700'>
          {currentChatroom?.requestorID?.realName}님이 채팅 참여를 요청했습니다.
        </div>
        <div className='flex flex-shrink-0 gap-4'>
          <ContainedButton buttonSize='small' btnColor='secondary' onClick={handleReject}>
            미승인
          </ContainedButton>
          <ContainedButton buttonSize='small' onClick={handleApprove}>
            승인
          </ContainedButton>
        </div>
      </div>
    </div>
  );
}
