import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import FullLoading from 'afterdoc-saas-web/shared/components/FullLoading/FullLoading';
import { usePatient } from 'afterdoc-saas-web/templates/CustomerChat/hooks/use-patient';
import { chatRoomIDSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { handleScollByParentChildIndex } from '../../functions/use-handle-scroll-by-parent-child-index';
import '../../styles/ManualContainer.scss';
import ManualPanelFolder from './components/ManualPanelFolder';
import ManualPanelFolderItem from './components/ManualPanelFolderItem/ManualPanelFolderItem';
import { useManusFolders } from './hooks/use-manus-folders';

export type ManualHandleClickParams = {
  id: string;
  parentIndex: number;
  childIndex?: number;
};

export default function ManualList({ keyword }: { keyword: string }) {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const [focusedManualTagID, setFocusedManualTagID] = useState<string | null>(null);
  const manualListContainerRef = useRef<HTMLDivElement>(null);

  const { treatmentTags, automationAppliedTreatmentTag } = usePatient();
  const automationTreatmentTagId = automationAppliedTreatmentTag?.tagId ?? undefined;
  const treatmentTagsIds = [
    ...(automationTreatmentTagId ? [automationTreatmentTagId] : []),
    ...(treatmentTags ?? []).map((tag) => tag.tagId),
  ];
  const { data, folderOpenMap, settingTagPanelOpenMap, firstOpenIndex, isLoading } =
    useManusFolders({
      keyword,
      chatRoomID,
    });
  const [tagPanelOpenMap, setTagPanelOpenMap] = useState(() => new Map<string, boolean>());

  useEffect(() => {
    setTagPanelOpenMap(settingTagPanelOpenMap);
  }, [settingTagPanelOpenMap, chatRoomID]);

  useEffect(() => {
    setFocusedManualTagID(null);
  }, [chatRoomID]);

  useEffect(() => {
    if (firstOpenIndex >= 0) {
      handleScollByParentChildIndex({
        container: manualListContainerRef.current,
        parentIndex: firstOpenIndex,
      });
    }
  }, [firstOpenIndex, manualListContainerRef.current]);

  const handleClick = ({ id, parentIndex, childIndex }: ManualHandleClickParams) => {
    handleScollByParentChildIndex({
      container: manualListContainerRef.current,
      parentIndex,
      childIndex,
    });

    setFocusedManualTagID(id);

    setTagPanelOpenMap((prevMap) => {
      const newTagPanelOpenMap = new Map(prevMap);

      for (const [key] of newTagPanelOpenMap) {
        if (key === id) {
          newTagPanelOpenMap.set(id, !prevMap.get(id));
        } else {
          newTagPanelOpenMap.set(key, false);
        }
      }

      return newTagPanelOpenMap;
    });
  };

  if (isLoading) return <FullLoading />;

  return (
    <>
      {data.length === 0 ? (
        <NoData
          title={keyword ? '검색 결과가 없습니다.' : '표시할 내용이 없습니다.'}
          className='h-full flex-center'
          subTitle={
            keyword
              ? '단어의 철자가 정확한지 확인해 주세요.'
              : '[원내매뉴얼]에서 표시할 내용을 설정할 수 있습니다.'
          }
          iconProps={{
            name: 'warning',
            size: 48,
            color: 'white600',
          }}
        />
      ) : (
        // 드래그를 해야하기에 className='select-none' 금지
        <div id='manual-container' ref={manualListContainerRef}>
          {data.map((parentItem, index) => {
            const parentIndex = index;
            const isOpen = folderOpenMap.get(parentItem.id) ?? false;
            const isChildOpen = tagPanelOpenMap.get(parentItem.id) ?? false;
            const { items } = parentItem;

            return (
              <ManualPanelFolder
                key={parentItem.title}
                parentIndex={parentIndex}
                id={parentItem.id}
                isOpen={isOpen}
                isChildOpen={isChildOpen}
                handleClick={handleClick}
                parentItem={parentItem}
                focusedManualTagID={focusedManualTagID}
                treatmentTagsIds={treatmentTagsIds}>
                {items.map((childItem, index) => {
                  const childIndex = index;

                  const { key: childID, color, text } = childItem;

                  const isItemOpen = tagPanelOpenMap.get(childID) ?? false;

                  return (
                    <ManualPanelFolderItem
                      key={childID}
                      parentIndex={parentIndex}
                      childIndex={childIndex}
                      childID={childID}
                      color={color}
                      text={text}
                      treatmentTagsIds={treatmentTagsIds}
                      focusedManualTagID={focusedManualTagID}
                      handleClick={handleClick}
                      isItemOpen={isItemOpen}
                    />
                  );
                })}
              </ManualPanelFolder>
            );
          })}
        </div>
      )}
    </>
  );
}
