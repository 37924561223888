import {
  Automation,
  Chat,
  Contents,
  Db,
  Manual,
  Setting,
} from 'afterdoc-saas-web/assets/icons/gnb';
import type { ReactNode } from 'react';

export type AuthorizationGroupKey =
  | 'patientConsultation'
  | 'patientManagement'
  | 'reservationManagement'
  | 'automation'
  | 'manualManagement'
  | 'content'
  | 'hospitalSettings';

export interface AuthorizationItemType {
  icon: ReactNode;
  key: AuthorizationGroupKey;
}

type ItemTypes = {
  [key: string]: AuthorizationItemType;
};

export const AUTHORIZATION_TYPES: ItemTypes = {
  customerConsultation: {
    icon: <Chat width={24} height={24} />,
    key: 'patientConsultation',
  },
  customerManagement: {
    icon: <Db width={24} height={24} />,
    key: 'patientManagement',
  },
  automation: {
    icon: <Automation width={24} height={24} />,
    key: 'automation',
  },
  manualManagement: {
    icon: <Manual width={24} height={24} />,
    key: 'manualManagement',
  },
  content: {
    icon: <Contents width={24} height={24} />,
    key: 'content',
  },
  hospitalSettings: {
    icon: <Setting width={24} height={24} />,
    key: 'hospitalSettings',
  },
};
