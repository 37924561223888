import { customerTableDisplayModeState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-display-mode';
import { editableHeaderFilterState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/editable-header-filter';
import { searchTextState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/search';
import { selectedSearchCriteriaState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import {
  focusedCellState,
  selectedCellState,
} from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';
import { selectedCardInfoState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/ControlPanel/containers/OngoingConsultingAutomation/state/selected-card-info';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';

export const useResetTableJotaiStates = () => {
  const resetSelectedCell = useResetAtom(selectedCellState);
  const resetFocusedCell = useResetAtom(focusedCellState);
  const resetSearchText = useResetAtom(searchTextState);
  const resetEditableHeaderFilter = useResetAtom(editableHeaderFilterState);
  const resetSelectedSearchCriteria = useResetAtom(selectedSearchCriteriaState);
  const resetSelectedCardInfo = useResetAtom(selectedCardInfoState);

  const [customerTableDisplayMode, setCustomerTableDisplayMode] = useAtom(
    customerTableDisplayModeState,
  );

  const handleChangeDisplayMode = useCallback(
    (mode: Parameters<typeof setCustomerTableDisplayMode>[0]) => {
      setCustomerTableDisplayMode(mode ?? 'VIEWER');
    },
    [setCustomerTableDisplayMode],
  );

  const resetTableJotaiStates = useCallback(
    (mode: Parameters<typeof handleChangeDisplayMode>[0]) => {
      // 테이블 모드 변경
      if (customerTableDisplayMode !== mode) {
        handleChangeDisplayMode(mode);
      }

      // 셀 선택 상태 초기화
      resetSelectedCell();
      resetFocusedCell();

      // 검색어 초기화
      resetSearchText();

      // 필터 초기화
      resetEditableHeaderFilter();

      // 카드 정보 초기화
      resetSelectedSearchCriteria();
      resetSelectedCardInfo();
    },
    [
      resetSelectedCell,
      resetFocusedCell,
      resetSearchText,
      resetEditableHeaderFilter,
      customerTableDisplayMode,
      handleChangeDisplayMode,
      resetSelectedSearchCriteria,
      resetSelectedCardInfo,
    ],
  );

  return {
    resetTableJotaiStates,
  };
};
