import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { handleInstagramLogin } from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/InstagramForm/components/InstagramIntegrationWrapper/functions/handleInstagramLogin';

export default function AddInstagramAccount() {
  const { hospitalID } = useSelectedHospitalInfo();

  return (
    <span
      className='cursor-pointer text-Body14 text-blue500'
      onClick={() => handleInstagramLogin(hospitalID)}>
      계정 추가 및 재연동
    </span>
  );
}
