import type { ElFindLinkedChatroomsHandlerData } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { displayTextContent } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingList/functions/displayTextContent';
import { USER_TYPE_CODE } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/constants/message';
import RoomTypeIconState from 'afterdoc-saas-web/templates/CustomerChat/components/RoomTypeIconState/RoomTypeIconState';
import { useIsDisabledChat } from 'afterdoc-saas-web/templates/CustomerChat/hooks/use-is-disabled-chat';
import type { OnMoveChatClickParams } from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/LinkageChannels/LinkedChannelChatRooms.container';
import { linkedChannelChatRoomNameDisplay } from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/LinkageChannels/components/LinkedChannelChatRoom/functions/linked-channel-chat-room-name-display';
import dayjs from 'dayjs';
import { useMemo } from 'react';

type LinkedChannelChatRoomProps = { linkedChannel: ElFindLinkedChatroomsHandlerData['data'][0] } & {
  onMoveChatClick: (params: OnMoveChatClickParams) => void;
};

export default function LinkedChannelChatRoom({
  linkedChannel,
  onMoveChatClick,
}: LinkedChannelChatRoomProps) {
  const {
    _id,
    roomType,
    isCounselInProgress,
    lastMessage,
    lastMessageUpdatedAt,
    isParticipating,
    counselors,
    channelName,
  } = linkedChannel;

  const { isUsageHaltedDisabledSending } = useIsDisabledChat({ chatRoomID: _id });

  const { isTranslated, content, translatedContent, type, userType } = lastMessage;
  const isLeft = userType === USER_TYPE_CODE.PATIENT; // 1000: 환자

  const previewMessage = useMemo(() => {
    const textContent = isLeft || !isTranslated ? content : translatedContent;

    const displayContent = displayTextContent(type);

    return displayContent || textContent || '';
  }, [isLeft, isTranslated, translatedContent, content, type]);

  const formatedUpdatedAt = useMemo(() => {
    const updatedAt = dayjs(lastMessageUpdatedAt);
    const isToday = updatedAt.isSame(dayjs(), 'day');
    return isToday ? updatedAt.format('HH:mm') : updatedAt.format('YYYY-MM-DD');
  }, [lastMessageUpdatedAt]);

  const isClosedChatRoom = !isCounselInProgress;

  const isDisabledChat = isUsageHaltedDisabledSending ?? false;

  return (
    <div
      className={`flex cursor-pointer flex-col gap-8 overflow-hidden px-13 py-12 ${isDisabledChat ? 'bg-disabled' : 'bg-white50 hover:bg-blueLight'}`}
      onClick={() => onMoveChatClick({ chatRoomID: _id, isParticipating, counselors })}>
      <div className='flex-row-center justify-between'>
        <div className='flex-row-center gap-8'>
          <div>{RoomTypeIconState({ roomType, isUsageHaltedDisabledSending })}</div>
          <h2 className='line-clamp-1 max-w-[200px] text-Header14 '>
            {linkedChannelChatRoomNameDisplay({ roomType, channelName: channelName ?? '채널명' })}
          </h2>
          {isClosedChatRoom && (
            <div className='rounded-md bg-white100 px-8 text-center text-Body12 text-black500'>
              상담종료
            </div>
          )}
        </div>
        {isParticipating && <div className='text-Body10Bold text-blue500'>참여 중(나)</div>}
      </div>
      <div className='flex h-35 flex-row justify-between'>
        <span className='line-clamp-2 text-Body12 text-black500'>{previewMessage}</span>
        <span className='flex content-end items-end text-Caption9 text-black200'>
          {formatedUpdatedAt}
        </span>
      </div>
    </div>
  );
}
