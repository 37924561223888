import type { SVGProps } from "react";
const SvgInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="url(#instagram_svg__a)"
      d="M8.004 0c-3.34 0-4.317.003-4.507.02C2.81.075 2.385.183 1.92.415 1.562.593 1.28.8 1 1.089q-.76.79-.926 1.946c-.054.374-.07.45-.073 2.361-.002.637 0 1.476 0 2.6 0 3.338.003 4.314.02 4.504.055.667.16 1.086.38 1.546a3.37 3.37 0 0 0 2.185 1.784c.33.085.695.132 1.162.154.198.009 2.218.015 4.239.015s4.042-.003 4.235-.012c.541-.026.856-.068 1.204-.158a3.35 3.35 0 0 0 2.184-1.788c.217-.448.328-.884.377-1.516.011-.138.016-2.336.016-4.53 0-2.196-.005-4.39-.016-4.528-.05-.642-.16-1.074-.385-1.53a3.2 3.2 0 0 0-.685-.94c-.53-.505-1.176-.812-1.948-.924-.374-.054-.448-.07-2.36-.073z"
    />
    <path
      fill="url(#instagram_svg__b)"
      d="M8.004 0c-3.34 0-4.317.003-4.507.02C2.81.075 2.385.183 1.92.415 1.562.593 1.28.8 1 1.089q-.76.79-.926 1.946c-.054.374-.07.45-.073 2.361-.002.637 0 1.476 0 2.6 0 3.338.003 4.314.02 4.504.055.667.16 1.086.38 1.546a3.37 3.37 0 0 0 2.185 1.784c.33.085.695.132 1.162.154.198.009 2.218.015 4.239.015s4.042-.003 4.235-.012c.541-.026.856-.068 1.204-.158a3.35 3.35 0 0 0 2.184-1.788c.217-.448.328-.884.377-1.516.011-.138.016-2.336.016-4.53 0-2.196-.005-4.39-.016-4.528-.05-.642-.16-1.074-.385-1.53a3.2 3.2 0 0 0-.685-.94c-.53-.505-1.176-.812-1.948-.924-.374-.054-.448-.07-2.36-.073z"
    />
    <path
      fill="#fff"
      d="M8 2.092c-1.604 0-1.805.007-2.435.036-.629.029-1.058.128-1.434.274-.388.151-.718.353-1.046.682a2.9 2.9 0 0 0-.682 1.046c-.147.376-.246.805-.275 1.434-.028.63-.035.832-.035 2.436s.007 1.805.035 2.435c.03.63.129 1.059.275 1.434.15.389.353.719.681 1.047.329.329.658.53 1.046.682.376.146.806.245 1.434.274.63.029.832.036 2.436.036s1.805-.007 2.435-.036 1.06-.128 1.435-.274a2.9 2.9 0 0 0 1.046-.682 2.9 2.9 0 0 0 .682-1.047c.145-.375.245-.805.274-1.433.028-.63.036-.831.036-2.436s-.008-1.806-.036-2.436c-.03-.629-.13-1.058-.274-1.434a2.9 2.9 0 0 0-.682-1.046 2.9 2.9 0 0 0-1.046-.682c-.377-.146-.806-.245-1.435-.274-.63-.029-.831-.036-2.436-.036zm-.53 1.065H8c1.578 0 1.765.006 2.388.034.576.026.889.122 1.097.203.276.107.472.235.679.442s.335.404.442.68c.08.208.177.52.203 1.096.029.623.035.81.035 2.387s-.006 1.763-.035 2.386c-.026.576-.122.889-.203 1.097a1.8 1.8 0 0 1-.442.679 1.8 1.8 0 0 1-.68.441c-.207.082-.52.178-1.096.204-.623.028-.81.034-2.387.034-1.578 0-1.765-.006-2.387-.034-.576-.027-.889-.123-1.097-.204a1.8 1.8 0 0 1-.68-.442 1.8 1.8 0 0 1-.442-.679c-.08-.208-.177-.52-.203-1.096-.028-.623-.034-.81-.034-2.388s.006-1.763.034-2.386c.026-.576.122-.889.203-1.097a1.8 1.8 0 0 1 .442-.68c.207-.206.404-.334.68-.442.208-.08.52-.177 1.097-.203.545-.025.756-.032 1.857-.034zm3.684.98a.709.709 0 1 0 0 1.419.709.709 0 0 0 0-1.418m-3.153.83a3.034 3.034 0 1 0 0 6.067 3.034 3.034 0 0 0 0-6.068M8 6.03a1.97 1.97 0 1 1 0 3.938 1.97 1.97 0 0 1 0-3.938"
    />
    <defs>
      <radialGradient
        id="instagram_svg__a"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(0 -15.8561 14.751 0 4.25 17.231)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD5" />
        <stop offset={0.1} stopColor="#FD5" />
        <stop offset={0.5} stopColor="#FF543E" />
        <stop offset={1} stopColor="#C837AB" />
      </radialGradient>
      <radialGradient
        id="instagram_svg__b"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(1.3915 6.94987 -28.654 5.73708 -2.68 1.153)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3771C8" />
        <stop offset={0.128} stopColor="#3771C8" />
        <stop offset={1} stopColor="#60F" stopOpacity={0} />
      </radialGradient>
    </defs>
  </svg>
);
export default SvgInstagram;
