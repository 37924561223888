import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { CreateUpdateHomeCareParams } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/types/create-update-homecare-params';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { SHARED_UTILS } from 'utils/utils';

type CreateHomeCareParam = Parameters<typeof apiClient.v3.apiEventHospitalAftercareElCreate>[0];

type UpdateHomeCareParam = Parameters<typeof apiClient.v3.apiEventHospitalAftercareElUpdate>[0];

type RemoveHomeCareParam = Parameters<typeof apiClient.v3.apiEventHospitalAftercareElRemove>[0];

const createHospitalHomeCare = async (params: CreateHomeCareParam) => {
  const { data } = await apiClient.v3.apiEventHospitalAftercareElCreate(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const updateHospitalHomeCare = async (params: UpdateHomeCareParam) => {
  const { data } = await apiClient.v3.apiEventHospitalAftercareElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const removeHospitalHomeCare = async (params: RemoveHomeCareParam) => {
  const { data } = await apiClient.v3.apiEventHospitalAftercareElRemove(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const showDeleteConfirmation = (onConfirm: () => void) => {
  modalService.defaultWarning({
    title: '홈케어 상품을 삭제할까요?',
    contents: '삭제한 홈케어 상품은 복구할 수 없습니다.',
    onConfirm,
  });
};

export const useHospitalHomeCare = () => {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();

  const createHomeCareMutation = useMutation({
    mutationFn: (params: CreateUpdateHomeCareParams) => {
      const { _id, saleType, saleRate, salePrice, price, ...otherParams } = params;

      const filteredParams = {
        ...otherParams,
        saleType,
        saleRate: saleType === 'price' ? undefined : saleRate,
        salePrice: saleType === 'rate' ? undefined : salePrice,
        price: price ?? 0,
      };

      return createHospitalHomeCare({
        ...filteredParams,
        hospitalID,
      });
    },
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiEventHospitalAftercareElCount] });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiEventHospitalAftercareElFindSimple],
      });
      toastService.successMsg({ text: '홈케어 상품을 등록했습니다.' });
    },
    onError: (error: Error) => {
      console.error('Error creating hospital homeCare', error);
      toastService.errorMsg({ text: '홈케어 상품 등록을 실패했습니다.' });
    },
  });

  const createHomeCareAction = (
    params: CreateUpdateHomeCareParams,
    onSuccessCallback?: (id: string) => void,
    onFailureCallback?: () => void,
  ) => {
    createHomeCareMutation.mutate(params, {
      onSuccess: (data) => onSuccessCallback?.(data._id ?? ''),
      onError: () => onFailureCallback?.(),
    });
  };

  const updateHomeCareMutation = useMutation({
    mutationFn: (params: CreateUpdateHomeCareParams) =>
      updateHospitalHomeCare({ ...params, price: params.price ?? 0, hospitalID }),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiEventHospitalAftercareElFindSimple],
      });
      toastService.successMsg({ text: '홈케어 상품을 수정했습니다.' });
    },
    onError: (error: Error) => {
      console.error('Error creating hospital homeCare', error);
      toastService.errorMsg({ text: '홈케어 상품 수정을 실패했습니다.' });
    },
  });

  const updateHomeCareAction = (
    params: CreateUpdateHomeCareParams,
    onSuccessCallback?: () => void,
    onFailureCallback?: () => void,
  ) => {
    const { saleRate, salePrice, saleType, type, fileIDs, content } = params;

    const filteredParams = {
      ...params,
      fileIDs: type === 'text' ? undefined : fileIDs,
      content: type === 'image' ? undefined : content,
      saleRate: saleType === 'price' ? undefined : saleRate,
      salePrice: saleType === 'rate' ? undefined : salePrice,
    };

    updateHomeCareMutation.mutate(filteredParams, {
      onSuccess: () => onSuccessCallback?.(),
      onError: () => onFailureCallback?.(),
    });
  };

  const removeHomeCareMutation = useMutation({
    mutationFn: removeHospitalHomeCare,
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiEventHospitalAftercareElFindSimple],
      });
      modalService.pop();
      toastService.successMsg({ text: '홈케어 상품을 삭제했습니다.' });
    },
    onError: (error: Error) => {
      console.error('Error removing hospital homeCare', error);
      modalService.pop();
      toastService.errorMsg({ text: '홈케어 상품 삭제를 실패했습니다.' });
    },
  });

  const removeHomeCareAction = (id: string, onSuccessCallback?: () => void) => {
    showDeleteConfirmation(() => {
      removeHomeCareMutation.mutate(
        { _id: id },
        {
          onSuccess: () => {
            if (onSuccessCallback) onSuccessCallback();
          },
        },
      );
    });
  };

  return {
    createHomeCareAction,
    updateHomeCareAction,
    removeHomeCareAction,
  };
};
