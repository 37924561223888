import {
  COMMON_REPLACEABLE_TEXTS,
  MAX_LENGTH,
} from 'afterdoc-saas-web/templates/Automation/containers/Dialog/EditCommonAutomationDialog/constants/replaceable-texts';
import {
  calculateReplacedLength,
  removeSpecificReplaceableText,
  truncateTextBeforeMaxLength,
} from 'afterdoc-saas-web/templates/Automation/containers/shared/functions/calculate-text';
import { useCallback } from 'react';

interface UseTextAreaWithMaxLengthProps {
  setActualTextLength: (value: number) => void;
}
export function useTextAreaWithMaxLength({ setActualTextLength }: UseTextAreaWithMaxLengthProps) {
  const handleTextAreaChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = e.target.value;
      const prevValue = e.target.defaultValue;

      const newActualLength =
        removeSpecificReplaceableText(newValue, COMMON_REPLACEABLE_TEXTS).length +
        calculateReplacedLength(newValue, COMMON_REPLACEABLE_TEXTS);

      const isAdding = newValue.length > prevValue.length;

      if (isAdding) {
        if (newActualLength <= MAX_LENGTH) {
          setActualTextLength(newActualLength);
        } else {
          const truncatedValue = truncateTextBeforeMaxLength(
            newValue,
            COMMON_REPLACEABLE_TEXTS,
            MAX_LENGTH,
          );
          const truncatedActualLength =
            removeSpecificReplaceableText(truncatedValue, COMMON_REPLACEABLE_TEXTS).length +
            calculateReplacedLength(truncatedValue, COMMON_REPLACEABLE_TEXTS);

          setActualTextLength(truncatedActualLength);
          e.target.value = truncatedValue;
        }
      } else {
        setActualTextLength(newActualLength);
      }
    },
    [setActualTextLength],
  );

  return {
    handleTextAreaChange,
  };
}
