import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  ApiVideocontenthospitalElDetailParams,
  FilePaths,
  VideoContentExerciseSetFilePaths,
  VideoContentHospitalThumbnails,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import VideoPlayer, {
  VIDEO_PLAYER_DIALOG_ID,
} from 'afterdoc-saas-web/shared/components/VideoPlayer/VideoPlayer';
import ExerciseSetInfo from 'afterdoc-saas-web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/components/WorkoutVideoContent/components/WorkoutVideoDetailContentDialog/components/ExerciseSetInfo';
import StimulateMusclesInfo from 'afterdoc-saas-web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/components/WorkoutVideoContent/components/WorkoutVideoDetailContentDialog/components/StimulateMusclesInfo';
import VideoContentSection from 'afterdoc-saas-web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/components/WorkoutVideoContent/components/WorkoutVideoDetailContentDialog/components/VideoContentSection';
import { CONTENT_SECTIONS } from 'afterdoc-saas-web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/components/WorkoutVideoContent/components/WorkoutVideoDetailContentDialog/constants/content-sections';
import { useEffect } from 'react';

const getChildrenContent = (
  content: VideoContentHospitalThumbnails,
  fileKey: keyof VideoContentHospitalThumbnails | undefined,
) => {
  if (!fileKey || !content[fileKey]) {
    return null;
  }

  switch (fileKey) {
    case 'exerciseSet':
      return (
        <ExerciseSetInfo exerciseSets={content[fileKey] as VideoContentExerciseSetFilePaths[]} />
      );
    case 'stimulateMusclesImageID':
      return <StimulateMusclesInfo filePaths={(content[fileKey] as FilePaths).paths ?? []} />;
    default:
      return null;
  }
};

const fetchVideoContentDetail = async (params: ApiVideocontenthospitalElDetailParams) => {
  const response = await apiClient.v3.apiVideocontenthospitalElDetail(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

interface WorkoutVideoDetailContentDialogProps {
  videoContentHospitalID: string;
}

export const WORKOUT_VIDEO_DETAIL_CONTENT_DIALOG_ID = 'workout-video-detail-content-dialog';

export default function WorkoutVideoDetailContentDialog({
  videoContentHospitalID,
}: WorkoutVideoDetailContentDialogProps) {
  const { data: videoContent } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiVideocontenthospitalElDetail,
      {
        vchID: videoContentHospitalID,
      },
    ] as const,
    queryFn: ({ queryKey }) => fetchVideoContentDetail(queryKey[1]),
  });

  const handleClick = () => {
    if (!videoContentHospitalID) return;

    dialogService.push(<VideoPlayer vchID={videoContentHospitalID} />, {
      id: VIDEO_PLAYER_DIALOG_ID,
      hasCloseButton: true,
      titleProps: {
        title: videoContent.title ?? '',
      },
      wrapperClassName: 'h-[590px] !rounded-bl-none !rounded-br-none',
    });
  };

  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        const { id } = (await dialogService.getLastDialog()) ?? {};
        if (id === WORKOUT_VIDEO_DETAIL_CONTENT_DIALOG_ID) {
          dialogService.popById(WORKOUT_VIDEO_DETAIL_CONTENT_DIALOG_ID);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className='flex h-full w-[400px] flex-col border-white600 bg-white50'>
      <Scrollbar disabledX={true}>
        <div className='h-full flex-col-center p-20'>
          <div className='relative w-full cursor-pointer rounded-3x ' onClick={handleClick}>
            {videoContent.thumbnailFileID?.paths?.[0] && (
              <div className='relative h-[175px] w-full overflow-hidden rounded-r16'>
                <img
                  className='h-full w-full object-cover'
                  src={videoContent.thumbnailFileID?.paths?.[0]}
                  alt='videoContentThumbnail'
                />
              </div>
            )}
            <div
              className='absolute-center h-40 w-40 flex-center rounded-full'
              style={{ backgroundColor: 'rgba(17, 17, 17, 0.6)' }}>
              <Icon name='play' size={24} color='white50' />
            </div>
          </div>
          <div className='mt-30 flex w-full flex-col gap-6'>
            <span className='line-clamp-2 overflow-hidden break-all text-Body13 text-black500'>
              {videoContent.subtitle}
            </span>
            <span className='line-clamp-2 overflow-hidden break-all text-Header16 text-black700'>
              {videoContent.title}
            </span>
          </div>
          <div className='mt-30 flex w-full flex-col gap-10'>
            {CONTENT_SECTIONS.map((section) => {
              return (
                <VideoContentSection
                  key={`${section.title}_${section.key}`}
                  section={section}
                  content={videoContent}>
                  {getChildrenContent(videoContent, section.fileKey)}
                </VideoContentSection>
              );
            })}
          </div>
        </div>
      </Scrollbar>
    </div>
  );
}
