import { getChatRoomInfo } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/hooks/use-chatroom-info-one';

export const getChatRoomOne = async (chatRoomID: string) => {
  try {
    const response = await getChatRoomInfo({
      chatRoomID,
    });
    //이 부분 역시 parentHServiceID를 통해서 카카오톡 연동과 무관하게 적절한 id 값을 가져옴
    return response;
  } catch (error) {
    console.error('Failed to fetch chat room info:', error);
    throw error;
  }
};
