import type { CounselAutomationAPIFormValues } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { useAtomValue } from 'jotai';
import { Suspense, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import SendContentFormHeader from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/FormLayout/SendContentFormHeader';
import SendContentFormTab from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/FormLayout/SendContentFormTab';
import EventContentForm from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/EventOrHomecareContentForm';
import ImageContentForm from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/ImageContentForm';
import SurveyContentForm from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/SurveyContentForm';
import TextContentForm from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/TextContentForm';
import VideoContentForm from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/VideoContentForm';
import WorkoutVideoContentForm from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/WorkoutVideoContentForm/WorkoutVideoContentForm';
import SendContentFormReplaceableTextLists from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/SendContentFormReplaceableTextLists';
import {
  selectedContentTabItemState,
  selectedMessageIdState,
  selectedMessageIndexState,
} from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';

export default function SendContentForm() {
  const textRef = useRef<HTMLTextAreaElement>(null);

  const { watch } = useFormContext<CounselAutomationAPIFormValues>();

  const selectedMessageId = useAtomValue(selectedMessageIdState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const selectedContentTabIndex = useAtomValue(selectedContentTabItemState);

  const sendingType = watch(`messages.${selectedMessageIndex}.sendingType`);

  if (sendingType === 'SURVEY') {
    return (
      <>
        {/* 내용 헤더 */}
        <SendContentFormHeader />

        {/* 전송내용 - 설문 */}
        <Suspense>
          <SurveyContentForm ref={textRef} />
        </Suspense>

        <SendContentFormReplaceableTextLists isSurvey={true} textRef={textRef} />
      </>
    );
  }

  return (
    <>
      {/* 내용 헤더 */}
      <SendContentFormHeader />

      {/* 내용 탭 (텍스트, 이미지, 동영상, 홈케어콘텐츠, 이벤트) */}
      <Suspense>
        <SendContentFormTab />
      </Suspense>

      {/* 전송내용 - 텍스트 */}
      <Suspense>
        {selectedContentTabIndex?.[selectedMessageId] === '텍스트' && (
          <TextContentForm ref={textRef} />
        )}
      </Suspense>

      {/* 전송내용 - 이미지 */}
      <Suspense>
        {selectedContentTabIndex?.[selectedMessageId] === '이미지' && (
          <ImageContentForm ref={textRef} />
        )}
      </Suspense>

      {/* 전송내용 - 동영상 */}
      <Suspense>
        {selectedContentTabIndex?.[selectedMessageId] === '동영상' && (
          <VideoContentForm ref={textRef} />
        )}
      </Suspense>

      {/* 전송내용 - 홈케어콘텐츠 */}
      <Suspense>
        {selectedContentTabIndex?.[selectedMessageId] === '홈케어콘텐츠' && (
          <WorkoutVideoContentForm ref={textRef} />
        )}
      </Suspense>

      {/* 전송내용 - 이벤트 */}
      <Suspense>
        {selectedContentTabIndex?.[selectedMessageId] === '이벤트' && (
          <EventContentForm ref={textRef} />
        )}
      </Suspense>

      {/* 치환문자 */}
      <Suspense>
        <SendContentFormReplaceableTextLists textRef={textRef} />
      </Suspense>
    </>
  );
}
