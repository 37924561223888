import { neverCheck } from 'afterdoc-saas-web/shared/utils/never-check';
import { CUSTOMER_CHAT_ERROR } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/constants/customer-chat-error-text';
import { ROOM_TYPE_MAP } from '../constants/ROOM_TYPE_MAP';
import type { RoomType } from '../states/selected-chat-room';

export type ChannelClosedStateSwitchParams = {
  roomType: RoomType | null;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  ikakaoInfo: Record<string, any> | undefined;
};

export type ChannelClosedState = {
  isClosedChannelChat: boolean;
  code: string | null;
};

const defaultChannelClosedState = { isClosedChannelChat: false, code: null };

export const channelClosedStateSwitch = ({
  roomType,
  ikakaoInfo,
}: ChannelClosedStateSwitchParams): ChannelClosedState => {
  switch (roomType) {
    case ROOM_TYPE_MAP.kakao: {
      return {
        isClosedChannelChat: ikakaoInfo?.isClosed ?? defaultChannelClosedState.isClosedChannelChat,
        // 카카오 상담톡이 isClosed가 되는 경우는 세가지
        // 1. 상담톡이 기간 만료가 된 경우
        // 2. 상담톡 환자가 차단을 한 경우
        // 3. 그 외 확인되지 않고 있는 에러 상황
        // 고로 EXPIRED 처리 함에 문제 없음.
        code: CUSTOMER_CHAT_ERROR.KAKAO.EXPIRED.CODE,
      };
    }
    case ROOM_TYPE_MAP.instagram:
    case ROOM_TYPE_MAP.manager:
    case ROOM_TYPE_MAP.afterdoc:
    case null: //해당 함수가 isDisabledChat에 사용되었고 CautionImagesSelect 컴포넌트에서 사용됨
      return defaultChannelClosedState;
    default:
      roomType satisfies never;
      neverCheck(roomType);
      return defaultChannelClosedState;
  }
};
