import { useMutation, useQueryClient } from '@tanstack/react-query';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import type { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  ApiPatientsElDetailData,
  ElFindLinkedChatroomsHandlerData,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { patientIdState } from 'afterdoc-saas-web/states/patient-id';
import { chatRoomIDSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import {
  postApprovalChatroomCreate,
  postChatRoomElCounselor,
} from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoTitle/apis';
import LinkedChannelChatRoom from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/LinkageChannels/components/LinkedChannelChatRoom/LinkedChannelChatRoom';
import LinkedChannelChatRoomJoinBottom from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/LinkageChannels/components/LinkedChannelChatRoomJoinBottom/LinkedChannelChatRoomJoinBottom';
import LinkedChannelsLoading from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/LinkageChannels/components/LinkedChannelsLoading';
import LinkedChannelsNoData from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/LinkageChannels/components/LinkedChannelsNoData';
import { closeCustomerInfo } from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/LinkageChannels/functions/close-customer-info';
import { useLinkedChatRoomsForMoving } from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/LinkageChannels/hooks/use-linked-chat-rooms-for-moving';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiError } from 'utils/api/check-api-response';

type Counselors = ElFindLinkedChatroomsHandlerData['data'][0]['counselors'];

export type OnMoveChatClickParams = Pick<
  ElFindLinkedChatroomsHandlerData['data'][0],
  'isParticipating'
> & {
  chatRoomID: ElFindLinkedChatroomsHandlerData['data'][0]['_id'];
  counselors: Counselors;
};

export const CHAT_JOIN_REQUEST_MODAL_ID = 'chat-join-request-success';

export default function LinkedChannelChatRoomsContainer() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const globalChatRoomID = useAtomValue(chatRoomIDSelector);
  const patientId = useAtomValue(patientIdState);

  const { hospitalID } = useSelectedHospitalInfo();
  const { userId, authorizationTypeID } = useUserInfo();
  const {
    // 고객 id
    patientId: patientIdFromQuery,
  } = queryClient.getQueryData([
    QUERY_KEY.apiPatientsElDetail,
    { patientId },
  ]) as ApiPatientsElDetailData['data'];

  const { linkedChannels } = useLinkedChatRoomsForMoving(patientIdFromQuery);

  const mutationPostChatroomCounselorAdd = useMutation({
    mutationFn: (params: Parameters<typeof apiClient.v3.apiChatroomElCounselorAdd>[0]) =>
      postChatRoomElCounselor(params),
    onError: (error) => {
      if (error instanceof ApiError) {
        console.warn('error', error);
        toastService.errorMsg({
          text: error.message,
        });
      }

      console.warn('error', error);
    },
  });

  const postApprovalChatroomCreateMutation = useMutation({
    mutationFn: (params: Parameters<typeof apiClient.v3.apiApprovalChatroomElCreate>[0]) =>
      postApprovalChatroomCreate(params),
    onSuccess: () => {
      modalService.popById(CHAT_JOIN_REQUEST_MODAL_ID);
    },
    onError: (error) => {
      console.warn('error', error);
    },
  });

  const onMoveChatClick = ({ chatRoomID, isParticipating, counselors }: OnMoveChatClickParams) => {
    const isCounselor = counselors.length !== 0;
    const isSameCustomerChatSelectedChatRoomID = globalChatRoomID === chatRoomID;

    // Case 1. 상담 최고 권한 있음
    if (authorizationTypeID?.canControlPatientChatting) {
      if (location.pathname === '/customer-chat' && isSameCustomerChatSelectedChatRoomID) {
        closeCustomerInfo();
        return;
      }

      // 고객 상담 - 해당 환자의 채팅방 화면으로 점프
      return navigate(`/customer-chat?chatRoomId=${chatRoomID}&reload=${Date.now()}`);
    }

    // Case 2. 상담 최고 권한 없음
    if (!authorizationTypeID?.canControlPatientChatting) {
      // Case 2-1. 해당 환자의 채팅방에 참여중인 경우
      if (isParticipating) {
        if (
          location.pathname === '/customer-chat' &&
          patientIdFromQuery &&
          isSameCustomerChatSelectedChatRoomID
        ) {
          closeCustomerInfo();
          return;
        }

        //  해당 환자의 채팅방 화면으로 점프
        return navigate(`/customer-chat?chatRoomId=${chatRoomID}&reload=${Date.now()}`);
      }

      // Case 2-2. 해당 환자의 채팅방에 참여중이 아닌 경우 (채팅 참여 모달에서 확인시 분기 함수)
      // Case 2-2-1. 담당자가 존재하는 경우
      if (isCounselor) {
        const confirmClickFunction = () => {
          postApprovalChatroomCreateMutation.mutate({
            chatRoomID,
            hospitalID,
            type: 'join',
          });
        };

        modalService.defaultSuccess({
          id: CHAT_JOIN_REQUEST_MODAL_ID,
          title: '채팅 참여',
          contents: `${counselors.map((user) => user.realName).join(', ')}님에게 참여 신청을 전송할까요?\n수락 시 채팅 리스트에서 확인할 수 있습니다.`,
          buttonType: 'CUSTOM',
          bottomButtons: (
            <LinkedChannelChatRoomJoinBottom confirmClickAdditionalHandler={confirmClickFunction} />
          ),
        });
        return;
      }

      // Case 2-2-2. 담당자가 존재하지 않는 경우
      return mutationPostChatroomCounselorAdd.mutate(
        {
          chatRoomID,
          counselors: [userId],
        },
        {
          onSuccess: () => {
            navigate(`/customer-chat?chatRoomId=${chatRoomID}&reload=${Date.now()}`);
          },
        },
      );
    }
  };

  const isNoData = linkedChannels.length === 0;

  return (
    <div className='mb-20 flex w-[434px] flex-col gap-16 rounded-2xl bg-white100 py-20 pr-24 pl-24'>
      <div className='gap-4'>
        <div className='text-Header14 text-black500'>채팅방</div>
        <div className='text-Body12 text-black500'>
          고객이 이용 중인 채팅방입니다. 클릭 시 바로 이동됩니다.
        </div>
      </div>
      <div className='h-full w-full overflow-hidden'>
        <Suspense fallback={<LinkedChannelsLoading />}>
          <Scrollbar disabledX className='rounded-r10 bg-white50'>
            {isNoData ? (
              <LinkedChannelsNoData />
            ) : (
              <div className='flex flex-col overflow-hidden'>
                {linkedChannels.map((linkedChannel) => (
                  <LinkedChannelChatRoom
                    key={linkedChannel._id}
                    linkedChannel={linkedChannel}
                    onMoveChatClick={onMoveChatClick}
                  />
                ))}
              </div>
            )}
          </Scrollbar>
        </Suspense>
      </div>
    </div>
  );
}
