import { QUERY_KEY } from '@apis/swaggers/query-key';
import { useHospitalHomeCare } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/hooks/use-hospital-home-care';
import EventHomeCarePreviewDialog from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventHomeCarePreviewDialog/EventHomeCarePreviewDialog';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { Suspense } from 'react';

interface HomeCareActionPanelProps {
  homeCareID?: string;
}

export default function HomeCareActionPanel({ homeCareID }: HomeCareActionPanelProps) {
  const { removeHomeCareAction } = useHospitalHomeCare();

  const { authorizationTypeID } = useUserInfo();

  const handleDetailButtonClick = () => {
    if (!homeCareID) return;
    dialogService.push(
      <Suspense
        fallback={
          <div className='h-full w-[400px] flex-center'>
            <BaseLoading />
          </div>
        }>
        <EventHomeCarePreviewDialog
          id={homeCareID}
          queryKey={QUERY_KEY.apiEventHospitalAftercareElFindOne}
        />
      </Suspense>,
      {
        id: 'event-home-care-preview-detail-dialog',
        hasCloseButton: true,
        wrapperClassName: 'h-[880px]',
        titleProps: {
          title: '홈케어 상품 상세보기',
        },
      },
    );
  };

  const handleRemoveButtonClick = () => {
    if (!homeCareID) return;
    removeHomeCareAction(homeCareID);
  };

  return (
    <div className='flex flex-grow items-center justify-end gap-6'>
      <OutlinedButton
        className='h-22 w-70'
        buttonSize='small'
        btnColor='secondary'
        onClick={handleDetailButtonClick}>
        상세보기
      </OutlinedButton>
      {!!authorizationTypeID?.canDeleteContent && (
        <ContainedButton
          className='h-22 w-50'
          buttonSize='small'
          btnColor='secondary'
          onClick={handleRemoveButtonClick}>
          삭제
        </ContainedButton>
      )}
    </div>
  );
}
