import {
  eventPushRequestCreateParamState,
  eventPushSendToHospitalAtom,
} from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/event-push-request-create-param-state';
import { useHospitalEvents } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/hooks/use-hospital-event';
import { eventCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/states/event-create-or-update-param-state';
import type { CreateUpdateEventParamType } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/types/create-update-event-param-type';
import { validateEventOrHomeCareParams } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/functions/validate-event-or-home-care-params';
import { useImageUpload } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/hooks/use-image-upload';
import { contentEditErrorsState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/content-edit-errors-state';
import { detailImageTempFileState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/detail-image-temp-file-state';
import { pushNotificationOptionState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/push-notification-option-state';
import { thumbnailImageTempFileState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/thumbnail-image-temp-file-state';
import { useRequestPush } from '@templates/Content/containers/ContentManager/hooks/use-request-push';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import DownloadFooter from 'afterdoc-design-system/components/Molecules/DownloadFooter/DownloadFooter';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';

interface EventManagePageFooter {
  isModified: boolean;
}

export default function EventManagePageFooter({ isModified }: EventManagePageFooter): JSX.Element {
  const { createEventAction, updateEventAction, removeEventAction } = useHospitalEvents();
  const { checkPushParamValid, requestPushAction } = useRequestPush();

  const { authorizationTypeID } = useUserInfo();

  const [contentEdit, setContentEdit] = useAtom(contentEditState);
  const [eventCreateOrUpdateParam, setEventCreateOrUpdateParam] = useAtom(
    eventCreateOrUpdateParamState,
  );
  const eventPushRequestParam = useAtomValue(eventPushRequestCreateParamState);

  const thumbnailImageTempFile = useAtomValue(thumbnailImageTempFileState);
  const detailImageTempFile = useAtomValue(detailImageTempFileState);

  const isSendToHospital = useAtomValue(eventPushSendToHospitalAtom);
  const pushNotificationOption = useAtomValue(pushNotificationOptionState);

  const setContentEditErrors = useSetAtom(contentEditErrorsState);

  const [isPrepared, setIsPrepared] = useState(false);

  const { uploadThumbnailImage, uploadDetailImage } = useImageUpload<CreateUpdateEventParamType>(
    thumbnailImageTempFile,
    detailImageTempFile,
    setEventCreateOrUpdateParam,
  );

  const validateAndSetErrors = () => {
    const errors = validateEventOrHomeCareParams(
      eventCreateOrUpdateParam,
      thumbnailImageTempFile,
      detailImageTempFile,
    );
    if (Object.keys(errors).length > 0) {
      setContentEditErrors((prev) => ({ ...prev, ...errors }));
      return false;
    }
    return true;
  };

  const validatePushTargetParams = () => {
    if (contentEdit.id || pushNotificationOption === 'none' || isSendToHospital) return true;

    return checkPushParamValid(eventPushRequestParam);
  };

  const handleEditEnd = () => setContentEdit({ isEditing: false });

  const handleCancel = () => {
    if (isModified) {
      modalService.defaultWarning({
        onConfirm: () => {
          const message = `이벤트 ${contentEdit.id ? '수정' : '등록'}을 취소했습니다.`;
          toastService.errorMsg({ text: message });
          setContentEdit({ isEditing: false });
          modalService.pop();
        },
      });
      return;
    }
    setContentEdit({ isEditing: false });
  };

  const handleRemove = () => {
    if (contentEdit.id) {
      removeEventAction(contentEdit.id, handleEditEnd);
    }
  };

  const handleSuccessCallback = (hospitalEventID: string) => {
    if (pushNotificationOption === 'none') {
      handleEditEnd();
      return;
    }
    requestPushAction({
      shouldValidate: false,
      eventPushRequestParam,
      hospitalEventID,
      onSuccessCallback: handleEditEnd,
      onFailureCallback: handleEditEnd,
    });
  };

  const handleErrorCallback = () => {
    setIsPrepared(false);
  };

  const handleConfirm = async () => {
    try {
      const isEventParamValid = validateAndSetErrors();
      const isPushParamValid = validatePushTargetParams();

      if (!isEventParamValid || !isPushParamValid) return;

      fullDimmedLoadingService.on();

      await uploadThumbnailImage();
      if (eventCreateOrUpdateParam.type === 'image') {
        await uploadDetailImage();
      }

      setIsPrepared(true);
    } catch (error) {
      console.error('Error during upload:', error);
      fullDimmedLoadingService.off();
    }
  };

  useEffect(() => {
    if (isPrepared) {
      if (contentEdit.id) {
        updateEventAction(eventCreateOrUpdateParam, handleEditEnd, handleErrorCallback);
      } else {
        createEventAction(eventCreateOrUpdateParam, handleSuccessCallback, handleErrorCallback);
      }
    }
  }, [isPrepared]);

  const customLeftButton = contentEdit.id && authorizationTypeID?.canDeleteContent && (
    <ContainedButton btnColor='red' onClick={handleRemove}>
      삭제
    </ContainedButton>
  );

  const customRightButton = (
    <div className='flex gap-10'>
      <ContainedButton btnColor='transparent' onClick={handleCancel}>
        취소
      </ContainedButton>
      <ContainedButton btnColor='blue' onClick={handleConfirm}>
        저장
      </ContainedButton>
    </div>
  );

  return (
    <DownloadFooter customLeftButton={customLeftButton} customRightButton={customRightButton} />
  );
}
