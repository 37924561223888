import type { ManualFolderList } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import type { ManusFolders } from '../hooks/use-manus-folders';

export const settingFolderOpen = ({
  data,
  treatmentTagsIDs,
  isSearchMode,
  firstTreatmentTagID,
}: {
  data: ManualFolderList;
  treatmentTagsIDs: string[];
  isSearchMode: boolean;
  firstTreatmentTagID: string;
}): ManusFolders[] => {
  const folders = data.foldersHasTags || [];

  return folders.map(({ treatmentTags, _id, name }) => {
    const isAnyTagOpen = treatmentTags?.some((tag) => treatmentTagsIDs.includes(tag._id));
    return {
      id: _id,
      type: 'folder',
      color: '',
      title: name,
      isOpen: isSearchMode ? true : (isAnyTagOpen ?? false),
      items:
        treatmentTags?.map(({ _id, categoryName, color }) => ({
          key: _id,
          text: categoryName,
          color: color,
          isOpen: firstTreatmentTagID === _id,
        })) ?? [],
    };
  });
};
