import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiServiceSettingsElFindOneParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';

const fetchServiceSetting = async (params: ApiServiceSettingsElFindOneParams) => {
  const { data } = await apiClient.v3.apiServiceSettingsElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useGetIsKakaoAlimAndFriendTalkLinkage = () => {
  const { hospitalID } = useSelectedHospitalInfo();

  const { data: serviceSettings } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiServiceSettingsElFindOne, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchServiceSetting(queryKey[1]),
  });

  return {
    hasKakaoAlimAndFriendTalkLinkage: serviceSettings.hasKakaoAlimAndFriendTalkLinkage,
    unlinkedAt: serviceSettings.kakaoAlimAndFriendTalkUnlinkedAt,
  };
};
