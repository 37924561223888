import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { CHAT_JOIN_REQUEST_MODAL_ID } from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/LinkageChannels/LinkedChannelChatRooms.container';

type LinkageChannelsChatJoinBottomProps = {
  confirmClickAdditionalHandler?: () => void;
};

export default function LinkedChannelChatRoomJoinBottom({
  confirmClickAdditionalHandler,
}: LinkageChannelsChatJoinBottomProps) {
  const handleConfirmClick = (confirmClickAdditionalHandler?: () => void) => {
    modalService.popById(CHAT_JOIN_REQUEST_MODAL_ID);

    confirmClickAdditionalHandler?.();
  };

  return (
    <>
      <OutlinedButton
        className='w-full'
        btnColor='blue'
        onClick={() => {
          modalService.popById(CHAT_JOIN_REQUEST_MODAL_ID);
        }}>
        취소
      </OutlinedButton>
      <ContainedButton
        className='w-full'
        btnColor='blue'
        onClick={() => {
          handleConfirmClick(confirmClickAdditionalHandler);

          modalService.popById(CHAT_JOIN_REQUEST_MODAL_ID);
        }}>
        확인
      </ContainedButton>
    </>
  );
}
