import { useCheckIsOverflow } from '@shared-hooks/use-check-is-overflow';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useSuspenseQuery } from '@tanstack/react-query';
import CursorTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/CursorTooltip';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type {
  ApiV2HreservationElFindParams2,
  V2HReservation,
  V2Reservation,
} from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { CHANGED_RESERVATIONS_TABLE_HEADERS } from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ChangedReservationsList/constants/changed-reservations-table-headers';
import { RESERVATION_HISTORY_TABLE_LIMIT } from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ReservationHistoryTable/constants/reservation-history-table-limit';
import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';

const CHANGED_RESERVATIONS_TABLE_STYLE = {
  paddingStyle: 'px-16 py-10',
  borderStyle: 'border-white400 border-b',
};

const fetchChangedReservationList = async (params: ApiV2HreservationElFindParams2) => {
  const response = await apiClient.v3.apiV2HreservationElFind(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

interface ChangedReservationsTableBodyProps {
  currentPage: number;
  reservationID: string;
}

export default function ChangedReservationsTableBody({
  currentPage,
  reservationID,
}: ChangedReservationsTableBodyProps) {
  const skip = (currentPage - 1) * RESERVATION_HISTORY_TABLE_LIMIT;
  const { hospitalID } = useSelectedHospitalInfo();

  const params: ApiV2HreservationElFindParams2 = {
    hospitalID,
    reservationID,
    limit: RESERVATION_HISTORY_TABLE_LIMIT,
    key: 'createdAt',
    way: -1,
    skip,
  };

  const { data: changedReservationList, refetch } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiV2HreservationElFind, params] as const,
    queryFn: ({ queryKey }) => fetchChangedReservationList(queryKey[1]),
  });

  useEffect(() => {
    window.electron?.ipcRenderer.on('Application.doneCreateReservation', (_, payload) => {
      if (payload) {
        refetch();
      }
    });

    return () => {
      window.electron?.ipcRenderer.removeAllListeners('Application.doneCreateReservation');
    };
  }, []);

  if (!changedReservationList || changedReservationList.length === 0) {
    return (
      <tr>
        <td colSpan={CHANGED_RESERVATIONS_TABLE_HEADERS.length}>
          <div className='flex h-[230px] w-full items-center justify-center'>
            <NoData
              iconProps={{
                name: 'warning',
                size: 48,
                color: 'white600',
              }}
              title='등록한 예약이 없습니다.'
              subTitle='애프터닥 예약으로 고객의 예약 내역을 관리할 수 있습니다.'
            />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <>
      {changedReservationList.map((data) => {
        const {
          _id: reservationId,
          body: { startedAt, groupTitle, columnTitle, programTitle, statusTitle, note, isCanceled },
          createdAt,
          writerID,
          // 백엔드 타입이 이상해서 타입 캐스팅을 해줘야함
        } = data as unknown as {
          body: V2Reservation;
          _id: string;
        } & V2HReservation[0];

        const ChangedReservationsRowData = {
          // 변경일시
          changedAt: dayjs(createdAt).format('YYYY-MM-DD HH:mm') ?? '-',
          // 부서
          department: groupTitle ?? '-',
          // 담당자
          columnTitle: columnTitle ?? '-',
          // 예약일시
          reservationDate: dayjs(startedAt).format('YYYY-MM-DD HH:mm') ?? '-',
          // 프로그램
          programName: programTitle ?? '-',
          // 예약상태
          reservationStatus: statusTitle ?? '-',
          // 예약메모
          reservationMemo: note ?? '-',
          // 수정자
          writer:
            writerID?.type === 1000
              ? '고객'
              : // 서비스 매니저인 경우
                Number(writerID?.type) >= 3000
                ? '애프터닥'
                : (writerID?.realName ?? '-'),
        };

        return (
          <tr key={reservationId} className={`${isCanceled ? 'text-red500' : ''}`}>
            {Object.entries(ChangedReservationsRowData).map(([key, value]) => (
              <ChangedReservationsTableBodyRow key={key} content={value} />
            ))}
          </tr>
        );
      })}
    </>
  );
}

function ChangedReservationsTableBodyRow({ content }: { content?: string | JSX.Element }) {
  const ref = useRef<HTMLDivElement>(null);

  const { isOverflow } = useCheckIsOverflow(ref);

  return (
    <td
      className={customTwMerge(
        CHANGED_RESERVATIONS_TABLE_STYLE.paddingStyle,
        CHANGED_RESERVATIONS_TABLE_STYLE.borderStyle,
      )}>
      <div className='flex'>
        <CursorTooltip
          text={content}
          show={isOverflow}
          wrapperProps={{
            className: 'truncate',
            style: {
              cursor: 'default',
            },
          }}>
          <div className='truncate break-all' ref={ref}>
            {content}
          </div>
        </CursorTooltip>
      </div>
    </td>
  );
}
