import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { isNationalitySelectBoxOpenedState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselBaseSetting/states/is-nationality-select-box-opened';
import { useAtomValue } from 'jotai';

export default function NationalitySettingSkeleton() {
  const isNationalitySelectBoxOpened = useAtomValue(isNationalitySelectBoxOpenedState);

  return (
    <div className='mt-16 rounded-r10 border border-white600 bg-white200 p-10'>
      <button type='button' className='flex w-full justify-between'>
        <div className='text-Header12 text-black500'>국적 (선택)</div>
        <Icon
          name={isNationalitySelectBoxOpened ? 'chevron-up' : 'chevron-down'}
          color='black200'
        />
      </button>
      {isNationalitySelectBoxOpened && (
        <div>
          <LabelText textClassName='text-Body10Bold mt-10'>국적</LabelText>
          <div className='mt-4 h-[250px] flex-center rounded-r10 border border-black200 bg-white50 py-4 pr-4 pl-16'>
            <BaseLoading />
          </div>
        </div>
      )}
    </div>
  );
}
