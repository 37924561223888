import type { VideoContentCategoryParentInfos } from '@apis/swaggers/swagger-docs';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import ContentPanelFolder from '@templates/Content/containers/ContentPanel/components/ContentPanelFolder';
import ContentPanelFolderItem from '@templates/Content/containers/ContentPanel/components/ContentPanelFolderItem';
import {
  CONTENT_PANEL_ITEMS,
  type ContentLayer,
  type ContentSubLayer,
} from '@templates/Content/containers/ContentPanel/constants/tab-items';
import { contentPanelLayerState } from '@templates/Content/containers/ContentPanel/states/content-panel-layer-state';
import { focusedContentSubLayerState } from '@templates/Content/containers/ContentPanel/states/focused-content-sub-layer-state';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useContentPanelCategories } from './hooks/use-content-categories';

const createContentItem = (category: VideoContentCategoryParentInfos): ContentSubLayer => ({
  key: category._id ?? '',
  text: category.categoryName ?? '',
  data: category,
});

const addCategoryToLayer = (
  layers: ContentLayer[],
  category: VideoContentCategoryParentInfos,
  parentCategoryName: string,
): ContentLayer[] => {
  const existingLayer = layers.find((layer) => layer.title === parentCategoryName);

  const contentItem = createContentItem(category);

  if (existingLayer) {
    existingLayer.items.push(contentItem);
  } else {
    layers.push({
      title: parentCategoryName,
      isOpen: true,
      items: [contentItem],
    });
  }

  return layers;
};

const buildContentPanelItems = (categories: VideoContentCategoryParentInfos[]): ContentLayer[] => {
  const layers: ContentLayer[] = [];
  for (const category of categories) {
    for (const parent of category.parentCategoryIDs ?? []) {
      addCategoryToLayer(layers, category, parent.categoryName ?? '');
    }
  }
  return layers;
};

export default function ContentPanelContainer() {
  const [categories, setCategories] = useState<VideoContentCategoryParentInfos[]>([]);
  const [contentPanelLayer, setContentPanelLayer] = useAtom(contentPanelLayerState);
  const setFocusedContentSubLayer = useSetAtom(focusedContentSubLayerState);

  const contentEdit = useAtomValue(contentEditState);

  const { usingHospitalEvent } = useSelectedHospitalInfo();

  const filteredContentPanelItems = useMemo(() => {
    return usingHospitalEvent
      ? CONTENT_PANEL_ITEMS
      : CONTENT_PANEL_ITEMS.filter((item) => item.title !== '이벤트');
  }, [usingHospitalEvent]);

  useContentPanelCategories({
    filteredContentPanelItems,
    setCategories,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (categories.length > 0) {
      const contentPanelItems = buildContentPanelItems(categories);

      setContentPanelLayer([...contentPanelItems, ...filteredContentPanelItems]);

      if (contentPanelItems.length > 0 && contentPanelItems[0].items.length > 0) {
        setFocusedContentSubLayer(contentPanelItems[0].items[0]);
      }
    }
  }, [categories, filteredContentPanelItems]);

  return (
    <>
      <div
        className='z-10 flex h-full flex-col border-r border-r-white400 bg-white50'
        style={{ visibility: contentEdit.isEditing ? 'hidden' : 'visible' }}>
        <Title title='콘텐츠' />
        <Scrollbar disabledX>
          <div className='select-none'>
            {contentPanelLayer.map((parentItem, folderIndex) => (
              <ContentPanelFolder key={parentItem.title} folderIndex={folderIndex}>
                {parentItem.items.map((childItem, itemIndex) => (
                  <ContentPanelFolderItem
                    key={childItem.key}
                    folderIndex={folderIndex}
                    itemIndex={itemIndex}
                  />
                ))}
              </ContentPanelFolder>
            ))}
          </div>
        </Scrollbar>
      </div>
    </>
  );
}
