import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { SingleAlgorithmHandlerParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { algorithmModeState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/algorithm-mode';
import { useAtomValue } from 'jotai';

const fetchSingleAlgorithmInfo = async (params: SingleAlgorithmHandlerParams) => {
  const response = await apiClient.v3.singleAlgorithmHandler(params);
  return response.data.data;
};

export function useSingleAlgorithmInfo() {
  const algorithmMode = useAtomValue(algorithmModeState);
  const { hospitalID } = useSelectedHospitalInfo();

  const { data: singleAlgorithmInfo } = useQuery({
    queryKey: [
      QUERY_KEY.singleAlgorithmHandler,
      {
        algorithmId:
          algorithmMode.mode === 'EDIT' || algorithmMode.mode === 'COPY'
            ? algorithmMode.algorithmId
            : undefined,
        hospitalID,
      },
    ] as const,
    queryFn: ({ queryKey }) => {
      const algorithmId = queryKey[1].algorithmId;
      if (!algorithmId) {
        return null;
      }

      return fetchSingleAlgorithmInfo({
        algorithmId,
        hospitalID,
      });
    },
    enabled: algorithmMode.mode !== 'CREATE' && !!algorithmMode.algorithmId,
  });

  return {
    singleAlgorithmInfo,
  };
}
