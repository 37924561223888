import type { ChatFileAndContentIDs } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import ChattingMessage from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/ChattingMessage';
import { useDateDivider } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-date-divider';
import { chattingWidthAtom } from 'afterdoc-saas-web/templates/CustomerChat/states/chattingWidth';
import { useAtomValue } from 'jotai';
import { v4 as uuidv4 } from 'uuid';
import type { DataResponse } from '../../hooks/use-conditional-chat-data';
import { USER_TYPE_CODE } from './components/ChattingMessage/constants/message';
import DateDivider from './components/DateDivider/DateDivider';

export type ChattingContentType = ChatFileAndContentIDs & { isUnread: boolean; status: string };

type ChattingContentProps = {
  chatRoomID: string;
  messages: Array<ChattingContentType>;
  setResMessages: React.Dispatch<React.SetStateAction<DataResponse[]>>;
  resMessages: DataResponse[];
};

export default function ChattingContent({
  chatRoomID,
  messages,
  setResMessages,
  resMessages,
}: ChattingContentProps) {
  const { shouldShowDate } = useDateDivider();
  const { userId } = useUserInfo();

  const chattingWidth = useAtomValue(chattingWidthAtom);

  const processMessageData = (
    chatMessage: ChattingContentType,
    messages: ChattingContentType[],
    index: number,
  ) => {
    const previousMessageTime = messages[index + 1]?.sentAt;
    const currentMessageTime = chatMessage.sentAt;
    const isLastSendMessage = messages.length === index + 1;

    const dividerLineDate = shouldShowDate({
      isLastSendMessage,
      previousMessageTime,
      currentMessageTime,
    });

    const isLeft = chatMessage.userType === USER_TYPE_CODE.PATIENT;

    const key = chatMessage._id + currentMessageTime;

    return { dividerLineDate, isLeft, key };
  };

  return (
    <>
      {messages.map((chatMessage, index) => {
        const { dividerLineDate, isLeft, key } = processMessageData(chatMessage, messages, index);

        return (
          <div key={key ?? uuidv4()} className='flex flex-col gap-20'>
            {dividerLineDate && <DateDivider dividerLineDate={dividerLineDate} />}
            <ChattingMessage
              {...chatMessage}
              chatRoomID={chatRoomID}
              isLeft={isLeft}
              userId={userId}
              chattingWidth={chattingWidth}
              index={index}
              setResMessages={setResMessages}
              resMessages={resMessages}
            />
          </div>
        );
      })}
    </>
  );
}
