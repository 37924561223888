import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import useFileDownloadWithProgress from 'afterdoc-saas-web/shared/hooks/files/use-file-download-with-progress';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { sanitizeFileName } from 'afterdoc-saas-web/shared/utils/sanitize-file-name';
import { useGetUsingColumns } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/hooks/apis/use-get-using-columns';
import type { Worksheet } from 'exceljs';
import { useEffect, useMemo, useState } from 'react';

interface CellStyle {
  font: {
    name: string;
    size: number;
    bold?: boolean;
    color?: { argb: string };
  };
  numFmt?: string;
}

interface ColumnConfig {
  header: string;
  useNumericStyle?: boolean;
}

export const useDownloadCustomerManagementExcelForm = () => {
  const { shortName } = useSelectedHospitalInfo();
  const usingColumns = useGetUsingColumns();

  const { startDownload, ...rest } = useFileDownloadWithProgress();
  const [ExcelJS, setExcelJS] = useState<typeof import('exceljs') | null>(null);
  const excelTitle = `${sanitizeFileName(shortName)}_고객등록_양식.xlsx`;

  const getActiveColumns = useMemo(() => {
    const baseColumns = new Map<string, ColumnConfig>([['A', { header: '고객명*' }]]);

    if (usingColumns?.usingChartNumber) {
      baseColumns.set('B', { header: '차트번호' });
    }

    const phoneNumberColumn = usingColumns?.usingChartNumber ? 'C' : 'B';
    baseColumns.set(phoneNumberColumn, {
      header: '휴대폰번호*',
      useNumericStyle: true,
    });

    return baseColumns;
  }, [usingColumns]);

  const applyBasicStyle = (worksheet: Worksheet, cell: string, style: CellStyle) => {
    const wsCell = worksheet.getCell(cell);
    wsCell.font = style.font;
    if (style.numFmt) wsCell.numFmt = style.numFmt;
    return wsCell;
  };

  const setupWorksheet = (worksheet: Worksheet) => {
    // 컬럼 너비 설정
    for (const col of getActiveColumns.keys()) {
      const columnIndex = col.charCodeAt(0) - 64;
      worksheet.getColumn(columnIndex).width = 15;
    }

    // 행 높이와 셀 병합
    worksheet.getRow(1).height = 40;

    // 활성화된 컬럼 수에 따라 첫 번째 행 병합
    const lastColumn = String.fromCharCode(64 + getActiveColumns.size);
    worksheet.mergeCells(`A1:${lastColumn}1`);
    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.mergeCells('A2:C2');
  };

  const fillInitialData = (worksheet: Worksheet) => {
    const headerData: Record<string, string> = {
      A1: '고객 등록 양식',
      A2: '*양식을 임의로 수정하면 업로드에 실패할 수 있습니다.',
    };

    // 동적 헤더 추가
    for (const [col, config] of getActiveColumns) {
      headerData[`${col}3`] = config.header;
    }

    // 헤더 스타일
    const headerStyles: Record<string, CellStyle> = {
      A1: { font: { name: 'Arial', bold: true, size: 20 } },
      A2: { font: { name: 'Arial', size: 10, color: { argb: 'FF0000' } } },
      default: { font: { name: 'Arial', size: 10, bold: true } },
    };

    // 데이터 스타일
    const dataStyle: CellStyle = { font: { name: 'Arial', size: 10 } };
    const numericStyle: CellStyle = { ...dataStyle, numFmt: '@' };

    // 헤더 적용
    for (const [cell, value] of Object.entries(headerData)) {
      const style = headerStyles[cell] || headerStyles.default;
      applyBasicStyle(worksheet, cell, style).value = value;
    }

    // 빈 행 생성
    for (let i = 4; i <= 300; i++) {
      for (const [col, config] of getActiveColumns) {
        const style = config.useNumericStyle ? numericStyle : dataStyle;
        applyBasicStyle(worksheet, `${col}${i}`, style).value = '';
      }
    }
  };

  const handleDownloadCustomerManagementExcelForm = async () => {
    if (!ExcelJS) {
      toastService.errorMsg({ text: '엑셀 파일 다운로드에 실패했습니다.' });
      return;
    }

    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('고객등록 양식');

      setupWorksheet(worksheet);
      fillInitialData(worksheet);

      if (window.electron) {
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        startDownload(blob, excelTitle, [{ name: 'Excel Files', extensions: ['xlsx'] }]);
      }
    } catch (error) {
      console.error('엑셀 파일 다운로드 에러 발생 :', error);
      toastService.errorMsg({ text: '엑셀 파일 다운로드에 실패했습니다.' });
    }
  };

  useEffect(() => {
    import('exceljs/dist/exceljs.min.js').then((module) => {
      setExcelJS(module as typeof import('exceljs'));
    });
  }, []);

  return {
    handleDownloadCustomerManagementExcelForm,
    excelTitle,
    ...rest,
  };
};
