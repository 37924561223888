import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { isCustomerManagementSaveLockState } from 'afterdoc-saas-web/hooks/push-notification/states/is-customer-management-save-lock';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useSaveUser } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/hooks/save/use-save-user';
import { useSaveUserTemporary } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/hooks/save/use-save-user-temporary';
import { useTemporaryPatientsModifier } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/utils/ use-temporary-patients-modifier';
import { customerTableDisplayModeState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-display-mode';
import { modifierInfoState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/modifier-info';
import type { CustomerManagementTemporaryAPIFormValues } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

// 행에 저장할 데이터가 있는지 확인하는 함수
const hasRowData = (row: CustomerManagementTemporaryAPIFormValues[number]) => {
  const { hospitalID, order, patientId, ...fields } = row;
  return Object.values(fields).some(
    (value) =>
      value !== null &&
      value !== '' &&
      value !== undefined &&
      !(Array.isArray(value) && value.length === 0),
  );
};

export default function FinalSaveButton() {
  const { getValues } = useFormContext<{ rows: CustomerManagementTemporaryAPIFormValues }>();
  const [isSaving, setIsSaving] = useState(false); // 로컬 상태로 저장 중인지 여부 추적

  const { hospitalID } = useSelectedHospitalInfo();
  const { patchTemporaryPatientsLastModifierMutation } = useTemporaryPatientsModifier();
  const { handleTemporarySave } = useSaveUserTemporary();
  const { handleSaveUserInfoFinally } = useSaveUser();

  const setCustomerTableDisplayMode = useSetAtom(customerTableDisplayModeState);
  const [isCustomerManagementSaveLock, setIsCustomerManagementSaveLock] = useAtom(
    isCustomerManagementSaveLockState,
  );
  const setModifierInfo = useSetAtom(modifierInfoState);

  // useCallback으로 핸들러 함수 메모이제이션
  const handleSaveUser = useCallback(async () => {
    // 이미 저장 중이면 중복 실행 방지
    if (isSaving) return;

    const rows = getValues('rows');

    if (!rows.some(hasRowData)) {
      return toastService.errorMsg({
        text: '저장할 고객 정보가 없습니다.',
      });
    }

    try {
      // 1. 저장 시작 시 상태 업데이트
      setIsSaving(true);

      // 2. Display Mode 변경
      setCustomerTableDisplayMode('VIEWER');

      // 3. 찐저장 잠금 상태 걸기
      setIsCustomerManagementSaveLock((prev) => ({
        ...prev,
        isLock: true,
      }));

      // 4. 임시 저장 실행
      const temporarySaveResult = await handleTemporarySave(rows);

      // 5. 최종 저장 실행
      if (temporarySaveResult) {
        await handleSaveUserInfoFinally();
      }

      // 6. 마지막 수정자 정보 업데이트
      const result = await patchTemporaryPatientsLastModifierMutation.mutateAsync({
        hospitalID,
      });

      // 7. 수정자 정보 상태 업데이트
      setModifierInfo({
        createdAt: result.createdAt,
        userID: result.userID,
      });
    } catch (error) {
      console.error('Error during save process:', error);
      // 에러 발생 시 저장 상태 해제
      setIsSaving(false);

      // 에러 발생 시 잠금 상태 해제 (선택적)
      setIsCustomerManagementSaveLock((prev) => ({
        ...prev,
        isLock: false,
      }));
    }
  }, [
    getValues,
    hospitalID,
    handleTemporarySave,
    handleSaveUserInfoFinally,
    patchTemporaryPatientsLastModifierMutation,
    setCustomerTableDisplayMode,
    setIsCustomerManagementSaveLock,
    setModifierInfo,
    isSaving,
  ]);

  return (
    <ContainedButton
      btnColor='blue'
      onClick={handleSaveUser}
      // 버튼 비활성화 조건: 전역 잠금 상태 또는 로컬 저장 중 상태
      disabled={isCustomerManagementSaveLock.isLock || isSaving}
    >
      등록
    </ContainedButton>
  );
}
