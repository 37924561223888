import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import {
  MAX_LAYOUT_WIDTH,
  MIN_LAYOUT_WIDTH,
} from 'afterdoc-saas-web/templates/CustomerChat/constants/layout';

export default function ChatRoomIDNull() {
  return (
    <div
      className='h-full w-full flex-center flex-col gap-16 bg-blue50'
      style={{ minWidth: `${MIN_LAYOUT_WIDTH}px`, maxWidth: `${MAX_LAYOUT_WIDTH}px` }}>
      <NoData
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'blue100',
        }}
        title='고객을 선택해 주세요.'
        subTitle='고객을 선택하여 상담을 진행할 수 있습니다.'
      />
    </div>
  );
}
