import { FileUploadController } from '@shared/utils/fileUpload/FileUploadController';
import type { FileUploadResponse } from '@shared/utils/fileUpload/types/types';
import type { CreateUpdateEventParamType } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/types/create-update-event-param-type';
import type { CreateUpdateHomeCareParams } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/types/create-update-homecare-params';
import type { UploadImageFile } from '@templates/Content/containers/ContentManager/types/upload-image-file';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';

type ParamType = CreateUpdateEventParamType | CreateUpdateHomeCareParams;

export const useImageUpload = <T = ParamType>(
  thumbnailImageTempFile: UploadImageFile | null,
  detailImageTempFile: UploadImageFile | null,
  setParam: React.Dispatch<React.SetStateAction<T>>,
) => {
  const { userId } = useUserInfo();

  const uploadImage = async (
    tempFile: UploadImageFile | null,
    callback: (fileID: string) => void,
  ): Promise<void> => {
    if (!tempFile) return;

    return new Promise<void>((resolve, reject) => {
      const onComplete = (result: FileUploadResponse) => {
        if (result.code !== 0 || !result.data) {
          return reject(new Error('Image upload failed'));
        }

        const file = result.data[0];
        callback(file._id);
        resolve();
      };

      FileUploadController.uploadFiles({
        files: [tempFile.file],
        userID: userId,
        callback: onComplete,
      });
    });
  };

  const uploadThumbnailImage = async () => {
    await uploadImage(thumbnailImageTempFile, (fileID) => {
      setParam((prev) => ({ ...prev, representImage: fileID }));
    });
  };

  const uploadDetailImage = async () => {
    await uploadImage(detailImageTempFile, (fileID) => {
      setParam((prev) => ({ ...prev, fileIDs: [fileID] }));
    });
  };

  return { uploadThumbnailImage, uploadDetailImage };
};
