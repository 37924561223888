import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiApprovalChatroomElFindParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useMemo } from 'react';

const getApprovalRequest = async (query: ApiApprovalChatroomElFindParams) => {
  const { data } = await apiClient.v3.apiApprovalChatroomElFind(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const GET_APPROVAL_REQUEST_BASED_QUERY = {
  status: 'waiting',
  type: 'join',
};

export const useGetApprovalChatroom = ({ chatRoomID }: { chatRoomID: string }) => {
  const query = useMemo(() => {
    return {
      chatRoomID,
      ...GET_APPROVAL_REQUEST_BASED_QUERY,
    };
  }, [chatRoomID]);

  const {
    data: approvalChatroom,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [QUERY_KEY.apiApprovalChatroomElFind, query] as const,
    queryFn: ({ queryKey }) => getApprovalRequest(queryKey[1]),
  });

  return {
    approvalChatroom: approvalChatroom ?? [],
    isLoading,
    refetch,
  };
};
