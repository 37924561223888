import { IMAGE_EXTENSIONS } from '@shared/utils/fileUpload/constants/file';
import { isSizeNoZero } from 'afterdoc-saas-web/shared/utils/fileUpload/functions/is-size-no-zero';
import { validateFileSize } from 'afterdoc-saas-web/shared/utils/fileUpload/functions/validateFileSize';

export const getFileExt = (file: File): string => {
  const fileName = file.name;
  const array = fileName.split('.');
  return array[array.length - 1];
};

const isImageFile = (file: File): boolean => {
  const ext = getFileExt(file).toLowerCase();
  return IMAGE_EXTENSIONS.includes(ext);
};

const getVideoFileWidthHeight = (file: File): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const video = document.createElement('video');

    video.src = url;

    video.onloadedmetadata = () => {
      const width = video.videoWidth;
      const height = video.videoHeight;
      URL.revokeObjectURL(url); // Clean up the object URL
      resolve({ width, height });
    };

    video.onerror = (error) => {
      URL.revokeObjectURL(url);
      reject(error);
    };
  });
};

const getImageFileWidthHeight = (file: File): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target?.result) {
        const img = new Image();
        img.src = e.target.result as string;

        img.onload = () => {
          const width = img.width;
          const height = img.height;
          resolve({ width, height });
        };

        img.onerror = (error) => {
          reject(error);
        };
      } else {
        reject(new Error('FileReader failed to load the file'));
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const FileService = {
  getFileExt,
  getImageFileWidthHeight,
  getVideoFileWidthHeight,
  isImageFile,
  validateFileSize,
  isSizeNoZero,
};
