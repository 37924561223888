import type { KakaoConsultationTalkLinkageHandlerBodyRequest } from '@apis/swaggers/swagger-docs';
import RequestInfoWrapper from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/common/RequestInfoWrapper/RequestInfoWrapper';
import { hospitalSettingsModifiedState } from '@templates/HospitalSetting/states/hospital-settings-modified-state';
import { useNavigationBlocker } from 'afterdoc-saas-web/shared/hooks/gnb/use-navigation-blocker';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import InstagramIntegrationWrapper from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/InstagramForm/components/InstagramIntegrationWrapper/InstagramIntegrationWrapper';
import type { InstagramAccountsResType } from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/hooks/use-Linkage-history';
import { useAtom } from 'jotai/index';
import { isEqual } from 'lodash-es';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const EMPTY_SUB_TITLE = '';

interface InstagramFormProps {
  data: InstagramAccountsResType;
}

export default function InstagramForm({ data }: InstagramFormProps) {
  const { hospitalID } = useSelectedHospitalInfo();
  const { realName } = useUserInfo();

  const [hospitalSettingsModified, setHospitalSettingsModified] = useAtom(
    hospitalSettingsModifiedState,
  );

  const { requesterName = realName, requesterContact = '00000' } = data;

  const defaultValues = {
    hospitalID,
    requesterName,
    toBeContactedPhone: requesterContact,
  };

  const methods = useForm<KakaoConsultationTalkLinkageHandlerBodyRequest>({
    defaultValues: { ...defaultValues },
  });

  const currentValues = methods.watch();

  useEffect(() => {
    setHospitalSettingsModified(
      Object.keys(defaultValues).some((key) => {
        const typedKey = key as keyof typeof defaultValues;
        const currentValue = currentValues[typedKey];
        const defaultValue = defaultValues[typedKey];

        if (typeof currentValue === 'string' && typeof defaultValue === 'string') {
          return currentValue !== defaultValue;
        }

        if (typeof currentValue === 'object' && typeof defaultValue === 'object') {
          return !isEqual(currentValue, defaultValue);
        }
        return currentValue !== defaultValue;
      }),
    );
  }, [currentValues]);

  useNavigationBlocker({
    shouldBlock: hospitalSettingsModified,
  });

  return (
    <FormProvider {...methods}>
      <form autoComplete='off'>
        <div className='flex size-full flex-col'>
          <RequestInfoWrapper
            subTitle={EMPTY_SUB_TITLE} //RequestInfoWrapper를 사용하는 곳에서 명시적으로 넘겨주고 있어서 빈값으로 넘겨줌
            isBeStartDateTimeInput={false}
            isAllDisabled
          />
        </div>
      </form>
      <InstagramIntegrationWrapper
        hospitalID={hospitalID}
        instagramAccounts={data.instagramAccounts}
        maximumInstagramAccountCount={data.maximumInstagramAccountCount}
      />
    </FormProvider>
  );
}
