import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { CommonAlgorithmInput } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { originalValuesState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/EditCommonAutomationDialog/states/original-values';
import { useAtomValue } from 'jotai';
import { isEqual } from 'lodash-es';
import { useFormContext } from 'react-hook-form';

const editCommonAutomation = async (params: CommonAlgorithmInput) => {
  const response = await apiClient.v3.apiAutomationsElCommonAlgorithmUpsert(params);
  return response.data;
};

export const useSubmitEditCommonAutomation = () => {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();
  const originalValues = useAtomValue(originalValuesState);

  const { watch, setError, reset } = useFormContext<CommonAlgorithmInput>();
  const changedValues = watch();

  const registerMutation = useMutation({
    mutationFn: editCommonAutomation,
    onSuccess: (data) => {
      if (data.code === 0) {
        toastService.successMsg({
          text: '공동 자동화가 수정되었습니다.',
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiAutomationsElMainList, { hospitalID }],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiAutomationsElCommonAlgorithm, { hospitalID }],
        });
        reset(changedValues);
      } else {
        toastService.errorMsg({
          text: data.message,
        });
      }
    },
    onError: (error) => {
      console.error('error', error);
      toastService.errorMsg({
        text: '공동 자동화 수정을 실패했습니다. 다시 시도해 주세요.',
      });
    },
  });

  const openModal = (isActive: boolean) => {
    modalService.defaultWarning({
      title: `자동화를 ${isActive ? '활성화' : '비활성화'} 할까요?`,
      contents: isActive
        ? '활성화 할 경우, 상담자동화별 지정된 발송일자에 메시지가 발송됩니다.'
        : '현재 상담자동화별 지정된 발송일자에 더 이상 메시지를 발송하지 않습니다.',
      onConfirm: () => {
        try {
          registerMutation.mutate(changedValues);
        } catch (error) {
          console.error('File upload failed:', error);
          toastService.errorMsg({
            text: '파일 업로드에 실패했습니다. 다시 시도해 주세요.',
          });
        }
        modalService.pop();
      },
      onCancel: () => {
        modalService.pop();
      },
    });
  };

  const handleRegister = async () => {
    if (isEqual(changedValues, originalValues)) {
      toastService.errorMsg({
        text: '수정된 내용이 없습니다.',
      });
      return;
    }

    if (!changedValues.content || changedValues.content.trim() === '') {
      setError('content', {
        type: 'manual',
        message: '내용을 입력해 주세요.',
      });
      return;
    }

    if (originalValues?.isActive !== changedValues.isActive) {
      openModal(changedValues.isActive);
      return;
    }

    try {
      registerMutation.mutate(changedValues);
    } catch (error) {
      console.error('File upload failed:', error);
      toastService.errorMsg({
        text: '파일 업로드에 실패했습니다. 다시 시도해 주세요.',
      });
    }
  };

  return {
    handleRegister,
    isPending: registerMutation.isPending,
  };
};
