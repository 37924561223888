export const DEFAULT_LAYOUT_WIDTH = 550;
export const MIN_LAYOUT_WIDTH = 550;
export const MAX_LAYOUT_WIDTH = 2000;

export const Z_INDEXS = {
  ChattingTooltip: 4,
  ManualToogleButton: 2,
  ChattingBottomIsSettingTranslateLanguage: 10,
  MostTop: 1000,
};
