import type { CustomerManagementTemporaryAPIFormValues } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';

export function createInitialRows(
  hospitalID: string,
  rows?: CustomerManagementTemporaryAPIFormValues,
  forcedMaxOrder?: number,
): CustomerManagementTemporaryAPIFormValues {
  const createEmptyRow = (order: number): CustomerManagementTemporaryAPIFormValues[number] => ({
    order,
    hospitalID,
    patientId: null,
    name: null,
    chartNumber: null,
    isFirstVisit: null,
    phoneNumber: null,
    birthdate: null,
    gender: null,
    treatmentTagIds: [],
    nationalityId: null,
    hasToBlockSendingMessage: null,
  });

  if (!rows?.length) {
    return Array.from({ length: 50 }, (_, i) => createEmptyRow(i + 1));
  }

  // forcedMaxOrder가 제공되면 그 값을 사용, 아니면 기존 로직대로 계산
  const maxOrder = forcedMaxOrder ?? Math.max(...rows.map((row) => row.order));
  const finalLength = Math.ceil(maxOrder / 50) * 50;
  const allOrders = Array.from({ length: finalLength }, (_, i) => i + 1);

  return allOrders.map((order) => {
    const existingRow = rows.find((row) => row.order === order);
    return existingRow || createEmptyRow(order);
  });
}
