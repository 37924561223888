import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { filterWrapperClassName } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableHeader/constants/class';
import { forwardRef, useState } from 'react';

const GenderFilter = forwardRef<
  HTMLDivElement,
  {
    value: 'MALE' | 'FEMALE' | 'all' | undefined;
    onValueChange?: (value: 'MALE' | 'FEMALE' | 'all' | undefined) => void;
  }
>((props, ref) => {
  const [checkState, setCheckState] = useState<{ MALE: boolean; FEMALE: boolean }>({
    MALE: props.value === 'MALE' || props.value === 'all',
    FEMALE: props.value === 'FEMALE' || props.value === 'all',
  });

  const handleCheckboxChange = (gender: 'MALE' | 'FEMALE') => {
    const newState = { ...checkState, [gender]: !checkState[gender] };
    setCheckState(newState);

    if (newState.MALE && newState.FEMALE) {
      props.onValueChange?.('all');
    } else if (newState.MALE && !newState.FEMALE) {
      props.onValueChange?.('MALE');
    } else if (!newState.MALE && newState.FEMALE) {
      props.onValueChange?.('FEMALE');
    } else {
      props.onValueChange?.(undefined);
    }
  };

  return (
    <div className={`${filterWrapperClassName} w-[188px]`} ref={ref}>
      <div className='p-16'>
        <div className='mb-10 text-Caption9 text-black200'>
          *체크한 값에 해당하는 고객만 표시됩니다.
        </div>
        <Checkbox
          label={
            <FilledTag className='cursor-pointer' tagSize='small'>
              남성
            </FilledTag>
          }
          checked={checkState.MALE}
          onChange={() => handleCheckboxChange('MALE')}
        />
        <Checkbox
          label={
            <FilledTag className='cursor-pointer' tagSize='small'>
              여성
            </FilledTag>
          }
          checked={checkState.FEMALE}
          onChange={() => handleCheckboxChange('FEMALE')}
        />
      </div>
    </div>
  );
});

export default GenderFilter;
