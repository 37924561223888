import type { RoomType } from '@templates/CustomerChat/states/selected-chat-room';
import type { LinkedChatroomList } from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoTitle/components/LinkedChatRoomManagerDialog/hooks/use-linked-chat-room';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import Icon, { type IconType } from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import type { LinkedChatroomElUnlinkHandlerBodyRequest } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import type { MouseEvent } from 'react';

interface LinkedChattingItemProps {
  item: LinkedChatroomList[number];
  handleUnLinkChatRoom: (params: LinkedChatroomElUnlinkHandlerBodyRequest) => void;
}

const roomTypeConfig: Record<RoomType, { icon: IconType; label: string } | null> = {
  ikakao: { icon: 'kakaotalk', label: '상담톡' }, // 인스타 연동 시 추가 코드 필요.
  instagram: null,
  manager: null,
  patient: null,
};

export default function LinkedChatRoomItem({
  item,
  handleUnLinkChatRoom,
}: LinkedChattingItemProps) {
  const roomType = item.chatRoomID.roomType;
  const config = roomType ? roomTypeConfig[roomType] : null;

  if (!config) {
    return null;
  }

  const handleUnLinkChatRoomButton = () => {
    modalService.defaultWarning({
      title: '채팅방 연동 해제',
      contents:
        '선택한 채팅방의 연동을 해제할까요?\n' +
        '연동을 해제하더라도, 고객등록을 통해 다시 연동할 수 있습니다.',
      onConfirm: handleConfirm,
      onCancel: handleCancel,
    });
  };

  const handleConfirm = (e?: MouseEvent) => {
    e?.stopPropagation();
    handleUnLinkChatRoom({ _id: item._id });
    modalService.pop();
  };

  const handleCancel = (e?: MouseEvent) => {
    e?.stopPropagation();
    modalService.pop();
  };

  return (
    <div className='mt-10 flex flex-col gap-10'>
      <div className='flex items-center justify-between gap-24'>
        <div className='flex flex-col gap-4 overflow-hidden'>
          <div className='flex items-center gap-4 overflow-hidden'>
            <Icon name={config.icon} size={20} className='flex-shrink-0' />
            <span className='flex-grow truncate text-Header14 text-black700'>
              {item.channelName}
            </span>
          </div>
          <span className='truncate text-Body12 text-black400'>{item.chatRoomID.title}</span>
        </div>
        <OutlinedButton
          className='flex-shrink-0'
          buttonSize={'small'}
          onClick={handleUnLinkChatRoomButton}>
          해제
        </OutlinedButton>
      </div>

      <Divider type={'line'} />
    </div>
  );
}
