import LayoutBox from '@shared/components/LayoutBox/LayoutBox';
import { isServiceManagerNavigatorVisibleState } from '@shared/states/is-service-manager-navigator-visible';
import { Color } from '@tailwind-base/styles/color';
import NoticeContentEditor from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/components/NoticeManageDialog/components/NoticeContentEditor';
import NoticeCreateDateLabel from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/components/NoticeManageDialog/components/NoticeCreateDateLabel';
import NoticeManagePageFooter from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/components/NoticeManageDialog/components/NoticeManagePageFooter';
import NoticePreview from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/components/NoticeManageDialog/components/NoticePreview';
import NoticePushTarget from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/components/NoticeManageDialog/components/NoticePushTarget';
import NoticeTitleInput from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/components/NoticeManageDialog/components/NoticeTitleInput';
import { DEFAULT_NOTICE_PARAMS } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/constants/default-notice-params';
import { noticeCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/states/notice-create-or-update-param-state';
import { noticeEditErrorState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/states/notice-edit-error-state';
import type { CreateUpdateNoticeParamType } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/types/create-update-notice-param-type';
import { popupContentTempFileState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-content-temp-file-state';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { useNavigationBlocker } from 'afterdoc-saas-web/shared/hooks/gnb/use-navigation-blocker';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';
import { isEqual } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

export default function NoticeManageDialogContainer() {
  const { hospitalID } = useSelectedHospitalInfo();

  const { authorizationTypeID } = useUserInfo();

  const [noticeCreateOrUpdateParam, setNoticeCreateOrUpdateParams] = useAtom(
    noticeCreateOrUpdateParamState,
  );

  const contentEdit = useAtomValue(contentEditState);
  const isServiceManagerNavigatorVisible = useAtomValue(isServiceManagerNavigatorVisibleState);

  const resetNoticeCreateOrUpdateParam = useResetAtom(noticeCreateOrUpdateParamState);
  const resetNoticeEditError = useResetAtom(noticeEditErrorState);
  const resetPopupContentTempFile = useResetAtom(popupContentTempFileState);

  const [initCreateOrUpdateParam, setInitCreateOrUpdateParam] = useState<
    CreateUpdateNoticeParamType | undefined
  >(undefined);

  const [isInitialized, setIsInitialized] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const { title, content } = noticeCreateOrUpdateParam;

  const canUpsertContent = !!authorizationTypeID?.canUpsertContent;

  const formattedDate = useMemo(() => {
    if (contentEdit.id) {
      const editData = contentEdit.data;
      if (editData?.editType === 'notice' && editData.createdAt) {
        return dayjs(editData.createdAt).isValid()
          ? dayjs(editData.createdAt).format('YYYY-MM-DD')
          : '-';
      }
      return '-';
    }
    return dayjs().format('YYYY-MM-DD');
  }, [contentEdit]);

  useEffect(() => {
    if (contentEdit.id && contentEdit.data?.editType === 'notice') {
      const { _id: noticeID = '', title = '', content = '' } = contentEdit.data;
      const noticeData = {
        _id: noticeID,
        hospitalID,
        title,
        content,
        isSendMessage: false,
        isSendToHospital: false,
      };
      setNoticeCreateOrUpdateParams(noticeData);
      setInitCreateOrUpdateParam(noticeData);
    } else {
      setInitCreateOrUpdateParam(DEFAULT_NOTICE_PARAMS);
    }
    setIsInitialized(true);
  }, [contentEdit]);

  useEffect(() => {
    const isEquals = isEqual(initCreateOrUpdateParam, noticeCreateOrUpdateParam);
    setIsModified(!isEquals);
  }, [initCreateOrUpdateParam, noticeCreateOrUpdateParam]);

  useEffect(() => {
    return () => {
      resetNoticeCreateOrUpdateParam();
      resetNoticeEditError();
      resetPopupContentTempFile();
    };
  }, []);

  useNavigationBlocker({
    shouldBlock: isModified,
  });

  if (!isInitialized) {
    return (
      <div className='min-h-screen w-full flex-center'>
        <BaseLoading />
      </div>
    );
  }

  return (
    <Portal
      style={{
        position: 'fixed',
        top: 0,
        left: isServiceManagerNavigatorVisible ? '140px' : '70px',
        zIndex: 100,
        right: 0,
        bottom: 0,
        backgroundColor: Color.blueLight,
      }}>
      <div className='flex min-h-screen w-full flex-col'>
        <div className='mx-auto flex w-[1400px]'>
          <div className='w-[1000px] bg-white50'>
            <Title title={`공지사항 ${contentEdit.id ? '수정' : '등록'}`} />
            <div className='h-[calc(100vh-120px)]'>
              <Scrollbar disabledX>
                <div className='h-full w-full px-100 py-40'>
                  <LayoutBox direction='vertical' size={10} width={800}>
                    <NoticeCreateDateLabel date={formattedDate} />
                    <NoticeTitleInput title={title} isDisabled={!canUpsertContent} />
                    <Divider type='line' />
                  </LayoutBox>
                  <LayoutBox noStyle>{!contentEdit.id && <NoticePushTarget />}</LayoutBox>
                  <LayoutBox noStyle>
                    <NoticeContentEditor content={content} isDisabled={!canUpsertContent} />
                  </LayoutBox>
                </div>
              </Scrollbar>
            </div>
          </div>
          <NoticePreview title={title} content={content} createdAt={formattedDate} />
        </div>
        <NoticeManagePageFooter isModified={isModified} />
      </div>
    </Portal>
  );
}
