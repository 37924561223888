import { useClickOutside } from '@shared-hooks/use-click-outside';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useQueryClient } from '@tanstack/react-query';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import { SUCCESS } from 'afterdoc-saas-web/shared/constants/codes';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { hospitalInfosAtom } from 'afterdoc-saas-web/states/token';
import { useChatRoomInfoOne } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/hooks/use-chatroom-info-one';
import { Z_INDEXS } from 'afterdoc-saas-web/templates/CustomerChat/constants/layout';
import { usePatient } from 'afterdoc-saas-web/templates/CustomerChat/hooks/use-patient';
import { useHospitalAccount } from 'afterdoc-saas-web/templates/HospitalSetting/containers/AllAccountSetting/hooks/use-hospital-account';
import { useAtomValue } from 'jotai';
import { useCallback, useId, useMemo, useRef } from 'react';
import { WIDTH } from '../../constants/dropdown';
import { createTopPosition } from './functions/create-top-position';
import { displayAddNames } from './functions/display-add-names';
import { postChatroomCounselerAdd } from './functions/postChatroomCounselerAdd';
import { handleError, showToastMessage } from './functions/response-handle';
import { useRenderingPosition } from './hooks/use-rendering-position';
import { useToggleSelect } from './hooks/use-toggle-select';

type DropdownProps = {
  selectedName?: string;
  handleToggle: () => void;
  triggerPosition: { top: number; left: number };
  isOnMouseLeave: boolean;
  eachChattingRoomID: string;
};

export default function ChildDropdown({
  selectedName,
  handleToggle,
  triggerPosition,
  isOnMouseLeave,
  eachChattingRoomID,
}: DropdownProps) {
  const queryClient = useQueryClient();
  const id = useId();

  const hospitalInfos = useAtomValue(hospitalInfosAtom);

  const { top, left } = triggerPosition;

  const serviceManagers = hospitalInfos
    .map((hospitalInfo) => {
      if (hospitalInfo.nurseID) {
        return {
          id: hospitalInfo.nurseID._id ?? '',
          realName: '애프터닥',
        };
      }
      return undefined;
    })
    .filter((serviceManager) => serviceManager !== undefined) as { id: string; realName: string }[];

  const { userId: userID } = useUserInfo();
  const { name } = usePatient();

  const dropdownRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const { counselors, isLoading } = useChatRoomInfoOne({
    chatRoomID: eachChattingRoomID,
  });
  const newCounselors = counselors.map((counselor) => ({
    id: counselor._id,
    realName: counselor.realName,
  }));

  //담당자 추가 하는 후보들은 병원 모든 직원들
  const { fetchAccountListWithOutSuspense } = useHospitalAccount();
  const { data: accounts } = fetchAccountListWithOutSuspense();
  const newAccounts =
    accounts?.map((account) => ({
      id: account._id,
      realName: account.realName ?? '없음',
    })) ?? [];

  const accountsWithServiceManager =
    serviceManagers.length > 0 ? [...serviceManagers, ...newAccounts] : newAccounts;
  const counselorsWithServiceManager =
    serviceManagers.length > 0 ? [...serviceManagers, ...newCounselors] : newCounselors;

  const initialSelectedIDs = useMemo(() => {
    return counselorsWithServiceManager.map((counselor) => counselor.id);
  }, [counselorsWithServiceManager]);

  const { selectedUserIDs, toggleSelect, isInitialSelected, isSelected } =
    useToggleSelect(initialSelectedIDs);

  const user = counselorsWithServiceManager.find(({ id }) => id === userID);

  const newHworkders =
    (user === undefined
      ? accountsWithServiceManager
      : accountsWithServiceManager.filter(({ id }) => id !== userID)) ?? [];

  const dropdownHeight = useMemo(() => {
    return newHworkders.length > 0
      ? Math.min(32 * (newHworkders.length + (user ? 1 : 0)), 170)
      : 40;
  }, [newHworkders]);

  const { position } = useRenderingPosition({
    renderingRef: dropdownRef,
    renderingDomHeight: dropdownHeight,
  });

  useClickOutside({
    id,
    ref: dropdownRef,
    contentRef,
    onClose: handleToggle,
  });

  const onMouseLeaveOption = useCallback(() => {
    isOnMouseLeave && handleToggle();
  }, [isOnMouseLeave, handleToggle]);

  const handleConfirmClick = async (onlyAddIDs: string[]) => {
    try {
      const { code } = await postChatroomCounselerAdd({
        chatRoomID: eachChattingRoomID,
        onlyAddIDs,
      });
      const isSuccess = code === SUCCESS;

      showToastMessage(isSuccess);

      if (isSuccess) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiChatroomElFind],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiChatroomElFindOne],
        });

        modalService.popById('add-user-confirm-modal');
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleModal = (e: React.MouseEvent) => {
    e.stopPropagation();

    const onlyAddIDs = selectedUserIDs.filter((id) => !initialSelectedIDs.includes(id));

    modalService.push({
      id: 'add-user-confirm-modal',
      width: 384,
      titleProps: {
        title: '담당자를 추가할까요?',
      },
      contents: (
        <div className='w-full flex-col'>
          <span className='text-Body12 text-black500'>
            {selectedName ?? name}님 채팅방에 {displayAddNames(onlyAddIDs, accounts ?? [])}님을
            담당자로 추가합니다.
          </span>
          <div className='flex-center gap-8 pt-20 pb-15 '>
            <OutlinedButton
              className='flex-1'
              onClick={() => {
                modalService.pop();
              }}>
              취소
            </OutlinedButton>
            <ContainedButton
              className='flex-1'
              btnColor='blue'
              onClick={() => handleConfirmClick(onlyAddIDs)}>
              확인
            </ContainedButton>
          </div>
        </div>
      ),
    });
  };

  const isDisabled = useMemo(() => {
    return initialSelectedIDs.length === selectedUserIDs.length;
  }, [initialSelectedIDs, selectedUserIDs]);

  return (
    <div
      ref={dropdownRef}
      className='relative'
      style={{ zIndex: Z_INDEXS.ChattingTooltip }}
      onMouseLeave={onMouseLeaveOption}>
      {
        <div
          ref={contentRef}
          className={
            'absolute h-fit w-full select-none rounded-r6 bg-white50 py-4 pt-4 shadow-modal ring-1 ring-black ring-opacity-5'
          }
          data-ignore-click-outside={true}
          style={{
            top: createTopPosition({
              top,
              dropdownHeight: dropdownHeight,
              offset: 24 + (isLoading ? 0 : 26) + 10, // -(ParticipantsChip 높이 + 드롭다운 '추가'버튼 높이 + default 5+5)
              position,
            }),
            left,
            width: SHARED_UTILS.css.getCssSizeValue(WIDTH),
          }}>
          {isLoading ? (
            <div className='flex flex-center' style={{ height: dropdownHeight }}>
              <BaseLoading />
            </div>
          ) : (
            <div style={{ height: dropdownHeight }}>
              <Scrollbar disabledX>
                <ul aria-orientation='vertical' aria-labelledby='options-menu' className='relative'>
                  {user && (
                    <li
                      key={user.id}
                      className={customTwMerge(
                        'flex items-center justify-between px-10 py-7 text-Header12 text-disabled',
                      )}>
                      <span className='overflow-hidden text-ellipsis whitespace-nowrap'>{`${user.realName}(나)`}</span>
                      <Icon name='done' color='blue500' size={16} className={'flex-shrink-0 '} />
                    </li>
                  )}
                  {newHworkders.map(({ id, realName }, index) => (
                    <li
                      key={id}
                      tabIndex={index}
                      onClick={(e) => toggleSelect(e, id)}
                      className={customTwMerge(
                        'flex h-30 cursor-pointer items-center justify-between px-10 py-7 text-Body12 hover:bg-blue50 hover:text-Body12 focus:bg-blue50 focus:outline-none',
                        isSelected(id) ? 'text-Header12' : 'font-regular text-Body12',
                        isInitialSelected(id) && 'text-disabled',
                      )}
                      item-index={index}
                      aria-selected={isSelected(id) ? 'true' : undefined}>
                      <span className='overflow-hidden text-ellipsis whitespace-nowrap'>
                        {realName}
                      </span>
                      {isSelected(id) || isInitialSelected(id) ? (
                        <Icon name='done' color='blue500' size={16} className={'flex-shrink-0 '} />
                      ) : (
                        <div className='h-16 w-16' />
                      )}
                    </li>
                  ))}
                </ul>
              </Scrollbar>
            </div>
          )}
          {!isLoading && (
            <button
              disabled={isDisabled}
              type='button'
              onClick={handleModal}
              className={`flex h-26 w-full items-center justify-center text-Body12 ${
                isDisabled
                  ? 'cursor-not-allowed bg-white50 text-disabled'
                  : 'bg-white50 text-blue500 hover:bg-blue50'
              }`}>
              추가
            </button>
          )}
        </div>
      }
    </div>
  );
}
