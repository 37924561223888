import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiNoticeHospitalElFindSimpleParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';

const getNotice = async (query: ApiNoticeHospitalElFindSimpleParams) => {
  const { data } = await apiClient.v3.apiNoticeHospitalElFindSimple(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useNoticeList = ({ keyword }: { keyword?: string }) => {
  const { hospitalID } = useSelectedHospitalInfo();
  const query = {
    hospitalID,
    ...(keyword && { keyword }),
    isRemoved: false,
  };

  const { data, error, isLoading } = useQuery({
    queryKey: [QUERY_KEY.apiNoticeHospitalElFindSimple, query] as const,
    queryFn: ({ queryKey }) => getNotice(queryKey[1]),
  });

  const newData =
    data?.map(({ _id, title, createdAt }) => {
      return {
        id: _id ?? '',
        title,
        createdAt,
      };
    }) ?? [];

  return {
    data: newData,
    error,
    isLoading,
  };
};
