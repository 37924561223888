import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import type { TemporaryPatientsLastModifierHandlerBodyRequest } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { modifierInfoState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/modifier-info';
import { useSetAtom } from 'jotai';

const patchTemporaryPatientsLastModifier = async (
  params: TemporaryPatientsLastModifierHandlerBodyRequest,
) => {
  const response = await apiClient.v3.temporaryPatientsLastModifierHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useTemporaryPatientsModifier = () => {
  const setModifierInfo = useSetAtom(modifierInfoState);

  const patchTemporaryPatientsLastModifierMutation = useMutation({
    mutationFn: patchTemporaryPatientsLastModifier,
    onSuccess: (data) => {
      setModifierInfo({
        createdAt: data.createdAt,
        userID: data.userID,
      });
    },
  });

  return {
    patchTemporaryPatientsLastModifierMutation,
  };
};
