import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import AddInstagramAccount from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/InstagramForm/components/InstagramIntegrationWrapper/components/AddInstagramAccount';
import InstagramLinkageInfoAdjacent from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/InstagramForm/components/InstagramIntegrationWrapper/components/InstagramLinkageInfoAdjacent';
import InstagramLinkageStatusItem from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/InstagramForm/components/InstagramIntegrationWrapper/components/InstagramLinkageStatusItem/InstagramLinkageStatusItem';
import NoInstagramLinkData from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/InstagramForm/components/InstagramIntegrationWrapper/components/NoInstagramLinkData';
import { useRefreshInstagramLinkageHandler } from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/InstagramForm/components/InstagramIntegrationWrapper/hooks/use-refresh-instagram-linkage-handler';
import type { InstagramAccountsResType } from 'afterdoc-saas-web/templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/hooks/use-Linkage-history';

type InstagramIntegrationWrapperProps = {
  hospitalID: string;
  instagramAccounts: InstagramAccountsResType['instagramAccounts'];
  maximumInstagramAccountCount: number;
};

export default function InstagramIntegrationWrapper({
  hospitalID,
  instagramAccounts,
  maximumInstagramAccountCount,
}: InstagramIntegrationWrapperProps) {
  useRefreshInstagramLinkageHandler();

  const accountNum = instagramAccounts.length;
  const isExistInstagramLinkData = accountNum !== 0;

  return (
    <div className='w-full flex-col gap-20'>
      <>
        <Title
          title='연동정보'
          wrapperClassName={'px-0 pt-0 mt-8'}
          trailingChildren={<AddInstagramAccount />}
          adjacentChildren={{
            position: 'right',
            children: (
              <InstagramLinkageInfoAdjacent
                usingNum={accountNum}
                totalNum={maximumInstagramAccountCount ?? accountNum}
              />
            ),
            className: 'gap-2',
          }}
        />
        <p className='text-Body11 text-black200'>
          연동된 계정의 비밀번호가 변경되거나 첫 연동 후 60일 마다 재로그인을 진행해야 DM을 수신받을
          수 있습니다.
        </p>
      </>
      <div className='mt-8 flex w-full flex-col gap-8'>
        {!isExistInstagramLinkData && <NoInstagramLinkData />}

        {isExistInstagramLinkData &&
          instagramAccounts.map(
            ({ userName, fullName, reAuthorizationType, reAuthorizationReason }) => {
              const status = reAuthorizationType ? 'error' : 'linkage';

              return (
                <InstagramLinkageStatusItem
                  key={userName}
                  hospitalID={hospitalID}
                  name={userName}
                  user={fullName}
                  status={status}
                  reAuthorizationReason={reAuthorizationReason}
                />
              );
            },
          )}
      </div>
    </div>
  );
}
