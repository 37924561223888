import type { IntegrationChannelItem } from '@templates/HospitalSetting/containers/ChannelIntegration/types/intergration-channel-item';

export const CHANNEL_INTEGRATION_LIST: IntegrationChannelItem[] = [
  {
    key: 'hasKakaoAlimAndFriendTalkLinkage',
    icon: 'kakaotalk',
    title: '카카오 알림톡',
    description: '알림톡/친구톡으로 상담자동화 메시지를 보낼 수 있습니다.',
    linkageType: 'ALIM_TALK',
  },
  {
    key: 'hasKakaoConsultationTalkLinkage',
    icon: 'kakaotalk',
    title: '카카오 상담톡',
    description: '카카오 채널로 문의한 고객과 메시지를 주고 받을 수 있습니다.',
    linkageType: 'CONSULTATION_TALK',
  },
  {
    key: 'hasInstagramLinkage',
    icon: 'instagram',
    title: '인스타그램',
    description: '인스타그램으로 문의한 고객과 DM을 주고 받을 수 있습니다.',
    linkageType: 'INSTAGRAM',
  },
] as const;
