import type {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/query-core';
import type { ChatFileAndContentIDs } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { debounce } from 'lodash-es';
import { type MutableRefObject, useEffect } from 'react';

type UseTopScrollObserverProps = {
  fetchPreviousPage:
    | (() => Promise<void>)
    | ((
        options?: FetchNextPageOptions,
      ) => Promise<
        InfiniteQueryObserverResult<InfiniteData<ChatFileAndContentIDs[], unknown>, Error>
      >);
  isLoading: boolean;
  chatContainerRef: MutableRefObject<HTMLDivElement | null>;
  loadPreviousMoreRef: MutableRefObject<HTMLDivElement | null>;
  setPreviousScrollHeight: React.Dispatch<React.SetStateAction<number>>;
};

// 아래의 부분은 .withDebounce를 사용하기 위한 실험적 Function prototype 확장
declare global {
  interface Function {
    withDebounce(options: { wait: number }): Function;
    withSetTimeout(delay: number): void;
  }
}

const extendFunctionPrototypeForDebounce = () => {
  if (!Function.prototype.withDebounce) {
    Function.prototype.withDebounce = function ({ wait = 1000 }) {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      return debounce((...args: any[]) => {
        return this.apply(this, args);
      }, wait);
    };
  }
  if (!Function.prototype.withSetTimeout) {
    Function.prototype.withSetTimeout = function (delay) {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      return setTimeout((...args: any[]) => {
        this.apply(this, args);
      }, delay);
    };
  }
};

extendFunctionPrototypeForDebounce();

//참고로 스크롤의 값들은 뒤집어져서 위로 올릴수록 0부터 -로 절대값은 커지고 값은 작아지는 환경
export const useTopScrollObserver = ({
  fetchPreviousPage,
  isLoading,
  chatContainerRef,
  loadPreviousMoreRef,
  setPreviousScrollHeight,
}: UseTopScrollObserverProps) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        for (const entry of entries) {
          const container = chatContainerRef.current;
          if (container) {
            if (entry.target === loadPreviousMoreRef.current) {
              if (entry.isIntersecting && !isLoading) {
                setPreviousScrollHeight(container.scrollHeight);

                await fetchPreviousPage.withDebounce({ wait: 100 })();
              }
            }
          }
        }
      },
      {
        root: chatContainerRef.current,
        rootMargin: '50px 0px 0px 0px', //뒤집어져있기 때문에 상단에 50px만큼 여유를 줌
        threshold: 0.5,
      },
    );

    if (loadPreviousMoreRef.current) {
      observer.observe(loadPreviousMoreRef.current);
    }

    return () => {
      if (loadPreviousMoreRef.current) {
        observer.unobserve(loadPreviousMoreRef.current);
        observer.disconnect();
      }
    };
  }, [
    isLoading,
    chatContainerRef.current,
    loadPreviousMoreRef.current,
    // fetchPreviousPage,//수시로 바뀌는 함수라서 의존성에 넣지 않음
    setPreviousScrollHeight,
    fetchPreviousPage.withDebounce,
  ]);
};
