import type { StatusType } from '@templates/HospitalManual/components/HospitalManualContent/components/ManualCautionMessage/types/status-type';

interface TagStatusConfig {
  label: string;
  bgColor?: string;
  textColor?: string;
  message: string;
  showTooltip: boolean;
  showLink: boolean;
}

export const TAG_STATUS_CONFIG: Record<StatusType, TagStatusConfig> = {
  requested: {
    label: '검수신청',
    bgColor: '#EBEBEB',
    textColor: '#222222',
    message:
      '알림톡 검수가 진행중입니다. 승인이 완료되기 전까지 애프터닥 앱에 가입한 사람들에게만 메시지가 전송됩니다.',
    showTooltip: false,
    showLink: false,
  },
  approved: {
    label: '사용가능',
    bgColor: '#B0D6FF',
    textColor: '#007AFF',
    message:
      '알림톡 승인이 완료된 메시지입니다. 내용을 변경하시는 경우, 알림톡 검수가 완료되기 전까지 애프터닥 앱에 가입한 사람들에게만 메시지가 전송됩니다.',
    showTooltip: false,
    showLink: false,
  },
  rejected: {
    label: '반려',
    bgColor: '#F8B0B0',
    textColor: '#F05454',
    message:
      '알림톡 검수 결과 반려된 메시지입니다. 검수 신청을 다시 하시려면 내용을 변경하신 후 다시 저장해 주세요.',
    showTooltip: true,
    showLink: false,
  },
  blocked: {
    label: '차단',
    bgColor: '#F8B0B0',
    textColor: '#F05454',
    message: '알림톡 발송이 불가합니다. 해당 상태가 표시되는 경우, 애프터닥으로 문의해주세요.',
    showTooltip: true,
    showLink: false,
  },
  dormant: {
    label: '휴면',
    bgColor: '#F8B0B0',
    textColor: '#F05454',
    message: '알림톡 발송이 불가합니다. 해당 상태가 표시되는 경우, 애프터닥으로 문의해주세요.',
    showTooltip: false,
    showLink: true,
  },
};
