import { useEffect, useState } from 'react';

export default function TaesungTestPage() {
  const clientId = '478312678670059';
  const redirectUri = 'https://dev.frontend.afterdoc.net/taesung-test';

  const authUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=instagram_business_basic,instagram_business_manage_messages`;
  const handleLogin = () => {
    window.location.href = authUrl;
  };

  const [code, setCode] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    setCode(String(code));
  }, []);

  return (
    <div>
      <h1>Instagram 로그인</h1>
      {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
      <button onClick={handleLogin}>Instagram으로 로그인</button>
      <div>code:{code}</div>
    </div>
  );
}
