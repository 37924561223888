import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import type { InstagramDisconnectionHandlerBodyRequest } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { SHARED_UTILS } from 'utils/utils';

const disconnectedInstagramAccount = async (params: InstagramDisconnectionHandlerBodyRequest) => {
  const { data } = await apiClient.v3.instagramDisconnectionHandler(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};
const statusStyles = {
  errorStyle: {
    bg: 'bg-red50',
    border: 'border-red500',
  },
  linkageStyle: {
    bg: 'bg-white50',
    border: 'border-white500',
  },
};

type InstagramLinkageStatusItemProps = {
  hospitalID: string;
  name: string;
  user: string | undefined;
  status: 'error' | 'linkage';
  reAuthorizationReason?: string;
};

export default function InstagramLinkageStatusItem({
  hospitalID,
  name,
  user,
  status,
  reAuthorizationReason,
}: InstagramLinkageStatusItemProps) {
  const styleKey = `${status}Style` as keyof typeof statusStyles;
  const styles = statusStyles[styleKey] || statusStyles.errorStyle;

  const handleConfirm = async (e?: React.MouseEvent) => {
    e?.stopPropagation();
    const { isDisconnectedSuccess } = await disconnectedInstagramAccount({
      hospitalID,
      userName: name,
    });

    if (isDisconnectedSuccess) {
      toastService.successMsg({ text: '연동이 해제되었습니다.' });
      modalService.pop();
    } else {
      toastService.errorMsg({ text: '연동이 해제를 실패했습니다.' });
    }
  };

  const handleCancel = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    modalService.pop();
  };

  const handleUnLink = () => {
    modalService.defaultWarning({
      title: '연동 해제',
      contents: (
        <>
          선택하신 인스타그램 계정 연동을 해제합니다. <br />
          해제 후 해당 계정의 DM을 확인할 수 없습니다.
        </>
      ),
      onConfirm: handleConfirm,
      onCancel: handleCancel,
    });
  };

  const isReAuthorizationReason = !!reAuthorizationReason;

  return (
    <div
      className={`flex min-h-88 max-w-[440px] flex-row items-center justify-between border ${styles.border} ${styles.bg} gap-5 rounded-2xl px-16 py-10`}>
      <div className='flex min-w-0 flex-1 flex-col gap-4'>
        <h2 className='truncate text-Header14 text-black700'>{`${name}`}</h2>
        <p className='truncate text-Body12 text-black500'>{user ?? '-'}</p>
        {isReAuthorizationReason && (
          <p className='whitespace-normal break-words font-medium text-Body11 text-red500'>{`*${reAuthorizationReason}`}</p>
        )}
      </div>
      <OutlinedButton btnColor='secondary' onClick={handleUnLink} className='w-64'>
        해제
      </OutlinedButton>
    </div>
  );
}
