import { userIDAtom, userInfoAtom, userTypeAtom } from 'afterdoc-saas-web/states/token';
import { useAtomValue } from 'jotai';

export const useUserInfo = () => {
  const userInfo = useAtomValue(userInfoAtom);
  const userId = useAtomValue(userIDAtom);
  const userType = useAtomValue(userTypeAtom);

  return {
    userId: userId,
    userType: userType,
    ...userInfo,
  };
};
