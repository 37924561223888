import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';

export default function CommonKakaoDescription({
  channel,
  removeOverlay,
}: { channel: '상담톡' | '알림톡'; removeOverlay: () => void }) {
  return (
    <>
      <span className='text-Header14 text-black500'>{`카카오 ${channel} (유료)`}</span>
      <span className='mt-12 whitespace-pre-wrap text-center text-Body12 text-black500'>
        {channel} 연동은 카카오 <span className='text-red500'>비즈니스 채널 승인 완료 후 신청</span>
        할 수 있습니다.
        <br />
        신청을 원하시면 아래 버튼을 클릭해 주세요.
      </span>
      <ContainedButton btnColor='blue' className='mt-20' onClick={removeOverlay}>
        연동신청
      </ContainedButton>
    </>
  );
}
