import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import EditorViewer from 'afterdoc-saas-web/shared/components/EditorViewer/EditorViewer';
import { useManualSingleData } from '../../hooks/use-manual-single-data';
import CautionImagesSelect from './components/CautionImaagesSelect/CautionImagesSelect';
import { NoHTMLData } from './components/NoHTMLData';

export default function TagDetailPanel({ treatmentTagId }: { treatmentTagId: string }) {
  const {
    data: { manualContent },
    isLoading,
  } = useManualSingleData({ treatmentTagId });

  return (
    <div className='flex flex-col gap-16'>
      <div className='mx-20'>
        {!manualContent && isLoading ? (
          <div className='flex h-[222px] flex-center'>
            <BaseLoading />
          </div>
        ) : manualContent ? (
          <EditorViewer htmlContent={manualContent} />
        ) : (
          <NoHTMLData />
        )}
        <Divider type='line' className='my-16' />
        <span className='text-Header14 text-black500 '>주의사항 이미지</span>
      </div>
      <CautionImagesSelect treatmentTagId={treatmentTagId} />
    </div>
  );
}
