import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { AuthorizationTypeForV3 } from '@apis/swaggers/swagger-docs';
import { useSuspenseQuery } from '@tanstack/react-query';
import AuthorizationSettingSaveButton from '@templates/HospitalSetting/containers/AuthorizationSetting/components/AuthorizationSettingSaveButton';
import AuthorizationSettingTitle from '@templates/HospitalSetting/containers/AuthorizationSetting/components/AuthorizationSettingTitle';
import AuthorizationTypeDetailSettingContainer from '@templates/HospitalSetting/containers/AuthorizationSetting/containers/AuthorizationTypeDetailSetting/AuthorizationTypeDetailSetting.container';
import AuthorizationTypeSelectionContainer from '@templates/HospitalSetting/containers/AuthorizationSetting/containers/AuthorizationTypeSelection/AuthorizationTypeSelection.container';
import {
  hospitalAuthorizationTypesState,
  hospitalInitialAuthorizationTypesState,
} from '@templates/HospitalSetting/containers/AuthorizationSetting/jotai/hospital-authorization-types';
import { selectAuthorizationTypeIndex } from '@templates/HospitalSetting/containers/AuthorizationSetting/jotai/select-authorization-type-index';
import { hospitalSettingsModifiedState } from '@templates/HospitalSetting/states/hospital-settings-modified-state';
import { useNavigationBlocker } from 'afterdoc-saas-web/shared/hooks/gnb/use-navigation-blocker';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useAtom } from 'jotai';
import { useSetAtom } from 'jotai/index';
import { isEqual } from 'lodash-es';
import { useEffect, useRef } from 'react';
import { SHARED_UTILS } from 'utils/utils';

type HospitalsAuthorizationTypeElFindParams = Parameters<
  typeof apiClient.v3.apiHospitalsAuthorizationTypeElFind
>[0];

const fetchAuthorizationTypes = async (params: HospitalsAuthorizationTypeElFindParams) => {
  const response = await apiClient.v3.apiHospitalsAuthorizationTypeElFind(params);
  return SHARED_UTILS.api.checkApiResponse<AuthorizationTypeForV3[]>(response.data);
};

export default function AuthorizationContainer() {
  const { hospitalID } = useSelectedHospitalInfo();
  const [hospitalAuthorizationTypes, setHospitalAuthorizationTypes] = useAtom(
    hospitalAuthorizationTypesState,
  );
  const [initAuthorizationTypes, setInitAuthorizationTypes] = useAtom(
    hospitalInitialAuthorizationTypesState,
  );
  const [hospitalSettingsModified, setHospitalSettingsModified] = useAtom(
    hospitalSettingsModifiedState,
  );
  const setSelectAuthorizationTypeIndex = useSetAtom(selectAuthorizationTypeIndex);

  const isFirstRender = useRef(true);

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiHospitalsAuthorizationTypeElFind, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchAuthorizationTypes(queryKey[1]),
  });

  useEffect(() => {
    if (data) {
      setHospitalAuthorizationTypes(data);
      setInitAuthorizationTypes(data);
      if (isFirstRender.current) {
        isFirstRender.current = false;
        const index = data.findIndex((type) => type.name === '마스터');
        setSelectAuthorizationTypeIndex(index >= 0 ? index : 0);
      }
    }
  }, [data]);

  useEffect(() => {
    if (initAuthorizationTypes && hospitalAuthorizationTypes) {
      const isEquals = isEqual(initAuthorizationTypes, hospitalAuthorizationTypes);
      setHospitalSettingsModified(!isEquals);
    }
  }, [initAuthorizationTypes, hospitalAuthorizationTypes]);

  useNavigationBlocker({
    shouldBlock: hospitalSettingsModified,
  });

  return (
    <div className='h-full bg-white50 px-80 pt-40'>
      <AuthorizationSettingTitle />
      <div className='px-20'>
        <AuthorizationTypeSelectionContainer />
        <AuthorizationTypeDetailSettingContainer />
        <AuthorizationSettingSaveButton />
      </div>
    </div>
  );
}
