import { useMutation } from '@tanstack/react-query';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import type { PatientSavingRequest } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { isCustomerManagementSaveLockState } from 'afterdoc-saas-web/hooks/push-notification/states/is-customer-management-save-lock';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';

const postPatientsInfo = async (params: PatientSavingRequest) => {
  const response = await apiClient.v3.apiPatientsEl(params);
  return response.data;
};

export const useSaveUser = () => {
  const { hospitalID } = useSelectedHospitalInfo();

  const resetIsCustomerManagementSaveLock = useResetAtom(isCustomerManagementSaveLockState);

  const setIsCustomerManagementSaveLock = useSetAtom(isCustomerManagementSaveLockState);

  const postSaveFinalUserInfos = useMutation({
    mutationFn: (params: PatientSavingRequest) => postPatientsInfo(params),
    onSuccess: async (data) => {
      if (data.code === 0) {
        setIsCustomerManagementSaveLock((prev) => ({
          ...prev,
          count: data.data.totalTargetCount,
        }));
      }

      // 동시에 여러번 저장하는 경우 처리
      if (data.code === 40901) {
        toastService.errorMsg({
          text: data.message,
        });
        return;
      }

      // 처리할 데이터가 없는 경우
      if (data.code === 40401) {
        toastService.errorMsg({
          text: '업데이트할 고객정보가 없습니다.',
        });
        return;
      }
    },
    onError: () => {
      resetIsCustomerManagementSaveLock();
      toastService.errorMsg({
        text: '일시적인 오류가 발생하여 업데이트에 실패하였습니다. 다시 시도해 주세요.',
      });
    },
  });

  const handleSaveUserInfoFinally = async () => {
    await postSaveFinalUserInfos.mutateAsync({
      hospitalID,
    });
  };

  return {
    handleSaveUserInfoFinally,
  };
};
