import { apiClient } from '@apis/instances/api-client';
import type {
  InstagramAccountsHandlerData,
  InstagramAccountsHandlerParams,
  KakaoLatestLinkageHistoryHandlerData,
  KakaoLatestLinkageHistoryHandlerParams,
} from '@apis/swaggers/swagger-docs';
import { useSuspenseQuery } from '@tanstack/react-query';
import type { LinkageType } from '@templates/HospitalSetting/containers/ChannelIntegration/types/linkage-type';
import { neverCheck } from 'afterdoc-saas-web/shared/utils/never-check';
import { SHARED_UTILS } from 'utils/utils';

export type KakaoLatestLinkageHistoryResType = KakaoLatestLinkageHistoryHandlerData['data'];
export type InstagramAccountsResType = InstagramAccountsHandlerData['data'];

export type LinkageHistoryResult =
  | KakaoLatestLinkageHistoryResType
  | InstagramAccountsResType
  | null;

const fetchKakaoLatestLinkageHistory = async (params: KakaoLatestLinkageHistoryHandlerParams) => {
  try {
    const { data } = await apiClient.v3.kakaoLatestLinkageHistoryHandler(params);
    return SHARED_UTILS.api.checkApiResponse<KakaoLatestLinkageHistoryResType>(data);
  } catch (error) {
    if (SHARED_UTILS.api.isApiErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

const fetchInstagramAccounts = async (params: InstagramAccountsHandlerParams) => {
  const { data } = await apiClient.v3.instagramAccountsHandler(params);
  return SHARED_UTILS.api.checkApiResponse<InstagramAccountsResType>(data);
};

type UseLinkageHistoryParams = {
  linkageType: LinkageType;
  hospitalID: string;
};

export const INTEGRATION_ALL_LINKAGE_HISTORY_HANDLER = 'IntegrationAllLinkageHistoryHandler';

export function useLinkageHistory({ linkageType, hospitalID }: UseLinkageHistoryParams) {
  return useSuspenseQuery<LinkageHistoryResult>({
    //linkageTyp에 따라 여러 API 통합에 따른 key 커스텀
    queryKey: [INTEGRATION_ALL_LINKAGE_HISTORY_HANDLER, { linkageType, hospitalID }],
    queryFn: ({ queryKey }) => {
      const { linkageType, hospitalID } = queryKey[1] as UseLinkageHistoryParams;

      switch (linkageType) {
        case 'ALIM_TALK':
        case 'CONSULTATION_TALK':
          return fetchKakaoLatestLinkageHistory({ hospitalID, linkageType });
        case 'INSTAGRAM':
          return fetchInstagramAccounts({ hospitalID });
        default:
          linkageType satisfies never;
          neverCheck(linkageType);
          return null;
      }
    },
  });
}
