import type { ApiPatientsElTemporaryListOrSearchParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { atomWithReset } from 'jotai/utils';

export interface UnTaggedHeaderFilterState {
  isFirstVisit: ApiPatientsElTemporaryListOrSearchParams['isFirstVisit'] | 'all';
  countryCode: ApiPatientsElTemporaryListOrSearchParams['countryCode'];
  gender: ApiPatientsElTemporaryListOrSearchParams['gender'] | 'all';
  treatmentCategories: ApiPatientsElTemporaryListOrSearchParams['treatmentCategories'];
  nationality: ApiPatientsElTemporaryListOrSearchParams['nationality'];
}

export const unTaggedHeaderFilterState = atomWithReset<UnTaggedHeaderFilterState>({
  isFirstVisit: undefined,
  countryCode: undefined,
  gender: undefined,
  treatmentCategories: undefined,
  nationality: undefined,
});
