import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiPatientsElDetailParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { chatRoomIDSelector, chatRoomParentHServiceIDSelector } from '../states/selected-chat-room';

export const getPatient = async (query: ApiPatientsElDetailParams) => {
  const { data } = await apiClient.v3.apiPatientsElDetail(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const usePatient = () => {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const chatRoomParentHServiceID = useAtomValue(chatRoomParentHServiceIDSelector);
  const query = useMemo(
    () => ({
      patientId: chatRoomParentHServiceID,
    }),
    [chatRoomParentHServiceID],
  );
  const { data, error, isLoading } = useQuery({
    queryKey: [QUERY_KEY.apiPatientsElDetail, query] as const,
    queryFn: ({ queryKey }) => getPatient(queryKey[1]),
    enabled: !!chatRoomParentHServiceID && !!chatRoomID,
  });

  const {
    name,
    patientId,
    isFirstVisit,
    chartNumber,
    isJoinedUserToAfterDoc,
    hasKakaoLinkage,
    gender,
    birthdate,
    phoneNumber,
    countryCode,
    treatmentTags,
    nationality,
    userID,
    linkType,
    closeReason,
    automationAppliedTreatmentTag,
  } = data || {};

  return {
    isFirstVisit,
    patientId,
    name: name ?? '',
    chartNumber,
    isJoinedUserToAfterDoc,
    hasKakaoLinkage,
    gender,
    birthdate,
    phoneNumber,
    countryCode,
    treatmentTags,
    nationalityKoreanCountryName: nationality?.koreanCountryName,
    userID,
    error,
    isLoading,
    linkType,
    closeReason,
    automationAppliedTreatmentTag,
  };
};
