import { CUSTOMER_CHAT_ERROR } from 'afterdoc-saas-web/templates/CustomerChat/components/ChattingRoom/constants/customer-chat-error-text';

const NORMAL_CHATTING_PLACEHOLDER = '메시지를 입력해주세요.';
const CHATTING_CLOSED_PLACEHOLDER =
  '상담 종료한 채팅방입니다.\n상담을 진행하시려면 메시지를 입력해주세요.';

type ChattingSendingPlaceholderParams = {
  isSettingBottomTranslateLanguage: boolean;
  isUsageHaltedDisabledSending: boolean;
  code: string | null;
  isCounselInProgress: boolean;
};

export const chattingSendingPlaceholder = ({
  isSettingBottomTranslateLanguage,
  isUsageHaltedDisabledSending,
  code,
  isCounselInProgress,
}: ChattingSendingPlaceholderParams) => {
  if (isSettingBottomTranslateLanguage) return '';

  if (isUsageHaltedDisabledSending) {
    if (code === CUSTOMER_CHAT_ERROR.KAKAO.EXPIRED.CODE)
      return CUSTOMER_CHAT_ERROR.KAKAO.EXPIRED.TEXT;

    return CUSTOMER_CHAT_ERROR.AFTERDOC.USAGE_HALTED_DISABLED_SENDING.TEXT;
  }

  if (isCounselInProgress) {
    return NORMAL_CHATTING_PLACEHOLDER;
  }
  return CHATTING_CLOSED_PLACEHOLDER;
};
