import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { MarketingAlgorithmTargetCountHandlerParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import LayoutBox from 'afterdoc-saas-web/shared/components/LayoutBox/LayoutBox';
import SuspenseWithLoading from 'afterdoc-saas-web/shared/components/SuspenseWithLoading/SuspenseWithLoading';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import type { MarketingAutomationAPIFormValues } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import AlgorithmApplyTargetSetting from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingBaseSetting/components/Setting/AlgorithmApplyTargetSetting';
import MarketingAlgorithmNameSetting from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingBaseSetting/components/Setting/MarketingAlgorithmNameSetting';
import NationalitySetting from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingBaseSetting/components/Setting/NationalitySetting';
import TreatmentTagSetting from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingBaseSetting/components/Setting/TreatmentTagSetting';
import { isNationalitySelectBoxOpenedState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingBaseSetting/states/is-nationality-select-box-opened';
import { selectedMarketingAutomationTabState } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/states/selected-marketing-automation-tab-state';
import NextButton from 'afterdoc-saas-web/templates/Automation/containers/shared/components/Buttons/NextButton';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export default function MarketingBaseSettingContainer() {
  return (
    <div className='h-[calc(100vh-140px)] w-full'>
      <Scrollbar>
        <div className='mx-auto mt-40 w-[700px] rounded-r16 bg-white50 px-40 py-20 shadow-modal'>
          {/* 마케팅 - 자동화명 설정 */}
          <LayoutBox noStyle>
            <MarketingAlgorithmNameSetting />
          </LayoutBox>

          <Divider type='line' className='my-20' />

          {/* 상담 - 자동화 적용 대상 설정 */}
          <LayoutBox noStyle>
            <AlgorithmApplyTargetSetting />
          </LayoutBox>

          {/* 치료태그 설정 */}
          <SuspenseWithLoading type='custom' className='h-[300px] flex-w-full-center bg-white50'>
            <TreatmentTagSetting />
          </SuspenseWithLoading>

          {/* 국적 설정 */}
          <Suspense fallback={<NationalitySettingSkeleton />}>
            <NationalitySetting />
          </Suspense>

          {/* 이전 다음 버튼 */}
          <LayoutBox direction='vertical' justify='center' width='100%' className='mt-20' size={6}>
            <NextButtonWithAppliedCount />
          </LayoutBox>
        </div>
      </Scrollbar>
    </div>
  );
}

const NationalitySettingSkeleton = () => {
  const isNationalitySelectBoxOpened = useAtomValue(isNationalitySelectBoxOpenedState);

  return (
    <div className='mt-16 rounded-r10 border border-white600 bg-white200 p-10'>
      <button type='button' className='flex w-full justify-between'>
        <div className='text-Header12 text-black500'>국적 (선택)</div>
        <Icon
          name={isNationalitySelectBoxOpened ? 'chevron-up' : 'chevron-down'}
          color='black200'
        />
      </button>
      {isNationalitySelectBoxOpened && (
        <div>
          <LabelText textClassName='text-Body10Bold mt-10'>국적</LabelText>
          <div className='h-[250px] flex-center rounded-r10 border border-black200 bg-white50 py-4 pr-4 pl-16'>
            <BaseLoading />
          </div>
        </div>
      )}
    </div>
  );
};

const NextButtonWithAppliedCount = () => {
  const { watch } = useFormContext<MarketingAutomationAPIFormValues>();

  const setSelectedAutomationTab = useSetAtom(selectedMarketingAutomationTabState);

  const name = watch('name');
  const targetTreatmentTags = watch('targetTreatmentTags');
  const isForNoTreatmentTags = watch('isForNoTreatmentTags');

  const onChangeTab = () => {
    if (!name?.length || (!targetTreatmentTags?.length && !isForNoTreatmentTags)) {
      return toastService.errorMsg({
        text: '현재 페이지의 필수정보를 모두 입력해 주세요.',
      });
    }

    setSelectedAutomationTab(1);
  };

  return (
    <>
      <Suspense>
        <TargetPatientCount />
      </Suspense>
      <LayoutBox noStyle>
        <NextButton className='mx-auto w-[200px]' onClick={onChangeTab} />
      </LayoutBox>
    </>
  );
};

const fetchFilteredTargetCount = async (params: MarketingAlgorithmTargetCountHandlerParams) => {
  const response = await apiClient.v3.marketingAlgorithmTargetCountHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const TargetPatientCount = () => {
  const { watch } = useFormContext<MarketingAutomationAPIFormValues>();
  const targetTreatmentTags = watch('targetTreatmentTags');
  const toBeAppliedNationalityIds = watch('toBeAppliedNationalityIds');
  const isForNoTreatmentTags = watch('isForNoTreatmentTags');
  const isForNoNationality = watch('isForNoNationality');

  const { hospitalID } = useSelectedHospitalInfo();

  const { data, isLoading } = useQuery({
    queryKey: [
      QUERY_KEY.marketingAlgorithmTargetCountHandler,
      {
        hospitalID,
        treatmentTagIds:
          targetTreatmentTags && targetTreatmentTags.length > 0
            ? targetTreatmentTags.join(',')
            : undefined,
        nationalityIds:
          toBeAppliedNationalityIds && toBeAppliedNationalityIds.length > 0
            ? toBeAppliedNationalityIds.join(',')
            : undefined,

        isForNoTreatmentTags,
        isForNoNationality,
      },
    ] as const,
    enabled: Boolean(
      (targetTreatmentTags && targetTreatmentTags.length > 0) || isForNoTreatmentTags,
    ),
    queryFn: ({ queryKey }) => fetchFilteredTargetCount(queryKey[1]),
  });

  useEffect(() => {
    if (!data && isLoading) {
      fullDimmedLoadingService.on();
    } else {
      fullDimmedLoadingService.off();
    }
  }, [data, isLoading]);

  return (
    <div className='flex items-center justify-center gap-10'>
      <div className='text-Header12 text-blue500'>
        {(data?.targetCount ?? '0').toLocaleString()}명 적용
      </div>
    </div>
  );
};
