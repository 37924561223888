import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useQueryClient } from '@tanstack/react-query';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiPatientsElDetailData } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { patientIdState } from 'afterdoc-saas-web/states/patient-id';
import { reservationSelectedDateState } from 'afterdoc-saas-web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/states/date';
import dayjs from 'dayjs';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import ReservationHistoryDatePickerContainer from '../containers/ReservationHistoryDatePicker.container';
import { PERIOD_OPTIONS } from '../containers/constants/period-options';
import { periodTabState } from '../containers/states/period-tab';

export default function ReservationHistoryTitleAndSubTextWithDatePicker() {
  const queryClient = useQueryClient();

  const patientId = useAtomValue(patientIdState);
  const { patientId: patientIdFromQuery } = queryClient.getQueryData([
    QUERY_KEY.apiPatientsElDetail,
    { patientId },
  ]) as ApiPatientsElDetailData['data'];
  const [selectedTab, setSelectedTab] = useAtom(periodTabState);

  const setReservationSelectedDate = useSetAtom(reservationSelectedDateState);

  return (
    <div className='flex w-full flex-row items-center justify-between'>
      <div className='flex flex-col gap-8'>
        <div className='text-Header14 text-black500'>예약내역</div>
        <div className='flex flex-row items-center gap-6'>
          <div className='text-Body12 text-black500'>
            취소된 예약은 <span className='text-red500'>빨간색 글씨</span>로 표시됩니다.
          </div>
        </div>
      </div>
      <div className='flex flex-row items-center gap-20'>
        <div className='flex flex-row p-6'>
          {PERIOD_OPTIONS.map((option, index) => (
            <button
              key={option}
              type='button'
              className={customTwMerge(
                'h-fit w-fit select-none rounded-r6 px-14 py-4 text-Body11',
                PERIOD_OPTIONS.findIndex((option) => option === selectedTab) === index
                  ? 'bg-blue50 text-blue500'
                  : 'bg-white50 text-white800',
              )}
              onClick={() => {
                setSelectedTab(option);

                if (option === '연간') {
                  setReservationSelectedDate({
                    startedAt: dayjs().startOf('year').startOf('day').toDate(),
                    endedAt: dayjs().endOf('year').endOf('day').toDate(),
                  });

                  return;
                }

                if (option === '월간') {
                  setReservationSelectedDate({
                    startedAt: dayjs().startOf('month').startOf('day').toDate(),
                    endedAt: dayjs().endOf('month').endOf('day').toDate(),
                  });

                  return;
                }

                if (option === '주간') {
                  setReservationSelectedDate({
                    startedAt: dayjs().startOf('week').startOf('day').toDate(),
                    endedAt: dayjs().endOf('week').endOf('day').toDate(),
                  });

                  return;
                }

                if (selectedTab === '일간') {
                  setReservationSelectedDate({
                    startedAt: dayjs().startOf('day').startOf('day').toDate(),
                    endedAt: dayjs().endOf('day').endOf('day').toDate(),
                  });

                  return;
                }
              }}>
              {option}
            </button>
          ))}
        </div>
        <ReservationHistoryDatePickerContainer />
        <ContainedButton
          onClick={() => {
            if (window.electron === undefined) {
              alert('앱에서 실행해야 하는 기능입니다.');
            }
            window.electron?.ipcRenderer.send('Application.showReservationWindow', {
              value: { hserviceID: patientIdFromQuery },
            });
          }}
          buttonSize='small'>
          예약등록
        </ContainedButton>
      </div>
    </div>
  );
}
