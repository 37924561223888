import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type {
  LinkedChatroomElFindHandlerParams,
  LinkedChatroomElFindHandlerResponse,
  LinkedChatroomElUnlinkByChatroomHandlerBodyRequest,
  LinkedChatroomElUnlinkHandlerBodyRequest,
} from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/info/use-selected-hospital-info';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { SHARED_UTILS } from 'utils/utils';

export type LinkedChatroomList = LinkedChatroomElFindHandlerResponse['data'];

const fetchLinkedChatRooms = async (param: LinkedChatroomElFindHandlerParams) => {
  const { data } = await apiClient.v3.linkedChatroomElFindHandler(param);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const requestUnLinkChatRoom = async (param: LinkedChatroomElUnlinkHandlerBodyRequest) => {
  const { data } = await apiClient.v3.linkedChatroomElUnlinkHandler(param);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const requestUnLinkChatRoomByChatRoom = async (
  param: LinkedChatroomElUnlinkByChatroomHandlerBodyRequest,
) => {
  const { data } = await apiClient.v3.linkedChatroomElUnlinkByChatroomHandler(param);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useLinkedChatRoom = (hserviceID: string) => {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();

  const { data, isLoading, isRefetching, isError, refetch } = useQuery({
    queryKey: [
      QUERY_KEY.linkedChatroomElFindHandler,
      {
        hospitalID,
        hserviceID,
        isRemoved: false,
      },
    ] as const,
    queryFn: ({ queryKey }) => fetchLinkedChatRooms(queryKey[1]),
    placeholderData: [],
    gcTime: 0,
    staleTime: 0,
  });

  //정책적으로 elFindLinkedChatroomsHandler는 캐싱을 사용하지 않으나 해당 팝업의 경우 화면 최신화가 되지 않아 강제로 업뎃 필요
  const invalidateLinkedChatRooms = () => {
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEY.elFindLinkedChatroomsHandler,
        {
          hserviceID,
        },
      ],
    });
  };

  const unlinkChatRoomMutation = useMutation({
    mutationFn: requestUnLinkChatRoom,
    onSuccess: () => {
      refetch();
      toastService.successMsg({ text: '채팅방 연동이 해제되었습니다.' });
      invalidateLinkedChatRooms();
    },
    onError: (error: Error) => {
      console.error('Failed to unlink chat room:', error);
      toastService.errorMsg({ text: '채팅방 연동 해제를 실패하였습니다.' });
    },
  });

  const unlinkChatRoomByChatRoomMutation = useMutation({
    mutationFn: requestUnLinkChatRoomByChatRoom,
    onSuccess: () => {
      refetch();
      toastService.successMsg({ text: '채팅방 연동이 해제되었습니다.' });
      invalidateLinkedChatRooms();
    },
    onError: (error: Error) => {
      console.error('Failed to unlink chat room:', error);
      toastService.errorMsg({ text: '채팅방 연동 해제를 실패하였습니다.' });
    },
  });

  const handleUnLinkChatRoom = (params: LinkedChatroomElUnlinkHandlerBodyRequest) => {
    unlinkChatRoomMutation.mutate(params);
  };

  const handleUnLinkChatRoomByChatRoom = (
    params: LinkedChatroomElUnlinkByChatroomHandlerBodyRequest,
  ) => {
    unlinkChatRoomByChatRoomMutation.mutate(params);
  };

  const isShowIndicator = isLoading || isRefetching || unlinkChatRoomMutation.isPending;

  return {
    data: data ?? [],
    isShowIndicator,
    isError,
    handleUnLinkChatRoom,
    handleUnLinkChatRoomByChatRoom,
  };
};
