import { SHARED_UTILS } from '@shared-utils/utils';
import { useInfiniteQuery } from '@tanstack/react-query';
import { apiClient } from 'afterdoc-saas-web/apis/instances/api-client';
import { QUERY_KEY } from 'afterdoc-saas-web/apis/swaggers/query-key';
import type { ApiChatElFindParams } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { chatRoomIDSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtomValue } from 'jotai';

const INTERVAL_NUM = 20;

const getChat = async (query: ApiChatElFindParams) => {
  const { data } = await apiClient.v3.apiChatElFind(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useInfiniteChatIsBookmarkData = () => {
  const chatRoomID = useAtomValue(chatRoomIDSelector);

  const query = {
    chatRoomID,
    isBookmark: true,
    key: 'sentAt',
    way: -1,
  };

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: [QUERY_KEY.apiChatElFind, query] as const,
      queryFn: ({ queryKey, pageParam }) => {
        const { skip, limit } = pageParam;
        return getChat({ ...queryKey[1], skip, limit } as ApiChatElFindParams);
      },
      getNextPageParam: (lastPage, pages) => {
        if ((lastPage?.length ?? 0) < INTERVAL_NUM) return undefined;
        return { skip: pages.length * INTERVAL_NUM, limit: INTERVAL_NUM };
      },
      initialPageParam: { limit: INTERVAL_NUM, skip: 0 },
      enabled: !!chatRoomID,
      staleTime: 0,
      gcTime: 0,
    });

  const newMessages = data?.pages.flat() ?? [];

  return { data: newMessages, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading };
};
