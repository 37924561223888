import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiV2ReservationElFindParams, ReservationDetail } from '@apis/swaggers/swagger-docs';
import type { DataNotificationPayload } from '@shared-hooks/use-data-event-bus-handler/event-bus';
import { useQuery } from '@tanstack/react-query';
import { useAlMightyReservations } from '@templates/CustomerChat/components/CustomerReservation/hooks/use-al-mighty-reservations';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { chatRoomParentHServiceIDSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useDataEventBusHandler } from 'hooks/use-data-event-bus-handler/use-data-event-bus-handler';
import { useAtomValue } from 'jotai/index';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

type ApiV2ReservationElFindParamsExtension = ApiV2ReservationElFindParams & { hserviceID?: string };

const fetchReservationList = async (
  params: ApiV2ReservationElFindParamsExtension,
  options?: { signal?: AbortSignal },
) => {
  const { data } = await apiClient.v3.apiV2ReservationElFind(params, options);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const DEFAULT_LIMIT = 5;

export const useCustomerReservation = () => {
  const { hospitalID, integrationSetting } = useSelectedHospitalInfo();

  const chatRoomParentHServiceID = useAtomValue(chatRoomParentHServiceIDSelector);

  const [reservations, setReservations] = useState<ReservationDetail[]>([]);
  const [isAllDataFetched, setIsAllDataFetched] = useState(false);

  const [isALReservation, setIsALReservation] = useState<boolean | null>(null);

  const { isALLoading, isALReservationError, aLReservations } = useAlMightyReservations({
    isALReservation,
    chatRoomParentHServiceID,
  });

  const [isFetching, setIsFetching] = useState(false);

  const [isFetchingError, setIsFetchingError] = useState(false);

  const [queryParamKey, setQueryParamKey] = useState<ApiV2ReservationElFindParamsExtension>({
    hospitalID,
    hserviceID: chatRoomParentHServiceID ?? '',
    isRemoved: false,
    skip: 0,
    limit: DEFAULT_LIMIT,
    key: 'startedAt',
    way: -1,
  });

  const handleNotificationRef = useRef<((payload: DataNotificationPayload) => void) | null>(null);

  const updateQueryParamKey = useCallback(
    (newParams: Partial<ApiV2ReservationElFindParamsExtension>) => {
      setQueryParamKey((prev) => {
        const updatedParams = { ...prev, ...newParams };
        if (!isEqual(prev, updatedParams)) {
          return updatedParams;
        }
        return prev;
      });
    },
    [],
  );
  const { data, refetch, isLoading, isError, isRefetchError } = useQuery({
    queryKey: [QUERY_KEY.apiV2ReservationElFind, queryParamKey] as const,
    queryFn: async ({ signal }) => {
      return await fetchReservationList(queryParamKey, { signal });
    },
    enabled: !!chatRoomParentHServiceID,
    gcTime: 0,
    staleTime: 0,
  });

  const handleScrollToEnd = useCallback(() => {
    if (isLoading || isAllDataFetched) return;
    updateQueryParamKey({
      limit: DEFAULT_LIMIT,
      skip: reservations.length,
    });
  }, [isLoading, reservations]);

  useEffect(() => {
    if (integrationSetting) {
      const { isRemoved, integrationType, usingReservation } = integrationSetting;
      setIsALReservation(!isRemoved && integrationType === 'ALM' && usingReservation);
    } else {
      setIsALReservation(false);
    }
  }, [integrationSetting]);

  useEffect(() => {
    if (data !== undefined) {
      setIsAllDataFetched(data.length < DEFAULT_LIMIT);
      setReservations((prev) => {
        if (!queryParamKey.skip) {
          return [...data];
        }
        return [...prev, ...data];
      });
    }
  }, [data, queryParamKey]);

  useEffect(() => {
    if (chatRoomParentHServiceID) {
      setIsAllDataFetched(false);
      setReservations([]);
      updateQueryParamKey({
        hserviceID: chatRoomParentHServiceID,
        limit: DEFAULT_LIMIT,
        skip: 0,
      });
    }
  }, [chatRoomParentHServiceID]);

  const notificationConfigs = useMemo(
    () => [
      {
        codes: [
          'd_v2ReservationCreate_el',
          'd_v2ReservationUpdate_el',
          'd_v2ReservationCancel_el',
          'd_v2ReservationRemove_el',
        ],
        handler: (payload: DataNotificationPayload) => handleNotificationRef.current?.(payload),
      },
    ],
    [],
  );

  useDataEventBusHandler(notificationConfigs);

  useEffect(() => {
    handleNotificationRef.current = (payload: DataNotificationPayload) => {
      const hserviceID = payload.data?.hserviceID;
      if (hserviceID && hserviceID === chatRoomParentHServiceID) {
        const newLimit = DEFAULT_LIMIT + (queryParamKey.skip ?? 0) + 1;
        const newSkip = 0;

        if (newLimit === queryParamKey.limit && newSkip === queryParamKey.skip) {
          refetch();
        } else {
          updateQueryParamKey({ limit: newLimit, skip: newSkip });
        }
      }
    };
  }, [queryParamKey]);

  useEffect(() => {
    setIsFetching(isLoading || isALLoading);
  }, [isLoading, isALLoading]);

  useEffect(() => {
    setIsFetchingError(isError || isRefetchError || isALReservationError);
  }, [isError, isRefetchError, isALReservationError]);

  return {
    isFetching,
    isFetchingError,
    isAllDataFetched,
    isALReservation,
    reservations,
    aLReservations,
    chatRoomParentHServiceID,
    handleScrollToEnd,
  };
};
