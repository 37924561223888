import type { UserNameAndType } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'afterdoc-saas-web/shared/hooks/info/use-selected-hospital-info';
import { useUserInfo } from 'afterdoc-saas-web/shared/hooks/info/use-user-info';
import { chatRoomIDSelector } from 'afterdoc-saas-web/templates/CustomerChat/states/selected-chat-room';
import { useAtomValue } from 'jotai';
import { useChatRoomInfoOne } from '../../ChattingRoom/hooks/use-chatroom-info-one';
import {
  OPTION_CHAT_EXIT,
  OPTION_CHAT_NOT_IN_PROGRESS,
  OPTION_DETAIL,
  OPTION_RESERVATION_ENROLL,
  OPTION_USER_ADD,
} from '../constants/dropdown';
import {
  applyFilters,
  noUseReservationFilterOptions,
  onlyKakaoFilterOptions,
} from '../functions/apply-filters';

export type DropdownDataParams = {
  id?: string;
  isOnlyKakao?: boolean;
};

const isInCounselors = (counselors: UserNameAndType[], userID: string) => {
  return counselors.some((counselor) => counselor._id === userID);
};

const isInParticipants = (participants: UserNameAndType[], userID: string) => {
  return participants.some((participant) => participant._id === userID);
};

const calculateRedTextIndices = (options: string[]) =>
  options
    .map((option, index) => (RED_TEXT_LINES.includes(option) ? index : -1))
    .filter((index) => index !== -1);

export const RED_TEXT_LINES = [OPTION_CHAT_NOT_IN_PROGRESS, OPTION_CHAT_EXIT];

const OPTIONS = [OPTION_RESERVATION_ENROLL, OPTION_DETAIL, OPTION_USER_ADD, ...RED_TEXT_LINES];

export function useParentDropdownOptions({ id, isOnlyKakao = false }: DropdownDataParams) {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const { authorizationTypeID, userId: userID } = useUserInfo();
  const { usingSaaSReservation } = useSelectedHospitalInfo();

  const targetChatRoomId = id ?? (chatRoomID || '');
  const isNoUseReservation = !usingSaaSReservation;

  const { counselors, participants, isCounselInProgress } = useChatRoomInfoOne({
    chatRoomID: targetChatRoomId,
  });

  const isAuthoriztionControlPatientChatting = authorizationTypeID?.canControlPatientChatting;

  const isClosedChat = !isCounselInProgress;

  const isInCounselor = isInCounselors(counselors, userID);
  const isInParticipant = isInParticipants(participants, userID);

  //채팅 초대 권한 없는 그룹
  if (!isAuthoriztionControlPatientChatting && isInParticipant && !isInCounselor) {
    const data = ['예약등록', '상세정보', '채팅방 나가기'];

    const filteredData = applyFilters([
      [onlyKakaoFilterOptions, isOnlyKakao],
      [noUseReservationFilterOptions, isNoUseReservation],
    ])(data);

    return {
      data: filteredData,
      redTextIndices: calculateRedTextIndices(filteredData),
    };
  }

  if (!isAuthoriztionControlPatientChatting && isInParticipant && isInCounselor) {
    const data = isClosedChat
      ? ['예약등록', '상세정보', '담당자 추가', '채팅방 나가기']
      : ['예약등록', '상세정보', '담당자 추가', '상담 종료', '채팅방 나가기'];

    const filteredData = applyFilters([
      [onlyKakaoFilterOptions, isOnlyKakao],
      [noUseReservationFilterOptions, isNoUseReservation],
    ])(data);

    return {
      data: filteredData,
      redTextIndices: calculateRedTextIndices(filteredData),
    };
  }

  //채팅 초대 권한 있는 그룹
  if (isAuthoriztionControlPatientChatting && !isInParticipant && !isInCounselor) {
    const data = isClosedChat
      ? ['예약등록', '상세정보', '담당자 추가']
      : ['예약등록', '상세정보', '담당자 추가', '상담 종료'];

    const filteredData = applyFilters([
      [onlyKakaoFilterOptions, isOnlyKakao],
      [noUseReservationFilterOptions, isNoUseReservation],
    ])(data);

    return {
      data: filteredData,
      redTextIndices: calculateRedTextIndices(filteredData),
    };
  }

  if (isAuthoriztionControlPatientChatting && isInParticipant) {
    if (isClosedChat) {
      const data = ['예약등록', '상세정보', '담당자 추가', '채팅방 나가기'];

      const filteredData = applyFilters([
        [onlyKakaoFilterOptions, isOnlyKakao],
        [noUseReservationFilterOptions, isNoUseReservation],
      ])(data);

      return {
        data: filteredData,
        redTextIndices: calculateRedTextIndices(filteredData),
      };
    }
  }

  const filteredData = applyFilters([
    [onlyKakaoFilterOptions, isOnlyKakao],
    [noUseReservationFilterOptions, isNoUseReservation],
  ])(OPTIONS);

  return {
    data: filteredData,
    redTextIndices: calculateRedTextIndices(filteredData),
  };
}
