import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import type { AlgorithmMessage } from 'afterdoc-saas-web/apis/swaggers/swagger-docs';
import { useGetCalculatedResult } from 'afterdoc-saas-web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/hooks/use-get-calculated-result';
import HomecareDetailContentDialog from 'afterdoc-saas-web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/components/HomecareContent/components/HomecareDetailContentDialog';
import { useMemo } from 'react';

interface HomecareContentProps {
  homecareContent: NonNullable<NonNullable<AlgorithmMessage['content']>['homecareContent']>;
}

export default function HomecareContent({
  homecareContent: {
    _id: homecareId,
    thumbnailUrl,
    title,
    price,
    salePrice,
    saleRate,
    saleType,
    isActive,
    isLimitedTime,
    isRemoved,
  },
}: HomecareContentProps) {
  const { formattedPriceDisplay, calculatedPrice, calculatedRate } = useGetCalculatedResult({
    saleRate,
    saleType,
    price,
    salePrice,
  });

  const isEventOrHomecareEnded = useMemo(() => {
    if (!isActive || isRemoved) {
      return true;
    }
    if (!isLimitedTime) {
      return false;
    }
  }, [isActive, isRemoved, isLimitedTime]);

  const onClickHomecare = () => {
    if (!homecareId) return;

    dialogService.push(<HomecareDetailContentDialog homecareId={homecareId} />, {
      id: 'homecare-detail-content-dialog',
      hasCloseButton: true,
    });
  };

  return (
    <div
      className='flex cursor-pointer items-center gap-10 rounded-r16 border border-white400 p-10'
      onClick={onClickHomecare}>
      {thumbnailUrl ? (
        <div className='relative'>
          {isEventOrHomecareEnded && (
            <div className='absolute top-0 right-0 h-full w-full flex-center whitespace-pre rounded-r10 bg-black800 bg-opacity-80 text-Body14 text-white50'>
              판매 종료
            </div>
          )}
          <img
            src={thumbnailUrl}
            alt='홈케어상품 썸네일'
            className={customTwMerge('h-[64px] w-[64px] rounded-[8px] object-cover')}
          />
        </div>
      ) : (
        <div className='h-64 w-64 rounded-r10 bg-disabled' />
      )}
      <div className='flex flex-col'>
        {title ? (
          <div className='line-clamp-2 text-Header12 text-black500'>{title}</div>
        ) : (
          <div className='line-clamp-2 text-Header12 text-black500'>이벤트/홈케어상품 제목</div>
        )}
        <div className='mt-8 text-Body12 text-black200 line-through'>{formattedPriceDisplay}</div>
        <div className='mt-2 flex'>
          <div className='text-Header12 text-blue500'>{calculatedPrice}원</div>
          <div className='text-Header12 text-blue500'>&nbsp;({calculatedRate})</div>
        </div>
      </div>
    </div>
  );
}
